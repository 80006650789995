import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { RemediationTagService } from './remediationtag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-editremediationtag',
	templateUrl: './editremediationtag.component.html'
})

export class EditRemediationTagComponent implements OnInit {
	editRemediationTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private remediationTagService: RemediationTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

	}
	ngOnInit() {
		this.editRemediationTag = new FormGroup({
			id: new FormControl(this.remediationTagService.curRemediationId, Validators.required),
			remediationTag: new FormControl(this.remediationTagService.curRemediation, Validators.required)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedToUpdateRemediation()) {
			this.remediationTagService.editRemediationTag(this.editRemediationTag.value.id, this.editRemediationTag.value.remediationTag)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully modified the remediation tag.");
					this.remediationTagService.curRemediation = this.editRemediationTag.value.remediationTag;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	checkNeedToUpdateRemediation() {
		if (this.remediationTagService.curRemediation != this.editRemediationTag.value.remediationTag) {
			return true;
		} else {
			return false;
		}
	}
}