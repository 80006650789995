export class Constants {
	public static CANNOT_CANCEL = "You are not authorized to cancel some of the selected assessments. Please select assessments assigned to you.";
	public static CANNOT_RESCHEDULE = "You are not authorized to reschedule some of the selected assessments. Please select assessments assigned to you.";
	public static INVALID_FILE_TYPE = "Invalid file type";
	public static INVALID_RANGE_SELF_SCHEDULED_DATE = "Invalid date range for Knowledge Check scheduled date.";
	public static INVALID_RANGE_SCHEDULED_ASS_DATE = "Invalid date range for assessment scheduled date.";
	public static INVALID_RANGE_SELF_COMPLETED_DATE = "Invalid date range for Knowledge Check completed date.";
	public static INVALID_RANGE_ASS_COMPLETED_DATE = "Invalid date range for assessment completed date.";
	public static INVALID_RANGE_IN_REGULATORY_COMPLIANCE = "Invalid date range.";
	public static MODIFY_COMPLETED_ASSESSMENTS = "You cannot modify completed assessments.";
	public static RANGE_ERROR_SELF_SCHEDULED_DATE = "Select a date range for Knowledge Check scheduled date.";
	public static RANGE_ERROR_SCHEDULED_ASS_DATE = "Select a date range for assessment scheduled date.";
	public static RANGE_ERROR_SELF_COMPLETED_DATE = "Select a date range for Knowledge Check completed date.";
	public static RANGE_ERROR_ASS_COMPLETED_DATE = "Select a date range for assessment completed date.";
	public static RANGE_ERROR_IN_REGULATORY_COMPLIANCE = "select a valid date range.";
	public static SUCCESS_CANCEL_ASSESSMENTS = "Successfully canceled the assessment(s).";
	public static SELF_ASSESSMENTS = "Knowledge Check has been started/completed for some of the selected assessment(s)."
	public static CANCEL_ASSESSMENTS = "Are you sure you want to cancel the assessment(s)?"
	public static SUCCESS_CHANGE_ASSESSOR = "Successfully changed the assessor.";
	public static SCHEDULE_ASSESSMENT = "Are you sure you want to schedule the assessment(s) for the selected user(s)?";
	public static RESCHEDULE_ASSESSMENT = "Are you sure you want to reschedule the assessment(s) for the selected user(s)?";
	public static SCHEDULE_ASSESSMENT_WITH_WARNING = "Knowledge Check is due in less than 30 days.";
	public static EMPTY_OPPORTUNITY_INPUT = "Please enter opportunity details.";
	public static EMPTY_OPPORTUNITY_TEXT = "Please enter opportunity text.";
	public static EMPTY_OPPORTUNITY_TYPE = "Please enter opportunity type.";
	public static DUPLICATE_OPPORTUNITY = "Opportunity already exist. Please enter different opportunity details.";
	public static WRONG_ASSESSMENT_DATE = "Schedule assessment date cannot be a past date.";
	public static SUCCESS_RESCHEDULE_ASSESSMENT = "Successfully rescheduled the assessment(s).";
	public static DATE_NOT_CHANGED_CANDIDATES = "Knowledge Check scheduled date is not changed for the following user(s).";
	public static SUCCESS_SCHEDULE_ASSESSMENT = "Successfully scheduled the assessment(s).";
	public static NEVER_ASSESSED_REPORT = "Never been assessed"
	public static OVERDUE_ASSESSMENTS_REPORT = "Overdue assessments"
	public static LIST_ALL_SUPERVISORS = "List of all supervisors"
	public static LIST_ALL_CANDIDATES = "List of all candidates"
	public static LIST_ALL_TASKS = "List of tasks"
	public static LIST_ALL_PERFORMANCE = "List of performances"
	public static LIST_ALL_CHECKLIST = "List of checklists"
	public static LIST_ALL_KNOWLEDGE = "List of knowledge"
	public static MOST_MISSED_KNOWLEDGE = "Most missed knowledge"
	public static MOST_MISSED_PERFORMANCE = "Most missed performance"
	public static MOST_MISSED_TASKS = "Most missed tasks"
	public static MOST_MISSED_KNOWLEDGE_QUESTION = "Most missed knowledge question"
	public static PERCENT_ASSESSMENT_NA_REPORT = "Percent of assessment N/A"
	public static ROLE = "Roles"
	public static JOBPROFILE_TASKS = "Job Profile Tasks"
	public static OPPORTUNITY_FOR_DEVELOPMENT = "Opportunity for Development"
	public static ERROR_HAS_NO_ANSWER_CHECKLIST = 'This performance has checklist without an answer.'
	public static ERROR_HAS_NO_COMPLETED_ASSESSMENT = 'This user has no completed assessment.';
	public static ERROR_HAS_NO_COMPLETED_ASSESSMENT_FOR_CANDIDATE = 'You do not have any completed assessments.';
	public static ERROR_JOB_PROFILE_STATUS_DISABLE = "Disable this job profile to remove items.";
	public static ERROR_JOB_PROFILE_STATUS_DISABLE_ADD = "A new core competency or a task cannot be added without disabling the job profile.";
	public static INFO_NO_DIFFERENCE = 'There is no difference between these two job profiles';
	public static INFO_NO_SIMILARITIES = 'There are no similarities between these two job profiles';
	public static INFO_NO_CHANGES = 'There are no changes to update.';
	public static INFO_DUPLICATE_FILES = 'Newly added file(s) are duplicated.';
	public static INFO_INVALID_FILES = 'Newly added file(s) are invalid.';
	public static INFO_INVALID_AND_DUPLICATED_FILES = 'Newly added files are invalid and duplicated.';
	public static ASSESSMENT = "Assessment";
	public static SELF_ASSESSMENT = "Knowledge Check";
	public static REMEDIATION_REPORT = "Remediation Report";
	public static MY_REMEDIATION_REPORT = "My Remediation Report";
	public static WARN_SELECT_CORECOMPETENCY = "Please select core comptency";
	public static WARN_SELECT_TASK = "Please select tasks";
	public static WARN_SELECT_PERFORMANCE = "Please select performance";
	public static WARN_SELECT_KNOWLEDGE = "Please select knowledge";
	public static WARN_SELECT_REMEDIATION = "Please select remedaition";
	public static WARN_SELECT_CHECKLIST = "Please select checklist";
	public static ERROR_INVALID_KEYCODE = "Invalid key code. A key code has been sent to your email again.";
	public static ERROR_NO_USERROLE = "There is no role attached to this user. Please contact your system administrator.";
	public static MODAL_TOPIC_OVERRIDE_ANSWERS = 'Warning';
	public static MODAL_TOPIC_ADD_NOTE = 'Add Notes'
	public static MODAL_TOPIC_REASON_FOR_NA = 'Edit Reason for N/A'
	public static MODAL_BODY_OVERRIDE_ANSWERS = 'You have already answered a performance or a knowledge in this task. Are you sure you want to N/A?';
	public static MODAL_BODY_OVERRIDE_ANSWERS_YES = 'You have already answered a performance or a knowledge in this task. Are you sure you want to set this task to Checked?';
	public static MODAL_BODY_TASK_OVERRIDE_NA = 'You have already set this task to N/A. Are you sure you want to undo?';
	public static MODAL_BODY_TASK_OVERRIDE_YES = 'You have already set this task to Checked. Are you sure you want to undo?';
	public static ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE = 'At least one of the following is required: attach document(s), link, note or course number/course description. Inserted file(s) are invalid.';
	public static ERROR_PROVIDE_REQUIRED_FIELDS = 'At least one of the following is required: attach document(s), link, note or course number and course description.';
	public static ERROR_ONGOING_ASSESSMENTS = 'Unable to change assess attribute since there is an ongoing assessment for this user.';
	public static ERROR_PERFORM_ASSESSMENT = "You are not allowed to perform this assessment. This is assisgned to another assessor.";
	public static ERROR_DUPLICAT_OPPORTUNITY = 'Opportunity for development entry already exists.';
	public static ERROR_ALL_NA_TASKS = "You cannot N/A all the tasks."
	public static MODAL_BODY_COMPLETE_ASSESSMENT_PART1 = "Are you sure you want to N/A the remaining task(s) with the default reason for N/A '"
	public static MODAL_BODY_COMPLETE_ASSESSMENT_PART2 = "' when completing the assessment?"

	//overlay instruction 
	public static OERLAY_INS_BACKGROUND_MSG = 'Tap any where to remove overlay instructions.'
	public static OERLAY_INS_DSHBOR_REST_BTN = 'Click on this button to load the default dashboard'
	public static OERLAY_INS_DSHBOR_SAVE_BTN = 'Click on this button to save the existing dashboard'
	public static OERLAY_INS_DSHBOR_ADDNEW_BTN = 'Click on this button to add an already removed widget to the dashboard'
	public static OERLAY_INS_DSHBOR_MANAGE_BTN = 'Use these buttons to manage your dashboard'
	public static OERLAY_INS_DSHBOR_WIDGET_CLOSE_BTN = 'Click on this button to remove widgets from the dashboard'
	public static OERLAY_INS_DSHBOR_WIDGET_MINIMIZE_BTN = 'Click on this button to minimize widgets'
	public static OERLAY_INS_SLIDEBAR = 'Click on menu items to see the content'
	public static OERLAY_INS_SLIDEBAR_TOGGLE_BTN = 'Click on this button to see the navigation bar'
	public static OERLAY_INS_PROFILE_BTN = 'Click on this icon to update profile, change password and logout'
	public static OERLAY_INS_SELFASEST_COMSELFASSE_BTN = '3. Click to complete once all core competency and task are completed'
	public static OERLAY_INS_SELFASEST_CCITEM = '1. Expand core competency to see tasks'
	public static OERLAY_INS_SELFASEST_TASKITEM = '2. Click each task and answer all questions and save'

	public static SUCCESS_REGENERATE = 'Successfully regenerated the assessor package.'
	public static ADD_NOTE = 'Successfully added note(s).'

	public static SUCCESS_CHANGE_THEME = 'Successfully changed the application settings.'
	public static SUCCESS_UPLOAD_FAVICON = 'Successfully uploaded favicon.'
	public static SUCCESS_UPLOAD_LOGINLOGO = 'Successfully uploaded login logo. Please logout and login to see the changes.'
	public static SUCCESS_UPLOAD_HEADERLOGO = 'Successfully uploaded header logo. Please logout and login to see the changes.'
	public static SUCCESS_COPY_JOB_PROFILE = 'Successfully copied the selected job profile.'
	public static EMPTY_COPY_JOB_PROFILE = 'There are no any active items/changes to copy.'

	public static MODIFY_LOCKED_ASSESSMENTS = 'You cannot modify locked assessments.'
	public static UNLOCK_ASSESSMENT = "Unlocking the assessment manually will cause data deletion from the tablet app. Are you sure you want to unlock the assessment?"
	public static SUCCESS_UNLOCKED_ASSESSMENT = "Successfully unlocked the assessment."
	public static SELF_ASSESSMENT_REVIEW = "Knowledge Check Review"
	public static MY_SELF_ASSESSMENT_REVIEW = "My Knowledge Check Review"

	public static ERROR_NO_INTERNET = "Please check your internet connection."

	constructor() {
	}
}
