import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { UserService } from '../user/user.service';
import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { Constants } from '../util/const/constants';
import { AssessmentService } from './assessment.service';
import { TableComponent } from '../util/table/table.component';
import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from 'mydatepicker';
import * as FileSaver from 'file-saver';
import { AssessmentModel } from './assessment.model';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

@Component({
	selector: 'app-assessments',
	templateUrl: './allassessment.component.html',
	styleUrls: ['./allassessment.component.scss']
})

export class Assessment implements OnInit {
	@ViewChild('changeAssessorModal', { static: true }) changeAssessorModal: ModalComponent;
	@ViewChild('cancelAssessmentModal', { static: true }) cancelAssessmentModal: ModalComponent;
	@ViewChild('unlockAssessmentModal', { static: true }) unlockAssessmentModal: ModalComponent;
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	showCompleted: number = 1;
	paginatedResults = [];
	selectedRows = [];
	assessorList = [];
	searchFullName: string = "";
	searchOrgCode: string = "";
	searchJobProfile: string = "";
	searchAssessor: string = "";
	scheduledSelfAssFromDate: Date = null;
	public data: Array<any> = [];
	public filteredData: Array<any> = [];
	public temp: string = "cell-green";
	public currentDate: any;
	isLoading: boolean = false;
	isFiltering: boolean = true;
	originaldata: Array<any> = [];
	showSelfAssessmentMsg: boolean = false
	constants: any
	selectedAssessment: any = null

	isQueryAssessment: boolean = false;
	isModifyAssessment: boolean = false;
	isDataExist: boolean = false;
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public userId: any = localStorage.getItem('userId');
	public selectedUserRole = [];
	public userRole: any = null
	assessmentForm: FormGroup;
	changeAssessorForm: FormGroup;
	myDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};
	scheduledSelfAssToOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};
	scheduledAssToOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};
	selfAssCompletedToOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};
	assCompletedToOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};

	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private userService: UserService,
		private assessmentService: AssessmentService,
		private changeDetectorRef: ChangeDetectorRef,
		private formBuilder: FormBuilder) {

		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
			this.userRole = this.selectedUserRole[0][1]
		}
		this.constants = Constants;
		this.isQueryAssessment = this.userService.isCapabilityAssigned('QueryAssessment', this.selectedUserRole[0][1]);
		this.isModifyAssessment = this.userService.isCapabilityAssigned('ModifyAssessment', this.selectedUserRole[0][1]);
		this.loadAllAssessors();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	onSorted($event) {
		this.isFiltering = false;
		this.sortData($event)
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: AllAssessmentSearchCriteria) {
		if (criteria.sortColumn == 'userName' || criteria.sortColumn == 'orgCode' || criteria.sortColumn == 'jobProfile' || criteria.sortColumn == 'assessorName') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	sortData(criteria: AllAssessmentSearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push(this.originaldata[i]);
			}
			if (this.searchFullName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["userName"].match(new RegExp(this.searchFullName, "i"));
				});
			}
			if (this.searchOrgCode != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["orgCode"].match(new RegExp(this.searchOrgCode, "i"));
				});
			}
			if (this.searchJobProfile != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["jobProfile"].match(new RegExp(this.searchJobProfile, "i"));
				});
			}
			if (this.searchAssessor != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["assessorName"].match(new RegExp(this.searchAssessor, "i"));
				});
			}
		}
	}

	filter() {
		this.isFiltering = true;
		this.filteredData = [];
		for (let i = 0; i < this.originaldata.length; i++) {
			this.filteredData.push(this.originaldata[i]);
		}

		if (this.searchFullName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["userName"].match(new RegExp(this.searchFullName, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["orgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["jobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		if (this.searchAssessor != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["assessorName"].match(new RegExp(this.searchAssessor, "i"));
			});
		}
		this.pageChanged(1);
		this.isFiltering = false;
	}

	getStyle(status) {
		let style = "";
		if (status == "Completed") {
			style = " text-center cell-green";
		} else if (status == "Started") {
			style = " text-center cell-yellow";
		} else {
			style = " text-center";
		}
		return style;
	}

	showType(e) {
		if (e.target.checked) {
			this.showCompleted = 2;
		} else {
			this.showCompleted = 1;
		}
	}

	loadAllAssessors() {
		this.userService.getAllAssessors()
			.subscribe(
			data => {
				this.data = [];
				for (var i = 0; i < data.assessorList.length; i++) {
					this.assessorList.push({ id: data.assessorList[i].Id, name: data.assessorList[i].FirstName + " " + data.assessorList[i].LastName });
				}
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	getAssessments(param: any) {
		this.assessmentService.getAllAssessments(param)
			.subscribe(
			data => {
				this.data = [];
				for (var i = 0; i < data.Assessments.length; i++) {
					// console.log('[getAssessments] (data_' + i +') ' + JSON.stringify(data.Assessments[i]).toString())
					// this.data.push({
					// 	id: data.Assessments[i].Id, fullName: data.Assessments[i].FirstName + " " + data.Assessments[i].LastName,
					// 	jobProfile: data.Assessments[i].jobProfile, orgCode: data.Assessments[i].orgCode,
					// 	scheduleSelfAssessmentDate: data.Assessments[i].ScheduleSelfAssessmentDate == null ? "" : new Date(data.Assessments[i].ScheduleSelfAssessmentDate).toLocaleDateString(), scheduleAssessmentDate: data.Assessments[i].ScheduleAssessmentDate == null ? "" : new Date(data.Assessments[i].ScheduleAssessmentDate).toLocaleDateString(),
					// 	selfAssessmentCompletedDate: data.Assessments[i].SelfAssessmentCompletedDate == null ? "" : new Date(data.Assessments[i].SelfAssessmentCompletedDate).toLocaleDateString(), assessmentCompletedDate: data.Assessments[i].AssessmentCompletedDate == null ? "" : new Date(data.Assessments[i].AssessmentCompletedDate).toLocaleDateString(),
					// 	assessor: data.Assessments[i].AssessorStatus == null ? "" : data.Assessments[i].AssessorStatus, assessorId: data.Assessments[i].AssessorId == null ? "" : data.Assessments[i].AssessorId,
					// 	selfAssessment: data.Assessments[i].SelfAssessmentStatus == null ? "" : data.Assessments[i].SelfAssessmentStatus,
					// 	selfAssessmentId: data.Assessments[i].SelfAssessmentId == null ? "" : data.Assessments[i].SelfAssessmentId,
					// 	selfAssessmentAnswer: data.Assessments[i].SelfAssessmentAnswerKeyStatus == null ? "" : data.Assessments[i].SelfAssessmentAnswerKeyStatus,
					// 	selfAssessmentAnswerId: data.Assessments[i].SelfAssessmentAnswerId == null ? "" : data.Assessments[i].SelfAssessmentAnswerId
					// });

					let tempAssessment = data.Assessments[i]
					let assessment: AssessmentModel = new AssessmentModel(tempAssessment.Id)
					assessment.assessorId = tempAssessment.AssessorId
					assessment.assessorName = tempAssessment.AssessorFirstName + ' ' + tempAssessment.AssessorLastName
					assessment.assessorPkgId = tempAssessment.assessorPkgId
					assessment.selfAssessmentId = tempAssessment.SelfAssessmentId
					assessment.selfAssessmentAnswerId = tempAssessment.SelfAssessmentAnswerId
					assessment.userId = tempAssessment.UserId
					assessment.userName = tempAssessment.FirstName + ' ' + tempAssessment.LastName
					assessment.userContractStatus = tempAssessment.IsContract
					assessment.userEmail = tempAssessment.Email
					assessment.supervisorId = tempAssessment.SupervisorId
					assessment.orgId = tempAssessment.OrgId
					if (tempAssessment.Status == 'Completed') {
						assessment.orgCode = tempAssessment.orgCode
					} else {
						assessment.orgCode = tempAssessment.currentOrgCode
					}
					assessment.jobProfileId = tempAssessment.JobProfieId
					assessment.jobProfile = tempAssessment.jobProfile
					assessment.disciplineId = tempAssessment.DisciplineId
					assessment.discipline = tempAssessment.Discipline
					assessment.status = tempAssessment.Status
					assessment.selfAssStatus = tempAssessment.AssessmentSelfAssessmentStatus
					assessment.candidateStrengths = tempAssessment.CandidateStrengths
					assessment.lockedBy = tempAssessment.LockedBy
					assessment.assessorCompleted = tempAssessment.AssessorCompleted
					assessment.candidateSignature = tempAssessment.CandidateSignature
					assessment.assessorSignature = tempAssessment.AssessorSignature
					assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest

					if (tempAssessment.AssessmentCompletedDate == null) {
						assessment.assessmentCompletedDate = null
					} else {
						assessment.assessmentCompletedDate = new Date(new Date(tempAssessment.AssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.AssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.AssessmentCompletedDate).getUTCDate());
					}
					if (tempAssessment.AssessmentDate == null) {
						assessment.assessmentDate = null
					} else {
						assessment.assessmentDate = new Date(new Date(tempAssessment.AssessmentDate).getUTCFullYear(), new Date(tempAssessment.AssessmentDate).getUTCMonth(), new Date(tempAssessment.AssessmentDate).getUTCDate());
					}
					if (tempAssessment.SelfAssessmentCompletedDate == null) {
						assessment.selfAssessmentCompletedDate = null
					} else {
						assessment.selfAssessmentCompletedDate = new Date(new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCDate());
					}
					if (tempAssessment.ScheduleAssessmentDate == null) {
						assessment.scheduleAssessmentDate = null
					} else {
						assessment.scheduleAssessmentDate = new Date(new Date(tempAssessment.ScheduleAssessmentDate).getUTCFullYear(), new Date(tempAssessment.ScheduleAssessmentDate).getUTCMonth(), new Date(tempAssessment.ScheduleAssessmentDate).getUTCDate());
					}
					if (tempAssessment.ScheduleSelfAssessmentDate == null) {
						assessment.scheduleSelfAssessmentDate = null
					} else {
						assessment.scheduleSelfAssessmentDate = new Date(new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCMonth(), new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCDate());
					}
					assessment.assessorStatus = tempAssessment.AssessorStatus
					assessment.selfAssessmentStatus = tempAssessment.SelfAssessmentStatus
					assessment.selfAssessmentAnswerKeyStatus = tempAssessment.SelfAssessmentAnswerKeyStatus
					assessment.supervisorName = tempAssessment.SupervisorName == null ? '' : tempAssessment.SupervisorName

					this.data.push(assessment)
					this.originaldata.push(assessment);
				}

				this.total = this.data.length;
				this.filteredData = this.data;
				if (this.filteredData.length > 0) {
					this.isDataExist = true
				} else {
					this.isDataExist = false
				}
				this.pageChanged();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	ngOnInit() {
		this.assessmentForm = new FormGroup({
			scheduledSelfAssFromDate: new FormControl(null),
			scheduledSelfAssToDate: new FormControl(null),
			scheduledAssFromDate: new FormControl(null),
			scheduledAssToDate: new FormControl(null),
			selfAssCompletedFromDate: new FormControl(null),
			selfAssCompletedToDate: new FormControl(null),
			assCompletedFromDate: new FormControl(null),
			assCompletedToDate: new FormControl(null),
			showCompleted: new FormControl(null)
		});

		this.changeAssessorForm = this.formBuilder.group({
			assessor: new FormControl(null, Validators.required)
		});

		let mode = this.assessmentService.getAssessmentMode();
		if (mode) {
			let startDate: Date = this.assessmentService.getAssessmentDate();
			if (mode == 'SelfAssessment') {
				this.assessmentForm.get("scheduledSelfAssFromDate").setValue({ date: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() } });
				this.assessmentForm.get("scheduledSelfAssToDate").setValue({ date: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() } });
			} else {
				this.assessmentForm.get("scheduledAssFromDate").setValue({ date: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() } });
				this.assessmentForm.get("scheduledAssToDate").setValue({ date: { year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate() } });
			}
			this.assessmentService.setAssessmentMode(null);
			this.assessmentService.setAssessmentDate(null);
			this.onSubmit();
		}
	}

	scheduledSelfAssFromChanged(event: IMyDateModel) {
		var temp = event.jsdate;
		var newDate;
		if (event.jsdate != null) {
			temp = new Date(temp.getTime() - (24 * 60 * 60 * 1000));
			newDate = { year: temp.getFullYear(), month: temp.getMonth() + 1, day: temp.getDate() };
		} else {
			newDate = { year: 0, month: 0, day: 0 };
		}

		this.scheduledSelfAssToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: newDate
		};
	}

	scheduledAssToChanged(event: IMyDateModel) {
		var temp = event.jsdate;
		var newDate;
		if (event.jsdate != null) {
			temp = new Date(temp.getTime() - (24 * 60 * 60 * 1000));
			newDate = { year: temp.getFullYear(), month: temp.getMonth() + 1, day: temp.getDate() };
		} else {
			newDate = { year: 0, month: 0, day: 0 };
		}
		this.scheduledAssToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: newDate
		};
	}

	selfAssCompletedToChanged(event: IMyDateModel) {
		var temp = event.jsdate;
		var newDate;
		if (event.jsdate != null) {
			temp = new Date(temp.getTime() - (24 * 60 * 60 * 1000));
			newDate = { year: temp.getFullYear(), month: temp.getMonth() + 1, day: temp.getDate() };
		} else {
			newDate = { year: 0, month: 0, day: 0 };
		}
		this.selfAssCompletedToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: newDate
		};
	}

	assCompletedToChanged(event: IMyDateModel) {
		var temp = event.jsdate;
		var newDate;
		if (event.jsdate != null) {
			temp = new Date(temp.getTime() - (24 * 60 * 60 * 1000));
			newDate = { year: temp.getFullYear(), month: temp.getMonth() + 1, day: temp.getDate() };
		} else {
			newDate = { year: 0, month: 0, day: 0 };
		}
		this.assCompletedToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: newDate
		};
	}

	public clearForm() {
		this.assessmentForm.reset();
		this.searchFullName = '';
		this.searchOrgCode = '';
		this.searchJobProfile = '';
		this.searchAssessor = '';
		this.showCompleted = 1;
		this.data = [];
		this.filteredData = [];
		this.originaldata = [];
		this.pageChanged();
		this.total = 1;
		this.numPages = 1;
		this.alertServices.clear();
		this.assessmentForm.get("scheduledSelfAssFromDate").setValue(null);
		this.assessmentForm.get("scheduledSelfAssToDate").setValue(null);
		this.assessmentForm.get("scheduledAssFromDate").setValue(null);
		this.assessmentForm.get("scheduledAssToDate").setValue(null);
		this.assessmentForm.get("selfAssCompletedFromDate").setValue(null);
		this.assessmentForm.get("selfAssCompletedToDate").setValue(null);
		this.assessmentForm.get("assCompletedFromDate").setValue(null);
		this.assessmentForm.get("assCompletedToDate").setValue(null);
		this.scheduledSelfAssToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: { year: 0, month: 0, day: 0 }
		};
		this.scheduledAssToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: { year: 0, month: 0, day: 0 }
		};
		this.selfAssCompletedToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: { year: 0, month: 0, day: 0 }
		};
		this.assCompletedToOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: { year: 0, month: 0, day: 0 }
		};
		this.isDataExist = false
	}

	onSubmit() {
		this.isFiltering = true;
		this.searchFullName = '';
		this.searchOrgCode = '';
		this.searchJobProfile = '';
		this.searchAssessor = '';
		this.filteredData = [];
		this.originaldata = [];

		this.selectedRows = [];
		this.alertServices.clear();
		var scheduledSelfAssFromDate = this.assessmentForm.get("scheduledSelfAssFromDate").value;
		if (!scheduledSelfAssFromDate) {
			scheduledSelfAssFromDate = "";
		} else {
			scheduledSelfAssFromDate = scheduledSelfAssFromDate.date;
			scheduledSelfAssFromDate = new Date(scheduledSelfAssFromDate.year, scheduledSelfAssFromDate.month - 1, scheduledSelfAssFromDate.day);
		}

		var scheduledSelfAssToDate = this.assessmentForm.get("scheduledSelfAssToDate").value;
		if (!scheduledSelfAssToDate) {
			scheduledSelfAssToDate = "";
		} else {
			scheduledSelfAssToDate = scheduledSelfAssToDate.date;
			scheduledSelfAssToDate = new Date(scheduledSelfAssToDate.year, scheduledSelfAssToDate.month - 1, scheduledSelfAssToDate.day);
		}
		var scheduledAssFromDate = this.assessmentForm.get("scheduledAssFromDate").value;
		if (!scheduledAssFromDate) {
			scheduledAssFromDate = "";
		} else {
			scheduledAssFromDate = scheduledAssFromDate.date;
			scheduledAssFromDate = new Date(scheduledAssFromDate.year, scheduledAssFromDate.month - 1, scheduledAssFromDate.day);
		}
		var scheduledAssToDate = this.assessmentForm.get("scheduledAssToDate").value;
		if (!scheduledAssToDate) {
			scheduledAssToDate = "";
		} else {
			scheduledAssToDate = scheduledAssToDate.date;
			scheduledAssToDate = new Date(scheduledAssToDate.year, scheduledAssToDate.month - 1, scheduledAssToDate.day);
		}
		var selfAssCompletedFromDate = this.assessmentForm.get("selfAssCompletedFromDate").value;
		if (!selfAssCompletedFromDate) {
			selfAssCompletedFromDate = "";
		} else {
			selfAssCompletedFromDate = selfAssCompletedFromDate.date;
			selfAssCompletedFromDate = new Date(selfAssCompletedFromDate.year, selfAssCompletedFromDate.month - 1, selfAssCompletedFromDate.day);
		}
		var selfAssCompletedToDate = this.assessmentForm.get("selfAssCompletedToDate").value;
		if (!selfAssCompletedToDate) {
			selfAssCompletedToDate = "";
		} else {
			selfAssCompletedToDate = selfAssCompletedToDate.date;
			selfAssCompletedToDate = new Date(selfAssCompletedToDate.year, selfAssCompletedToDate.month - 1, selfAssCompletedToDate.day);
		}
		var assCompletedFromDate = this.assessmentForm.get("assCompletedFromDate").value;
		if (!assCompletedFromDate) {
			assCompletedFromDate = "";
		} else {
			assCompletedFromDate = assCompletedFromDate.date;
			assCompletedFromDate = new Date(assCompletedFromDate.year, assCompletedFromDate.month - 1, assCompletedFromDate.day);
		}
		var assCompletedToDate = this.assessmentForm.get("assCompletedToDate").value;
		if (!assCompletedToDate) {
			assCompletedToDate = "";
		} else {
			assCompletedToDate = assCompletedToDate.date;
			assCompletedToDate = new Date(assCompletedToDate.year, assCompletedToDate.month - 1, assCompletedToDate.day);
		}

		this.data = [];
		this.filteredData = [];
		this.pageChanged();
		this.total = 1;
		this.numPages = 1;
		var result = true;

		if ((!scheduledSelfAssFromDate && scheduledSelfAssToDate) || (!scheduledSelfAssToDate && scheduledSelfAssFromDate)) {
			result = false;
			this.alertServices.error(Constants.RANGE_ERROR_SELF_SCHEDULED_DATE)
		} else {
			if (scheduledSelfAssFromDate > scheduledSelfAssToDate) {
				this.alertServices.error(Constants.INVALID_RANGE_SELF_SCHEDULED_DATE)
			}
		}
		if ((!scheduledAssFromDate && scheduledAssToDate) || (!scheduledAssToDate && scheduledAssFromDate)) {
			result = false;
			this.alertServices.error(Constants.RANGE_ERROR_SCHEDULED_ASS_DATE)
		} else {
			if (scheduledAssFromDate > scheduledAssToDate) {
				this.alertServices.error(Constants.INVALID_RANGE_SCHEDULED_ASS_DATE)
			}
		}
		if ((!selfAssCompletedFromDate && selfAssCompletedToDate) || (!selfAssCompletedToDate && selfAssCompletedFromDate)) {
			result = false;
			this.alertServices.error(Constants.RANGE_ERROR_SELF_COMPLETED_DATE)
		} else {
			if (selfAssCompletedFromDate > selfAssCompletedToDate) {
				this.alertServices.error(Constants.INVALID_RANGE_SELF_COMPLETED_DATE)
			}
		}
		if ((!assCompletedFromDate && assCompletedToDate) || (!assCompletedToDate && assCompletedFromDate)) {
			result = false;
			this.alertServices.error(Constants.RANGE_ERROR_ASS_COMPLETED_DATE)
		} else {
			if (assCompletedFromDate > assCompletedToDate) {
				this.alertServices.error(Constants.INVALID_RANGE_ASS_COMPLETED_DATE)
			}
		}
		if (result) {
			var param = {
				'scheduledSelfAssFromDate': scheduledSelfAssFromDate == "" ? "" : scheduledSelfAssFromDate.toLocaleDateString().replace(/[^ -~]/g, ''), 'scheduledSelfAssToDate': scheduledSelfAssToDate == "" ? "" : scheduledSelfAssToDate.toLocaleDateString().replace(/[^ -~]/g, ''),
				'scheduledAssFromDate': scheduledAssFromDate == "" ? "" : scheduledAssFromDate.toLocaleDateString().replace(/[^ -~]/g, ''), 'scheduledAssToDate': scheduledAssToDate == "" ? "" : scheduledAssToDate.toLocaleDateString().replace(/[^ -~]/g, ''),
				'selfAssCompletedFromDate': selfAssCompletedFromDate == "" ? "" : selfAssCompletedFromDate.toLocaleDateString().replace(/[^ -~]/g, ''), 'selfAssCompletedToDate': selfAssCompletedToDate == "" ? "" : selfAssCompletedToDate.toLocaleDateString().replace(/[^ -~]/g, ''),
				'assCompletedFromDate': assCompletedFromDate == "" ? "" : assCompletedFromDate.toLocaleDateString().replace(/[^ -~]/g, ''), 'assCompletedToDate': assCompletedToDate == "" ? "" : assCompletedToDate.toLocaleDateString().replace(/[^ -~]/g, ''),
				'orgCodeId': this.assessmentService.getSelectedOrgCodeId(),
				'showCompleted': this.showCompleted
			};
			this.assessmentService.setSelectedOrgCodeId(null);
			if (this.isQueryAssessment) {
				this.getAssessments(param);
			} else {
				//do nothing
			}
		}
		this.isFiltering = false;
	}

	fileIconButtonClickEvent(assessmentId, pkgId, userName, assessmentDate, type): any {
		this.currentDate = this.formatDate(assessmentDate);
		userName = userName.replace(/ /g, '');
		this.isLoading = true
		this.assessmentService.getPackageContent(assessmentId, pkgId)
			.subscribe(fileData => {
				this.isLoading = false
				FileSaver.saveAs(fileData, type + "_" + userName + "_" + this.currentDate + ".pdf");
			});
	}

	public performAssessmentBtnClickEvent(assessment) {
		this.assessmentService.setSelectedAssessmentModel(assessment)
		this.sharedService.change('PerformAssessment')
	}

	setSelected(e, i) {
		let objIndex = this.data.findIndex((obj => obj.id == this.paginatedResults[i].id));
		if (e.target.checked) {
			if (this.paginatedResults[i].status == "Completed" || (this.paginatedResults[i].isKnowledgeTest == 1 && this.paginatedResults[i].selfAssStatus == 'Completed')) {
				this.alertServices.clear();
				this.alertServices.error(Constants.MODIFY_COMPLETED_ASSESSMENTS);
				e.target.checked = false;
			} else if (this.paginatedResults[i].lockedBy) {
				this.alertServices.clear();
				this.alertServices.error(Constants.MODIFY_LOCKED_ASSESSMENTS);
				e.target.checked = false;
			} else {
				this.data[objIndex].selected = 1;
			}
		} else {
			this.data[objIndex].selected = 0;
		}
		this.selectedRows = this.data.filter(function (v) { return v["selected"] == 1; });
	}

	cancelAssessment() {
		this.selectedRows = this.data.filter(function (v) { return v["selected"] == 1; });
		this.showSelfAssessmentMsg = false
		let canReschedule = true;
		for (var i = 0; i < this.selectedRows.length; i++) {
			if (this.selectedRows[i].assessorId != localStorage.getItem("userId")) {
				canReschedule = false;
			}
			if (this.selectedRows[i].selfAssStatus != 'New') {
				this.showSelfAssessmentMsg = true
			}
		}

		if (!canReschedule) {
			this.alertServices.clear();
			this.alertServices.error(Constants.CANNOT_CANCEL);
		} else {
			this.cancelAssessmentModal.open()
		}
	}

	cancel() {
		this.assessmentService.cancelAssessment(this.selectedRows)
			.subscribe(
			data => {
				this.onSubmit();
				this.alertServices.clear();
				this.alertServices.success(Constants.SUCCESS_CANCEL_ASSESSMENTS);
				this.selectedRows = [];
				this.data.forEach(obj => obj.selected = 0);
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);

	}

	unlockAssessment(assessmentId: any) {
		this.selectedAssessment = assessmentId
		this.unlockAssessmentModal.open()
	}

	unlock() {
		this.assessmentService.unlockAssessment(this.selectedAssessment)
			.subscribe(
			data => {
				this.onSubmit();
				this.alertServices.clear();
				this.alertServices.success(Constants.SUCCESS_UNLOCKED_ASSESSMENT);
				this.selectedRows = [];
				this.data.forEach(obj => obj.selected = 0);
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);

	}

	reschedule() {
		this.selectedRows = this.data.filter(function (v) { return v["selected"] == 1; });
		let canReschedule = true;
		for (var i = 0; i < this.selectedRows.length; i++) {
			if (this.selectedRows[i].assessorId != localStorage.getItem("userId")) {
				canReschedule = false;
				break;
			}
		}

		if (!canReschedule) {
			this.alertServices.clear();
			this.alertServices.error(Constants.CANNOT_RESCHEDULE);
		} else {
			this.assessmentService.setSelectedUsers(this.selectedRows);
			this.assessmentService.setScheduleMode('Reschedule');
			this.assessmentService.setScheduledSelfAssessmentDate(this.selectedRows[0]["scheduleSelfAssessmentDate"])
			this.selectedRows = [];
			this.data.forEach(obj => obj.selected = 0);
			this.selectedComponentItem('Calendar');
		}

	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	changeAssessor() {
		this.selectedRows = this.data.filter(function (v) { return v["selected"] == 1; });
		this.assessmentService.changeAssessor(this.selectedRows, this.changeAssessorForm.value.assessor)
			.subscribe(
			data => {
				this.onSubmit();
				this.alertServices.clear();
				this.alertServices.success(Constants.SUCCESS_CHANGE_ASSESSOR);
				this.changeAssessorForm.reset();
				this.selectedRows = [];
				this.data.forEach(obj => obj.selected = 0);
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	public formatDate(date) {
		date = date.split("/");
		var year = date[2]
		year = year.substring(2);
		var month = date[0];
		var day = date[1];

		if (month.length == 1) {
			month = "0" + month;
		}

		if (day.length == 1) {
			day = "0" + day;
		}

		var Date = month + day + year
		return Date;
		// var time = date.getHours() + '' + date.getMinutes()
		// return (Date + ':' + time)
	}

	regeneratePkg(assessmentId) {
		this.isLoading = true;
		this.assessmentService.regenerateAssessorPackage(assessmentId)
			.subscribe(
			data => {
				this.isLoading = false;
				this.alertServices.clear();
				this.alertServices.success(Constants.SUCCESS_REGENERATE);
			},
			error => {
				this.isLoading = false;
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	exportToExcel() {
		var data = []
		if (this.filteredData.length > 0) {
			this.filteredData.forEach(item => {
				var temp = {
					"Full Name": item.userName,
					"Org Code": item.orgCode,
					"Job Profile": item.jobProfile,
					"Assessor": item.assessorName,
					"Knowledge Check Scheduled": item.scheduleSelfAssessmentDate,
					"Knowledge Check Actual": item.selfAssessmentCompletedDate,
					"Assessor Lead Scheduled": item.isKnowledgeTest == 1 ? 'Knowledge Check Only' : item.scheduleAssessmentDate,
					"Assessor Lead Actual": item.assessmentDate,
					"Assessment Completed Date": item.assessmentCompletedDate
				}
				data.push(temp)
			});

			let outputName = "Manage Assessments"

			this.assessmentService.exportAsExcelFile(data, outputName);
		}
	}
}

export class AllAssessmentSearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}

