export class remediation {
	constructor(
		public id: string,
		public medium: string,
		public description: string,
		public type: string,
		public courseNumber: string,
		public courseDescription: string,
		public link: string,
		public orgId: string,
		public note: string,
		public attachment: any,
		public tagList?: any,
		public removedAttachments?: any,
		public jptid?: any,
		public jppid?: any,
		public jpkid?: any,
		public JPCLId?: any,
		public text?: string,
	) { }
}
