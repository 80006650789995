<div *ngIf="show" class="bs-callout bs-callout-info">
	<h6>Assessment Progress</h6>

	<div>
		<var *ngIf="isKnowledgeTest == 0">Assessor Lead</var>
		<div class="progress" *ngIf="isKnowledgeTest == 0">
			<div class="progress-bar progress-bar-warning progress-bar-striped" [style.width]="progress +'%'" aria-valuemin="0" aria-valuemax="100">
				<span>{{progress}}% </span>
			</div>
		</div>
		<div *ngIf="isKnowledgeTest == 1" style="display: flex"> <span class="badge badge-custom-kt mt-1 mr-1 mb-1">Knowledge Check Only</span></div>
		<var>Knowledge Check</var>
		<div class="progress">
			<div class="progress-bar progress-bar-warning progress-bar-striped" [style.width]="selfAssessmentProgress +'%'" aria-valuemin="0" aria-valuemax="100">
				<span>{{selfAssessmentProgress}}% </span>
			</div>
		</div>
		<br>
	</div>
</div>