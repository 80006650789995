import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay } from 'angular-calendar';
import {
	subMonths,
	addMonths,
	addDays,
	addWeeks,
	subDays,
	subWeeks,
	startOfMonth,
	endOfMonth,
	startOfWeek,
	endOfWeek,
	startOfDay,
	endOfDay,
	isSameDay,
	isSameMonth,
	addHours
} from 'date-fns';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { Subject } from 'rxjs/Subject';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../user/user.service';
import { AlertService } from '../util/alert/alert.service';
import { SharedService } from '../home/shared.service';
import { AssessmentService } from './assessment.service';
import { Constants } from "../util/const/constants";
import * as moment from 'moment-timezone';

type CalendarPeriod = 'day' | 'week' | 'month';

function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
	return {
		day: addDays,
		week: addWeeks,
		month: addMonths
	}[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
	return {
		day: subDays,
		week: subWeeks,
		month: subMonths
	}[period](date, amount);
}

const colors: any = {
	red: {
		primary: '#ad2121',
		secondary: '#FAE3E3'
	},
	blue: {
		primary: '#1e90ff',
		secondary: '#D1E8FF'
	},
	yellow: {
		primary: '#e3bc08',
		secondary: '#FDF1BA'
	}
};

@Component({
	selector: 'app-assessment-calendar',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: 'assessmentcalendar.component.html',
	styleUrls: ['./assessmentcalendar.component.scss']
})
export class AssessmentCalendarComponent {
	@ViewChild('scheduleconfirmationModal', { static: true }) scheduleconfirmationModal: ModalComponent;
	@ViewChild('rescheduleconfirmationModal', { static: true }) rescheduleconfirmationModal: ModalComponent;
	@ViewChild('scheduleInformationModal', { static: true }) scheduleInformationModal: ModalComponent;
	@ViewChild('rescheduleInformationModal', { static: true }) rescheduleInformationModal: ModalComponent;
	view: CalendarPeriod = 'month';

	viewDate: Date = new Date();
	warningMessage = null;
	actions: CalendarEventAction[] = [
		{
			label: '<i class="fa fa-fw fa-pencil"></i>',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.handleEvent('Edited', event);
			}
		},
		{
			label: '<i class="fa fa-fw fa-times"></i>',
			onClick: ({ event }: { event: CalendarEvent }): void => {
				this.events = this.events.filter(iEvent => iEvent !== event);
				this.handleEvent('Deleted', event);
			}
		}
	];

	refresh: Subject<any> = new Subject();
	events: Array<CalendarEvent<{ orgCodeId: string, count: number, type: string }>> = [];
	localEvents: Array<CalendarEvent<{ orgCodeId: string, count: number, type: string }>> = [];
	activeDayIsOpen: boolean = false;
	selectedUsers: Array<any> = [];
	scheduledMode: string = null;
	scheduleAssessmentDate: string;
	isLoading = false;
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public selectedUserRole = [];
	isQueryAssessment: boolean = false;
	isAddAssessment: boolean = false;
	isAdminRole: boolean = false;
	constants: any;
	timeZone: any = '';
	knowledgeTestChecked: number = 0;

	constructor(private modal: NgbModal, private sharedService: SharedService, private userService: UserService, private assessmentService: AssessmentService, private alertServices: AlertService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		this.isQueryAssessment = this.userService.isCapabilityAssigned('QueryAssessment', this.selectedUserRole[0][1]);
		this.isAddAssessment = this.userService.isCapabilityAssigned('AddAssessment', this.selectedUserRole[0][1]);
		if (this.selectedUserRole[0][1] == 'Admin') {
			this.isAdminRole = true;
		} else {
			this.isAdminRole = false;
		}
		if (this.isQueryAssessment) {
			this.getCalendarDataByScheduleAssessmentDate();
			this.getCalendarDataByScheduleSelfAssessmentDate();
		}
		this.constants = Constants;
		this.timeZone = localStorage.getItem('TimeZone');
	}

	ngOnInit() {
		this.selectedUsers = this.assessmentService.getSelectedUsers();
		this.scheduledMode = this.assessmentService.getScheduleMode();
		if (this.selectedUsers.length > 0) {
			if (this.scheduledMode == 'Reschedule') {
				this.rescheduleInformationModal.open();
			} else {
				this.scheduleInformationModal.open();
			}
		}
	}

	getCalendarDataByScheduleSelfAssessmentDate() {
		this.assessmentService.getCalendarDataByScheduleSelfAssessmentDate(this.viewDate, this.isAdminRole)
			.subscribe(
			data => {
				for (var i = 0; i < data.data.length; i++) {
					let newDate = new Date(new Date(data.data[i].ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(data.data[i].ScheduleSelfAssessmentDate).getUTCMonth(), new Date(data.data[i].ScheduleSelfAssessmentDate).getUTCDate());
					// let date = moment(data.data[i].ScheduleSelfAssessmentDate);
					// date.tz(this.timeZone);
					// let localDate = date._d;
					// this.events.push({
					// 	start: startOfDay(newDate),
					// 	end: startOfDay(newDate),
					// 	title: data.data[i].Description + ' - ' + data.data[i].count,
					// 	color: colors.blue,
					// 	meta: {
					// 		orgCodeId: data.data[i].OrgCodeId,
					// 		count: data.data[i].count,
					// 		type: 'SelfAssessment'
					// 	}
					// });
					this.localEvents.push({
						start: startOfDay(newDate),
						end: endOfDay(newDate),
						title: data.data[i].Description + ' - ' + data.data[i].count,
						color: colors.blue,
						meta: {
							orgCodeId: data.data[i].OrgCodeId,
							count: data.data[i].count,
							type: 'SelfAssessment'
						}
					});
				}
				this.refresh.next();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	getCalendarDataByScheduleAssessmentDate() {
		this.assessmentService.getCalendarDataByScheduleAssessmentDate(this.viewDate, this.isAdminRole)
			.subscribe(
			data => {
				for (var i = 0; i < data.data.length; i++) {
					let newDate = new Date(new Date(data.data[i].ScheduleAssessmentDate).getUTCFullYear(), new Date(data.data[i].ScheduleAssessmentDate).getUTCMonth(), new Date(data.data[i].ScheduleAssessmentDate).getUTCDate());
					// let date = moment(data.data[i].ScheduleAssessmentDate);
					// date.tz(this.timeZone);
					// let localDate = date._d;
					// this.events.push({
					// 	start: startOfDay(newDate),
					// 	end: startOfDay(newDate),
					// 	title: data.data[i].Description + ' - ' + data.data[i].count,
					// 	color: colors.yellow,
					// 	meta: {
					// 		orgCodeId: data.data[i].OrgCodeId,
					// 		count: data.data[i].count,
					// 		type: 'Assessment'
					// 	}
					// });
					this.localEvents.push({
						start: startOfDay(newDate),
						end: endOfDay(newDate),
						title: data.data[i].Description + ' - ' + data.data[i].count,
						color: colors.yellow,
						meta: {
							orgCodeId: data.data[i].OrgCodeId,
							count: data.data[i].count,
							type: 'Assessment'
						}
					});
				}
				this.refresh.next();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
		this.selectedUsers = this.assessmentService.getSelectedUsers();
		this.alertServices.clear();
		this.warningMessage = null;
		if (this.selectedUsers.length > 0) {
			if (new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) < new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())) {
				this.alertServices.error(Constants.WRONG_ASSESSMENT_DATE);
			} else {
				this.scheduledMode = this.assessmentService.getScheduleMode();
				// let offset = moment.tz(this.timeZone).utcOffset();
				// this.scheduleAssessmentDate =  new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate())
				this.scheduleAssessmentDate = date.toLocaleDateString().replace(/[^ -~]/g, '');

				if (this.selectedUsers.length > 0) {
					if (this.scheduledMode == 'Reschedule') {
						let scheduleSelfAssessmentDate = this.assessmentService.getScheduledSelfAssessmentDate()
						if ((new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 7) > new Date(scheduleSelfAssessmentDate.getUTCFullYear(), scheduleSelfAssessmentDate.getUTCMonth(), scheduleSelfAssessmentDate.getUTCDate()))
							&& (new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 7) < new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 30))) {
							this.warningMessage = Constants.SCHEDULE_ASSESSMENT_WITH_WARNING;
						}
						this.rescheduleconfirmationModal.open();
					} else {
						if (new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - 7) < new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 30)) {
							this.warningMessage = Constants.SCHEDULE_ASSESSMENT_WITH_WARNING;
						}
						this.scheduleconfirmationModal.open();
					}
				} else {
					//do nothing
				}
			}
		}
	}

	scheduleAssessments() {
		this.isLoading = true;
		if (this.scheduledMode == 'Reschedule') {
			this.assessmentService.rescheduleAssessment(this.selectedUsers, this.scheduleAssessmentDate, this.isAdminRole)
				.subscribe(
				data => {
					this.assessmentService.setSelectedUsers([]);
					this.assessmentService.setScheduleMode(null);
					this.scheduleAssessmentDate = null;
					this.alertServices.clear();
					this.alertServices.success(Constants.SUCCESS_RESCHEDULE_ASSESSMENT);

					if (data.selfAssessmentNotChangedList.length > 0) {
						let msg = Constants.DATE_NOT_CHANGED_CANDIDATES + " ";
						for (var i = 0; i < data.selfAssessmentNotChangedList.length; i++) {
							msg += data.selfAssessmentNotChangedList[i];
							if (i != (data.selfAssessmentNotChangedList.length - 1)) {
								msg += ', ';
							}
						}
						this.alertServices.success(msg);
					}

					this.isLoading = false;
					this.events = [];
					this.localEvents = [];
					if (this.isQueryAssessment) {
						for (var i = 0; i < data.scheduleAssessmentData.length; i++) {
							let newDate = new Date(new Date(data.scheduleAssessmentData[i].ScheduleAssessmentDate).getUTCFullYear(), new Date(data.scheduleAssessmentData[i].ScheduleAssessmentDate).getUTCMonth(), new Date(data.scheduleAssessmentData[i].ScheduleAssessmentDate).getUTCDate());
							// let date = moment(data.scheduleAssessmentData[i].ScheduleAssessmentDate);
							// date.tz(this.timeZone);
							// let localDate = date._d;
							// this.events.push({
							// 	start: startOfDay(newDate),
							// 	end: startOfDay(newDate),
							// 	title: data.scheduleAssessmentData[i].Description + ' - ' + data.scheduleAssessmentData[i].count,
							// 	color: colors.yellow,
							// 	meta: {
							// 		orgCodeId: data.scheduleAssessmentData[i].OrgCodeId,
							// 		count: data.scheduleAssessmentData[i].count,
							// 		type: 'Assessment'
							// 	}
							// });
							this.localEvents.push({
								start: startOfDay(newDate),
								end: endOfDay(newDate),
								title: data.scheduleAssessmentData[i].Description + ' - ' + data.scheduleAssessmentData[i].count,
								color: colors.yellow,
								meta: {
									orgCodeId: data.scheduleAssessmentData[i].OrgCodeId,
									count: data.scheduleAssessmentData[i].count,
									type: 'Assessment'
								}
							});
						}
						for (var i = 0; i < data.selfAssessmentData.length; i++) {
							let newDate = new Date(new Date(data.selfAssessmentData[i].ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(data.selfAssessmentData[i].ScheduleSelfAssessmentDate).getUTCMonth(), new Date(data.selfAssessmentData[i].ScheduleSelfAssessmentDate).getUTCDate());
							// let date = moment(data.selfAssessmentData[i].ScheduleSelfAssessmentDate);
							// date.tz(this.timeZone);
							// let localDate = date._d;
							// this.events.push({
							// 	start: startOfDay(newDate),
							// 	end: startOfDay(newDate),
							// 	title: data.selfAssessmentData[i].Description + ' - ' + data.selfAssessmentData[i].count,
							// 	color: colors.blue,
							// 	meta: {
							// 		orgCodeId: data.selfAssessmentData[i].OrgCodeId,
							// 		count: data.selfAssessmentData[i].count,
							// 		type: 'SelfAssessment'
							// 	}
							// });
							this.localEvents.push({
								start: startOfDay(newDate),
								end: endOfDay(newDate),
								title: data.selfAssessmentData[i].Description + ' - ' + data.selfAssessmentData[i].count,
								color: colors.blue,
								meta: {
									orgCodeId: data.selfAssessmentData[i].OrgCodeId,
									count: data.selfAssessmentData[i].count,
									type: 'SelfAssessment'
								}
							});
						}
					}
					this.refresh.next();
				},
				error => {
					this.isLoading = false;
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			this.assessmentService.addAssessment(this.selectedUsers, this.scheduleAssessmentDate, this.isAdminRole, this.knowledgeTestChecked)
				.subscribe(
				data => {
					this.assessmentService.setSelectedUsers([]);
					this.scheduleAssessmentDate = null;
					this.alertServices.clear();
					this.alertServices.success(Constants.SUCCESS_SCHEDULE_ASSESSMENT);
					this.isLoading = false;
					this.events = [];
					this.localEvents = [];
					if (this.isQueryAssessment) {
						for (var i = 0; i < data.scheduleAssessmentData.length; i++) {
							let newDate = new Date(new Date(data.scheduleAssessmentData[i].ScheduleAssessmentDate).getUTCFullYear(), new Date(data.scheduleAssessmentData[i].ScheduleAssessmentDate).getUTCMonth(), new Date(data.scheduleAssessmentData[i].ScheduleAssessmentDate).getUTCDate());
							// let date = moment(data.scheduleAssessmentData[i].ScheduleAssessmentDate);
							// date = date.tz(this.timeZone);
							// let localDate = date._d;
							// this.events.push({
							// 	start: startOfDay(newDate),
							// 	end: startOfDay(newDate),
							// 	title: data.scheduleAssessmentData[i].Description + ' - ' + data.scheduleAssessmentData[i].count,
							// 	color: colors.yellow,
							// 	meta: {
							// 		orgCodeId: data.scheduleAssessmentData[i].OrgCodeId,
							// 		count: data.scheduleAssessmentData[i].count,
							// 		type: 'Assessment'
							// 	}
							// });
							this.localEvents.push({
								start: startOfDay(newDate),
								end: endOfDay(newDate),
								title: data.scheduleAssessmentData[i].Description + ' - ' + data.scheduleAssessmentData[i].count,
								color: colors.yellow,
								meta: {
									orgCodeId: data.scheduleAssessmentData[i].OrgCodeId,
									count: data.scheduleAssessmentData[i].count,
									type: 'Assessment'
								}
							});
						}
						for (var i = 0; i < data.selfAssessmentData.length; i++) {
							let newDate = new Date(new Date(data.selfAssessmentData[i].ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(data.selfAssessmentData[i].ScheduleSelfAssessmentDate).getUTCMonth(), new Date(data.selfAssessmentData[i].ScheduleSelfAssessmentDate).getUTCDate());
							// let date = moment(data.selfAssessmentData[i].ScheduleSelfAssessmentDate);
							// date = date.tz(this.timeZone);
							// let localDate = date._d;
							// this.events.push({
							// 	start: startOfDay(newDate),
							// 	end: startOfDay(newDate),
							// 	title: data.selfAssessmentData[i].Description + ' - ' + data.selfAssessmentData[i].count,
							// 	color: colors.blue,
							// 	meta: {
							// 		orgCodeId: data.selfAssessmentData[i].OrgCodeId,
							// 		count: data.selfAssessmentData[i].count,
							// 		type: 'SelfAssessment'
							// 	}
							// });

							this.localEvents.push({
								start: startOfDay(newDate),
								end: endOfDay(newDate),
								title: data.selfAssessmentData[i].Description + ' - ' + data.selfAssessmentData[i].count,
								color: colors.blue,
								meta: {
									orgCodeId: data.selfAssessmentData[i].OrgCodeId,
									count: data.selfAssessmentData[i].count,
									type: 'SelfAssessment'
								}
							});
						}
					}
					this.refresh.next();
				},
				error => {
					this.isLoading = false;
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		}
	}

	eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
		event.start = newStart;
		event.end = newEnd;
		this.handleEvent('Dropped or resized', event);
		this.refresh.next();
	}

	handleEvent(action: string, event: CalendarEvent): void {
		if (event.color.primary == "#1e90ff") {
			this.assessmentService.setAssessmentMode('SelfAssessment');
		} else {
			this.assessmentService.setAssessmentMode('Assessment');
		}
		this.assessmentService.setSelectedOrgCodeId(event.meta.orgCodeId);
		this.assessmentService.setAssessmentDate(event.start);
		this.selectedComponentItem("Assessments");
	}

	increment(): void {
		this.events = [];
		this.localEvents = [];
		if (this.isQueryAssessment) {
			this.getCalendarDataByScheduleAssessmentDate();
			this.getCalendarDataByScheduleSelfAssessmentDate();
		}
	}

	decrement(): void {
		this.events = [];
		this.localEvents = [];
		if (this.isQueryAssessment) {
			this.getCalendarDataByScheduleAssessmentDate();
			this.getCalendarDataByScheduleSelfAssessmentDate();
		}
	}

	today(): void {
		this.events = [];
		this.localEvents = [];
		if (this.isQueryAssessment) {
			this.getCalendarDataByScheduleAssessmentDate();
			this.getCalendarDataByScheduleSelfAssessmentDate();
		}
	}

	changeDate(date: Date): void {
		this.viewDate = date;
	}

	beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
		body.forEach(day => {
			day.badgeTotal = 0;
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	markKnowledgeTest(event: any) {
		if (event.target.checked) {
			this.knowledgeTestChecked = 1
		} else {
			this.knowledgeTestChecked = 0
		}
		console.log(this.knowledgeTestChecked)
	}

}
