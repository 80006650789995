import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { AssessmentService } from '../assessment.service';
import { UserService } from '../../user/user.service'
import * as Highcharts from 'highcharts';

@Component({
	selector: "app-knowledge-test-by-candidate",
	templateUrl: "./knowledgetestbycandidate.component.html",
})
export class KnowledgeTestByCandidateComponent implements OnInit {
	mode: any = 'tabular'
	public stortedUserData: any = localStorage.getItem('selectedUserRole');
	public userId: any = localStorage.getItem('userId');
	public selectedUserRole = [];
	isLoading: boolean = false
	supervisorId: any = null;
	public data: Array<any> = [];
	dataAvailable: boolean = false

	ngOnInit() {

	}

	constructor(private alertService: AlertService,
		private sharedService: SharedService,
		private assessmentService: AssessmentService,
		private userService: UserService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		if (this.stortedUserData) {
			this.selectedUserRole = JSON.parse(this.stortedUserData);
			if (this.selectedUserRole[0][1] == 'Supervisor') {
				this.supervisorId = localStorage.getItem('userId');
			}
		}
		this.isLoading = true;
		this.dataAvailable = false

		this.assessmentService.getKnowledgeTestByCandidate(this.supervisorId, null, null)
			.subscribe(
			data => {
				this.data = [];
				for (var i = 0; i < data.assessments.length; i++) {
					let score = 0;
					if (data.assessments[i].SelfAssessmentCorrect > 0 && data.assessments[i].SelfAssessmentTotal > 0) {
						score = Math.round(((data.assessments[i].SelfAssessmentCorrect) / data.assessments[i].SelfAssessmentTotal) * 100)
					}
					let completedDate: Date = null
					if (data.assessments[i].SelfAssessmentCompletedDate == null) {
						completedDate = null
					} else {
						completedDate = new Date(new Date(data.assessments[i].SelfAssessmentCompletedDate).getUTCFullYear(), new Date(data.assessments[i].SelfAssessmentCompletedDate).getUTCMonth(), new Date(data.assessments[i].SelfAssessmentCompletedDate).getUTCDate());
					}
					let orgCode = data.assessments[i].OrgCode
					if (data.assessments[i].AssessmentStatus == 'Completed' || data.assessments[i].IsKnowledgeTest == 1) {
						orgCode = data.assessments[i].AssessmentOrgCode
					}

					this.data.push({
						"firstName": data.assessments[i].FirstName,
						"lastName": data.assessments[i].LastName,
						"jobProfile": data.assessments[i].JobProfile,
						"orgCode": orgCode,
						"correct": data.assessments[i].SelfAssessmentCorrect,
						"total": data.assessments[i].SelfAssessmentTotal,
						"completedDate": completedDate,
						"score": score,
						"competencyScore": data.assessments[i].AssessmentScore
					});
				}

				this.isLoading = false;
				this.dataAvailable = true

			},
			error => {
				this.isLoading = false;
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	changeMode(param: any) {
		this.mode = param;
	}


}

