import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { UserService } from '../user.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
// import { Select2OptionData } from 'ng2-select2';
import { CustomModalService } from '../../util/custommodal/custommodal.service';

@Component({
	selector: 'app-assignuserrole',
	templateUrl: './assignuserrole.component.html'
})

export class AssignUserRoleComponent implements OnInit {
	assignUserRoleForm: FormGroup;
	userRoleList: any[] = [];
	selectedUserId: string;
	isDataAvailable: boolean = false;
	roleUpdate: boolean = false;
	// public userList: Array<Select2OptionData> = [];
	public userList: Array<any> = [];
	// public options: Select2Options = {
	// 	theme: 'classic'
	// };
	public count = 0;
	constructor(private customModalService: CustomModalService, private formBuilder: FormBuilder, private router: Router, private userServices: UserService,
		private alertServices: AlertService, private sharedService: SharedService) {
		this.userServices.getAllUsers()
			.subscribe(
			data => {
				this.userList = [];
				// this.userList.push({ id: "default", text: "--select user--" });
				for (var i = 0; i < data.userlist.length; i++) {
					this.userList.push({ id: data.userlist[i].Id, text: data.userlist[i].FirstName + " " + data.userlist[i].LastName + " - " + data.userlist[i].OrgCode });
				}

			},
			error => {
				this.alertServices.clear();
				this.alertServices.error(error);
			}
			);
	}

	public selectedUser(value: any) {
		this.userServices.getRolesAssignedToUser(value.id)
			.subscribe(
			data => {
				this.isDataAvailable = true;
				let control = <FormArray>this.assignUserRoleForm.controls['roles'];
				control.controls = []
				this.userRoleList = []
				for (var i = 0; i < data.userroles.length; i++) {
					let access = false;
					if (data.userroles[i].RoleId != null) {
						this.userRoleList.push(i);
						access = true;
					}
					control.push(this.formBuilder.group({
						id: [data.userroles[i].Id, Validators.required],
						role: [data.userroles[i].Description, Validators.required],
						access: [access]
					}));
				}
			},
			error => {
				this.alertServices.clear();
				this.alertServices.error(error);
			}
			);
	}

	public removedUser(value: any) {
		let control = <FormArray>this.assignUserRoleForm.controls['roles'];
		control.controls = []
		this.isDataAvailable = false;
	}

	// public changed(e: any) {
	// 	this.assignUserRoleForm = this.formBuilder.group({
	// 		userId: new FormControl(null, Validators.required),
	// 		roles: this.formBuilder.array([
	// 		])
	// 	});
	// 	this.assignUserRoleForm.setValue({ userId: e.value, roles: [] });
	// 	if (e.value === "default") {
	// 		this.userRoleList = [];
	// 		this.isDataAvailable = false;
	// 	} else {
	// 		this.userServices.getRolesAssignedToUser(e.value)
	// 			.subscribe(
	// 			data => {
	// 				this.isDataAvailable = true;
	// 				let control = <FormArray>this.assignUserRoleForm.controls['roles'];
	// 				for (var i = 0; i < data.userroles.length; i++) {
	// 					let access = false;
	// 					if (data.userroles[i].RoleId != null) {
	// 						access = true;
	// 					}
	// 					control.push(this.formBuilder.group({
	// 						id: [data.userroles[i].Id, Validators.required],
	// 						role: [data.userroles[i].Description, Validators.required],
	// 						access: [access]
	// 					}));
	// 				}

	// 			},
	// 			error => {
	// 				this.alertServices.clear();
	// 				this.alertServices.error(error);
	// 			}
	// 			);
	// 	}
	// }

	ngOnInit() {
		this.assignUserRoleForm = this.formBuilder.group({
			userId: new FormControl(null, Validators.required),
			roles: this.formBuilder.array([
			])
		});

	}

	ngAfterViewInit() {

	}

	getFormData() {
		return <FormArray>this.assignUserRoleForm.get('roles');
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	public assigneRole(e: any, i: number) {
		let userId = this.assignUserRoleForm.value.userId[0].id;
		if (!e.target.checked && (this.assignUserRoleForm.controls.roles.value[i].role === "Supervisor" || this.assignUserRoleForm.controls.roles.value[i].role === "Assessor")) {
			let itemType = "Role_" + this.assignUserRoleForm.controls.roles.value[i].role;
			this.customModalService.invoke(
				itemType,
				userId,
				this.assignUserRoleForm.controls.roles.value[i],
				"delete", e.target
			);
		}
	}

	onSubmit() {
		this.roleUpdate = false;
		let userId = this.assignUserRoleForm.value.userId[0].id;
		let roles = <FormArray>this.assignUserRoleForm.controls['roles'];
		let selectedRoles = [];
		let roleData: any[] = [];		
		for (var i = 0; i < roles.length; i++) {
			let access = this.assignUserRoleForm.controls.roles.value[i].access === true ? 1 : 0;			
			if (access == 1) {
				if (this.userRoleList.indexOf(i) == -1) {
					this.roleUpdate = true;
				}
				let roleId = this.assignUserRoleForm.controls.roles.value[i].id;
				roleData.push({ 'roleId': roleId });
				selectedRoles.push(i);
			}
			else {
				if (this.userRoleList.indexOf(i) != -1) {
					this.roleUpdate = true;
				}
			}
		}
		if (this.userRoleList.length > 0 && this.roleUpdate && roleData.length == 0) {
			this.alertServices.clear();
			this.userServices.setRolesAssignedToUser(userId, roleData)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully remove the role(s).")
					this.userRoleList = selectedRoles;
				},
				error => {
					this.alertServices.clear();
					this.alertServices.error(error);
				}
				);
			this.roleUpdate = false;
		}
		else if (!this.roleUpdate) {
			this.alertServices.clear();
			this.alertServices.error("Please select role(s) to assigned. There are no changes to update.");
		}
		else if (roleData.length > 0 && this.roleUpdate) {
			this.userServices.setRolesAssignedToUser(userId, roleData)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully updated the selected role(s).");
					this.userRoleList = selectedRoles;
				},
				error => {
					this.alertServices.clear();
					this.alertServices.error(error);
				}
				);
		}
	}

}
