<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Add New Org Type</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr/>
</div>


<div class="container">
	<form [formGroup]="addOrgtypeForm">
		<!--org type-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Type
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="text" class="form-control" id="orgtype" formControlName="orgtype" name="orgtype">
			</div>
		</div>

		<alert></alert>

		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-3">
			</div>
			<!-- <div class="col-sm-3">
                <button type="submit" class="btn btn-warning btn-block" [disabled]="!addOrgtypeForm.valid" >Save</button>
				</div>
            <div class="col-sm-3">
                <button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('OrgType')" >Cancel</button>
				</div> -->
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!addOrgtypeForm.valid" (click)="save()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!addOrgtypeForm.valid" (click)="saveAndNew()">Save & New</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
			</div>
		</div>
	</form>
</div>