<div>
  <div class="table-responsive mb-2">
    <ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
      class="table-sm">
    </ng-table>
  </div>
  <pagination *ngIf="config.paging" class="pagination-sm mt-2" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
    [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
  </pagination>
  <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
</div>