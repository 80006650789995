import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { PerformanceTagService } from './performancetag.service';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';
import { CustomModalService } from '../../util/custommodal/custommodal.service';

@Component({
	selector: 'app-performancetag',
	templateUrl: './allperformancetag.component.html'
})

export class AllPerformanceTagComponent extends TableComponent {
	selectedPerformancetagId: string;
	selectedPerformanceStatus: number;
	newStatus: string;

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	private isDeleteCC: boolean;
	private isModifyCC: boolean;
	isAddCC: boolean;
	private isQueryCC: boolean;
	showHideForm: FormGroup;
	checked: boolean = false;

	public rows: Array<any> = [];
	public columns: Array<any> = [
		{ title: 'Performance Tag', name: 'performanceTag', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'statusbutton', className: 'accepter-col-action' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];
	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	constructor(private customModalService: CustomModalService, private router: Router, private userService: UserService, private sharedService: SharedService, private alertServices: AlertService, private performanceTagService: PerformanceTagService) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		// console.log('in constructor');

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddCC = this.userService.isCapabilityAssigned('AddTag', this.selectedUserRole[0][1]);
		this.isModifyCC = this.userService.isCapabilityAssigned('ModifyTag', this.selectedUserRole[0][1]);
		this.isDeleteCC = this.userService.isCapabilityAssigned('DeleteTag', this.selectedUserRole[0][1]);
		this.isQueryCC = this.userService.isCapabilityAssigned('QueryTag', this.selectedUserRole[0][1]);
	}
	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		//load data after check the permissions
		if (this.isQueryCC) {
			this.loadAllShowPerformanceTags();
		} else {
			//do nothing
		}

		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		// console.log('[onCellClick] (selectedRow) : ' + JSON.stringify(data.row.id));
		this.selectedPerformancetagId = data.row.id;
		this.selectedPerformanceStatus = data.row.status;
		this.newStatus = this.selectedPerformanceStatus == 1 ? "disable" : "enable";
		switch (data.column) {
			case 'editbutton':
				if (this.isModifyCC) {
					this.editButtonClickEvent(data.row.id, data.row.performanceTag);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				if (this.isDeleteCC) {
					this.deleteButtonClickEvent(this.selectedPerformancetagId);
				} else {
					//do nothing
				}
				break;
			case 'statusbutton':
				if (this.isModifyCC) {
					if (data.row.showHide == 0) {
						// do nothing
					} else {
						this.statusButtonClickEvent(this.selectedPerformancetagId, this.selectedPerformanceStatus);
					}
				} else {
					//do nothing
				}
				break;
			case "showhidebutton":
				//check modify capability
				if (this.isModifyCC) {
					if (data.row.status == 0 || data.row.showHide == 0) {
						this.showHideButtonClickEvent(data.row.id, data.row.showHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	private deleteButtonClickEvent(id: string) {
		let itemType = "Performance Tag";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete",
			null
		);
	}

	private delete() {
		this.performanceTagService.deletePerformanceTag(this.selectedPerformancetagId)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully removed the performance tag.");
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	private editButtonClickEvent(compTagId: string, performanceTag: string) {
		this.performanceTagService.setCurretData(compTagId, performanceTag);
		this.sharedService.change('EditPerformanceTag');
	}

	private statusButtonClickEvent(id: string, status: number) {
		let itemType = "Performance Tag";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private changeStatus(compTagId: string, tagStatus: number) {
		this.performanceTagService.changePerformanceTagStatus(compTagId, tagStatus == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			//	console.log(this.data[i].Status);
			//check user modify capability
			if (this.isModifyCC) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				if (this.data[i].status == 1) {
					this.data[i].statusbutton = '<a class="btn btn-sm " style="cursor: pointer"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					if (this.data[i].showHide == 0) {
						this.data[i].statusbutton = '<a class="btn btn-sm disabled" ><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].statusbutton = '<a class="btn btn-sm " style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}

				}
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				if (this.data[i].status == 1) {
					this.data[i].statusbutton = '<a class="btn btn-sm disabled"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					if (this.data[i].showHide == 0) {
						this.data[i].statusbutton = '<a class="btn btn-sm disabled"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].statusbutton = '<a class="action-btn " style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				}
			}


			if (this.isDeleteCC) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.data[i].showHide == 1) {
				if (this.data[i].status == 0) {
					if (this.isModifyCC) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyCC) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}

		}
	}


	private loadAllPerformanceTags() {
		this.performanceTagService.getAllPerformanceTag()
			.subscribe(
			data => {
				this.data = [];
				//console.log('[loadAllPerformanceTags] ' + JSON.stringify(data).toString());
				for (let i = 0; i < data.cctagList.length; i++) {
					this.data.push({
						'id': data.cctagList[i].Id,
						'performanceTag': data.cctagList[i].Description,
						'status': data.cctagList[i].Status,
						'showHide': data.cctagList[i].ShowHide,
						'statusbutton': '', 'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});

				}
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	private loadAllShowPerformanceTags() {
		this.performanceTagService.getAllShowPerformanceTag()
			.subscribe(
			data => {
				this.data = [];
				//console.log('[loadAllPerformanceTags] ' + JSON.stringify(data).toString());
				for (let i = 0; i < data.cctagList.length; i++) {
					this.data.push({
						'id': data.cctagList[i].Id,
						'performanceTag': data.cctagList[i].Description,
						'status': data.cctagList[i].Status,
						'showHide': data.cctagList[i].ShowHide,
						'statusbutton': '', 'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});

				}
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	showHide(event) {		
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryCC) {
				this.loadAllPerformanceTags();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowPerformanceTags();
		}
	}

	loadData() {
		if (this.checked) {
			this.loadAllPerformanceTags();
		}
		else {
			this.loadAllShowPerformanceTags();
		}
	}
	
	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Performance Tag";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	public showHideEvent(performanceId: string, showHide: number) {
		this.performanceTagService.changePerformanceTagShowHide(performanceId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}


}
