<div class="col-lg-12">
    <div class="col" style="overflow:hidden">
        <div class="float-left">
            <h4>Add New Task Tag</h4>
        </div>
        <div class="float-right">

        </div>
    </div>
    <hr />
</div>


<div class="col-lg-12 mx-auto">
    <form [formGroup]="addTaskTag" >        
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Task Tag <sup><i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i></sup></label>
            <div class="col-sm-10">
                <textarea noSpaces maxlength="100" autosize type="text" class="form-control" id="taskTag" placeholder="Task Tag" formControlName="taskTag" name="taskTag"></textarea>
            </div>
        </div>        
        <alert></alert>
        <!--buttons-->
        <div class="form-group row">
            <div class="col-sm-3">
            </div>
            <!-- <div class="col-sm-3">
                <button type="submit" class="btn btn-warning btn-block" [disabled]="!addTaskTag.valid">Save</button>
            </div>
            <div class="col-sm-3">
                <button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('AllTaskTag')">Cancel</button>
				</div> -->
				<div class="col-sm-3">
					<button type="submit" class="btn btn-warning btn-block" [disabled]="!addTaskTag.valid" (click)="save()">Save</button>
				</div>
				<div class="col-sm-3">
					<button type="submit" class="btn btn-warning btn-block" [disabled]="!addTaskTag.valid" (click)="saveAndNew()">Save & New</button>
				</div>
				<div class="col-sm-3">
					<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
				</div>
        </div>
    </form>
</div>