import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class CoreCompetencyTagService {
	public curCoreCompId: string = null;
	public curCoreCompetency: string = null;

	constructor(private http: Http, private router: Router) { }

	addCoreCompetencyTag(coreCompetencyTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'coreCompetencyTag': coreCompetencyTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/corecomptag/addCoreCompetencyTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editCoreCompetencyTag(compTagId: string, coreCompetencyTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'coreCompetencyTag': coreCompetencyTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/corecomptag/editCoreCompetencyTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeCoreCompetencyTagStatus(compTagId: string, tagStatus: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'tagStatus': tagStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/corecomptag/changeCoreCompetencyTagStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllCoreCompetencyTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/corecomptag/getAllCoreCompetencyTag' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveCoreCompetencyTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/corecomptag/getActiveCoreCompetencyTag' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteCoreCompetencyTag(compTagId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/corecomptag/removeCoreCompetencyTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setCurretData(compTagId: string, coreCompetencyTag: string) {
		this.curCoreCompId = compTagId;
		this.curCoreCompetency = coreCompetencyTag;
	}

	getAllShowCoreCompetencyTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/corecomptag/getAllShowCoreCompetencyTag' + token, { headers: headers })
			.map(res => res.json());
	}

	changeCCTagShowHide(ccId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'ccId': ccId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/corecomptag/changeCCTagShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}


}
