<div class="col-lg-12">
	<div class="col">
		<div class="float-left">
			<h4>Dashboard</h4>
		</div>
		<div class="float-right">
			<div class="float-left">
				<!-- <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 31%; left: -25%;" [message]="resetMessage" [side]="'apSouthWest'"></app-overlay-tooltip> -->
				<button #resetBtn type="button" class="btn btn-outline-warning btn-sm pointer-cursor m-1" (click)="resetDashBoard()">
					<i class="fa fa-window-restore fa-fw mr-1"></i>Reset Dashboard
				</button>
				<!-- <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 31%; left: 1%;" [message]="saveMessage" [side]="'apSouthEast'"></app-overlay-tooltip> -->
				<button type="button" class="btn btn-outline-warning btn-sm pointer-cursor m-1" (click)="saveDashboard()">
					<i class="fa fa-floppy-o fa-fw mr-1"></i>Save Dashboard
				</button>
				<!-- <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 31%; left: 13%;" [message]="addNewMessage" [side]="'apSouthEast'"></app-overlay-tooltip> -->
				<button type="button" class="btn btn-outline-warning btn-sm pointer-cursor m-1" (click)="showWidgetsList()">
					<i class="fa fa-plus-square fa-fw mr-1"></i>Add New Widget
				</button>
				<div *ngIf="showOverlay" style="position: relative; width: 100%; z-index: 1090; border: 1px solid #FAFAFA;">
						<app-overlay-tooltip *ngIf="showOverlay" style="position: absolute; top: -12px; left: -153px;" [message]="manageBtnMessage"
						[side]="'apSouthWest'"></app-overlay-tooltip>
				</div>
			</div>
			<button *ngIf="userRoleId[0][1] == 'Candidate'" type="button" class="btn btn-outline-warning btn-sm pointer-cursor m-1" (click)="showHelp()">
				<i class="fa fa-question-circle fa-fw mr-1"></i>Help
			</button>
		</div>
	</div>
	<div class="col" style="overflow:hidden">
		<div class="col-lg-6 float-right">
			<div *ngIf="userRoleId[0][0] == '1'">
				<app-assessmentprogress></app-assessmentprogress>
			</div>
		</div>
		<div class="col-lg-6 float-left">
			<div *ngIf="userRoleId[0][0] == '1'">
				<app-upcomingAssessment></app-upcomingAssessment>
			</div>
		</div>
	</div>
	<hr class="mt-2" />
</div>

<div class="col-lg-12 mx-auto">
	<alert></alert>
	<div class="alert alert-danger" *ngIf="this.userRoleId[0][0] == null">{{errorNoUserRole}}
	</div>
	<div class="container-fluid">
		<div class="row d-block" [ngClass]="{'none-transparent-div': showWidgetPanel, 'transparent-div': !showWidgetPanel}">
			<div *ngFor="let widget of displayWidgetsList; let i = index" class="{{ widget.size }} mt-1 mb-1" [ngClass]="{'float-right': widget.componentId == 9, 'float-left': widget.componentId != 9}">
				<div class="panel panel-default" *ngIf="widget.checked">
					<div class="panel-heading">
						<div class="row">
							<div class="col-sm-9">
								<i class="fa fa-fw {{ widget.componentIcon }} mr-2"></i>
								<b>{{ widget.componentName }}</b>
							</div>
							<div class="col-sm-3 float-right">
								<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor" (click)="removeWidget(widget);">
									<i class="fa fa-times" aria-hidden="true">
										<app-overlay-tooltip *ngIf="showOverlay && i == 1" style="position: relative; top: 5px; left: -82px;" [message]="widgetCloseMessage"
										 [side]="'apSouth'"></app-overlay-tooltip>
									</i>
								</button>
								<button type="button" class="btn btn-sm btn-outline-dark float-right pl-1 pr-1 pt-0 pb-0 ml-1 mr-1 mb-1 pointer-cursor" (click)="minimizeWidget(widget);">
									<i class="fa" [ngClass]="{'fa-chevron-down': widget.minimized, 'fa-chevron-up': !widget.minimized}" aria-hidden="true">
										<app-overlay-tooltip *ngIf="showOverlay && i == 1" style="position: relative; top: -25px; left: -330px;" [message]="widgetMinimizeMessage"
										 [side]="'apWest'"></app-overlay-tooltip>
									</i>
								</button>
							</div>
						</div>
					</div>
					<div class="card-block card-body pb-1 pt-1" [ngClass]="{'div-hide': widget.minimized, 'div-show': !widget.minimized}">
						<div #widgetBody></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<modal #selectWidgetsModal [keyboard]="false" [backdrop]="'static'" style="z-index: 1170">
	<modal-header [show-close]="false">
		<h4 class="modal-title">Add New Widgets</h4>
	</modal-header>
	<modal-body>
		<div class="row">
			<div class="col-lg-10 mx-auto" *ngFor="let widget of widgetList">
				<div class="input-group">
					<span class="input-group-addon ">
						<input type="checkbox" aria-label="Checkbox for following text input" value="{{widget.componentId}}" [(ngModel)]="widget.checked">
					</span>
					<label class="form-control" aria-label="Text input with checkbox"> {{ widget.componentName }} </label>
				</div>
				<br>
			</div>
		</div>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="getSelectedWidgets()">OK</button>
	</modal-footer>
</modal>