export class AssessmentModel {
	constructor(
		public id: string,
		public assessorId?: string,
		public assessorName?: string,
		public firstName?: string,
		public lastName?: string,

		public assessorPkgId?: string,
		public selfAssessmentId?: string,
		public selfAssessmentAnswerId?: string,

		public userId?: string,
		public userName?: string,
		public userContractStatus?: any,
		public userEmail?: string,

		public supervisorId?: string,
		public supervisorName?: string,

		public orgId?: string,
		public orgCode?: string,

		public jobProfileId?: string,
		public jobProfileDesc?: any,
		public jobProfile?: any,

		public disciplineId?: string,
		public discipline?: string,

		public assessmentCompletedDate?: Date,
		public selfAssessmentCompletedDate?: Date,
		public scheduleAssessmentDate?: Date,
		public scheduleSelfAssessmentDate?: Date,
		public assessmentDate?: Date,

		public assessorStatus?: string,
		public selfAssessmentStatus?: string,
		public selfAssessmentAnswerKeyStatus?: string,

		public status?: string,
		public selfAssStatus?: string,
		public opportunityList?: any,

		public candidateStrengths?: string,

		public totalItems?: number,
		public answeredItems?: number,
		public progress?: number,
		public assessmentScore?: number,
		public selfAssessmentProgress?: number,

		public lockedBy?: string,
		public assessorCompleted?: number,
		public candidateSignature?: number,
		public assessorSignature?: number,

		public isKnowledgeTest?: number
	) { }
}
