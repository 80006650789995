<div *ngIf="sizeOfData != 0" class="bs-callout bs-callout-info">
	<h6 class="mb-1">Candidate Assessments</h6>
	<p class="mt-3 mb-1"> {{ description1 }} </p>
	<p class="mt-1 mb-2" *ngIf="assessorCompleted == 0">
		<a class="pointer-cursor" *ngIf="selfAssessmentStatus != 'Completed'">
			<strong><u class="link-click" (click)="directToSelfAssessment()">Knowledge Check</u></strong>
		</a>

		<a *ngIf="selfAssessmentStatus == 'Completed'">
			Knowledge Check
		</a> {{ description2 }}
		<!-- <button type="button" class="btn btn-outline-warning btn-sm" (click)="directToSelfAssessment()" *ngIf="selfAssessmentStatus!='Completed'">Self Assessment</button> -->
	</p>
	<!-- <a class="pointer-cursor" *ngIf="selfAssessmentStatus == 'Completed'">
		<strong><u class="link-click" (click)="directToSelfAssessmentReview()">Self assessment review</u></strong>
	</a> -->
	<button *ngIf="selfAssessmentStatus == 'Completed'" type="button" class="btn btn-info btn-sm pointer-cursor px-3 py-2 mr-1" (click)="directToSelfAssessmentReview()">
		Knowledge Check Review</button>
	<button *ngIf="candidateSignature == 0 && assessorCompleted == 1" type="button" class="btn btn-info btn-sm pointer-cursor px-3 py-2"
	 (click)="directToSignaturePage()">
		Signature</button>
	<br *ngIf="selfAssessmentStatus != 'Completed'">
</div>