import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { discipline } from './discipline';

@Injectable()
export class DisciplineService {

	constructor(private http: Http, private router: Router) { }

	private SelectedDisciplineForEdit: discipline;

	public setSelectedDisciplineForEdit(discipline: discipline): void {
		this.SelectedDisciplineForEdit = discipline;
	}

	public getSelectedDisciplineForEdit(): discipline {
		return this.SelectedDisciplineForEdit;
	}

	// Get all posts from the API
	getDiscipline() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/getDiscipline' + token, { headers: headers })
			.map(res => res.json());
	}

	//change discipline status
	changeStatus(id: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'id': id, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/changeStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add discipline
	addDiscipline(discipline: discipline) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(discipline);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/addDiscipline' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update discipling
	updateDisciplingById(discipline: discipline) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(discipline);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/updateDisciplingById' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get active disciplines
	getActiveDisciplines() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/getActiveDisciplines' + token, { headers: headers })
			.map(res => res.json());
	}

	//delete discipline
	deleteDiscipline(Id: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'Id': Id });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/deleteDiscipline' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getShowDiscipline() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/getShowDiscipline' + token, { headers: headers })
			.map(res => res.json());
	}

	changeDisciplineShowHide(disciplineId: string, showhide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'disciplineId': disciplineId, 'showhide': showhide});
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/discipline/changeDisciplineShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}
}