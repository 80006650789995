<!--component html goes here -->
<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Job Profile</h4>
		</div>
		<div class="float-right">
			<div class="row">
				<form [formGroup]="showHideForm" class="mr-2 ml-1">
					<div class="col-sm- 12">
						<input class="mr-1" (change)="showHide($event)" [checked]="checked==true" type="checkbox" id="showHide" name="showHide"> Show hidden
					</div>
				</form>
				<button type="button" class="btn btn-outline-warning btn-sm ml-1" (click)="selectedComponentItem('AddJobProfile')" [disabled]="!isAddJobProfile">
					<i class="fa fa fa-plus fa-fw"></i>&nbsp;Add New Job Profile
				</button>
			</div>
		</div>
	</div>
	<hr />
</div>

<div class="col-lg-12 mx-auto">
	<alert></alert>
	<div class="table-responsive mb-2">
		<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
		 class="table-sm" style="cursor: pointer;">
		</ng-table>
	</div>
	<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
	 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
	</pagination>
	<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
	<div *ngIf=isLoading class="loading"></div>
</div>

<modal #changeStatusErrorModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Cannot change the status</h4>
	</modal-header>
	<modal-body>
		Status change is not allowed. Please check the following,
		<div class="alert alert-info mt-2 mb-2 p-2">
			<pre class="m-0 p-0">{{statusErrorMsg.trim()}}</pre>
		</div>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeStatusErrorModal.dismiss()">OK</button>
	</modal-footer>
</modal>