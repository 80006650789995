import { Component, OnInit } from "@angular/core";

import { ReportTableComponent } from "../reporttable.component";

import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { Report } from "../report.model";

@Component({
	selector: "app-supervisor-report",
	templateUrl: "./allsupervisorreport.component.html"
})
export class AllSupervisorReportComponent extends ReportTableComponent
	implements OnInit {
	public report: Report;
	public excelData: any[];
	public columns: Array<any> = [
		{
			title: "First Name",
			name: "First Name",
			filtering: { filterString: "" }
		},
		{
			title: "Last Name",
			name: "Last Name",
			filtering: { filterString: "" }
		},
		{
			title: "Contractor",
			name: "Contractor",
			filtering: { filterString: "" }
		},
		{
			title: "Email",
			name: "Email",
			filtering: { filterString: "" }
		},
		{
			title: "Org Code",
			name: "Org Code",
			filtering: { filterString: "" }
		},
		{
			title: "Job Profile",
			name: "Job Profile",
			filtering: { filterString: "" }
		},
		{
			title: "Time Zone",
			name: "Time Zone",
			filtering: { filterString: "" }
		}
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	private serverResultData: Array<any> = [];

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
	}

	ngOnInit() {
		this.loadAllSupervisors(this.report.reportData);
	}

	loadAllSupervisors(data: any) {
		this.excelData = [];
		for (let i = 0; i < data.length; i++) {
			this.data.push({
				"First Name": data[i].FirstName,
				"Last Name": data[i].LastName,
				"Contractor": data[i].IsContract == 0 ? "No" : "Yes",
				"Email": data[i].Email,
				"Org Code": data[i].OrgCode == null ? "" : data[i].OrgCode,
				"Job Profile": data[i].JobProfile == null ? "" : data[i].JobProfile,
				"Time Zone": data[i].TimeZone == null ? "" : data[i].TimeZone
			});

			// this.excelData.push({
			//   "First Name": data[i].FirstName,
			//   "Last Name": data[i].LastName,
			//   "Email": data[i].Email,
			//   "Org Code": data[i].OrgCode == null ? "" : data[i].OrgCode,
			//   "Job Profile": data[i].JobProfile == null ? "" : data[i].JobProfile,
			//   "Time Zone": data[i].TimeZone == null ? "" : data[i].TimeZone
			// });
		}
		this.serverResultData = data;
		//  this.report.reportDataList = this.excelData;
		this.report.columns = this.columns;
		this.config.report = this.report;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.onChangeTable(this.config);
	}

	public onCellClick(data: any): any { }
}
