<div class="pos-f-t fixed-top header" style="border-bottom: 1px solid #ddd;">
  <!--<nav class="navbar navbar-inverse bg-inverse navbar-toggleable-md">
    <div class="navbar-toggler navbar-toggler-right" style="margin-right:0px; padding-top:0px; padding-bottom:3px; padding-left:0px; padding-right:0px;">
      <ul class="navbar-nav-toggler ml-auto mt-md-0">
        <li class="nav-item dropdown" ngbDropdown>
          <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
						<i class="fa fa-user"></i>
					</button>
          <div class="dropdown-menu dropdown-menu-right">
            <button class="dropdown-item capitalize-text" [disabled]="true">
							<i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</button>
            <button class="dropdown-item" [disabled]="true">
							<i class="fa fa-fw fa-group"></i> Switch User Roles</button>
            <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
							<i class="fa fa-fw fa-power-off"></i> Log Out</a>
            <hr>
            <a class="dropdown-item">
							<i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
          </div>
        </li>
      </ul>
    </div>
    <a class="navbar-brand" href="javascript:void(0)">
			<img [src]="logoSrc" />
		 </a>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto mt-md-0">
        <li class="nav-item dropdown" ngbDropdown>
          <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
						<i class="fa fa-user"></i>
						
          <div class="dropdown-menu dropdown-menu-right">
            <button class="dropdown-item" [disabled]="true">
							<i class="fa fa-fw fa-user"></i>{{userFirstName}}'s Profile</button>
            <button class="dropdown-item" [disabled]="true">
							<i class="fa fa-fw fa-group"></i> Switch User Roles</button>
            <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
							<i class="fa fa-fw fa-power-off"></i> Log Out</a>
            <hr>
            <a class="dropdown-item">
							<i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>-->
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="navbar-brand">
      <img [src]="logoSrc" />
    </div>
    

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <form class="form-inline my-2 my-lg-0">

        <!--<a class="nav-link " href="#" id="navbarDropdown" role="button">-->
        <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0 my-2 my-sm-0 pl-3 pr-3" id="navbarDropdown" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user">
              <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: -16px; left: -330px;" [message]="profileBtnMessage"
                [side]="'apWest'"></app-overlay-tooltip> 
            </i>            
          </button>
        <!--</a>-->
        <div class="dropdown-menu dropdown-menu-right mr-3" style="top:78%" aria-labelledby="navbarDropdown">
          <button class="dropdown-item capitalize-text" [disabled]="true">
              <i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</button>
          <button class="dropdown-item" id="showUserrole" [disabled]="true">
              <i class="fa fa-fw fa-group"></i> Switch User Roles</button>
          <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
              <i class="fa fa-fw fa-power-off"></i> Log Out</a>
          <hr>
          <a class="dropdown-item">
              <i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
        </div>
      </form>
    </div>
  </nav>
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="main-container mt-3 m-1">
  <!-- <div class="col-lg-12 mt-1">
		<div class="col" style="overflow:hidden">
			<div class="float-left">
				<h4>Self Assessment</h4>
			</div>
		</div>
	</div> 
	<hr/> -->
  <alert></alert>
  <div class="col-lg-12 mx-auto pb-2">
    <div class="row" *ngIf="assessmentCompleted">
      <div class="col-sm-12 mb-2 mx-auto">
        <h4 class="display-4 ml-3 text-center">You answered {{score}}% correct.</h4>
      </div>
      <div class="col-sm-12 mb-3 mt-2 text-center">
        <button type="button" [disabled]="!isMissedKnowledgeAvailable" class="btn btn-warning btn-lg ml-2 mr-2 mb-1 pl-5 pr-5" (click)="viewMissedKnowledge()">Review Missed Knowledge</button>
        <button type="button" class="btn btn-warning btn-lg ml-2 mr-2 mb-1 pl-5 pr-5" (click)="returnToDashBoard()">Return to Dashboard</button>
      </div>
    </div>
    <div class="row" *ngIf="ccList.length > 0 && !showKnowledge && !assessmentCompleted">
      <div class="col-sm-12 mb-2">
        <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 83%; left: 82%;" [message]="sACompletedBtn" [side]="'apSouthWest'"></app-overlay-tooltip>
        <button type="button" class="btn float-right btn-lg ml-1 mb-1" [disabled]="!isCCCompleted" (click)="completeSelfAssessment()"
          [ngClass]="{'btn-warning': isCCCompleted, 'btn-secondary': !isCCCompleted}">
					Complete Knowledge Check
				</button>
        <button type="button" class="btn float-right btn-warning btn-lg ml-2 mr-2 mb-1 pl-5 pr-5" (click)="returnToDashBoard()">Return to Dashboard</button>
      </div>
      <div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of ccList; let k = index">
        <ng-container *ngIf="k == 0; else elseCCItemTemplate">
          <div class="card p-2 " [ngClass]="{'card-completed': ccItem.assStatus == 1, 'card-notcomplete': ccItem.assStatus == 0 }"
            (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
            <div class="card-body pointer-cursor">
              <div class="float-left mt-1 mb-1">
                <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: -140px; left: 140px;" [message]="ccOverlayInc" [side]="'apNorthEast'"></app-overlay-tooltip>
                <label class="mb-0">{{ ccItem.text }} <span class="badge badge-warning mr-1">{{ ccItem.taskTotal }}</span></label>
              </div>
              <div class="float-right">
                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseCCItemTemplate>
          <div class="card p-2 " [ngClass]="{'card-completed': ccItem.assStatus == 1, 'card-notcomplete': ccItem.assStatus == 0 }"
            (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
            <div class="card-body pointer-cursor">
              <div class="float-left mt-1 mb-1">
                <label class="mb-0">{{ ccItem.text }} <span class="badge badge-warning mr-1">{{ ccItem.taskTotal }}</span></label>
              </div>
              <div class="float-right">
                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="showCCDetail == ccItem.id">
          <div class="col-sm-12 mt-1 mb-1" *ngFor="let task of ccItem.taskList; let t = index ">
            <!-- <div class="col-sm-12 mx-auto mt-1"> -->
            <ng-container *ngIf="(t == 0) && (k == 0); else elseTemplate">
              <div class="card p-2" [ngClass]="{'card-completed': task.assStatus == 1}" (click)="selectTask(ccItem, task)">
                <div class="card-body pointer-cursor">
                  <div class="float-left mt-1 mb-1">
                    <div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
                    <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 53%; left: 95%;" [message]="taskOverlayInc" [side]="'apSouthEast'"></app-overlay-tooltip>
                    <label class="mb-0">{{ task.text }}</label>
                  </div>
                  <!--<div class="float-right">
												<div class="float-right btn btn-sm border-0 mr-1 ml-1">
													<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
												</div>
											</div>-->
                </div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="card p-2" [ngClass]="{'card-completed': task.assStatus == 1}" (click)="selectTask(ccItem, task)">
                <div class="card-body pointer-cursor">
                  <div class="float-left mt-1 mb-1">
                    <div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
                    {{ task.text }}
                  </div>
                  <!--<div class="float-right">
													<div class="float-right btn btn-sm border-0 mr-1 ml-1">
														<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
													</div>
												</div>-->
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showKnowledge">
      <div class="row">
        <div class="col-sm-12 ">
          <h4>{{ selectedCC.text }}</h4>
        </div>
        <div class="col-sm-12 ">
          <h5>Task: {{ selectedTask.text }} </h5>
        </div>
        <hr/>
        <div class="col-sm-12 mt-2 mb-2" *ngFor="let knowledge of selectedTask.knowledgeList | orderBy : 'orderNum'; let k = index ">
          <div class="card">
            <div class="card-header">
              <span class="badge badge-dark-number mr-2 mt-1 float-left">{{k + 1}}</span>
              <p class="mb-0">{{ knowledge.knowledge}}</p>
				  <img *ngIf="knowledge.isImageAttached === 1" [src]="getImageURL(knowledge.id, knowledge.id, knowledge.fileType)" class="img-fluid mb-2 mt-2 img-custom">
            </div>
            <div class="card-body">
              <div class="col-sm-12 ml-6" *ngIf="knowledge.type == 'Boolean'">
                <div class="m-1 p-1">
                  <input class="m-1" type="radio" [name]="knowledge.id" value="1" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)"
                    (click)="setKnowlegeAnswer(knowledge, true)"> True</div>
                <div class="m-1 p-1">
                  <input class="m-1" type="radio" [name]="knowledge.id" value="0" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)"
                    (click)="setKnowlegeAnswer(knowledge, false)"> False</div>
              </div>
              <div class="col-sm-12 ml-6" *ngFor="let knowledgeAnswer of knowledge.answerList ; let n = index ">
                <div class="m-1 p-1" *ngIf="knowledge.type == 'Single'">
                  <input class="m-1" type="radio" [checked]="knowledgeAnswer.GivenAnswer === 1" [name]="knowledge.id"
                    [value]="knowledgeAnswer.id" (click)="setAnswer(knowledge, knowledgeAnswer)"> <span *ngIf="knowledgeAnswer.text" >{{knowledgeAnswer.text}}</span>                  
                  <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid mb-4 img-custom">
                </div>
                <div class="m-1 p-1" *ngIf="knowledge.type == 'Multiple'">
                  <input class="m-1" type="checkbox" [name]="knowledge.Id" [value]="knowledgeAnswer.id" [(ngModel)]="knowledgeAnswer.GivenAnswer"><span *ngIf="knowledgeAnswer.text">{{knowledgeAnswer.text}}</span>
						<img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid mb-4 img-custom" >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-6"></div>
        <div class="col-sm-3">
          <button type="button" [disabled]="assessmentCompleted" (click)="save()" class="btn btn-warning btn-block">Save</button>
        </div>
        <div class="col-sm-3">
          <button type="button" (click)="cancel(selectedTask)" class="btn btn-default btn-block">Cancel</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isMissedKnowledgeAvailable && reviewMissedAnswers">
      <div class="col-sm-12 mb-1">
        <h4 class="ml-2 mt-1 mb-2"> Missed Knowledge </h4>
        <hr />
      </div>
      <div class="col-sm-12 mt-1 mb-1 pl-4 pr-4" *ngFor="let ccItem of reviewCCList | orderBy : 'orderNum'; let k = index">
        <div class="card p-2 card-default" *ngIf="ccItem.assStatus == 0" (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
          <div class="card-body pointer-cursor">
            <div class="float-left mt-1 mb-1">
              {{ ccItem.text }}
            </div>
            <div class="float-right">
              <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showCCDetail == ccItem.id">
          <div class="col-sm-12 mt-1 mb-1" *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let t = index ">
            <!-- <div class="col-sm-12 mx-auto mt-1"> -->
            <div class="card p-2" *ngIf="task.assStatus == 0" (click)="showCollapsePanel(task.id , 'task')">
              <div class="card-body pointer-cursor">
                <div class="float-left mt-1 mb-1">
                  <div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
                  {{ task.text }}
                </div>
                <div class="float-right">
                  <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-1 mb-1" *ngIf="showTaskDetail==task.id && task.knowledgeList.length>0">
              <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
                <div class="mt-2" *ngIf="task.knowledgeList.length>0">
                  <h5 class="mt-1 mb-1">Knowledge</h5>
                  <div class="col-sm-12 mx-auto mt-1" *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
                    <div class="card p-2" *ngIf="knowledge.answer == 2" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
                      <div class="card-body pointer-cursor">
                        <div class="mt-1 mb-1">
                          <div class="float-right">
                            <div class="float-right btn btn-sm border-0 mr-1 ml-1" *ngIf="knowledge.remediationList.length > 0">
                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
                            </div>
                          </div>
                          <div class="col-sm-12 mt-1 mb-1 pr-5">
                            <span class="badge badge-dark-number mr-2 mt-1 float-left">{{k + 1}}</span>
                            <p class="mb-0">{{ knowledge.knowledge}}</p>
									 <img *ngIf="knowledge.isImageAttached === 1" [src]="getImageURL(knowledge.id, knowledge.id, knowledge.fileType)" class="img-fluid mb-2 mt-2 img-custom">
                            <div class="col-sm-12 ml-6" *ngIf="knowledge.type == 'Boolean'">
                              <div class="row pl-2 ml-2 mb-1">
                                <span class="float-left p-2 badge " [ngClass]="{'badge-success-cus': knowledge.booleanAnswerCorrect == 1, 'badge-danger-cus': knowledge.booleanAnswerCorrect == 0}">
																	<input class="float-left" disabled type="radio" [name]="knowledge.id" value="1" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)"
																	 (click)="setKnowlegeAnswer(knowledge, true)">
																</span>
                                <p class="m-0 mt-1 ml-2 float-right">True</p>
                              </div>
                              <div class="row pl-2 ml-2 mt-1">
                                <span class="float-left p-2 badge " [ngClass]="{'badge-danger-cus': knowledge.booleanAnswerCorrect == 1, 'badge-success-cus': knowledge.booleanAnswerCorrect == 0}">
																	<input class="float-left" disabled type="radio" [name]="knowledge.id" value="0" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)"
																	 (click)="setKnowlegeAnswer(knowledge, false)">
																</span>
                                <p class="m-0 mt-1 ml-2 float-right">False</p>
                              </div>
                            </div>
                            <div class="col-sm-12 ml-6" *ngFor="let knowledgeAnswer of knowledge.answerList ; let n = index ">
                              <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Single'">
                                <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 0), 'badge-success-cus': knowledgeAnswer.IsCorrect == 1 , 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																	<input class="float-left" disabled type="radio" [checked]="knowledgeAnswer.GivenAnswer === 1" [name]="knowledge.id" [value]="knowledgeAnswer.id"
																	 (click)="setAnswer(knowledge, knowledgeAnswer)">
																</span>
                                <p class="m-0 mt-1 ml-2 float-right" *ngIf="knowledgeAnswer.text">{{knowledgeAnswer.text}}</p>
										  <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-review-custom ml-1">
                              </div>
                              <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Multiple'">
                                <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 0), 'badge-success-cus': knowledgeAnswer.IsCorrect == 1, 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																	<input class="float-left" disabled type="checkbox" [name]="knowledge.Id" [value]="knowledgeAnswer.id" [(ngModel)]="knowledgeAnswer.GivenAnswer">
																</span>
                                <p class="m-0 mt-1 ml-2 float-right" *ngIf="knowledgeAnswer.text">{{knowledgeAnswer.text}}</p>
										  <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-review-custom ml-1">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- knowledge remediation -->
                    <div *ngIf="showKnowledgeDetail == knowledge.id && knowledge.remediationList.length > 0">
                      <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
                        <div class="mt-2" *ngIf="knowledge.remediationList.length>0">
                          <h5 class="mt-1 mb-1">Remediation</h5>
                          <div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
                            <div class="col-sm-12 mx-auto mt-1">
                              <div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
                                <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
                                  <div class="col-sm-12 pl-1 pr-1">
                                    <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                      <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
                                      <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{ kremediation.Description
                                      }}
                                    </div>
                                    <div class="float-right">
                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 float-left mt-3 mb-1 pl-0" *ngIf="kremediation.CourseDescription">
                                    <b>Course Description : </b> {{ kremediation.CourseDescription}}
                                  </div>
                                  <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf="kremediation.CourseNumber">
                                    <b>Course Number : </b> {{ kremediation.CourseNumber }}
                                  </div>
                                  <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf="kremediation.Link">
                                    <b>Link : </b>
                                    <a *ngIf="kremediation.Link.indexOf('http') < 0" [href]="'http://'+kremediation.Link" target="_blank">{{kremediation.Link}}</a>
                                    <a *ngIf="kremediation.Link.indexOf('http') >= 0" href={{kremediation.Link}} target="_blank">{{kremediation.Link}}</a>
                                  </div>
                                  <div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                    <label class="mb-0">
																			<b>Note : </b>
																		</label>
                                    <div class="col-sm-12 mt-2 ml-2">
                                      <form>
                                        <div class="form-group row">
                                          <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
                                            (click)="$event.stopPropagation()" readonly></textarea>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                  <div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                    <span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
                                  </div>
                                  <br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
                                  <div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
                                    <ng-template [ngIf]="item.text == 'pdf' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'txt' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'docx' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'doc' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'ppt' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'pptx' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'xls' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'xlsx' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                  </div>
                                </div>
                                <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
                                  <div class="float-left">
                                    <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
                                    <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{ kremediation.Description
                                    }}
                                  </div>
                                  <div class="float-right">
                                    <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                      <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" *ngIf="task.remediationList.length>0">
                  <div class="mt-2" *ngIf="task.remediationList.length>0">
                    <h5 class="mt-1 mb-1">Remediation</h5>
                    <div *ngFor="let remediation of task.remediationList; let tr = index ">
                      <div class="col-sm-12 mx-auto mt-1">
                        <div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentTaskRemId , 'taskRemediationDetail', remediation.Id)">
                          <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail == remediation.AssessmentTaskRemId">
                            <div class="col-sm-12 pl-1 pr-1">
                              <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                <span class="badge badge-warning mr-1">{{ remediation.Type }} </span> {{ remediation.Description
                                }}
                              </div>
                              <div class="float-right">
                                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 float-left mt-3 mb-1 pl-0" *ngIf="remediation.CourseDescription">
                              <b>Course Description : </b> {{ remediation.CourseDescription }}
                            </div>
                            <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf=" remediation.CourseNumber">
                              <b>Course Number : </b> {{ remediation.CourseNumber }}
                            </div>
                            <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf=" remediation.Link">
                              <b>Link : </b>
                              <a *ngIf="remediation.Link.indexOf('http') < 0" [href]="'http://'+remediation.Link" target="_blank">{{remediation.Link}}</a>
                              <a *ngIf="remediation.Link.indexOf('http') >= 0" href={{remediation.Link}} target="_blank">{{remediation.Link}}</a>
                            </div>
                            <div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                              <label class="mb-0">
																<b>Note : </b>
															</label>
                              <div class="col-sm-12 mt-2 ml-2">
                                <form>
                                  <div class="form-group row">
                                    <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
                                      (click)="$event.stopPropagation()" readonly></textarea>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="col-sm-12 float-left mt-3 mb-1 pl-0" *ngIf="selectedTaskRemediationAttachment.length > 0">Documents
                              <span class="badge badge-dark mr-1">{{selectedTaskRemediationAttachment != null?selectedTaskRemediationAttachment.length:''}}</span>
                            </div>
                            <br *ngIf="selectedTaskRemediationAttachment.length > 0">
                            <div *ngFor="let item of selectedTaskRemediationAttachment; let k = index ">
                              <ng-template [ngIf]="item.text == 'pdf' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'txt' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'docx' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'doc' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'ppt' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'pptx' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'xls' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'xlsx' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                            </div>
                          </div>
                          <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail != remediation.AssessmentTaskRemId">
                            <div class="float-left">
                              <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                              <span class="badge badge-warning mr-1">{{ remediation.Type }}</span> {{ remediation.Description
                              }}
                            </div>
                            <div class="float-right">
                              <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showOverlay" class="modal-backdrop fade show" (click)="removeOverlay()"></div>
<div *ngIf="showOverlay" class="overlay" (click)="removeOverlay()">
  <div class="container">
    <div class="card p-3 overlay-card">
      <div class="card-body">
        <h4 class="mx-auto text-center">
          {{ constants.OERLAY_INS_BACKGROUND_MSG }}
        </h4>
      </div>
    </div>
  </div>
</div>
