<!-- <div class="col-sm-12 text-center">
	<div class="row">
		<div class="col-sm-3">
			<div class="btn-group">
				<div class="btn btn-warning" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (click)="decrement()">
					Previous
				</div>
				<div class="btn btn-secondary" mwlCalendarToday [(viewDate)]="viewDate" (click)="today()">
					Today
				</div>
				<div class="btn btn-warning" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (click)="increment()">
					Next
				</div>
			</div>
		</div>
		<div class="col-sm-3">
			<h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
		</div>
		<div class="col-sm-3">
			<div class="btn-group">
				<div class="btn btn-warning" (click)="view = 'month'" [class.active]="view === 'month'">
					Month
				</div>
				<div class="btn btn-warning" (click)="view = 'week'" [class.active]="view === 'week'">
					Week
				</div>
				<div class="btn btn-warning" (click)="view = 'day'" [class.active]="view === 'day'">
					Day
				</div>
			</div>
		</div>
		<div class="col-sm-3">
			<div class="btn-group">
				<div id="selfAssessmentColor"></div>
				<div>&nbsp;Self Assessments</div>
			</div>
			<div class="btn-group">
				<div id="assessmentColor"></div>
				<div>&nbsp;Assessor Lead</div>
			</div>
		</div>
	</div>
</div> -->

<div class="col-sm-12 mx-auto text-center">
	<h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
</div>
<div class="col-sm-12 mx-auto mt-1 mb-1">
	<div class="row">
		<div class="col-sm-4">
			<div class="btn-group mb-1">
				<div class="btn btn-warning" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (click)="decrement()">
					Previous
				</div>
				<div class="btn btn-secondary" mwlCalendarToday [(viewDate)]="viewDate" (click)="today()">
					Today
				</div>
				<div class="btn btn-warning" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (click)="increment()">
					Next
				</div>
			</div>
		</div>
		<div class="col-sm-4 text-center">
			<div class="btn-group ml-1 mr-1">
				<div id="selfAssessmentColor" class="mr-1"></div>
				<label class="m-0 p-1">Knowledge Check
				</label>
			</div>
			<div class="btn-group ml-1 mr-1">
				<div id="assessmentColor" class="mr-1"></div>
				<label class="m-0 p-1">Assessor Lead</label>
			</div>
		</div>
		<div class="col-sm-4">
			<div class="btn-group mb-1 float-right">
				<div class="btn btn-warning" (click)="view = 'month'" [class.active]="view === 'month'">
					Month
				</div>
				<div class="btn btn-warning" (click)="view = 'week'" [class.active]="view === 'week'">
					Week
				</div>
				<div class="btn btn-warning" (click)="view = 'day'" [class.active]="view === 'day'">
					Day
				</div>
			</div>
		</div>

	</div>
</div>
<div>
	<alert></alert>
</div>
<br>
<br>
<ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale" let-tooltipPlacement="tooltipPlacement"
 let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay" let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
 let-tooltipAppendToBody="tooltipAppendToBody" let-selfSum="day.events.filter(function (v) { return v.meta.type == 'SelfAssessment'}).reduce(function(prevVal, elem) { return prevVal + elem.meta.count;}, 0);"
 let-sum="day.events.filter(function (v) { return v.meta.type == 'Assessment'}).reduce(function(prevVal, elem) { return prevVal + elem.meta.count;}, 0);">
	<div class="cal-cell-top">
		<span class="cal-day-selfAssessmentTotal" *ngIf="day.events.length > 0">{{ selfSum }}  Knowledge Check</span>
		<span class="cal-day-assessmentTotal" *ngIf="day.events.length > 0">{{ sum }} Assessor Lead</span>
		<span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
	</div>
	<div class="cal-events" *ngIf="day.events.length > 0">
		<div class="cal-event" *ngFor="let event of day.events" [style.backgroundColor]="event.color.primary" [ngClass]="event?.cssClass"
		 (mouseenter)="highlightDay.emit({event: event})" (mouseleave)="unhighlightDay.emit({event: event})" [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
		 [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody"
		 (mwlClick)="handleEvent($event, event)">
		</div>
	</div>
</ng-template>
<div [ngSwitch]="view">
	<mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="localEvents" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
	 (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
	 (beforeViewRender)="beforeMonthViewRender($event)" [cellTemplate]="customCellTemplate">
	</mwl-calendar-month-view>
	<mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="localEvents" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
	 (eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-week-view>
	<mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="localEvents" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
	 (eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-day-view>

</div>
<div *ngIf=isLoading class="loading"></div>

<modal #scheduleconfirmationModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Schedule Assessments</h4>
	</modal-header>
	<modal-body>
		<p *ngIf="warningMessage != null">{{warningMessage}}</p>
		<p>{{constants.SCHEDULE_ASSESSMENT}}</p>
		<p><input type="checkbox" id="includeChilderen" 
                        name="includeChildren" (click)="markKnowledgeTest($event)"> Knowledge Check Only
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="scheduleAssessments()">Yes</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="scheduleconfirmationModal.dismiss()">No</button>
	</modal-footer>
</modal>
<modal #rescheduleconfirmationModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Reschedule Assessments</h4>
	</modal-header>
	<modal-body>
		<p *ngIf="warningMessage != null">{{warningMessage}}</p>
		<p>{{constants.RESCHEDULE_ASSESSMENT}}</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="scheduleAssessments()">Yes</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="scheduleconfirmationModal.dismiss()">No</button>
	</modal-footer>
</modal>
<modal #scheduleInformationModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Schedule Assessments</h4>
	</modal-header>
	<modal-body>
		Please select a date to schedule assessment(s).
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="scheduleInformationModal.dismiss()">OK</button>
	</modal-footer>
</modal>
<modal #rescheduleInformationModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Reschedule Assessments</h4>
	</modal-header>
	<modal-body>
		Please select a date to reschedule assessment(s).
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="rescheduleInformationModal.dismiss()">OK</button>
	</modal-footer>
</modal>