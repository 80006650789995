<div class="pos-f-t fixed-top header" style="border-bottom: 1px solid #ddd;">
	<!--<nav class="navbar navbar-inverse bg-inverse navbar-toggleable-md">
		<div class="navbar-toggler navbar-toggler-right" style="margin-right:0px; padding-top:0px; padding-bottom:3px; padding-left:0px; padding-right:0px;">
			<ul class="navbar-nav-toggler ml-auto mt-md-0">
				<li class="nav-item dropdown" ngbDropdown>
					<button type="button" class="btn btn-warning-name dropdown-toggle rounded-0" aria-haspopup="true" aria-expanded="false"
					 ngbDropdownToggle>
						<i class="fa fa-user"></i>
					</button>
					<div class="dropdown-menu dropdown-menu-right">
						<button class="dropdown-item capitalize-text" [disabled]="true">
							<i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</button>
						<button class="dropdown-item" [disabled]="true">
							<i class="fa fa-fw fa-group"></i> Switch User Roles</button>
						<a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
							<i class="fa fa-fw fa-power-off"></i> Log Out</a>
						<hr>
						<a class="dropdown-item">
							<i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
					</div>
				</li>
			</ul>
		</div>
		<a class="navbar-brand" href="javascript:void(0)">
			<img [src]="logoSrc" />
		</a>
		<div class="collapse navbar-collapse">
			<ul class="navbar-nav ml-auto mt-md-0">
				<li class="nav-item dropdown" ngbDropdown>
					<button type="button" class="btn btn-warning-name dropdown-toggle rounded-0" aria-haspopup="true" aria-expanded="false"
					 ngbDropdownToggle>
						<i class="fa fa-user"></i>
						
					</button>
					<div class="dropdown-menu dropdown-menu-right">
						<button class="dropdown-item" [disabled]="true">
							<i class="fa fa-fw fa-user"></i>{{userFirstName}}'s Profile</button>
						<button class="dropdown-item" [disabled]="true">
							<i class="fa fa-fw fa-group"></i> Switch User Roles</button>
						<a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
							<i class="fa fa-fw fa-power-off"></i> Log Out</a>
						<hr>
						<a class="dropdown-item">
							<i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
					</div>
				</li>
			</ul>
		</div>
	</nav>-->
	<nav class="navbar navbar-expand-lg navbar-light ">
    <div class="navbar-brand">
      <img [src]="logoSrc" />
    </div>
    

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <form class="form-inline my-2 my-lg-0">

        <!--<a class="nav-link " href="#" id="navbarDropdown" role="button">-->
        <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0 my-2 my-sm-0 pl-3 pr-3" id="navbarDropdown" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user">              
            </i>            
          </button>
        <!--</a>-->
        <div class="dropdown-menu dropdown-menu-right mr-3" style="top:78%" aria-labelledby="navbarDropdown">
          <button class="dropdown-item capitalize-text" [disabled]="true">
              <i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</button>
          <button class="dropdown-item" id="showUserrole" [disabled]="true">
              <i class="fa fa-fw fa-group"></i> Switch User Roles</button>
          <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
              <i class="fa fa-fw fa-power-off"></i> Log Out</a>
          <hr>
          <a class="dropdown-item">
              <i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
        </div>
      </form>
    </div>
  </nav>
</div>
<div class="main-container mt-3 m-1">
	<div class="row" #container1></div>
	<alert></alert>
	<div *ngIf=isLoading class="loading"></div>
	<div class="col-lg-12 mx-auto pb-2">
		<div class="row">
			<div class="col-sm-12 mb-3 mt-2 text-right">
				<button *ngIf="showAssessment" type="button" class="btn btn-outline-warning btn ml-2 mr-2 mb-1 pl-5 pr-5 cursor-pointer" (click)="showSignaturePage()">
					Signature Page
				</button>
				<button *ngIf="show" type="button" class="btn btn-outline-warning btn ml-2 mr-2 mb-1 pl-5 pr-5 cursor-pointer" (click)="viewAssessment()">View
					Assessment</button>
				<button type="button" class="btn btn-warning btn ml-2 mr-2 mb-1 pl-5 pr-5 cursor-pointer" (click)="returnToDashBoard()">Return
					to Dashboard</button>
			</div>
		</div>
		<div *ngIf="show">
			<div>
				<div class="row">
					<div class="col-sm-12 mb-2 mx-auto">
						<h5 class="fw-300">Please review the assessment and sign below:</h5>
					</div>
				</div>
				<form class="mb-3">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label">Name</label>
						<div class="col-sm-4 ">
							<input type="text" class="form-control" id="userName" placeholder="{{ assessment.userName }}" disabled>
						</div>
						<label class="col-sm-2 col-form-label">Org Code</label>
						<div class="col-sm-4 ">
							<input type="text" class="form-control" id="orgCode" placeholder="{{ assessment.orgCode }}" disabled>
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label">JobProfile</label>
						<div class="col-sm-4">
							<input type="text" class="form-control" id="jobProfile" placeholder="{{ assessment.jobProfileDesc }}" disabled>
						</div>
						<label class="col-sm-2 col-form-label">E-mail</label>
						<div class="col-sm-4 ">
							<input type="text" class="form-control" id="email" placeholder="{{ assessment.userEmail }}" disabled>
						</div>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label">Discipline</label>
						<div class="col-sm-4">
							<input type="text" class="form-control" id="discipline" placeholder="{{ assessment.discipline }}" disabled>
						</div>
						<label class="col-sm-2 col-form-label">Supervisor</label>
						<div class="col-sm-4">
							<!--<input type="text" class="form-control" id="supervisorName" placeholder="{{ assessment.supervisorName }}"
							 disabled>-->
							<textarea autosize height="" type="text" class="form-control" id="supervisorName"  disabled>{{ assessment.supervisorName }}</textarea>

						</div>
					</div>

				</form>
			</div>
			<!-- <div class="row">
				<div class="col-sm-12 mb-3 mt-2 text-left">
					<button type="button" class="btn btn-info btn-sm ml-2 mr-2 mb-1 pl-5 pr-5" (click)="viewAssessment()">View Assessment</button>
				</div>
			</div> -->
			<div class="card card-main-100 card-assessment  col-sm-12 my-4 py-3">
				<form [formGroup]="assessmentCompleteForm" (ngSubmit)="submitSignature()" class="mb-3">
					<div class="row mx-auto mb-2">
						<div class="col-sm-12 pr-4" *ngIf="type=='candidate'">
							<p class="h5 mb-1">Candidate</p>
							<hr class="my-1" />
						</div>
						<div class="col-sm-12 pl-4" *ngIf="type=='assessor'">
							<p class="h5 mb-1">Assessor</p>
							<hr class="my-1" />
						</div>
					</div>
					<div class="row mx-auto mb-2">
						<div class="col-sm-12 pl-4" *ngIf="type=='assessor'">
							<div class="form-group row">
								<label for="inputPassword" class="col-sm-4 col-form-label">Assessor Certification</label>
								<div class="col-sm-8">
									<input type="text" class="form-control" id="assessorCertification" formControlName="assessorCertification"
									 placeholder="Assessor Certification">
								</div>
							</div>
						</div>
						<div class="col-sm-12">
							<div class="card">
								<div class="col-sm-12">
									<button type="button" class="float-right btn btn-sm btn-warning mt-2 mr-2" (click)="clearSignaturePad1()">Clear</button>
								</div>
								<signature-pad [options]="signaturePadOptions" #SignaturePad1 (onEndEvent)="drawComplete()"></signature-pad>

							</div>
						</div>
					</div>
					<div class="row mx-auto mb-4">
						<div class="col-sm-12 text-center">
							<label class="mb-0">Signature</label>
							<div>
								<small>
									<var>{{userName}}</var>
								</small>
							</div>
						</div>

					</div>
					<div class="row mx-auto mb-1">
						<div class="col-sm-6 pr-4"></div>
						<div class="col-sm-6 pl-4">
							<div class="col-sm-6 float-right pl-0">
								<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Submit</button>
							</div>

						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="errorMsg">
		<div class="alert alert-danger" role="alert">
			<p class="h6 text-center">{{errorMsg}}</p>
		</div>
	</div>
	<div class="container p-0 mx-auto mb-4 mt-3" *ngIf="success">
		<div class="alert alert-success" role="alert">
			<p class="h6 text-center">Successfully submitted the signature.</p>
		</div>
	</div>
	<div class="col-lg-12 mx-auto pb-2" *ngIf="showAssessment">
		<app-candidate-assessment></app-candidate-assessment>
	</div>
</div>