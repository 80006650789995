<!-- <div class="mt-1 col-md-12"> &nbsp;</div>
<div class="card card-default">
	<div class="card-header">
		<div class="panel-heading">
			<i class="fa fa-clock-o fa-fw"></i> Ranking/Score of my employees
		</div>
	</div>
	<div class="card-block">
		<div class="panel panel-default">
			<div class="panel-body">
				<table class="table bootstrap-datatable countries">
					<thead>
						<tr>
							<th>Name</th>
							<th>Category</th>
							<th>Start Date</th>
							<th>End Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Name 1</td>
							<td>Liquid Penetrant</td>
							<td>06/05/2017</td>
							<td>06/10/2017</td>
						</tr>
						<tr>
							<td>Name 2</td>
							<td>Magnetic Particle</td>
							<td>07/05/2017</td>
							<td>07/10/2017</td>
						</tr>
						<tr>
							<td>Name 3</td>
							<td>Radiography</td>
							<td>05/05/2017</td>
							<td>05/10/2017</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div class="mt-1 col-md-12"> &nbsp;</div> -->
<table class="table bootstrap-datatable countries">
	<thead>
		<tr>
			<th>Name</th>
			<th>Category</th>
			<th>Start Date</th>
			<th>End Date</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>Name 1</td>
			<td>Liquid Penetrant</td>
			<td>06/05/2017</td>
			<td>06/10/2017</td>
		</tr>
		<tr>
			<td>Name 2</td>
			<td>Magnetic Particle</td>
			<td>07/05/2017</td>
			<td>07/10/2017</td>
		</tr>
		<tr>
			<td>Name 3</td>
			<td>Radiography</td>
			<td>05/05/2017</td>
			<td>05/10/2017</td>
		</tr>
	</tbody>
</table>