import { Component, OnInit } from "@angular/core";
import { ReportTableComponent } from "../reporttable.component";

import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { JobProfileService } from '../../jobprofile/jobprofile.service';
import { Report } from "../report.model";

@Component({
	selector: "app-jobprofile-tasks-report",
	templateUrl: "./jobprofiletasks.component.html"
})
export class JobProfileTasksReportComponent extends ReportTableComponent
	implements OnInit {
	public jobProfileList: Array<any> = [];
	public isLoading: boolean = false;
	public report: Report;
	public excelData: any[];
	public columns: Array<any> = [
		{
			title: "Core Competency",
			name: "Core Competency",
			filtering: { filterString: "" }
		},
		{
			title: "Task Group",
			name: "Task Group",
			filtering: { filterString: "" }
		},
		{
			title: "Task",
			name: "Task",
			filtering: { filterString: "" }
		}
	];

	private serverResultData: Array<any> = [];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private jobProfileService: JobProfileService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
		this.getActiveJobProfiles();
	}

	ngOnInit() {

	}

	private getActiveJobProfiles() {
		this.isLoading = true;
		this.jobProfileService.getAllJobProfiles()
			.subscribe(
			data => {
				this.jobProfileList = [];
				for (var i = 0; i < data.jobprofileList.length; i++) {
					this.jobProfileList.push({ id: data.jobprofileList[i].Id, text: data.jobprofileList[i].Description + " - " + data.jobprofileList[i].OrgCode });
				}
				this.isLoading = false;
			},
			error => {

			}
			);
	}

	public removedJobProfile(value: any): void {
		this.report.reportData = [];
		this.data = [];
		this.setTableData()
	}

	setTableData() {
		this.serverResultData = this.data;
		this.report.columns = this.columns;
		this.config.report = this.report;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);

		this.onChangeTable(this.config);
	}

	public selectedJobProfile(value: any): void {
		this.data = []
		this.jobProfileService.getJobProfileTasks(value.id)
			.subscribe(
			data => {
				this.report.reportData = data.taskList;
				this.report.reportItem = value.text.substring(0, value.text.indexOf("-") - 1)
				for (let i = 0; i < this.report.reportData.length; i++) {
					var taskGroup = this.report.reportData[i].TaskGroup;
					if (!taskGroup) {
						taskGroup = "";
					}
					this.data.push({
						"Core Competency": this.report.reportData[i].CoreCompetency,
						"Task Group": taskGroup,
						"Task": this.report.reportData[i].Task
					});

				}
				this.serverResultData = this.data;
				this.setTableData()
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
			);
	}
}
