import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';

import { Task } from '../task/task.model';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { TaskService } from '../task/task.service';
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: 'app-alltask',
	templateUrl: './alltask.component.html'
})
export class AllTaskComponent extends TableComponent {
	public selectedTask: any;

	public selectedTaskStatus: boolean = true;
	public confirmationBtnClass: string = 'btn-default';

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	private isDeleteTask: boolean;
	private isModifyTask: boolean;
	isAddTask: boolean;
	private isQueryTask: boolean;

	public tagList = [];
	showHideForm: FormGroup;
	checked: boolean = false;

	public columns: Array<any> = [
		{ title: 'Task', name: 'task', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'status', className: 'accepter-col-action' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	//this array store all the data from the server
	private serverResultData: Array<any> = [];

	constructor(private customModalService: CustomModalService, private alertService: AlertService, private taskService: TaskService,
		private sharedService: SharedService, private userService: UserService) {
		super();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddTask = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.isModifyTask = this.userService.isCapabilityAssigned('ModifyCompLib', this.selectedUserRole[0][1]);
		this.isDeleteTask = this.userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryTask = this.userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);

	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.isQueryTask) {
			//load data
			this.loadAllShowTasks();
		} else {
			//do nothing
		}

		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add 'implements OnChanges' to the class.

	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		var elementnumber = parseInt(data.row.id);
		this.selectedTask = this.serverResultData[elementnumber];
		this.selectedTaskStatus = this.serverResultData[elementnumber].Status;
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = 'btn-danger'
		} else {
			this.confirmationBtnClass = 'btn-success'
		}
		// console.log('[onCellClick] (selectedUserId) : ' + this.selectedUserId);
		switch (data.column) {
			case 'editbutton':
				//check user has modify/edit capability
				if (this.isModifyTask) {
					this.editButtonClickEvent(this.selectedTask);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				//check user has delete capability
				if (this.isDeleteTask) {
					this.deleteButtonClickEvent(this.selectedTask.Id);
				} else {
					//do nothing
				}
				break;
			case 'status':
				if (this.isModifyTask) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						//do nothing
					} else {
						this.statusButtonClickEvent(this.selectedTask.Id, this.serverResultData[elementnumber].Status);
					}
				} else {
					//do nothing
				}
				break;
			case "showhidebutton":
				//check delete capability
				if (this.isModifyTask) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonClickEvent(this.selectedTask.Id, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	private statusButtonClickEvent(id: string, status: number) {
		let itemType = "Task";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private deleteButtonClickEvent(id: string) {
		let itemType = "Task";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	//load buttons to the table
	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {

			//check user has modify capability
			if (this.isModifyTask) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status = '<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					if (this.serverResultData[i].ShowHide == 0) {
						this.data[i].status = '<a class="btn btn-sm disabled"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].status = '<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				}
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status = '<a class="btn btn-sm disabled"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].status = '<a class="btn btn-sm disabled" ><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}
			//check user has delete capability
			if (this.isDeleteTask) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyTask) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyTask) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}

		}
	}

	//load all tasks from the db
	private loadAllTasks() {
		this.taskService.getAllTaks()
			.subscribe(
			data => {
				this.data = [];
				//console.log('[loadAllTasks] data : ' + JSON.stringify(data) + '/(count)' + data.taskList.length);
				for (let i = 0; i < data.taskList.length; i++) {
					this.data.push({
						'id': i,
						'taskId': data.taskList[i].Id,
						'task': data.taskList[i].Task,
						'status': data.taskList[i].Status,
						'showHide': data.taskList[i].ShowHide,
						'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				this.serverResultData = data.taskList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//edit button click event
	private editButtonClickEvent(selectedTask: any) {
		this.taskService.getAssignedTaskTagList(selectedTask.Id)
			.subscribe(
			data => {
				//console.log('[editButtonClickEvent]' + JSON.stringify(data).toString());
				this.tagList = []
				data.assignTagList.forEach(element => {
					let item = { value: element.Description, id: element.TTagId }
					this.tagList.push(item);
				});

				let tempTask = new Task(selectedTask.Id, selectedTask.Task);
				tempTask.tagList = this.tagList
				this.taskService.setSelectedTaskForEdit(tempTask);
				this.sharedService.change('EditTask');
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	//delete button click event
	delete() {
		//console.log('[deleteTask] (taskId/task) : ' + this.selectedTask.Id + '/' + this.selectedTask.Task);
		const tempTask = new Task(this.selectedTask.Id, this.selectedTask.Task, this.selectedTask.Status);
		this.taskService.deleteTask(tempTask)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	changeStatus() {
		//console.log('[changeStatusofTask] (taskId/status) : ' + this.selectedTask.Id + '/' + this.selectedTask.Status);
		const tempTask = new Task(this.selectedTask.Id, this.selectedTask.Task, !this.selectedTask.Status);
		this.taskService.updateTaskStatusByTaskId(tempTask)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	private loadAllShowTasks() {
		this.taskService.getAllShowTaks()
			.subscribe(
			data => {
				this.data = [];
				//console.log('[loadAllTasks] data : ' + JSON.stringify(data) + '/(count)' + data.taskList.length);
				for (let i = 0; i < data.taskList.length; i++) {
					this.data.push({
						'id': i,
						'taskId': data.taskList[i].Id,
						'task': data.taskList[i].Task,
						'status': data.taskList[i].Status,
						'showHide': data.taskList[i].ShowHide,
						'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				this.serverResultData = data.taskList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	showHide(event) {		
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryTask) {
				this.loadAllTasks();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowTasks();
		}
	}
		
	loadData() {
		if (this.checked) {
			this.loadAllTasks();
		}
		else {
			this.loadAllShowTasks();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Task";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	public showHideEvent(taskId: string, showHide: number) {
		this.taskService.changeTaskShowHide(taskId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}
}
