import { ReportTableComponent } from "../reporttable.component";
import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { Report } from "../report.model";

@Component({
	selector: "app-knowledgereport",
	templateUrl: "./knowledgereport.component.html"
})
export class KnowledgeReportComponent extends ReportTableComponent implements OnInit {
	public report: Report;
	public excelData: any[];
	public columns: Array<any> = [
		{
			title: "Knowledge",
			name: "Knowledge",
			filtering: { filterString: "" }
		},
		{
			title: "Test Question",
			name: "Test Question",
			filtering: { filterString: "" }
		},
		{
			title: "Type",
			name: "Type",
			filtering: { filterString: "" }
		},
		{
			title: "Org Code",
			name: "Org Code",
			filtering: { filterString: "" }
		},
		{
			title: "Tags",
			name: "Tags",
			filtering: { filterString: "" }
		}
	];

	private serverResultData: Array<any> = [];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered" ]
	};
	// public data: Array<any> = [];

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
	}

	ngOnInit() {
		this.loadActiveKnowledgeWithTags(this.report.reportData);
	}

	public loadActiveKnowledgeWithTags(data: any) {
		this.excelData = [];
		for (let i = 0; i < data.length; i++) {
			this.data.push({
				"Knowledge": data[i].Knowledge,
				"Test Question": data[i].IsTestquestion == 1 ? "Yes" : "No",
				"Type": data[i].Type == null ? "" : data[i].Type,
				"Org Code": data[i].OrgCode == null ? "" : data[i].OrgCode,
				"Tags": data[i].Tags == null ? "" : data[i].Tags
			});

			// this.excelData.push({
			//   "Knowledge": data[i].Knowledge,
			//   "Test Question": data[i].IsTestquestion == 1 ? "Yes" : "No",
			//   "Type": data[i].Type == null ? "" : data[i].Type,
			//   "Org Code": data[i].OrgCode == null ? "" : data[i].OrgCode,
			//   "Tags": data[i].Tags == null ? "" : data[i].Tags
			// });
		}
		this.serverResultData = data;
		this.report.columns = this.columns;
		// this.report.reportDataList = this.excelData;
		this.config.report = this.report;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.onChangeTable(this.config);
	}
}
