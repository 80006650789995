import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { ChecklistTagService } from './checklisttag.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-editchecklisttag',
	templateUrl: './editchecklisttag.component.html'
})

export class EditChecklistTagComponent implements OnInit {
	editChecklistTag: FormGroup;

	// public editChecklist: Checklist;

	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private checklistTagService: ChecklistTagService
	) {

	}
	ngOnInit() {
		this.editChecklistTag = new FormGroup({
			id: new FormControl(this.checklistTagService.curChecklistId, Validators.required),
			checklistTag: new FormControl(this.checklistTagService.curChecklist, Validators.required)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedToUpdateChecklist()) {
			this.checklistTagService.editChecklistTag(this.editChecklistTag.value.id, this.editChecklistTag.value.checklistTag)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully modified the checklist tag.");
					this.checklistTagService.curChecklist = this.editChecklistTag.value.checklistTag;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	checkNeedToUpdateChecklist() {
		if (this.checklistTagService.curChecklist != this.editChecklistTag.value.checklistTag) {
			return true;
		} else {
			return false;
		}
	}
}