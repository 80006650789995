<!--component html goes here -->
<div class="col-lg-12">
    <div class="col" style="overflow:hidden">
        <div class="float-left">
            <h4>Perform Assessment&nbsp;<span *ngIf="disableforAdmin" class="badge badge-custom-kt ml-1">Read Only</span></h4>
        </div>
        <div class="float-right"></div>
    </div>
    <hr />
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="col-lg-12 mx-auto pb-2">
    <alert></alert>
    <div *ngIf="!showHierarchy">
        <form class="mb-3">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-4 ">
                    <input type="text" class="form-control" id="userName" placeholder="{{ assessment.userName }}" disabled>
                </div>
                <label class="col-sm-2 col-form-label">Org Code</label>
                <div class="col-sm-4 ">
                    <input type="text" class="form-control" id="orgCode" placeholder="{{ assessment.orgCode }}" disabled>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label">JobProfile</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" id="jobProfile" placeholder="{{ assessment.jobProfile }}" disabled>
                </div>
                <label class="col-sm-2 col-form-label">E-mail</label>
                <div class="col-sm-4 ">
                    <input type="text" class="form-control" id="email" placeholder="{{ assessment.userEmail }}" disabled>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Discipline</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" id="discipline" placeholder="{{ assessment.discipline }}" disabled>
                </div>
                <label class="col-sm-2 col-form-label">Supervisor</label>
                <div class="col-sm-4">
                    <!--<input type="text" class="form-control" id="supervisorName" placeholder="{{ assessment.supervisorName }}" disabled>-->
						  <textarea autosize height="" type="text" class="form-control" id="supervisorName"  disabled>{{ assessment.supervisorName }}</textarea>
                </div>
            </div>

            <div class="form-group row" *ngIf="assessment.status == 'Completed'">
                <label class="col-sm-2 col-form-label">Assessment Date</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" id="assessmentCompDate" placeholder="{{ localAssessmentCompletedDate.toLocaleDateString() }}" disabled>
                </div>
                <label class="col-sm-2 col-form-label"></label>
                <div class="col-sm-4"></div>
            </div>

            <div class="form-group row mt-4" *ngIf="!showAssmentDatePanel">
                <div class="col-sm-4 "></div>
                <div class="col-sm-4 "></div>
                <div class="col-sm-4 ">
                    <button class="btn btn-warning btn-block" (click)="assessmentButtonClickEvent($event, assessment.status)" [disabled]="showAssmentDatePanel">{{ assessmentButtonText }}</button>
                </div>
            </div>
        </form>
        <div *ngIf="showAssmentDatePanel" class="mt-4">
            <form [formGroup]="assessmentDateForm" class="pt-3" (ngSubmit)="onSubmitAsscompletedDate()" (window:resize)="beResponsive()">
                <div class="form-group row mx-auto" *ngIf="assessment.status == 'New'">
                    <div class="col-sm-1"></div>
                    <label class="col-sm-4 col-form-label">Assessment Date <sup><i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i></sup></label>
                    <div class="col-sm-6 ">
                        <my-date-picker (dateChanged)="assessmentDatePickerChanged($event)" formControlName="assessmentCompletedDate" [options]="assessmentDatePickerOptions"></my-date-picker>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="form-group row mx-auto" *ngIf="assessment.status != 'New'">
                    <div class="col-sm-1"></div>
                    <label class="col-sm-4 col-form-label">Assessment Date</label>
                    <div class="col-sm-6 ">
                        <input type="text" class="form-control" id="discipline" placeholder="{{ localAssessmentCompletedDate.toLocaleDateString() }}" disabled>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="form-group row mt-4">
                    <div class="col-sm-5 "></div>
                    <div class="col-sm-3 ">
                        <button class="btn btn-warning btn-block" type="submit" [disabled]="!assessmentDateForm.valid" *ngIf="assessment.status == 'New'">Continue</button>
                        <button class="btn btn-warning btn-block" *ngIf="assessment.status == 'Started'" (click)="continue($event)">Continue</button>
                    </div>
                    <div class="col-sm-3 ">
                        <button class="btn btn-default btn-block" (click)="startAssessment(false)">Cancel</button>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="showHierarchy">
        <form class="mb-3">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Name</label>
                <div class="col-sm-4 ">
                    <input type="text" class="form-control" id="userName" placeholder="{{ assessment.userName }}" disabled>
                </div>
                <label class="col-sm-2 col-form-label">Org Code</label>
                <div class="col-sm-4 ">
                    <input type="text" class="form-control" id="orgCode" placeholder="{{ assessment.orgCode }}" disabled>
                </div>
            </div>
        </form>
        <hr />
        <app-pahierarchy [jobProfileModel]="jobProfile" [assessmentModel]="assessment"></app-pahierarchy>
    </div>
</div>