<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Add New Remediation</h4>
		</div>
		<div class="float-right">
		</div>
	</div>
	<hr/>
</div>

<div class="container">
	<form [formGroup]="addRemediationForm">
		<!--Delivery Medium-->
		<div *ngIf="mediumList">
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Delivery Medium
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-5">
					<ng-select [allowClear]="true" [items]="mediumList" placeholder="Select delivery medium" name="medium" id="medium" formControlName="medium">
					</ng-select>
					<!-- <select class="form-control" formControlName="medium" name="medium" id="medium" [(ngModel)]="mediumId" required>
						<option *ngFor="let ml of mediumList" [ngValue]="ml.id"> {{ml.text}} </option>
					</select> -->
				</div>
			</div>
		</div>
		<!--description-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Description
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<!--<input noSpaces maxlength="50" autosize type="text" class="form-control" id="description" formControlName="description" name="description">-->
				<textarea noSpaces maxlength="400" autosize type="text" class="form-control" id="description" formControlName="description" name="description"></textarea>
			</div>
		</div>
		<!--type-->
		<div *ngIf="typeList">
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Type
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-5">
					<!-- <select class="form-control" formControlName="type" name="type" id="type" [(ngModel)]="typeId" required>
						<option *ngFor="let tl of typeList" [ngValue]="tl.id"> {{tl.text}} </option>
					</select> -->
					<ng-select [allowClear]="true" [items]="typeList" placeholder="Select type" name="type" id="type" formControlName="type">
					</ng-select>
				</div>
			</div>
		</div>
		<!--COURSE NUMBER-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Course Number</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="50" type="text" class="form-control" id="coursenumber" formControlName="coursenumber" name="coursenumber">
			</div>
		</div>
		<!--course description-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Course Description</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="400" type="text" class="form-control" id="coursedescription" formControlName="coursedescription" name="coursedescription">
			</div>
		</div>
		<!--link-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Link</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="url" class="form-control" id="link" formControlName="link" name="link" (click)="updateLink($event)" pattern="^(?:(ftp|http|https)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$">
			</div>
			<div class="alert alert-danger" role="alert" *ngIf="addRemediationForm.controls.link.hasError('pattern')">
				Your link must contain at least one period(.) and at least one character before the period and at least two charcters after
				the period.</div>
		</div>
		<!--orgcode-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Code</label>
			<div class="col-sm-4">
				<ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId">
				</ng-select>
			</div>
		</div>
		<!--Note-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Note</label>
			<div class="col-sm-10">
				<textarea noSpaces maxlength="400" autosize type="text" class="form-control" id="note" formControlName="note" name="note"></textarea>
			</div>
		</div>
		<!--upload-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Attachment</label>
			<div class="col-sm-10">
				<input type="file" #fileInput id="file" (change)="fileChangeEvent($event)" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
				 multiple>
				<br>
				<label class="col-form-label">( .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf )</label>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Tags</label>
			<div class="col-sm-10">
				<tag-input [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" (onAdd)="addTags($event)" (onRemove)="removeTags($event)"
				 formControlName="remediationTag" name="remediationTag">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="autocompleteItemsAsObjects">
					</tag-input-dropdown>
				</tag-input>
			</div>
		</div>

		<alert></alert>

		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-3">
			</div>
			<!-- <div class="col-sm-3">
                <button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save</button>
            </div>
            <div class="col-sm-3">
                <button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('Remediation')">Cancel</button>
				</div> -->
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()" (click)="save()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()" (click)="saveAndNew()">Save & New</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
			</div>
		</div>
	</form>
</div>