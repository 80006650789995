<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Add New Knowledge</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="col-lg-12 mx-auto">
	<form [formGroup]="addKnowledgeForm">
		<!--first name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Knowledge
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-8">
				<textarea noSpaces type="text" class="form-control" id="knowledge" formControlName="knowledge" name="knowledge" placeholder="Knowledge"
				 maxlength="400" autosize></textarea>
			</div>
			<div class="col-sm-2">
				<div>
					<input (change)="showType($event)" type="checkbox" id="isTestQuestion" formControlName="isTestQuestion"
						name="isTestQuestion"> Test Question
				</div>
				<div *ngIf="showVisualQuestion==true">
					<input (change)="showVisualTypeOnChange($event)"  type="checkbox" id="isVisualQuestion" formControlName="isVisualQuestion"
						name="isVisualQuestion"> Visual Question
				</div>
			</div>
		</div>
		<div class="form-group row" *ngIf="showVisualType==true">
			<label class="col-sm-2 col-form-label"></label>
			<div class="col-sm-10">
				<div>
					<input type="file" #fileInput id="file" (change)="fileChangeEvent($event)" accept=".jpg, .jpeg, .png" multiple>
					<button *ngIf="showQuestionImage==true" type="button" class="btn btn-outline-warning"
						(click)="removeImage(fileInput)">
						<i class="fa fa-times" aria-hidden="true"></i>
					</button>
				</div>
				<div *ngIf="showQuestionImage==true" class="mt-2">
					<img [src]="questionImageUrl" class="img-fluid" style="max-width: 100%; max-height: 350px">
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Code</label>
			<div class="col-sm-4">
				<ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId">
				</ng-select>
			</div>
		</div>

		<!-- Tags -->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Tags</label>
			<div class="col-sm-10">
				<tag-input [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" (onAdd)="addTags($event)" (onRemove)="removeTags($event)"
				 formControlName="knowledgeTag" name="knowledgeTag">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="autocompleteItemsAsObjects">
					</tag-input-dropdown>
				</tag-input>
			</div>
		</div>

		<div class="form-group row" *ngIf="showTypeDiv==true">
			<label class="col-sm-2 col-form-label">Type</label>
			<div class="col-sm-3">
				<select (change)="showAnswer($event)" class="form-control" id="type" formControlName="type" name="type">
					<option>Single</option>
					<option>Multiple</option>
					<option>Boolean</option>
				</select>
			</div>
		</div>
		<div class="form-group row" *ngIf="showVisualType==true">
			<label class="col-sm-2 col-form-label">Type</label>
			<div class="col-sm-3">
				<select (change)="showVisualAnswer($event)" class="form-control" id="visualtypeOpt" formControlName="visualtypeOpt" name="visualtypeOpt">
					<option>Single</option>
					<option>Multiple</option>
				</select>
			</div>
		</div>	
		<div class="form-group row" *ngIf="showBooleanDiv==true">
			<div class="col-sm-3">
				<div>
					<label>
						<input type="radio" id="trueorfalse" formControlName="trueorfalse" name="trueorfalse" value="true" (click)="setBoolean()"> True</label>
					<br>
					<label>
						<input type="radio" id="trueorfalse" formControlName="trueorfalse" name="trueorfalse" value="false" (click)="setBoolean()"> False</label>
				</div>
			</div>
		</div>
		<div class="form-group row" *ngIf="showAnswers==true">
			<div class="col-sm-2">
				<button type="button" class="btn btn-outline-warning" (click)="addAnswer()">
					<i class="fa fa-plus-square fa-fw"></i>&nbsp;Add Answer
				</button>
			</div>
		</div>
		<div *ngIf="showAnswers==true" formArrayName="answers">
			<div *ngFor="let answer of addKnowledgeForm.controls.answers.controls; let i=index">
				<div class="form-group row" [formGroupName]="i">
					<div class="col-sm-10">
						<input noSpaces maxlength="200" type="text" class="form-control" placeholder="Enter Answer" formControlName="answer" value="" name="answer" type="text">
					</div>
					<div class="col-sm-2" >
						<input class="mr-2" *ngIf="showRadioBtns==false" type="checkbox" id="isCorrect" formControlName="isCorrect" name="isCorrect">
						<input class="mr-2" *ngIf="showRadioBtns==true" (change)="setIsCorrect($event,i)" type="radio" id="isCorrect" formControlName="isCorrect" name="isCorrect">
						<button type="button" class="btn btn-outline-warning ml-3" (click)="removeAnswer(i)">
								<i class="fa fa-times" aria-hidden="true"></i>
							</button>
					</div>
					<!-- <div class="col-sm-1">	
					</div> -->
				</div>
			</div>
			
		</div>
		<div *ngIf="showVisualAnswers==true" formArrayName="visualAnswers">
			<div *ngFor="let visualAnswer of addKnowledgeForm.controls.visualAnswers.controls; let i=index">
				<div class="form-group row" [formGroupName]="i">
					<div class="col-sm-6" *ngIf="showImage==false">
						<input noSpaces maxlength="200" type="text" class="form-control" placeholder="Enter Answer"
							formControlName="visualTextAnswer" value="" name="visualTextAnswer" type="text">
					</div>
					<div class="col-sm-6" *ngIf="showImage==true">
						<img [src]="visualAnswer.get('photos').value" class="img-fluid"
							style="max-width: 100%; max-height: 350px;">
					</div>
					<div class="col-sm-4">
						<input type="file" #file (change)="preview($event, i)" accept=".jpg, .jpeg, .png">
					</div>
					<div class="col-sm-2">
						<input class="mr-2" *ngIf="showRadioBtns==false" type="checkbox" id="isCorrectAnswer"
							formControlName="isCorrectAnswer" name="isCorrectAnswer">
						<input class="mr-2" *ngIf="showRadioBtns==true" (change)="setIsCorrectVisualAnswer($event,i)"
							type="radio" id="isCorrectAnswer" formControlName="isCorrectAnswer" name="isCorrectAnswer">
						<button type="button" class="btn btn-outline-warning ml-3" (click)="removeVisualAnswer(i)">
							<i class="fa fa-times" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="alert alert-danger" role="alert" *ngIf="isSelected==false">
			Select the correct answer.</div>
		<div class="alert alert-danger" role="alert" *ngIf="isSelectedMultiple==false && isSelected==true">
			Select Multiple Answers.</div>
		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-3">
			</div>
			<!-- <div class="col-sm-3">
                <button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save</button>
            </div>
            <div class="col-sm-3">
                <button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('Knowledge')">Cancel</button>
				</div> -->
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()" (click)="save()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()" (click)="saveAndNew()">Save & New</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
			</div>
		</div>
	</form>
</div>