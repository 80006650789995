import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { TaskTagService } from './tasktag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-edittasktag',
	templateUrl: './edittasktag.component.html'
})

export class EditTaskTagComponent implements OnInit {
	editTaskTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private taskTagService: TaskTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

	}

	ngOnInit() {
		this.editTaskTag = new FormGroup({
			id: new FormControl(this.taskTagService.curTaskId, Validators.required),
			taskTag: new FormControl(this.taskTagService.curTask, Validators.required)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedToUpdateTask()) {
			this.taskTagService.editTaskTag(this.editTaskTag.value.id, this.editTaskTag.value.taskTag)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully modified the task tag.");
					this.taskTagService.curTask = this.editTaskTag.value.taskTag;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	checkNeedToUpdateTask() {
		if (this.taskTagService.curTask != this.editTaskTag.value.taskTag) {
			return true;
		} else {
			return false;
		}
	}
}