<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Change Theme</h4>
		</div>
		<div class="float-right">
			<div class="row">
			</div>
		</div>
	</div>
	<hr />
</div>
<div class="col-sm-12 mx-auto mb-3">
	<alert></alert>
	<div class="">
		<div class="col-sm-12">
			<h6>Select Color Theme</h6>
		</div>
		<div class="row mx-auto mt-3">
			<div class="col-sm-2" *ngFor="let theme of themeArray">
				<div class="card mb-3" (click)="changeTheme(theme)" [ngClass]="{'card-red': theme.color=='red','card-green': theme.color=='green','card-yellow': theme.color=='yellow','card-blue':theme.color=='blue' }">
					<div class="card-body mx-auto pt-3 pb-3 pl-0 pr-0">
						<i *ngIf="themeColor == theme.color" class="fa fa-check fa-4x" aria-hidden="true"></i>
					</div>
					<div class="card-footer text-center pt-2 pb-2 pl-0 pr-0" [ngClass]="{'text-red': theme.color=='red','text-green': theme.color=='green','text-yellow': theme.color=='yellow','text-blue':theme.color=='blue' }">
						<h6>{{theme.name}}</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-12 mx-auto mt-3">
		<form [formGroup]="appConfigForm">
			<!--application name-->
			<div class="form-group row">
				<label class="col-sm-4 col-form-label">Application Name</label>
				<div class="col-sm-8">
					<input maxlength="50" type="text" class="form-control" id="applicationName" formControlName="applicationName" name="applicationName">
				</div>
			</div>
		</form>
	</div>
	<div class="col-sm-12 mt-2 mb-3">
		<div class="row">
			<div class="col-sm-6"></div>
			<div class="col-sm-3"></div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!checkValid()" (click)='saveTheme()'>Save</button>
			</div>
		</div>
	</div>
	<div class="col-sm-12 mt-3 mb-2">
		<div class="row">
			<div class="col-sm-4">
				<div class="panel panel-default">
					<div class="panel-heading">
						<b>Upload Favicon</b>
					</div>
					<div class="panel-body">
						<input type="file" #faviconInput id="file" (change)="fileChangeEvent($event, 'favicon')" accept=".ico">
						<label class="col-sm-12 col-form-label mt-2 p-0"><b>16 w x 16 h</b></label>
						<label class="col-sm-12 col-form-label mb-1 p-0"><b>.ico (Recommended)</b> or <b>24-bit PNG</b></label>
						<button class="btn btn-warning btn-block mt-2" [disabled]="!checkFavicon()" (click)="uploadFavicon()">Upload</button>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="panel panel-default">
					<div class="panel-heading">
						<b>Upload Login Logo</b>
					</div>
					<div class="panel-body">
						<input type="file" #loginLogoInput id="file" (change)="fileChangeEvent($event, 'loginLogo')" accept=".jpg, .png">
						<label class="col-sm-12 col-form-label mt-2 p-0">less than <b>800 w x</b> less than <b>200 h</b></label>
						<label class="col-sm-12 col-form-label mb-1 p-0"><b>JPG</b> or <b>24-bit PNG</b></label>
						<button class="btn btn-warning btn-block mt-2" [disabled]="!checkLoginLogo()" (click)="uploadLoginLogo()">Upload</button>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="panel panel-default">
					<div class="panel-heading">
						<b>Upload Header Logo</b>
					</div>
					<div class="panel-body">
						<input type="file" #headerLogoInput id="file" (change)="fileChangeEvent($event, 'headerLogo')" accept=".jpg, .png">
						<label class="col-sm-12 col-form-label mt-2 p-0"><b>150 w x 46 h</b></label>
						<label class="col-sm-12 col-form-label mb-1 p-0"><b>JPG</b> or <b>24-bit PNG</b></label>
						<button class="btn btn-warning btn-block mt-2" [disabled]="!checkHeaderLogo()" (click)="uploadHeaderLogo()">Upload</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>