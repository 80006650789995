import { Component } from "@angular/core";
import { CoreCompetencyService } from "../corecompetency.service";
import { AlertService } from "../../../util/alert/alert.service";

@Component({
	selector: 'app-reordercorecompetency',
	templateUrl: './reordercorecompetency.component.html'
})
export class ReorderCoreCompetencyComponent {

	public data: any;
	public ccList = [];
	constructor(private coreCompetencyService: CoreCompetencyService, private alertServices: AlertService) {
		this.loadAllCoreCompetency();
	}

	ngOnit() {

	}

	//load all the core competencies
	public loadAllCoreCompetency() {
		this.data = [];
		this.coreCompetencyService.getAllCoreCompetencies()
			.subscribe(
			data => {
				// console.log('[loadAllCoreCompetency] ' + JSON.stringify(data).toString());
				for (let i = 0; i < data.length; i++) {
					this.data.push({
						'id': data[i].Id,
						'coreCompetency': data[i].Description,
						'orderNum': data[i].OrderNo
					});
				}
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}
	//Move up core competencies
	public moveUpCoreCompetency(data: any, coreCompetency: any) {
		this.ccList = [];
		let cc1;
		// if (data.length == 0 || data.length != 1) {
		cc1 = data.find(item => item.orderNum === coreCompetency.orderNum - 1);
		coreCompetency.orderNum = coreCompetency.orderNum - 1;
		cc1.orderNum = cc1.orderNum + 1;

		this.ccList.push(cc1);
		this.ccList.push(coreCompetency);
		this.coreCompetencyService.updateCoreCompetencyOrder(this.ccList)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);

			},
			error => {
				let err = error.json();
				this.alertServices.clear();
				this.alertServices.handleError(err);

				coreCompetency.orderNum = coreCompetency.orderNum + 1;
				cc1.orderNum = cc1.orderNum - 1;
			}
			)
		// }
	}

	//move down core competencies
	public moveDownCoreCompetency(data: any, coreCompetency: any) {
		this.ccList = [];
		let cc2;

		cc2 = data.find(item => item.orderNum === coreCompetency.orderNum + 1);
		coreCompetency.orderNum = coreCompetency.orderNum + 1;
		cc2.orderNum = cc2.orderNum - 1;

		this.ccList.push(cc2);
		this.ccList.push(coreCompetency);
		this.coreCompetencyService.updateCoreCompetencyOrder(this.ccList)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);

			},
			error => {
				let err = error.json();
				this.alertServices.clear();
				this.alertServices.handleError(err);

				coreCompetency.orderNum = coreCompetency.orderNum - 1;
				cc2.orderNum = cc2.orderNum + 1;
			}
			)
	}
}