import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Http } from '@angular/http';
import { user } from './user';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { AlertService } from '../util/alert/alert.service';

import { SharedService } from '../home/shared.service';

@Component({
	selector: 'app-resetpwd',
	templateUrl: './resetpassword.component.html',
	providers: [UserService]
})
export class ResetPasswordComponent implements OnInit {
	passwordResetForm: FormGroup;

	constructor(private router: Router, private userService: UserService, private alertService: AlertService, private fb: FormBuilder, private sharedService: SharedService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.passwordResetForm = fb.group({
			curPwd: ['', Validators.required],
			newPwd: ['', Validators.required],
			retypeNewPwd: ['', Validators.required]
		}, { validator: matchingPasswords('newPwd', 'retypeNewPwd') })
	}

	onSubmit() {
		const tempUser = new user('', this.passwordResetForm.value.curPwd, '', '', '', false, '', '', localStorage.getItem('userId'), this.passwordResetForm.value.newPwd);
		this.userService.validateCurrentPassword(tempUser)
			.subscribe(
			data => {
				this.alertService.success('Your password has been changed');
				this.passwordResetForm.reset();
			},
			error => {
				let err = error.json();
				this.alertService.handleError(err);
			}
			);

	}

	ngOnInit() {
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}


}

// FORM GROUP VALIDATORS
export function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
	return (group: FormGroup): { [key: string]: any } => {
		let password = group.controls[passwordKey];
		let confirmPassword = group.controls[confirmPasswordKey];

		if (password.value !== confirmPassword.value) {
			return {
				mismatchedPasswords: true
			};
		}
	}
}
