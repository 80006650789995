import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { UserService } from '../user/user.service';

import { orgtype } from './orgtype';
import { OrgtypeService } from './orgtype.service';
import { NoWhitespaceDirective } from "../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-editorgtype',
	templateUrl: './editorgtype.component.html'
})
export class EditOrgTypeComponent implements OnInit {

	public editOrgtype: orgtype;
	editOrgtypeForm: FormGroup;

	constructor(private sharedService: SharedService,
		private orgtypeService: OrgtypeService,
		private alertServices: AlertService,
		private userService: UserService) {
		this.editOrgtype = orgtypeService.getSelectedOrgtypeForEdit();

		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.editOrgtypeForm = new FormGroup({
			orgtype: new FormControl(this.editOrgtype.orgtype, Validators.required),
		});
	}

	onSubmit() {
		if (this.checkNeedToUpdateUser()) {
			const tempOrgType = new orgtype(this.editOrgtype.Id, this.editOrgtypeForm.value.orgtype);
			this.orgtypeService.updateOrgtypeById(tempOrgType)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success(data.message);
					this.editOrgtype.orgtype = this.editOrgtypeForm.value.orgtype;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			//no need to update the user no changes
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	//navigate to org type component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	checkNeedToUpdateUser(): boolean {
		if (this.editOrgtype.orgtype != this.editOrgtypeForm.value.orgtype) {
			return true;
		} else {
			return false;
		}
	}
}