<div #assessmentHistory class="table-responsive mb-1">
    <table class="table-sm table-bordered  table-striped col-sm-12" *ngIf="data.length != 0">
        <thead>
            <tr>
                <th class="text-center ">Job Profile Description</th>
                <th class="text-center ">Scheduled Knowledge Check Date</th>
                <th class="text-center ">Knowledge Check Completed Date</th>
                <th class="text-center ">Scheduled Assessor Lead Date</th>
                <th class="text-center ">Assessment Completed Date</th>
					 <th class="text-center ">Knowledge Check Only</th>
                <th class="text-center ">Remediation Report</th>
                <th class="text-center ">Assessment Report</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data | convertDate : 'allAssessments' ; let i = index">
                    <td class="">{{row.jobProfileDesc }}</td>
                <td class="">{{row.scheduleSelfAssessmentDate == null ? "" : row.scheduleSelfAssessmentDate.toLocaleDateString()}}</td>
                <td class="">{{row.selfAssessmentCompletedDate == null ? "" : row.selfAssessmentCompletedDate.toLocaleDateString()}}</td>
                <td class="">{{row.scheduleAssessmentDate == null ? "" : row.scheduleAssessmentDate.toLocaleDateString() }}</td>
                <td class="">{{row.assessmentCompletedDate == null ? "" : row.assessmentCompletedDate.toLocaleDateString()}}</td>
					 <td class=" text-center">
						 <input *ngIf="row.isKnowledgeTest == 1" type="checkbox" checked disabled>
						 <input *ngIf="row.isKnowledgeTest == 0" type="checkbox" disabled>
					 </td>
                <td class=" text-center">
                    <a style="cursor: pointer" class="btn btn-sm edit " (click)="performAssessmentClickEvent(row, 5)">
                        <i class="fa fa-archive " aria-hidden="true"></i>
                    </a>
                </td>
                <td class=" text-center">
                    <a style="cursor: pointer" class="btn btn-sm edit " (click)="performAssessmentClickEvent(row, 6)">
                        <i class="fa fa-folder " aria-hidden="true"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="alert alert-warning" *ngIf="data.length == 0">
    <a href="#" class="close" data-dismiss="alert"></a>
    <strong>Warning!</strong> There are no completed assessments.
</div>