import { Component, Input, AfterViewInit, ViewChild, ComponentFactoryResolver, OnDestroy, Type, ComponentRef } from '@angular/core';
import { AfterContentInit, ViewContainerRef } from '@angular/core';

import { ItemDirective } from './item.directive';

@Component({
	selector: 'component-holder',
	// template: '<div><ng-template component-host> </ng-template> </div> '
	template: '<div><div #componentHost></div></div>'

})
export class ItemsComponent implements AfterContentInit, OnDestroy {

	@Input() componentItem: any;
	// @ViewChild(ItemDirective) itemHost: ItemDirective;
	@ViewChild('componentHost', { read: ViewContainerRef, static: true }) componentHost: ViewContainerRef;
	componentRef: ComponentRef<Component>;

	constructor(private resolver: ComponentFactoryResolver) { }

	// ngAfterViewInit(){
	//     this.updateComponent();
	// }

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.

		this.updateComponent();
	}

	ngOnDestroy() {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		if (this.componentRef) {
			this.componentRef.destroy();
		}
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add 'implements OnChanges' to the class.
		// this.itemHost.viewContainerRef.clear();
		this.updateComponent();
	}

	updateComponent() {
		// let componentFactory = this.resolver.resolveComponentFactory(this.componentItem);
		// let viewContainerRef = this.itemHost.viewContainerRef;
		// this.componentRef = viewContainerRef.createComponent(componentFactory);
		this.componentHost.clear();
		const authFormFactory = this.resolver.resolveComponentFactory(this.componentItem);
		const component = this.componentHost.createComponent(authFormFactory);
	}
}
