import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import * as Highcharts from 'highcharts/highstock';
import { isNumeric } from 'rxjs/util/isNumeric';
import { FormGroup, FormControl } from "@angular/forms";
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service';
import { UserService } from '../../user/user.service';
import { AssessmentService } from '../assessment.service';
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';

@Component({
	selector: "app-knowledge-test-by-candidate-graphical",
	templateUrl: "./knowledgetestbycandidategraphical.component.html",
})
export class KnowledgeTestByCandidateGraphicalComponent implements OnInit {
	originaldata: Array<any> = [];
	@Input() supervisorId: any;
	chart = Highcharts;
	options;
	categories: Array<any> = [];
	competencyScores: Array<any> = [];
	knowledgeTestScores: Array<any> = [];
	filterForm: FormGroup;
	public orgList: Array<any> = [];
	public jobProfileList: Array<any> = [];
	public supervisorList: Array<any> = [];
	firstTime: boolean = false;
	noResults: boolean = false;
	valid: boolean = true;
	isLoading: boolean = false;

	constructor(private jobProfileService: JobProfileService,
		private userService: UserService,
		private orgcodeService: OrgcodeService,
		private assessmentService: AssessmentService,
		private pipe: OrderArrayByType,
		private alertService: AlertService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.getActiveJobProfiles()
		this.getActiveOrgCodes()
		this.getAllSupervisors()
	}

	ngOnInit() {
		this.init();
		this.firstTime = true
		this.filterForm = new FormGroup({
			orgId: new FormControl(null),
			jobProfileId: new FormControl(null),
			supervisorId: new FormControl(null)
		});
		var param = {
			'orgId': "", 'jobProfileId': "", 'supervisorId': ""
		};
	}

	private getActiveJobProfiles() {
		this.jobProfileService.getAllActiveJobProfiles()
			.subscribe(
			data => {
				this.jobProfileList = [];
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobProfileList.push({ id: data.jobProfileList[i].Id, text: data.jobProfileList[i].Description });
				}
			},
			error => {

			}
			);
	}

	private getActiveOrgCodes() {
		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
				}
			},
			error => {

			}
			);
	}

	private getAllSupervisors() {
		this.userService.getAllSupervisors("")
			.subscribe(
			data => {
				this.supervisorList = [];
				for (var i = 0; i < data.supervisorsdetails.length; i++) {
					let supervisorName = data.supervisorsdetails[i].FirstName + ' ' + data.supervisorsdetails[i].LastName;
					this.supervisorList.push({ id: data.supervisorsdetails[i].Id, text: supervisorName });
				}
			},
			error => {
			}
			);
	}

	onSubmit() {
		this.valid = true
		this.firstTime = false
		let orgId = "";
		if (this.filterForm.get("orgId").value != null) {
			if (this.filterForm.get("orgId").value.length > 0) {
				orgId = this.filterForm.get("orgId").value[0].id;
			}
		}
		let jobProfileId = "";
		if (this.filterForm.get("jobProfileId").value != null) {
			if (this.filterForm.get("jobProfileId").value.length > 0) {
				jobProfileId = this.filterForm.get("jobProfileId").value[0].id;
			}
		}
		let selectedSupId = "";
		if (this.filterForm.get("supervisorId").value != null) {
			if (this.filterForm.get("supervisorId").value.length > 0) {
				selectedSupId = this.filterForm.get("supervisorId").value[0].id;
			}
		}

		if (this.supervisorId) {
			if (!jobProfileId && !orgId) {
				this.valid = false
				this.alertService.clear()
				this.alertService.error("Please select at least one filtering item to see the graph results.")
			}
		} else {
			if (!jobProfileId && !orgId && !selectedSupId) {
				this.valid = false
				this.alertService.clear()
				this.alertService.error("Please select at least one filtering item to see the graph results.")
			}
		}
		if (this.valid) {
			if (!selectedSupId) {
				selectedSupId = this.supervisorId
			}
			this.isLoading = true
			this.assessmentService.getKnowledgeTestByCandidate(selectedSupId, orgId, jobProfileId)
				.subscribe(
				data => {
					this.originaldata = [];
					for (var i = 0; i < data.assessments.length; i++) {
						let score = 0;
						if (data.assessments[i].SelfAssessmentCorrect > 0 && data.assessments[i].SelfAssessmentTotal > 0) {
							score = Math.round(((data.assessments[i].SelfAssessmentCorrect) / data.assessments[i].SelfAssessmentTotal) * 100)
						}
						let completedDate: Date = null
						if (data.assessments[i].SelfAssessmentCompletedDate == null) {
							completedDate = null
						} else {
							completedDate = new Date(new Date(data.assessments[i].SelfAssessmentCompletedDate).getUTCFullYear(), new Date(data.assessments[i].SelfAssessmentCompletedDate).getUTCMonth(), new Date(data.assessments[i].SelfAssessmentCompletedDate).getUTCDate());
						}
						let orgCode = data.assessments[i].OrgCode
						if (data.assessments[i].AssessmentStatus == 'Completed' || data.assessments[i].IsKnowledgeTest == 1) {
							orgCode = data.assessments[i].AssessmentOrgCode
						}
						this.originaldata.push({
							"firstName": data.assessments[i].FirstName,
							"lastName": data.assessments[i].LastName,
							"jobProfile": data.assessments[i].JobProfile,
							"orgCode": orgCode,
							"correct": data.assessments[i].SelfAssessmentCorrect,
							"total": data.assessments[i].SelfAssessmentTotal,
							"completedDate": completedDate,
							"score": score,
							"competencyScore": data.assessments[i].AssessmentScore
						});
					}
					this.isLoading = false
					this.init()
				},
				error => {
					this.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				})
		}
	}

	public clearForm() {
		this.filterForm.reset();
		this.categories = []
		this.knowledgeTestScores = []
		this.competencyScores = []
		this.originaldata = []
		this.init()
		this.noResults = false
		this.valid = true
		this.alertService.clear()
	}

	init() {
		this.categories = []
		this.knowledgeTestScores = []
		this.competencyScores = []

		this.originaldata = this.pipe.transform(this.originaldata, 'scoredesc')

		for (let i = 0; i < this.originaldata.length; i++) {
			this.categories.push(this.originaldata[i].firstName + " " + this.originaldata[i].lastName);
			this.knowledgeTestScores.push(this.originaldata[i].score)
		}

		if (this.firstTime) {
			this.firstTime = false
		}

		if (this.originaldata.length == 0) {
			this.noResults = true
		} else {
			this.noResults = false
		}
		// let max = 10
		// let scroll = true
		// if (this.categories.length < 10) {
		// 	max = this.categories.length
		// 	scroll = false
		// }

		this.options = {
			chart: {
				type: 'column'
			},
			xAxis: {
				categories: this.categories,
				type: "category",
				labels: {
					formatter: function () {
						if (isNumeric(this.value)) {
							return ""
						} else {
							return this.value
						}
					}
				}
			},
			title: {
				text: ''
			},
			yAxis: {
				min: 0,
				max: 100,
				title: {
					text: 'Percentage'
				}
			},
			plotOptions: {
				column: {
					cropThreshold: 25000,
					minPointLength: 3
				}
			},
			credits: {
				enabled: false
			},
			series: [{
				name: 'Knowledge Check Score',
				type: 'column',
				cropThreshold: 100000,
				data: this.knowledgeTestScores,
			}]
		};

	}
}