import { Injectable, Input, Output, EventEmitter } from '@angular/core';

@Injectable()
export class SharedService {
	@Output() fire: EventEmitter<any> = new EventEmitter();

	@Output() hide: EventEmitter<any> = new EventEmitter();

	@Output() activeWidgetHeight: EventEmitter<any> = new EventEmitter();

	@Output() scheduledWidgetHeight: EventEmitter<any> = new EventEmitter();

	@Output() historyWidgetHeight: EventEmitter<any> = new EventEmitter();

	@Output() compSnapshotWidgetHeight: EventEmitter<any> = new EventEmitter();

	@Output() compPercentageHeight: EventEmitter<any> = new EventEmitter();

	@Output() employeeCompPercentageHeight: EventEmitter<any> = new EventEmitter();

	@Output() widgetList: EventEmitter<any> = new EventEmitter()

	@Output() userRole: EventEmitter<any> = new EventEmitter()

	@Output() item: EventEmitter<any> = new EventEmitter()

	@Output() overlay: EventEmitter<boolean> = new EventEmitter<boolean>()

	@Output() refresh: EventEmitter<any[]> = new EventEmitter<any[]>()

	constructor() {

	}

	change(itemName: string) {
		this.fire.emit(itemName);
	}

	getEmittedValue() {
		return this.fire;
	}

	activeHeightChange(height: number) {
		this.activeWidgetHeight.emit(height);
	}

	getActiveWidgetHeight() {
		return this.activeWidgetHeight;
	}

	scheduledHeightChange(height: number) {
		this.scheduledWidgetHeight.emit(height);
	}

	getScheduledWidgetHeight() {
		return this.scheduledWidgetHeight;
	}

	competencyPercentageHeightChange(height: number) {
		this.compPercentageHeight.emit(height);
	}

	getCompetencyPercentageHeight() {
		return this.compPercentageHeight;
	}

	hideComponent(itemName: string) {
		this.hide.emit(itemName);
	}

	getHideValue() {
		return this.hide;
	}

	historyHeightChange(height: number) {
		this.historyWidgetHeight.emit(height);
	}

	getHistoryWidgetHeight() {
		return this.historyWidgetHeight;
	}

	compsanpshotHeightChange(height: number) {
		this.compSnapshotWidgetHeight.emit(height);
	}

	getCompSnapshotWidgettHeight() {
		return this.compSnapshotWidgetHeight;
	}

	employeeCompPercentageHeightChange(height: number) {
		this.employeeCompPercentageHeight.emit(height);
	}

	getEmployeeCompPercentageHeight() {
		return this.employeeCompPercentageHeight;
	}

	widgetListChange(widgetList: any) {
		this.widgetList.emit(widgetList)
	}

	getWidgetList() {
		return this.widgetList
	}

	userRoleChange(userRole: any) {
		this.userRole.emit()
	}

	getUserRole() {
		return this.userRole
	}

	itemChange(item: string) {
		this.item.emit(item);
	}

	getItem() {
		return this.item;
	}


	toggleOverlayIns(val: boolean) {
		this.overlay.emit(val);
	}

	getToggleOverlayValue() {
		return this.overlay;
	}

	setRefreshReport(report: any[]) {		
		this.refresh.emit(report)
	}

	getRefreshReport() {
		return this.refresh
	}

}
