import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Knowledge } from './knowledge.model';
import { KnowledgeAnswer } from './knowledgeanswer';
import { KnowledgeService } from './knowledge.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { KnowledgeTagService } from '../../tag/knowledgetag/knowledgetag.service';
import { CustomModalService } from "../../util/custommodal/custommodal.service";
import { TagInputModule } from 'ngx-chips';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

declare var $: any;

@Component({
	selector: 'app-editknowledge',
	templateUrl: './editknowledge.component.html',
	styleUrls: ['./addknowledge.component.scss']
})
export class EditKnowledgeComponent implements OnInit, AfterViewInit {

	@ViewChild('fileInput', { static: true })
	questionFileInputVariable: ElementRef;

	editKnowledgeForm: FormGroup;
	arrayOfStrings: string[];
	showTypeDiv: boolean = false;
	showBooleanDiv: boolean = false;
	showAnswers: boolean = false;
	showRadioBtns: boolean = false;
	showVisualType: boolean = false;
	isVisualQuestionDiv: boolean = false;
	showVisualAnswers: boolean = false;
	clickVisualQuestion: boolean = false;
	showAddButton: boolean = false;
	showImage: boolean = false;
	isSelected: boolean;
	showQuestionImage: boolean = false;
	showVisualQuestion: boolean = false;
	public selectedOrgId: string;
	public orgList: Array<any> = [];
	public editKnowledge: Knowledge;
	public selectedTags = [];
	public activeTagList = [];
	items = [];
	public trueOrFalse: any;
	public isSelectedMultiple: boolean;
	public ansExists: boolean;
	public editKnowledgeAnswer: Array<KnowledgeAnswer> = [];
	public answerArray: Array<KnowledgeAnswer> = [];
	public dependencyList: Array<any> = [];
	public isLoading: boolean = false;
	itemType = "Knowledge";
	checkedRadioIndex: number = -1;
	public type: string
	public typeList: any[];
	public visualTypeList: any[];
	questionImageUrl: any = '';
	filesToUpload: Array<File> = [];
	questionImageexistingUrl: any = '';
	fileList: File[] = [];
	visualOptionTypevalue: any = ''
	imgURL: any = '';
	visualAnswerList: any = [];
	visualQuestionList: any = [];
	answerList: any = [];
	oldFileType: any;

	constructor(private formBuilder: FormBuilder, private userService: UserService, private alertService: AlertService,
		private knowledgeService: KnowledgeService, private sharedService: SharedService, private orgcodeService: OrgcodeService,
		private knowledgeTagService: KnowledgeTagService, private customModalService: CustomModalService, private sanitizer: DomSanitizer) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.editKnowledge = knowledgeService.getSelectedKnowledgeForEdit();
		this.editKnowledge.tagList.forEach(tag => {
			let item = { value: tag.value, id: tag.id }
			this.items.push(item)
		});

		this.checkForDependencies();
		this.editKnowledge.answerList.forEach(element => {
			let item = { answer: element.answer, isCorrect: element.isCorrect }
			this.editKnowledgeAnswer.push(item)
		})
	}

	checkForDependencies() {
		this.customModalService.invoke(
			this.itemType,
			this.editKnowledge.id,
			this,
			"edit", null
		);
	}

	valid() {
		let result = false;
		if (this.editKnowledgeForm.get("knowledge").status == "VALID" && !this.editKnowledgeForm.value.isTestQuestion) {
			result = true;
		}
		else if (this.editKnowledgeForm.get("knowledge").status == "VALID" && this.editKnowledgeForm.value.isTestQuestion) {
			if ((this.editKnowledgeForm.value.type == "" && this.editKnowledgeForm.value.type == null) ||
				(this.editKnowledgeForm.value.visualtypeOpt == "" && this.editKnowledgeForm.value.visualtypeOpt == null)) {
				result = false;
			}
			else if (this.editKnowledgeForm.get('knowledge').value != this.editKnowledge.knowledge) {
				return true;
			}
			else {
				if (this.compareOrgCodeChanges() || this.compairArrayForChanges(this.editKnowledge.tagList, this.items)) {
					result = true;
				}
				let answerControls = <FormArray>this.editKnowledgeForm.controls['answers'];
				let visualAnswerControls = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
				let count = 0;
				let emptyFieldFound = false;
				let imageValid = false
				if (answerControls.length != 0) {
					for (var i = 0; i < answerControls.length; i++) {

						if (this.editKnowledgeForm.controls.answers.value[i].answer.trim().length == 0) {
							emptyFieldFound = true;
							break;
						}
						if (this.editKnowledgeForm.value.type == "Single") {
							let value = (this.checkedRadioIndex != -1) ? 1 : 0;
							if (value == 1) {
								count++;
								break;
							}

						}
						else {
							let value = (this.editKnowledgeForm.controls.answers.value[i].isCorrect === true) ? 1 : 0;
							if (value == 1) {
								count++;
							}
						}
					}

				} else if (visualAnswerControls.length != 0) {
					for (var i = 0; i < visualAnswerControls.length; i++) {
						if (this.editKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer.trim().length == 0) {
							if (this.showImage == false) {
								emptyFieldFound = true;
								break;
							} else {
								if (visualAnswerControls.length != this.visualAnswerList.length) {
									imageValid = true;
									break;
								}
							}
						}
						if (this.editKnowledgeForm.value.visualtypeOpt == "Single") {
							let value = (this.checkedRadioIndex != -1) ? 1 : 0;
							if (value == 1) {
								count++;
								break;
							}
						}
						else {
							let value = (this.editKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true) ? 1 : 0;
							if (value == 1) {
								count++;
							}
						}
					}
				} else {
					//nothing to do here
				}
				if (!emptyFieldFound && count > 0) {
					if (this.showImage == false) {
						result = true;
					} else {
						if (imageValid && count > 0) {
							result = false;
						} else {
							result = true;
						}
					}
				}
				if (count < 2 && this.editKnowledgeForm.value.type == "Multiple") {
					result = false;
				}
				if (count < 2 && this.editKnowledgeForm.value.visualtypeOpt == "Multiple") {
					result = false;
				}

				if ((0 < answerControls.length && answerControls.length < 2) && this.editKnowledgeForm.value.type == "Single") {
					result = false;
				}
				if ((0 < visualAnswerControls.length && visualAnswerControls.length < 2) && this.editKnowledgeForm.value.visualtypeOpt == "Single") {
					result = false;
				}
				if (this.editKnowledgeForm.value.type == "Boolean" && this.editKnowledge.type == 'Boolean' && ((this.editKnowledgeForm.value.trueorfalse == 'true' && this.editKnowledge.correctAnswer == 0) || (this.editKnowledgeForm.value.trueorfalse == 'false' && this.editKnowledge.correctAnswer == 1))) {
					result = true;
				}
				else if (this.editKnowledgeForm.value.type == "Boolean" && this.editKnowledge.type != 'Boolean' && ((this.editKnowledgeForm.value.trueorfalse == 'true' && this.editKnowledge.correctAnswer == 0) || (this.editKnowledgeForm.value.trueorfalse == 'false'))) {
					result = true;
				}
			}
		}
		return result;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		//console.log('[selected Knowldege]' + JSON.stringify(this.editKnowledge).toString());
		this.loadActiveKnowledgeTags();
		this.editKnowledgeForm = this.formBuilder.group({
			id: new FormControl(null, Validators.required),
			knowledge: new FormControl(null, Validators.required),
			knowledgeTag: new FormControl(''),
			isTestQuestion: new FormControl(null),
			isVisualQuestion: new FormControl(null),
			type: new FormControl(null),
			visualtypeOpt: new FormControl(null),
			isImageAttached: new FormControl(null),
			fileType: new FormControl(null),
			orgId: new FormControl(null),
			trueorfalse: new FormControl(null),
			answers: this.formBuilder.array([]),
			visualAnswers: this.formBuilder.array([])
		});

		this.typeList = [{ id: 'Single', text: 'Single' }, { id: 'Multiple', text: 'Multiple' }, { id: 'Boolean', text: 'Boolean' }];
		this.visualTypeList = [{ id: 'Single', text: 'Single' }, { id: 'Multiple', text: 'Multiple' }];

		// console.log('[id]' +JSON.stringify(this.editKnowledge).toString());
		this.knowledgeService.getKnowledgeById(this.editKnowledge.id)
			.subscribe(
			data => {
				// console.log(JSON.stringify(data));
				// console.log(data.knowledge[0].OrgCodeId);
				if (data.knowledge[0].IsCorrect == 1) {
					this.trueOrFalse = 'true';
				} else if (data.knowledge[0].IsCorrect == 0) {
					this.trueOrFalse = "false";
				} else if (data.knowledge[0].IsCorrect == null) {
					this.trueOrFalse = null;
				}
				this.editKnowledgeForm.setValue({
					id: data.knowledge[0].Id, knowledge: data.knowledge[0].Knowledge, isTestQuestion: data.knowledge[0].IsTestQuestion == 1 ? true : false,
					isVisualQuestion: data.knowledge[0].IsVisualQuestion == 1 ? true : false, type: data.knowledge[0].Type, visualtypeOpt: data.knowledge[0].Type,
					isImageAttached: data.knowledge[0].ImageAttached, fileType: data.knowledge[0].FileType, orgId: "", trueorfalse: this.trueOrFalse, knowledgeTag: this.items, answers: [], visualAnswers: []
				});
				this.showVisualQuestion = ((data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 1) ||
					data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 0) ? true : false;
				this.showAnswers = (data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 0) ? true : false;
				this.showTypeDiv = (data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 0) ? true : false;
				this.showVisualType = (data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 1) ? true : false;
				this.showVisualAnswers = (data.knowledge[0].IsVisualQuestion == 1 && data.knowledge[0].IsTestQuestion == 1) ? true : false;
				this.clickVisualQuestion = (data.knowledge[0].IsVisualQuestion == 1 && data.knowledge[0].IsTestQuestion == 1) ? true : false;
				this.showAddButton = (data.knowledge[0].Type === "Single" || data.knowledge[0].Type === "Multiple") ? true : false;
				this.showRadioBtns = data.knowledge[0].Type === "Single" ? true : false;
				this.showBooleanDiv = data.knowledge[0].Type === "Boolean" ? true : false;
				// this.visualOptionTypevalue = (data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 1) ? data.knowledge[0].Type : "";
				this.oldFileType = data.knowledge[0].FileType
				if (data.knowledge[0].IsTestQuestion == 1 && data.knowledge[0].IsVisualQuestion == 0) {
					this.type = data.knowledge[0].Type
					this.visualOptionTypevalue = ""
				} else {
					this.type = ""
					this.visualOptionTypevalue = data.knowledge[0].Type
				}
				if (data.knowledge[0].ImageAttached == 1) {
					this.showQuestionImage = true
					this.questionImageUrl = environment.server_URL + '/images/knowledge/' + data.knowledge[0].Id + '/' + data.knowledge[0].Id + '.' + data.knowledge[0].FileType
					this.questionImageexistingUrl = environment.server_URL + '/images/knowledge/' + data.knowledge[0].Id + '/' + data.knowledge[0].Id + '.' + data.knowledge[0].FileType
				} else {
					this.showQuestionImage = false
					this.questionImageUrl = ''
				}

				if (this.showAnswers) {
					let control = <FormArray>this.editKnowledgeForm.controls['answers'];
					for (var i = 0; i < data.answers.length; i++) {
						if (data.answers[i].IsCorrect === 1) {
							this.checkedRadioIndex = i;
						}
						control.push(this.formBuilder.group({
							answer: [data.answers[i].Answer, Validators.required],
							isCorrect: [data.answers[i].IsCorrect === 1 ? true : false]
						}));
					}
				} else {
					let visualControl = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
					for (var i = 0; i < data.answers.length; i++) {
						if (data.answers[i].IsCorrect === 1) {
							this.checkedRadioIndex = i;
						}
						if (data.answers[i].Answer != '') {
							this.showImage = false
							visualControl.push(this.formBuilder.group({
								visualAnswerId: [''],
								newVisualAnswerId: [''],
								isImageChanged: [''],
								answerFileType: [''],
								visualTextAnswer: [data.answers[i].Answer, Validators.required],
								isCorrectAnswer: [data.answers[i].IsCorrect === 1 ? true : false],
								photos: ['']
							}));
						} else {
							this.showImage = true
							this.visualAnswerList.push(data.answers[i].Id + data.answers[i].FileType)
							let path = environment.server_URL + '/images/knowledge/' + data.knowledge[0].Id + '/' + data.answers[i].Id + '.' + data.answers[i].FileType
							visualControl.push(this.formBuilder.group({
								visualAnswerId: [data.answers[i].Id],
								newVisualAnswerId: [''],
								isImageChanged: [false],
								answerFileType: [data.answers[i].FileType],
								answerOldFileType: [data.answers[i].FileType],
								visualTextAnswer: [''],
								isCorrectAnswer: [data.answers[i].IsCorrect === 1 ? true : false],
								photos: [path],
							}));
						}
					}
				}
				this.orgcodeService.getActiveOrgcodes()
					.subscribe(
					data => {
						this.orgList = [];
						for (var i = 0; i < data.orgcode.length; i++) {
							this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
							if (this.editKnowledge.orgCode != null) {
								if (data.orgcode[i].Id == this.editKnowledge.orgCode) {
									this.editKnowledgeForm.get("orgId").setValue([{ id: data.orgcode[i].Id, text: data.orgcode[i].Description }])
								}
							}
						}
						// this.selectedOrgId = this.editKnowledgeForm.value.orgCode;
					},
					error => {
					}
					);
			},
			error => {
			}
			);
	}

	initAnswer() {
		return this.formBuilder.group({
			answer: ['', Validators.required],
			isCorrect: ['']
		});
	}

	initVisualAnswer() {
		return this.formBuilder.group({
			visualAnswerId: [''],
			newVisualAnswerId: [''],
			isImageChanged: [''],
			answerFileType: [''],
			answerOldFileType: [''],
			visualTextAnswer: ['', Validators.required],
			isCorrectAnswer: [''],
			photos: ['']
		});
	}

	addAnswer() {
		if (this.clickVisualQuestion == true) {
			if (this.fileList.length == 0 && this.visualAnswerList.length == 0) {
				this.showImage = false
			} else {
				this.showImage = true
				this.valid()
			}
			const control = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
			control.push(this.initVisualAnswer());
		} else {
			const control = <FormArray>this.editKnowledgeForm.controls['answers'];
			control.push(this.initAnswer());
		}
	}

	removeAnswer(i: number) {
		const control = <FormArray>this.editKnowledgeForm.controls['answers'];
		control.removeAt(i);
		if (this.checkedRadioIndex == i) {
			this.checkedRadioIndex = -1;
		}
	}

	removeVisualAnswer(i: number) {
		const control = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		if (this.editKnowledgeForm.controls.visualAnswers.value[i].answerFileType) {
			this.fileList.splice(i, 1);
			this.visualAnswerList.splice(i, 1);
		}
		if (this.checkedRadioIndex == i) {
			this.checkedRadioIndex = -1;
		}
		control.removeAt(i);
	}


	removeall() {
		const control = <FormArray>this.editKnowledgeForm.controls['answers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			control.removeAt(0);
		}
		this.checkedRadioIndex = -1;
	}

	showType(e) {
		if (e.target.checked) {
			this.showTypeDiv = true;
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showVisualType = false;
			this.showAddButton = false;
			this.showVisualQuestion = true;
			this.editKnowledgeForm.get('type').setValidators([Validators.required]);
			this.editKnowledgeForm.get("visualtypeOpt").setValue("");
		} else {
			this.showTypeDiv = false;
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showVisualQuestion = false;
			this.editKnowledgeForm.get('type').setValidators([]);
			this.editKnowledgeForm.get("visualtypeOpt").setValue("");
			this.removeall();
			this.editKnowledgeForm.get("type").setValue("");
		}
		this.editKnowledgeForm.get("trueorfalse").setValue("");
		this.checkedRadioIndex = -1;
		this.editKnowledgeForm.get('type').updateValueAndValidity();
	}

	showVisualTypeOnChange(e) {
		if (e.target.checked) {
			this.showTypeDiv = false;
			this.showVisualType = true;
			this.clickVisualQuestion = true
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showAnswers = false;
			this.showAddButton = false;
			this.editKnowledgeForm.get("type").setValue("");
			this.editKnowledgeForm.get("visualtypeOpt").setValue("");
			this.removeall();
		} else {
			this.showTypeDiv = true;
			this.showVisualType = false
			this.clickVisualQuestion = false
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showVisualAnswers = false
			this.showQuestionImage = false
			this.showAddButton = false;
			this.editKnowledgeForm.get("visualtypeOpt").setValue("");
			this.editKnowledgeForm.get("type").setValue("");
			this.removeAllVisualAnswer()
			this.fileList = []
			this.visualAnswerList = []
		}
		this.editKnowledgeForm.get('visualtypeOpt').updateValueAndValidity();
		this.editKnowledgeForm.get('type').updateValueAndValidity();
		this.checkedRadioIndex = -1;
	}

	setIsCorrect(e, i) {
		let answerControls = <FormArray>this.editKnowledgeForm.controls['answers'];
		for (var j = 0; j < answerControls.length; j++) {
			if (i == j && e.target.checked) {
				this.checkedRadioIndex = i;
				this.editKnowledgeForm.controls.answers.value[j].isCorrect = true;
			} else {
				this.editKnowledgeForm.controls.answers.value[j].isCorrect = false;
			}
		}
	}

	setIsCorrectVisualAnswer(e, i) {
		let answerControls = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		for (var j = 0; j < answerControls.length; j++) {
			if (i == j && e.target.checked) {
				this.checkedRadioIndex = i;
				this.editKnowledgeForm.controls.visualAnswers.value[j].isCorrectAnswer = true;
			} else {
				this.editKnowledgeForm.controls.visualAnswers.value[j].isCorrectAnswer = false;
			}
		}
	}

	removeAllCorrectAnswers() {
		const control = <FormArray>this.editKnowledgeForm.controls['answers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			if (this.editKnowledgeForm.controls.answers.value[i].isCorrect) {
				control.controls[i].get("isCorrect").setValue(false)
			}
		}
		this.checkedRadioIndex = -1;
	}

	removeAllVisualCorrectAnswers() {
		const control = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			if (this.editKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswe) {
				control.controls[i].get("isCorrectAnswer").setValue(false)
			}
		}
		this.checkedRadioIndex = -1;
	}

	removeAllVisualAnswer() {
		const control = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			this.visualQuestionList.splice(i, 1);
			control.removeAt(0);
		}
		this.checkedRadioIndex = -1;
		for (var i = 0; i < this.fileList.length; i++) {
			this.fileList.splice(i, 1);
		}
	}

	showAnswer(e) {
		if (e.target.selectedIndex == 0 || e.target.selectedIndex == 1) {
			this.removeAllCorrectAnswers()
			this.showAnswers = true;
			this.showBooleanDiv = false;
			if (e.target.selectedIndex == 0) {
				this.showRadioBtns = true;
			} else {
				this.showRadioBtns = false;
			}
			this.editKnowledgeForm.get('trueorfalse').setValidators([]);
			this.showAddButton = true
		} else {
			this.removeall();
			this.removeAllVisualAnswer();
			this.showAddButton = false;
			this.showAnswers = false;
			this.showBooleanDiv = true;
			this.showRadioBtns = false;
			this.editKnowledgeForm.get('trueorfalse').setValidators([Validators.required]);
		}
		this.editKnowledgeForm.get("trueorfalse").setValue("");
		this.editKnowledgeForm.get('trueorfalse').updateValueAndValidity();
		this.editKnowledgeForm.get("visualtypeOpt").setValue("");
	}

	showVisualAnswer(e) {
		if (e.target.selectedIndex == 0 || e.target.selectedIndex == 1) {
			this.showVisualAnswers = true
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.removeAllVisualCorrectAnswers()
			if (e.target.selectedIndex == 0) {
				this.showRadioBtns = true;
			} else {
				this.showRadioBtns = false;
			}
			this.showAddButton = true
			this.editKnowledgeForm.get('trueorfalse').setValidators([]);
			this.clickVisualQuestion = true
		} else {
			this.showBooleanDiv = false;
			this.showVisualAnswers = false
			this.showAnswers = false;
			this.showAddButton = false;
			this.clickVisualQuestion = false;
			this.removeAllVisualAnswer()
		}
		this.editKnowledgeForm.get("type").setValue("");

	}

	fileChangeEvent(fileInput: any) {
		this.alertService.clear();
		let filetype = fileInput.target.files[0].name.split('.').pop()
		if (this.checkForValidFile(filetype)) {
			this.showQuestionImage = true
			this.filesToUpload = <Array<File>>fileInput.target.files;
			var reader = new FileReader();
			reader.onload = (event: any) => {
				this.questionImageUrl = event.target.result;
			}
			reader.readAsDataURL(fileInput.target.files[0]);
			this.editKnowledgeForm.get("fileType").setValue(fileInput.target.files[0].name.split('.').pop());
		} else {
			this.alertService.error('Inserted file is invalid.');
			this.questionFileInputVariable.nativeElement.value = ""
			this.showQuestionImage = false
			this.editKnowledgeForm.get("fileType").setValue("");
		}
	}

	preview(event: any, i) {
		this.alertService.clear();
		let fileType = event.target.files[0].name.split('.').pop()
		let answerControls = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		if (this.checkForValidFile(fileType)) {
			this.showImage = true
			for (var j = 0; j < answerControls.length; j++) {
				var selectedFile = event.target.files[0];
				if (i == j && event.target.files && event.target.files[0]) {
					if (this.editKnowledgeForm.controls.visualAnswers.value[i].answerFileType) {
						this.fileList.splice(i, 1);
						this.visualAnswerList.splice(i, 1);
					}
					this.fileList.push(selectedFile);
					this.visualAnswerList.push(event.target.files[0].name)
					answerControls.controls[i].get("isImageChanged").setValue(true)
					answerControls.controls[i].get("newVisualAnswerId").setValue(event.target.files[0].name)
					answerControls.controls[i].get("visualTextAnswer").setValue('')
					answerControls.controls[i].get("answerFileType").setValue(event.target.files[0].name.split('.').pop())
					var reader = new FileReader();
					reader.onload = (event: any) => {
						this.imgURL = event.target.result;
						answerControls.controls[i].get("photos").setValue(this.imgURL)
					}
					reader.readAsDataURL(event.target.files[0]);
				}
			}
		} else {
			this.fileList.splice(i, 1);
			this.visualAnswerList.splice(i, 1);
			answerControls.controls[i].get("photos").setValue("")
			event.srcElement.value = null;
			this.alertService.error('Inserted file(s) are invalid.');
		}
	}

	private checkForValidFile(ext) {
		switch (ext.toLowerCase()) {
			case 'jpg':
			case 'gif':
			case 'bmp':
			case 'png':
				return true;
		}
		return false;
	}

	ngAfterViewInit() {
	}

	saveKnowledge() {
		this.isLoading = true
		let orgId = null;
		if (this.editKnowledgeForm.get("orgId").value != null) {
			if (this.editKnowledgeForm.get("orgId").value.length > 0) {
				orgId = this.editKnowledgeForm.get("orgId").value[0].id;
			}
		}

		let isTestQue = this.editKnowledgeForm.value.isTestQuestion === true ? 1 : 0;
		let isVisualQuestion = this.editKnowledgeForm.value.isVisualQuestion === true ? 1 : 0;
		const knowledge = new Knowledge(this.editKnowledgeForm.value.knowledge, isTestQue);
		knowledge.isVisualQuestion = isVisualQuestion
		if (this.editKnowledgeForm.value.type != "") {
			knowledge.type = this.editKnowledgeForm.value.type;
		}
		if (this.editKnowledgeForm.value.visualtypeOpt != "") {
			knowledge.type = this.editKnowledgeForm.value.visualtypeOpt
		}
		knowledge.correctAnswer = this.editKnowledgeForm.value.trueorfalse === "true" ? 1 : 0;
		knowledge.correctAnswer = this.editKnowledgeForm.value.type != "Boolean" ? null : knowledge.correctAnswer;
		knowledge.id = this.editKnowledgeForm.value.id;
		knowledge.orgCode = orgId;
		knowledge.tagList = this.editKnowledgeForm.value.knowledgeTag;
		if (this.filesToUpload.length != 0) {
			knowledge.isImageAttached = 1
		} else {
			knowledge.isImageAttached = this.editKnowledgeForm.value.isImageAttached
		}
		knowledge.fileType = this.editKnowledgeForm.value.fileType
		let ansArray: Array<KnowledgeAnswer> = [];
		let answerControls = <FormArray>this.editKnowledgeForm.controls['answers'];
		let visualAnswerControls = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		let count = 0;
		if (answerControls.length != 0) {
			for (var i = 0; i < answerControls.length; i++) {
				//console.log(this.editKnowledgeForm.controls.answers.value[i].isCorrect);
				let value = this.editKnowledgeForm.controls.answers.value[i].isCorrect === true ? 1 : 0;
				if (value == 1) {
					count++;
				}
				//console.log(this.editKnowledgeForm.controls.answers.value[i].answer + " "+ value);
				let curAnswer = new KnowledgeAnswer(this.editKnowledgeForm.controls.answers.value[i].answer, value);
				ansArray.push(curAnswer);
			}
		} else if (visualAnswerControls.length != 0) {
			for (var i = 0; i < visualAnswerControls.length; i++) {
				let value = this.editKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true ? 1 : 0;
				if (value == 1) {
					count++;
				}
				let answer = this.editKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer
				let answerId = this.editKnowledgeForm.controls.visualAnswers.value[i].visualAnswerId
				let newAnswerId = this.editKnowledgeForm.controls.visualAnswers.value[i].newVisualAnswerId
				let fileType = this.editKnowledgeForm.controls.visualAnswers.value[i].answerFileType
				let isImageChanged = this.editKnowledgeForm.controls.visualAnswers.value[i].isImageChanged
				let answerOldFileType = this.editKnowledgeForm.controls.visualAnswers.value[i].answerOldFileType
				if (answer != '') {
					let curAnswer = new KnowledgeAnswer(this.editKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer, value);
					ansArray.push(curAnswer);
				} else {
					let curAnswer = new KnowledgeAnswer(this.editKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer, value, answerId, newAnswerId, fileType, isImageChanged, answerOldFileType);
					ansArray.push(curAnswer);
				}

			}
		} else {

		}

		if (count > 0) {
			this.isSelected = true;
		} else {
			this.isSelected = false;
			if (knowledge.type == "Boolean" || knowledge.isTestQuestion == 0) {
				this.isSelected = true;
			}
			else if (knowledge.type == "Single" && this.checkedRadioIndex != -1) {
				ansArray[this.checkedRadioIndex].isCorrect = 1;
				this.isSelected = true;
			}
		}

		if (count < 2 && knowledge.type == "Multiple") {
			this.isSelectedMultiple = false;
		} else {
			this.isSelectedMultiple = true;
		}

		if (this.isSelected) {
			if (this.isSelectedMultiple) {
				this.answerList = []
				this.knowledgeService.editKnowledge(knowledge, ansArray, [], [])
					.subscribe(
					data => {
						if (this.fileList.length != 0) {
							this.answerList = data.answerList
							this.knowledgeService.makeFileRequests([], this.fileList, this.editKnowledge.id, this.answerList)
								.subscribe(
								data => {

								},
								error => {
									this.isLoading = false
									this.alertService.clear();
									let err = error.json();
									this.alertService.handleError(err);
								}
								);
						}
						if (this.filesToUpload.length != 0) {
							this.knowledgeService.makeFileRequest([], this.filesToUpload, this.editKnowledge.id, this.oldFileType)
								.subscribe(
								data => {

								},
								error => {
									this.isLoading = false
									this.alertService.clear();
									let err = error.json();
									this.alertService.handleError(err);
								}
								);

						}
						this.isLoading = false
						this.alertService.clear();
						this.alertService.success('Successfully modified the knowledge.');
						this.editKnowledge.knowledge = this.editKnowledgeForm.value.knowledge;
						this.editKnowledge.tagList = []
						this.items.forEach(tag => {
							let item = { value: tag.value, id: tag.id }
							this.editKnowledge.tagList.push(item)
						});
						this.editKnowledge.orgCode = orgId;
						this.editKnowledge.type = this.editKnowledgeForm.value.type;
						this.trueOrFalse = this.editKnowledgeForm.value.trueorfalse;
						this.editKnowledge.isTestQuestion = this.editKnowledgeForm.value.isTestQuestion;
						this.editKnowledge.correctAnswer = this.editKnowledgeForm.value.trueorfalse == 'true' ? 1 : 0;
						this.editKnowledgeAnswer = ansArray;
						this.editKnowledge.fileType = knowledge.fileType
						
					},
					error => {
						this.isLoading = false;
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
					);

			} else {
				this.alertService.clear();
				this.alertService.error('Select Multiple Answers.');
			}
		}
	}

	onSubmit() {
		this.answerArray = [];
		this.ansExists = true;
		let answerControls = <FormArray>this.editKnowledgeForm.controls['answers'];
		let visualAnswerControls = <FormArray>this.editKnowledgeForm.controls['visualAnswers'];
		let count = 0;
		if (answerControls.length != 0) {
			for (var i = 0; i < answerControls.length; i++) {
				let value = this.editKnowledgeForm.controls.answers.value[i].isCorrect === true ? 1 : 0;
				let answer = this.editKnowledgeForm.controls.answers.value[i].answer;
				let fullAnswer = new KnowledgeAnswer(this.editKnowledgeForm.controls.answers.value[i].answer, value);
				if (this.answerArray.length == 0) {
					this.answerArray.push(fullAnswer);
				} else if (!this.isAnswerAlreadyExists(this.answerArray, answer)) {
					this.answerArray.push(fullAnswer);
				} else {
					count++;
				}
			}
		} else if (visualAnswerControls.length != 0) {
			for (var i = 0; i < visualAnswerControls.length; i++) {
				let value = this.editKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true ? 1 : 0;
				let answer = this.editKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer;
				let fullAnswer = new KnowledgeAnswer(this.editKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer, value);
				if (this.answerArray.length == 0) {
					this.answerArray.push(fullAnswer);
				} else if (!this.isAnswerAlreadyExists(this.answerArray, answer)) {
					this.answerArray.push(fullAnswer);
				} else {
					count++;
				}
			}
		} else {

		}

		if (count > 0) {
			this.ansExists = false
		}

		if (this.ansExists) {
			if (this.checkNeedToUpdateKnowledge() || (this.editKnowledge.knowledge != this.editKnowledgeForm.value.knowledge)) {
				if (this.dependencyList.length > 0) {
					if (this.checkNeedToUpdateKnowledge()) {
						this.alertService.clear()
						this.alertService.error("This knowledge is currenly being used. You are only allowed to modify the knowledge text.")
					} else {
						this.saveKnowledge()
					}
				} else {
					this.saveKnowledge()
				}
			} else {
				//No need to update knowledge
				this.alertService.clear();
				this.alertService.error('There are no changes to update.');
			}
		} else {
			this.alertService.clear();
			this.alertService.error('Same Answers exists.');
		}
	}

	public isAnswerAlreadyExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.answer == value) {
				if (a.answer) {
					isExists = true;
				} else {
					isExists = false;
				}
			}
		});
		return isExists;
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	public checkNeedToUpdateKnowledge(): boolean {
		if (this.editKnowledge.orgCode === "") {
			this.editKnowledge.orgCode = null;
		}

		let orgId = null;
		if (this.editKnowledgeForm.get("orgId").value != null) {
			if (this.editKnowledgeForm.get("orgId").value.length > 0) {
				orgId = this.editKnowledgeForm.get("orgId").value[0].id;
			}
		}

		if (!this.editKnowledge.type) {
			this.editKnowledge.type = null;
		}
		let type = null
		if (this.editKnowledgeForm.value.type) {
			type = this.editKnowledgeForm.value.type
		}

		let fileType = null
		if (this.editKnowledgeForm.value.fileType) {
			fileType = this.editKnowledgeForm.value.fileType
		}

		if (this.compairArrayForChanges(this.editKnowledge.tagList, this.items)) {
			return true;
		} else if (this.filesToUpload.length != 0 || (this.editKnowledge.fileType && !fileType)) {
			return true;
		} else if (this.editKnowledge.orgCode != orgId) {
			return true;
		} else if (this.editKnowledge.type != type) {
			return true;
		} else if (this.trueOrFalse != this.editKnowledgeForm.value.trueorfalse) {
			return true;
		} else if (this.compairAnswerArrayForChanges(this.editKnowledgeAnswer, this.answerArray)) {
			return true;
		} else {
			return false;
		}

	}
	
    public compareOrgCodeChanges(): boolean {
        let hasChanges: boolean = false;
		let orgId = "";
		if (this.editKnowledgeForm.get("orgId").value != null) {
			if (this.editKnowledgeForm.get("orgId").value.length > 0) {
				orgId = this.editKnowledgeForm.get("orgId").value[0].id;
			}
		}
		if (this.editKnowledge.orgCode != orgId) {
			hasChanges = true;
		}
        return hasChanges;
    }

	public compairArrayForChanges(arrayOld: any, arrayNew: any): boolean {
		//console.log('[compairArrayForChanges] (arrayOld) ' + JSON.stringify(arrayOld).toString())
		//console.log('[compairArrayForChanges] (arrayNew) ' + JSON.stringify(arrayNew).toString())
		let haveChanges: boolean = false
		if (arrayOld.length != arrayNew.length) {
			haveChanges = true
		} else {
			let missing = []
			arrayNew.forEach(newItem => {
				if (!(this.isValueExists(arrayOld, newItem.value))) {
					missing.push(newItem)
				}
			});
			if (missing.length == 0) {
				haveChanges = false
			} else {
				haveChanges = true
			}
		}
		return haveChanges;
	}

	public isValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.value == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	public compairAnswerArrayForChanges(arrayOld: any, arrayNew: any): boolean {
		let haveChanges: boolean = false
		if (arrayOld.length != arrayNew.length) {
			haveChanges = true
		} else {
			let missing = []
			arrayNew.forEach(newItem => {
				if (!(this.isFullAnswerExists(arrayOld, newItem))) {
					missing.push(newItem)
				}
			});
			if (missing.length == 0) {
				haveChanges = false
				if (this.isAnswerChanged(arrayOld, arrayNew)) {
					haveChanges = true
				}
			} else {
				haveChanges = true
			}
		}
		return haveChanges;
	}

	public isAnswerChanged(arrayOld: any, arrayNew: any): boolean {
		var result = false
		for (var i = 0; i < arrayOld.length; i++) {
			if (arrayOld[i].isCorrect != arrayNew[i].isCorrect) {
				result = true
			}
		}
		return result
	}

	public isFullAnswerExists(list: any[], value): boolean {
		var isExists = false;
		list.forEach(a => {
			let aAnswer = ''
			let vAnswer = ''
			if (a.answer) {
				aAnswer = a.answer
			}
			if (value.answer) {
				vAnswer = value.answer
			}
			if (aAnswer == vAnswer && a.isCorrect == value.isCorrect) {
				isExists = true;
			}
		});
		return isExists;
	}

	public loadActiveKnowledgeTags() {
		this.knowledgeTagService.getActiveKnowledgeTags()
			.subscribe(
			data => {
				//console.log('[loadActiveChecklistTags] ' + JSON.stringify(data).toString());
				this.activeTagList = []
				data.ccTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.activeTagList.push(item);
				});
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		this.items.push(event);
		// console.log('[addTags] (this.items) ' + JSON.stringify(this.items).toString());
	};

	public removeTags(event: any) {
		//console.log('[removeTags]' + JSON.stringify(event).toString());
		this.items = this.items.filter(tag => tag.id !== event.id);
	};

	public removeImage(element) {
		element.value = ""
		this.showQuestionImage = false
		this.editKnowledgeForm.get("fileType").setValue("");
		this.editKnowledgeForm.get("isImageAttached").setValue(0);
	}

}
