import { TableComponent } from "../util/table/table.component";
import { Report } from "./report.model";

export class ReportTableComponent extends TableComponent {

	public originalData: Array<any> = [];
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"],
		report: null
	};

	public onChangeTable(
		config: any,
		page: any = { page: this.page, itemsPerPage: this.itemsPerPage }
	): any {
		if (config.filtering) {
			Object.assign(this.config.filtering, config.filtering);
		}

		if (config.sorting) {
			Object.assign(this.config.sorting, config.sorting);
		}

		for (var i = 0; i < this.originalData.length; i++) {
			this.data = this.originalData.map(x => Object.assign({}, x));
		}

		let filteredData = this.changeFilter(this.data, this.config);
		let sortedData = this.changeSort(filteredData, this.config);
		this.rows =
			page && config.paging ? this.changePage(page, sortedData) : sortedData;
		this.length = sortedData.length;
		this.config.report.reportDataList = sortedData;
		this.extendData();
	}
}
