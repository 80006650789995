
import { Injectable, Component } from '@angular/core';

import { AllUsersComponent } from '../user/users/allusers.component';
import { AddUserComponent } from '../user/users/adduser.component';
import { ProfileComponent } from '../user/profile.component';
import { ResetPasswordComponent } from '../user/resetpassword.component';
import { OrgCodeComponent } from '../orgcode/orgcode.component'
import { EditUserComponent } from '../user/users/edituser.component';
import { OrgTypeComponent } from '../orgtype/orgtype.component';
import { AddOrgTypeComponent } from '../orgtype/addorgtype.component';
import { EditOrgTypeComponent } from '../orgtype/editorgtype.component';
import { KnowledgesComponent } from '../complib/knowledge/allknowledges.component';
import { AddKnowledgeComponent } from '../complib/knowledge/addknowledge.component';
import { EditKnowledgeComponent } from '../complib/knowledge/editknowledge.component';
import { AssignUserRoleComponent } from '../user/users/assignuserrole.component';
import { AllTaskComponent } from '../complib/task/alltask.component';
import { AddTaskComponent } from '../complib/task/addtask.component';
import { EditTaskComponent } from '../complib/task/edittask.component';
import { AllCoreCompetencyComponent } from '../complib/corecompetency/allcorecompetency.component';
import { AddCoreCompetencyComponent } from '../complib/corecompetency/addcorecompetency.component';
import { EditCoreCompetencyComponent } from '../complib/corecompetency/editcorecompetency.component';
import { AllChecklistComponent } from '../complib/checklist/allchecklist.component';
import { AddChecklistComponent } from '../complib/checklist/addchecklist.component';
import { EditChecklistComponent } from '../complib/checklist/editchecklist.component';
import { DisciplineComponent } from '../discipline/discipline.component';
import { AddDisciplineComponent } from '../discipline/adddiscipline.component';
import { EditDisciplineComponent } from '../discipline/editdiscipline.component';
import { RemediationComponent } from '../complib/remediation/remediation.component';
import { AddRemediationComponent } from '../complib/remediation/addremediation.component';
import { EditRemediationComponent } from '../complib/remediation/editremediation.component';
import { AllPerformanceComponent } from '../complib/performance/allperformance.component';
import { AddPerformanceComponent } from '../complib/performance/addperformance.component';
import { EditPerformanceComponent } from '../complib/performance/editperformance.component';
import { AllJobProfileComponent } from '../jobprofile/alljobprofile.component';
import { AddEditJobProfileComponent } from '../jobprofile/addeditjobprofile.component';
import { AssessmentCalendarComponent } from '../assessment/assessmentcalendar.component';
import { ScheduleAssessmentComponent } from '../assessment/scheduleassessment.component';
import { AllCoreCompetencyTagComponent } from '../tag/corecompetencytag/allcorecompetencytag.component';
import { AddCoreCompetencyTagComponent } from '../tag/corecompetencytag/addcorecompetencytag.component';
import { EditCoreCompetencyTagComponent } from '../tag/corecompetencytag/editcorecompetencytag.component';
import { AllTaskTagComponent } from '../tag/tasktag/alltasktag.component';
import { AddTaskTagComponent } from '../tag/tasktag/addtasktag.component';
import { EditTaskTagComponent } from '../tag/tasktag/edittasktag.component';
import { AllPerformanceTagComponent } from '../tag/performancetag/allperformancetag.component';
import { AddPerformanceTagComponent } from '../tag/performancetag/addperformancetag.component';
import { EditPerformanceTagComponent } from '../tag/performancetag/editperformancetag.component';
import { AllChecklistTagComponent } from '../tag/checklisttag/allchecklisttag.component';
import { AddChecklistTagComponent } from '../tag/checklisttag/addchecklisttag.component';
import { EditChecklistTagComponent } from '../tag/checklisttag/editchecklisttag.component';
import { AllRemediationTagComponent } from '../tag/remediationtag/allremediationtag.component';
import { AddRemediationTagComponent } from '../tag/remediationtag/addremediationtag.component';
import { EditRemediationTagComponent } from '../tag/remediationtag/editremediationtag.component';
import { AllKnowledgeTagComponent } from '../tag/knowledgetag/allknowledgetag.component';
import { AddKnowledgeTagComponent } from '../tag/knowledgetag/addknowledgetag.component';
import { EditKnowledgeTagComponent } from '../tag/knowledgetag/editknowledgetag.component';
import { Assessment } from '../assessment/allassessment.component';
import { PerformAssessment } from '../assessment/performassessment/performassessment.component';
import { ReportComponent } from '../report/report.component';
import { RemediationReportComponent } from '../report/remediationreport/remediationreport.component';
import { GenerateReportComponent } from '../report/generatereport.component'
import { CompairJobProfileComponent } from '../jobprofile/compairjobprofile/compairjobprofile.component'
import { JobProgressionPlanningComponent } from "../jobprofile/jobprogressionplanning/jobprogressionplanning.component"
import { AssessmentRemediationReportComponent } from '../report/remediationreport/assessmentremediationreport.component';
import { CandidateAssessmentComponent } from '../assessment/candidateassessment/candidateassessment.component';
import { StaticDashboardComponent } from '../dashboard/staticdashboard.component'
import { SuccessionPlanningComponent } from '../assessment/successionplanning/successionplanning.component'
import { AllTaskGroupComponent } from "../complib/task/taskgroup/alltaskgroup.component";
import { AddTaskGroupComponent } from "../complib/task/taskgroup/addtaskgroup.component";
import { EditTaskGroupComponent } from "../complib/task/taskgroup/edittaskgroup.component";
import { ReorderCoreCompetencyComponent } from "../complib/corecompetency/reordercorecompetency/reordercorecompetency.component";
import { CandidateCompetencyComponent } from '../assessment/candidatecompetency/candidatecompetency.component';
import { CompetencyAnalysisToolComponent } from '../assessment/competencyanalysistool/competencyanalysis.component'
import { AllNoteComponent } from '../complib/note/allnote.component';
import { AddNoteComponent } from '../complib/note/addnote.component';
import { EditNoteComponent } from '../complib/note/editnote.component';
import { ChangeTheme } from '../theme/changetheme.component'
import { SelfAssessmentReview } from '../assessment/selfassessmentreview/selfassessmentreview.component'
import { AllNAReasonComponent } from '../complib/nareason/allnareason.component';
import { AddNAReasonComponent } from '../complib/nareason/addnareason.component';
import { EditNAReasonComponent } from '../complib/nareason/editnareason.component';
import { AllRegulatoryAgency } from '../complib/regulatoryagency/allregulatoryagency.component';
import { AddRegulatoryAgencyComponent } from '../complib/regulatoryagency/addregulatoryagency.component'
import { EditRegulatoryAgencyComponent } from '../complib/regulatoryagency/editregulatoryagency.component'
import { RegulatoryComplianceReportComponent } from '../report/regulatorycompliancereport/regulatorycompliancereport.component'
import { RemediationMatrixReportComponent } from '../report/remediationmatrix/remediationmatrixreport.component'
import { MissedTaskMatrixReportComponent } from '../report/missedtaskmatrix/missedtaskmatrixreport.component'
import { KnowledgeTestByCandidateComponent } from '../assessment/knowledgetestbycandidate/knowledgetestbycandidate.component'
import { CandidateCompetencyGraphicalViewComponent } from '../assessment/candidatecompetency/candidatecompetencygraphicalview.component'

@Injectable()
export class ItemService {
	loadItem(itemName: String): any {
		switch (itemName) {
			case "Dashboard":
				return StaticDashboardComponent;
			case "AllUsers":
				return AllUsersComponent;
			case "Profile":
				return ProfileComponent;
			case "AddUser":
				return AddUserComponent;
			case "ResetPassword":
				return ResetPasswordComponent;
			case "OrgCode":
				return OrgCodeComponent;
			case "EditUser":
				return EditUserComponent;
			case "OrgType":
				return OrgTypeComponent;
			case "AddOrgtype":
				return AddOrgTypeComponent;
			case "EditOrgtype":
				return EditOrgTypeComponent
			case "Knowledge":
				return KnowledgesComponent;
			case "AddKnowledge":
				return AddKnowledgeComponent;
			case "EditKnowledge":
				return EditKnowledgeComponent;
			case "AssignUserRole":
				return AssignUserRoleComponent;
			case "AllTask":
				return AllTaskComponent;
			case "AddTask":
				return AddTaskComponent;
			case "EditTask":
				return EditTaskComponent;
			case "CoreCompetency":
				return AllCoreCompetencyComponent;
			case "AddCoreCompetency":
				return AddCoreCompetencyComponent;
			case "EditCoreCompetency":
				return EditCoreCompetencyComponent;
			case "Checklist":
				return AllChecklistComponent;
			case "AddChecklist":
				return AddChecklistComponent;
			case "EditChecklist":
				return EditChecklistComponent;
			case "Discipline":
				return DisciplineComponent;
			case "AddDiscipline":
				return AddDisciplineComponent
			case "EditDiscipline":
				return EditDisciplineComponent;
			case "AllPerformance":
				return AllPerformanceComponent;
			case "AddPerformance":
				return AddPerformanceComponent;
			case "EditPerformance":
				return EditPerformanceComponent;
			case "AllJobProfile":
				return AllJobProfileComponent;
			case "AddJobProfile":
				return AddEditJobProfileComponent;
			case "Remediation":
				return RemediationComponent;
			case "AddRemediation":
				return AddRemediationComponent;
			case "EditRemediation":
				return EditRemediationComponent;
			case "Calendar":
				return AssessmentCalendarComponent;
			case "ScheduleAssessment":
				return ScheduleAssessmentComponent;
			case 'CoreCompetencyTag':
				return AllCoreCompetencyTagComponent;
			case 'AddCoreCompetencyTag':
				return AddCoreCompetencyTagComponent;
			case 'EditCoreCompetencyTag':
				return EditCoreCompetencyTagComponent;
			case 'AllTaskTag':
				return AllTaskTagComponent;
			case 'AddTaskTag':
				return AddTaskTagComponent;
			case 'EditTaskTag':
				return EditTaskTagComponent;
			case "AllPerformanceTag":
				return AllPerformanceTagComponent;
			case "AddPerformanceTag":
				return AddPerformanceTagComponent;
			case "EditPerformanceTag":
				return EditPerformanceTagComponent;
			case "ChecklistTag":
				return AllChecklistTagComponent;
			case "AddChecklistTag":
				return AddChecklistTagComponent;
			case "EditChecklistTag":
				return EditChecklistTagComponent;
			case "RemediationTag":
				return AllRemediationTagComponent;
			case "AddRemediationTag":
				return AddRemediationTagComponent;
			case "EditRemediationTag":
				return EditRemediationTagComponent;
			case "KnowledgeTag":
				return AllKnowledgeTagComponent;
			case "AddKnowledgeTag":
				return AddKnowledgeTagComponent;
			case "EditKnowledgeTag":
				return EditKnowledgeTagComponent;
			case "Assessments":
				return Assessment;
			case "PerformAssessment":
				return PerformAssessment;
			case "Report":
				return ReportComponent;
			case 'GenerateReport':
				return GenerateReportComponent;
			case 'CompairJobProfile':
				return CompairJobProfileComponent;
			case 'RemediationReport':
				return RemediationReportComponent;
			case 'JobProgressionPlanning':
				return JobProgressionPlanningComponent;
			case 'AssessmentRemediationReport':
				return AssessmentRemediationReportComponent;
			case 'CandidateAssessment':
				return CandidateAssessmentComponent;
			case 'SuccessionPlanning':
				return SuccessionPlanningComponent;
			case 'AllTaskGroup':
				return AllTaskGroupComponent;
			case 'AddTaskGroup':
				return AddTaskGroupComponent
			case 'EditTaskGroup':
				return EditTaskGroupComponent
			case 'ReorderCoreCompetency':
				return ReorderCoreCompetencyComponent;
			case 'CandidateCompetency':
				return CandidateCompetencyComponent;
			case 'CompetencyAnalysis':
				return CompetencyAnalysisToolComponent;
			case "AllNote":
				return AllNoteComponent;
			case "AddNote":
				return AddNoteComponent;
			case "EditNote":
				return EditNoteComponent;
			case "ChangeTheme":
				return ChangeTheme
			case "SelfAssessmentReview":
				return SelfAssessmentReview;
			case "AllNAReason":
				return AllNAReasonComponent;
			case "AddNAReason":
				return AddNAReasonComponent;
			case "EditNAReason":
				return EditNAReasonComponent;
			case "AllRegulatoryAgency":
				return AllRegulatoryAgency;
			case "AddRegulatoryAgency":
				return AddRegulatoryAgencyComponent;
			case "EditRegulatoryAgency":
				return EditRegulatoryAgencyComponent;
			case "RegulatoryCompliance":
				return RegulatoryComplianceReportComponent;
			case "RemediationMatrix":
				return RemediationMatrixReportComponent;
			case "MissedTaskMatrix":
				return MissedTaskMatrixReportComponent;
			case "KnowledgeTestByCandidate":
				return KnowledgeTestByCandidateComponent;
			case "CandidateCompetencyGraphicalView":
			  	return CandidateCompetencyGraphicalViewComponent;
			default:
				return StaticDashboardComponent;
		}
	}
}
