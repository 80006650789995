import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ReportTableComponent } from "../reporttable.component";

import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";

import { Report } from "../report.model";

@Component({
	selector: "app-missed-assessment-data-report",
	templateUrl: "./missedassessmentdatareport.component.html"
})
export class MissedAssessmentDataReportComponent extends ReportTableComponent
	implements OnInit {
	public report: Report;
	public excelData: any[];
	public columns: Array<any>;
	private serverResultData: Array<any> = [];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"],
		report: null
	};

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
		if (this.report.reportName == "Most missed performance") {
			this.columns = [
				{
					title: "Performance",
					name: "Performance",
					filtering: {
						filterString: ""
					}
				},
				{
					title: "Occurrences",
					name: "Occurrences",
					filtering: { filterString: "" }
				},
				{
					title: "Percentage",
					name: "Percentage",
					filtering: { filterString: "" }
				}
			];
		} else {
			this.columns = [
				{
					title: "Knowledge",
					name: "Knowledge",
					filtering: {
						filterString: ""
					}
				},
				{
					title: "Occurrences",
					name: "Occurrences",
					filtering: { filterString: "" }
				},
				{
					title: "Percentage",
					name: "Percentage",
					filtering: { filterString: "" }
				}
			];
		}

		this.config.sorting = { columns: this.columns };
	}

	ngOnInit() {
		this.loadAssessmentData(this.report.reportData);
	}

	/**
	 * [onCellClick cell click event in table]
	 * @param  {any} data [description]
	 * @return {any}      [description]
	 */
	public onCellClick(data: any): any { }
	//no need to impletement event
	private loadAssessmentData(data: any) {
		this.excelData = [];
		for (let i = 0; i < data.length; i++) {
			if (data[i].IncorrectCount != 0) {
				let percentage = (data[i].IncorrectCount / data[i].TotalCount * 100).toFixed(0) + '%';
				let incorrectAnswers = '' + data[i].IncorrectCount;
				if (this.report.reportName == "Most missed performance") {
					this.data.push({
						"Performance": data[i].Name,
						"Occurrences": incorrectAnswers,
						"Percentage": percentage
					});
				}
				else {
					this.data.push({
						"Knowledge": data[i].Name,
						"Occurrences": incorrectAnswers,
						"Percentage": percentage
					});
				}
			}
		}

		this.sortArray(this.data);
		this.report.columns = this.columns;
		this.config.report = this.report;
		this.serverResultData = data;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.onChangeTable(this.config);
	}

	private sortArray(array: any) {
		if (array != null && array.length != 0) {
			array.sort((first, second) => {
				var firstOccurrences = +(first.Occurrences);
				var secondOccurrences = +(second.Occurrences);
				if (firstOccurrences === secondOccurrences) {
					var firstPercentage = +(first.Percentage.substring(0, first.Percentage.length - 1));
					var secondPercentage = +(second.Percentage.substring(0, second.Percentage.length - 1));
					if (firstPercentage > secondPercentage) {
						return -1;
					} else if (firstPercentage < secondPercentage) {
						return 1;
					} else {
						return 0;
					}
				}
			});
			return array
		} else {
			return array
		}
	}

}
