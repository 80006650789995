<div #employeeAssessments>
	<div *ngIf="data.length != 0">
		<div class="card mb-2 assessment-card" *ngFor="let row of data ; let i = index">
			<div class="card-body pt-2 pr-2 pl-2" (click)="employeeAssessmentClickEvent(row, 4)">
				<div class="col-sm-12 px-0 d-inline-flex">
					<div class="col-sm-8 px-0">
						<p class="mb-0">{{row.userName}}</p>
					</div>
					<div class="col-sm-4 px-0">
						<p class="text-muted mb-0 float-right">{{row.assessmentCompletedDate.toLocaleDateString()}}</p>
					</div>
				</div>

				<div class="col-sm-12 px-0">
					<div class="progress">
						<div *ngIf="row.assessmentScore < 85" class="pointer pointer-cursor"
							[style.left]="row.assessmentScore + '%'">
							<div class="progress-badge">
								<p>{{ row.assessmentScore }}%</p>
							</div>
						</div>
						<div *ngIf="row.assessmentScore == 100" class="pointer pointer-cursor"
							[style.left]="99.25 + '%'">
							<div class="progress-badge float-right">
								<p>{{ row.assessmentScore }}%</p>
							</div>
						</div>
						<div *ngIf="row.assessmentScore >= 85 && row.assessmentScore != 100"
							class="pointer pointer-cursor" [style.left]="row.assessmentScore + '%'">
							<div class="progress-badge float-right">
								<p>{{ row.assessmentScore }}%</p>
							</div>
						</div>
						<div class="progress-bar"
							[ngClass]="{'progress-bar-success': row.assessmentScore >= 80, 'progress-bar-warning': row.assessmentScore >= 50 && row.assessmentScore < 80, 'progress-bar-danger' : row.assessmentScore < 50}"
							role="progressbar" aria-valuenow="row.assessmentScore" aria-valuemin="0" aria-valuemax="100"
							[style.width]="row.assessmentScore + '%'">
						</div>
					</div>
					<!-- <div>
							<div class="float-left">
								<small class="text-muted">Date:
									{{row.assessmentCompletedDate.toLocaleDateString()}}</small>
							</div>
							<div class="float-right">
								<small class="text-muted">Assessment Report</small>
								<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
									(click)="employeeAssessmentClickEvent(row, 4)">
									<i class="fa fa-folder" aria-hidden="true"></i>
								</button>
							</div>
						</div> -->
				</div>
			</div>
		</div>
	</div>

	<!-- <div *ngIf="row.assessmentScore < 90 && row.assessmentScore > 10" class="pointer-sm pointer-cursor" [style.left]="row.assessmentScore + '%'">
              <div class="progress-badge">
                <p>{{ row.assessmentScore }}%</p>
              </div>
            </div>
            <div *ngIf="row.assessmentScore == 100" class="pointer-sm pointer-cursor" [style.left]="99.5 + '%'">
              <div class="progress-badge float-right">
                <p>{{ row.assessmentScore }}%</p>
              </div>
            </div>
            <div *ngIf="row.assessmentScore >= 90 && row.assessmentScore != 100" class="pointer-sm pointer-cursor" [style.left]="row.assessmentScore + '%'">
              <div class="progress-badge float-right">
                <p>{{ row.assessmentScore }}%</p>
              </div>
            </div>
            <div *ngIf="row.assessmentScore <= 10" class="pointer-sm pointer-cursor" [style.left]="row.assessmentScore + '%'">
              <div class="progress-badge float-left">
                <p>{{ row.assessmentScore }}%</p>
              </div>
            </div>
            <div class="progress-bar progress-bar-danger" role="progressbar" style="width:50%">
            </div>
            <div class="progress-bar progress-bar-warning" role="progressbar" style="width:30%">
            </div>
            <div class="progress-bar progress-bar-success" role="progressbar" style="width:20%">
            </div>
          </div> -->

	<!-- <div #employeeAssessments class="table-responsive-sm">
	<table class="table table-bordered border-dark table-striped col-sm-12" *ngIf="data.length != 0"  >
		<colgroup>
			<col style="width: 70%;">
			<col style="width: 20%;">
			<col style="width: 10%;">
		</colgroup>
		<thead>
			<tr>
				<th class="text-center border-dark">Score</th>
				<th class="text-center border-dark">Full Name</th>
				<th class="text-center border-dark">Assessment Report</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of data ; let i = index">
				<td class="border-dark" >
					<div>
						<div class="progress">
							<div *ngIf="row.assessmentScore < 90 && row.assessmentScore > 10" class="pointer-sm pointer-cursor" [style.left]="row.assessmentScore + '%'">
								<div class="progress-badge">
									<p>{{ row.assessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="row.assessmentScore == 100" class="pointer-sm pointer-cursor" [style.left]="99.5 + '%'">
								<div class="progress-badge float-right">
									<p>{{ row.assessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="row.assessmentScore >= 90 && row.assessmentScore != 100" class="pointer-sm pointer-cursor" [style.left]="row.assessmentScore + '%'">
								<div class="progress-badge float-right">
									<p>{{ row.assessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="row.assessmentScore <= 10" class="pointer-sm pointer-cursor" [style.left]="row.assessmentScore + '%'">
								<div class="progress-badge float-left">
									<p>{{ row.assessmentScore }}%</p>
								</div>
							</div>
							<div class="progress-bar progress-bar-danger" role="progressbar" style="width:50%">
							</div>
							<div class="progress-bar progress-bar-warning" role="progressbar" style="width:30%">
							</div>
							<div class="progress-bar progress-bar-success" role="progressbar" style="width:20%">
							</div>
						</div>
					</div>
				</td>
				<td class="border-dark">{{row.userName}}</td>
				<td class="border-dark text-center">
					<a style="cursor: pointer" class="btn btn-sm edit " (click)="employeeAssessmentClickEvent(row, 4)">
						<i class="fa fa-folder " aria-hidden="true"></i>
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</div> -->