import { Pipe, PipeTransform } from '@angular/core';

/*
 * Common class to data convertion purposes
 * Takes string as an argumnet to find the operation type
 * Usage:
 *   array | convert: <type>
 * Example:
 *
*/

@Pipe({
	name: 'convert',
	pure: true
})
export class CommonDataConverter implements PipeTransform {

	public timeZone = null;
	transform(array: any, type: string): any {
		switch (type) {
			case 'convertRemediationLink':
				array = this.convertRemediationLink(array)
				break;
		}
		return array;

	}


	private convertRemediationLink(array: any): any {
		if (array.length != 0) {
			for (var i = 0; i < array.length; i++) {
				if (array[i].Link != '' && array[i].Link.includes('http')) {
					array[i].link = array[i].Link;
				}
				else if (array[i].Link != '') {
					array[i].link = "http://" + array[i].Link;
				}				
			}
			return array
		}
		else {
			return array
		}
	}
}





