import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class ChecklistTagService {
	public curChecklistId: string = null;
	public curChecklist: string = null;

	constructor(private http: Http, private router: Router) { }

	addChecklistTag(checklistTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'checklistTag': checklistTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/checklisttag/addChecklistTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editChecklistTag(checklistId: string, checklistTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'checklistId': checklistId, 'checklistTag': checklistTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/checklisttag/editChecklistTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeChecklistTagStatus(checklistId: string, tagStatus: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'checklistId': checklistId, 'tagStatus': tagStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/checklisttag/changeChecklistTagStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllChecklistTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/checklisttag/getAllChecklistTag' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveChecklistTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/checklisttag/getActiveChecklistTag' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteChecklistTag(checklistId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'checklistId': checklistId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/checklisttag/removeChecklistTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setCurretData(checklistId: string, checklistTag: string) {
		this.curChecklistId = checklistId;
		this.curChecklist = checklistTag;
	}

	getAllShowChecklistTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/checklisttag/getAllShowChecklistTag' + token, { headers: headers })
			.map(res => res.json());
	}

	changeChecklistTagShowHide(checklistId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'checklistId': checklistId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/checklisttag/changeChecklistTagShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
