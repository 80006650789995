/**
 * This component use to display
 * assessment related remediation details
*/
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from "@angular/core";
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model';
import { Task } from '../../complib/task/task.model';
import { Knowledge } from '../../complib/knowledge/knowledge.model';
import { Performance } from '../../complib/performance/performance.model';
import { CheckList } from '../../complib/checklist/checklist.model';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { RemediationService } from '../../complib/remediation/remediation.service';
import { UserService } from '../../user/user.service';
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Constants } from '../../util/const/constants';
import { AssessmentModel } from '../../assessment/assessment.model';
import { JobProfile } from '../../jobprofile/jobprofile.model';
import { AssessmentService } from '../../assessment/assessment.service';
import * as FileSaver from "file-saver";
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
//import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
	selector: "app-assessmentremediation-report",
	templateUrl: "./assessmentremediationreport.component.html",
	styleUrls: ['./assessmentremediationreport.component.scss'],
	providers: [OrderArrayByType]
})
export class AssessmentRemediationReportComponent implements OnInit {
	@ViewChild('forMoreInfomodal', { static: true }) forMoreInfomodal: ModalComponent;
	@ViewChild('attachmentInfomodal', { static: true }) attachmentInfomodal: ModalComponent;
	public assessmentModel: AssessmentModel = null;
	public showCCDetail: string = '';
	public showTaskDetail: string = '';
	public showPerformanceDetail: string = '';
	public showKnowledgeDetail: string = '';
	public showCheckListDetail: string = '';
	public showTaskRemediationDetail: string = '';
	public showPerformanceRemediationDetail: string = '';
	public showCheckListRemediationDetail: string = '';
	public showKnowledgeRemediationDetail: string = '';
	public showingDetatilPanel: string = '';
	public showTaskGroupDetail: string = '';
	public remediationAttachmentList: any = [];
	public selectedTaskRemediationAttachment: any = []
	public selectedPerformanceRemediationAttachment: any = [];
	public selectedChecklistRemediationAttachment: any = [];
	public selectedKnowledgeRemediationAttachment: any = [];
	public attachments: any;
	@Input() assessmentId: any;
	@Input() jobProfileId: any;
	@Input() userName: any;
	public serverResultData = [];
	public filteredData = [];
	public originaldata = []
	public ccResultList = []

	public searchDescription: string = "";
	public searchMedium: string = "";
	public searchType: string = "";
	public searchCCItem: string = "";
	public searchTaskG: string = "";
	public searchTask: string = "";

	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;

	public remediationName: string = ''
	public remediationDescription: string = ''
	public remediationNumber: string = ''
	public remediationLink: string = ''
	public remediationNote: string = ''
	public selectedRemediationAttachments: any = []
	public isLoading = false

	dropdownList = [];
	selectedItems = [{ "item_id": 3, "item_text": "Low" }];
	dropdownSettings = {
		singleSelection: false,
		idField: 'item_id',
		textField: 'item_text',
		allowSearchFilter: false
	};

	constructor(
		private remediationService: RemediationService,
		private userService: UserService,
		private sharedService: SharedService,
		private alertService: AlertService,
		private assessmentService: AssessmentService,
		private changeDetectorRef: ChangeDetectorRef,
		private orderPipe: OrderArrayByType
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.assessmentModel = assessmentService.getSelectedAssessmentModel();
	}

	ngAfterViewInit() {
		// $(document).ready(function () {
		// 	$(".dropdown-btn").click(function () {
		// 		if ($('#filter-btn').length == 0) {
		// 			$('.dropdown-list').append('<button type="button" id="filter-btn" class="btn btn-primary btn-block">Filter</button>');
		// 		}

		// 	});
		// });
		this.setSelectedItemStyle(this.selectedItems[0])
	}

	setSelectedItemStyle(item: any) {
		let selitem = item.item_text;
		setTimeout(function () {
			$('span.selected-item:contains(' + selitem + ')').css('border', 'none');
			$('span.selected-item:contains(' + selitem + ')').css('padding-bottom', '1px');
			if (selitem == 'Low') {
				$('span.selected-item:contains(' + selitem + ')').css('background-color', '#da3748');
			}
			else if (selitem == 'Medium') {
				$('span.selected-item:contains(' + selitem + ')').css('background-color', '#fec02f');
			}
			else if (selitem == 'High') {
				$('span.selected-item:contains(' + selitem + ')').css('background-color', '#27a844');
			} else {
				$('span.selected-item:contains(' + selitem + ')').css('background-color', '#337ab7');
			}
		}, 100);
	}

	ngOnInit() {
		this.createJobProfileHierarchy();
		this.dropdownList = [
			{ "item_id": 1, "item_text": "High" },
			{ "item_id": 2, "item_text": "Medium" },
			{ "item_id": 3, "item_text": "Low" }
		];
	}

	onItemSelect(item: any) {
		this.setSelectedItemStyle(item)
		this.filter()
		this.filteredData = this.filterByScore()
		this.pageChanged(1)
	}

	OnItemDeSelect(item: any) {
		this.filter()
		this.filteredData = this.filterByScore()
		this.pageChanged(1)
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.serverResultData = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	clickEvent(element) {
		element.focus();
	}

	onSorted($event) {
		this.sortData($event)
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: AssessmentRemediationReportSearchCriteria) {
		if (criteria.sortColumn == 'remediationDescription' || criteria.sortColumn == 'remediationDeliveryMedium' || criteria.sortColumn == 'remediationType' || criteria.sortColumn == 'connectedCoreCompetency'
			|| criteria.sortColumn == 'connectedTaskGroup' || criteria.sortColumn == 'connectedTask') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	sortData(criteria: AssessmentRemediationReportSearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData = this.originaldata.map(x => Object.assign({}, x));
			}
			if (this.searchDescription != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["remediationDescription"].match(new RegExp(this.searchDescription, "i"));
				});
			}
			if (this.searchMedium != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["remediationDeliveryMedium"].match(new RegExp(this.searchMedium, "i"));
				});
			}
			if (this.searchType != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["remediationType"].match(new RegExp(this.searchType, "i"));
				});
			}
			if (this.searchCCItem != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["connectedCorecompetency"].match(new RegExp(this.searchCCItem, "i"));
				});
			}
			if (this.searchTaskG != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["connectedTaskGroup"].match(new RegExp(this.searchTaskG, "i"));
				});
			}
			if (this.searchTask != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["connectedTask"].match(new RegExp(this.searchTask, "i"));
				});
			}
		}
	}

	private filterByScore() {
		var result = []
		for (var i = 0; i < this.selectedItems.length; i++) {
			var selItem = this.selectedItems[i]
			if (selItem.item_id == 3) {
				result = result.concat(this.filteredData.filter(item => {
					if (item.connectedTaskScore < 50) {
						return true;
					} else {
						return false
					}
				}))
			} else if (selItem.item_id == 2) {
				result = result.concat(this.filteredData.filter(item => {
					if (item.connectedTaskScore >= 50 && item.connectedTaskScore < 80) {
						return true;
					} else {
						return false
					}
				}))
			} else if (selItem.item_id == 1) {
				result = result.concat(this.filteredData.filter(item => {
					if (item.connectedTaskScore >= 80) {
						return true;
					} else {
						return false
					}
				}))
			}
		}
		result = this.orderPipe.transform(result, 'alphabeticRemediations')
		return result;
	}

	private createJobProfileHierarchy() {
		if (this.assessmentModel == null) {
			this.assessmentModel = new AssessmentModel(this.assessmentId);
			this.assessmentModel.jobProfile = new JobProfile(this.jobProfileId);
		}
		else {
			this.assessmentModel.jobProfile = new JobProfile(this.assessmentModel.jobProfileId);
		}

		let param: any = {
			'assessmentId': this.assessmentId == null ? this.assessmentModel.id : this.assessmentId,
			'includeSelfAssessment': true
		}

		let remediationAttachmentList = []
		this.isLoading = true;
		this.assessmentService.getAssessmentJobProfileHierarchy(param)
			.subscribe(
			data => {
				this.alertService.clear()
				this.ccResultList = this.generateCCListFromData(data, "assessment")
				this.validateCCList(this.ccResultList)

				this.remediationService.getRemediationAttachments().subscribe(
					data => {
						if (data.remediationAttachmentList.length > 0) {
							for (var i = 0; i < data.remediationAttachmentList.length; i++) {
								var fileExtension = data.remediationAttachmentList[i].FileName.substr((data.remediationAttachmentList[i].FileName.lastIndexOf('.') + 1));
								remediationAttachmentList.push({ remediationId: data.remediationAttachmentList[i].RemediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
							}
						}
						this.loadTableData(this.ccResultList, remediationAttachmentList)
						this.filteredData = this.orderPipe.transform(this.serverResultData, 'alphabeticRemediations')
						this.originaldata = this.filteredData
						this.filteredData = this.filterByScore()
						this.total = this.filteredData.length;
						this.pageChanged();
						this.isLoading = false
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false
					}
				);
			},
			error => {
				this.assessmentModel.jobProfile.coreCompetencyList = [];
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false
			}
			)
	}

	public forMoreInfoClickFunction(itemlist: any, description: string) {
		this.remediationName = description
		itemlist.forEach(item => {
			if (this.remediationName == item.remediationDescription) {
				this.remediationDescription = item.courseDescription
				this.remediationNumber = item.courseNumber
				this.remediationLink = item.link
				this.remediationNote = item.note
			}
		});
		this.forMoreInfomodal.open()
	}

	public attachmentInfoClickFunction(itemlist: any, description: string) {
		let remediationId
		let id
		let text
		let fileName
		this.selectedRemediationAttachments = []
		this.remediationName = description
		itemlist.forEach(item => {
			remediationId = item.remediationId
			id = item.id
			text = item.text
			fileName = item.fileName
			this.selectedRemediationAttachments.push({ remediationId, id, text, fileName })
		});
		this.attachmentInfomodal.open()
	}

	loadTableData(ccList: any, remediationAttachments: any) {
		let remediationId
		let remediationDescription
		let remediationDeliveryMedium
		let remediationType
		let connectedCorecompetency
		let connectedTaskGroup
		let connectedTask
		let connectedTaskId
		let connectedTaskScore
		let taskIdList = []
		this.serverResultData = []
		let courseDescription = ''
		let courseNumber = ''
		let link = ''
		let note = ''
		ccList.forEach(ccItem => {
			connectedCorecompetency = ccItem.text
			if (ccItem.taskList.length > 0) {
				ccItem.taskList.forEach(task => {
					if (task.isTaskGroup) {
						connectedTaskGroup = task.taskGroup
						if (task.taskList.length > 0) {
							task.taskList.forEach(tGTask => {
								connectedTask = tGTask.text
								connectedTaskId = tGTask.id
								connectedTaskScore = tGTask.assessmentScore
								taskIdList.push({ connectedTaskId })
								if (tGTask.remediationList.length > 0) {
									tGTask.remediationList.forEach(remediationList => {
										remediationId = remediationList.Id
										remediationDescription = remediationList.Description
										remediationDeliveryMedium = remediationList.DeliveryMedium
										remediationType = remediationList.Type
										courseDescription = remediationList.CourseDescription
										courseNumber = remediationList.CourseNumber
										link = remediationList.Link
										note = remediationList.Note
										let temItem = []

										if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
											temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
										}
										let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remediationList.RemediationId)
										if (taskIdList.length > 0) {
											if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

											} else {
												this.serverResultData.push({
													connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
													remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
												})
											}
										} else {
											this.serverResultData.push({
												connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
												remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
											})
										}
										taskIdList.push({ connectedTaskId, remediationId })
									});
								}
								if (tGTask.performanceList.length > 0) {
									tGTask.performanceList.forEach(remediationList => {
										if (remediationList.remediationList.length > 0) {
											remediationList.remediationList.forEach(remed => {
												remediationId = remed.Id
												remediationDescription = remed.Description
												remediationDeliveryMedium = remed.DeliveryMedium
												remediationType = remed.Type
												courseDescription = remed.CourseDescription
												courseNumber = remed.CourseNumber
												link = remed.Link
												note = remed.Note
												let temItem = []

												if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
													temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
												}
												let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remed.RemediationId)
												if (taskIdList.length > 0) {
													if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

													} else {
														this.serverResultData.push({
															connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
															remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
														})
													}
												} else {
													this.serverResultData.push({
														connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
														remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
													})
												}
												taskIdList.push({ connectedTaskId, remediationId })
											});
										}
										if (remediationList.checkList.length > 0) {
											remediationList.checkList.forEach(remediationCheckList => {
												if (remediationCheckList.remediationList.length > 0) {
													remediationCheckList.remediationList.forEach(remed => {
														remediationId = remed.Id
														remediationDescription = remed.Description
														remediationDeliveryMedium = remed.DeliveryMedium
														remediationType = remed.Type
														courseDescription = remed.CourseDescription
														courseNumber = remed.CourseNumber
														link = remed.Link
														note = remed.Note
														let temItem = []

														if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
															temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
														}
														let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remed.RemediationId)
														if (taskIdList.length > 0) {
															if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

															} else {
																this.serverResultData.push({
																	connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
																	remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
																})
															}
														} else {
															this.serverResultData.push({
																connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
																remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
															})
														}
														taskIdList.push({ connectedTaskId, remediationId })
													});

												}
											});
										}
									});
								}
								if (tGTask.knowledgeList.length > 0) {
									tGTask.knowledgeList.forEach(remediationList => {
										if (remediationList.remediationList.length > 0) {
											remediationList.remediationList.forEach(remed => {
												remediationId = remed.Id
												remediationDescription = remed.Description
												remediationDeliveryMedium = remed.DeliveryMedium
												remediationType = remed.Type
												courseDescription = remed.CourseDescription
												courseNumber = remed.CourseNumber
												link = remed.Link
												note = remed.Note
												let temItem = []

												if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
													temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
												}
												let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remed.RemediationId)
												if (taskIdList.length > 0) {
													if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

													} else {
														this.serverResultData.push({
															connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
															remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
														})
													}
												} else {
													this.serverResultData.push({
														connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
														remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
													})
												}
												taskIdList.push({ connectedTaskId, remediationId })
											});
										}
									});
								}
							});
						}
					} else {
						connectedTaskGroup = ''
						connectedTask = task.text
						connectedTaskId = task.id
						connectedTaskScore = task.assessmentScore
						//taskIdList.push({connectedTaskId})
						if (task.remediationList.length > 0) {
							task.remediationList.forEach(remediationList => {
								remediationId = remediationList.Id
								remediationDescription = remediationList.Description
								remediationDeliveryMedium = remediationList.DeliveryMedium
								remediationType = remediationList.Type
								courseDescription = remediationList.CourseDescription
								courseNumber = remediationList.CourseNumber
								link = remediationList.Link
								note = remediationList.Note
								let temItem = []

								if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
									temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
								}
								let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remediationList.RemediationId)
								if (taskIdList.length > 0) {
									if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

									} else {
										this.serverResultData.push({
											connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
											remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
										})
									}
								} else {
									this.serverResultData.push({
										connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
										remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
									})
								}
								taskIdList.push({ connectedTaskId, remediationId })
							});
						}
					}
					if (task.performanceList.length > 0) {
						task.performanceList.forEach(remediationList => {
							if (remediationList.remediationList.length > 0) {
								remediationList.remediationList.forEach(remed => {
									remediationId = remed.Id
									remediationDescription = remed.Description
									remediationDeliveryMedium = remed.DeliveryMedium
									remediationType = remed.Type
									courseDescription = remed.CourseDescription
									courseNumber = remed.CourseNumber
									link = remed.Link
									note = remed.Note
									let temItem = []

									if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
										temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
									}
									let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remed.RemediationId)
									if (taskIdList.length > 0) {
										if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

										} else {
											this.serverResultData.push({
												connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
												remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
											})
										}
									} else {
										this.serverResultData.push({
											connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
											remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
										})
									}
									taskIdList.push({ connectedTaskId, remediationId })
								});
							}
							if (remediationList.checkList.length > 0) {
								remediationList.checkList.forEach(remediationCheckList => {
									if (remediationCheckList.remediationList.length > 0) {
										remediationCheckList.remediationList.forEach(remed => {
											remediationId = remed.Id
											remediationDescription = remed.Description
											remediationDeliveryMedium = remed.DeliveryMedium
											remediationType = remed.Type
											courseDescription = remed.CourseDescription
											courseNumber = remed.CourseNumber
											link = remed.Link
											note = remed.Note
											let temItem = []

											if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
												temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
											}
											let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remed.RemediationId)
											if (taskIdList.length > 0) {
												if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

												} else {
													this.serverResultData.push({
														connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
														remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
													})
												}
											} else {
												this.serverResultData.push({
													connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
													remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
												})
											}
											taskIdList.push({ connectedTaskId, remediationId })
										});
									}
								});
							}
						});
					}
					if (task.knowledgeList.length > 0) {
						task.knowledgeList.forEach(remediationList => {
							if (remediationList.remediationList.length > 0) {
								remediationList.remediationList.forEach(remed => {
									remediationId = remed.Id
									remediationDescription = remed.Description
									remediationDeliveryMedium = remed.DeliveryMedium
									remediationType = remed.Type
									courseDescription = remed.CourseDescription
									courseNumber = remed.CourseNumber
									link = remed.Link
									note = remed.Note
									let temItem = []

									if (courseDescription != '' || courseNumber != '' || link != '' || note != '') {
										temItem.push({ remediationDescription, courseDescription, courseNumber, link, note })
									}
									let selectedRemediationAttachment = remediationAttachments.filter(x => x.remediationId == remed.RemediationId)

									if (taskIdList.length > 0) {
										if (this.checkValueExists(taskIdList, remediationId, connectedTaskId)) {

										} else {
											this.serverResultData.push({
												connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
												remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
											})
										}
									} else {
										this.serverResultData.push({
											connectedCorecompetency, connectedTaskGroup, connectedTask, connectedTaskScore, remediationDescription,
											remediationDeliveryMedium, remediationType, temItem, selectedRemediationAttachment
										})
									}
									taskIdList.push({ connectedTaskId, remediationId })
								});
							}
						});
					}
				});
			}
		});
	}

	//check given value exists in a given array
	private checkValueExists(list: any[], remediationId: string, taskId: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.connectedTaskId == taskId && a.remediationId == remediationId) {
				isExists = true;
			}
		});
		return isExists;
	}



	public filter() {
		for (var i = 0; i < this.originaldata.length; i++) {
			this.filteredData = this.originaldata.map(x => Object.assign({}, x));
		}
		if (this.searchDescription != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["remediationDescription"].match(new RegExp(this.searchDescription, "i"));
			});
		}
		if (this.searchMedium != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["remediationDeliveryMedium"].match(new RegExp(this.searchMedium, "i"));
			});
		}
		if (this.searchType != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["remediationType"].match(new RegExp(this.searchType, "i"));
			});
		}
		if (this.searchCCItem != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["connectedCorecompetency"].match(new RegExp(this.searchCCItem, "i"));
			});
		}
		if (this.searchTaskG != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["connectedTaskGroup"].match(new RegExp(this.searchTaskG, "i"));
			});
		}
		if (this.searchTask != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["connectedTask"].match(new RegExp(this.searchTask, "i"));
			});
		}
		this.filteredData = this.filterByScore()
		this.pageChanged(1);
	}

	//generate assessment corecompetency list from data
	private generateCCListFromData(data: any, message: any): any {
		let corecompetencyList = []
		if (message == 'assessment') {
			for (let i = 0; i < data.result.length; i++) {
				let cci = data.result[i].ccItem
				let tl = data.result[i].taskList;
				let pl = data.result[i].performanceList;
				let kl = data.result[i].knowledgeList;
				let dl = data.result[i].documentList;
				let cll = data.result[i].checkList;
				let trl = data.result[i].taskRemediationList;
				let prl = data.result[i].performanceRemediationList;
				let krl = data.result[i].knowledgeRemediationList;
				let clrl = data.result[i].checkListRemediationList;

				let clml = this.generateCheckListModel(cll, clrl);

				let kml = this.generateKnowledgeListModel(kl, krl);

				let pml = this.generatePerformanceListModel(pl, clml, prl)

				let tml = this.generateTaskListModel(tl, pml, kml, trl)

				if (tml.length > 0) {
					let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
					ccItem.orderNum = cci.orderNum
					ccItem.assStatus = cci.status
					ccItem.assId = cci.ACCId
					corecompetencyList.push(ccItem)
				}
			}

		} else {
			for (let i = 0; i < data.result.length; i++) {
				let cci = data.result[i].ccItem
				let tl = data.result[i].taskList;
				let kl = data.result[i].knowledgeList;
				let trl = data.result[i].taskRemediationList;
				let krl = data.result[i].knowledgeRemediationList;
				let kml = this.generateKnowledgeListModel(kl, krl);
				let tml = this.generateSelfTaskListModel(tl, kml, trl)

				if (tml.length > 0) {
					let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
					ccItem.orderNum = cci.orderNum
					ccItem.assStatus = cci.status
					ccItem.assId = cci.ACCId
					corecompetencyList.push(ccItem)
				}
			}

		}
		return corecompetencyList;
	}

	private generateCheckListModel(checkList: any, clrList: any): any {
		let clModelList = []
		for (let i = 0; i < checkList.length; i++) {
			if (checkList[i].answer == 2) {
				let clModel: CheckList = new CheckList(checkList[i].id, checkList[i].text, '', true, checkList[i].orderNum);
				clModel.remediationList = clrList.filter(item => item.ACLId === checkList[i].ACLId);
				clModel.jppId = checkList[i].APId
				clModel.answer = checkList[i].answer
				clModel.assId = checkList[i].ACLId
				clModelList.push(clModel)
			}
		}
		return clModelList;
	}

	private generateKnowledgeListModel(knowledgeList: any, knoRemList: any): any {
		let knowledgeModelList = []
		for (let i = 0; i < knowledgeList.length; i++) {
			if (knowledgeList[i].answer == 2) {
				let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 0, knowledgeList[i].id);
				kModel.remediationList = knoRemList.filter(item => item.AKId === knowledgeList[i].AKId);
				kModel.jptId = knowledgeList[i].ATId
				kModel.orderNum = knowledgeList[i].orderNum
				kModel.answer = knowledgeList[i].answer
				kModel.assId = knowledgeList[i].AKId
				knowledgeModelList.push(kModel)
			}
		}
		return knowledgeModelList;
	}

	private generatePerformanceListModel(perList: any, checkList: any, remList: any) {
		let performanceModelList = []
		for (let i = 0; i < perList.length; i++) {
			if (perList[i].answer == 2 || checkList.length > 0) {
				let pModel: Performance = new Performance(perList[i].id, perList[i].text, true)
				pModel.checkList = checkList.filter(item => item.jppId === perList[i].APId)
				pModel.remediationList = (perList[i].answer == 2 || pModel.checkList.length > 0) ? remList.filter(item => item.APId === perList[i].APId) : []
				if (perList[i].answer == 2 || pModel.checkList.length > 0) {
					pModel.checkList = pModel.checkList.filter(item => item.remediationList.length > 0)
					pModel.jptId = perList[i].ATId
					pModel.orderNum = perList[i].orderNum
					pModel.answer = perList[i].answer
					pModel.assId = perList[i].APId
					pModel.assessmentNote = perList[i].note
					performanceModelList.push(pModel)
				}
			}
		}
		return performanceModelList;
	}

	private generateTaskListModel(taskList: any, perList: any, knoList: any, remList: any) {
		let taskModelList = [];
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task;

			if (taskList[i].isTaskGroup) {
				taskModel = new Task(taskList[i].ATId, taskList[i].text);
				taskModel.performanceList = [];
				taskModel.knowledgeList = [];
				taskModel.remediationList = [];
				taskModel.taskList = []
				taskModel.assId = taskList[i].ATId
				taskModel.assStatus = taskList[i].status
				taskModel.jpccId = taskList[i].ACCId
				taskModel.orderNum = taskList[i].orderNum
				taskModel.tgId = taskList[i].TGId
				taskModel.isTaskGroup = taskList[i].isTaskGroup
				taskModel.taskGroup = taskList[i].tgText
				taskModel.taskGroupId = taskList[i].taskGroupId
				taskModelList.push(taskModel)
			} else {
				taskModel = new Task(taskList[i].id, taskList[i].text)
				taskModel.performanceList = perList.filter(item => item.jptId === taskList[i].ATId)
				taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId)
				if (taskModel.performanceList.length > 0 || taskModel.knowledgeList.length > 0) {
					taskModel.remediationList = remList.filter(item => item.ATId === taskList[i].ATId)
					taskModel.performanceList = taskModel.performanceList.filter(item => (item.remediationList.length > 0 || item.checkList.length > 0))
					taskModel.criticalityValue = taskList[i].criticality;
					taskModel.knowledgeList = taskModel.knowledgeList.filter(item => item.remediationList.length > 0)
					if (taskModel.remediationList.length > 0 || (taskModel.performanceList.length > 0 || taskModel.knowledgeList.length > 0)) {
						let score = 0
						if (taskList[i].assessmentScore) {
							score = taskList[i].assessmentScore
						}
						taskModel.jpccId = taskList[i].ACCId
						taskModel.orderNum = taskList[i].orderNum
						taskModel.assStatus = taskList[i].status
						taskModel.assId = taskList[i].ATId
						taskModel.criticalityValue = taskList[i].criticality
						taskModel.tgId = taskList[i].TGId
						taskModel.isTaskGroup = taskList[i].isTaskGroup
						taskModel.taskGroup = taskList[i].tgText
						taskModel.taskGroupId = taskList[i].taskGroupId
						taskModel.assessmentScore = score;
						taskModelList.push(taskModel)
					}
				}
			}
		}

		taskModelList = this.modifyTaskList(taskModelList)
		return taskModelList;
	}

	//avoid task groups without attached tasks  with remediation
	public modifyTaskList(taskList: any): any {
		let returnTaskList = []
		let tempTaskList = []
		let remediationList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {  //task groups
				returnTaskList.push(task)
			} else {
				if (task.taskGroupId != null) {  //task attached to task group
					tempTaskList.push(task)
				} else {  //task attached to core competency
					remediationList.push(task)
				}
			}
		});

		tempTaskList.forEach(task => {
			if (task.taskGroupId != null && task.taskGroupId != 'null') {  //task attached to task group
				let tg = returnTaskList.find(item => item.assId === task.taskGroupId);
				tg.taskList.push(task)
			} else {  // task attached to core competency
				remediationList.push(task)
			}
		});

		returnTaskList.forEach(task => {
			if (task.taskList.length > 0) {
				remediationList.push(task)
			}
		});

		return remediationList
	}

	private generateSelfTaskListModel(taskList: any, knoList: any, remList: any) {
		let taskModelList = [];
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task;
			if (taskList[i].isTaskGroup) {
				taskModel = new Task(taskList[i].ATId, taskList[i].text);
				taskModel.performanceList = [];
				taskModel.knowledgeList = [];
				taskModel.remediationList = [];
				taskModel.taskList = []
				taskModel.assId = taskList[i].ATId
				taskModel.assStatus = taskList[i].status
				taskModel.jpccId = taskList[i].ACCId
				taskModel.orderNum = taskList[i].orderNum
				taskModel.tgId = taskList[i].TGId
				taskModel.isTaskGroup = taskList[i].isTaskGroup
				taskModel.taskGroup = taskList[i].tgText
				taskModel.taskGroupId = taskList[i].taskGroupId
				taskModelList.push(taskModel)
			} else {
				taskModel = new Task(taskList[i].id, taskList[i].text)
				taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId)
				if (taskModel.knowledgeList.length > 0) {
					taskModel.remediationList = remList.filter(item => item.ATId === taskList[i].ATId)
					taskModel.knowledgeList = taskModel.knowledgeList.filter(item => item.remediationList.length > 0)
					taskModel.criticalityValue = taskList[i].criticality;
					if (taskModel.remediationList.length > 0 || taskModel.knowledgeList.length > 0) {
						taskModel.jpccId = taskList[i].ACCId
						taskModel.orderNum = taskList[i].orderNum
						taskModel.assStatus = taskList[i].status
						taskModel.assId = taskList[i].ATId
						taskModel.criticalityValue = taskList[i].criticality
						taskModel.tgId = taskList[i].TGId
						taskModel.isTaskGroup = taskList[i].isTaskGroup
						taskModel.taskGroup = taskList[i].tgText
						taskModel.taskGroupId = taskList[i].taskGroupId
						taskModel.performanceList = [];
						taskModelList.push(taskModel)
					}
				}
			}

		}
		taskModelList = this.modifyTaskList(taskModelList)
		return taskModelList;
	}

	/**
	 * [validate cc item for 0-knowledge and 0-performance]
	 * this is not practical because user can not create 0-k 0-p cc item
	 * @param  {any}    ccList [description]
	 * @return {[type]}        [description]
	 */
	private validateCCList(ccList: any) {
		ccList.forEach(ccItem => {
			if (ccItem.taskList.length != 0) {
				ccItem.taskList.forEach(task => {
					if (task.performanceList.length != 0) {
						//do nothing
					} else {
						if (task.knowledgeList.length != 0) {
							//do nothing
						} else {
							task.assStatus = 3
						}
					}
				});
			} else {
				ccItem.assStatus = 3
			}
		});

		ccList.forEach(ccItem => {
			let completedTaskCount: number = 0;
			ccItem.taskList.forEach(task => {
				if (task.assStatus == 3) {
					completedTaskCount++
				}
			});
			if (completedTaskCount == ccItem.taskList.length) {
				ccItem.assStatus = 3
			}
		});
	}

	//show and hide collapse panel (button click event)
	// public showCollapsePanel(itemId: any, itemType: string, remediationId: any) {
	// 	this.showingDetatilPanel = itemType
	// 	switch (itemType) {
	// 		case 'coreCompetency':
	// 			this.showCCDetail = this.changeVarableToShowHideItem(this.showCCDetail, itemId);
	// 			this.showTaskDetail = '';
	// 			this.showTaskGroupDetail = '';
	// 			this.showPerformanceDetail = '';
	// 			this.showKnowledgeDetail = '';
	// 			this.showCheckListDetail = '';
	// 			this.showTaskRemediationDetail = '';
	// 			this.showPerformanceRemediationDetail = '';
	// 			this.showCheckListRemediationDetail = '';
	// 			this.showKnowledgeRemediationDetail = '';
	// 			break;
	// 		case 'taskGroup':
	// 			this.showTaskGroupDetail = this.changeVarableToShowHideItem(this.showTaskGroupDetail, itemId)
	// 			this.showTaskDetail = '';
	// 			this.showPerformanceDetail = '';
	// 			this.showKnowledgeDetail = '';
	// 			this.showCheckListDetail = '';
	// 			this.showTaskRemediationDetail = '';
	// 			this.showPerformanceRemediationDetail = '';
	// 			this.showCheckListRemediationDetail = '';
	// 			this.showKnowledgeRemediationDetail = '';
	// 			break;
	// 		case 'taskGroupTask':
	// 			this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId)
	// 			this.showPerformanceDetail = '';
	// 			this.showKnowledgeDetail = '';
	// 			this.showCheckListDetail = '';
	// 			this.showTaskRemediationDetail = '';
	// 			this.showPerformanceRemediationDetail = '';
	// 			this.showCheckListRemediationDetail = '';
	// 			this.showKnowledgeRemediationDetail = '';
	// 			break
	// 		case 'task':
	// 			this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
	// 			this.showPerformanceDetail = '';
	// 			this.showTaskGroupDetail = ''
	// 			this.showKnowledgeDetail = '';
	// 			this.showCheckListDetail = '';
	// 			this.showTaskRemediationDetail = '';
	// 			this.showPerformanceRemediationDetail = '';
	// 			this.showCheckListRemediationDetail = '';
	// 			this.showKnowledgeRemediationDetail = '';
	// 			break;
	// 		case 'performance':
	// 			this.showPerformanceDetail = this.changeVarableToShowHideItem(this.showPerformanceDetail, itemId);
	// 			this.showCheckListDetail = '';
	// 			this.showPerformanceRemediationDetail = '';
	// 			this.showCheckListRemediationDetail = '';
	// 			break;
	// 		case 'knowledgeRemediation':
	// 			this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, itemId)
	// 			this.showKnowledgeRemediationDetail = '';
	// 			break;
	// 		case 'checkListRemediation':
	// 			this.showCheckListDetail = this.changeVarableToShowHideItem(this.showCheckListDetail, itemId)
	// 			this.showCheckListRemediationDetail = '';
	// 			break;
	// 		case 'taskRemediationDetail':
	// 			this.selectedTaskRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
	// 			if (this.selectedTaskRemediationAttachment == null || this.selectedTaskRemediationAttachment.length == 0) {
	// 				this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedTaskRemediationAttachment);
	// 			}
	// 			else if (this.selectedTaskRemediationAttachment.length == 1 && this.selectedTaskRemediationAttachment[0].id == '') {
	// 				this.selectedTaskRemediationAttachment = [];
	// 			}
	// 			this.showTaskRemediationDetail = this.changeVarableToShowHideItem(this.showTaskRemediationDetail, itemId)
	// 			break;
	// 		case 'performanceRemediationDetail':
	// 			this.selectedPerformanceRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
	// 			if (this.selectedPerformanceRemediationAttachment == null || this.selectedPerformanceRemediationAttachment.length == 0) {
	// 				this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedPerformanceRemediationAttachment);
	// 			}
	// 			else if (this.selectedPerformanceRemediationAttachment.length == 1 && this.selectedPerformanceRemediationAttachment[0].id == '') {
	// 				this.selectedPerformanceRemediationAttachment = [];
	// 			}
	// 			this.showPerformanceRemediationDetail = this.changeVarableToShowHideItem(this.showPerformanceRemediationDetail, itemId)
	// 			break;
	// 		case 'knowledgeRemediationDetail':
	// 			this.selectedKnowledgeRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
	// 			if (this.selectedKnowledgeRemediationAttachment == null || this.selectedKnowledgeRemediationAttachment.length == 0) {
	// 				this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedKnowledgeRemediationAttachment);
	// 			}
	// 			else if (this.selectedKnowledgeRemediationAttachment.length == 1 && this.selectedKnowledgeRemediationAttachment[0].id == '') {
	// 				this.selectedKnowledgeRemediationAttachment = [];
	// 			}
	// 			this.showKnowledgeRemediationDetail = this.changeVarableToShowHideItem(this.showKnowledgeRemediationDetail, itemId)
	// 			break;
	// 		case 'checklistRemediationDetail':
	// 			this.selectedChecklistRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
	// 			if (this.selectedChecklistRemediationAttachment == null || this.selectedChecklistRemediationAttachment.length == 0) {
	// 				this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedChecklistRemediationAttachment);
	// 			}
	// 			else if (this.selectedChecklistRemediationAttachment.length == 1 && this.selectedChecklistRemediationAttachment[0].id == '') {
	// 				this.selectedChecklistRemediationAttachment = [];
	// 			}
	// 			this.showCheckListRemediationDetail = this.changeVarableToShowHideItem(this.showCheckListRemediationDetail, itemId)
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }

	// private getRemediationAttachmentsByRemediationId(remediationId: any, attachments: any) {
	// 	this.remediationService.getRemediationAttachmentsByRemediationId(remediationId).subscribe(
	// 		data => {
	// 			if (data.remediationAttachmentList.length > 0) {
	// 				for (var i = 0; i < data.remediationAttachmentList.length; i++) {
	// 					var fileExtension = data.remediationAttachmentList[i].FileName.substr((data.remediationAttachmentList[i].FileName.lastIndexOf('.') + 1));
	// 					attachments.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
	// 					this.remediationAttachmentList.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
	// 				}
	// 			}
	// 			else {
	// 				this.remediationAttachmentList.push({ remediationId: remediationId, id: '', text: '', fileName: '' });
	// 			}

	// 		},
	// 		error => {
	// 			this.alertService.clear();
	// 			const err = error.json();
	// 			this.alertService.handleError(err);
	// 		}
	// 	);
	// }

	public fileIconButtonClickEvent(fileName, attachmentId): any {
		var fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
		var mediaType = this.remediationService.getFileType(fileExtension);
		this.remediationService.getFileContent(fileName, attachmentId, mediaType)
			.subscribe(fileData => {
				FileSaver.saveAs(fileData, fileName);
			});
	}

	exportToExcel() {
		let outputName = "RemediationReport"		
		if (this.userName) {
			this.userName = this.userName.replace(/ /g, '');
			outputName += "_" + this.userName
		}
		var data = []
		if (this.filteredData.length > 0) {
			this.filteredData.forEach(item => {
				var temp = {
					"Description": item.remediationDescription,
					"Delivery Medium": item.remediationDeliveryMedium,
					"Type": item.remediationType,
					"Core Competency": item.connectedCorecompetency,
					"Task Group": item.connectedTaskGroup,
					"Task": item.connectedTask,
					"Task Score": item.connectedTaskScore + "%",
				}
				data.push(temp)
			});
			this.assessmentService.exportAsExcelFile(data, outputName);
		}
	}

	// private changeVarableToShowHideItem(val: string, text: string): string {
	// 	if (val != text) {
	// 		val = text;
	// 	} else {
	// 		val = '';
	// 	}
	// 	return val;
	// }
}
export class AssessmentRemediationReportSearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}



