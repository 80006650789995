<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Edit Checklist</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="container">
	<form [formGroup]="editChecklistForm" (ngSubmit)="onSubmit()">
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Checklist
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="text" class="form-control" id="checklist" formControlName="checklist" name="checklist">
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Code</label>
			<div class="col-sm-4">
				<ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId"
				 [disabled]="dependencyList.length > 0">
				</ng-select>
			</div>
			<div *ngIf="dependencyList.length > 0" class="col-sm-1">
				<button type="button" class="btn btn-outline-warning" (click)="checkForDependencies()">
					<i class="fa fa-link" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<!--Tags-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Tags</label>
			<div class="col-sm-10">
				<tag-input [ngModel]="items" [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" formControlName="checklistTag"
				 [displayBy]="'value'" [identifyBy]="'id'" (onAdd)="addTags($event)" (onRemove)="removeTags($event)" name="checklistTag">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="activeTagList">
					</tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-2">
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('Checklist')">Cancel</button>
			</div>
		</div>
	</form>
</div>