import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { AssessmentService } from '../assessment.service';
import { UserService } from '../../user/user.service'

@Component({
	selector: "app-knowledge-test-by-candidate-tabular",
	templateUrl: "./knowledgetestbycandidatetabular.component.html",
})
export class KnowledgeTestByCandidateTabularComponent implements OnInit {

	private serverResultData: Array<any> = [];
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	@Input() originaldata: Array<any>;
	searchFirstName: string = "";
	searchLastName: string = "";
	searchJobProfile: string = "";
	searchOrgCode: string = "";
	searchCompletedDate: any = "";
	isFiltering: boolean = false;

	ngOnInit() {
		this.getKnowledgeTestData();
	}

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private assessmentService: AssessmentService,
		private changeDetectorRef: ChangeDetectorRef,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	getKnowledgeTestData() {
		this.filteredData = this.originaldata
		this.total = this.filteredData.length;
		this.pageChanged();

	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	filter() {
		this.isFiltering = true;
		for (var i = 0; i < this.originaldata.length; i++) {
			this.filteredData = this.originaldata.map(x => Object.assign({}, x));
		}
		if (this.searchFirstName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["firstName"].match(new RegExp(this.searchFirstName, "i"));
			});
		}
		if (this.searchLastName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["lastName"].match(new RegExp(this.searchLastName, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["jobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["orgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}

		this.pageChanged(1);
		this.isFiltering = false;
	}

	tabularView() {
		console.log("tabular")
	}

	onSorted($event) {
		this.isFiltering = false;
		this.sortData($event)
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: CandidateCompetencySearchCriteria) {
		if (criteria.sortColumn == 'firstName' || criteria.sortColumn == 'lastName' || criteria.sortColumn == 'jobProfile' || criteria.sortColumn == 'orgCode') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	sortData(criteria: CandidateCompetencySearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
					
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push({
					"firstName": this.originaldata[i].FirstName,
					"lastName": this.originaldata[i].LastName,
					"jobProfile": this.originaldata[i].JobProfile,
					"orgCode": this.originaldata[i].OrgCode,
					"correct": this.originaldata[i].Correct,
					"total": this.originaldata[i].Total,
					"completedDate": this.originaldata[i].CompletedDate,
					"score": this.originaldata[i].Score,
					"competencyScore": this.originaldata[i].AssessmentScore
				});
			}
			if (this.searchFirstName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["firstName"].match(new RegExp(this.searchFirstName, "i"));
				});
			}
			if (this.searchLastName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["lastName"].match(new RegExp(this.searchLastName, "i"));
				});
			}
			if (this.searchJobProfile != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["jobProfile"].match(new RegExp(this.searchJobProfile, "i"));
				});
			}
			if (this.searchOrgCode != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["orgCode"].match(new RegExp(this.searchOrgCode, "i"));
				});
			}


		}
	}
}

export class CandidateCompetencySearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}
