<div class="row mx-auto mt-2">
	<div class="col-sm-12 table-responsive mt-2 mb-2">
		<table sortable-table (sorted)="onSorted($event)" class="table table-bordered table-striped data-sortable col-sm-12">
			<thead>
				<tr *ngIf="!isFiltering">					
					<th sortable-column="firstName" sortable-type="string" class="text-center ">First Name</th>
					<th sortable-column="lastName" sortable-type="string" class="text-center ">Last Name</th>
					<th sortable-column="jobProfile" sortable-type="string" class="text-center ">Job Profile</th>
					<th sortable-column="orgCode" sortable-type="string" class="text-center ">Org Code</th>
					<th sortable-column="completedDate" sortable-type="number" class="text-center ">Knowledge Check Completed Date</th>
					<th sortable-column="correct" sortable-type="number" class="set-width text-center ">Knowledge Check Questions Correct</th>
					<th sortable-column="total" sortable-type="number" class="set-width text-center ">Knowledge Check Questions Total</th>
					<th sortable-column="score" sortable-type="number" class="set-width text-center ">Knowledge Check Score</th>					
				</tr>	
				<tr *ngIf="isFiltering">					
					<th class="text-center ">First Name</th>
					<th class="text-center ">Last Name</th>
					<th class="text-center ">Job Profile</th>
					<th class="text-center ">Org Code</th>
					<th class="text-center ">Knowledge Check Completed Date</th>
					<th class="set-width text-center ">Knowledge Check Questions Correct</th>
					<th class="set-width text-center ">Knowledge Check Questions Total</th>
					<th class="set-width text-center ">Knowledge Check Score</th>					
				</tr>			
			</thead>
			<tbody>
				<tr>					
					<th class="">
						<input class="form-control" type="text" name="searchFirstName" [(ngModel)]="searchFirstName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchLastName" [(ngModel)]="searchLastName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
					</th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
				</tr>
				<tr *ngFor="let row of paginatedResults; let i = index" >					
					<td>{{row.firstName}}</td>
					<td>{{row.lastName}}</td>
					<td>{{row.jobProfile}}</td>
					<td>{{row.orgCode}}</td>					
					<td>{{row.completedDate == null ? "" : row.completedDate.toLocaleDateString()}}</td>
					<td>{{row.correct}}</td>	
					<td>{{row.total}}</td>	
					<td>{{row.score}}%</td>	
				</tr>
			</tbody>
		</table>
	</div>
	<div class="col-sm-12 mb-4">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
		 [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
	</div>
</div>

