import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { user } from "../user";
import { UserService } from "../user.service";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { OrgcodeService } from "../../orgcode/orgcode.service";
import { JobProfileService } from "../../jobprofile/jobprofile.service";
import { Constants } from "../../util/const/constants";
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { AssessmentService } from '../../assessment/assessment.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: "app-edituser",
	templateUrl: "./edituser.component.html"
})
export class EditUserComponent implements OnInit {
	public editUser: user;
	editUserForm: FormGroup;
	public orgList: Array<any> = [];
	public supervisorList: Array<any> = [];
	supervisorName: string;
	userId: string;
	isCheckedYes: boolean;
	isCheckedNo: boolean;
	selectedAssess: boolean;
	public jobprofileList: Array<any> = [];
	public selectedTimeZone: string = "";
	public isContract: boolean = false;
	public timeZoneList: Array<any> = [];
	assessChanged: boolean = false;
	radioChecked: boolean = false;
	param: any = {
		'scheduledSelfAssFromDate': "", 'scheduledSelfAssToDate': "",
		'scheduledAssFromDate': "", 'scheduledAssToDate': "",
		'selfAssCompletedFromDate': "", 'selfAssCompletedToDate': "",
		'assCompletedFromDate': "", 'assCompletedToDate': "",
		'orgCodeId': "",
		'showCompleted': 1
	};

	public selected(value: any): void {
		this.selectedTimeZone = value.text;
	}

	public removed(value: any): void {
		this.selectedTimeZone = "";
	}

	public refreshValue(value: any): void {
		this.selectedTimeZone = value.text;
	}

	constructor(
		private sharedService: SharedService,
		private userServices: UserService,
		private alertServices: AlertService,
		private orgcodeService: OrgcodeService,
		private jobProfileService: JobProfileService,
		private customModalService: CustomModalService,
		private assessmentService: AssessmentService
	) {
		if (!userServices.isValidLoggedIn()) {
			userServices.redirectToLoginScreen();
		}
		this.editUser = userServices.getSelectedUserForEdit();
		this.selectedAssess = this.editUser.isAssesed;
	}

	public selectedOrgCode(value: any): void {
		this.alertServices.clear();
		this.editUserForm.get("jobprofile").setValue(null);
		this.jobprofileList = [];
		this.getActiveJobprofile(value.id);
	}

	public removedOrgCode(value: any): void {
		this.editUserForm.get("jobprofile").setValue(null);
		this.jobprofileList = [];
	}

	valid() {
		let result = false;

		if (this.editUserForm.get("firstName").status == "VALID" && this.editUserForm.get("lastName").status == "VALID" && this.editUserForm.get('email').status == "VALID"
			&& this.editUserForm.get("timeZone").status == "VALID" && this.editUserForm.get("assess").status == "VALID" &&
			this.editUserForm.get("orgId").status == "VALID") {
			result = true;
		}
		return result;
	}

	ngOnInit() {
		this.userId = this.editUser.id;
		if (this.editUser.isAssesed == true) {
			this.isCheckedYes = true;
		} else {
			this.isCheckedNo = true;
		}
		this.isContract = this.editUser.isContract;

		this.editUserForm = new FormGroup({
			firstName: new FormControl(this.editUser.firstName, Validators.required),
			lastName: new FormControl(this.editUser.lastName, Validators.required),
			email: new FormControl(this.editUser.email, [
				Validators.required,
				Validators.pattern(
					"[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])[.])+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
				)
			]),
			orgId: new FormControl(null, Validators.required),
			supervisor: new FormControl(null),
			assess: new FormControl(this.selectedAssess),
			jobprofile: new FormControl(null),
			timeZone: new FormControl(null, Validators.required),
			isContractUser: new FormControl(this.isContract),
		});

		if (this.editUser.timeZone != "") {
			this.editUserForm.get('timeZone').setValue([this.editUser.timeZone]);
			this.selectedTimeZone = this.editUser.timeZone;
		}

		let countries = localStorage.getItem('timeZones');
		if (countries != '') {
			let countryData = countries.split('}')
			for (var i = 0; i < countryData.length - 2; i++) {
				let timeZoneData = countryData[i].split(':');
				let country = timeZoneData[3].split(',')[0].slice(1, -1);
				let timeZones = timeZoneData[4].split(',');
				let zones: Array<any> = []
				for (var j = 0; j < timeZones.length; j++) {
					if (timeZones.length == 1) {
						let zoneText = timeZones[j].slice(2, -2)
						zones.push({ id: i + j, text: zoneText });
					}
					else if (j == 0) {
						let zoneText = timeZones[j].slice(2, -1)
						zones.push({ id: i + j, text: zoneText });
					}
					else if (j == timeZones.length - 1) {
						let zoneText = timeZones[j].slice(1, -2)
						zones.push({ id: i + j, text: zoneText });
					}
					else {
						let zoneText = timeZones[j].slice(1, -1)
						zones.push({ id: i + j, text: zoneText });
					}
				}
				this.timeZoneList.push({ id: i + 1, text: country, children: zones });
			}
			this.timeZoneList = this.timeZoneList.sort((a, b) => { return a.text.localeCompare(b.text) });
		}

		this.orgcodeService.getActiveOrgcodes().subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({
						id: data.orgcode[i].Id,
						text: data.orgcode[i].Description
					});
					if (data.orgcode[i].Id == this.editUser.orgId) {
						this.editUserForm.get("orgId").setValue([{ id: data.orgcode[i].Id, text: data.orgcode[i].Description }])
					}
				}

			},
			error => {
			}
		);
		// get all supervisor
		this.userServices.getAllSupervisors(this.userId).subscribe(
			data => {
				this.supervisorList = [];
				var assignedSupList = []
				if (this.editUser.supervisorId) {
					assignedSupList = this.editUser.supervisorId.split(",")
				}
				var selectedSupIds = []
				var supIds = []
				for (var i = 0; i < data.supervisorsdetails.length; i++) {
					this.supervisorName = data.supervisorsdetails[i].FirstName + " " + data.supervisorsdetails[i].LastName;
					this.supervisorList.push({
						id: data.supervisorsdetails[i].Id,
						text: this.supervisorName
					});
					if (this.editUser.supervisorId != null) {
						if (assignedSupList.includes(data.supervisorsdetails[i].Id)) {
							selectedSupIds.push({ id: data.supervisorsdetails[i].Id, text: this.supervisorName })
							supIds.push(data.supervisorsdetails[i].Id)
						}
					}
				}

				if (selectedSupIds.length > 0) {
					this.editUserForm.get("supervisor").setValue(selectedSupIds)
					this.editUser.supIds = supIds
				}

			},
			error => {
			}
		);

		if (this.editUser.orgId != null) {
			this.jobProfileService.getActiveJobprofilesAboveOrgCode(this.editUser.orgId).subscribe(
				data => {
					this.jobprofileList = [];
					for (var i = 0; i < data.jobProfileList.length; i++) {
						this.jobprofileList.push({
							id: data.jobProfileList[i].Id,
							text: data.jobProfileList[i].Description
						});
						if (data.jobProfileList[i].Id == this.editUser.jobProfieId) {
							this.editUserForm.get("jobprofile").setValue([{ id: data.jobProfileList[i].Id, text: data.jobProfileList[i].Description }])
						}
					}

				},
				error => {
				}
			);
		}
	}


	selectJobProfile(value: any) {
		this.alertServices.clear();
		if (value.id != this.editUser.jobProfieId) {
			this.assessmentService.getAllAssessments(this.param)
				.subscribe(
				data => {
					let activeAssessments = data.Assessments.filter(item => item.UserId === this.userId);
					if (activeAssessments != null && activeAssessments.length > 0) {
						this.alertServices.warn("Please note that this user has an active assessment with the current job profile. This change will not affect the already scheduled assessments.")
					}
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		}
	}

	public changeAssessee(event: any) {
		var target = event.target || event.srcElement || event.currentTarget;
		var idAttr = target.attributes.id;
		var value = idAttr.nodeValue;
		this.radioChecked = !this.radioChecked;

		if (value == "nonassess") {
			if (!this.selectedAssess) {
				this.assessChanged = false;
			}
			else {
				let noRadioBtn = document.getElementById("nonassess");
				let yesRadioBtn = document.getElementById("assess");
				let radoButtons = [yesRadioBtn, noRadioBtn, this.editUserForm];
				let itemType = "Attribute_Assess";
				let cc = this.customModalService.invoke(
					itemType,
					this.userId,
					this,
					"delete", radoButtons
				);
			}
		}
		else {
			if (this.selectedAssess) {
				this.assessChanged = false;
			}
			else {
				this.assessChanged = true;
			}
		}

	}

	//navigate to all user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedToUpdateUser()) {
			let jobprofileId = null;
			if (this.editUserForm.get("jobprofile").value != null) {
				if (this.editUserForm.get("jobprofile").value.length > 0) {
					jobprofileId = this.editUserForm.get("jobprofile").value[0].id;
				}
			}

			let supervisorId = [];
			if (this.editUserForm.get("supervisor").value != null) {
				if (this.editUserForm.get("supervisor").value.length > 0) {
					for (var i = 0; i < this.editUserForm.get("supervisor").value.length; i++) {
						supervisorId.push(this.editUserForm.get("supervisor").value[i].id)
				}
			}
			}

			let orgId = null;
			if (this.editUserForm.get("orgId").value != null) {
				if (this.editUserForm.get("orgId").value.length > 0) {
					orgId = this.editUserForm.get("orgId").value[0].id;
				}
			}

			let timeZone = null;
			if (this.selectedTimeZone != "") {
				timeZone = this.selectedTimeZone;
			}

			let assess = this.editUserForm.value.assess;
			if (this.editUser.isAssesed == true && this.customModalService.isFoundDependency()) {
				assess = true;
			}

			//need to update the user
			const tempUser = new user(this.editUserForm.value.email.toLowerCase());
			tempUser.firstName = this.editUserForm.value.firstName;
			tempUser.lastName = this.editUserForm.value.lastName;
			tempUser.orgId = orgId;
			tempUser.id = this.editUser.id;
			tempUser.supIds = supervisorId;
			tempUser.isAssesed = assess;
			tempUser.jobProfieId = jobprofileId;
			tempUser.timeZone = timeZone;
			tempUser.isContract = tempUser.isContract = this.editUserForm.value.isContractUser != null ? this.editUserForm.value.isContractUser : false;

			if (this.editUserForm.value.assess == true && tempUser.jobProfieId == null
			) {
				this.alertServices.clear();
				this.alertServices.error("Job profile is mandatory for assess users.");
			} else {
				this.userServices.updateUserByUserId(tempUser).subscribe(
					data => {
						this.alertServices.clear();
						this.alertServices.success(data.message);
						this.editUser.firstName = this.editUserForm.value.firstName;
						this.editUser.lastName = this.editUserForm.value.lastName;
						this.editUser.orgId = orgId;
						this.editUser.email = this.editUserForm.value.email;
						this.editUser.isAssesed = this.editUserForm.value.assess;
						this.editUser.jobProfieId = jobprofileId;
						this.editUser.supIds = supervisorId;
						this.editUser.timeZone = timeZone;
						if (this.editUser.id == localStorage.getItem('userId')) {
							localStorage.setItem('TimeZone', this.editUser.timeZone);
						}
						this.editUser.isContract = this.editUserForm.value.isContractUser;
					},
					error => {
						this.alertServices.clear();
						let err = error.json();
						this.alertServices.handleError(err);
					}
				);
			}
		} else {
			//no need to update the user no changes
			this.alertServices.clear();
			this.alertServices.error("There are no changes to update.");
		}
	}

	checkNeedToUpdateUser(): boolean {
		let jobprofileId = null;
		if (this.editUserForm.get("jobprofile").value != null) {
			if (this.editUserForm.get("jobprofile").value.length > 0) {
				jobprofileId = this.editUserForm.get("jobprofile").value[0].id;
			}
		}

		let supervisorId = [];
		if (this.editUserForm.get("supervisor").value != null) {
			if (this.editUserForm.get("supervisor").value.length > 0) {
				for (var i = 0; i < this.editUserForm.get("supervisor").value.length; i++) {
					supervisorId.push(this.editUserForm.get("supervisor").value[i].id)
			}
		}
		}

		let orgId = null;
		if (this.editUserForm.get("orgId").value != null) {
			if (this.editUserForm.get("orgId").value.length > 0) {
				orgId = this.editUserForm.get("orgId").value[0].id;
			}
		}

		let timeZone = null;
		if (this.selectedTimeZone != "") {
			timeZone = this.selectedTimeZone;
		}
		let assess = this.editUserForm.value.assess;
		if (this.editUser.isAssesed == true && this.customModalService.isFoundDependency()) {
			assess = true;
		}

		if (this.editUser.firstName != this.editUserForm.value.firstName) {
			return true;
		} else if (this.editUser.lastName != this.editUserForm.value.lastName) {
			return true;
		} else if (this.editUser.email != this.editUserForm.value.email) {
			return true;
		} else if (this.editUser.orgId != orgId) {
			return true;
		} else if (this.compare(this.editUser.supIds, supervisorId)) {
			return true;
		} else if (
			this.editUser.jobProfieId != jobprofileId
		) {
			return true;
		} else if (timeZone != null && this.editUser.timeZone != timeZone) {
			return true;
		} else if (this.editUser.isContract != this.editUserForm.value.isContractUser) {
			return true;
		}
		else if (this.editUser.isAssesed != assess) {
			return true;
		}
		else {
			return false;
		}
	}

	compare(arr1: any[], arr2: any[]) {
		if (arr1) {
			arr1 = arr1.sort()
		}
		if (arr2) {
			arr2 = arr2.sort()
		}

		if (JSON.stringify(arr1) != JSON.stringify(arr2)) {
			return true
		} else {
			return false
		}

	}

	private getActiveJobprofile(orgId: string) {
		if (orgId != null) {
			this.jobProfileService.getActiveJobprofilesAboveOrgCode(orgId).subscribe(
				data => {
					this.jobprofileList = [];
					for (var i = 0; i < data.jobProfileList.length; i++) {
						this.jobprofileList.push({
							id: data.jobProfileList[i].Id,
							text: data.jobProfileList[i].Description
						});
					}
				},
				error => {
				}
			);
		}
	}
}
