<div class="col-sm-12">
  <div class="col-sm-12 table-responsive mt-2 mb-2">
    <table sortable-table (sorted)="onSorted($event)" class="table table-bordered  data-sortable col-sm-12">
      <thead>
        <tr *ngIf="!isFiltering">
          <th sortable-column="Name" sortable-type="string" class="set-width text-center ">Name</th>
          <th sortable-column="JobProfile" sortable-type="string" class="text-center ">Job Profile</th>
          <th sortable-column="OrgCode" sortable-type="string" class="text-center ">Org Code</th>
          <th sortable-column="CompletedDate" sortable-type="number" class="text-center ">Completed Date</th>
          <th sortable-column="KnowledgeCheckScore" sortable-type="number" class="text-center ">Knowledge
            Check Score</th>
          <th sortable-column="CompetencyScore" sortable-type="number" class="text-center ">Competency Score
          </th>
          <th sortable-column="Category" sortable-type="string" class="text-center ">Category</th>
          <th sortable-column="Opportunity" sortable-type="string" class="text-center ">Task / Opportunity
          </th>
        </tr>
        <tr *ngIf="isFiltering">
          <th class="set-width text-center ">Name</th>
          <th class="text-center ">Job Profile</th>
          <th class="text-center ">Org Code</th>
          <th class="text-center ">Completed Date</th>
          <th class="text-center ">Knowledge Check Score</th>
          <th class="text-center ">Competency Score</th>
          <th class="text-center ">Category</th>
          <th class="text-center ">Task / Opportunity</th>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: rgba(0, 0, 0, 0.05)">
          <th class="">
            <input class="form-control" type="text" name="searchName" [(ngModel)]="searchName" (keyup)="filter()">
          </th>
          <th class="">
            <input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile"
              (keyup)="filter()">
          </th>
          <th class="">
            <input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
          </th>
          <th class=""></th>
          <th class=""></th>
          <th class=""></th>
          <th class="">
            <input class="form-control" type="text" name="searchCategory" [(ngModel)]="searchCategory"
              (keyup)="filter()">
          </th>
          <th class="">
            <input class="form-control" type="text" name="searchOpportunity" [(ngModel)]="searchOpportunity"
              (keyup)="filter()">
          </th>
        </tr>
        <tr *ngFor="let row of paginatedResults; let i = index" [style.background-color]="row.color">
          <td>{{row.Name}}</td>
          <td>{{row.JobProfile}}</td>
          <td>{{row.OrgCode}}</td>
          <td>{{row.CompletedDate}}</td>
          <td>{{row.KnowledgeCheckScore}}</td>
          <td>{{row.CompetencyScore}}</td>
          <td>{{row.Category}}</td>
          <td>{{row.Opportunity}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-sm-12">
    <pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage"
      [maxSize]="maxNoOfPages" [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)"
      (numPages)="numPages = $event"></pagination>
    <pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
  </div>
</div>

<div *ngIf=isLoading class="loading"></div>
 