import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import { TableComponent } from "../util/table/table.component";

import { JobProfile } from "./jobprofile.model";
import { CoreCompetency } from "../complib/corecompetency/corecompetency.model";
import { Task } from "../complib/task/task.model";
import { Performance } from "../complib/performance/performance.model";
import { Knowledge } from "../complib/knowledge/knowledge.model";
import { CheckList } from "../complib/checklist/checklist.model";

import { UserService } from "../user/user.service";
import { SharedService } from "../home/shared.service";
import { AlertService } from "../util/alert/alert.service";
import { JobProfileService } from "./jobprofile.service";
import { CustomModalService } from "../util/custommodal/custommodal.service";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
	selector: "app-alljobprofile",
	templateUrl: "./alljobprofile.component.html"
})
export class AllJobProfileComponent extends TableComponent {
	@ViewChild("changeStatusErrorModal", { static: true }) changeStatusErrorModal: ModalComponent;

	public selectedJobProfile: any;

	public selectedjobProfileStatus: boolean = true;
	public confirmationBtnClass: string = "btn-default";

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem("selectedUserRole");
	private isDeleteJobProfile: boolean;
	private isModifyJobProfile: boolean;
	isAddJobProfile: boolean;
	private isQueryJobProfile: boolean;
	checked: boolean = false;
	isLoading: boolean = false

	public data: any;
	public statusErrorMsg: any = "";
	public columns: Array<any> = [
		{
			title: "Job Profile Description",
			name: "description",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Org Code",
			name: "orgcode",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Discipline",
			name: "discipline",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Frequency",
			name: "frequency",
			filtering: { filterString: "", placeholder: "" }
		},
		{ title: "", name: "status", className: "accepter-col-action" },
		{ title: "", name: "editbutton", className: "accepter-col-action" },
		{ title: "", name: "deletebutton", className: "accepter-col-action" },
		{ title: "", name: "showhidebutton", className: "accepter-col-action" },
	];
	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	//this array store all the data from the server
	private serverResultData: Array<any> = [];
	showHideForm: FormGroup;
	constructor(
		private customModalService: CustomModalService,
		private alertService: AlertService,
		private jobProfileService: JobProfileService,
		private sharedService: SharedService,
		private userService: UserService
	) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddJobProfile = this.userService.isCapabilityAssigned(
			"AddJobProfile",
			this.selectedUserRole[0][1]
		);
		this.isModifyJobProfile = this.userService.isCapabilityAssigned(
			"ModifyJobProfile",
			this.selectedUserRole[0][1]
		);
		this.isDeleteJobProfile = this.userService.isCapabilityAssigned(
			"DeleteJobProfile",
			this.selectedUserRole[0][1]
		);
		this.isQueryJobProfile = this.userService.isCapabilityAssigned(
			"QueryJobProfile",
			this.selectedUserRole[0][1]
		);
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.isQueryJobProfile) {
			this.loadAllShowJobProfiles();
		} else {
			//do nothing
		}

		this.showHideForm = new FormGroup({
			showHide: new FormControl(null),
		})
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		if (itemName == "AddJobProfile") {
			this.jobProfileService.setjobProfileOperation("ADD");
		}
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		// console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		var elementnumber = parseInt(data.row.id);
		this.selectedJobProfile = this.serverResultData[elementnumber];
		this.selectedjobProfileStatus = this.serverResultData[elementnumber].Status;
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = "btn-danger";
		} else {
			this.confirmationBtnClass = "btn-success";
		}

		switch (data.column) {
			case "editbutton":
				if (this.isModifyJobProfile) {
					this.editButtonClickEvent(this.selectedJobProfile);
				} else {
					//do nothing
				}
				break;
			case "deletebutton":
				if (this.isDeleteJobProfile) {
					this.deleteButtonClickEvent(this.selectedJobProfile.Id);
				} else {
					//do nothing
				}
				break;
			case "status":
				if (this.isModifyJobProfile) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						// do nothing
					} else {
						this.statusButtonClickEvent(
							this.selectedJobProfile,
							this.serverResultData[elementnumber].Status
						);
					}
				} else {
					//do nothing
				}
				break;
			case "showhidebutton":
				if (this.isModifyJobProfile) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonClickEvent(this.selectedJobProfile.Id, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	private deleteButtonClickEvent(id) {
		let itemType = "Job Profile";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	//load buttons to the table
	public extendData() {
		for (let i = 0; i < this.data.length; i++) {			
			//check user has modify capability
			if (this.isModifyJobProfile) {
				this.data[i].editbutton =
					'<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status =
						'<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					if (this.serverResultData[i].ShowHide == 0) {
						this.data[i].status =
							'<a class="btn btn-sm disabled" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].status =
							'<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				}
			} else {
				this.data[i].editbutton =
					'<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status =
						'<a class="btn btn-sm disabled"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].status =
						'<a class="btn btn-sm disabled" ><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}
			//check user has delete capability
			if (this.isDeleteJobProfile) {
				this.data[i].deletebutton =
					'<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton =
					'<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyJobProfile) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyJobProfile) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}

	//load all job profiles from the db
	private loadAllJobProfiles() {
		this.jobProfileService.getAllJobProfiles().subscribe(
			data => {
				this.data = [];
				// console.log("[loadAllJobProfiles] data : " +JSON.stringify(data) + "/(count)" + data.jobprofileList.length );
				for (let i = 0; i < data.jobprofileList.length; i++) {
					this.data.push({
						id: i,
						jobProfileId: data.jobprofileList[i].Id,
						description: data.jobprofileList[i].Description,
						orgcode: data.jobprofileList[i].OrgCode,
						discipline: data.jobprofileList[i].Discipline == null ? '' : data.jobprofileList[i].Discipline,
						frequency: data.jobprofileList[i].Frequency.toString(),
						status: data.jobprofileList[i].Status,
						showHide: data.jobprofileList[i].ShowHide,
						// statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.jobprofileList;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		);
	}

	//status button click event
	private statusButtonClickEvent(selectedJobProfile: any, status: string) {
		let itemType = "Job Profile";
		if (status == "0") {
			this.isLoading = true
			let tempJobProfile: JobProfile = new JobProfile(
				selectedJobProfile.Id,
				selectedJobProfile.Description,
				selectedJobProfile.Status,
				selectedJobProfile.OrgId,
				selectedJobProfile.OrgCode,
				selectedJobProfile.DisciplineId,
				selectedJobProfile.Discipline,
				selectedJobProfile.Frequency,
				[],
				selectedJobProfile.ShowHide
			);

			this.jobProfileService.getJobProfileDetail(tempJobProfile).subscribe(
				data => {
					let ccList = this.jobProfileService.generateCCListFromData(data);
					this.statusErrorMsg = this.validateCCListForStatusChange(ccList);
					this.isLoading = false
					if (this.statusErrorMsg.trim() == "") {
						this.customModalService.invoke(
							itemType,
							selectedJobProfile.Id,
							this,
							"statusChange",
							status
						);
					} else {
						this.changeStatusErrorModal.open('lg');
					}
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			);
		} else {
			this.customModalService.invoke(
				itemType,
				selectedJobProfile.Id,
				this,
				"statusChange",
				status
			);
		}
	}

	private validateCCListForStatusChange(ccList: any): any {	
		let returnVal: any = "";
		let knowledgeQuestionIncluded = false;
		ccList.forEach(ccItem => {
			if (ccItem.taskList.length != 0) {
				ccItem.taskList.forEach(taskItem => {
					if (taskItem.isTaskGroup) {
						taskItem.taskList.forEach(tgTaskItem => {
							if (tgTaskItem.performanceList.length != 0) {
								//do nothing
							} else {
								returnVal += tgTaskItem.text + " has no performance assigned to it." + "\n";
							}
							if (tgTaskItem.knowledgeList.length == 0 && tgTaskItem.knowledgeQuestionList.length == 0) {
								returnVal += tgTaskItem.text + " has no knowledge/knowledge question assigned to it." + "\n";
							} else {
								if (tgTaskItem.knowledgeQuestionList.length != 0) {
									knowledgeQuestionIncluded = true;
								}
							}
						});
					} else {
						if (taskItem.performanceList.length != 0) {
							//do nothing
						} else {
							returnVal += taskItem.text + " has no performance assigned to it." + "\n";
						}
						if (taskItem.knowledgeList.length == 0 && taskItem.knowledgeQuestionList.length == 0) {
							returnVal += taskItem.text + " has no knowledge/knowledge question assigned to it." + "\n";
						} else {
							if (taskItem.knowledgeQuestionList.length != 0) {
								knowledgeQuestionIncluded = true;
							}
						}
					}
				});
			} else {
				returnVal += ccItem.text + " has no tasks assigned to it." + "\n";
			}
		});

		if (!knowledgeQuestionIncluded) {
			returnVal += "Job profile should have at least one knowledge question to enable it." + "\n";
		}
		return returnVal;
	}


	//check knowledge for none test question(IsTestQuestion == 0)
	private checkKnowledgeForNTQ(knowledgeList: any): boolean {
		let nonTestQuestionCount: number = 0
		knowledgeList.forEach(knowledge => {
			if (!knowledge.isTestQuestion) {
				nonTestQuestionCount++
			}
		});
		return (nonTestQuestionCount == 0) ? false : true
	}

	//edit button click event
	private editButtonClickEvent(selectedJobProfile: any) {
		// console.log("[selectedJobProfile] " + JSON.stringify(selectedJobProfile).toString());
		let tempJobProfile: JobProfile = new JobProfile(selectedJobProfile.Id, selectedJobProfile.Description,
			selectedJobProfile.Status, selectedJobProfile.OrgId, selectedJobProfile.OrgCode, selectedJobProfile.DisciplineId,
			selectedJobProfile.Discipline, selectedJobProfile.Frequency, [], selectedJobProfile.ShowHide
		);

		this.jobProfileService.getJobProfileDetail(tempJobProfile).subscribe(
			data => {
				// console.log('[editButtonClickEvent] (data) : ' + JSON.stringify(data));
				let ccList = this.jobProfileService.generateCCListFromData(data)
				// console.log('[editButtonClickEvent] (ccList) : ' + JSON.stringify(ccList));
				tempJobProfile.coreCompetencyList = ccList;
				this.jobProfileService.setSelectedJobProfileForEdit(tempJobProfile);
				this.jobProfileService.setjobProfileOperation("EDIT");
				this.sharedService.change("AddJobProfile");
				// console.log('[editButtonClickEvent] (ccList) : ' + JSON.stringify(data).toString());
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		);
	}

	//delete button click event
	delete() {
		// console.log('[deletePerformance] (jobprofileId/description) : ' + this.selectedJobProfile.Id + '/' + this.selectedJobProfile.Description);
		this.isLoading = true
		const tempJobProfile = new JobProfile(this.selectedJobProfile.Id, this.selectedJobProfile.Description, !this.selectedJobProfile.Status);
		this.jobProfileService.deleteJobProfile(tempJobProfile).subscribe(
			data => {
				this.isLoading = false
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.isLoading = false
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		);
	}

	//change status of the performance
	changeStatus() {
		// console.log("[changeStatusofPerformance] (jobprofileId/Status) : " + this.selectedJobProfile.Id + "/" + this.selectedJobProfile.Status);
		const tempJobProfile = new JobProfile(this.selectedJobProfile.Id, this.selectedJobProfile.Description, !this.selectedJobProfile.Status);
		this.jobProfileService.updateJobProfileStatusByJobProfileId(tempJobProfile).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		);
	}

	showHide(event) {
		//check modify capability
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryJobProfile) {
				this.loadAllJobProfiles();
			} else {
				//modify capability is not assigned
			}
		} else {
			this.loadAllShowJobProfiles();
		}
	}

	loadData() {
		if (this.checked) {
			this.loadAllJobProfiles();
		}
		else {
			this.loadAllShowJobProfiles();
		}
	}

	//load all job profiles from the db
	private loadAllShowJobProfiles() {
		this.jobProfileService.getAllShowJobProfiles().subscribe(
			data => {
				this.data = [];				
				for (let i = 0; i < data.jobprofileList.length; i++) {
					this.data.push({
						id: i,
						jobProfileId: data.jobprofileList[i].Id,
						description: data.jobprofileList[i].Description,
						orgcode: data.jobprofileList[i].OrgCode,
						discipline: data.jobprofileList[i].Discipline == null ? '' : data.jobprofileList[i].Discipline,
						frequency: data.jobprofileList[i].Frequency.toString(),
						status: data.jobprofileList[i].Status,
						showHide: data.jobprofileList[i].ShowHide,
						// statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.jobprofileList;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		);
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Job Profile";
		let status = showHide;
		this.customModalService.invoke(itemType, id, this, "showHide", status);
	}

	public showHideEvent(selectedJobProfileId: string, showHide: number) {
		this.jobProfileService.changeJobProfileShowHide(selectedJobProfileId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}
}
