import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

import { CheckList } from './checklist.model'

@Injectable()
export class ChecklistService {
	// public curChecklistId: string = null;
	// public curChecklist: string = null;
	// public curOrgId: string = null;

	constructor(private http: Http, private router: Router) { }

	//selected checklist for edit
	private selectedChecklistForEdit: CheckList;

	public getSelectedChecklistForEdit(): CheckList {
		return this.selectedChecklistForEdit;
	}
	public setSelectedChecklistForEdit(checklist: CheckList): void {
		this.selectedChecklistForEdit = checklist;
	}

	addChecklist(checkList: CheckList) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(checkList);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/addChecklist' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editChecklist(checklist: CheckList) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(checklist);		
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/editChecklist' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeChecklistStatus(checklistId: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'checklistId': checklistId, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/changeChecklistStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllChecklist() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getAllChecklist' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteChecklist(checklistId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'checklistId': checklistId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/removeChecklist' + token, body, { headers: headers })
			.map(res => res.json());
	}

	// setCurretData(checklistId: string, checklist: string, orgId: string) {
	// 	this.curChecklistId = checklistId;
	// 	this.curChecklist = checklist;
	// 	this.curOrgId = orgId;
	// }

	getActliveChecklistByOrgCode(orgCodeId) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'orgCodeId': orgCodeId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getAllActiveChecklistByOrgCode' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get assigned checklist taglist
	getAssignedChecklistTagList(clId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'clId': clId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/getAssignedChecklistTagList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActiveChecklistWithTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getActiveChecklistWithTags' + token, { headers: headers })
			.map(res => res.json());
	}

	getAllShowChecklist() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getShowChecklist' + token, { headers: headers })
			.map(res => res.json());
	}

	changeChecklistShowHide(checklistId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'checklistId': checklistId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/changeChecklistShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
