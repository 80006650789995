import { Component, OnInit, Input, Output, ChangeDetectorRef } from "@angular/core";
import { EventEmitter } from "@angular/core";

import { JobProfile } from './jobprofile.model';
import { CoreCompetency } from '../complib/corecompetency/corecompetency.model';
import { Task } from '../complib/task/task.model';
import { Knowledge } from '../complib/knowledge/knowledge.model';
import { Performance } from '../complib/performance/performance.model';
import { CheckList } from '../complib/checklist/checklist.model';
import { RegulatoryAgency } from '../complib/regulatoryagency/regulatoryagency.model'

import { CoreCompetencyService } from '../complib/corecompetency/corecompetency.service';
import { TaskService } from '../complib/task/task.service';
import { JobProfileService } from './jobprofile.service';
import { PerformanceService } from '../complib/performance/performance.service';
import { AlertService } from '../util/alert/alert.service';
import { SharedService } from '../home/shared.service';
import { KnowledgeService } from '../complib/knowledge/knowledge.service';
import { ChecklistService } from '../complib/checklist/checklist.service';
import { RemediationService } from '../complib/remediation/remediation.service';
import { UserService } from '../user/user.service';
import { RegulatoryAgencyService } from '../complib/regulatoryagency/regulatoryagency.service'

import { OrderArrayByType } from '../util/orderarraybytype.pipe'
import { PreventParentClickEvent } from '../util/preventparentclick.directive';

import { TagInputModule } from 'ngx-chips';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CoreCompetencyTagService } from '../tag/corecompetencytag/corecompetencytag.service';
import { TaskTagService } from '../tag/tasktag/tasktag.service';
import { PerformanceTagService } from '../tag/performancetag/performancetag.service';
import { KnowledgeTagService } from '../tag/knowledgetag/knowledgetag.service';
import { ChecklistTagService } from '../tag/checklisttag/checklisttag.service';
import { RemediationTagService } from '../tag/remediationtag/remediationtag.service'

import { Constants } from '../util/const/constants'

@Component({
	selector: 'app-jphierarchy',
	templateUrl: './jphierarchy.component.html',
	styleUrls: ['./jphierarchy.component.scss'],
	providers: [OrderArrayByType]
})
export class JPHiercachyComponent implements OnInit {

	public showHierarchy: boolean = true
	public showTableData: boolean = false
	public showAddTaskGroup: boolean = false
	public addTaskGroup: boolean = false;

	public tableTopic: string = "";
	public taskGroupTopic: string = 'Add task group'
	//used to check which item that user is going to add from table
	public selectedItem: string = "";
	public showKnowledges: boolean = false;
	//use these variable to load add new components dynamically
	@Output() notify: EventEmitter<string> = new EventEmitter<string>();
	@Output() showingItems: EventEmitter<any> = new EventEmitter<any>();
	@Input() rShowingItems;

	//check capability to add new items
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public isAddCompLib: boolean;

	public jobProfileModel: JobProfile;
	public selectedCCModel: any = null;
	public selectedTaskModel: any = null;
	public selectedTaskGroupModel: any = null
	public selectedPerformanceModel: any = null;
	public selectedKnowledgeModel: any = null;
	public selectedCheckListModel: any = null;
	public selectedRegulatoryModel: any = null;

	//this variable use to show/hide data panel
	public showCCDetail: string = '';
	public showTaskGroupDetail: string = '';
	public showTaskDetail: string = '';
	public showPerformanceDetail: string = '';
	public showKnowledgeDetail: string = '';
	public showCheckListDetail: string = '';
	public showingDetatilPanel: string = '';

	//table related variable
	public rows: Array<any> = [];
	public columns: Array<any> = [];
	public page: number = 1;
	public itemsPerPage: number = 10;
	public maxSize: number = 10;
	public numPages: number = 1;
	public length: number = 0;
	public pageCount: number = 0;
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: '' },
		className: ['table-striped', 'table-bordered']
	};
	//this array used to load data(not all) to the table
	private data: Array<any> = [];
	private originalData: Array<any> = [];
	public items = [];
	private showingItemArray = [];

	selectTagForm: FormGroup;
	public addTaskGroupForm: FormGroup
	public autocompleteItemsAsObjects = [];
	public selectedTags: Array<any> = [];

	public tagList = [];
	paginatedResults = [];
	selectedRows = [];
	searchKnowledge: string = "";
	searchType: string = "";
	searchQuestion: string = "";
	searchVQuestion: string = "";
	searchOrgCode: string = "";
	public filteredData: Array<any> = [];
	isLoading = false;

	public isClickedRegAgency: boolean = false;
	public rgList: Array<any> = [];
	public selectedTaskId: any
	public placeholderText: string = ''
	public selectedRegAgency

	constructor(
		private jobProfileService: JobProfileService,
		private corecompetencyServive: CoreCompetencyService,
		private taskService: TaskService,
		private performanceService: PerformanceService,
		private knowledgeService: KnowledgeService,
		private checkListService: ChecklistService,
		private remediationService: RemediationService,
		private regulatoryAgencyService: RegulatoryAgencyService,
		private userService: UserService,
		private sharedService: SharedService,
		private alertService: AlertService,
		private coreCompetencyTagService: CoreCompetencyTagService,
		private taskTagService: TaskTagService,
		private performanceTagService: PerformanceTagService,
		private knowledgeTagService: KnowledgeTagService,
		private checklistTagService: ChecklistTagService,
		private remediationTagService: RemediationTagService,
		private changeDetectorRef: ChangeDetectorRef,
		private orderPipe: OrderArrayByType
	) {
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		this.isAddCompLib = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.jobProfileModel = this.jobProfileService.getSelectedJobProfileForEdit();
	}

	ngOnInit() {
		this.selectTagForm = new FormGroup({
			selectTag: new FormControl(null),
		});

		this.addTaskGroupForm = new FormGroup({
			taskGroup: new FormControl(null, Validators.required)
		})
	}

	ngAfterContentInit() {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.showCCDetail = this.rShowingItems[0]
		this.showTaskDetail = this.rShowingItems[1]
		this.showPerformanceDetail = this.rShowingItems[2]
		this.showKnowledgeDetail = this.rShowingItems[3]
		this.showCheckListDetail = this.rShowingItems[4]
	}

	//Add new core competency button click event
	public addNewBtnClick(component: any) {
		this.notify.emit(component)
		// console.log('[addNewBtnClick] (selectedMenuItem) : ' + this.selectedMenuItem);
	}

	//show and hide collapse panel (button click event)
	public showCollapsePanel(itemId: any, itemType: string) {
		this.showingDetatilPanel = itemType
		switch (itemType) {
			case 'coreCompetency':
				this.showCCDetail = this.changeVarableToShowHideItem(this.showCCDetail, itemId);
				this.showTaskDetail = ''
				this.showPerformanceDetail = ''
				this.showKnowledgeDetail = ''
				this.showCheckListDetail = ''
				break;
			case 'taskGroup':
				this.showTaskGroupDetail = this.changeVarableToShowHideItem(this.showTaskGroupDetail, itemId);
				this.showTaskDetail = '';
				this.showPerformanceDetail = ''
				this.showKnowledgeDetail = ''
				this.showCheckListDetail = ''
				break;
			case 'task':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
				this.showPerformanceDetail = ''
				this.showKnowledgeDetail = ''
				this.showCheckListDetail = ''
				this.selectedTaskId = ''
				this.isClickedRegAgency = false
				break;
			case 'performance':
				this.showPerformanceDetail = this.changeVarableToShowHideItem(this.showPerformanceDetail, itemId);
				this.showCheckListDetail = ''
				break;
			case 'knowledgeRemediation':
				this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, itemId)
				break;
			case 'checkListRemediation':
				this.showCheckListDetail = this.changeVarableToShowHideItem(this.showCheckListDetail, itemId)
				break;
			default:
				break;
		}
		this.showingItemArray = [this.showCCDetail, this.showTaskDetail, this.showPerformanceDetail, this.showKnowledgeDetail, this.showCheckListDetail]
		this.showingItems.emit(this.showingItemArray)
	}

	private changeVarableToShowHideItem(val: string, text: string): string {
		if (val != text) {
			val = text;
		} else {
			val = '';
		}
		return val;
	}

	private addNewItem() {
		// console.log('[addNewItem] (selectedItem) ' + this.selectedItem)
		switch (this.selectedItem) {
			case 'coreCompetency':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addNewBtnClick('coreCompetency')
				}
				break;
			case 'task':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addNewBtnClick('task')
				}
				break;
			case 'taskGroup':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addNewBtnClick('taskGroup')
				}
				break;
			case 'taskGroupTask':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addNewBtnClick('task')
				}
				break;
			case 'performance':
				this.addNewBtnClick('performance')
				break;
			case 'knowledge':
				this.addNewBtnClick('knowledge')
				break;
			case 'checkList':
				this.addNewBtnClick('checkList')
				break;
			case 'taskRemediation':
				this.addNewBtnClick('remediation')
				break;
			case 'performanceRemediation':
				this.addNewBtnClick('remediation')
				break;
			case 'knowledgeRemediation':
				this.addNewBtnClick('remediation')
				break;
			case 'checkListRemediation':
				this.addNewBtnClick('remediation')
				break;
			default:
				break;
		}
		localStorage.setItem('showingItems', JSON.stringify(this.showingItemArray))
	}

	//<editor-fold> SHOWTABLE
	//add code competency button click event
	public addCoreCompetencyBtnClick(item: string) {
		this.selectedCCModel = null
		this.selectedTaskModel = null
		this.selectedTaskGroupModel = null
		this.selectedRegulatoryModel = null
		this.selectedPerformanceModel = null
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null

		this.alertService.clear();
		this.tableTopic = 'Select Core competency';
		this.selectedItem = item;
		this.page = 1;
		this.loadAllActiveCoreCompTag();
		this.getActiveCoreCompetencies();
		this.showTableData = true;
		this.showHierarchy = false

	}

	public addTaskBtnClick(item: string, ccModel: any) {
		this.selectedCCModel = ccModel;
		this.showTableData = true;
		this.showHierarchy = false
		this.selectedTaskModel = null
		this.selectedTaskGroupModel = null
		this.selectedRegulatoryModel = null
		this.selectedPerformanceModel = null
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null
		this.addTaskGroup = false;

		this.alertService.clear();
		this.tableTopic = 'Select Task';
		this.page = 1;
		this.selectedItem = item;
		this.jobProfileService.setSelectedJPCCModel(ccModel);
		this.jobProfileService.setSelectedTaskGroupModel(null)
		this.loadAllActiveTaskTag();
		this.getActiveTasks(ccModel);
	}

	public addTaskFromTaskGroup(item: string, ccModel: CoreCompetency, taskGroup: Task) {
		this.selectedCCModel = ccModel;
		this.showTableData = true;
		this.showHierarchy = false
		this.selectedTaskModel = null
		this.selectedTaskGroupModel = taskGroup
		this.selectedRegulatoryModel = null
		this.selectedPerformanceModel = null
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null
		this.addTaskGroup = false;

		this.alertService.clear();
		this.tableTopic = 'Select Task';
		this.page = 1;
		this.selectedItem = item;
		this.jobProfileService.setSelectedJPCCModel(ccModel);
		this.jobProfileService.setSelectedTaskGroupModel(taskGroup)
		this.loadAllActiveTaskTag();
		this.getActiveTasks(ccModel);
	}

	// public editTaskGroup(item: string, ccModel: CoreCompetency, taskGroup: Task) {
	// 	this.selectedCCModel = ccModel;
	// 	this.showHierarchy = false
	// 	this.showAddTaskGroup = true
	// 	this.selectedTaskModel = null
	// 	this.selectedTaskGroupModel = taskGroup
	// 	this.selectedPerformanceModel = null
	// 	this.selectedKnowledgeModel = null
	// 	this.selectedCheckListModel = null

	// 	this.alertService.clear()
	// 	this.taskGroupTopic = 'Edit task group'
	// 	this.addTaskGroupForm.setValue({ taskGroup: taskGroup.taskGroup })
	// 	this.selectedItem = item
	// 	this.jobProfileService.setSelectedJPCCModel(ccModel);
	// }

	public addTaskGroupBtnClick(item: string, ccModel: CoreCompetency) {
		this.selectedCCModel = ccModel

		this.showHierarchy = false
		this.showTableData = true

		this.selectedTaskModel = null
		this.selectedTaskGroupModel = null
		this.selectedRegulatoryModel = null
		this.selectedPerformanceModel = null
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null

		this.alertService.clear()
		this.tableTopic = 'Select task group'
		this.page = 1;
		this.selectedItem = item
		this.jobProfileService.setSelectedJPCCModel(ccModel);
		this.jobProfileService.setSelectedTaskGroupModel(null)
		this.addTaskGroup = true;
		this.getActiveTaskGroups(ccModel)
	}

	//add new properties to the task (button click event)
	public addPropertiesToTaskBtnClick(item: string, ccModel: any, taskModel: any) {
		this.selectedCCModel = ccModel;
		this.selectedTaskModel = taskModel;
		this.selectedTaskGroupModel = null
		this.selectedRegulatoryModel = null
		this.selectedPerformanceModel = null
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null

		this.alertService.clear();
		this.selectedItem = item
		this.jobProfileService.setSelectedJPCCModel(ccModel)
		this.jobProfileService.setSelectedTaskModel(taskModel)
		switch (item) {
			case 'performance':
				this.tableTopic = 'Select Performance';
				this.page = 1;
				this.loadAllActivePerformanceTag();
				this.getActivePerformance(taskModel);
				break;
			case 'knowledge':
				this.tableTopic = 'Select Knowledge';
				this.page = 1;
				this.searchKnowledge = "";
				this.searchType = "";
				this.searchOrgCode = "";
				this.searchQuestion = "";
				this.searchVQuestion = "";
				this.loadAllActiveKnowledgeTag();
				this.loadActiveKnowledges(taskModel);
				break;
			case 'taskRemediation':
				this.tableTopic = 'Select Remediation';
				this.page = 1;
				this.jobProfileService.setRemediationType(item);
				this.loadAllActiveRemediationTag();
				this.getActiveRemediationList(taskModel);
				break;
			default:
				break;
		}
		this.showTableData = true;
		this.showHierarchy = false
	}

	public addRegulatoryAgency(taskId: any, taskModel: any) {
		this.loadAllRegulatoryAgency(taskModel)
		this.selectedTaskId = taskId
		this.isClickedRegAgency = true
	}
	public showCollapseRegPanel() {
		this.isClickedRegAgency = false
		this.selectedTaskId = ''
	}

	public selectedRegulatoryAgency(event: any, ccModel: any, taskModel: any, regModel: any) {
		this.selectedCCModel = ccModel;
		this.selectedTaskModel = taskModel;
		this.selectedTaskGroupModel = null
		this.selectedRegulatoryModel = regModel
		this.selectedPerformanceModel = null;
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null
		this.alertService.clear();
		this.jobProfileService.setSelectedJPCCModel(ccModel)
		this.jobProfileService.setSelectedTaskModel(taskModel)
		this.jobProfileService.setSelectedRegulatoryModel(regModel)
		this.addRegulatoryToDB(event.id, event.text, taskModel)
	}

	public removedRegulatoryAgency(event: any) {
		this.selectedRegAgency = null
	}

	setSelected(e, i) {
		let objIndex = this.data.findIndex((obj => obj.id == this.paginatedResults[i].id));
		if (e.target.checked) {
			this.data[objIndex].selected = 1;
		} else {
			this.data[objIndex].selected = 0;
		}
		this.selectedRows = this.data.filter(function (v) { return v["selected"] == 1; });
	}

	//add new remediations to knowledge button click
	public addRemediationToKnowledgeBtnClick(item: string, ccModel: any, taskModel: any, knowledgeModel: any) {
		this.selectedCCModel = ccModel
		this.selectedTaskModel = taskModel
		this.selectedTaskGroupModel = null
		this.selectedPerformanceModel = null
		this.selectedKnowledgeModel = knowledgeModel
		this.selectedCheckListModel = null

		this.alertService.clear()
		this.tableTopic = 'Select Remediation';
		this.page = 1;
		this.selectedItem = item
		this.getActiveRemediationList(knowledgeModel)
		this.loadAllActiveRemediationTag();
		this.jobProfileService.setSelectedJPCCModel(ccModel)
		this.jobProfileService.setSelectedTaskModel(taskModel)
		this.jobProfileService.setSelectedKnowledgeModel(knowledgeModel)
		this.jobProfileService.setRemediationType(item)
		this.showTableData = true
		this.showHierarchy = false
	}

	public addPropertiesToPerformanceBtnClick(item: string, ccModel: any, taskModel: any, perModel: any) {
		this.selectedCCModel = ccModel;
		this.selectedTaskModel = taskModel;
		this.selectedTaskGroupModel = null
		this.selectedPerformanceModel = perModel;
		this.selectedKnowledgeModel = null
		this.selectedCheckListModel = null

		this.alertService.clear();
		this.selectedItem = item
		this.jobProfileService.setSelectedJPCCModel(ccModel)
		this.jobProfileService.setSelectedTaskModel(taskModel)
		this.jobProfileService.setSelectedPerformanceModel(perModel)
		switch (item) {
			case 'checkList':
				this.tableTopic = 'Select Checklist';
				this.page = 1;
				this.loadAllActiveChecklistTag();
				this.getActiveCheckList(perModel);
				break;
			case 'performanceRemediation':
				this.tableTopic = 'Select Remediation';
				this.page = 1;
				this.jobProfileService.setRemediationType(item);
				this.loadAllActiveRemediationTag();
				this.getActiveRemediationList(perModel);
				break;
			default:
				break;
		}
		this.showTableData = true;
		this.showHierarchy = false
	}

	public addRemediationToCheckListBtnClick(item: string, ccModel: any, taskModel: any, perModel: any, clModel: any) {
		this.alertService.clear()
		this.selectedItem = item
		this.selectedCCModel = ccModel
		this.selectedTaskModel = taskModel
		this.selectedTaskGroupModel = null
		this.selectedPerformanceModel = perModel
		this.selectedCheckListModel = clModel
		this.page = 1;
		this.getActiveRemediationList(clModel)
		this.loadAllActiveRemediationTag();
		this.jobProfileService.setSelectedJPCCModel(ccModel)
		this.jobProfileService.setSelectedTaskModel(taskModel)
		this.jobProfileService.setSelectedPerformanceModel(perModel)
		this.jobProfileService.setSelectedCheckListModel(clModel)
		this.jobProfileService.setRemediationType(item)
		this.showTableData = true
		this.showHierarchy = false
	}

	//get all active core competency tags
	public loadAllActiveCoreCompTag() {
		this.coreCompetencyTagService.getActiveCoreCompetencyTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.coreCompetencyTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	//get all active task tags
	public loadAllActiveTaskTag() {
		this.taskTagService.getActiveTaskTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.taskTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	public loadAllRegulatoryAgency(taskModel: any) {
		const selectedList = taskModel.regulatoryList;
		this.regulatoryAgencyService.getAllRegulatoryAgency()
			.subscribe(
			data => {
				this.rgList = [];
				for (var i = 0; i < data.regulatoryAgencyList.length; i++) {
					if (selectedList.length == 0) {
						this.rgList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].ShortName });
					} else {
						if (this.checkValueExists(selectedList, data.regulatoryAgencyList[i].Id)) {
							// Data is available
						} else {
							this.rgList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].ShortName });
						}
					}

				}
				if (this.rgList.length > 0) {
					this.placeholderText = "Select regulatory agency"
				} else {
					this.placeholderText = "No items available"
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}

	//get all active performance tags
	public loadAllActivePerformanceTag() {
		this.performanceTagService.getActivePerformanceTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.ptagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	//get all active knowledge tags
	public loadAllActiveKnowledgeTag() {
		this.knowledgeTagService.getActiveKnowledgeTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.ccTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	//get all active checklist tags
	public loadAllActiveChecklistTag() {
		this.checklistTagService.getActiveChecklistTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.ccTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	//get all active remediation tags
	public loadAllActiveRemediationTag() {
		this.remediationTagService.getActiveRemediationTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.remediationTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	//get all active core competencies
	private getActiveCoreCompetencies() {
		this.isLoading = true;
		this.columns = [
			{ title: '', name: 'selectbutton', className: 'accepter-col-action' },
			{ title: 'Description', name: 'description', filtering: { filterString: '', placeholder: '' } }
		];
		this.corecompetencyServive.getActiveCoreCompetencies()
			.subscribe(
			data => {
				//console.log('[getActiveCoreCompetencies] data : ' + JSON.stringify(data) + '/(count)' + data.coreCompetencyList.length);
				this.data = [];
				for (let i = 0; i < data.coreCompetencyList.length; i++) {
					//console.log(data.coreCompetencyList[i].Tags);
					let tagsArray = data.coreCompetencyList[i].Tags == null ? [] : data.coreCompetencyList[i].Tags.split(",");
					//console.log(tagsArray);
					if (this.jobProfileModel.coreCompetencyList.length == 0) {
						this.data.push({
							'id': data.coreCompetencyList[i].Id,
							'description': data.coreCompetencyList[i].Description,
							'tags': tagsArray,
							'selected': '0', 'selectbutton': ''
						});
					} else {
						if (this.checkValueExists(this.jobProfileModel.coreCompetencyList, data.coreCompetencyList[i].Id)) {
							// let tempItem = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, data.coreCompetencyList[i].Id);
							// if(tempItem.taskList.length != 0) {
							//
							// } else {
							//     this.data.push({
							//         'id' : data.coreCompetencyList[i].Id,
							//         'description' : data.coreCompetencyList[i].Description,
							//         'selected': '1', 'selectbutton': ''
							//     });
							// }
						} else {
							this.data.push({
								'id': data.coreCompetencyList[i].Id,
								'description': data.coreCompetencyList[i].Description,
								'tags': tagsArray,
								'selected': '0', 'selectbutton': ''
							});
						}
					}
				}
				this.originalData = this.data;
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData(false);
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	//get task list without task groups
	private getActualTaskList(taskList: any): any {
		let returnTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				task.taskList.forEach(tgt => {
					returnTaskList.push(tgt)
				});
			} else {
				returnTaskList.push(task)
			}
		});
		return returnTaskList
	}

	//get active tasks
	private getActiveTasks(ccModel: any) {
		this.isLoading = true;
		const selectedList = this.getActualTaskList(ccModel.taskList)

		this.columns = [
			{ title: '', name: 'selectbutton', className: 'accepter-col-action' },
			{ title: 'Task', name: 'description', filtering: { filterString: '', placeholder: '' } }
		];

		this.taskService.getActiveTasks().subscribe(
			data => {
				//console.log('[getActiveTasks] data : ' + JSON.stringify(data) + '/(count)' + data.taskList.length);
				this.data = [];
				for (let i = 0; i < data.taskList.length; i++) {
					//console.log(data.taskList[i].Tags);
					let tagsArray = data.taskList[i].Tags == null ? [] : data.taskList[i].Tags.split(",");
					//console.log(tagsArray);
					if (selectedList.length == 0) {
						this.data.push({
							'id': data.taskList[i].Id,
							'description': data.taskList[i].Task,
							'tags': tagsArray,
							'selected': '0', 'selectbutton': ''
						});
					} else {
						if (this.checkValueExists(selectedList, data.taskList[i].Id)) {
							// let tempItem = this.getItemByIdFromArray(ccModel.taskList, data.taskList[i].Id);
							// if((tempItem.performanceList.length != 0) || (tempItem.knowledgeList.length != 0) || (tempItem.remediationList.length != 0)) {
							//
							// } else {
							//     this.data.push({
							//         'id' : data.taskList[i].Id,
							//         'description' : data.taskList[i].Task,
							//         'selected': '1', 'selectbutton': ''
							//     });
							// }
						} else {
							this.data.push({
								'id': data.taskList[i].Id,
								'description': data.taskList[i].Task,
								'tags': tagsArray,
								'selected': '0', 'selectbutton': ''
							});
						}
					}
				}
				this.originalData = this.data;
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData(false);
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		)
	}

	private getTaskGroupListFromTaskList(taskList: any): any {
		let taskgroupList = []
		taskList.forEach(element => {
			if (element.isTaskGroup) {
				taskgroupList.push(element)
			} else {

			}
		});
		return taskgroupList
	}

	private getActiveTaskGroups(ccModel: CoreCompetency) {
		this.isLoading = true;
		let selectedTaskGroupList = this.getTaskGroupListFromTaskList(ccModel.taskList)

		this.columns = [
			{ title: '', name: 'selectbutton', className: 'accepter-col-action' },
			{ title: 'Task Group', name: 'description', filtering: { filterString: '', placeholder: '' } }
		];

		this.taskService.getAllTaskGroups().subscribe(
			data => {
				this.data = []

				for (let i = 0; i < data.taskGroupList.length; i++) {
					if (data.taskGroupList[i].Status == 1) {
						if (selectedTaskGroupList.length == 0) {
							this.data.push({
								'id': data.taskGroupList[i].Id,
								'description': data.taskGroupList[i].TaskGroup,
								'selected': '0', 'selectbutton': ''
							});
						} else {
							if (this.checkValueExistsForTaskGroup(selectedTaskGroupList, data.taskGroupList[i].Id)) {

							} else {
								this.data.push({
									'id': data.taskGroupList[i].Id,
									'description': data.taskGroupList[i].TaskGroup,
									'selected': '0', 'selectbutton': ''
								});
							}
						}
					}
				}
				this.originalData = this.data;
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData(false);
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		)
	}

	//get active performance list
	private getActivePerformance(taskModel: any) {
		this.isLoading = true;
		const selectedList = taskModel.performanceList;

		this.columns = [
			{ title: '', name: 'selectbutton', className: 'accepter-col-action' },
			{ title: 'Description', name: 'description', filtering: { filterString: '', placeholder: '' } }
		];

		this.performanceService.getActivePerformances().subscribe(
			data => {
				//console.log('[getActivePerformance] data : ' + JSON.stringify(data) + '/(count)' + data.performanceList.length);
				this.data = [];
				for (let i = 0; i < data.performanceList.length; i++) {
					//console.log(data.performanceList[i].Tags);
					let tagsArray = data.performanceList[i].Tags == null ? [] : data.performanceList[i].Tags.split(",");
					//console.log(tagsArray);
					if (selectedList.length == 0) {
						this.data.push({
							'id': data.performanceList[i].Id,
							'description': data.performanceList[i].Performance,
							'tags': tagsArray,
							'selected': '0', 'selectbutton': ''
						});
					} else {
						if (this.checkValueExists(selectedList, data.performanceList[i].Id)) {
							// let tempItem = this.getItemByIdFromArray(taskModel.performanceList, data.performanceList[i].Id);
							// if((tempItem.checkList.length != 0) || (tempItem.remediationList.length != 0)) {
							//
							// } else {
							//     this.data.push({
							//         'id' : data.performanceList[i].Id,
							//         'description' : data.performanceList[i].Performance,
							//         'selected': '1', 'selectbutton': ''
							//     });
							// }
						} else {
							this.data.push({
								'id': data.performanceList[i].Id,
								'description': data.performanceList[i].Performance,
								'tags': tagsArray,
								'selected': '0', 'selectbutton': ''
							});
						}
					}
				}
				this.originalData = this.data;
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData(false);
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private getActiveCheckList(performanceModel: any) {
		this.isLoading = true;
		const selectedList = performanceModel.checkList;
		this.columns = [
			{ title: '', name: 'selectbutton', className: 'accepter-col-action' },
			{ title: 'Description', name: 'description', filtering: { filterString: '', placeholder: '' } }
		];

		this.checkListService.getActliveChecklistByOrgCode(this.jobProfileModel.orgCodeId)
			.subscribe(
			data => {
				//console.log('[getActiveCheckList] (data) : ' + JSON.stringify(data) + '/(count) ' + data.checklist.length);
				this.data = [];
				for (let i = 0; i < data.checklist.length; i++) {
					//console.log(data.checklist[i].Tags);
					let tagsArray = data.checklist[i].Tags == null ? [] : data.checklist[i].Tags.split(",");
					//console.log(tagsArray);
					if (selectedList.length == 0) {
						this.data.push({
							'id': data.checklist[i].Id,
							'description': data.checklist[i].Description,
							'tags': tagsArray,
							'selected': '0', 'selectbutton': ''
						});
					} else {
						if (this.checkValueExists(selectedList, data.checklist[i].Id)) {
							// let tempItem = this.getItemByIdFromArray(performanceModel.checkList, data.checklist[i].Id);
							// if(tempItem.remediationList.length != 0) {
							//
							// } else {
							//     this.data.push({
							//         'id' : data.checklist[i].Id,
							//         'description' : data.checklist[i].Description,
							//         'selected': '1', 'selectbutton': ''
							//     });
							// }
						} else {
							this.data.push({
								'id': data.checklist[i].Id,
								'description': data.checklist[i].Description,
								'tags': tagsArray,
								'selected': '0', 'selectbutton': ''
							});
						}
					}
				}
				this.originalData = this.data;
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData(false);
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	pageChanged(page?: number): void {
		if (page) { this.page = page; }
		let end = this.itemsPerPage * this.page;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.length = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	//Filter knowledges
	filter() {
		this.filteredData = this.data;
		if (this.searchKnowledge != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["description"].match(new RegExp(this.searchKnowledge, "i"));
			});
		}
		if (this.searchType != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["type"].match(new RegExp(this.searchType, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["orgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchQuestion != "") {
			if (this.searchQuestion == "2") {

			}
			else {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["isTestQuestion"].match(new RegExp(this.searchQuestion, "i"));
				});
			}
		}
		if (this.searchVQuestion != "") {
			if (this.searchVQuestion == "2") {

			}
			else {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["isVisualQuestion"].match(new RegExp(this.searchVQuestion, "i"));
				});
			}
		}
		this.pageChanged(1);
	}


	//load active knowledges
	private loadActiveKnowledges(taskModel: any) {
		this.isLoading = true;
		const selectedList = taskModel.knowledgeList.concat(taskModel.knowledgeQuestionList);
		this.data = [];
		this.knowledgeService.getActliveKnowledgeByOrgCode(this.jobProfileModel.orgCodeId)
			.subscribe(
			data => {
				// console.log('[getActiveKnowledgeList] (data) : ' + JSON.stringify(data) + '/(count) ' + data.knowledge.length);
				this.data = [];
				for (let i = 0; i < data.knowledge.length; i++) {
					// console.log(data.knowledge[i].Tags)
					let tagsArray = data.knowledge[i].Tags == null ? [] : data.knowledge[i].Tags.split(",");
					// console.log(tagsArray)
					if (selectedList.length == 0) {
						this.data.push({
							'id': data.knowledge[i].Id,
							'description': data.knowledge[i].Knowledge,
							'isTestQuestion': data.knowledge[i].IsTestQuestion == 1 ? "1" : "0",
							'isVisualQuestion': data.knowledge[i].IsVisualQuestion == 1 ? "1" : "0",
							'type': data.knowledge[i].Type,
							'tags': tagsArray,
							'orgCode': data.knowledge[i].OrgCode == null ? "" : data.knowledge[i].OrgCode,
							'selected': '0', 'selectbutton': ''
						});
					} else {
						if (this.checkValueExists(selectedList, data.knowledge[i].Id)) {

						} else {
							this.data.push({
								'id': data.knowledge[i].Id,
								'description': data.knowledge[i].Knowledge,
								'isTestQuestion': data.knowledge[i].IsTestQuestion == 1 ? "1" : "0",
								'isVisualQuestion': data.knowledge[i].IsVisualQuestion == 1 ? "1" : "0",
								'type': data.knowledge[i].Type,
								'tags': tagsArray,
								'orgCode': data.knowledge[i].OrgCode == null ? "" : data.knowledge[i].OrgCode,
								'selected': '0', 'selectbutton': ''
							});
						}
					}
				}
				this.showKnowledges = true;
				this.length = this.data.length;
				this.filteredData = this.data;
				this.originalData = this.data;
				this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
				this.pageChanged();
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	private getActiveRemediationList(parentModel: any) {
		this.isLoading = true;
		const selectedList = parentModel.remediationList;
		this.columns = [
			{ title: '', name: 'selectbutton', className: 'accepter-col-action pr-5' },
			{ title: 'Description', name: 'description', filtering: { filterString: '', placeholder: '' } },
			{ title: 'Delivery Medium', name: 'deliverymedium', filtering: { filterString: '', placeholder: '' } },
			{ title: 'Type', name: 'type', filtering: { filterString: '', placeholder: '' } },
			{ title: 'Course Description', name: 'coursedescription', filtering: { filterString: '', placeholder: '' } }
		];

		this.remediationService.getActliveRemediationByOrgCode(this.jobProfileModel.orgCodeId)
			.subscribe(
			data => {
				//console.log('[getActiveCheckList] (data) : ' + JSON.stringify(data) + '/(count) ' + data.remediation.length);
				this.data = [];
				for (let i = 0; i < data.remediation.length; i++) {
					//console.log(data.remediation[i].Tags);
					let tagsArray = data.remediation[i].Tags == null ? [] : data.remediation[i].Tags.split(",");
					//console.log(tagsArray);
					if (selectedList.length == 0) {
						this.data.push({
							'id': data.remediation[i].Id,
							'description': data.remediation[i].Description,
							'deliverymedium': data.remediation[i].DeliveryMedium,
							'type': data.remediation[i].Type,
							'coursedescription': data.remediation[i].CourseDescription,
							'tags': tagsArray,
							'selected': '0', 'selectbutton': ''
						});
					} else {
						if (this.checkValueExists(selectedList, data.remediation[i].Id)) {
							this.data.push({
								'id': data.remediation[i].Id,
								'description': data.remediation[i].Description,
								'deliverymedium': data.remediation[i].DeliveryMedium,
								'type': data.remediation[i].Type,
								'coursedescription': data.remediation[i].CourseDescription,
								'tags': tagsArray,
								'selected': '1', 'selectbutton': ''
							});
						} else {
							this.data.push({
								'id': data.remediation[i].Id,
								'description': data.remediation[i].Description,
								'deliverymedium': data.remediation[i].DeliveryMedium,
								'type': data.remediation[i].Type,
								'coursedescription': data.remediation[i].CourseDescription,
								'tags': tagsArray,
								'selected': '0', 'selectbutton': ''
							});
						}
					}
				}
				this.originalData = this.data;
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData(false);
				this.isLoading = false;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			)
	}

	//add tags function
	public addTags(event: any) {
		// console.log('[addTags]'+ event.id);
		this.filterByTags(event);
	}

	public filterByTags(event: any) {
		this.selectedTags.push(event);
		this.data = this.originalData.filter(
			item => {
				return this.selectedTags.every(tag => {
					if (item.tags.length == 0) {
						return false;
					} else {
						if (item.tags.length == 1) {
							if (item.tags == tag.id) {
								return true;
							} else {
								return false;
							}
						} else {
							if (item.tags.indexOf(tag.id) < 0) {
								return false;
							} else {
								return true;
							}
						}
					}
				});
			}
		);
		this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
		this.onChangeTable(this.config);
		// this.extendData(false);		
		this.paginatedResults = this.data
	}

	// remove tag fuction
	public removeTags(event: any) {
		this.filterByRemoveTags(event);
	}

	public filterByRemoveTags(event: any) {
		// console.log('event ' + event.value.id);
		this.selectedTags = this.selectedTags.filter(tag => tag !== event);
		this.data = this.originalData.filter(
			item => {
				return this.selectedTags.every(tag => {
					if (item.tags.length == 0) {
						return false;
					} else {
						if (item.tags.length == 1) {
							if (item.tags == tag.id) {
								return true;
							} else {
								return false;
							}
						} else {
							if (item.tags.indexOf(tag.id) < 0) {
								return false;
							} else {
								return true;
							}
						}
					}
				});
			}
		);
		this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
		this.onChangeTable(this.config);
		// this.extendData(false);		
		this.paginatedResults = this.data
	}

	//check given value exists in a given array
	private checkValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.id == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	//check value exist for task group
	private checkValueExistsForTaskGroup(list: any[], value: string): boolean {
		var isExists = false
		list.forEach(a => {
			if (a.tgId == value) {
				isExists = true
			}
		});
		return isExists
	}

	//check value exist for skill properties
	private checkValueExistsForSkillProperties(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.Id == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	//remove item from normal array list
	private removeItemFromArray(arrayList: any, itemId: string): any {
		arrayList = arrayList.filter(item => item.id !== itemId);
		return arrayList
	}

	//remove item from skill properties array (check list, knowledge, remediation)
	private removeItemFromSkillPropertyArray(arrayList: any, itemId: string): any {
		arrayList = arrayList.filter(item => item.Id !== itemId);
		return arrayList
	}

	//find item by id in arrayList
	private getItemByIdFromArray(arrayList: any, itemId: string): any {
		return arrayList.find(item => item.id === itemId);
	}

	//find item by tgId in arrayList
	private getItemByTGIdFromArray(arrayList: any, itemId: string): any {
		return arrayList.find(item => item.tgId === itemId);
	}
	//</editor-fold> SHOWTABLE

	//<editor-fold> ADD_ITEM_TO_JOBPROFILE
	//add selected item to db (Add TO Job Profile button click event)
	public addToJobProfile() {
		switch (this.selectedItem) {
			case 'coreCompetency':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addCoreCompetencyToDB();
				}
				break;
			case 'taskGroupTask':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addTaskGroupTaskToDB();
				}
				break;
			case 'task':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addTasksToDB();
				}
				break;
			case 'taskGroup':
				if (this.jobProfileModel.jobProfileStatus) {
					this.alertService.clear()
					this.alertService.error(Constants.ERROR_JOB_PROFILE_STATUS_DISABLE_ADD)
				} else {
					this.addTaskGroupToDB()
				}
				break;
			case 'performance':
				this.addPerformanceToDB();
				break;
			case 'taskRemediation':
				this.addTaskRemediationToDB();
				break;
			case 'checkListRemediation':
				this.addCheckListRemediationToDB();
				break;
			case 'checkList':
				this.addCheckListToDB();
				break;
			case 'knowledge':
				this.addKnowledgeToDB();
				break;
			case 'knowledgeRemediation':
				this.addKnowledgeRemediationToDB();
				break;
			case 'performanceRemediation':
				this.addPerformanceRemediationToDB();
				break;
			default:
				break;
		}
	}

	//add core competencies to the DB
	public addCoreCompetencyToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
		tempJobProfile.coreCompetencyList = this.getTableSelectedValues();

		let ccList = this.jobProfileModel.coreCompetencyList;
		let newItems = [];
		if (ccList.length != 0) {
			this.getTableSelectedValues().forEach(ccItem => {
				if (this.checkValueExists(this.jobProfileModel.coreCompetencyList, ccItem.id)) {
					//do nothing
				} else {
					let itemCC = new CoreCompetency(ccItem.id, ccItem.text);
					itemCC.taskList = [];
					itemCC.taskTotal = 0;
					ccList.push(itemCC);
					newItems.push(itemCC);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(ccItem => {
				let itemCC = new CoreCompetency(ccItem.id, ccItem.text);
				itemCC.taskList = [];
				itemCC.taskTotal = 0;
				ccList.push(itemCC);
				newItems.push(itemCC);
			});
		}
		tempJobProfile.coreCompetencyList = newItems;
		this.jobProfileModel.coreCompetencyList = ccList;
		if (newItems.length != 0) {
			this.jobProfileService.addCoreCompetenciesToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_CORECOMPETENCY)
		}
	}

	//add task to task group in job profile
	public addTaskGroupTaskToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let taskGroup: Task = this.selectedTaskGroupModel
		let currentTaskList = taskGroup.taskList
		let newtaskList = [];
		let i = currentTaskList.length

		if (currentTaskList != 0) {
			this.getTableSelectedValues().forEach(taskItem => {
				if (this.checkValueExists(currentTaskList, taskItem.id)) {
					//do nothing
				} else {
					let itemTask = new Task(taskItem.id, taskItem.text, false, [], [], []);
					itemTask.regulatoryList = []
					itemTask.orderNum = ++i;
					itemTask.isTaskGroup = false
					itemTask.criticalityValue = 1
					itemTask.taskGroupId = taskGroup.id
					itemTask.remediationList = []
					itemTask.knowledgeQuestionList = []
					currentTaskList.push(itemTask);
					newtaskList.push(itemTask);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(taskItem => {
				let itemTask = new Task(taskItem.id, taskItem.text, false, [], [], []);
				itemTask.regulatoryList = []
				itemTask.orderNum = ++i;
				itemTask.isTaskGroup = false
				itemTask.criticalityValue = 1;
				itemTask.taskGroupId = taskGroup.id
				itemTask.remediationList = []
				itemTask.knowledgeQuestionList = []
				currentTaskList.push(itemTask);
				newtaskList.push(itemTask);
			});
		}
		taskGroup.taskList = currentTaskList;

		const ccMItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text);
		ccMItem.taskList = newtaskList;
		//ccMItem.taskTotal = this.selectedCCModel.taskList.length  newtaskList.length;
		tempJobProfile.coreCompetencyList = ccMItem;
		if (newtaskList.length != 0) {
			this.jobProfileService.addTasksToTaskGroupInJP(tempJobProfile).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'taskGroup') {
						this.showCollapsePanel(this.selectedTaskModel.id, 'taskGroup');
					} else {
						//do nothing
					}

				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_TASK)
		}
	}

	//add tasks to the DB
	public addTasksToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let currentTaskList = jpccModelItem.taskList;
		let newtaskList = [];
		let i = currentTaskList.length

		if (currentTaskList != 0) {
			this.getTableSelectedValues().forEach(taskItem => {
				if (this.checkValueExists(currentTaskList, taskItem.id)) {
					//do nothing
				} else {
					let itemTask = new Task(taskItem.id, taskItem.text, false, [], [], []);
					itemTask.regulatoryList = []
					itemTask.orderNum = ++i;
					itemTask.isTaskGroup = false
					itemTask.criticalityValue = 1;
					itemTask.remediationList = []
					itemTask.knowledgeQuestionList = []
					currentTaskList.push(itemTask);
					newtaskList.push(itemTask);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(taskItem => {
				let itemTask = new Task(taskItem.id, taskItem.text, false, [], [], []);
				itemTask.regulatoryList = []
				itemTask.orderNum = ++i;
				itemTask.isTaskGroup = false
				itemTask.criticalityValue = 1;
				itemTask.remediationList = []
				itemTask.knowledgeQuestionList = []
				currentTaskList.push(itemTask);
				newtaskList.push(itemTask);
			});
		}
		jpccModelItem.taskList = currentTaskList;

		const ccMItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text);
		ccMItem.taskList = newtaskList;
		let taskTotal = this.selectedCCModel.taskTotal + newtaskList.length
		tempJobProfile.coreCompetencyList = ccMItem;
		// console.log('[tempJobProfile] ' + JSON.stringify(tempJobProfile).toString());
		if (newtaskList.length != 0) {
			this.jobProfileService.addTaskToJobProfile(tempJobProfile).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					jpccModelItem.taskTotal = taskTotal
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'coreCompetency') {
						this.showCollapsePanel(this.selectedCCModel.id, 'coreCompetency');
					} else {
						//do nothing
					}
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_TASK)
		}
	}

	// public taskGroupFormSubmit() {
	// 	if (this.taskGroupTopic == 'Add task group') {
	// 		this.addTaskGroupToDB()
	// 	} else {
	// 		this.editTaskGroupName()
	// 	}
	// }

	//add task group to db
	public addTaskGroupToDB() {
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let currentTaskList = jpccModelItem.taskList
		let currentTaskGroupList = this.getTaskGroupListFromTaskList(jpccModelItem.taskList);
		let i = currentTaskList.length
		let newtaskList = []

		if (currentTaskGroupList.length != 0) {
			this.getTableSelectedValues().forEach(taskItem => {
				if (this.checkValueExistsForTaskGroup(currentTaskList, taskItem.id)) {
					//do nothing
				} else {
					let itemTask = new Task();
					itemTask.orderNum = ++i;
					itemTask.isTaskGroup = true
					itemTask.criticalityValue = 1;
					itemTask.tgId = taskItem.id
					itemTask.taskGroup = taskItem.text
					itemTask.taskList = []
					currentTaskList.push(itemTask);
					newtaskList.push(itemTask);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(taskItem => {
				let itemTask = new Task();
				itemTask.orderNum = ++i;
				itemTask.isTaskGroup = true
				itemTask.criticalityValue = 1;
				itemTask.tgId = taskItem.id
				itemTask.taskGroup = taskItem.text
				itemTask.taskList = []
				currentTaskList.push(itemTask);
				newtaskList.push(itemTask);
			});
		}

		const ccMItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text);
		ccMItem.taskList = newtaskList;
		let taskTotal = this.selectedCCModel.taskTotal + newtaskList.length
		tempJobProfile.coreCompetencyList = ccMItem;

		this.jobProfileService.addTaskGroupToJobProfile(tempJobProfile).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				jpccModelItem.taskTotal = taskTotal
				data.idList.forEach(element => {
					let tgItem: Task = this.getItemByTGIdFromArray(currentTaskList, element.tgId)
					tgItem.id = element.id
				});
				this.showHierarchy = true
				this.showTableData = false
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	// public editTaskGroupName() {
	// 	let taskGroup: Task = this.selectedTaskGroupModel
	// 	// console.log('[editTaskGroupName] (taskGroup) ' + JSON.stringify(taskGroup));
	// 	const newTaskGroupText: string = this.addTaskGroupForm.value.taskGroup;

	// 	if (newTaskGroupText != taskGroup.taskGroup) {
	// 		let tempTask: Task = new Task(taskGroup.id)
	// 		tempTask.taskGroup = newTaskGroupText;
	// 		this.jobProfileService.editTaskGroupInJP(tempTask).subscribe(
	// 			data => {
	// 				this.alertService.clear();
	// 				this.alertService.success(data.message);
	// 				taskGroup.taskGroup = newTaskGroupText
	// 			},
	// 			error => {
	// 				let err = error.json();
	// 				this.alertService.clear();
	// 				this.alertService.handleError(err);
	// 			}
	// 		)
	// 	} else {
	// 		this.alertService.clear();
	// 		this.alertService.error('There are no changes to update.')
	// 	}
	// }


	//add performance to DB

	public addRegulatoryToDB(regulatorySelectedId: string, regulatorySelectedText: any, taskModel: any) {
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task

		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}

		let currentRegulatoryList = jpTaskModelItem.regulatoryList;
		let newRegList = []

		if (currentRegulatoryList.length != 0) {
			if (this.checkValueExists(currentRegulatoryList, regulatorySelectedId)) {
				//do nothing
			} else {
				let itemRegulatory: RegulatoryAgency = new RegulatoryAgency(regulatorySelectedId, '');
				itemRegulatory.text = regulatorySelectedText
				currentRegulatoryList.push(itemRegulatory);
				newRegList.push(itemRegulatory);
			}

		} else {
			let itemRegulatory: RegulatoryAgency = new RegulatoryAgency(regulatorySelectedId, '');
			itemRegulatory.text = regulatorySelectedText
			currentRegulatoryList.push(itemRegulatory);
			newRegList.push(itemRegulatory);
		}

		jpTaskModelItem.regulatoryList = currentRegulatoryList;

		const tempJpTaskModelItem: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text, true);
		tempJpTaskModelItem.regulatoryList = newRegList;
		const tempJPCCModelItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text, true, tempJpTaskModelItem);
		tempJobProfile.coreCompetencyList = tempJPCCModelItem;

		this.jobProfileService.addRegulatoryAgencyToJobProfile(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadAllRegulatoryAgency(taskModel)
				this.removedRegulatoryAgency(tempJpTaskModelItem.regulatoryList)
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	public addPerformanceToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		console.log(jpTaskModelItem, null, 4)
		let currentPerList = jpTaskModelItem.performanceList;
		let newPerList = [];
		let i = currentPerList.length

		if (currentPerList.length != 0) {
			this.getTableSelectedValues().forEach(performanceItem => {
				if (this.checkValueExists(currentPerList, performanceItem.id)) {
					//do nothing
				} else {
					let itemPerformance: Performance = new Performance(performanceItem.id, performanceItem.text, true, [], []);
					itemPerformance.orderNum = ++i;
					currentPerList.push(itemPerformance);
					newPerList.push(itemPerformance);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(performanceItem => {
				let itemPerformance: Performance = new Performance(performanceItem.id, performanceItem.text, true, [], []);
				itemPerformance.orderNum = ++i;
				currentPerList.push(itemPerformance);
				newPerList.push(itemPerformance);

			});
		}
		jpTaskModelItem.performanceList = currentPerList;

		const tempJpTaskModelItem: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text, true, newPerList);
		const tempJPCCModelItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text, true, tempJpTaskModelItem);
		tempJobProfile.coreCompetencyList = tempJPCCModelItem;

		// console.log('[tempJobProfile] ' + JSON.stringify(tempJobProfile).toString());
		if (newPerList.length != 0) {
			this.jobProfileService.addPerformanceToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'task') {
						this.showCollapsePanel(this.selectedTaskModel.id, 'task');
					} else {
						//do nothing
					}

				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_PERFORMANCE)
		}
	}

	//add knowledge to db
	private addKnowledgeToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		let currentKnowledgeList = jpTaskModelItem.knowledgeList.concat(jpTaskModelItem.knowledgeQuestionList);

		let newKnowledgeList = [];
		let i = currentKnowledgeList.length

		if (currentKnowledgeList.length != 0) {
			this.getTableSelectedValues().forEach(kItem => {
				if (this.checkValueExists(currentKnowledgeList, kItem.id)) {
					//do nothing
				} else {
					let knowledgeItem: Knowledge = new Knowledge(kItem.text, 0);
					knowledgeItem.id = kItem.id
					knowledgeItem.remediationList = []
					knowledgeItem.orderNum = ++i;
					knowledgeItem.isTestQuestion = kItem.testQuestion;
					currentKnowledgeList.push(knowledgeItem);
					newKnowledgeList.push(knowledgeItem);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(kItem => {
				let knowledgeItem: Knowledge = new Knowledge(kItem.text, 0);
				knowledgeItem.id = kItem.id
				knowledgeItem.remediationList = []
				knowledgeItem.orderNum = ++i;
				knowledgeItem.isTestQuestion = kItem.testQuestion;
				currentKnowledgeList.push(knowledgeItem);
				newKnowledgeList.push(knowledgeItem);
			});
		}
		jpTaskModelItem.knowledgeList = currentKnowledgeList.filter(item => item.isTestQuestion == 0);
		jpTaskModelItem.knowledgeQuestionList = currentKnowledgeList.filter(item => item.isTestQuestion == 1);

		const tempJpTaskModelItem: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text);
		tempJpTaskModelItem.knowledgeList = newKnowledgeList;
		const tempJPCCModelItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text, true, tempJpTaskModelItem);
		tempJobProfile.coreCompetencyList = tempJPCCModelItem;

		// console.log('[tempJobProfile] ' + JSON.stringify(tempJobProfile).toString());
		if (newKnowledgeList.length != 0) {
			this.jobProfileService.addKnowledgeToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'task') {
						this.showCollapsePanel(this.selectedTaskModel.id, 'task');
					} else {
						//do nothing
					}
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_KNOWLEDGE)
		}
	}

	//add remediation to the task (table: jobprofiletaskremediation)
	private addTaskRemediationToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		let currentRemList = jpTaskModelItem.remediationList;
		let newRemList = [];
		if (currentRemList.length != 0) {
			this.getTableSelectedValues().forEach(remediationItem => {
				if (this.checkValueExists(currentRemList, remediationItem.id)) {
					//do nothing
				} else {
					currentRemList.push(remediationItem);
					newRemList.push(remediationItem);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(remediationItem => {
				currentRemList.push(remediationItem);
				newRemList.push(remediationItem);
			});
		}
		jpTaskModelItem.remediationList = currentRemList;

		const tempJpTaskModelItem: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text);
		tempJpTaskModelItem.remediationList = newRemList;
		const tempJPCCModelItem: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text, true, tempJpTaskModelItem);
		tempJobProfile.coreCompetencyList = tempJPCCModelItem;

		// console.log('[tempJobProfile] ' + JSON.stringify(tempJobProfile).toString());
		if (newRemList.length != 0) {
			this.jobProfileService.addTaskRemediationToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'task') {
						this.showCollapsePanel(this.selectedTaskModel.id, 'task');
					} else {
						//do nothing
					}
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_REMEDIATION);
		}
	}

	//add remediation to knowledge
	public addKnowledgeRemediationToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		let knowledgeItem: Knowledge = this.getItemByIdFromArray(jpTaskModelItem.knowledgeList.concat(jpTaskModelItem.knowledgeQuestionList), this.selectedKnowledgeModel.id)
		let currentRemList = knowledgeItem.remediationList;
		let newRemList = [];
		if (currentRemList.length != 0) {
			this.getTableSelectedValues().forEach(remediationItem => {
				if (this.checkValueExists(currentRemList, remediationItem.id)) {
					//do nothing
				} else {
					currentRemList.push(remediationItem);
					newRemList.push(remediationItem);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(remediationItem => {
				currentRemList.push(remediationItem);
				newRemList.push(remediationItem);
			});
		}
		knowledgeItem.remediationList = currentRemList;

		const tempKnowledgeItem: Knowledge = new Knowledge(this.selectedKnowledgeModel.knowledge, this.selectedKnowledgeModel.isTestQuestion)
		tempKnowledgeItem.id = this.selectedKnowledgeModel.id
		tempKnowledgeItem.remediationList = newRemList;
		const tempTaskModel: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text)
		tempTaskModel.knowledgeList = tempKnowledgeItem
		const tempCCModel: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text, true, tempTaskModel)
		tempJobProfile.coreCompetencyList = tempCCModel;
		if (newRemList.length != 0) {
			this.jobProfileService.addKnowledgeRemediationToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'knowledgeRemediation') {
						this.showCollapsePanel(this.selectedKnowledgeModel.id, 'knowledgeRemediation');
					} else {
						//do nothing
					}

				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				)
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_REMEDIATION)
		}
	}

	private addCheckListToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		let jpPerformanceModel: Performance = this.getItemByIdFromArray(jpTaskModelItem.performanceList, this.selectedPerformanceModel.id);
		let currentCheckList = jpPerformanceModel.checkList
		let newCheckList = []
		let i = currentCheckList.length;

		if (currentCheckList.length != 0) {
			this.getTableSelectedValues().forEach(clItem => {
				if (this.checkValueExists(currentCheckList, clItem.id)) {
					//do nothing
				} else {
					let item = new CheckList(clItem.id, clItem.text)
					item.remediationList = []
					item.orderNum = ++i;
					currentCheckList.push(item);
					newCheckList.push(item);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(clItem => {
				let item = new CheckList(clItem.id, clItem.text)
				item.remediationList = []
				item.orderNum = ++i;
				currentCheckList.push(item);
				newCheckList.push(item);
			});
		}
		jpPerformanceModel.checkList = currentCheckList;

		const tempPerformanceModel: Performance = new Performance(this.selectedPerformanceModel.id, this.selectedPerformanceModel.text, true)
		tempPerformanceModel.checkList = newCheckList
		const tempTaskModel: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text)
		tempTaskModel.performanceList = tempPerformanceModel
		const tempCCModel: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text)
		tempCCModel.taskList = tempTaskModel
		tempJobProfile.coreCompetencyList = tempCCModel
		if (newCheckList.length != 0) {
			this.jobProfileService.addCheckListToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'performance') {
						this.showCollapsePanel(this.selectedPerformanceModel.id, 'performance');
					} else {
						//do nothing
					}

				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_CHECKLIST);
		}
	}

	// add performance remediation to db
	public addPerformanceRemediationToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		let jpPerformanceModel: Performance = this.getItemByIdFromArray(jpTaskModelItem.performanceList, this.selectedPerformanceModel.id);
		let currentRemList = jpPerformanceModel.remediationList
		let newRemList = []

		if (currentRemList.length != 0) {
			this.getTableSelectedValues().forEach(remediationItem => {
				if (this.checkValueExists(currentRemList, remediationItem.id)) {
					//do nothing
				} else {

					currentRemList.push(remediationItem);
					newRemList.push(remediationItem);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(remediationItem => {
				currentRemList.push(remediationItem);
				newRemList.push(remediationItem);
			});
		}
		jpPerformanceModel.remediationList = currentRemList;

		const tempPerformanceModel: Performance = new Performance(this.selectedPerformanceModel.id, this.selectedPerformanceModel.text, true)
		tempPerformanceModel.remediationList = newRemList
		const tempTaskModel: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text)
		tempTaskModel.performanceList = tempPerformanceModel
		const tempCCModel: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text)
		tempCCModel.taskList = tempTaskModel
		tempJobProfile.coreCompetencyList = tempCCModel
		if (newRemList.length != 0) {
			this.jobProfileService.addPerformanceRemediationToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'performance') {
						this.showCollapsePanel(this.selectedPerformanceModel.id, 'performance');
					} else {
						//do nothing
					}
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_REMEDIATION);
		}
	}

	public addCheckListRemediationToDB() {
		this.selectedTags = [];
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, this.selectedCCModel.id);
		let jpTaskModelItem: Task
		if (this.selectedTaskModel.taskGroupId != null && this.selectedTaskModel.taskGroupId != 'null') {
			let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.taskGroupId)
			jpTaskModelItem = this.getItemByIdFromArray(taskGroup.taskList, this.selectedTaskModel.id)
		} else {
			jpTaskModelItem = this.getItemByIdFromArray(jpccModelItem.taskList, this.selectedTaskModel.id);
		}
		let jpPerformanceModel: Performance = this.getItemByIdFromArray(jpTaskModelItem.performanceList, this.selectedPerformanceModel.id);
		let jpCheckListModel: CheckList = this.getItemByIdFromArray(jpPerformanceModel.checkList, this.selectedCheckListModel.id)
		let currentRemList = jpCheckListModel.remediationList
		let newRemList = []

		if (currentRemList.length != 0) {
			this.getTableSelectedValues().forEach(remediationItem => {
				if (this.checkValueExists(currentRemList, remediationItem.id)) {
					//do nothing
				} else {
					currentRemList.push(remediationItem);
					newRemList.push(remediationItem);
				}
			});
		} else {
			this.getTableSelectedValues().forEach(remediationItem => {
				currentRemList.push(remediationItem);
				newRemList.push(remediationItem);
			});
		}
		jpCheckListModel.remediationList = currentRemList

		const tempCheckListModel: CheckList = new CheckList(this.selectedCheckListModel.id, this.selectedCheckListModel.text)
		tempCheckListModel.remediationList = newRemList
		const tempPerformanceModel: Performance = new Performance(this.selectedPerformanceModel.id, this.selectedPerformanceModel.text, true)
		tempPerformanceModel.checkList = tempCheckListModel
		const tempTaskModel: Task = new Task(this.selectedTaskModel.id, this.selectedTaskModel.text)
		tempTaskModel.performanceList = tempPerformanceModel
		const tempCCModel: CoreCompetency = new CoreCompetency(this.selectedCCModel.id, this.selectedCCModel.text)
		tempCCModel.taskList = tempTaskModel
		tempJobProfile.coreCompetencyList = tempCCModel
		if (newRemList.length != 0) {
			this.jobProfileService.addCheckListRemediationToJobProfile(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();//hide table
					if (this.showingDetatilPanel != 'checkListRemediation') {
						this.showCollapsePanel(this.selectedPerformanceModel.id, 'checkListRemediation');
					} else {
						//do nothing
					}
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				)
		} else {
			this.alertService.clear();
			this.alertService.warn(Constants.WARN_SELECT_REMEDIATION);
		}

	}

	//table cancle button click event
	public cancel() {
		this.showKnowledges = false;
		this.addTaskGroup = false;
		if (this.showTableData) {
			this.showTableData = false;
			this.showHierarchy = true
		} else {
		}

		if (this.showAddTaskGroup) {
			this.showAddTaskGroup = false
			this.showHierarchy = true
		} else {
		}
	}
	// </editor-fold> ADD_ITEM_TO_JOBPROFILE

	//<editor-fold> REMOVE_ITEM
	//remove item from selected list and make server calls
	// remove core competency
	private removeCoreCompetency(itemId: any, itemText: any, k: any) {
		// console.log('[removeCoreCompetency] (this.jobProfileModel) ' + JSON.stringify(this.jobProfileModel));
		//job profile is enable
		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
		let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, itemId);
		tempJobProfile.coreCompetencyList = jpccModelItem;

		if (jpccModelItem.taskList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This core competency has tasks assigned to it.')
		} else {
			this.jobProfileService.deleteCCOfJPByCCIdAndJPId(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.jobProfileModel.coreCompetencyList = this.removeItemFromArray(this.jobProfileModel.coreCompetencyList, itemId)
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		}
	}
	private removeTaskGroup(ccItem: CoreCompetency, taskGroup: Task) {
		// console.log('[removeTaskGroup] (taskGroup) ' + JSON.stringify(taskGroup));

		if (taskGroup.taskList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This task group has tasks assigned to it.')
		} else {
			let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
			let tempCCModel: CoreCompetency = new CoreCompetency(ccItem.id, '')
			tempCCModel.taskList = taskGroup
			tempJobProfile.coreCompetencyList = tempCCModel

			this.jobProfileService.deleteTaskGroupOfJP(tempJobProfile).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);

					let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, ccItem.id);
					jpccModelItem.taskList = this.removeItemFromArray(jpccModelItem.taskList, taskGroup.id);
					let taskTotal = jpccModelItem.taskList.length
					jpccModelItem.taskTotal = taskTotal
					jpccModelItem.taskList.forEach(element => {
						if (element.orderNum > taskGroup.orderNum) {
							element.orderNum = element.orderNum - 1
						}
					});
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			)
		}
	}

	//remove task
	private removeTask(ccItem: CoreCompetency, taskItem: Task) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
		const taskList = [{ id: taskItem.id, text: taskItem.text, orderNum: taskItem.orderNum }];

		let jpccModelItem: CoreCompetency = new CoreCompetency(ccItem.id, '', true, taskList);
		tempJobProfile.coreCompetencyList = jpccModelItem

		if (taskItem.performanceList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This task has performances assigned to it.')
		} else if (taskItem.regulatoryList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This task has regulatory agency assigned to it.')
		} else if (taskItem.knowledgeList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This task has knowledges assigned to it.')
		} else if (taskItem.remediationList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This task has remediations assigned to it.')
		} else {
			if (this.jobProfileModel.jobProfileStatus && ccItem.taskList.length == 1) {
				this.alertService.clear();
				this.alertService.error('Cannot delete. This core competency must contain at least one task.')
			} else {
				this.jobProfileService.deleteTaskOfJPByTIDAndJPIDAndCCID(tempJobProfile).subscribe(
					data => {
						this.alertService.clear();
						this.alertService.success(data.message);

						if (taskItem.taskGroupId != null && taskItem.taskGroupId != 'null') {
							let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, ccItem.id);
							let taskGroup: Task = this.getItemByIdFromArray(jpccModelItem.taskList, taskItem.taskGroupId)
							if (taskGroup != undefined) {
								taskGroup.taskList = this.removeItemFromArray(taskGroup.taskList, taskItem.id)
								taskGroup.taskList.forEach(element => {
									if (element.orderNum > taskItem.orderNum) {
										element.orderNum = element.orderNum - 1
									}
								});
							}
						} else {
							let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, ccItem.id);
							jpccModelItem.taskList = this.removeItemFromArray(jpccModelItem.taskList, taskItem.id);
							let taskTotal = jpccModelItem.taskList.length
							jpccModelItem.taskTotal = taskTotal
							jpccModelItem.taskList.forEach(element => {
								if (element.orderNum > taskItem.orderNum) {
									element.orderNum = element.orderNum - 1
								}
							});
						}

					},
					error => {
						let err = error.json();
						this.alertService.clear();
						this.alertService.handleError(err);
					}
				);
			}

		}
	}

	//remove regulatory agency
	public removeRegulatoryAgency(ccItem: CoreCompetency, taskItem: Task, regulatoryItem: RegulatoryAgency) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jptaskModelItem = new Task(taskItem.id, taskItem.text, true, '');
		jptaskModelItem.regulatoryList = regulatoryItem;
		let jpccModelItem: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text, true, jptaskModelItem);

		tempJobProfile.coreCompetencyList = jpccModelItem;

		this.jobProfileService.deleteRegulatoryAgencyOfJP(tempJobProfile).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				taskItem.regulatoryList = this.removeItemFromArray(taskItem.regulatoryList, regulatoryItem.id)
				let jptaskModelItem = new Task(taskItem.id, taskItem.text, true, '');
				jptaskModelItem.regulatoryList = taskItem.regulatoryList;
				this.loadAllRegulatoryAgency(jptaskModelItem)

			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		);

	}

	//remove performance
	private removePerformance(ccItem: CoreCompetency, taskItem: Task, performanceItem: Performance) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jptaskModelItem = new Task(taskItem.id, taskItem.text, true, performanceItem);
		let jpccModelItem: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text, true, jptaskModelItem);

		tempJobProfile.coreCompetencyList = jpccModelItem;

		if (performanceItem.checkList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This performance has checkList assigned to it.')
		} else if (performanceItem.remediationList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This performance has remediations assigned to it.')
		} else {
			if (this.jobProfileModel.jobProfileStatus && taskItem.performanceList.length == 1) {
				this.alertService.clear();
				this.alertService.error('Cannot delete. This task must contain at least one performance.')
			} else {
				this.jobProfileService.deletePerformanceOfJP(tempJobProfile).subscribe(
					data => {
						this.alertService.clear();
						this.alertService.success(data.message);
						taskItem.performanceList = this.removeItemFromArray(taskItem.performanceList, performanceItem.id)
						taskItem.performanceList.forEach(element => {
							if (element.orderNum > performanceItem.orderNum) {
								performanceItem.orderNum = performanceItem.orderNum - 1
							}
						});
					},
					error => {
						let err = error.json();
						this.alertService.clear();
						this.alertService.handleError(err);
					}
				);
			}
		}
	}

	//remove knowledge of job Profile
	private removeKnowledge(ccItem: CoreCompetency, taskItem: Task, knowledgeItem: Knowledge) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jptaskModelItem = new Task(taskItem.id, taskItem.text);
		jptaskModelItem.knowledgeList = knowledgeItem;
		let jpccModelItem: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text, true, jptaskModelItem);

		tempJobProfile.coreCompetencyList = jpccModelItem;

		if (knowledgeItem.remediationList.length != 0) {
			this.alertService.clear();
			this.alertService.error('Cannot delete. This knowledge has remediations assigned to it.')
		} else {
			if (this.jobProfileModel.jobProfileStatus) {
				if ((taskItem.knowledgeList.length + taskItem.knowledgeQuestionList.length) > 1) {
					this.jobProfileService.deleteKnowledgeOfJP(tempJobProfile).subscribe(
						data => {
							this.alertService.clear()
							this.alertService.success(data.message)
							if (knowledgeItem.isTestQuestion == 0) {
								taskItem.knowledgeList = this.removeItemFromArray(taskItem.knowledgeList, knowledgeItem.id)
							} else {
								taskItem.knowledgeQuestionList = this.removeItemFromArray(taskItem.knowledgeQuestionList, knowledgeItem.id)
							}
							taskItem.knowledgeList.forEach(element => {
								if (element.orderNum > knowledgeItem.orderNum) {
									knowledgeItem.orderNum = knowledgeItem.orderNum - 1
								}
							});
						},
						error => {
							let err = error.json();
							this.alertService.clear();
							this.alertService.handleError(err);
						}
					)
				} else {
					this.alertService.clear();
					this.alertService.error('Cannot delete. A task must contain at least one performance and one knowledge/knowledge question')
				}
			} else {
				this.jobProfileService.deleteKnowledgeOfJP(tempJobProfile).subscribe(
					data => {
						this.alertService.clear()
						this.alertService.success(data.message)
						if (knowledgeItem.isTestQuestion == 0) {
							taskItem.knowledgeList = this.removeItemFromArray(taskItem.knowledgeList, knowledgeItem.id)
						} else {
							taskItem.knowledgeQuestionList = this.removeItemFromArray(taskItem.knowledgeQuestionList, knowledgeItem.id)
						}
						taskItem.knowledgeList.forEach(element => {
							if (element.orderNum > knowledgeItem.orderNum) {
								knowledgeItem.orderNum = knowledgeItem.orderNum - 1
							}
						});
					},
					error => {
						let err = error.json();
						this.alertService.clear();
						this.alertService.handleError(err);
					}
				)
			}
		}
	}

	public checkValidityToDeleteKanowledge(taskItem: Task, knowledgeItem: Knowledge): boolean {
		let ntqCount: number = 0
		let qCount: number = taskItem.knowledgeList.length
		let returnVal: boolean = true

		taskItem.knowledgeList.forEach(kno => {
			if (!kno.isTestQuestion) {
				ntqCount++
			}
		});

		if (knowledgeItem.isTestQuestion) {
			//is test question
			returnVal = true
		} else {
			//none test question
			if (ntqCount > 1) {
				returnVal = true
			} else {
				returnVal = false
			}
		}

		return returnVal
	}

	//remove remediation of task
	private removeTaskRemediation(ccItem: CoreCompetency, taskItem: Task, remediationItem: any) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

		let jptaskModelItem = new Task(taskItem.id, taskItem.text);
		jptaskModelItem.remediationList = remediationItem;
		let jpccModelItem: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text, true, jptaskModelItem);

		tempJobProfile.coreCompetencyList = jpccModelItem;

		this.jobProfileService.deleteTaskRemediationOfJP(tempJobProfile).subscribe(
			data => {
				this.alertService.clear()
				this.alertService.success(data.message)
				taskItem.remediationList = this.removeItemFromArray(taskItem.remediationList, remediationItem.id)
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	//remove knowledge remediation
	private removeKnowledgeRemediation(ccItem: CoreCompetency, taskItem: Task, knowledgeItem: Knowledge, remediationItem: any) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let knowledgeModel: Knowledge = new Knowledge(knowledgeItem.knowledge, knowledgeItem.isTestQuestion)
		knowledgeModel.id = knowledgeItem.id
		knowledgeModel.remediationList = remediationItem;
		let taskModel: Task = new Task(taskItem.id, taskItem.text)
		taskModel.knowledgeList = knowledgeModel
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = taskModel

		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.deleteKnowledgeRemediationOfJP(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear()
				this.alertService.success(data.message)
				knowledgeItem.remediationList = this.removeItemFromArray(knowledgeItem.remediationList, remediationItem.id)
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			)
	}

	private removeCheckList(ccItem: CoreCompetency, taskItem: Task, performanceItem: Performance, checkListItem: CheckList) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let performanceModel: Performance = new Performance(performanceItem.id, performanceItem.text, true)
		performanceModel.checkList = checkListItem
		let taskModel: Task = new Task(taskItem.id, taskItem.text)
		taskModel.performanceList = performanceModel
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = taskModel;

		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.deleteCheckListOfJobProfile(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear()
				this.alertService.success(data.message)
				performanceItem.checkList = this.removeItemFromArray(performanceItem.checkList, checkListItem.id)
				performanceItem.checkList.forEach(element => {
					if (element.orderNum > checkListItem.orderNum) {
						checkListItem.orderNum = checkListItem.orderNum - 1
					}
				});
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			)
	}

	private removePerformanceRemediation(ccItem: CoreCompetency, taskItem: Task, performanceItem: Performance, remediationItem: any) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let performanceModel: Performance = new Performance(performanceItem.id, performanceItem.text, true)
		performanceModel.remediationList = remediationItem
		let taskModel: Task = new Task(taskItem.id, taskItem.text)
		taskModel.performanceList = performanceModel
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = taskModel;

		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.deletePerformanceRemediationOfJP(tempJobProfile).subscribe(
			data => {
				this.alertService.clear()
				this.alertService.success(data.message)
				performanceItem.remediationList = this.removeItemFromArray(performanceItem.remediationList, remediationItem.id)
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	public removeCheckListRemediation(ccItem: CoreCompetency, taskItem: Task, performanceItem: Performance, checkListItem: CheckList, remediationItem: any) {
		//job profile is enable
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let checkListModel: CheckList = new CheckList(checkListItem.id, checkListItem.text)
		checkListModel.remediationList = remediationItem
		let performanceModel: Performance = new Performance(performanceItem.id, performanceItem.text, true)
		performanceModel.checkList = checkListModel
		let taskModel: Task = new Task(taskItem.id, taskItem.text)
		taskModel.performanceList = performanceModel
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = taskModel;

		tempJobProfile.coreCompetencyList = ccModel;

		this.jobProfileService.deleteCheckListRemediationOfJP(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear()
				this.alertService.success(data.message)
				checkListItem.remediationList = this.removeItemFromArray(checkListItem.remediationList, remediationItem.id)
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			)
	}
	// </editor-fold> REMOVE_ITEM

	//<editor-fold> MOVE UP/DOWN CHECKLIST
	//move up task
	public moveUpTask(ccItem: CoreCompetency, taskItem: Task) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		// console.log('[moveUpTask] (taskItem) ' + JSON.stringify(taskItem));
		if (taskItem.orderNum - 1 < 0) {
			this.alertService.clear();
			this.alertService.error("Order Number is minus. Please contact your administrator.")
		} else {
			let tempTaskItem
			if (taskItem.taskGroupId) {
				let taskGroup: Task = this.getItemByIdFromArray(ccItem.taskList, taskItem.taskGroupId)
				// console.log('[moveUpTask] (taskGroup) ' + JSON.stringify(taskGroup));
				tempTaskItem = taskGroup.taskList.find(item => item.orderNum === (taskItem.orderNum - 1))
				taskItem.orderNum = taskItem.orderNum - 1
				tempTaskItem.orderNum = tempTaskItem.orderNum + 1
			} else {
				tempTaskItem = ccItem.taskList.find(item => item.orderNum === (taskItem.orderNum - 1))
				taskItem.orderNum = taskItem.orderNum - 1
				tempTaskItem.orderNum = tempTaskItem.orderNum + 1
			}

			let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
			ccModel.taskList = [tempTaskItem, taskItem]
			tempJobProfile.coreCompetencyList = ccModel


			this.jobProfileService.updateTaskOrder(tempJobProfile)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);

					taskItem.orderNum = taskItem.orderNum + 1
					tempTaskItem.orderNum = tempTaskItem.orderNum - 1
				}
				)
		}
	}

	//move down task
	public moveDownTask(ccItem: CoreCompetency, taskItem: Task) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempTaskItem
		if (taskItem.taskGroupId != null) {
			let taskGroup: Task = this.getItemByIdFromArray(ccItem.taskList, taskItem.taskGroupId)
			tempTaskItem = taskGroup.taskList.find(item => item.orderNum === (taskItem.orderNum + 1))
		} else {
			tempTaskItem = ccItem.taskList.find(item => item.orderNum === (taskItem.orderNum + 1))
		}

		if (tempTaskItem) {
			if (tempTaskItem.orderNum - 1 < 0) {
				this.alertService.clear();
				this.alertService.error("Order Number is minus. Please contact your administrator.")
			} else {
				if (taskItem.taskGroupId != null) {
					taskItem.orderNum = taskItem.orderNum + 1
					tempTaskItem.orderNum = tempTaskItem.orderNum - 1
				} else {
					taskItem.orderNum = taskItem.orderNum + 1
					tempTaskItem.orderNum = tempTaskItem.orderNum - 1
				}

				let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
				ccModel.taskList = [tempTaskItem, taskItem]
				tempJobProfile.coreCompetencyList = ccModel

				this.jobProfileService.updateTaskOrder(tempJobProfile).subscribe(
					data => {
						this.alertService.clear();
						this.alertService.success(data.message);
					},
					error => {
						let err = error.json();
						this.alertService.clear();
						this.alertService.handleError(err);

						taskItem.orderNum = taskItem.orderNum - 1
						tempTaskItem.orderNum = tempTaskItem.orderNum + 1
					}
				)
			}
		} else {
			this.alertService.clear();
			this.alertService.error("Incorrect order numbers. Please contact your administrator.")
		}
	}

	// move up performance
	public moveUpPerformance(ccItem: any, taskItem: any, perItem: any) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempPerItem = taskItem.performanceList.find(item => item.orderNum === (perItem.orderNum - 1))
		perItem.orderNum = perItem.orderNum - 1
		tempPerItem.orderNum = tempPerItem.orderNum + 1

		let tempTaskModel: Task = new Task(taskItem.id, taskItem.text)
		tempTaskModel.performanceList = taskItem.performanceList
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = tempTaskModel;
		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.updatePerformanceOrder(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);

				perItem.orderNum = perItem.orderNum + 1
				tempPerItem.orderNum = tempPerItem.orderNum - 1
			}
			)

	}

	//move down performance
	public moveDownPerformance(ccItem: any, taskItem: any, perItem: any) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempPerItem = taskItem.performanceList.find(item => item.orderNum === (perItem.orderNum + 1))
		perItem.orderNum = perItem.orderNum + 1
		tempPerItem.orderNum = tempPerItem.orderNum - 1

		let tempTaskModel: Task = new Task(taskItem.id, taskItem.text)
		tempTaskModel.performanceList = taskItem.performanceList
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = tempTaskModel;
		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.updatePerformanceOrder(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);

				perItem.orderNum = perItem.orderNum - 1
				tempPerItem.orderNum = tempPerItem.orderNum + 1
			}
			)
	}

	//move up knowledge
	public moveUpKnowledge(ccItem: any, taskItem: any, knoItem: any) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempKnoItem = null;
		if (knoItem.isTestQuestion == 0) {
			let index = taskItem.knowledgeList.findIndex(item => item.orderNum === knoItem.orderNum);
			if (index > 0) {
				tempKnoItem = taskItem.knowledgeList[index - 1]
			}
		} else {
			let index = taskItem.knowledgeQuestionList.findIndex(item => item.orderNum === knoItem.orderNum);
			if (index > 0) {
				tempKnoItem = taskItem.knowledgeQuestionList[index - 1]
			}
		}
		let tempOrderNo = knoItem.orderNum
		knoItem.orderNum = tempKnoItem.orderNum
		tempKnoItem.orderNum = tempOrderNo

		let tempTaskModel: Task = new Task(taskItem.id, taskItem.text)
		if (knoItem.isTestQuestion == 0) {
			tempTaskModel.knowledgeList = taskItem.knowledgeList
		} else {
			tempTaskModel.knowledgeList = taskItem.knowledgeQuestionList
		}
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = tempTaskModel;
		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.updateKnowledgeOrder(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);

				knoItem.orderNum = knoItem.orderNum + 1
				tempKnoItem.orderNum = tempKnoItem.orderNum - 1
			}
			)
	}

	//move down knowledge
	public moveDownKnowledge(ccItem: any, taskItem: any, knoItem: any) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempKnoItem = null;
		if (knoItem.isTestQuestion == 0) {
			let index = taskItem.knowledgeList.findIndex(item => item.orderNum === knoItem.orderNum);
			if (index >= 0) {
				tempKnoItem = taskItem.knowledgeList[index + 1]
			}
		} else {
			let index = taskItem.knowledgeQuestionList.findIndex(item => item.orderNum === knoItem.orderNum);
			if (index >= 0) {
				tempKnoItem = taskItem.knowledgeQuestionList[index + 1]
			}
		}
		let tempOrderNo = knoItem.orderNum
		knoItem.orderNum = tempKnoItem.orderNum
		tempKnoItem.orderNum = tempOrderNo

		let tempTaskModel: Task = new Task(taskItem.id, taskItem.text)
		if (knoItem.isTestQuestion == 0) {
			tempTaskModel.knowledgeList = taskItem.knowledgeList
		} else {
			tempTaskModel.knowledgeList = taskItem.knowledgeQuestionList
		}
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = tempTaskModel;
		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.updateKnowledgeOrder(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);

				knoItem.orderNum = knoItem.orderNum - 1
				tempKnoItem.orderNum = tempKnoItem.orderNum + 1
			}
			)
	}

	public moveUPCL(ccItem: any, taskItem: any, perItem: any, checkListItem: any) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempCheckListItem = perItem.checkList.find(item => item.orderNum === (checkListItem.orderNum - 1))
		checkListItem.orderNum = checkListItem.orderNum - 1;
		tempCheckListItem.orderNum = tempCheckListItem.orderNum + 1;

		// console.log('[moveUP] (NEW perItem.checkList) :' + JSON.stringify(perItem.checkList).toString());

		let tempPerModel: Performance = new Performance(perItem.id, perItem.text, true)
		tempPerModel.checkList = perItem.checkList
		let tempTaskModel: Task = new Task(taskItem.id, taskItem.text)
		tempTaskModel.performanceList = tempPerModel
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = tempTaskModel;
		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.updateCheckListOrder(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);

				checkListItem.orderNum = checkListItem.orderNum + 1;
				tempCheckListItem.orderNum = tempCheckListItem.orderNum - 1;
			}
			);
	}

	public moveDOWNCL(ccItem: any, taskItem: any, perItem: any, checkListItem: any) {
		let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId)

		let tempCheckListItem = perItem.checkList.find(item => item.orderNum === (checkListItem.orderNum + 1))
		checkListItem.orderNum = checkListItem.orderNum + 1;
		tempCheckListItem.orderNum = tempCheckListItem.orderNum - 1;

		// console.log('[moveDOWN] (NEW perItem.checkList) :' + JSON.stringify(perItem.checkList).toString());

		let tempPerModel: Performance = new Performance(perItem.id, perItem.text, true)
		tempPerModel.checkList = perItem.checkList
		let tempTaskModel: Task = new Task(taskItem.id, taskItem.text)
		tempTaskModel.performanceList = tempPerModel
		let ccModel: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		ccModel.taskList = tempTaskModel;
		tempJobProfile.coreCompetencyList = ccModel

		this.jobProfileService.updateCheckListOrder(tempJobProfile)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);

				checkListItem.orderNum = checkListItem.orderNum - 1;
				tempCheckListItem.orderNum = tempCheckListItem.orderNum + 1;
			}
			);
	}
	// </editor-fold> MOVE UP/DOWN CHECKLIST

	//<editor-fold> NG2TABLE_INIT_METHODS
	//table related code
	public changePage(page: any, data: Array<any> = this.data): Array<any> {
		// console.log('[changePage] ' + page.page + '/' + page.itemsPerPage);
		let start = (page.page - 1) * page.itemsPerPage;
		let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
		return data.slice(start, end);
	}
	//sort data
	public changeSort(data: any, config: any): any {
		if (!config.sorting) {
			return data;
		}

		let columns = this.config.sorting.columns || [];
		let columnName: string = void 0;
		let sort: string = void 0;

		for (let i = 0; i < columns.length; i++) {
			if (columns[i].sort !== '' && columns[i].sort !== false) {
				columnName = columns[i].name;
				sort = columns[i].sort;
			}
		}

		if (!columnName) {
			return data;
		}

		// simple sorting
		return data.sort((previous: any, current: any) => {
			if (previous[columnName] > current[columnName]) {
				return sort === 'desc' ? -1 : 1;
			} else if (previous[columnName] < current[columnName]) {
				return sort === 'asc' ? -1 : 1;
			}
			return 0;
		});
	}
	//filter data
	public changeFilter(data: any, config: any): any {
		let filteredData: Array<any> = data;
		this.columns.forEach((column: any) => {
			if (column.filtering) {
				filteredData = filteredData.filter((item: any) => {
					return item[column.name].match(new RegExp(column.filtering.filterString, "i"));
				});
			}
		});

		if (!config.filtering) {
			return filteredData;
		}

		if (config.filtering.columnName) {
			return filteredData.filter((item: any) =>
				item[config.filtering.columnName].match(this.config.filtering.filterString));
		}

		let tempArray: Array<any> = [];
		filteredData.forEach((item: any) => {
			let flag = false;
			this.columns.forEach((column: any) => {
				if (column.filtering) {
					if (item[column.name].toString().match(this.config.filtering.filterString)) {
						flag = true;
					}
				} else {
					flag = true;
				}
			});
			if (flag) {
				tempArray.push(item);
			}
		});
		filteredData = tempArray;

		return filteredData;
	}
	//onchange table method use to reload data to the table
	public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
		// console.log('[onChangeTable] ');
		if (config.filtering) {
			Object.assign(this.config.filtering, config.filtering);
		}

		if (config.sorting) {
			Object.assign(this.config.sorting, config.sorting);
		}

		let filteredData = this.changeFilter(this.data, this.config);
		let sortedData = this.changeSort(filteredData, this.config);
		this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
		this.length = sortedData.length;
	}
	//load other data to the table
	private extendData(isCellClick: boolean) {

		for (let i = 0; i < this.data.length; i++) {
			if (this.data[i].selected == "1") {
				this.data[i].selectbutton = '<input type="checkbox" checked>';
			} else {
				this.data[i].selectbutton = '<input type="checkbox" >';
			}
			if (isCellClick) {

			} else {
				if (this.data[i].isTestQuestion == "1") {
					this.data[i].isTestQuestion = '<i class="fa fa-check-square" aria-hidden="true"></i></a>';
				} else {
					this.data[i].isTestQuestion = '<i class="fa fa-square-o" aria-hidden="true"></i>';
				}
			}
		}
	}
	public onCellClick(data: any): any {
		// console.log('[onCellClick] (selectedRow) : ' + JSON.stringify(data.row.id));
		switch (data.column) {
			case 'selectbutton':
				for (var i = 0; i < this.data.length; i++) {
					if (this.data[i].id == data.row.id) {
						if (this.data[i].selected == "1") {
							this.data[i].selected = "0";
						} else {
							this.data[i].selected = "1";
						}
					}
				}
				this.onChangeTable(this.config);
				this.extendData(true);
				break;
			default:
				break;
		}
	}
	//get selected valueus of the table
	public getTableSelectedValues(): any {
		var selectedIdList = [];
		for (let i = 0; i < this.data.length; i++) {
			if (this.data[i].selected == '1') {
				if (this.selectedItem == 'knowledge') {
					selectedIdList.push({
						id: this.data[i].id,
						text: this.data[i].description,
						testQuestion: this.data[i].isTestQuestion
					})
				} else {
					selectedIdList.push({
						id: this.data[i].id,
						text: this.data[i].description
					})
				}

			}
		}

		// if(this.selectedItem == 'skill') {
		//     for(let i = 0; i < this.data.length; i++) {
		//         if(this.data[i].selected == '1') {
		//             selectedIdList.push({
		//                 id : this.data[i].id,
		//                 text : this.data[i].description,
		//                 checklist : this.data[i].checklist,
		//                 knowledge : this.data[i].knowledge,
		//                 remediation : this.data[i].remediation,
		//             })
		//         }
		//     }
		// } else {
		//     for(let i = 0; i < this.data.length; i++) {
		//         if(this.data[i].selected == '1') {
		//             selectedIdList.push({
		//                 id : this.data[i].id,
		//                 text : this.data[i].description
		//             })
		//         }
		//     }
		// }
		return selectedIdList;
	}
	//</editor-fold> NG2TABLE_INIT_METHODS

	//<editor-fold> CRITICALITY VALUE
	/**
	 * [changeCriticallityValue description]
	 * @param {CoreCompetency} ccItem [description]
	 * @param {Task}           task   [description]
	 */
	private changeCriticalityValue(newCriVal: number, ccItem: CoreCompetency, task: Task): void {
		// console.log('[changeCriticallityValue] (jobProfileModel) ' + JSON.stringify(this.jobProfileModel))
		// console.log('[changeCriticallityValue] (ccItem) ' + JSON.stringify(ccItem))
		// console.log('[changeCriticallityValue] (task) ' + JSON.stringify(task))

		let tempTask = new Task(task.id, task.text)
		tempTask.jpccId = task.jpccId
		tempTask.criticalityValue = newCriVal;

		// console.log('[changeCriticallityValue] (tempTask) ' + JSON.stringify(tempTask))

		let tempJp: JobProfile = new JobProfile(this.jobProfileModel.jobProfileId)
		let tempCCItem: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text)
		tempCCItem.taskList = tempTask
		tempJp.coreCompetencyList = tempCCItem

		if (newCriVal != task.criticalityValue) {
			this.jobProfileService.changeTaskCriticalityValue(tempJp).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);

					task.criticalityValue = newCriVal
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			)
		} else {
			//do nothing
		}
	}
	//</editor-fold> CRITICALITY VALUE

	/**
	 * sort core competency item content (task + task group)
	 * by alpabatic - ascending
	 * @param ccItem 
	 */
	public sortCCContentBtnClick(ccItem: CoreCompetency) {
		// console.log('[sortCCContentBtnClick] (ccItem) ' + JSON.stringify(ccItem.taskList.length))
		if (ccItem.taskList.length != 0 && ccItem.taskList.length != 1) {
			let temp = this.orderPipe.transform(this.getCCLevelTaskName(ccItem), 'alphabeticTaskname')
			let changeTaskList = this.changeOrderNum(temp)
			let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);
			let tempCCModel: CoreCompetency = new CoreCompetency(ccItem.id, '')
			tempCCModel.taskList = changeTaskList
			tempJobProfile.coreCompetencyList = tempCCModel

			this.jobProfileService.orderByTaskNameAlphabeticallyOfJP(tempJobProfile).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, ccItem.id);
					jpccModelItem.taskList = changeTaskList;
					let taskTotal = jpccModelItem.taskList.length
					jpccModelItem.taskTotal = taskTotal

				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			)
		} else {
			//nothing do
		}

	}

	/**
	 * sort task group item content (tasks)
	 * by alpabatic - ascending
	 * @param ccItem 
	 * @param taskGroup
	 */
	public sortTGContentBtnClick(ccItem: CoreCompetency, taskGroup: Task) {
		// console.log('[sortTGContentBtnClick] (ccItem) ' + JSON.stringify(ccItem))
		// console.log('[sortTGContentBtnClick] (taskGrop) ' + JSON.stringify(taskGroup))

		if (taskGroup.taskList.length != 0 && taskGroup.taskList.length != 1) {
			let temp = this.orderPipe.transform(taskGroup.taskList, 'alphabeticTaskname')
			let changeTaskList = this.changeOrderNum(temp)
			let tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId);

			let jpccModelItem: CoreCompetency = new CoreCompetency(ccItem.id, '', true, changeTaskList);
			tempJobProfile.coreCompetencyList = jpccModelItem

			this.jobProfileService.orderByTaskNameAlphabeticallyOfJP(tempJobProfile).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					let jpccModelItem: CoreCompetency = this.getItemByIdFromArray(this.jobProfileModel.coreCompetencyList, ccItem.id);
					let taskG: Task = this.getItemByIdFromArray(jpccModelItem.taskList, taskGroup.taskGroupId)
					taskGroup.taskList = changeTaskList

				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
			)
		} else {
			// nothing to do
		}
	}

	private getCCLevelTaskName(ccItem: any): any {
		let temptaskList = []
		ccItem.taskList.forEach(task => {
			if (task.isTaskGroup == 1) {
				task.text = task.taskGroup
				temptaskList.push(task)
			} else {
				temptaskList.push(task)
			}
		});
		return temptaskList
	}
	private changeOrderNum(taskList: any): any {
		let temptaskList = []
		let i = 1
		taskList.forEach(task => {
			task.orderNum = i++
			temptaskList.push(task)
		});
		return temptaskList
	}
}
