import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RegulatoryAgencyService } from './regulatoryagency.service'
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { Task } from '../task/task.model';
import { CoreCompetency } from '../corecompetency/corecompetency.model'
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { RegulatoryAgency } from './regulatoryagency.model';

@Component({
	selector: 'app-editregulatoryagency',
	templateUrl: './editregulatoryagency.component.html'
})

export class EditRegulatoryAgencyComponent implements OnInit {

    editNewRegulatoryForm: FormGroup;
    public editRegulatoryAgency: RegulatoryAgency;

    constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private regulatoryAgencyService: RegulatoryAgencyService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
        }
        
        this.editRegulatoryAgency = regulatoryAgencyService.getSelectedRegulatoryAgencyForEdit()
	}


    ngOnInit(){
        this.editNewRegulatoryForm = new FormGroup({
			description: new FormControl(this.editRegulatoryAgency.description, Validators.required),
			shortName: new FormControl(this.editRegulatoryAgency.shortName, Validators.required)
		});
    }

    selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
    }
    
    checkNeedtoUpdateregulatoryAgency(): boolean {
		if (this.editRegulatoryAgency.description != this.editNewRegulatoryForm.value.description) {
			return true;
        } else if(this.editRegulatoryAgency.shortName != this.editNewRegulatoryForm.value.shortName){
            return true;
        } else {
			return false;
		}
    }
    
    onSubmit() {
		if (this.checkNeedtoUpdateregulatoryAgency()) {
			let tempRegulatoryAgency = new RegulatoryAgency(this.editRegulatoryAgency.id, this.editNewRegulatoryForm.value.description, this.editNewRegulatoryForm.value.shortName)
			this.regulatoryAgencyService.updateRegulatoryAgencyById(tempRegulatoryAgency)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
                    this.editRegulatoryAgency.description = this.editNewRegulatoryForm.value.description;
                    this.editRegulatoryAgency.shortName = this.editNewRegulatoryForm.value.shortName					
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		} else {
			//no need to update note
			this.alertService.clear();
			this.alertService.error('There are no changes to update.');
		}
	}
}