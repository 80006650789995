<!--component html goes here -->
<div class="col-lg-12">
  <div class="col" style="overflow:hidden">
    <div class="float-left">
      <h4>Filter By</h4>
    </div>
    <div class="float-right"></div>
  </div>
  <hr />
</div>
<div class="col-lg-12">
  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label ">Org Code</label>
      <div class="col-sm-4">
        <!--<select2 [data]="orgList" [options]="options" [value]="selectedOrgId" (valueChanged)="changedOrgId($event)" name="orgId"
				 id="orgId" ngDefaultControl></select2>-->
        <ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" formControlName="orgId">
        </ng-select>
      </div>
      <div class="col-sm-3">
        <label class="col-form-label">
					<input type="checkbox" id="includeChilderen" formControlName="includeChildren" name="includeChildren">&nbsp;Include Children
				</label>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label ">Job Profile</label>
      <div class="col-sm-4">
        <!--<select2 [data]="jobProfileList" [options]="jobProfileOptions" [value]="selectedJobProfileId" (valueChanged)="changedJobProfileId($event)"
				 name="jobProfileId" id="jobProfileId" ngDefaultControl></select2>-->
        <ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select job profile" formControlName="jobProfileId">
        </ng-select>
      </div>
      <label class="col-sm-2 col-form-label ">Discipline</label>
      <div class="col-sm-4">
        <!--<select2 [data]="disciplineList" [options]="disciplineOptions" [value]="selectedDisciplineId" (valueChanged)="changedDisciplineId($event)"
				 name="disciplineId" id="disciplineId" ngDefaultControl></select2>-->
        <ng-select [allowClear]="true" [items]="disciplineList" formControlName="disciplineId" placeholder="Select discipline">
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label ">Next Assessment</label>

      <div class="col-sm-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Months</span>
          </div>
          <select class="form-control" formControlName="months" name="months" id="months" min-width>
					<option selected value="">&nbsp;</option>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
					<option value="9">9</option>
					<option value="10">10</option>
					<option value="11">11</option>
			</select>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Years</span>
          </div>
          <select class="form-control" formControlName="years" name="years" id="years" min-width>	
				 	<option selected value="">&nbsp;</option>				
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>					
			</select>
        </div>
      </div>
      <div class="col-sm-2"><label class="col-form-label">
					<input (change)="showData($event)" type="checkbox" id="pastDue" formControlName="pastDue" name="pastDue">&nbsp;Past Due
				</label></div>
    </div>
    <!--buttons-->
    <div class="form-group row">
      <div class="col-sm-6"></div>
      <div class="col-sm-3">
        <button type="submit" [disabled]="!isQueryAssessment" class="btn btn-warning btn-block">Show</button>
      </div>
      <div class="col-sm-3">
        <button type="button" (click)="clearForm()" class="btn btn-default btn-block">Clear</button>
      </div>
    </div>
  </form>
  <br>
  <alert></alert>
  <br>
  <div *ngIf="isLoading" class="loading"></div>
  <div *ngIf="show">
    <div class="table-responsive">
      <table sortable-table (sorted)="onSorted($event)" class="table table-bordered data-sortable">
        <thead>
          <tr *ngIf="!isFiltering">
            <th></th>
            <th sortable-column="FullName" sortable-type="string">Full Name</th>
            <th>Contractor</th>
            <th sortable-column="OrgCode" sortable-type="string">Org Code</th>
            <th sortable-column="JobProfile" sortable-type="string">Job Profile</th>
            <th sortable-column="Discipline" sortable-type="string">Discipline</th>
            <th sortable-column="MonthsFromLastAssessment" sortable-type="number">Months from Last Assessment</th>
            <th sortable-column="LastAssessmentDate" sortable-type="number">Last Assessment Date</th>
          </tr>
          <tr *ngIf="isFiltering">
            <th></th>
            <th>Full Name</th>
            <th>Contractor</th>
            <th>Org Code</th>
            <th>Job Profile</th>
            <th>Discipline</th>
            <th>Months from Last Assessment</th>
            <th>Last Assessment Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th class="">
              <input class="form-control" type="text" name="searchFullName" [(ngModel)]="searchFullName" (keyup)="filter()">
            </th>
            <th class=""></th>
            <th class="">
              <input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
            </th>
            <th class="">
              <input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile" (keyup)="filter()">
            </th>
            <th class="">
              <input class="form-control" type="text" name="searchDiscipline" [(ngModel)]="searchDiscipline" (keyup)="filter()">
            </th>
            <th class=""></th>
            <th class=""></th>
          </tr>
          <tr *ngFor="let row of paginatedResults;let i = index">
            <td>
              <!-- <input type="checkbox" (change)="setSelected($event, i)"> -->
              <input type="checkbox" (change)="setSelected($event, i)" *ngIf="row.selected == 1" checked>
              <input type="checkbox" (change)="setSelected($event, i)" *ngIf="row.selected == 0 || row.selected == null">
            </td>
            <td>{{row.FullName}}</td>
            <td>
              <input type="checkbox" class="bigcheck" disabled="disabled" *ngIf="row.IsContract == 1" checked="checked" />
              <input class="bigcheck" type="checkbox" disabled="disabled" *ngIf="row.IsContract==0 || row.IsContract==null" />
              <span class="bigcheck-target"></span>
            </td>
            <td>{{row.OrgCode}}</td>
            <td>{{row.JobProfile}}</td>
            <td>{{row.Discipline}}</td>
            <td>{{row.MonthsFromLastAssessment}}</td>
            <ng-template [ngIf]="row.LastAssessmentDate == 'red' ">
              <td bgcolor="#dc3545" ng-if="row.LastAssessmentDate == 'red'">{{row.completedDate == null ? "" : row.completedDate.toLocaleDateString()}}</td>
            </ng-template>
            <ng-template [ngIf]="row.LastAssessmentDate == 'yellow' ">
              <td bgcolor="#ffc107" ng-if="row.LastAssessmentDate == 'yellow'">{{row.completedDate == null ? "" : row.completedDate.toLocaleDateString()}}</td>
            </ng-template>
            <ng-template [ngIf]="row.LastAssessmentDate == 'green' ">
              <td bgcolor="#28a745" ng-if="row.LastAssessmentDate == 'green'">{{row.completedDate == null ? "" : row.completedDate.toLocaleDateString()}}</td>
            </ng-template>
          </tr>
        </tbody>
      </table>
      <pagination class="pagination-sm" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
        [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
    </div>
    <div class="row">
      <div class="col-sm-9"></div>
      <div class="col-sm-3">
        <button type="button" class="btn btn-warning btn-block" (click)="scheduleAssessment()" [disabled]="selectedUsers.length == 0 || !isAddAssessment">
					Schedule
				</button>
      </div>
    </div>
    <br>
  </div>
</div>
