import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';

import { user } from '../user';
import { SharedService } from '../../home/shared.service';
import { UserService } from '../user.service';
import { AlertService } from '../../util/alert/alert.service';
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: 'app-allusers',
	templateUrl: './allusers.component.html',
	styleUrls: ['./allusers.component.scss']
})
export class AllUsersComponent extends TableComponent {
	@ViewChild('deleteconfirmationModal', { static: false }) deleteconfirmationModal: ModalComponent;
	@ViewChild('activeconfirmationModal', { static: false }) activeconfirmationModal: ModalComponent;
	@ViewChild('inactiveconfirmationModal', { static: false }) inactiveconfirmationModal: ModalComponent;

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	private isDeleteUser: boolean;
	private isModifyUser: boolean;
	isAddUser: boolean;
	private isQueryUser: boolean;

	public selectedUserId: string;
	public selectedUserstatus: number;
	public userId: any;
	checked: boolean = false;

	public columns: Array<any> = [
		{
			title: "First Name",
			name: "firstname",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Last Name",
			name: "lastname",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Email",
			name: "email",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Org Code",
			name: "orgcode",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Supervisor Name",
			name: "supervisorname",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Is Assessed",
			name: "isAssessed",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Job Profile",
			name: "jobProfieId",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Time Zone",
			name: "timeZone",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Contractor",
			name: "isContract"
		},
		{ title: "", name: "statusbutton", className: "accepter-col-action" },
		{ title: "", name: "editbutton", className: "accepter-col-action" },
		{ title: "", name: "deletebutton", className: "accepter-col-action" },
		{ title: "", name: "showhidebutton", className: "accepter-col-action" }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	//this array store all the data from the server
	private serverResultData: Array<any> = [];
	showHideForm: FormGroup;

	constructor(
		private sharedService: SharedService,
		private userService: UserService,
		private alertServices: AlertService,
		private customModalService: CustomModalService
	) {
		super();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddUser = this.userService.isCapabilityAssigned(
			"AddUser",
			this.selectedUserRole[0][1]
		);
		this.isModifyUser = this.userService.isCapabilityAssigned(
			"ModifyUser",
			this.selectedUserRole[0][1]
		);
		this.isDeleteUser = this.userService.isCapabilityAssigned(
			"DeleteUser",
			this.selectedUserRole[0][1]
		);
		this.isQueryUser = this.userService.isCapabilityAssigned(
			"QueryUser",
			this.selectedUserRole[0][1]
		);

		if (this.isQueryUser) {
			//load data
			this.loadAllShowUsers();
		} else {
			//do nothing
		}

		this.userId = localStorage.getItem('userId');
	}

	ngOnInit() {
		this.showHideForm = new FormGroup({
			showHide: new FormControl(null),
		})
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add 'implements OnChanges' to the class.
	}

	//on cell click event
	public onCellClick(data: any): any {
		var elementnumber = parseInt(data.row.id) - 1;
		this.selectedUserId = this.serverResultData[elementnumber].Id;
		this.selectedUserstatus = this.serverResultData[elementnumber].Status;
		// console.log('[onCellClick] (selectedUserId) : ' + this.selectedUserId);
		switch (data.column) {
			case "editbutton":
				//check user has modify/edit capability
				if (this.isModifyUser) {
					this.editButtonClickEvent(this.serverResultData[elementnumber]);
				} else {
					//do nothing
				}

				break;
			case "deletebutton":
				//check user has delete capability
				if (this.isDeleteUser) {
					this.deleteButtonClickEvent(this.selectedUserId);
				} else {
					//do nothing
				}

				break;
			case "statusbutton":
				//check user has delete capability
				if (this.serverResultData[elementnumber].Id == this.userId) {
					//do nothing
				} else {
					if (this.isModifyUser) {
						if (this.serverResultData[elementnumber].ShowHide == 0) {
							// do nothing
						} else {
							this.statusButtonClickEvent(
								this.selectedUserId,
								this.serverResultData[elementnumber]
							);
						}
					} else {
						//do nothing
					}
				}
				break;

			case "showhidebutton":
				//check capability 
				if (this.isModifyUser) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonCickEvent(this.selectedUserId, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	//load buttons to the table
	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			// user not be able to disable.
			if (this.serverResultData[i].Id == this.userId) {
				this.data[i].statusbutton =
					' <a class="btn btn-sm  disabled edit"><i class="fa fa-ban fa-lg" aria-hidden="true"></i></a>'
			} else {
				//check status	
				if (this.serverResultData[i].Status == 1) {
					//check user has modify capability
					if (this.isModifyUser) {
						this.data[i].statusbutton =
							'<a class="btn btn-sm edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].statusbutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				} else {
					if (this.isModifyUser) {
						if (this.serverResultData[i].ShowHide == 0) {
							this.data[i].statusbutton =
								'<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
						} else {
							this.data[i].statusbutton =
								'<a class="btn btn-sm edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
						}

					} else {
						this.data[i].statusbutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				}
			}

			//check user has modify capability
			if (this.isModifyUser) {
				this.data[i].editbutton =
					'<a class="btn btn-sm edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].editbutton =
					'<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			}
			//check user has delete capability
			if (this.isDeleteUser) {
				this.data[i].deletebutton =
					'<a class="btn btn-sm delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton =
					'<a class="btn btn-sm  disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyUser) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyUser) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//load all users from the
	private loadAllUsers() {
		this.userService.getAllUsers().subscribe(
			data => {
				this.data = [];
				// console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.userlist.length);

				for (let i = 0; i < data.userlist.length; i++) {
					var asses;
					if (data.userlist[i].IsAssessed == 1) {
						asses = "Yes";
					} else {
						asses = "No";
					}
					this.data.push({
						id: i + 1,
						userId: data.userlist[i].Id,
						firstname: data.userlist[i].FirstName,
						lastname: data.userlist[i].LastName,
						email: data.userlist[i].Email,
						orgcode:	data.userlist[i].OrgCode == null ? "" : data.userlist[i].OrgCode,
						supervisorname: data.userlist[i].SupervisorName == null ? "" : data.userlist[i].SupervisorName,
						isAssessed: asses,
						jobProfieId:
						data.userlist[i].JobProfile == null
							? ""
							: data.userlist[i].JobProfile,
						timeZone: data.userlist[i].TimeZone == null
							? ""
							: data.userlist[i].TimeZone,
						isContract: data.userlist[i].IsContract == 1 ?
							'<a class="btn p-0"><i class="fa fa-check-square-o" aria-hidden="true"></i></a>' :
							'<a class="btn p-0"><i class="fa fa-square-o" aria-hidden="true"></i>',
						// '<input type="checkbox" class="bigcheck" disabled="disabled" checked="checked" /><span class="bigcheck-target"></span>' :
						// '<input type="checkbox" class="bigcheck" disabled="disabled" /><span class="bigcheck-target"></span>',
						isContractValue: data.userlist[i].IsContract,
						showHide: data.userlist[i].ShowHide,
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.userlist;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//	console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	//edit button click event
	private editButtonClickEvent(selectedUser: any) {
		//console.log('[user] : ' + selectedUser.Id + '/' + selectedUser.Email);
		var asses;
		if (selectedUser.IsAssessed == 1) {
			asses = true;
		} else {
			asses = false;
		}
		const editUser = new user(selectedUser.Email);
		editUser.id = selectedUser.Id;
		editUser.firstName = selectedUser.FirstName;
		editUser.lastName = selectedUser.LastName;
		editUser.orgId = selectedUser.OrgId;
		editUser.supervisorId = selectedUser.SupervisorId;
		editUser.isAssesed = asses;
		editUser.jobProfieId = selectedUser.JobProfieId;
		editUser.timeZone = selectedUser.TimeZone;
		editUser.isContract = selectedUser.IsContract;
		this.userService.setSelectedUserForEdit(editUser);
		this.sharedService.change("EditUser");
	}

	//delete button click event
	private deleteButtonClickEvent(id: string) {
		let itemType = "User";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete",
			null
		);
	}

	//delete user
	private delete() {
		// console.log('[deleteUser] (userId) : ' + userId);
		this.userService.deleteUser(this.selectedUserId).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	// status button click event
	private statusButtonClickEvent(id: string, selectedUser: any) {
		let itemType = "User";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			selectedUser.Status
		);
	}

	//change user status
	private changeStatus(userId: string, status: number) {
		this.userService.changeUserstatus(userId, status).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	showHide(event) {
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryUser) {
				this.loadAllUsers();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowUsers();
		}
	}

	private showHideButtonCickEvent(id: string, showHide: number) {
		let itemType = "User";
		let status = showHide;
		this.customModalService.invoke(itemType, id, this, "showHide", status);
	}

	//load all show users details
	private loadAllShowUsers() {
		this.userService.getAllShowUsers().subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.userlist.length; i++) {
					var asses;
					if (data.userlist[i].IsAssessed == 1) {
						asses = "Yes";
					} else {
						asses = "No";
					}
					this.data.push({
						id: i + 1,
						userId: data.userlist[i].Id,
						firstname: data.userlist[i].FirstName,
						lastname: data.userlist[i].LastName,
						email: data.userlist[i].Email,
						orgcode:
						data.userlist[i].OrgCode == null ? "" : data.userlist[i].OrgCode,
						supervisorname:
						data.userlist[i].SupervisorName == null ? "" : data.userlist[i].SupervisorName ,
						isAssessed: asses,
						jobProfieId:
						data.userlist[i].JobProfile == null
							? ""
							: data.userlist[i].JobProfile,
						timeZone: data.userlist[i].TimeZone == null
							? ""
							: data.userlist[i].TimeZone,
						isContract: data.userlist[i].IsContract == 1 ?
							'<a class="btn p-0"><i class="fa fa-check-square-o" aria-hidden="true"></i></a>' :
							'<a class="btn p-0"><i class="fa fa-square-o" aria-hidden="true"></i>',
						// '<input type="checkbox" class="bigcheck" disabled="disabled" checked="checked" /><span class="bigcheck-target"></span>' :
						// '<input type="checkbox" class="bigcheck" disabled="disabled" /><span class="bigcheck-target"></span>',
						isContractValue: data.userlist[i].IsContract,
						showHide: data.userlist[i].ShowHide,
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.userlist;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//	console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	loadData() {
		if (this.checked) {
			this.loadAllUsers();
		}
		else {
			this.loadAllShowUsers();
		}
	}

	public showHideEvent(userId: string, showHide: number) {
		this.userService.changeUserShowHide(userId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}

	exportToExcel(){
		var dataList = []
		var filterData = []
		filterData = this.changeFilter(this.data, this.config)
		if (filterData.length > 0) {
			filterData.forEach(item => {
				var temp = {
					"First Name": item.firstname,
					"Last Name": item.lastname,	
					"Email": item.email,
					"Org Code": item.orgcode,
					"Supervisor Name": item.supervisorname,
					"Is Assessed": item.isAssessed,
					"Job Profile": item.jobProfieId,
					"Time Zone": item.timeZone,
					"Contractor": item.isContractValue == 1 ? 'Yes' : 'No'
				}
				dataList.push(temp)
			});

			let outputName = "Users"
		
		this.userService.exportAsExcelFile(dataList, outputName);
		}
	}
}
