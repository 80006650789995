import { Component, ViewChild, ElementRef } from "@angular/core";
import { AssessmentService } from "../../../assessment/assessment.service";
import { SharedService } from "../../../home/shared.service";

@Component({
	selector: 'app-employeeassessments',
	templateUrl: './employeeassessments.component.html',
	styleUrls: ['./employeeassessments.component.scss']
})
export class EmployeeAssessmentsComponent {
	@ViewChild('employeeAssessments', { static: true })
	tblElement: ElementRef;
	public data: Array<any> = [];

	constructor(private assessmentService: AssessmentService, private sharedService: SharedService) {
		this.data = this.assessmentService.getEmployeeAssessmentsData();
		if(this.data.length != 0){
		localStorage.setItem('selectedAsseseeName', JSON.stringify(this.data[0].userName));
		}
	}


	public employeeAssessmentClickEvent(assessment, col) {
		this.assessmentService.setSelectedAssessmentModel(assessment);
		if (col == 4) {
			this.sharedService.change('CandidateAssessment');
		}
	}
}