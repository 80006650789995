<div class="col-lg-12">
  <div class="col mb-4" style="overflow:hidden">
    <div class="float-left">
      <h4>Knowledge Check by Candidate</h4>
    </div>
    <div class="float-right">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-outline-warning">
					<input type="radio" name="options" id="option2" autocomplete="off" (click)="changeMode('graphical')"> Graphical View
				</label>
        <label class="btn btn-outline-warning active">
					<input type="radio" name="options" id="option1" autocomplete="off" checked (click)="changeMode('tabular')"> Tabular View
				</label>

      </div>
    </div>
  </div>
  <hr />
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="mt-2">
  <div *ngIf="mode == 'tabular' && dataAvailable">
    <app-knowledge-test-by-candidate-tabular [originaldata]="data"></app-knowledge-test-by-candidate-tabular>
  </div>
  <div *ngIf="mode == 'graphical' && dataAvailable">
    <app-knowledge-test-by-candidate-graphical [supervisorId]="supervisorId"></app-knowledge-test-by-candidate-graphical>
  </div>
</div>
