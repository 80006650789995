<div #competencysnapshot class="row pointer-default" style="overflow-x: auto;overflow-y: auto;">
  <div class="col-md-12 mb-3 mt-2 p-0" *ngIf="snapshotArray.length != 0">
    <div class="col-sm-6 float-left"><b class="mr-1">Job Profile:</b> {{ jobProfile }}
    </div>
    <div class="col-sm-6 float-right"><b class="mr-1">Assessment Completed Date:</b> {{ assessmentCompletedDate }} <span *ngIf="isKnowledgeTest == 1" class="badge badge-custom-kt mt-1 mr-1">Knowledge Check Only</span>
    </div>
  </div>
  <div class="col-md-12 mb-3 mt-3 p-0" *ngIf="snapshotArray.length != 0">
    <div class="col-sm-8 float-left">
      <div class="progress">
        <div *ngIf="lastScore < 90 && lastScore > 10" class="pointer-sm pointer-cursor" [style.left]="lastScore + '%'">
          <div class="progress-badge">
            <p>{{ lastScore }}%</p>
          </div>
        </div>
        <div *ngIf="lastScore == 100" class="pointer-sm pointer-cursor" [style.left]="99.5 + '%'">
          <div class="progress-badge float-right">
            <p>{{ lastScore }}%</p>
          </div>
        </div>
        <div *ngIf="lastScore >= 90 && lastScore != 100" class="pointer-sm pointer-cursor" [style.left]="lastScore + '%'">
          <div class="progress-badge float-right">
            <p>{{ lastScore }}%</p>
          </div>
        </div>
        <div *ngIf="lastScore <= 10" class="pointer-sm pointer-cursor" [style.left]="lastScore + '%'">
          <div class="progress-badge float-left">
            <p>{{ lastScore }}%</p>
          </div>
        </div>
        <div class="progress-bar" [ngClass]="{'progress-bar-success': lastScore >= 80, 'progress-bar-warning': lastScore >= 50 && lastScore < 80, 'progress-bar-danger' : lastScore < 50}"
          role="progressbar" aria-valuenow="lastScore" aria-valuemin="0" aria-valuemax="100" [style.width]="lastScore + '%'">
        </div>
      </div>
    </div>
    <div class="col-sm-4 float-right p-1">
      <p><b>Overall Competency Score</b></p>
    </div>
  </div>

  <div class="col-sm-12 mb-2 p-0" *ngFor="let row of coreCompetency | orderBy : 'orderNum'; let i = index">
    <div class="col-sm-8 float-left">
      <div class="progress">
        <div *ngIf="row.CoreCompetencyScore < 90 && row.CoreCompetencyScore > 10" class="pointer pointer-cursor" [style.left]="row.CoreCompetencyScore + '%'">
          <div class="progress-badge">
            <p>{{ row.CoreCompetencyScore }}%</p>
          </div>
        </div>
        <div *ngIf="row.CoreCompetencyScore == 100" class="pointer pointer-cursor" [style.left]="99.5 + '%'">
          <div class="progress-badge float-right">
            <p>{{ row.CoreCompetencyScore }}%</p>
          </div>
        </div>
        <div *ngIf="row.CoreCompetencyScore >= 90 && row.CoreCompetencyScore != 100" class="pointer pointer-cursor" [style.left]="row.CoreCompetencyScore + '%'">
          <div class="progress-badge float-right">
            <p>{{ row.CoreCompetencyScore }}%</p>
          </div>
        </div>
        <div *ngIf="row.CoreCompetencyScore <= 10" class="pointer pointer-cursor" [style.left]="row.CoreCompetencyScore + '%'">
          <div class="progress-badge float-left">
            <p>{{ row.CoreCompetencyScore }}%</p>
          </div>
        </div>
        <div class="progress-bar" [ngClass]="{'progress-bar-success': row.CoreCompetencyScore >= 80, 'progress-bar-warning': row.CoreCompetencyScore >= 50 && row.CoreCompetencyScore < 80, 'progress-bar-danger' : row.CoreCompetencyScore < 50}"
          role="progressbar" aria-valuenow="row.CoreCompetencyScore" aria-valuemin="0" aria-valuemax="100" [style.width]="row.CoreCompetencyScore + '%'">
        </div>
      </div>
    </div>
    <div class="col-sm-4 float-right p-1">
      <p>{{row.Description}}</p>
    </div>
  </div>
</div>

<div class="alert alert-warning" *ngIf="snapshotArray.length == 0">
  <a href="#" class="close" data-dismiss="alert"></a>
  <strong>Warning!</strong> You do not have any completed assessments.
</div>
