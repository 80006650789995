import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { user } from './user';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	providers: [UserService]
})
export class UserComponent implements OnInit {
	curUser: user;
	allUsers: user[] = [];

	constructor(private userService: UserService, private router: Router) {
		if (!userService.isValidLoggedIn()) {
			//this.router.navigateByUrl('/user/login');
		}
	}

	ngOnInit() {
		/*console.log('data');
		  this.userService.getAllPosts().subscribe(posts => {
		  console.log(posts);
				//this.users = posts;
				for (let post of posts) {
							 console.log(post.FirstName + '==' + post.LastName);
							 this.allUsers.push(new user(post.Email, '', post.Id,
								  post.FirstName,post.LastName)
							 );
						}          
			 });*/
	}
}
