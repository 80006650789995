import { Component, OnInit } from "@angular/core";
import { SharedService } from "../home/shared.service";

import { Report } from "./report.model";

import { ReportService } from "./report.service";
import { AlertService } from "../util/alert/alert.service";
import { TaskService } from '../complib/task/task.service';
import { AssessmentService } from '../assessment/assessment.service';
import { Constants } from '../util/const/constants';
import { UserService } from '../user/user.service';
import { KnowledgeService } from "../complib/knowledge/knowledge.service";
import { ChecklistService } from "../complib/checklist/checklist.service";
import { PerformanceService } from "../complib/performance/performance.service";

@Component({
	selector: "app-report",
	templateUrl: "./report.component.html",
	styleUrls: ["./report.component.scss"]
})
export class ReportComponent implements OnInit {
	public reports = [];
	public types = [];
	isLoading = false;

	constructor(
		private sharedService: SharedService,
		private reportService: ReportService,
		private alertService: AlertService,
		private taskService: TaskService,
		private assessmentService: AssessmentService,
		private userService: UserService,
		private knowledgeService: KnowledgeService,
		private checklistService: ChecklistService,
		private performanceService: PerformanceService
	) {
		this.reports = this.getReportList();
	}

	ngOnInit() { }

	private getReportList(): any {
		let reportList: Array<any> = []
		let tempList: any[] = JSON.parse(localStorage.getItem("reportList"));
		for (var i = 0; i < tempList.length; i++) {
			// let reportObject : any = [];
			let reportArr: Array<any> = tempList[i].ReportList.split(",");
			let reportObject = { "type": tempList[i].Type, "reportList": reportArr }
			reportList.push(reportObject);
		}
		return reportList;
	}

	public generateReport(reportType: string, reportName: string) {
		let report: Report = new Report(reportType, reportName, null);
		switch (reportName) {
			case Constants.LIST_ALL_SUPERVISORS:
				this.generateSupervisorsReport(report);
				break;
			case Constants.LIST_ALL_CANDIDATES:
				this.generateCandidateReport(report);
				break;
			case Constants.LIST_ALL_TASKS:
				this.generateTaskreport(report);
				break;
			case Constants.LIST_ALL_CHECKLIST:
				this.generateChecklistreport(report);
				break;
			case Constants.LIST_ALL_PERFORMANCE:
				this.generatePerformancereport(report);
				break;
			case Constants.NEVER_ASSESSED_REPORT:
			case Constants.OVERDUE_ASSESSMENTS_REPORT:
				this.generateNeverAssessed(report);
				break;
			case Constants.LIST_ALL_KNOWLEDGE:
				this.generateKnowledgereport(report);
				break;
			case Constants.MOST_MISSED_PERFORMANCE:
				this.generatMissedPerformanceReport(report);
				break;
			case Constants.MOST_MISSED_KNOWLEDGE:
				this.generateMissedKnowledgeReport(report);
				break;
			case Constants.MOST_MISSED_TASKS:
				this.generateMissedTaskReport(report);
				break;
			case Constants.MOST_MISSED_KNOWLEDGE_QUESTION:
				this.generateMissedKnowledgeQuestionsReport(report);
				break;
			case Constants.PERCENT_ASSESSMENT_NA_REPORT:
				this.generatePercentAssessmentNA(true, report);
				break;
			case Constants.ROLE:
				this.generateRolereport(report);
				break;
			case Constants.JOBPROFILE_TASKS:
				this.generateJobProfileTasksreport(report);
				break;
			case Constants.OPPORTUNITY_FOR_DEVELOPMENT:
				this.opportunityForDevelopmentreport(report);
				break;

		}
	}

	private generatePercentAssessmentNA(recentAssessment, report: Report) {
		this.isLoading = true;
		this.assessmentService.getPercentOfAssessmentNA(recentAssessment).subscribe(
			data => {
				report.reportData = data.candidateList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generateMissedTaskReport(report: Report) {
		this.isLoading = true;
		this.assessmentService.getMissedTaskDetails().subscribe(
			data => {
				report.reportData = data.missedTaskList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generateMissedKnowledgeQuestionsReport(report: Report) {
		this.isLoading = true;
		this.assessmentService.getMissedKnowledgeDetails(1).subscribe(
			data => {
				report.reportData = data.missedKnowledgeList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generatMissedPerformanceReport(report: Report) {
		this.isLoading = true;
		this.assessmentService.getMissedPerformanceDetails().subscribe(
			data => {
				report.reportData = data.missedPerformanceList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generateMissedKnowledgeReport(report: Report) {
		this.isLoading = true;
		this.assessmentService.getMissedKnowledgeDetails(0).subscribe(
			data => {
				report.reportData = data.missedKnowledgeList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generateTaskreport(report: Report) {
		this.isLoading = true;
		this.taskService.getActiveTasks().subscribe(
			data => {
				report.reportData = data.taskList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generateCandidateReport(report: Report) {
		this.isLoading = true;
		if (report.reportType == "Candidate") {
			var selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'));			
			if (selectedUserRole[0][1] == 'Supervisor') {				
				this.assessmentService.generateAllCandidatesReportBySupervisorId(localStorage.getItem('userId')).subscribe(
					data => {
						report.reportData = data.candidates;
						this.reportService.setSelectedReport(report);
						this.sharedService.change("GenerateReport");
						this.isLoading = false;
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false;
					}
				);
			} else {
				this.assessmentService.generateAllCandidatesReport().subscribe(
					data => {
						report.reportData = data.candidates;
						this.reportService.setSelectedReport(report);
						this.sharedService.change("GenerateReport");
						this.isLoading = false;
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false;
					}
				);
			}
		} else {
			this.assessmentService.getSupervisorCandidateList().subscribe(
				data => {
					report.reportData = data.userList;
					this.reportService.setSelectedReport(report);
					this.sharedService.change("GenerateReport");
					this.isLoading = false;
				},
				error => {
					this.alertService.clear();
					const err = error.json();
					this.alertService.handleError(err);
					this.isLoading = false;
				}
			);
		}
	}

	private generateSupervisorsReport(report: Report) {
		this.isLoading = true;
		this.userService.getAllSupervisors('').subscribe(
			data => {
				report.reportData = data.supervisorsdetails;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
		);
	}

	private generateChecklistreport(report: Report) {
		this.isLoading = true;
		this.checklistService.getActiveChecklistWithTags()
			.subscribe(
			data => {
				report.reportData = data.checklist;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	private generatePerformancereport(report: Report) {
		this.isLoading = true;
		this.performanceService.getActivePerformanceWithTags()
			.subscribe(
			data => {
				report.reportData = data.performancelist;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	private generateNeverAssessed(report: Report) {
		this.isLoading = true;
		var selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'));
		if (report.reportName == Constants.NEVER_ASSESSED_REPORT) {
			if (selectedUserRole[0][1] == 'Supervisor') {
				this.assessmentService.getNeverAssessedCandadidateListBySupervisorId(localStorage.getItem('userId')).subscribe(
					data => {
						report.reportData = data.candidateList;
						this.reportService.setSelectedReport(report);
						this.sharedService.change("GenerateReport");
						this.isLoading = false;
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false;
					}
				);
			} else {
				this.assessmentService.getNeverAssessedCandadidateList().subscribe(
					data => {
						report.reportData = data.candidateList;
						this.reportService.setSelectedReport(report);
						this.sharedService.change("GenerateReport");
						this.isLoading = false;
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false;
					}
				);
			}
		}
		else {
			if (selectedUserRole[0][1] == 'Supervisor') {
				this.assessmentService.getOverdueAssessmentCandidateListBySupervisorId(localStorage.getItem('userId')).subscribe(
					data => {
						report.reportData = data.candidateList;
						this.reportService.setSelectedReport(report);
						this.sharedService.change("GenerateReport");
						this.isLoading = false;
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false;
					}
				);
			} else {
				this.assessmentService.getOverdueAssessmentCandidateList().subscribe(
					data => {
						report.reportData = data.candidateList;
						this.reportService.setSelectedReport(report);
						this.sharedService.change("GenerateReport");
						this.isLoading = false;
					},
					error => {
						this.alertService.clear();
						const err = error.json();
						this.alertService.handleError(err);
						this.isLoading = false;
					}
				);
			}
		}
	}

	private generateKnowledgereport(report: Report) {
		this.isLoading = true;
		this.knowledgeService.getActiveKnowledgeWithTags()
			.subscribe(
			data => {
				//console.log('[data]' + JSON.stringify(data).toString());
				report.reportData = data.knowledgelist;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	private generateRolereport(report: Report) {
		this.isLoading = true;
		this.userService.getAllActiveUsersWithRole()
			.subscribe(
			data => {
				//console.log('[data]' + JSON.stringify(data).toString());
				report.reportData = data.roleList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
				this.isLoading = false;
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

	private generateJobProfileTasksreport(report: Report) {
		this.reportService.setSelectedReport(report);
		this.sharedService.change("GenerateReport");
	}

	private opportunityForDevelopmentreport(report: Report) {
		this.isLoading = true;
		var selectedUserRole = [];
		var stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
		var supervisorId = ''
		if (selectedUserRole) {
			selectedUserRole = JSON.parse(stortedSelectedUserRole);
			if (selectedUserRole[0][1] == 'Supervisor') {
				supervisorId = localStorage.getItem('userId');
			}
		}
		this.assessmentService.getOpportunityOfAssessmentForReport(supervisorId)
			.subscribe(
			data => {
				//console.log('[data]' + JSON.stringify(data).toString());
				this.isLoading = false;
				report.reportData = data.opportunityList;
				this.reportService.setSelectedReport(report);
				this.sharedService.change("GenerateReport");
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
				this.isLoading = false;
			}
			);
	}

}
