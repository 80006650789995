<div *ngIf="showOverlay" class="modal-backdrop fade show" (click)="removeOverlay()"></div>
<div *ngIf="showOverlay" class="overlay" (click)="removeOverlay()">
    <div class="container">
        <div class="card p-3 overlay-card">
            <div class="card-body">
                <h4 class="mx-auto text-center">
                    {{ constants.OERLAY_INS_BACKGROUND_MSG }}
                </h4>
            </div>
        </div>
    </div>
</div>
<nav class="navbar p-0" [ngClass]="{'fixed-top': showOverlay == false, ' ': showOverlay == true}">
    <!-- navbar navbar-inverse navbar-fixed-top -->
    <app-header style="width:100%"></app-header>
</nav>
<div id="page-wrapper" style="min-height: 524px; margin-top:18px;" [ngClass]="{'hidden-overflow ': showOverlay == true}">
    <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 0px; left: -20px;" class="sidebar-overlay" [message]="slidebarMessage"
        [side]="'apEast'"></app-overlay-tooltip>
    <app-sidebar></app-sidebar>
</div>