<!--component html goes here -->
<div class="col-lg-12" *ngIf="showingComponent === 'AddJobProfile'">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<button *ngIf="jobProfileOperation == 'EDIT'" type="button" class="btn btn-outline-warning btn-sm pointer-cursor mr-1 ml-1 float-left"
			 (click)="showJobProfileList()">
				<i class="fa fa-arrow-left fa-fw"></i>
			</button>
			<h4 class="float-right ml-2">{{ componentTopic }}</h4>
		</div>
		<div class="float-right"></div>
	</div>
	<hr />
</div>
<div class="col-lg-12 mx-auto">
	<div *ngIf=isLoading class="loading"></div>
</div>
<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'AddJobProfile'">
	<alert></alert>
	<div *ngIf=isLoading class="loading"></div>
	<div class="col-sm-12 mx-auto" *ngIf="!showDisabledForm && jobProfileOperation != 'EDIT'">
		<form [formGroup]="addJobProfileForm" (ngSubmit)="onSubmit()">
			<!-- description -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Description
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<input noSpaces maxlength="100" type="text" class="form-control" id="description" placeholder="Description" formControlName="description" name="description">
				</div>
			</div>
			<!-- org code -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Org Code
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId">
					</ng-select>
				</div>
			</div>
			<!-- discipline -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Discipline
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<ng-select [allowClear]="true" [items]="disciplineList" placeholder="Select discipline" name="disciplineId" id="disciplineId"
					 formControlName="disciplineId">
					</ng-select>
				</div>
			</div>
			<!-- frequency -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Frequency
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-8">
					<input type="number" min="1" max="99" (keypress)="limitKeyPress($event.target.value, 2)" class="form-control mb-1" id="frequency"
					 placeholder="Frequency" formControlName="frequency" name="frequency">
					<div class="" *ngIf="addJobProfileForm.controls.frequency.touched">
						<div class="alert alert-danger mb-1" role="alert" *ngIf="addJobProfileForm.hasError('invalidFrequency')">Frequency input is invalid.</div>
					</div>
				</div>
				<label class="col-sm-2 col-form-label">months</label>
			</div>
			<!--buttons-->
			<div class="form-group row">
				<div class="col-sm-6"></div>
				<div class="col-sm-3">
					<button type="submit" class="btn btn-warning btn-block" [disabled]="!addJobProfileForm.valid">Save</button>
				</div>
				<div class="col-sm-3">
					<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('AllJobProfile')">Cancel</button>
				</div>
			</div>
		</form>
	</div>

	<!-- edit job profile form -->
	<div class="col-sm-12 mx-auto" *ngIf="!showDisabledForm && jobProfileOperation != 'ADD'">
		<form [formGroup]="editJobProfileForm" (ngSubmit)="onSubmitEdit()">
			<!-- description -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label" aria-required="true">Description
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<input noSpaces maxlength="100" type="text" class="form-control" id="editDescription" placeholder="Description" formControlName="editDescription"
					 name="editDescription">
				</div>
			</div>
			<!-- discipline -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Discipline
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<ng-select [allowClear]="true" [items]="disciplineList" placeholder="Select discipline" name="editDisciplineId" id="editDisciplineId"
					 formControlName="editDisciplineId">
					</ng-select>
				</div>
			</div>
			<!-- frequency -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Frequency
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-8">
					<input type="number" min="1" max="99" (keypress)="limitKeyPress($event.target.value, 2)" class="form-control mb-1" id="editFrequency"
					 placeholder="Frequency" formControlName="editFrequency" name="editFrequency">
					<div class="" *ngIf="editJobProfileForm.controls.editFrequency.touched">
						<div class="alert alert-danger mb-1" role="alert" *ngIf="editJobProfileForm.hasError('invalidFrequency')">Frequency input is invalid.</div>
					</div>
				</div>
				<label class="col-sm-2 col-form-label">months</label>
			</div>
			<!--buttons-->
			<div class="form-group row">
				<div class="col-sm-6"></div>
				<div class="col-sm-3">
					<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save Changes</button>
				</div>
				<div class="col-sm-3">
					<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('AllJobProfile')">Cancel</button>
				</div>
			</div>
		</form>
	</div>


	<div class="col-sm-12 mx-auto" *ngIf="showDisabledForm">
		<div class="col-sm-12" *ngIf="jobProfileOperation == 'ADD'">
			<form (ngSubmit)="onSubmitAddCopy()">
				<div class="form-group row">
					<div class="col-sm-6">
						<label for="disabledTextInputDescription" class="col-form-label-sm">Description</label>
						<input type="text" id="disabledTextInputDescription" class="form-control" placeholder="{{ savedDescription }}" readonly>
					</div>
					<div class="col-sm-6">
						<label for="disabledTextInputOrgCode" class="col-form-label-sm">Org Code</label>
						<input type="text" id="disabledTextInputOrgCode" class="form-control" placeholder="{{ savedOrgCode }}" readonly>
					</div>

				</div>
				<div class="col-sm-2" *ngIf="status==0">
					<div class="form-group row">

						<button id="copyButton" type="button" class="btn btn-outline-warning btn-sm" (click)="copyExistingJobProfile()">
							Copy Existing Job Profile
						</button>
					</div>
				</div>
				<div *ngIf="copyClicked">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label">Job Profile</label>
						<div class="col-sm-10">
							<ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select job profile" name="jobprofile" id="jobprofile"
							 (removed)="removedJobProfile($event)" (selected)="selectedJobProfile($event)">
							</ng-select>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-sm-6"></div>
						<div class="col-sm-3">
							<button type="submit" class="btn btn-warning btn-block" [disabled]="copyToJpId==-1">Copy</button>
						</div>
						<div class="col-sm-3">
							<button type="button" class="btn btn-default btn-block" (click)="change()">Cancel</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="col-sm-12" *ngIf="jobProfileOperation == 'EDIT'">
			<form (ngSubmit)="onSubmitEditCopy()">
				<div class="form-group row">
					<div class="col-sm-5">
						<label for="disabledTextInputDescription" class="col-form-label-sm">Description</label>
						<input type="text" id="disabledTextInputDescription" class="form-control" placeholder="{{ savedDescription }}" readonly>
					</div>
					<div class="col-sm-5">
						<label for="disabledTextInputOrgCode" class="col-form-label-sm">Org Code</label>
						<input type="text" id="disabledTextInputOrgCode" class="form-control" placeholder="{{ savedOrgCode }}" readonly>
					</div>
					<div class="col-sm-2 mx-auto" *ngIf="!showTableData">
						<label class="invisible" for="editJPButton">Edit Job Profile</label>
						<button id="editJPButton" type="button" class="btn btn-outline-warning btn-sm" (click)="editJPBtnClickEvent('coreCompetency')">
							<i class="fa fa-pencil-square-o fa-fw"></i>&nbsp;Edit Job Profile
						</button>
					</div>
				</div>
				<div class="col-sm-2" *ngIf="status==0">
					<div class="form-group row">

						<button id="copyButton" type="button" class="btn btn-outline-warning btn-sm" (click)="copyExistingJobProfile()">
							Copy Existing Job Profile
						</button>
					</div>
				</div>
				<div *ngIf="copyClicked">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label">Job Profile</label>
						<div class="col-sm-10">
							<ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select job profile" name="jobprofile" id="jobprofile"
							 (removed)="removedJobProfile($event)" (selected)="selectedJobProfile($event)">
							</ng-select>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-sm-6"></div>
						<div class="col-sm-3">
							<button type="submit" class="btn btn-warning btn-block" [disabled]="copyToJpId==-1">Copy</button>
						</div>
						<div class="col-sm-3">
							<button type="button" class="btn btn-default btn-block" (click)="change()">Cancel</button>
						</div>
					</div>
				</div>

			</form>
		</div>
		
		<div *ngIf=isLoading class="loading"></div>

		<div *ngIf="!copyClicked"> 
			<hr />
			<app-jphierarchy (notify)="addNewBtnClick($event)" (showingItems)="showingItems" [rShowingItems]="showingItems"> </app-jphierarchy>
		 </div>

	</div>

</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'coreCompetency'">
	<app-add-core-competency></app-add-core-competency>
</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'task'">
	<app-addtask></app-addtask>
</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'performance'">
	<app-addperformance></app-addperformance>
</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'checkList'">
	<app-add-checklist></app-add-checklist>
</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'remediation'">
	<app-add-remediation></app-add-remediation>
</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'knowledge'">
	<app-add-knowledge></app-add-knowledge>
</div>

<div class="col-lg-12 mx-auto" *ngIf="showingComponent === 'taskGroup'">
	<app-addtaskgroup></app-addtaskgroup>
</div>