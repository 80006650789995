import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
// import { Select2OptionData } from 'ng2-select2';

import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { ChecklistService } from './checklist.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service'

import { CheckList } from './checklist.model'
import { Performance } from '../performance/performance.model'
import { Task } from '../task/task.model'
import { CoreCompetency } from '../corecompetency/corecompetency.model'
import { JobProfile } from '../../jobprofile/jobprofile.model'

import { TagInputModule } from 'ngx-chips';
import { ChecklistTagService } from '../../tag/checklisttag/checklisttag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-add-checklist',
	templateUrl: './addchecklist.component.html'
})

export class AddChecklistComponent implements OnInit {

	addChecklist: FormGroup;
	public selectedOrgId: string;
	public orgList: Array<any> = [];
	public autocompleteItemsAsObjects = [];
	public selectedTags = [];
	public selectedMenuItem = localStorage.getItem('selectedMenuItem');

	constructor(private router: Router,
		private sharedService: SharedService,
		private alertService: AlertService,
		private checklistService: ChecklistService,
		private orgcodeService: OrgcodeService,
		private jobProfileService: JobProfileService,
		private checklistTagService: ChecklistTagService,
		private userService: UserService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		if (this.selectedMenuItem == 'AddJobProfile') {
			let jpOrgId = this.jobProfileService.getSelectedJobProfileForEdit().orgCodeId;
			this.orgcodeService.getActiveOrgCodesAboveOrgCode(jpOrgId)
				.subscribe(
				data => {
					this.orgList = [];
					for (var i = 0; i < data.orgCodeList.length; i++) {
						this.orgList.push({ id: data.orgCodeList[i].Id, text: data.orgCodeList[i].Description });
					}
					// this.selectedOrgId = null;
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
		else {
			this.orgcodeService.getActiveOrgcodes()
				.subscribe(
				data => {
					this.orgList = [];
					for (var i = 0; i < data.orgcode.length; i++) {
						this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
					}
					// this.selectedOrgId = null;
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
	}

	valid() {
		let result = false;
		if (this.addChecklist.get("checklist").status == "VALID") {
			result = true;
		}
		return result;
	}

	ngOnInit() {
		this.loadActiveChecklistTags()
		this.addChecklist = new FormGroup({
			checklist: new FormControl(null, Validators.required),
			orgId: new FormControl(null),
			checklistTag: new FormControl(null)
		});
	}

	cancel() {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('Checklist');
		}
	}

	saveAndNew() {
		let orgId = null;
		if (this.addChecklist.get("orgId").value != null) {
			if (this.addChecklist.get("orgId").value.length > 0) {
				orgId = this.addChecklist.get("orgId").value[0].id;
			}
		}

		let clText: string = this.addChecklist.value.checklist
		let clOrgId: string = orgId
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.addJPCheckList(clText, clOrgId)
		} else {
			this.addCheckList(clText, clOrgId)
		}
	}

	save() {
		let orgId = null;
		if (this.addChecklist.get("orgId").value != null) {
			if (this.addChecklist.get("orgId").value.length > 0) {
				orgId = this.addChecklist.get("orgId").value[0].id;
			}
		}

		let clText: string = this.addChecklist.value.checklist
		let clOrgId: string = orgId
		if (this.selectedMenuItem == 'AddJobProfile') {
			let tempCL: CheckList = new CheckList('', clText, clOrgId)
			tempCL.remediationList = []
			let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
			let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
			let taskModel: Task = this.jobProfileService.getSelectedTaskModel()
			let perModel: Performance = this.jobProfileService.getSelectedPerformanceModel()
			let i = perModel.checkList.length

			this.checklistService.addChecklist(tempCL)
				.subscribe(
				data => {
					tempCL.id = data.checkListId
					tempCL.orderNum = i++
					this.addCheckListToJPSave(jobProfile, ccModel, taskModel, perModel, tempCL);
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				)
		} else {
			let tempCheckList: CheckList = new CheckList('', clText, clOrgId)
			tempCheckList.tagList = this.selectedTags;
			this.checklistService.addChecklist(tempCheckList)
				.subscribe(
				data => {
					this.alertService.clear();
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
	}

	public addCheckList(clText: string, clOrgId: string) {
		let tempCheckList: CheckList = new CheckList('', clText, clOrgId)
		tempCheckList.tagList = this.selectedTags;
		//console.log('[addCheckList]'+ JSON.stringify(tempCheckList).toString());
		this.checklistService.addChecklist(tempCheckList)
			.subscribe(
			data => {
				//console.log('[addCheckList]'+ JSON.stringify(data).toString());
				this.alertService.clear();
				this.alertService.success("Successfully added the checklist.");
				// this.addChecklist.reset();
				// this.selectedOrgId = null;
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	public addJPCheckList(clText: string, clOrgId: string) {
		let tempCL: CheckList = new CheckList('', clText, clOrgId)
		tempCL.remediationList = []
		let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let taskModel: Task = this.jobProfileService.getSelectedTaskModel()
		let perModel: Performance = this.jobProfileService.getSelectedPerformanceModel()
		let i = perModel.checkList.length

		this.checklistService.addChecklist(tempCL)
			.subscribe(
			data => {
				tempCL.id = data.checkListId
				tempCL.orderNum = i++
				this.addCheckListToJP(jobProfile, ccModel, taskModel, perModel, tempCL)
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)

	}

	public addCheckListToJP(jp: JobProfile, cc: CoreCompetency, task: Task, per: Performance, cl: CheckList) {
		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempPer: Performance = new Performance(per.id, per.text, true, [])
		tempPer.checkList.push(cl)
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.performanceList = tempPer
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addCheckListToJobProfile(tempJP)
			.subscribe(
			data => {
				per.checkList.push(cl)
				this.alertService.clear();
				this.alertService.success('Successfully added check list to the job profile.');
				// this.addChecklist.reset();
				// this.selectedOrgId = null;
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	public addCheckListToJPSave(jp: JobProfile, cc: CoreCompetency, task: Task, per: Performance, cl: CheckList) {
		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempPer: Performance = new Performance(per.id, per.text, true, [])
		tempPer.checkList.push(cl)
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.performanceList = tempPer
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addCheckListToJobProfile(tempJP)
			.subscribe(
			data => {
				per.checkList.push(cl)
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	public loadActiveChecklistTags() {
		this.checklistTagService.getActiveChecklistTags()
			.subscribe(
			data => {
				//console.log('[loadActiveChecklistTags] ' + JSON.stringify(data).toString());
				this.autocompleteItemsAsObjects = []
				data.ccTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.selectedTags.push(event);
	};

	public removeTags(event: any) {
		this.selectedTags = this.selectedTags.filter(tag => tag.id !== event.id);
	};
}
