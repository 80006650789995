<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Users</h4>
		</div>
		<div class="float-right">
			<div class="row">
				<form [formGroup]="showHideForm" class="mr-2 ml-1">
					<div class="col-sm-12">
						<input class="mr-1" (change)="showHide($event)" [checked]="checked==true" type="checkbox" id="showHide" name="showHide"> Show Hidden
					</div>
				</form>
				<div>
					<button type="button" class="btn btn-outline-warning btn-sm ml-1" (click)="selectedComponentItem('AddUser')" [disabled]="!isAddUser">
						<i class="fa fa-user-plus fa-fw"></i>&nbsp;Add New User
					</button>
				</div>
				<div>
					<button type="button" class="btn btn-outline-warning btn-sm ml-1" (click)="exportToExcel()" [disabled]="!isAddUser">
						<i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
					</button>
				</div>
			</div>
		</div>
	</div>
	<hr />
</div>



<div class="col-lg-12 mx-auto">

	<alert></alert>
	<div class="table-responsive mb-2">
		<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
		 style="cursor: pointer;" class="table-sm">
		</ng-table>
	</div>
	<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
	 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
	</pagination>
	<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
	<!-- <ngb-pagination *ngIf="config.paging"
        [collectionSize]="length"
        [(page)]="page"
        [pageSize]="itemsPerPage"
        [maxSize]="maxSize"
        (pageChange)="onChangeTable(config, pageCount)"
        size="sm" >
    </ngb-pagination>
    <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{pageCount}}</pre>    -->

</div>