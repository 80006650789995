import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { JobProfile } from './jobprofile.model';
import { CoreCompetency } from '../complib/corecompetency/corecompetency.model'
import { Task } from '../complib/task/task.model'
import { Performance } from '../complib/performance/performance.model'
import { Knowledge } from '../complib/knowledge/knowledge.model'
import { CheckList } from '../complib/checklist/checklist.model'
import { RegulatoryAgency } from '../complib/regulatoryagency/regulatoryagency.model';

@Injectable()
export class JobProfileService {

	constructor(private http: Http, private router: Router) { }

	//selected jobprofile for edit
	private selectedJobProfileForEdit: JobProfile;
	public getSelectedJobProfileForEdit(): JobProfile {
		return this.selectedJobProfileForEdit;
	}
	public setSelectedJobProfileForEdit(jobProfile: JobProfile): void {
		this.selectedJobProfileForEdit = jobProfile;
	}

	private jobProfileOperation: string; //(ADD/EDIT)
	public getjobProfileOperation(): string {
		return this.jobProfileOperation;
	}
	public setjobProfileOperation(jpo: string): void {
		this.jobProfileOperation = jpo;
	}

	//selected jpcc model
	private selectedJPCCModel: CoreCompetency;
	public getSelectedJPCCModel(): CoreCompetency {
		return this.selectedJPCCModel
	}
	public setSelectedJPCCModel(coreCompetency: CoreCompetency): void {
		this.selectedJPCCModel = coreCompetency
	}

	//selected task model
	private selectedTaskModel: Task;
	public getSelectedTaskModel(): Task {
		return this.selectedTaskModel
	}
	public setSelectedTaskModel(task: Task): void {
		this.selectedTaskModel = task
	}

	//selected task Group model
	private selectedTaskGroupModel: Task = null;
	public getSelectedTaskGroupModel(): Task {
		return this.selectedTaskGroupModel
	}
	public setSelectedTaskGroupModel(task: Task): void {
		this.selectedTaskGroupModel = task
	}

	//selected performance model
	private selectedRegulatoryModel: RegulatoryAgency;
	public getSelectedRegulatoryModel(): RegulatoryAgency {
		return this.selectedRegulatoryModel
	}
	public setSelectedRegulatoryModel(regulatory: RegulatoryAgency): void {
		this.selectedRegulatoryModel = regulatory
	}

	//selected performance model
	private selectedPerformanceModel: Performance;
	public getSelectedPerformanceModel(): Performance {
		return this.selectedPerformanceModel
	}
	public setSelectedPerformanceModel(performance: Performance): void {
		this.selectedPerformanceModel = performance
	}

	//selected knowledge model
	private selectedKnowledgeModel: Knowledge;
	public getSelectedKnowledgeModel(): Knowledge {
		return this.selectedKnowledgeModel
	}
	public setSelectedKnowledgeModel(knowledge: Knowledge): void {
		this.selectedKnowledgeModel = knowledge
	}

	//selected check list model
	private selectedCheckListModel: CheckList;
	public getSelectedCheckListModel(): CheckList {
		return this.selectedCheckListModel
	}
	public setSelectedCheckListModel(checkList: CheckList): void {
		this.selectedCheckListModel = checkList
	}

	//remediation type for new remediation
	private remediationType: string;
	public getRemediationType(): string {
		return this.remediationType
	}
	public setRemediationType(type: string): void {
		this.remediationType = type
	}

	//get all job profiles
	getAllJobProfiles() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/jobprofile/getAllJobProfiles' + token, { headers: headers })
			.map(res => res.json());
	}

	//get all tasks of a job profile
	getJobProfileTasks(jobprofileId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'jobProfileId': jobprofileId });
		return this.http.post(environment.server_URL + '/jobprofile/getJobProfileTasks' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get job profile of the user
	getJobProfileOfUser() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'userId': localStorage.getItem('userId') });
		return this.http.post(environment.server_URL + '/jobprofile/getAllJobProfiles' + token, { headers: headers })
			.map(res => res.json());
	}

	//get all active job profiles
	getAllActiveJobProfiles() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.get(environment.server_URL + '/jobprofile/getAllActiveJobProfiles' + token, { headers: headers })
			.map(res => res.json());
	}

	//get job profile detail (corecompetency, task, performance, skill etc)
	getJobProfileDetail(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/getJobProfileDetail' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get active job profile by org Id
	getActiveJobprofileByOrgId(orgId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'orgId': orgId });
		return this.http.post(environment.server_URL + '/jobprofile/getActiveJobprofileByOrgId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update job profile status by job profile id
	updateJobProfileStatusByJobProfileId(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/updateJobProfileStatusByJobProfileId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//CopyActiveElementsOfJobProfile by job profile id
	CopyActiveElementsOfJobProfile(jobProfileId: string, copyJobProfileId: string, orgCodeId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'jobProfileId': jobProfileId, 'copyJobProfileId': copyJobProfileId, 'orgCodeId': orgCodeId });
		return this.http.post(environment.server_URL + '/jobprofile/CopyActiveElementsOfJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}


	//update JobProfile detail
	updateJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/updateJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update checklist order
	updateCheckListOrder(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/updateCheckListOrder' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateTaskOrder(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/updateTaskOrder' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updatePerformanceOrder(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/updatePerformanceOrder' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateKnowledgeOrder(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/updateKnowledgeOrder' + token, body, { headers: headers })
			.map(res => res.json());
	}

	// <editor-fold> ADD
	//add jobprofile detail

	//add new job profile
	addNewJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addNewJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add coreCompetency to job profile
	addCoreCompetenciesToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addCoreCompetenciesToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add task to job profile
	addTaskToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addTasksToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	addTaskGroupToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addTaskGroupToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editTaskGroupInJP(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/jobprofile/editTaskGroupInJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	addTasksToTaskGroupInJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addTasksToTaskGroupInJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add regulatory agency to job profile
	addRegulatoryAgencyToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addRegulatoryAgencyToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add performance to job profile
	addPerformanceToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addPerformanceToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add knowledge to job profile
	addKnowledgeToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addKnowledgeToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add task remediation to job profile
	addTaskRemediationToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addTaskRemediationToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add knowledge remediation to job profile
	addKnowledgeRemediationToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addKnowledgeRemediationToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add checkList to job profile
	addCheckListToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addCheckListToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add performance remediation to job profile
	addPerformanceRemediationToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addPerformanceRemediationToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add check list remediation to jp
	addCheckListRemediationToJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/addCheckListRemediationToJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//</editor-fold> ADD

	//<editor-fold> DELETE
	//delete job profile
	deleteJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete core competency by job profileid and core competency id
	deleteCCOfJPByCCIdAndJPId(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteCCOfJPByCCIdAndJPId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete task group in job profile
	deleteTaskGroupOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteTaskGroupOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete task of job profile by task id and core competency id
	deleteTaskOfJPByTIDAndJPIDAndCCID(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteTaskOfJPByTIDAndJPIDAndCCID' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteKnowledgeOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteKnowledgeOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteRegulatoryAgencyOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteRegulatoryAgencyOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deletePerformanceOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deletePerformanceOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteTaskRemediationOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteTaskRemediationOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteKnowledgeRemediationOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteKnowledgeRemediationOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}


	deleteCheckListOfJobProfile(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteCheckListOfJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deletePerformanceRemediationOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deletePerformanceRemediationOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteCheckListRemediationOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/deleteCheckListRemediationOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActiveJobprofilesAboveOrgCode(orgCodeId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'orgCodeId': orgCodeId });
		return this.http.post(environment.server_URL + '/jobprofile/getActiveJobprofilesAboveOrgCode' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllShowJobProfiles() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/jobprofile/getAllShowJobProfiles' + token, { headers: headers })
			.map(res => res.json());
	}

	changeJobProfileShowHide(jobProfileId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'jobProfileId': jobProfileId, 'showHide': showHide })
		return this.http.post(environment.server_URL + '/jobprofile/changeJobProfileShowHide' + token, body, { headers: headers })
			.map(res => res.json());

	}
	//</editor-fold> DELETE

	//<editor-fold> CRITICALITY VALUE
	changeTaskCriticalityValue(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/changeTaskCriticalityValue' + token, body, { headers: headers })
			.map(res => res.json());
	}

	orderByTaskNameAlphabeticallyOfJP(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/orderByTaskNameAlphabeticallyOfJP' + token, body, { headers: headers })
			.map(res => res.json());
	}

	orderByTaskNameAlphabeticallyOfTG(jobProfile: JobProfile) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(jobProfile);
		return this.http.post(environment.server_URL + '/jobprofile/orderByTaskNameAlphabeticallyOfTG' + token, body, { headers: headers })
			.map(res => res.json());
	}
	//</editor-fold> CRITICALITY VALUE

	//<editor-fold> GENERATE JOB PROFILE
	//generate cc list from data
	public generateCCListFromData(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem;
			let tl = data.result[i].taskList;
			let regl = data.result[i].regulatoryList;
			let pl = data.result[i].performanceList;
			let kl = data.result[i].knowledgeList;
			let cll = data.result[i].checkList;
			let trl = data.result[i].taskRemediationList;
			let prl = data.result[i].performanceRemediationList;
			let krl = data.result[i].knowledgeRemediationList;
			let clrl = data.result[i].checkListRemediationList;

			let clml = this.generateCheckListModel(cll, clrl);
			let kml = this.generateKnowledgeListModel(kl, krl);
			let pml = this.generatePerformanceListModel(pl, clml, prl);
			let tml = this.generateTaskListModel(tl, pml, kml, trl, regl);
			let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
			ccItem.orderNum = cci.orderNum;
			ccItem.taskTotal = this.generateCCLevelTotaltask(tl);
			corecompetencyList.push(ccItem);
		}
		return corecompetencyList;
	}

	public generateCheckListModel(checkList: any, clrList: any): any {
		let clModelList = [];
		for (let i = 0; i < checkList.length; i++) {
			let clModel: CheckList = new CheckList(checkList[i].id, checkList[i].text, "",
				true, checkList[i].orderNum);
			clModel.jppId = checkList[i].JPPId;
			clModel.remediationList = clrList.filter(
				item => item.JPCLId === checkList[i].JPCLId
			);
			clModelList.push(clModel);
		}
		return clModelList;
	}

	public generateKnowledgeListModel(knowledgeList: any, knoRemList: any): any {
		let knowledgeModelList = [];
		for (let i = 0; i < knowledgeList.length; i++) {
			let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 0, knowledgeList[i].id);
			kModel.remediationList = knoRemList.filter(
				item => item.JPKId === knowledgeList[i].JPKId
			);
			kModel.jptId = knowledgeList[i].JPTId;
			kModel.orderNum = knowledgeList[i].orderNum;
			kModel.isTestQuestion = knowledgeList[i].isTestQuestion;
			knowledgeModelList.push(kModel);
		}
		return knowledgeModelList;
	}

	public generatePerformanceListModel(perList: any, checkList: any, remList: any) {
		let performanceModelList = [];
		for (let i = 0; i < perList.length; i++) {
			let pModel: Performance = new Performance(perList[i].id, perList[i].text, true);
			pModel.checkList = checkList.filter(item => item.jppId === perList[i].JPPId);
			pModel.remediationList = remList.filter(item => item.JPPId === perList[i].JPPId);
			pModel.jptId = perList[i].JPTId;
			pModel.orderNum = perList[i].orderNum;
			performanceModelList.push(pModel);
		}
		return performanceModelList;
	}

	public generateTaskListModel(taskList: any, perList: any, knoList: any, remList: any, regList: any) {
		let taskModelList = [];
		// console.log('[generateTaskListModel] (perList) : ' + JSON.stringify(perList));
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task;
			if (taskList[i].isTaskGroup) {
				taskModel = new Task(taskList[i].JPTId, taskList[i].text);
				taskModel.regulatoryList = [];
				taskModel.performanceList = [];
				taskModel.knowledgeList = [];
				taskModel.knowledgeQuestionList = [];
				taskModel.remediationList = [];
				taskModel.jpccId = taskList[i].JPCCId;
				taskModel.orderNum = taskList[i].orderNum;
				taskModel.criticalityValue = taskList[i].criticality;
				taskModel.isTaskGroup = taskList[i].isTaskGroup
				taskModel.taskGroup = taskList[i].taskGroup
				taskModel.taskGroupId = taskList[i].taskGroupId
				taskModel.tgId = taskList[i].TGId
				taskModel.taskList = []
			} else {
				taskModel = new Task(taskList[i].id, taskList[i].text);
				taskModel.regulatoryList = regList.filter(item => item.JPTId === taskList[i].JPTId);
				taskModel.performanceList = perList.filter(item => item.jptId === taskList[i].JPTId);
				taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].JPTId).filter(item => item.isTestQuestion == 0);
				taskModel.knowledgeQuestionList = knoList.filter(item => item.jptId === taskList[i].JPTId).filter(item => item.isTestQuestion == 1);
				taskModel.remediationList = remList.filter(item => item.JPTId === taskList[i].JPTId);
				taskModel.jpccId = taskList[i].JPCCId;
				taskModel.orderNum = taskList[i].orderNum;
				taskModel.criticalityValue = taskList[i].criticality;
				taskModel.isTaskGroup = taskList[i].isTaskGroup
				taskModel.taskGroup = taskList[i].taskGroup
				taskModel.taskGroupId = taskList[i].taskGroupId
			}
			taskModelList.push(taskModel);

		}
		// console.log('[generateTaskListModel] (taskModelList) : ' + JSON.stringify(taskModelList));
		taskModelList = this.modifyTaskList(taskModelList)

		return taskModelList;
	}

	public modifyTaskList(taskList: any): any {
		let returnTaskList = []
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				returnTaskList.push(task)
			} else {
				if (task.taskGroupId != null) {
					tempTaskList.push(task)
				} else {
					returnTaskList.push(task)
				}
			}
		});
		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));
		// console.log('[modifyTaskList] (tempTaskList) : ' + JSON.stringify(tempTaskList));
		tempTaskList.forEach(task => {
			if (task.taskGroupId != null && task.taskGroupId != 'null') {
				let tg = returnTaskList.find(item => item.id === task.taskGroupId);
				if (tg != null) {
					tg.taskList.push(task)
				}
			} else {
				returnTaskList.push(task)
			}
		});

		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));

		return returnTaskList

	}

	public generateCCLevelTotaltask(taskList: any): any {
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.taskGroupId == null) {
				tempTaskList.push(task.id)
			} else {

			}
		});
		return tempTaskList.length
	}

	//remove item from normal array list
	private removeItemFromArray(arrayList: any, itemId: string): any {
		arrayList = arrayList.filter(item => item.id !== itemId);
		return arrayList
	}

	//find item by id in arrayList
	private getItemByIdFromArray(arrayList: any, itemId: string): any {
		return arrayList.find(item => item.id === itemId);
	}

	/**
	 * generate assessment jp hierarchy with cc and task score
	 * @param data 
	 */
	public generateAssessmentScoreJP(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem;
			let tl = data.result[i].taskList;

			let tml = this.generateAssessmentTaskListModel(tl);
			let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
			ccItem.assessmentScore = cci.assessmentScore
			corecompetencyList.push(ccItem);
		}
		return corecompetencyList;
	}

	public generateAssessmentTaskListModel(taskList: any) {
		let taskModelList = [];
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task = new Task(taskList[i].id, taskList[i].text);
			taskModel.performanceList = [];
			taskModel.knowledgeList = [];
			taskModel.remediationList = [];
			taskModel.jpccId = taskList[i].JPCCId;
			taskModel.orderNum = taskList[i].orderNum;
			taskModel.criticalityValue = taskList[i].criticality;
			taskModel.assessmentScore = taskList[i].assessmentScore
			taskModelList.push(taskModel);
		}
		return taskModelList;
	}
	//</editor-fold> GENERATE JOB PROFILE
}
