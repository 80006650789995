import { OnInit, Component } from "@angular/core";
import { AssessmentService } from "../../assessment/assessment.service";
import { ConvertDateTime } from "../../util/convertdatetime.pipe";
import { Router } from '@angular/router';
import { AlertService } from "../../util/alert/alert.service";
import * as moment from 'moment-timezone';
import { SharedService } from "../../home/shared.service";
import { AssessmentModel } from '../../assessment/assessment.model';

@Component({
	selector: 'app-upcomingAssessment',
	templateUrl: './upcomingassessments.component.html',
	styleUrls: ['./upcommingassessment.component.scss']
})
export class UpComingAssessmentsComponent implements OnInit {

	public userId = ''
	public data = [];
	public currentDate: any;
	public numAssessmentDays: number;
	public numSelfAssessmentDays: number;
	public description1: String;
	public description2: String;
	public sizeOfData: number;
	public localAssessmentDate: any;
	public localSelfAssessmentDate: any;
	public timeZone;
	public localDate;
	public assessmentId: any = "";
	public selfAssessmentStatus: string = '';
	public candidateSignature: any = null;
	public assessorCompleted: any = null
	public assessorSignature: any = null;
	public userName: any = '';

	constructor(private assessmentService: AssessmentService, private router: Router, private convertDataTime: ConvertDateTime,
		private alertService: AlertService, private sharedService: SharedService) {
		this.currentDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
		this.userId = localStorage.getItem('userId');
		this.timeZone = localStorage.getItem('TimeZone');
		this.userName = localStorage.getItem('userName');
	}

	ngOnInit(): void {
		this.getUpComingAssessments();
	}


	public getUpComingAssessments() {
		this.assessmentService.getUpComingAssessmentData(this.userId)
			.subscribe(
			data => {
				this.data = [];
				this.sizeOfData = data.assessmentData.length;
				// console.log('[currentdate]' + JSON.stringify(this.currentDate).toString());
				if (data.assessmentData.length != 0) {
					let ScheduleAssessmentDate = null;
					if (data.assessmentData[0].ScheduleAssessmentDate != null) {
						ScheduleAssessmentDate = new Date(new Date(data.assessmentData[0].ScheduleAssessmentDate).getUTCFullYear(), new Date(data.assessmentData[0].ScheduleAssessmentDate).getUTCMonth(), new Date(data.assessmentData[0].ScheduleAssessmentDate).getUTCDate());
					}
					let ScheduleSelfAssessmentDate = null;
					if (data.assessmentData[0].ScheduleSelfAssessmentDate != null) {
						ScheduleSelfAssessmentDate = new Date(new Date(data.assessmentData[0].ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(data.assessmentData[0].ScheduleSelfAssessmentDate).getUTCMonth(), new Date(data.assessmentData[0].ScheduleSelfAssessmentDate).getUTCDate());
					}
					let AssessmentCompletedDate = null;
					if (data.assessmentData[0].AssessmentCompletedDate != null) {
						AssessmentCompletedDate = new Date(new Date(data.assessmentData[0].AssessmentCompletedDate).getUTCFullYear(), new Date(data.assessmentData[0].AssessmentCompletedDate).getUTCMonth(), new Date(data.assessmentData[0].AssessmentCompletedDate).getUTCDate());
					}
					let SelfAssessmentCompletedDate = null;
					if (data.assessmentData[0].SelfAssessmentCompletedDate != null) {
						SelfAssessmentCompletedDate = new Date(new Date(data.assessmentData[0].SelfAssessmentCompletedDate).getUTCFullYear(), new Date(data.assessmentData[0].SelfAssessmentCompletedDate).getUTCMonth(), new Date(data.assessmentData[0].SelfAssessmentCompletedDate).getUTCDate());
					}
					let Status = data.assessmentData[0].Status;
					this.selfAssessmentStatus = data.assessmentData[0].SelfAssessmentStatus;
					this.assessmentId = data.assessmentData[0].AssessmentId;
					this.assessorCompleted = data.assessmentData[0].AssessorCompleted;
					this.candidateSignature = data.assessmentData[0].CandidateSignature;
					this.assessorSignature = data.assessmentData[0].AssessorSignature;
					

					this.data.push({
						'scheduleAssessmentDate': ScheduleAssessmentDate,
						'scheduleSelfAssessmentDate': ScheduleSelfAssessmentDate,
						'assessmentCompletedDate': AssessmentCompletedDate,
						'selfAssessmentCompletedDate': SelfAssessmentCompletedDate,
					});

					this.data = this.convertDataTime.transform(this.data, "allAssessments");

					// this.localAssessmentDate = this.setLocalAssessmentTime(this.data[0].scheduleAssessmentDate);
					// this.localSelfAssessmentDate = this.setLocalAssessmentTime(this.data[0].scheduleSelfAssessmentDate);
					this.numAssessmentDays = this.getdiffInAssessmentDate(this.data[0].scheduleAssessmentDate);
					this.numSelfAssessmentDays = this.getDiffInSelfAssessmentDate(this.data[0].scheduleSelfAssessmentDate);

					if (this.assessorCompleted == 0) {						
						if (AssessmentCompletedDate == null && SelfAssessmentCompletedDate == null) {
							if (Status == 'Started') {
								this.description1 = 'Assessor lead is in progress.'
							} else if (Status == 'New') {
								if (this.numAssessmentDays > 0) {
									this.description1 = "Scheduled assessor lead in " + this.numAssessmentDays + " days.";
								} else if (this.numAssessmentDays == 0) {
									this.description1 = "Scheduled assessor lead is due today.";
								} else if (this.numAssessmentDays < 0) {
									this.description1 = "Scheduled assessor lead was due " + (this.numAssessmentDays * -1) + " days ago.";
								}
							}
							if (this.numSelfAssessmentDays > 0) {
								this.description2 = " due in " + this.numSelfAssessmentDays + " days."
							} else if (this.numSelfAssessmentDays == 0) {
								this.description2 = " is due today.";
							} else if (this.numSelfAssessmentDays < 0) {
								this.description2 = " was due " + (this.numSelfAssessmentDays * -1) + " days ago.";
							}

						} else if (AssessmentCompletedDate != null && SelfAssessmentCompletedDate == null) {
							if (Status == 'Started') {
								this.description1 = 'Assessor lead is in progress.'
							} else if (Status == 'Completed') {
								this.description1 = 'Assessor lead is completed.'
							}
							if (this.numSelfAssessmentDays > 0) {
								this.description2 = " due in " + this.numSelfAssessmentDays + " days."
							} else if (this.numSelfAssessmentDays == 0) {
								this.description2 = " is due today.";
							} else if (this.numSelfAssessmentDays < 0) {
								this.description2 = " was due " + (this.numSelfAssessmentDays * -1) + " days ago.";
							}

						} else {
							if (Status == 'Started') {
								this.description1 = 'Assessor lead is in progress.'
							} else if (Status == 'Completed') {
								this.description1 = 'Assessor lead is completed.'
							} else if (Status == 'New') {
								if (this.numAssessmentDays > 0) {
									this.description1 = "Scheduled assessor lead in " + this.numAssessmentDays + " days.";
								} else if (this.numAssessmentDays == 0) {
									this.description1 = "Scheduled assessor lead is due today.";
								} else if (this.numAssessmentDays < 0) {
									this.description1 = "Scheduled assessor lead was due " + (this.numAssessmentDays * -1) + " days ago.";
								}
							}
							this.description2 = " is completed.";
						}
					} else {						
						if (this.candidateSignature == 0 && this.assessorSignature == 0) {
							this.description1 = 'Waiting for assessor and candidate signatures.'
						} else if (this.candidateSignature == 1 && this.assessorSignature == 0) {
							this.description1 = 'Waiting for assessor signature.'
						} else if (this.candidateSignature == 0 && this.assessorSignature == 1) {
							this.description1 = 'Waiting for candidate signature.'
						}
					}
				}
			}
			);
	}

	directToSelfAssessment() {
		this.router.navigateByUrl('/selfassessment?assessmentId=' + this.assessmentId);
	}

	directToSignaturePage(assessment) {
		this.router.navigateByUrl('/assessmentsignature?type=candidate&assessmentId=' + this.assessmentId);
	}

	directToSelfAssessmentReview() {
		let assessmentModel: AssessmentModel = new AssessmentModel(this.assessmentId);
		assessmentModel.selfAssessmentCompletedDate = this.data[0].selfAssessmentCompletedDate;
		assessmentModel.userName = this.userName
		this.assessmentService.setSelectedAssessmentModel(assessmentModel)
		this.sharedService.change('SelfAssessmentReview')
	}

	public getdiffInAssessmentDate(scheduleAssessmentDate) {
		let diffAssessment = (scheduleAssessmentDate - this.currentDate) / 1000 / 60 / 60 / 24;
		return this.numAssessmentDays = Math.round(diffAssessment);
	}

	public getDiffInSelfAssessmentDate(selfAssessmentCompletedDate) {
		let diffSelfAssessment = (selfAssessmentCompletedDate - this.currentDate) / 1000 / 60 / 60 / 24;
		return this.numSelfAssessmentDays = Math.round(diffSelfAssessment);
	}

	private setLocalAssessmentTime(AssDate) {
		let date = moment(AssDate);
		date.tz(this.timeZone);
		return this.localDate = date._d;
	}

}