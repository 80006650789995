import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Http, Headers } from '@angular/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class DependencyService {
	constructor(private http: Http, private router: Router) { }

	getDependency(itemId: String, itemType: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'itemId': itemId, 'itemType': itemType });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/dependency/getDependency' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
