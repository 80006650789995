import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ReportTableComponent } from "../reporttable.component";

import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";

import { Report } from "../report.model";
import { AssessmentModel } from '../../assessment/assessment.model';
import { AssessmentService } from '../../assessment/assessment.service';

@Component({
	selector: "app-percent-na-report",
	templateUrl: "./percentnareport.component.html",
	styleUrls: ["./percentnareport.component.scss"]
})
export class PercentNAReportComponent extends ReportTableComponent
	implements OnInit {
	public report: Report;
	public columns: Array<any>;
	private serverResultData: Array<any> = [];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"],
		report: null
	};

	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	originaldata: Array<any> = [];
	showRecentAssessment: boolean = true;
	searchCandidate: string = "";
	searchTaskNA: string = "";
	searchPerformanceNA: string = "";
	searchKnowledgeNA: string = "";
	searchAssessor: string = "";
	isLoading = false;
	isFiltering: boolean = true;

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private assessmentService: AssessmentService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
		this.report = this.reportService.getSelectedReport();
		this.columns = [
			{
				title: "Assessment Score",
				name: "Assessment Score",
				filtering: { filterString: "" }
			},
			{
				title: "Candidate",
				name: "Candidate",
				filtering: { filterString: "" }
			},
			{
				title: "Task NA",
				name: "Task NA",
				filtering: { filterString: "" }
			},
			{
				title: "Performance NA",
				name: "Performance NA",
				filtering: { filterString: "" }
			},
			{
				title: "Knowledge NA",
				name: "Knowledge NA",
				filtering: { filterString: "" }
			},
			{
				title: "Assessor",
				name: "Assessor",
				filtering: {
					filterString: ""
				}
			},
			{
				title: "Completed Date",
				name: "Completed Date",
				filtering: { filterString: "" }
			},
			{
				title: "Assessment Report",
				name: "Assessment Report"
			}
		];
		this.config.sorting = { columns: this.columns };
	}

	ngOnInit() {
		this.loadCandidateDataToTable(this.report.reportData);
	}

	ngAfterContentInit() {
		this.sharedService.getRefreshReport().subscribe(item => this.refreshPage(item));
	}

	refreshPage(item: any) {
		this.loadCandidateDataToTable(item)
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.config.report.reportDataList = this.filteredData
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * [onCellClick cell click event in table]
	 * @param  {any} data [description]
	 * @return {any}      [description]
	 */
	public onCellClick(data: any): any { }

	private loadCandidateDataToTable(data: any) {
		this.isFiltering = true;
		this.filteredData = []
		this.originaldata = []
		this.searchCandidate = ''
		this.searchTaskNA = ''
		this.searchKnowledgeNA = ''
		this.searchPerformanceNA = ''
		this.searchAssessor = ''
		
		data.forEach(item => {
			let assessmentScore = item.AssessmentScore == null ? 0 : item.AssessmentScore.toFixed(0);
			let assessment: AssessmentModel = new AssessmentModel(item.AssessmentId)
			assessment.assessmentScore = assessmentScore
			assessment.userName = item.CandidateFirstName + " " + item.CandidateLastName;
			if (item.AssessmentCompletedDate == null) {
				assessment.assessmentCompletedDate = null
			} else {
				assessment.assessmentCompletedDate = new Date(new Date(item.AssessmentCompletedDate).getUTCFullYear(), new Date(item.AssessmentCompletedDate).getUTCMonth(), new Date(item.AssessmentCompletedDate).getUTCDate());
			}
			assessment.candidateStrengths = item.CandidateStrengths
			assessment.jobProfileDesc = item.JobProfile

			var NAKnowledge = item.NAKnowledge
			var knowledgeTotal = item.KnowledgeTotal
			let precentKnowledgeNA: any
			
			if (!NAKnowledge) {
				NAKnowledge = 0
			}
			if (!knowledgeTotal) {
				knowledgeTotal = 0
				precentKnowledgeNA = '0'
			}else{
				precentKnowledgeNA =  Math.round((NAKnowledge / knowledgeTotal) * 100)
			}
			

			var tempItem = {
				"Assessment Score": assessmentScore + "",
				"Candidate": item.CandidateFirstName + " " + item.CandidateLastName,
				"Task NA": item.NATask + " of " + item.TaskTotal,
				"PrecentTaskNA": Math.round((item.NATask / item.TaskTotal) * 100),
				"Performance NA": item.NAPerformance + " of " + item.PerformanceTotal,
				"PrecentPerformanceNA": Math.round((item.NAPerformance / item.PerformanceTotal) * 100),
				"Knowledge NA": NAKnowledge + " of " + knowledgeTotal,
				"PrecentKnowledgeNA": precentKnowledgeNA,
				"Assessor": item.AssessorFirstName + " " + item.AssessorLastName,
				"Completed Date": new Date(new Date(item.AssessmentCompletedDate).getUTCFullYear(), new Date(item.AssessmentCompletedDate).getUTCMonth(), new Date(item.AssessmentCompletedDate).getUTCDate()).toLocaleDateString(),
				"Assessment Report": "",
				"Assessment": assessment
			}
			this.filteredData.push(tempItem);

			this.originaldata.push(tempItem);
		});
		this.report.columns = this.columns;
		this.config.report = this.report;
		this.serverResultData = data;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.total = this.filteredData.length;
		this.isFiltering = false;
		this.pageChanged();

	}

	public performAssessmentClickEvent(assessmentModel) {
		console.log(assessmentModel)
		localStorage.setItem('selectedAsseseeName', assessmentModel.userName)
		this.assessmentService.setSelectedAssessmentModel(assessmentModel);
		this.assessmentService.setGenerate(true)
		this.sharedService.change('CandidateAssessment');
	}

	filter() {
		this.isFiltering = true;
		for (var i = 0; i < this.originaldata.length; i++) {
			this.filteredData = this.originaldata.map(x => Object.assign({}, x));
		}
		if (this.searchCandidate != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Candidate"].match(new RegExp(this.searchCandidate, "i"));
			});
		}
		if (this.searchTaskNA != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Task NA"].match(new RegExp(this.searchTaskNA, "i"));
			});
		}
		if (this.searchPerformanceNA != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Performance NA"].match(new RegExp(this.searchPerformanceNA, "i"));
			});
		}
		if (this.searchKnowledgeNA != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Knowledge NA"].match(new RegExp(this.searchKnowledgeNA, "i"));
			});
		}
		if (this.searchAssessor != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Assessor"].match(new RegExp(this.searchAssessor, "i"));
			});
		}

		this.pageChanged(1);
		this.isFiltering = false;
	}

	onSorted($event) {
		this.isFiltering = false;
		console.log($event)
		this.sortData($event)
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: PercentNAReportSearchCriteria) {
		criteria.sortType = 'string';
	}

	sortData(criteria: PercentNAReportSearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push({
					"Assessment Score": this.originaldata[i]["Assessment Score"],
					"Candidate": this.originaldata[i]["Candidate"],
					"Task NA": this.originaldata[i]["Task NA"],
					"PrecentTaskNA": this.originaldata[i]["PrecentTaskNA"],
					"Performance NA": this.originaldata[i]["Performance NA"],
					"PrecentPerformanceNA": this.originaldata[i]["PrecentPerformanceNA"],
					"Knowledge NA": this.originaldata[i]["Knowledge NA"],
					"PrecentKnowledgeNA": this.originaldata[i]["PrecentKnowledgeNA"],
					"Assessor": this.originaldata[i]["Assessor"],
					"Completed Date": this.originaldata[i]["Completed Date"],
					"Assessment Report": "",
					"Assessment": this.originaldata[i]["Assessment"]
				});
			}
			if (this.searchCandidate != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Candidate"].match(new RegExp(this.searchCandidate, "i"));
				});
			}
			if (this.searchTaskNA != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Task NA"].match(new RegExp(this.searchTaskNA, "i"));
				});
			}
			if (this.searchPerformanceNA != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Performance NA"].match(new RegExp(this.searchPerformanceNA, "i"));
				});
			}
			if (this.searchKnowledgeNA != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Knowledge NA"].match(new RegExp(this.searchKnowledgeNA, "i"));
				});
			}
			if (this.searchAssessor != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Assessor"].match(new RegExp(this.searchAssessor, "i"));
				});
			}
		}
	}

}

export class PercentNAReportSearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}