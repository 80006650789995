export class CoreCompetency {
	constructor(
		public id: string,
		public text: string,
		public status?: boolean,		

		public taskList?: any,

		public tagList?: any,

		public taskTotal?: any,

		public nATask?: any,

		public ccLevelTaskTotal?: any,

		/** assessment status */
		public assStatus?: number,
		/** id of the core competency in assessment table.
		use to update assessment answer */
		public assId?: string,

		/** store assessment score */
		public assessmentScore?: number,

		public orderNum?: number,
	) { }
}
