/**This class used to display task report. Most missed tasks are
 * displayed in top.
*/

import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { Report } from "../report.model";
import { ReportTableComponent } from "../reporttable.component";
import { AssessmentService } from '../../assessment/assessment.service';

@Component({
	selector: "app-missed-tasks-report",
	templateUrl: "./missedtasksreport.component.html",
	styleUrls: ['./missedtasksreport.component.scss']
})
export class MissedTasksReportComponent implements OnInit {
	public report: Report;
	public data: any[];
	public excelData: any[];
	private serverResultData: Array<any> = [];
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	originaldata: Array<any> = [];

	searchTask: string = "";
	searchOccurrences: string = "";
	isFiltering: boolean = false;

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private changeDetectorRef: ChangeDetectorRef,
		private assessmentService: AssessmentService
	) {
		this.report = this.reportService.getSelectedReport();
	}

	ngOnInit() {
		this.processData(this.report.reportData);
		this.sortData(this.report.reportData);
		this.loadMostMissedTasks(this.report.reportData);
		this.createExcelData();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	setSortType(criteria: MissedTaskSearchCriteria) {
		if (criteria.sortColumn == 'Task') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	getMissedTasks(criteria: MissedTaskSearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push({
					"Task": this.originaldata[i].Task,
					"Occurrences": this.originaldata[i].Occurrences,
					"HighPercentage": this.originaldata[i].HighPercentage,
					"PassPercentage": this.originaldata[i].PassPercentage,
					"FailedPercentage": this.originaldata[i].FailedPercentage,
					"HighBadgepPosition": this.originaldata[i].HighBadgepPosition,
					"PassBadgepPosition": this.originaldata[i].PassBadgepPosition,
					"FailedBadgepPosition": this.originaldata[i].FailedBadgepPosition
				});
			}
			if (this.searchTask != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Task"].match(new RegExp(this.searchTask, "i"));
				});
			}
			if (this.searchOccurrences != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return ("" + item["Occurrences"]).match(new RegExp(this.searchOccurrences, "i"));
				});
			}
			this.report.printData = this.filteredData;
		}

	}

	onSorted($event) {
		this.isFiltering = false;
		this.getMissedTasks($event);
		this.createExcelData();
		this.pageChanged(this.currentPage);
	}

	filter() {
		this.isFiltering = true;
		this.filteredData = [];
		for (let i = 0; i < this.originaldata.length; i++) {
			this.filteredData.push({
				"Task": this.originaldata[i].Task,
				"Occurrences": this.originaldata[i].Occurrences,
				"HighPercentage": this.originaldata[i].HighPercentage,
				"PassPercentage": this.originaldata[i].PassPercentage,
				"FailedPercentage": this.originaldata[i].FailedPercentage,
				"HighBadgepPosition": this.originaldata[i].HighBadgepPosition,
				"PassBadgepPosition": this.originaldata[i].PassBadgepPosition,
				"FailedBadgepPosition": this.originaldata[i].FailedBadgepPosition
			});
		}
		if (this.searchTask != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Task"].match(new RegExp(this.searchTask, "i"));
			});
		}
		if (this.searchOccurrences != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return ("" + item["Occurrences"]).match(new RegExp(this.searchOccurrences, "i"));
			});
		}
		this.report.printData = this.filteredData;
		this.createExcelData();
		this.pageChanged(1);
		this.isFiltering = false;
	}


	private processData(reportData: any) {
		for (let i = 0; i < reportData.length; i++) {

			reportData[i].failedPercentage = Math.round((reportData[i].FailedCount / reportData[i].TotalCount) * 100);
			reportData[i].failedBadgepPosition = Math.round(((reportData[i].FailedCount / reportData[i].TotalCount) * 100) * 100) / 100; //Math.round(((reportData[i].highPercentage / 2 * 100).toFixed(0);
			reportData[i].passPercentage = Math.round((reportData[i].PassCount / reportData[i].TotalCount) * 100);
			reportData[i].passBadgepPosition = Math.round(((reportData[i].PassCount / reportData[i].TotalCount) * 100) * 100) / 100;  //((reportData[i].highPercentage + reportData[i].passPercentage / 2) * 100).toFixed(0);
			reportData[i].highPercentage = Math.round((reportData[i].HighCount / reportData[i].TotalCount) * 100);
			reportData[i].highBadgepPosition = Math.round(((reportData[i].HighCount / reportData[i].TotalCount) * 100) * 100) / 100; //(100 - (reportData[i].failedPercentage / 2 * 100)).toFixed(0);

			let result = this.assessmentService.getCalculatedPercentage(reportData[i].failedBadgepPosition, reportData[i].passBadgepPosition, reportData[i].highBadgepPosition)
			reportData[i].failedBadgepPosition = result.redPercentage
			reportData[i].passBadgepPosition = result.yellowPercentage
			reportData[i].highBadgepPosition = result.greenPercentage
		}
	}

	/**Sort reportData using failed percentage and pass percentage in
	 * decending order
	*/
	private sortData(reportData: any) {
		if (reportData != null && reportData.length != 0) {
			reportData.sort((first, second) => {
				var firstFailedPercentage = first.failedPercentage;
				var secondFailedPercentage = second.failedPercentage;
				if (firstFailedPercentage > secondFailedPercentage) {
					return -1;
				}
				else if (firstFailedPercentage < secondFailedPercentage) {
					return 1;
				}
				else {
					var firstPassPercentage = first.passPercentage;
					var secondPassPercentage = second.passPercentage;
					if (firstPassPercentage > secondPassPercentage) {
						return -1;
					} else if (firstPassPercentage < secondPassPercentage) {
						return 1;
					} else {
						var firstOccurances = first.Occurrences;
						var secondOccurrences = second.Occurrences;
						if (firstOccurances > secondOccurrences) {
							return -1;
						} else if (firstOccurances < secondOccurrences) {
							return 1;
						} else {
							return 0;
						}

					}
				}
			});
		}
	}

	createExcelData() {
		this.excelData = [];
		console.log("create excel data")
		for (let i = 0; i < this.filteredData.length; i++) {
			this.excelData.push({
				"Task": this.filteredData[i].Task,
				"Occurrences": this.filteredData[i].Occurrences,
				"Score >= 80": this.filteredData[i].HighPercentage + '%',
				"Score >= 50 & Score < 80": this.filteredData[i].PassPercentage + '%',
				"Score < 50": this.filteredData[i].FailedPercentage + '%',
			});
		}
		this.report.reportDataList = this.excelData;
	}

	public loadMostMissedTasks(data: any) {
		this.data = [];
		for (let i = 0; i < data.length; i++) {
			if (data[i].highPercentage != '100') {
				this.data.push({
					"Task": data[i].Name,
					"Occurrences": data[i].TotalCount,
					"HighPercentage": data[i].highPercentage,
					"PassPercentage": data[i].passPercentage,
					"FailedPercentage": data[i].failedPercentage,
					"HighBadgepPosition": data[i].highBadgepPosition,
					"PassBadgepPosition": data[i].passBadgepPosition,
					"FailedBadgepPosition": data[i].failedBadgepPosition
				});
				this.originaldata.push({
					"Task": data[i].Name,
					"Occurrences": data[i].TotalCount,
					"HighPercentage": data[i].highPercentage,
					"PassPercentage": data[i].passPercentage,
					"FailedPercentage": data[i].failedPercentage,
					"HighBadgepPosition": data[i].highBadgepPosition,
					"PassBadgepPosition": data[i].passBadgepPosition,
					"FailedBadgepPosition": data[i].failedBadgepPosition
				});
			}
		}
		this.report.printData = this.data;
		this.total = this.data.length;
		this.filteredData = this.data;
		this.pageChanged();
	}
}

export class MissedTaskSearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}
