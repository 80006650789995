<div class="ml-2 mr-2">
  <div class="row m-0 ">
    <div class="col-12">
      <h4 style="display:inline">{{ userName }}'s Assessment </h4><span *ngIf="assessmentModel.isKnowledgeTest == 1" class="badge badge-custom-kt mt-1 mr-1">Knowledge Check Only</span>
      <div style="overflow:hidden" *ngIf="assessmentModel.jobProfileDesc">
        Job Profile - {{ assessmentModel.jobProfileDesc }}
      </div>
      <div *ngIf="assessmentModel.assessmentCompletedDate">
        Assessment Completed Date - {{ assessmentModel.assessmentCompletedDate.toLocaleDateString() }}
      </div>
    </div>
  </div>
  <hr />
  <div class="row m-0 ">
    <div class="col-12 p-0">
      <div class="row m-0 ">
        <div class="col-sm-6 mb-4 mt-2">
          <button *ngIf="showGenerate" type="button" class="btn btn-outline-warning btn-sm mb-1" (click)="directToSelfAssessmentReview()">Knowledge Check Review</button>
          <button *ngIf="showGenerate" type="button" class="btn btn-outline-warning btn-sm ml-1 mb-1" (click)="directToRemediationReport()">Remediation Report</button>
          <button *ngIf="assessmentModel!=null && assessmentModel.opportunityList!= null && assessmentModel.opportunityList.length!=0"
            type="button" class="btn btn-outline-warning btn-sm ml-1 mb-1" (click)="showHideOpportunityPanel()">Opportunity For Development</button>
          <button *ngIf="assessmentModel!=null && assessmentModel.candidateStrengths!=null" type="button" class="btn btn-outline-warning btn-sm ml-1 mb-1"
            (click)="showCandidateStrength()">Candidate Strengths</button>
        </div>
        <div class="col-sm-6 mb-4 mt-2 text-right">
          <button *ngIf="showGenerate" type="button" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1" (click)="print()"  [disabled]="noResults">Print</button>
          <button *ngIf="showGenerate" type="button" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1" (click)="exportToExcel()"  [disabled]="noResults">Export to Excel</button>
          <button *ngIf="showGenerate" type="button" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1" (click)="download()">Generate</button>
          <div class="btn-group  float-right ml-1">
            <button *ngIf="!expandAllTasks" type="button" class="btn  btn-sm btn-outline-warning float-right" (click)="expandAllTasksInAssessment()"><i class="mr-1 ml-1 fa fa-angle-down" ></i>&nbsp;Expand All</button>
            <button *ngIf="expandAllTasks" type="button" class="btn btn-sm btn-outline-warning float-right" (click)="expandAllTasksInAssessment()"><i class="mr-1 ml-1 fa fa-angle-up" ></i>&nbsp;Collapse All</button>
          </div>

        </div>
      </div>

      <div class="row m-0 ">
        <div class="col-sm-12 mb-4">
          <div class="row">
            <div class="col-sm-3">
              <div class="btn-group btn-group-toggle float-left w-100">
                <button type="button" [ngClass]="{'active': allClicked, '' : !allClicked}" class="btn btn-sm btn-outline-warning" (click)="showAll()">All</button>
                <button type="button" [ngClass]="{'active': !allClicked, '':allClicked}" class="btn btn-sm btn-outline-warning" (click)="showRegulatoryAgency()">Regulatory Agency</button>

              </div>

            </div>

            <div class="col-sm-9">


              <div class="btn-group ml-1 float-left filter-wrapper-dd">
                <ng-select *ngIf="!allClicked" [multiple]="true" [allowClear]="true" [items]="regAgencyList" placeholder="{{placeholderText}}"
                  name="regAgency" id="regAgency" class="filter-ngselect" (data)="filterTasks($event)">
                </ng-select>
              </div>
              <!--<angular2-multiselect *ngIf="!allClicked" [data]="regAgencyList1" [(ngModel)]="selectedRegItems" [settings]="dropdownSettings"
      (onSelect)="filterTasks($event)" (onDeSelect)="filterTasks($event)" (onSelectAll)="filterTasks($event)" (onDeSelectAll)="removeAllRegulatoryAgency($event)"></angular2-multiselect>-->

            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-1 mb-5" *ngIf="showCandidateStrengthPanel">
      <br>
      <div class="card card-main-100 p-3 ">
        <div class="card-body pointer-cursor">
          <div class="mt-2 mb-2">
            <h5>Candidate Strengths</h5>
          </div>
          <form [formGroup]="candidateStrengthsForm">
            <div class="col-sm-12 mt-1">
              <div class="form-group row">
                <textarea rows="10" type="text" formControlName="candidateStrengths" class="form-control" id="candidateStrengths" name="candidateStrengths"></textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /candidate strength panel -->

    <!-- show opportunity for development panel -->

    <div class="col-sm-12 mt-1 mb-5" *ngIf="showofdPanel">
      <br>
      <div class="card card-main-100 p-3">
        <div class="card-body pointer-cursor">
          <div class="mt-2 mb-2">
            <h5>Opportunity for Development</h5>
          </div>
          <!-- <div class="mt-2"> -->
          <form [formGroup]="opportunityForm">
            <div class="col-sm-12 mt-1" *ngIf="assessmentModel!=null && assessmentModel.opportunityList.length > 0">
              <table class="table table-sm table-bordered table-striped">
                <thead>
                  <th class="">Category</th>
                  <th class="">Task / Opportunity</th>
                </thead>
                <tbody>
                  <tr *ngFor="let item of assessmentModel.opportunityList; let i=index">
                    <td class="">{{item.categoryText}}</td>
                    <td class="">{{item.text}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- /opportunity for development panel -->

    <div *ngIf=isLoading class="loading"></div>
    <div class="col-sm-12">
      <p class="mb-1">Competency Score</p>
      <div class="progress ">
        <div *ngIf="assessmentModel.assessmentScore < 90 && assessmentModel.assessmentScore > 10" class="pointer pointer-cursor"
          [style.left]="assessmentModel.assessmentScore + '%'">
          <div class="progress-badge">
            <p>{{ assessmentModel.assessmentScore }}%</p>
          </div>
        </div>
        <div *ngIf="assessmentModel.assessmentScore == 100" class="pointer pointer-cursor" [style.left]="99.5 + '%'">
          <div class="progress-badge float-right">
            <p>{{ assessmentModel.assessmentScore }}%</p>
          </div>
        </div>
        <div *ngIf="assessmentModel.assessmentScore >= 90 && assessmentModel.assessmentScore != 100" class="pointer pointer-cursor"
          [style.left]="assessmentModel.assessmentScore + '%'">
          <div class="progress-badge float-right">
            <p>{{ assessmentModel.assessmentScore }}%</p>
          </div>
        </div>
        <div *ngIf="assessmentModel.assessmentScore <= 10" class="pointer pointer-cursor" [style.left]="assessmentModel.assessmentScore + '%'">
          <div class="progress-badge float-left">
            <p>{{ assessmentModel.assessmentScore }}%</p>
          </div>
        </div>
        <div [ngClass]="{'progress-bar progress-bar-success': assessmentModel.assessmentScore >= 80, 'progress-bar progress-bar-warning': assessmentModel.assessmentScore >= 50 && assessmentModel.assessmentScore < 80, 'progress-bar progress-bar-danger' : assessmentModel.assessmentScore < 50}"
          role="progressbar" aria-valuenow="assessmentModel.assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="assessmentModel.assessmentScore + '%'">
        </div>
      </div>
    </div>

    <div class="col-sm-12 mx-auto mb-3">
      <div class="row" *ngIf=" assessmentModel!=null && assessmentModel.jobProfile!=null">
        <div class="col-sm-12" *ngIf="noResults">
          <div class=" alert alert-info">No matching items.</div>
        </div>
        <div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of assessmentModel.jobProfile.coreCompetencyList | orderBy : 'orderNum'; let k = index">
          <div class="row">
            <div class="col-sm-3 float-left">
              <div class="card p-2 pt-3 pb-3 card-empty">
                <div class="item-progress">
                  <div *ngIf="ccItem.assessmentScore < 90 && ccItem.assessmentScore > 10" class="item-pointer item-pointer-cursor" [style.left]="ccItem.assessmentScore + '%'">
                    <div class="item-progress-badge">
                      <p>{{ ccItem.assessmentScore }}%</p>
                    </div>
                  </div>
                  <div *ngIf="ccItem.assessmentScore == 100" class="item-pointer item-pointer-cursor" [style.left]="99.5 + '%'">
                    <div class="item-progress-badge float-right">
                      <p>{{ ccItem.assessmentScore }}%</p>
                    </div>
                  </div>
                  <div *ngIf="ccItem.assessmentScore >= 90 && ccItem.assessmentScore != 100" class="item-pointer item-pointer-cursor" [style.left]="ccItem.assessmentScore + '%'">
                    <div class="item-progress-badge float-right">
                      <p>{{ ccItem.assessmentScore }}%</p>
                    </div>
                  </div>
                  <div *ngIf="ccItem.assessmentScore <= 10" class="item-pointer item-pointer-cursor" [style.left]="ccItem.assessmentScore + '%'">
                    <div class="item-progress-badge float-left">
                      <p>{{ ccItem.assessmentScore }}%</p>
                    </div>
                  </div>
                  <div class="item-progress-bar" [ngClass]="{'progress-bar-success': ccItem.assessmentScore >= 80, 'progress-bar-warning': ccItem.assessmentScore >= 50 && ccItem.assessmentScore < 80, 'progress-bar-danger' : ccItem.assessmentScore < 50}"
                    role="progressbar" aria-valuenow="ccItem.assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="ccItem.assessmentScore + '%'">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-9 float-right">
              <div class="card p-2 card-default" (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
                <div class="card-body pointer-cursor">
                  <div class="float-left mb-2">
                    {{ ccItem.text }} <span class="badge badge-warning mr-1">{{ ccItem.ccLevelTaskTotal }}</span>
                  </div>
                  <div class="float-right">
                    <label class="mb-0">
                  <span class="badge badge-dark ">Tasks NA : {{ ccItem.nATask }} out of {{ ccItem.taskTotal }}</span>
                </label>
                    <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                      <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id || expandAllTasks, 'fa-angle-down': showCCDetail != ccItem.id && !expandAllTasks}"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="showCCDetail == ccItem.id || expandAllTasks">
            <div class="col-sm-12 mt-1 mb-1" *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let t = index ">
              <ng-container *ngIf="task.isTaskGroup; else elseTemplate">
                <div class="row mb-3">
                  <div class="col-sm-3 float-left pl-2">
                    <div class="card pl-0 pr-3 pt-3 pb-1 card-empty">
                    </div>
                  </div>
                  <div class="col-sm-9 float-right pr-4 pl-4">
                    <div class="card p-2 card-grey-lighten-3 pt-1 pb-1 pr-1" (click)="showCollapsePanel(task.id, 'taskGroup')">
                      <div class="card-body pointer-cursor mb-1 float-right pr-0 pl-0">
                        <label class="mb-0">
											<span class="badge badge-dark mr-1">Task Group</span>{{ task.taskGroup }} <span class="badge badge-warning mr-1">{{ task.taskList.length }}</span></label>
                        <div class="float-right">
                          <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                            <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskGroupDetail == task.id || expandAllTasks, 'fa-angle-down': showTaskGroupDetail != task.id && !expandAllTasks}"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 mt-2" *ngIf="showTaskGroupDetail == task.id || expandAllTasks">
                    <div *ngFor="let tGtask of task.taskList | orderBy : 'orderNum'; let gt = index">
                      <div class="row p-2">
                        <div class="col-sm-3 float-left pl-0">
                          <div class="card pl-0 pr-3 pt-1 pb-3 card-empty-trasparent">
                            <ng-container *ngIf="tGtask.isNA; else tGtaskElseTemplate">
                              <div class="item-progress mt-2 mb-0">
                                <div class="item-progress-badge">
                                  <p>NA</p>
                                </div>
                                <div class="item-progress-bar" role="progressbar" style="width:100%"></div>
                              </div>
                            </ng-container>
                            <ng-template #tGtaskElseTemplate>
                              <div class="item-progress mt-2 mb-0">
                                <div *ngIf="tGtask.assessmentScore < 90 && tGtask.assessmentScore > 10" class="item-pointer item-pointer-cursor" [style.left]="tGtask.assessmentScore + '%'">
                                  <div class="item-progress-badge">
                                    <p>{{ tGtask.assessmentScore }}%</p>
                                  </div>
                                </div>
                                <div *ngIf="tGtask.assessmentScore == 100" class="item-pointer item-pointer-cursor" [style.left]="99.5 + '%'">
                                  <div class="item-progress-badge float-right">
                                    <p>{{ tGtask.assessmentScore }}%</p>
                                  </div>
                                </div>
                                <div *ngIf="tGtask.assessmentScore >= 90 && tGtask.assessmentScore != 100" class="item-pointer item-pointer-cursor" [style.left]="tGtask.assessmentScore + '%'">
                                  <div class="item-progress-badge float-right">
                                    <p>{{ tGtask.assessmentScore }}%</p>
                                  </div>
                                </div>
                                <div *ngIf="tGtask.assessmentScore <= 10" class="item-pointer item-pointer-cursor" [style.left]="tGtask.assessmentScore + '%'">
                                  <div class="item-progress-badge float-left">
                                    <p>{{ tGtask.assessmentScore }}%</p>
                                  </div>
                                </div>
                                <div class="item-progress-bar" [ngClass]="{'progress-bar-success': tGtask.assessmentScore >= 80, 'progress-bar-warning': tGtask.assessmentScore >= 50 && tGtask.assessmentScore < 80, 'progress-bar-danger' : tGtask.assessmentScore < 50}"
                                  role="progressbar" aria-valuenow="tGtask.assessmentScore" aria-valuemin="0" aria-valuemax="100"
                                  [style.width]="tGtask.assessmentScore + '%'">
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                        <div class="col-sm-9 float-right pr-4 pl-4">
                          <div class="pr-2 pl-2">
                            <div class="card p-2" (click)="$event.stopPropagation(); showCollapsePanel(tGtask.id, 'taskGroupTask')">
                              <div class="card-body pointer-cursor">
                                <div class="float-left mt-1 mb-1">
                                  <div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
                                  <div class="col-sm-12">{{ tGtask.text }}</div>
                                  <div class=" col-sm-12" *ngIf="tGtask.regulatoryList.length > 0 && (showTaskDetail == tGtask.id || expandAllTasks)">
                                    <span class="badge badge-custom mt-1 mr-1" *ngFor="let regulatory of tGtask.regulatoryList | orderBy : 'alphabetic'">
                                    {{ regulatory.text }}</span>
                                  </div>
                                  <div class="col-sm-12  mt-2 ml-2 float-left" *ngIf="tGtask.reasonNA && (showTaskDetail == tGtask.id || expandAllTasks)">
                                    <div class="alert alert-warning" role="alert">
                                      <strong>Reason for N/A: </strong>{{tGtask.reasonNA}}
                                    </div>
                                  </div>
                                </div>
                                <div class="float-right">
                                  <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == tGtask.id || expandAllTasks, 'fa-angle-down': showTaskDetail != tGtask.id && !expandAllTasks}"></i>
                                  </div>
                                  <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                    <h6 class="m-0" *ngIf="tGtask.criticalityValue == 1">
                                      <span class="badge badge-success">Low</span>
                                    </h6>
                                    <h6 class="m-0" *ngIf="tGtask.criticalityValue == 2">
                                      <span class="badge badge-warning">Medium</span>
                                    </h6>
                                    <h6 class="m-0" *ngIf="tGtask.criticalityValue == 3">
                                      <span class="badge badge-danger">High</span>
                                    </h6>
                                  </div>
                                  <div class="float-right mr-1 ml-1" *ngIf="tGtask.regulatoryList.length > 0">
                                    <label>
                                  <span class="badge badge-custom">
                                    <i class="fa fa-university" aria-hidden="true"></i>
                                    Regulatory Agency
                                  </span>
                                </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 mb-2" *ngIf="(showTaskDetail == tGtask.id || expandAllTasks) && ( tGtask.performanceList.length>0 || tGtask.knowledgeList.length>0 || tGtask.knowledgeQuestionList.length>0 || tGtask.remediationList.length>0 ) ">
                        <!-- <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3"> -->
                        <div class="row">
                          <div class="col-sm-3 float-left"></div>
                          <div class="col-sm-9 float-right">
                            <div class="col-sm-12 mx-auto mt-0 pr-4 pl-4">
                              <div class="card card-grey-lighten-4 p-2">
                                <div class="mt-2" *ngIf="tGtask.performanceList.length>0">
                                  <h6 class="mt-1 mb-1">Performance
                                    <span class="badge badge-warning">{{ tGtask.performanceList.length }}</span>
                                  </h6>
                                  <div *ngFor="let performance of tGtask.performanceList | orderBy : 'orderNum' ; let p = index ">
                                    <div class="col-sm-12 mx-auto mt-1">
                                      <div class="card p-2 mb-0 mt-1 bs-callout" [ngClass]="{'bs-callout-success card-correct': performance.answer == 3, 'bs-callout-danger card-incorrect': performance.answer == 2, 'bs-callout-default':  performance.answer == 1 }"
                                        (click)="$event.stopPropagation(); showCollapsePanel(performance.id, 'performance')">
                                        <div class="card-body pointer-cursor">
                                          <div class="col-sm-11 mt-1 mb-1 pl-0 pr-0 float-left">
                                            <label class="col-sm-12 mb-0 ">{{ performance.text }}</label>
                                            <div class="mt-2 ml-2" *ngIf="showPerformanceDetail == performance.id && (performance.assessmentNote!='' || performance.reasonNA)">
                                              <div *ngIf="performance.reasonNA">
                                                <div class="col-sm-12  mt-2 ">
                                                  <div class="alert alert-warning" role="alert">
                                                    <strong>Reason for N/A: </strong>{{performance.reasonNA}}
                                                  </div>
                                                </div>
                                              </div>
                                              <div *ngIf="performance.assessmentNote!=''">
                                                <form>
                                                  <div class="col-sm-12">
                                                    <textarea rows="3" type="text" class="form-control" id="performanceNote" name="performanceNote" [(ngModel)]="performance.assessmentNote"
                                                      (click)="$event.stopPropagation()" readonly></textarea>
                                                  </div>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                          <div *ngIf="performance.assessmentNote != '' || performance.checkList.length > 0 || performance.remediationList.length > 0 || performance.reasonNA"
                                            class="float-right">
                                            <div class="btn btn-sm border-0 mr-1 ml-1 p-0 ">
                                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- show performance detail -->
                                      <div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id && (performance.checkList.length>0 || performance.remediationList.length>0 ) ">
                                        <div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
                                          <div class="mt-2" *ngIf="performance.checkList.length>0">
                                            <h6 class="mt-1 mb-1">Check List
                                              <span class="badge badge-warning">{{ performance.checkList.length }}</span>
                                            </h6>
                                            <div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
                                              <div class="col-sm-12 mx-auto mt-1">
                                                <div class="card p-2 bs-callout " [ngClass]="{'bs-callout-success card-correct': checkList.answer == 3, 'bs-callout-danger card-incorrect': checkList.answer == 2, 'bs-callout-default':  checkList.answer == 1 }"
                                                  (click)="$event.stopPropagation(); showCollapsePanel(checkList.id, 'checkListRemediation')">
                                                  <div class="card-body pointer-cursor">
                                                    <div class="col-sm-11 float-left mt-1 mb-1">
                                                      <label class="col-sm-12 mb-0 ">{{ checkList.text }}</label>
                                                      <div class="mt-2" *ngIf="showCheckListDetail == checkList.id && checkList.reasonNA">
                                                        <div class="col-sm-12  mt-2 ml-2">
                                                          <div class="alert alert-warning" role="alert">
                                                            <strong>Reason for N/A: </strong>{{checkList.reasonNA}}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="checkList.remediationList.length>0 || checkList.reasonNA" class="float-right">
                                                      <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <!-- checkList remediation -->
                                                <div *ngIf="showCheckListDetail == checkList.id">
                                                  <div class="mt-2" *ngIf="checkList.remediationList.length>0">
                                                    <h6 class="mt-1 mb-1">Remediation
                                                      <span class="badge badge-warning">{{ checkList.remediationList.length }}
																									</span>
                                                    </h6>
                                                    <div *ngFor="let clremediation of checkList.remediationList | convert : 'convertRemediationLink'; let clr = index">
                                                      <div class="col-sm-12 mx-auto mt-1">
                                                        <div class="card p-2" (click)="$event.stopPropagation(); showCollapsePanel(clremediation.AssessmentchecklistRemId , 'checklistRemediationDetail', clremediation.Id)">
                                                          <div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail == clremediation.AssessmentchecklistRemId">
                                                            <div class="col-sm-12 pl-1 pr-1">
                                                              <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                                <span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}
																														</span>
                                                                <span class="badge badge-warning mr-1">{{ clremediation.Type }}
																														</span> {{ clremediation.Description
                                                                }}
                                                              </div>
                                                              <div class="float-right">
                                                                <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div *ngIf="clremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                              <b>Course Description :
																													</b> {{ clremediation.CourseDescription
                                                              }}
                                                            </div>
                                                            <div *ngIf="clremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                              <b>Course Number :
																													</b> {{ clremediation.CourseNumber
                                                              }}
                                                            </div>
                                                            <div *ngIf="clremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                              <b>Link :
																													</b>
                                                              <a target="_blank" href="{{clremediation.link}}">{{clremediation.Link}}</a>
                                                            </div>
                                                            <div *ngIf="clremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                              <label class="mb-0">
																														<b>Note : </b>
																													</label>
                                                              <div class="col-sm-12 mt-2 ml-2">
                                                                <form>
                                                                  <div class="form-group row">
                                                                    <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="clremediation.Note"
                                                                      (click)="$event.stopPropagation()" readonly></textarea>
                                                                  </div>
                                                                </form>
                                                              </div>
                                                            </div>
                                                            <div *ngIf="selectedChecklistRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                              <span class="badge badge-dark ml-1">{{selectedChecklistRemediationAttachment.length}}</span>
                                                            </div>

                                                            <br *ngIf="selectedChecklistRemediationAttachment.length > 0">
                                                            <div *ngFor="let item of selectedChecklistRemediationAttachment; let k = index ">
                                                              <ng-template [ngIf]="item.text == 'pdf' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'txt' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a> {{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'docx' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'doc' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'ppt' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'pptx' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'xls' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileNamet, item.id, 'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                              <ng-template [ngIf]="item.text == 'xlsx' ">
                                                                <label for="default" class="">
																															<a class="btn btn-sm edit">
																																<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																																aria-hidden="true"></i>
																															</a>
																															{{ item.fileName }}
																														</label>
                                                              </ng-template>
                                                            </div>
                                                          </div>
                                                          <div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail != clremediation.AssessmentchecklistRemId">
                                                            <div class="float-left">
                                                              <span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}
																													</span>
                                                              <span class="badge badge-warning mr-1">{{ clremediation.Type }}
																													</span> {{ clremediation.Description}}
                                                            </div>
                                                            <div class="float-right">
                                                              <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true}"></i>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                          </div>

                                          <!-- performance remediation -->
                                          <div class="mt-2 " *ngIf='performance.remediationList.length>0'>
                                            <h6 class="mt-1 mb-1 ">Remediation
                                              <span class="badge badge-warning">{{ performance.remediationList.length }}</span>
                                            </h6>
                                            <div *ngFor="let remediation of performance.remediationList | convert : 'convertRemediationLink'; let rem=i ndex ">
                                              <div class="col-sm-12 mx-auto mt-1 pointer-cursor">
                                                <div class="card p-2" (click)="$event.stopPropagation(); showCollapsePanel(remediation.AssessmentPerfRemId , 'performanceRemediationDetail', remediation.Id) ">
                                                  <div class="card-body pointer-cursor" *ngIf="showPerformanceRemediationDetail == remediation.AssessmentPerfRemId">
                                                    <div class="col-sm-12 pl-1 pr-1">
                                                      <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                        <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}
																										</span>
                                                        <span class="badge badge-warning mr-1">{{ remediation.Type }}</span>                                                        {{ remediation.Description }}
                                                      </div>
                                                      <div class="float-right">
                                                        <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                          <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div *ngIf="remediation.CourseDescription!=''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                      <b>Course Description : </b> {{ remediation.CourseDescription}}
                                                    </div>
                                                    <div *ngIf="remediation.CourseNumber!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <b>Course Number : </b> {{ remediation.CourseNumber}}
                                                    </div>
                                                    <div *ngIf="remediation.Link!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <b>Link : </b>
                                                      <a target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
                                                    </div>
                                                    <div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <label class="mb-0">
																										<b>Note : </b>
																									</label>
                                                      <div class="col-sm-12 mt-2 ml-2">
                                                        <form>
                                                          <div class="form-group row">
                                                            <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
                                                              (click)="$event.stopPropagation()" readonly></textarea>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="selectedPerformanceRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                      <span class="badge badge-dark mr-1">{{selectedPerformanceRemediationAttachment.length}}</span>
                                                    </div>

                                                    <br *ngIf="selectedPerformanceRemediationAttachment.length > 0">
                                                    <div *ngFor="let item of selectedPerformanceRemediationAttachment; let k = index ">
                                                      <ng-template [ngIf]="item.text == 'pdf' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'txt' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'docx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'doc' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'ppt' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'pptx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'xls' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileNamet, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'xlsx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation(); fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                    </div>
                                                  </div>
                                                  <div class="card-body " *ngIf="showPerformanceRemediationDetail != remediation.AssessmentPerfRemId">
                                                    <div class="float-left ">
                                                      <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                                      <span class="badge badge-warning mr-1">{{ remediation.Type }}</span>                                                      {{ remediation.Description }}
                                                    </div>
                                                    <div class="float-right">
                                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-2" *ngIf="tGtask.knowledgeList.length>0">
                                  <h6 class="mt-1 mb-1">Knowledge
                                    <span class="badge badge-warning">{{ tGtask.knowledgeList.length }}</span>
                                  </h6>
                                  <div *ngFor="let knowledge of tGtask.knowledgeList | orderBy : 'orderNum'; let k = index ">
                                    <div class="col-sm-12 mx-auto mt-2">
                                      <div class="card p-2 mt-1 mb-0 bs-callout" [ngClass]="{'bs-callout-default': knowledge.answer == 1, 'bs-callout-danger card-incorrect': knowledge.answer == 2, 'bs-callout-success card-correct':  knowledge.answer == 3 }"
                                        (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
                                        <div class="card-body pointer-cursor">
                                          <div class="col-sm-11 float-left mt-1 mb-1 pl-0 pr-0">
                                            <label class="mb-0">{{ knowledge.knowledge }}</label>
                                            <div class="mt-2 ml-2" *ngIf="showKnowledgeDetail == knowledge.id && (knowledge.assessmentNote!='' || knowledge.reasonNA)">
                                              <div *ngIf="knowledge.reasonNA">
                                                <div class="col-sm-12  mt-2 float-left">
                                                  <div class="alert alert-warning" role="alert">
                                                    <strong>Reason for N/A: </strong>{{knowledge.reasonNA}}
                                                  </div>
                                                </div>
                                              </div>
                                              <form *ngIf="knowledge.assessmentNote!=''">
                                                <div class="col-sm-12">
                                                  <textarea rows="3" type="text" class="form-control" id="knowledgeNote" name="knowledgeNote" [(ngModel)]="knowledge.assessmentNote"
                                                    (click)="$event.stopPropagation()" readonly></textarea>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                          <div *ngIf="knowledge.assessmentNote != ''  || knowledge.remediationList.length > 0 || knowledge.reasonNA" class="float-right">
                                            <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- knowledge remediation -->
                                      <div class="col-sm-12 mx-auto " *ngIf="showKnowledgeDetail == knowledge.id">
                                        <div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3 mt-2" *ngIf="knowledge.remediationList.length>0">
                                          <div class="mt-2">
                                            <h6 class="mt-1 mb-1">Remediation
                                              <span class="badge badge-warning">{{ knowledge.remediationList.length }}</span>
                                            </h6>
                                            <div *ngFor="let kremediation of knowledge.remediationList | convert : 'convertRemediationLink'; let kr = index">
                                              <div class="col-sm-12 mx-auto mt-1">
                                                <div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
                                                  <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
                                                    <div class="col-sm-12 pl-1 pr-1">
                                                      <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                        <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																										</span>
                                                        <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>                                                        {{ kremediation.Description }}
                                                      </div>
                                                      <div class="float-right">
                                                        <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                          <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div *ngIf="kremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                      <b>Course Description : </b> {{ kremediation.CourseDescription}}
                                                    </div>
                                                    <div *ngIf="kremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <b>Course Number : </b> {{ kremediation.CourseNumber }}
                                                    </div>
                                                    <div *ngIf="kremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <b>Link : </b>
                                                      <a target="_blank" href="{{kremediation.link}}">{{kremediation.Link}}</a>
                                                    </div>
                                                    <div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <label class="mb-0">
																										<b>Note : </b>
																									</label>
                                                      <div class="col-sm-12 mt-2 ml-2">
                                                        <form>
                                                          <div class="form-group row">
                                                            <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
                                                              (click)="$event.stopPropagation()" readonly></textarea>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                      <span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
                                                    </div>
                                                    <br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
                                                    <div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
                                                      <ng-template [ngIf]="item.text == 'pdf' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'txt' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'docx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'doc' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'ppt' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'pptx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'xls' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'xlsx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                    </div>
                                                  </div>
                                                  <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
                                                    <div class="float-left">
                                                      <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																									</span>
                                                      <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>                                                      {{ kremediation.Description }}
                                                    </div>
                                                    <div class="float-right">
                                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                                <div class="mt-2" *ngIf="tGtask.knowledgeQuestionList.length>0">
                                  <h6 class="mt-1 mb-1">Knowledge Question
                                    <span class="badge badge-warning">{{ tGtask.knowledgeQuestionList.length }}</span>
                                  </h6>
                                  <div *ngFor="let knowledge of tGtask.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
                                    <div class="col-sm-12 mx-auto mt-2">
                                      <div class="card p-2 mt-1 mb-0 bs-callout" [ngClass]="{'bs-callout-default': knowledge.answer == 1, 'bs-callout-danger card-incorrect': knowledge.answer == 2, 'bs-callout-success card-correct':  knowledge.answer == 3 }"
                                        (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
                                        <div class="card-body pointer-cursor">
                                          <div class="float-left mt-1 mb-1">
                                            <p class="mb-0">{{ knowledge.knowledge }}</p>
                                            <img *ngIf="knowledge.isImageAttached === 1" [src]="getImageURL(knowledge.id, knowledge.id, knowledge.fileType)" class="img-fluid mb-2 mt-2 img-ans-custom">
                                            <div *ngIf="showKnowledgeDetail == knowledge.id || expandAllTasks">
                                              <div class="col-sm-12 ml-6" *ngIf="knowledge.type == 'Boolean'">
                                                <div class="row pl-2 ml-2 mb-1">
                                                  <span class="p-2 badge " [ngClass]="{'badge-success-cus': knowledge.booleanAnswerCorrect == 1, 'badge-danger-cus': ( knowledge.booleanAnswerCorrect == 0 && knowledge.answer === 2 ) }">
																								<input disabled class="float-left" type="radio" [name]="knowledge.id" value="1" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)">
																							</span>
                                                  <p class="m-0 mt-1 ml-2 float-right">True</p>
                                                </div>
                                                <div class="row pl-2 ml-2 mt-1">
                                                  <span class="p-2 badge " [ngClass]="{'badge-danger-cus': (knowledge.booleanAnswerCorrect == 1  && knowledge.answer === 2), 'badge-success-cus': knowledge.booleanAnswerCorrect == 0}">
																								<input disabled class="float-left" type="radio" [name]="knowledge.id" value="0" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)">
																							</span>
                                                  <p class="m-0 mt-1 ml-2 float-right">False</p>
                                                </div>
                                              </div>
                                              <div class="col-sm-12 ml-6" *ngFor="let knowledgeAnswer of knowledge.answerList ; let n = index ">
                                                <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Single'">
                                                  <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': knowledgeAnswer.IsCorrect == 0, 'badge-success-cus': knowledgeAnswer.IsCorrect == 1 , 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																								<input disabled class="float-left" type="radio" [checked]="knowledgeAnswer.GivenAnswer === 1" [name]="knowledge.id" [value]="knowledgeAnswer.id">
																							</span>
                                                  <p *ngIf="knowledgeAnswer.text" class="m-0 ml-2 mr-2 float-right">{{knowledgeAnswer.text}}</p>
                                                  <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                                                </div>
                                                <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Multiple'">
                                                  <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': knowledgeAnswer.IsCorrect == 0, 'badge-success-cus': knowledgeAnswer.IsCorrect == 1, 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																								<input disabled class="float-left" type="checkbox" [name]="knowledge.Id" [value]="knowledgeAnswer.id" [(ngModel)]="knowledgeAnswer.GivenAnswer">
																							</span>
                                                  <p *ngIf="knowledgeAnswer.text" class="m-0 mt-1 ml-2 float-right">{{knowledgeAnswer.text}}</p>
                                                  <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div *ngIf="knowledge.remediationList.length>0 || knowledge.answerList.length > 0 || knowledge.type == 'Boolean' " class="float-right">
                                            <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id || expandAllTasks, 'fa-angle-down': showKnowledgeDetail != knowledge.id && !expandAllTasks }"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- knowledge remediation -->
                                      <div class="col-sm-12 mx-auto " *ngIf="showKnowledgeDetail == knowledge.id || expandAllTasks">
                                        <div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3 mt-2" *ngIf="knowledge.remediationList.length>0">
                                          <div class="mt-2">
                                            <h6 class="mt-1 mb-1">Remediation
                                              <span class="badge badge-warning">{{ knowledge.remediationList.length }}</span>
                                            </h6>
                                            <div *ngFor="let kremediation of knowledge.remediationList | convert : 'convertRemediationLink'; let kr = index">
                                              <div class="col-sm-12 mx-auto mt-1">
                                                <div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
                                                  <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
                                                    <div class="col-sm-12 pl-1 pr-1">
                                                      <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                        <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																										</span>
                                                        <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>                                                        {{ kremediation.Description }}
                                                      </div>
                                                      <div class="float-right">
                                                        <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                          <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div *ngIf="kremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                      <b>Course Description : </b> {{ kremediation.CourseDescription}}
                                                    </div>
                                                    <div *ngIf="kremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <b>Course Number : </b> {{ kremediation.CourseNumber }}
                                                    </div>
                                                    <div *ngIf="kremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <b>Link : </b>
                                                      <a target="_blank" href="{{kremediation.link}}">{{kremediation.Link}}</a>
                                                    </div>
                                                    <div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                      <label class="mb-0">
																										<b>Note : </b>
																									</label>
                                                      <div class="col-sm-12 mt-2 ml-2">
                                                        <form>
                                                          <div class="form-group row">
                                                            <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
                                                              (click)="$event.stopPropagation()" readonly></textarea>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </div>
                                                    <div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                      <span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
                                                    </div>
                                                    <br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
                                                    <div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
                                                      <ng-template [ngIf]="item.text == 'pdf' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'txt' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'docx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'doc' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'ppt' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'pptx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'xls' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                      <ng-template [ngIf]="item.text == 'xlsx' ">
                                                        <label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																												aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }}
																										</label>
                                                      </ng-template>
                                                    </div>
                                                  </div>
                                                  <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
                                                    <div class="float-left">
                                                      <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																									</span>
                                                      <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>                                                      {{ kremediation.Description }}
                                                    </div>
                                                    <div class="float-right">
                                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                                <!-- task remediation -->
                                <div class="mt-2" *ngIf="tGtask.remediationList.length>0">
                                  <h6 class="mt-1 mb-1">Remediation
                                    <span class="badge badge-warning">{{ tGtask.remediationList.length }}</span>
                                  </h6>
                                  <div *ngFor="let remediation of tGtask.remediationList | convert : 'convertRemediationLink'; let tr = index ">
                                    <div class="col-sm-12 mx-auto mt-1">
                                      <div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentTaskRemId , 'taskRemediationDetail', remediation.Id)">
                                        <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail == remediation.AssessmentTaskRemId">
                                          <div class="col-sm-12 pl-1 pr-1">
                                            <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                              <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                              <span class="badge badge-warning mr-1">{{ remediation.Type }} </span> {{ remediation.Description
                                              }}
                                            </div>
                                            <div class="float-right">
                                              <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                              </div>
                                            </div>
                                          </div>

                                          <div *ngIf="remediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                            <b>Course Description : </b> {{ remediation.CourseDescription }}
                                          </div>
                                          <div *ngIf="remediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                            <b>Course Number : </b> {{ remediation.CourseNumber }}
                                          </div>
                                          <div *ngIf="remediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                            <b>Link : </b>
                                            <a target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
                                          </div>
                                          <div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                            <label class="mb-0">
																					<b>Note : </b>
																				</label>
                                            <div class="col-sm-12 mt-2 ml-2">
                                              <form>
                                                <div class="form-group row">
                                                  <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
                                                    (click)="$event.stopPropagation()" readonly></textarea>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                          <div *ngIf="selectedTaskRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                            <span class="badge badge-dark mr-1">{{selectedTaskRemediationAttachment.length}}</span>
                                          </div>

                                          <br *ngIf="selectedTaskRemediationAttachment.length > 0">
                                          <div *ngFor="let item of selectedTaskRemediationAttachment; let k = index ">
                                            <ng-template [ngIf]="item.text == 'pdf' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'txt' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'docx' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'doc' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'ppt' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'pptx' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'xls' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                            <ng-template [ngIf]="item.text == 'xlsx' ">
                                              <label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																							aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }}
																					</label>
                                            </ng-template>
                                          </div>
                                        </div>
                                        <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail != remediation.AssessmentTaskRemId">
                                          <div class="float-left">
                                            <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                            <span class="badge badge-warning mr-1">{{ remediation.Type }}</span> {{ remediation.Description
                                            }}
                                          </div>
                                          <div class="float-right">
                                            <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="mt-2" *ngIf="tGtask.documentList.length>0">
                                  <h6 class="mt-1 mb-1">Document
                                    <span class="badge badge-warning">{{ tGtask.documentList.length }}</span>
                                  </h6>
                                  <div *ngFor="let document of tGtask.documentList | orderBy : 'fileName' ; let m = index">
                                    <div class="col-sm-12 mx-auto mt-1 mb-1">
                                      <div class="card p-2 " (click)="showCollapsePanel(document.id, 'document')">
                                        <div class="card-body row pointer-cursor">
                                          <div class="col-sm-9 mt-1 mb-1">
                                            <div class="mt-1 mb-1">
                                              <span class="badge badge-info mr-1">{{ document.fileName }}</span>
                                            </div>
                                            <div class="mt-1 mb-1" *ngIf="showDocumentDetail == document.id">
                                              <div>
                                                <b>Category : </b>{{document.category}}</div>
                                              <div *ngIf="document.note != null">
                                                <b>Note : </b>{{document.note}}</div>
                                            </div>
                                          </div>
                                          <div class="col-sm-3">
                                            <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showDocumentDetail == document.id, 'fa-angle-down': showDocumentDetail != document.id }"></i>
                                            </div>
                                            <div class="float-right" *ngIf="showDocumentDetail == document.id">
                                              <a *ngIf="document.ext == 'pdf'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																						<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
																					</a>
                                              <a *ngIf="document.ext == 'txt'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																						<i class="fa fa-file-text-o fa-lg" aria-hidden="true"></i>
																					</a>
                                              <a *ngIf="document.ext == 'doc' || document.ext == 'docx'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																						<i class="fa fa-file-word-o fa-lg" aria-hidden="true"></i>
																					</a>
                                              <a *ngIf="document.ext == 'ppt' || document.ext == 'pptx'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																						<i class="fa fa-file-powerpoint-o fa-lg" aria-hidden="true"></i>
																					</a>
                                              <a *ngIf="document.ext == 'xls' || document.ext == 'xlsx'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																						<i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i>
																					</a>
                                              <a *ngIf="document.ext == 'png' || document.ext == 'jpg' || document.ext == 'jpeg' || document.ext == 'bmp' || document.ext == 'tiff' || document.ext == 'gif'"
                                                class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																						<i class="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
																					</a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #elseTemplate>
                <div class="row">
                  <div class="col-sm-3 float-left pl-2">
                    <div class="card pl-0 pr-3 pt-3 pb-1 card-empty">
                      <ng-container *ngIf="task.isNA; else taskElseTemplate">
                        <div class="item-progress">
                          <div class="item-progress-badge">
                            <p>NA</p>
                          </div>
                          <div class="item-progress-bar" role="progressbar" style="width:100%">
                          </div>
                        </div>
                      </ng-container>
                      <ng-template #taskElseTemplate>
                        <div class="item-progress">
                          <div *ngIf="task.assessmentScore < 90 && task.assessmentScore > 10" class="item-pointer item-pointer-cursor" [style.left]="task.assessmentScore + '%'">
                            <div class="item-progress-badge">
                              <p>{{ task.assessmentScore }}%</p>
                            </div>
                          </div>
                          <div *ngIf="task.assessmentScore == 100" class="item-pointer item-pointer-cursor" [style.left]="99.5 + '%'">
                            <div class="item-progress-badge float-right">
                              <p>{{ task.assessmentScore }}%</p>
                            </div>
                          </div>
                          <div *ngIf="task.assessmentScore >= 90 && task.assessmentScore != 100" class="item-pointer item-pointer-cursor" [style.left]="task.assessmentScore + '%'">
                            <div class="item-progress-badge float-right">
                              <p>{{ task.assessmentScore }}%</p>
                            </div>
                          </div>
                          <div *ngIf="task.assessmentScore <= 10" class="item-pointer item-pointer-cursor" [style.left]="task.assessmentScore + '%'">
                            <div class="item-progress-badge float-left">
                              <p>{{ task.assessmentScore }}%</p>
                            </div>
                          </div>
                          <div class="item-progress-bar" [ngClass]="{'progress-bar-success': task.assessmentScore >= 80, 'progress-bar-warning': task.assessmentScore >= 50 && task.assessmentScore < 80, 'progress-bar-danger' : task.assessmentScore < 50}"
                            role="progressbar" aria-valuenow="task.assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="task.assessmentScore + '%'">
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="col-sm-9 float-right pr-4 pl-4">
                    <div class="card p-2" (click)="showCollapsePanel(task.id, 'task')">
                      <div class="card-body pointer-cursor">
                        <div class="float-left mt-1 mb-1">
                          <div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
                          <div class="col-sm-12">{{ task.text }}</div>
                          <div class="col-sm-12" *ngIf="task.regulatoryList.length > 0 && (showTaskDetail == task.id || expandAllTasks)">
                            <span class="badge badge-custom mt-1 mr-1" *ngFor="let regulatory of task.regulatoryList | orderBy : 'alphabetic'">
                            {{ regulatory.text }}</span>
                          </div>
                          <div class="mt-2" *ngIf="(showTaskDetail == task.id || expandAllTasks) && task.reasonNA">
                            <div class="col-sm-12 ml-2 mt-2 float-left">
                              <div class="alert alert-warning" role="alert">
                                <strong>Reason for N/A: </strong>{{task.reasonNA}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="float-right">
                          <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                            <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id || expandAllTasks, 'fa-angle-down': showTaskDetail != task.id && !expandAllTasks}"></i>
                          </div>
                          <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                            <h6 class="m-0" *ngIf="task.criticalityValue == 1">
                              <span class="badge badge-success">Low</span>
                            </h6>
                            <h6 class="m-0" *ngIf="task.criticalityValue == 2">
                              <span class="badge badge-warning">Medium</span>
                            </h6>
                            <h6 class="m-0" *ngIf="task.criticalityValue == 3">
                              <span class="badge badge-danger">High</span>
                            </h6>
                          </div>
                          <div class="float-right mr-1 ml-1" *ngIf="task.regulatoryList.length > 0">
                            <label>
                          <span class="badge badge-custom">
                            <i class="fa fa-university" aria-hidden="true"></i>
                            Regulatory Agency
                          </span>
                        </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 mb-2 pl-3" *ngIf="(showTaskDetail == task.id || expandAllTasks)   && ( task.performanceList.length>0 || task.knowledgeList.length>0 || task.knowledgeQuestionList.length>0 || task.remediationList.length>0 ) ">
                  <!-- <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3"> -->
                  <div class="row">
                    <div class="col-sm-3 float-left"></div>
                    <div class="col-sm-9 float-right">
                      <div class="col-sm-12 mx-auto mt-1 pr-2 pl-3 mb-2">
                        <div class="card card-grey-lighten-4 p-2">
                          <div class="mt-2" *ngIf="task.performanceList.length>0">
                            <h6 class="mt-1 mb-1">Performance
                              <span class="badge badge-warning">{{ task.performanceList.length }}</span>
                            </h6>
                            <div *ngFor="let performance of task.performanceList | orderBy : 'orderNum' ; let p = index ">
                              <div class="col-sm-12 mx-auto mt-1">
                                <div class="card p-2 mb-0 mt-1 bs-callout" [ngClass]="{'bs-callout-success card-correct': performance.answer == 3, 'bs-callout-danger card-incorrect': performance.answer == 2, 'bs-callout-default':  performance.answer == 1 }"
                                  (click)="showCollapsePanel(performance.id, 'performance')">
                                  <div class="card-body pointer-cursor">
                                    <div class="col-sm-11 float-left mt-1 mb-1 pl-0 pr-0">
                                      <label class="mb-0">{{ performance.text }}</label>
                                      <div class="mt-2 ml-2" *ngIf="showPerformanceDetail == performance.id && (performance.assessmentNote!='' || performance.reasonNA)">
                                        <div *ngIf="performance.reasonNA">
                                          <div class="col-sm-12  mt-2 float-left">
                                            <div class="alert alert-warning" role="alert">
                                              <strong>Reason for N/A: </strong>{{performance.reasonNA}}
                                            </div>
                                          </div>
                                        </div>
                                        <form *ngIf="performance.assessmentNote!=''">
                                          <div class="col-sm-12">
                                            <textarea rows="3" type="text" class="form-control" id="performanceNote" name="performanceNote" [(ngModel)]="performance.assessmentNote"
                                              (click)="$event.stopPropagation()" readonly></textarea>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <div *ngIf="performance.assessmentNote != '' || performance.checkList.length > 0 || performance.remediationList.length > 0 || performance.reasonNA"
                                      class="float-right">
                                      <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <!-- show performance detail -->
                                <div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id && (performance.checkList.length>0 || performance.remediationList.length>0 ) ">
                                  <div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
                                    <div class="mt-2" *ngIf="performance.checkList.length>0">
                                      <h6 class="mt-1 mb-1">Check List
                                        <span class="badge badge-warning">{{ performance.checkList.length }}</span>
                                      </h6>
                                      <div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
                                        <div class="col-sm-12 mx-auto mt-1">
                                          <div class="card p-2 bs-callout " [ngClass]="{'bs-callout-success card-correct': checkList.answer == 3, 'bs-callout-danger card-incorrect': checkList.answer == 2, 'bs-callout-default':  checkList.answer == 1 }"
                                            (click)="showCollapsePanel(checkList.id, 'checkListRemediation')">
                                            <div class="card-body pointer-cursor">
                                              <div class="float-left mt-1 mb-1">
                                                <div class="col-sm-12">{{ checkList.text }}</div>
                                                <div class="mt-2" *ngIf="showCheckListDetail == checkList.id && checkList.reasonNA">
                                                  <div class="col-sm-12 ml-2 mt-2">
                                                    <div class="alert alert-warning" role="alert">
                                                      <strong>Reason for N/A: </strong>{{checkList.reasonNA}}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div *ngIf="checkList.remediationList.length>0 || checkList.reasonNA" class="float-right">
                                                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <!-- checkList remediation -->
                                          <div *ngIf="showCheckListDetail == checkList.id">
                                            <div class="mt-2" *ngIf="checkList.remediationList.length>0">
                                              <h6 class="mt-1 mb-1">Remediation
                                                <span class="badge badge-warning">{{ checkList.remediationList.length }}
																						</span>
                                              </h6>
                                              <div *ngFor="let clremediation of checkList.remediationList | convert : 'convertRemediationLink'; let clr = index">
                                                <div class="col-sm-12 mx-auto mt-1">
                                                  <div class="card p-2" (click)="showCollapsePanel(clremediation.AssessmentchecklistRemId , 'checklistRemediationDetail', clremediation.Id)">
                                                    <div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail == clremediation.AssessmentchecklistRemId">
                                                      <div class="col-sm-12 pl-1 pr-1">
                                                        <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                          <span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}
																											</span>
                                                          <span class="badge badge-warning mr-1">{{ clremediation.Type }}
																											</span> {{ clremediation.Description
                                                          }}
                                                        </div>
                                                        <div class="float-right">
                                                          <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                            <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div *ngIf="clremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                        <b>Course Description :
																										</b> {{ clremediation.CourseDescription
                                                        }}
                                                      </div>
                                                      <div *ngIf="clremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                        <b>Course Number :
																										</b> {{ clremediation.CourseNumber }}
                                                      </div>
                                                      <div *ngIf="clremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                        <b>Link :
																										</b>
                                                        <a target="_blank" href="{{clremediation.link}}">{{clremediation.Link}}</a>
                                                      </div>
                                                      <div *ngIf="clremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                        <label class="mb-0">
																											<b>Note : </b>
																										</label>
                                                        <div class="col-sm-12 mt-2 ml-2">
                                                          <form>
                                                            <div class="form-group row">
                                                              <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="clremediation.Note"
                                                                (click)="$event.stopPropagation()" readonly></textarea>
                                                            </div>
                                                          </form>
                                                        </div>
                                                      </div>
                                                      <div *ngIf="selectedChecklistRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                        <span class="badge badge-dark ml-1">{{selectedChecklistRemediationAttachment.length}}</span>
                                                      </div>

                                                      <br *ngIf="selectedChecklistRemediationAttachment.length > 0">
                                                      <div *ngFor="let item of selectedChecklistRemediationAttachment; let k = index ">
                                                        <ng-template [ngIf]="item.text == 'pdf' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'txt' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a> {{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'docx' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'doc' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'ppt' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'pptx' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'xls' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                        <ng-template [ngIf]="item.text == 'xlsx' ">
                                                          <label for="default" class="">
																												<a class="btn btn-sm edit">
																													<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																													 aria-hidden="true"></i>
																												</a>
																												{{ item.fileName }}
																											</label>
                                                        </ng-template>
                                                      </div>
                                                    </div>
                                                    <div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail != clremediation.AssessmentchecklistRemId">
                                                      <div class="float-left">
                                                        <span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}
																										</span>
                                                        <span class="badge badge-warning mr-1">{{ clremediation.Type }}
																										</span> {{ clremediation.Description}}
                                                      </div>
                                                      <div class="float-right">
                                                        <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                          <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true}"></i>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>

                                    <!-- performance remediation -->
                                    <div class="mt-2 " *ngIf='performance.remediationList.length>0'>
                                      <h6 class="mt-1 mb-1 ">Remediation
                                        <span class="badge badge-warning">{{ performance.remediationList.length }}</span>
                                      </h6>
                                      <div *ngFor="let remediation of performance.remediationList | convert : 'convertRemediationLink'; let rem=i ndex ">
                                        <div class="col-sm-12 mx-auto mt-1 pointer-cursor">
                                          <div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentPerfRemId , 'performanceRemediationDetail', remediation.Id) ">
                                            <div class="card-body pointer-cursor" *ngIf="showPerformanceRemediationDetail == remediation.AssessmentPerfRemId">
                                              <div class="col-sm-12 pl-1 pr-1">
                                                <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                  <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}
																							</span>
                                                  <span class="badge badge-warning mr-1">{{ remediation.Type }}</span> {{
                                                  remediation.Description }}
                                                </div>
                                                <div class="float-right">
                                                  <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                  </div>
                                                </div>
                                              </div>

                                              <div *ngIf="remediation.CourseDescription!=''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                <b>Course Description : </b> {{ remediation.CourseDescription}}
                                              </div>
                                              <div *ngIf="remediation.CourseNumber!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <b>Course Number : </b> {{ remediation.CourseNumber}}
                                              </div>
                                              <div *ngIf="remediation.Link!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <b>Link : </b>
                                                <a target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
                                              </div>
                                              <div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <label class="mb-0">
																							<b>Note : </b>
																						</label>
                                                <div class="col-sm-12 mt-2 ml-2">
                                                  <form>
                                                    <div class="form-group row">
                                                      <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
                                                        (click)="$event.stopPropagation()" readonly></textarea>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                              <div *ngIf="selectedPerformanceRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                <span class="badge badge-dark mr-1">{{selectedPerformanceRemediationAttachment.length}}</span>
                                              </div>

                                              <br *ngIf="selectedPerformanceRemediationAttachment.length > 0">
                                              <div *ngFor="let item of selectedPerformanceRemediationAttachment; let k = index ">
                                                <ng-template [ngIf]="item.text == 'pdf' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'txt' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'docx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'doc' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'ppt' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'pptx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'xls' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'xlsx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                              </div>
                                            </div>
                                            <div class="card-body " *ngIf="showPerformanceRemediationDetail != remediation.AssessmentPerfRemId">
                                              <div class="float-left ">
                                                <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                                <span class="badge badge-warning mr-1">{{ remediation.Type }}</span> {{ remediation.Description
                                                }}
                                              </div>
                                              <div class="float-right">
                                                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-2" *ngIf="task.knowledgeList.length>0">
                            <h6 class="mt-1 mb-1">Knowledge
                              <span class="badge badge-warning">{{ task.knowledgeList.length }}</span>
                            </h6>
                            <div *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
                              <div class="col-sm-12 mx-auto mt-2">
                                <div class="card p-2 mt-1 mb-0 bs-callout" [ngClass]="{'bs-callout-default': knowledge.answer == 1, 'bs-callout-danger card-incorrect': knowledge.answer == 2, 'bs-callout-success card-correct':  knowledge.answer == 3 }"
                                  (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
                                  <div class="card-body pointer-cursor">
                                    <div class="col-sm-11 float-left mt-1 mb-1 pl-0 pr-0">
                                      <label class="mb-0">{{ knowledge.knowledge }}</label>
                                      <div class="mt-2 ml-2" *ngIf="showKnowledgeDetail == knowledge.id && (knowledge.assessmentNote!='' || knowledge.reasonNA)">
                                        <div *ngIf="knowledge.reasonNA">
                                          <div class="col-sm-12 mt-2">
                                            <div class="alert alert-warning" role="alert">
                                              <strong>Reason for N/A: </strong>{{knowledge.reasonNA}}
                                            </div>
                                          </div>
                                        </div>
                                        <form *ngIf="knowledge.assessmentNote!=''">
                                          <div class="col-sm-12">
                                            <textarea rows="3" type="text" class="form-control" id="knowledgeNote" name="knowledgeNote" [(ngModel)]="knowledge.assessmentNote"
                                              (click)="$event.stopPropagation()" readonly></textarea>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <div *ngIf="knowledge.assessmentNote != '' || knowledge.remediationList.length > 0 || knowledge.reasonNA" class="float-right">
                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- knowledge remediation -->
                                <div class="col-sm-12 mx-auto " *ngIf="showKnowledgeDetail == knowledge.id">
                                  <div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3 mt-2" *ngIf="knowledge.remediationList.length>0">
                                    <div class="mt-2">
                                      <h6 class="mt-1 mb-1">Remediation
                                        <span class="badge badge-warning">{{ knowledge.remediationList.length }}</span>
                                      </h6>
                                      <div *ngFor="let kremediation of knowledge.remediationList | convert : 'convertRemediationLink'; let kr = index">
                                        <div class="col-sm-12 mx-auto mt-1">
                                          <div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
                                            <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
                                              <div class="col-sm-12 pl-1 pr-1">
                                                <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                  <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																							</span>
                                                  <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{
                                                  kremediation.Description }}
                                                </div>
                                                <div class="float-right">
                                                  <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                  </div>
                                                </div>
                                              </div>

                                              <div *ngIf="kremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                <b>Course Description : </b> {{ kremediation.CourseDescription}}
                                              </div>
                                              <div *ngIf="kremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <b>Course Number : </b> {{ kremediation.CourseNumber }}
                                              </div>
                                              <div *ngIf="kremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <b>Link : </b>
                                                <a target="_blank" href="{{kremediation.link}}">{{kremediation.Link}}</a>
                                              </div>
                                              <div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <label class="mb-0">
																							<b>Note : </b>
																						</label>
                                                <div class="col-sm-12 mt-2 ml-2">
                                                  <form>
                                                    <div class="form-group row">
                                                      <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
                                                        (click)="$event.stopPropagation()" readonly></textarea>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                              <div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                <span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
                                              </div>
                                              <br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
                                              <div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
                                                <ng-template [ngIf]="item.text == 'pdf' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'txt' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'docx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'doc' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'ppt' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'pptx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'xls' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'xlsx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                              </div>
                                            </div>
                                            <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
                                              <div class="float-left">
                                                <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																						</span>
                                                <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{
                                                kremediation.Description }}
                                              </div>
                                              <div class="float-right">
                                                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div class="mt-2" *ngIf="task.knowledgeQuestionList.length>0">
                            <h6 class="mt-1 mb-1">Knowledge Question
                              <span class="badge badge-warning">{{ task.knowledgeQuestionList.length }}</span>
                            </h6>
                            <div *ngFor="let knowledge of task.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
                              <div class="col-sm-12 mx-auto mt-2">
                                <div class="card p-2 mt-1 mb-0 bs-callout" [ngClass]="{'bs-callout-default': knowledge.answer == 1, 'bs-callout-danger card-incorrect': knowledge.answer == 2, 'bs-callout-success card-correct':  knowledge.answer == 3 }"
                                  (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
                                  <div class="card-body pointer-cursor">
                                    <div class="float-left mt-1 mb-1">
                                      <p class="mb-0">{{ knowledge.knowledge }}</p>
                                      <img *ngIf="knowledge.isImageAttached === 1" [src]="getImageURL(knowledge.id, knowledge.id, knowledge.fileType)" class="img-fluid mb-2 mt-2 img-ans-custom">
                                      <div *ngIf="showKnowledgeDetail == knowledge.id || expandAllTasks">
                                        <div class="col-sm-12 ml-6" *ngIf="knowledge.type == 'Boolean'">
                                          <div class="row pl-2 ml-2 mb-1">
                                            <span class="p-2 badge " [ngClass]="{'badge-success-cus': knowledge.booleanAnswerCorrect == 1, 'badge-danger-cus': ( knowledge.booleanAnswerCorrect == 0 && knowledge.answer === 2 ) }">
																					<input disabled class="float-left" type="radio" [name]="knowledge.id" value="1" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)">
																				</span>
                                            <p class="m-0 mt-1 ml-2 float-right">True</p>
                                          </div>
                                          <div class="row pl-2 ml-2 mt-1">
                                            <span class="p-2 badge " [ngClass]="{'badge-danger-cus': (knowledge.booleanAnswerCorrect == 1  && knowledge.answer === 2), 'badge-success-cus': knowledge.booleanAnswerCorrect == 0}">
																					<input disabled class="float-left" type="radio" [name]="knowledge.id" value="0" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)">
																				</span>
                                            <p class="m-0 mt-1 ml-2 float-right">False</p>
                                          </div>
                                        </div>
                                        <div class="col-sm-12 ml-6" *ngFor="let knowledgeAnswer of knowledge.answerList ; let n = index ">
                                          <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Single'">
                                            <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': knowledgeAnswer.IsCorrect == 0, 'badge-success-cus': knowledgeAnswer.IsCorrect == 1 , 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																					<input disabled class="float-left" type="radio" [checked]="knowledgeAnswer.GivenAnswer === 1" [name]="knowledge.id" [value]="knowledgeAnswer.id">
																				</span>
                                            <p *ngIf="knowledgeAnswer.text" class="m-0 ml-2 mr-2 float-right">{{knowledgeAnswer.text}}</p>
                                            <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                                          </div>
                                          <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Multiple'">
                                            <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': knowledgeAnswer.IsCorrect == 0, 'badge-success-cus': knowledgeAnswer.IsCorrect == 1, 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																					<input disabled class="float-left" type="checkbox" [name]="knowledge.Id" [value]="knowledgeAnswer.id" [(ngModel)]="knowledgeAnswer.GivenAnswer">
																				</span>
                                            <p *ngIf="knowledgeAnswer.text" class="m-0 mt-1 ml-2 float-right">{{knowledgeAnswer.text}}</p>
                                            <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div *ngIf="knowledge.remediationList.length>0 || knowledge.answerList.length > 0 || knowledge.type == 'Boolean' " class="float-right">
                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id || expandAllTasks, 'fa-angle-down': showKnowledgeDetail != knowledge.id && !expandAllTasks}"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- knowledge remediation -->
                                <div class="col-sm-12 mx-auto " *ngIf="showKnowledgeDetail == knowledge.id || expandAllTasks">
                                  <div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3 mt-2" *ngIf="knowledge.remediationList.length>0">
                                    <div class="mt-2">
                                      <h6 class="mt-1 mb-1">Remediation
                                        <span class="badge badge-warning">{{ knowledge.remediationList.length }}</span>
                                      </h6>
                                      <div *ngFor="let kremediation of knowledge.remediationList | convert : 'convertRemediationLink'; let kr = index">
                                        <div class="col-sm-12 mx-auto mt-1">
                                          <div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
                                            <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
                                              <div class="col-sm-12 pl-1 pr-1">
                                                <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                                  <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																							</span>
                                                  <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{
                                                  kremediation.Description }}
                                                </div>
                                                <div class="float-right">
                                                  <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                                  </div>
                                                </div>
                                              </div>

                                              <div *ngIf="kremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                                <b>Course Description : </b> {{ kremediation.CourseDescription}}
                                              </div>
                                              <div *ngIf="kremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <b>Course Number : </b> {{ kremediation.CourseNumber }}
                                              </div>
                                              <div *ngIf="kremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <b>Link : </b>
                                                <a target="_blank" href="{{kremediation.link}}">{{kremediation.Link}}</a>
                                              </div>
                                              <div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                                <label class="mb-0">
																							<b>Note : </b>
																						</label>
                                                <div class="col-sm-12 mt-2 ml-2">
                                                  <form>
                                                    <div class="form-group row">
                                                      <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
                                                        (click)="$event.stopPropagation()" readonly></textarea>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                              <div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                                <span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
                                              </div>
                                              <br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
                                              <div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
                                                <ng-template [ngIf]="item.text == 'pdf' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'txt' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'docx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'doc' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'ppt' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'pptx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'xls' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                                <ng-template [ngIf]="item.text == 'xlsx' ">
                                                  <label for="default" class="">
																								<a class="btn btn-sm edit">
																									<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																									 aria-hidden="true"></i>
																								</a>
																								{{ item.fileName }}
																							</label>
                                                </ng-template>
                                              </div>
                                            </div>
                                            <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
                                              <div class="float-left">
                                                <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}
																						</span>
                                                <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{
                                                kremediation.Description }}
                                              </div>
                                              <div class="float-right">
                                                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <!-- task remediation -->
                          <div class="mt-2" *ngIf="task.remediationList.length>0">
                            <h6 class="mt-1 mb-1">Remediation
                              <span class="badge badge-warning">{{ task.remediationList.length }}</span>
                            </h6>
                            <div *ngFor="let remediation of task.remediationList | convert : 'convertRemediationLink'; let tr = index ">
                              <div class="col-sm-12 mx-auto mt-1">
                                <div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentTaskRemId , 'taskRemediationDetail', remediation.Id)">
                                  <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail == remediation.AssessmentTaskRemId">
                                    <div class="col-sm-12 pl-1 pr-1">
                                      <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                        <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                        <span class="badge badge-warning mr-1">{{ remediation.Type }} </span> {{ remediation.Description
                                        }}
                                      </div>
                                      <div class="float-right">
                                        <div class="btn btn-sm border-0 mr-1 ml-1 p-0">
                                          <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                        </div>
                                      </div>
                                    </div>

                                    <div *ngIf="remediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
                                      <b>Course Description : </b> {{ remediation.CourseDescription }}
                                    </div>
                                    <div *ngIf="remediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                      <b>Course Number : </b> {{ remediation.CourseNumber }}
                                    </div>
                                    <div *ngIf="remediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                      <b>Link : </b>
                                      <a target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
                                    </div>
                                    <div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                      <label class="mb-0">
																		<b>Note : </b>
																	</label>
                                      <div class="col-sm-12 mt-2 ml-2">
                                        <form>
                                          <div class="form-group row">
                                            <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
                                              (click)="$event.stopPropagation()" readonly></textarea>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <div *ngIf="selectedTaskRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                      <span class="badge badge-dark mr-1">{{selectedTaskRemediationAttachment.length}}</span>
                                    </div>

                                    <br *ngIf="selectedTaskRemediationAttachment.length > 0">
                                    <div *ngFor="let item of selectedTaskRemediationAttachment; let k = index ">
                                      <ng-template [ngIf]="item.text == 'pdf' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'txt' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'docx' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'doc' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'ppt' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'pptx' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'xls' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                      <ng-template [ngIf]="item.text == 'xlsx' ">
                                        <label for="default" class="">
																			<a class="btn btn-sm edit">
																				<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																				 aria-hidden="true"></i>
																			</a>
																			{{ item.fileName }}
																		</label>
                                      </ng-template>
                                    </div>
                                  </div>
                                  <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail != remediation.AssessmentTaskRemId">
                                    <div class="float-left">
                                      <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                      <span class="badge badge-warning mr-1">{{ remediation.Type }}</span> {{ remediation.Description
                                      }}
                                    </div>
                                    <div class="float-right">
                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mt-2" *ngIf="task.documentList.length>0">
                            <h6 class="mt-1 mb-1">Document
                              <span class="badge badge-warning">{{ task.documentList.length }}</span>
                            </h6>
                            <div *ngFor="let document of task.documentList | orderBy : 'fileName' ; let m = index">
                              <div class="col-sm-12 mx-auto mt-1 mb-1">
                                <div class="card p-2 " (click)="showCollapsePanel(document.id, 'document')">
                                  <div class="card-body row pointer-cursor">
                                    <div class="col-sm-9 mt-1 mb-1">
                                      <div class="mt-1 mb-1">
                                        <span class="badge badge-info mr-1">{{ document.fileName }}</span>
                                      </div>
                                      <div class="mt-1 mb-1" *ngIf="showDocumentDetail == document.id">
                                        <div>
                                          <b>Category : </b>{{document.category}}</div>
                                        <div *ngIf="document.note != null">
                                          <b>Note : </b>{{document.note}}</div>
                                      </div>
                                    </div>
                                    <div class="col-sm-3">
                                      <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showDocumentDetail == document.id, 'fa-angle-down': showDocumentDetail != document.id }"></i>
                                      </div>
                                      <div class="float-right" *ngIf="showDocumentDetail == document.id">
                                        <a *ngIf="document.ext == 'pdf'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																			<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
																		</a>
                                        <a *ngIf="document.ext == 'txt'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																			<i class="fa fa-file-text-o fa-lg" aria-hidden="true"></i>
																		</a>
                                        <a *ngIf="document.ext == 'doc' || document.ext == 'docx'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																			<i class="fa fa-file-word-o fa-lg" aria-hidden="true"></i>
																		</a>
                                        <a *ngIf="document.ext == 'ppt' || document.ext == 'pptx'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																			<i class="fa fa-file-powerpoint-o fa-lg" aria-hidden="true"></i>
																		</a>
                                        <a *ngIf="document.ext == 'xls' || document.ext == 'xlsx'" class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																			<i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i>
																		</a>
                                        <a *ngIf="document.ext == 'png' || document.ext == 'jpg' || document.ext == 'jpeg' || document.ext == 'bmp' || document.ext == 'tiff' || document.ext == 'gif'"
                                          class="btn btn-sm edit" (click)="$event.stopPropagation();docFileIconButtonClickEvent(document.fileName, document.id, document.ext)">
																			<i class="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
																		</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
