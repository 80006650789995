import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { KnowledgeTagService } from './knowledgetag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-editknowledgetag',
	templateUrl: './editknowledgetag.component.html'
})

export class EditKnowledgeTagComponent implements OnInit {
	editKnowledgeTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private knowledgeTagService: KnowledgeTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

	}
	ngOnInit() {
		this.editKnowledgeTag = new FormGroup({
			id: new FormControl(this.knowledgeTagService.curKnowledgeId, Validators.required),
			knowledgeTag: new FormControl(this.knowledgeTagService.curKnowledge, Validators.required)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedToUpdateKnowledge()) {
			this.knowledgeTagService.editKnowledgeTag(this.editKnowledgeTag.value.id, this.editKnowledgeTag.value.knowledgeTag)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully modified the knowledge tag.");
					this.knowledgeTagService.curKnowledge = this.editKnowledgeTag.value.knowledgeTag;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	checkNeedToUpdateKnowledge() {
		if (this.knowledgeTagService.curKnowledge != this.editKnowledgeTag.value.knowledgeTag) {
			return true;
		} else {
			return false;
		}
	}
}