<div class="row mx-auto mt-2">
	<div class="col-sm-12 table-responsive mt-2 mb-2">
		<table sortable-table (sorted)="onSorted($event)" class="table table-bordered  data-sortable col-sm-12">
			<thead>
				<tr *ngIf="!isFiltering">
					<th class="text-center " sortable-column="SupervisorName">Supervisor Name</th>
					<th class="text-center " sortable-column="FirstName">First Name</th>
					<th class="text-center " sortable-column="LastName">Last Name</th>
					<th class="text-center " sortable-column="Contractor">Contractor</th>
					<th class="text-center " sortable-column="Email">Email</th>
					<th class="text-center " sortable-column="OrgCode">Org Code</th>
					<th class="text-center " sortable-column="JobProfile">Job Profile</th>
					<th class="text-center " sortable-column="TimeZone">Time Zone</th>
					<th class="text-center " sortable-column="IsAssessed">Is Assessed</th>
					<th class="text-center " sortable-column="LastAssessmentCompletedDate">Last Assessment Completed Date</th>
					<th class="text-center " sortable-column="ScheduledAssessmentDate">Scheduled Assessment Date</th>
				</tr>
				<tr *ngIf="isFiltering">
						<th class="text-center ">Supervisor Name</th>
						<th class="text-center ">First Name</th>
						<th class="text-center " >Last Name</th>
						<th class="text-center ">Contractor</th>
						<th class="text-center ">Email</th>
						<th class="text-center ">Org Code</th>
						<th class="text-center ">Job Profile</th>
						<th class="text-center ">Time Zone</th>
						<th class="text-center ">Is Assessed</th>
						<th class="text-center ">Last Assessment Completed Date</th>
						<th class="text-center ">Scheduled Assessment Date</th>
					</tr>
			</thead>
			<tbody>
				<tr style="background-color: rgba(0, 0, 0, 0.05)">
					<th class="">
						<input class="form-control" type="text" name="searchSupervisorName" [(ngModel)]="searchSupervisorName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchFirstName" [(ngModel)]="searchFirstName" (keyup)="filter()">
					</th>
					<th  class="">
						<input class="form-control" type="text" name="searchLastName" [(ngModel)]="searchLastName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchContractor" [(ngModel)]="searchContractor" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchEmail" [(ngModel)]="searchEmail" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchTimeZone" [(ngModel)]="searchTimeZone" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchIsAssessed" [(ngModel)]="searchIsAssessed" (keyup)="filter()">
					</th>
					<th></th>
					<th></th>
				</tr>
				<tr *ngFor="let row of paginatedResults; let i = index" [style.background-color]="row.Color">
					<td style="width:10%">{{row.SupervisorName}}</td>
					<td style="width:10%">{{row.FirstName}}</td>
					<td style="width:10%">{{row.LastName}}</td>
					<td style="width:10%">{{row.Contractor}}</td>
					<td style="width:10%">{{row.Email}}</td>
					<td style="width:10%">{{row.OrgCode}}</td>
					<td style="width:10%">{{row.JobProfile}}</td>
					<td style="width:10%">{{row.TimeZone}}</td>
					<td style="width:10%">{{row.IsAssessed}}</td>
					<td style="width:10%">{{row.LastAssessmentCompletedDate}}</td>
					<td style="width:10%">{{row.ScheduledAssessmentDate}}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="col-sm-12">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages" [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
  </div>
  </div>