<div class="col" style="overflow:hidden">
  <div class="float-left">
    <h5>Filter By</h5>
  </div>
  <div class="float-right"></div>
</div>
<div class="col-lg-12 mt-1">
  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <div class="form-group row">
      <label class="col-sm-2 col-form-label ">Org Code</label>
      <div class="col-sm-4">
        <ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" formControlName="orgId">
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label ">Job Profile</label>
      <div class="col-sm-4">
        <ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select job profile" formControlName="jobProfileId">
        </ng-select>
      </div>
    </div>
    <div class="form-group row" *ngIf="!supervisorId">
      <label class="col-sm-2 col-form-label ">Supervisor</label>
      <div class="col-sm-4">
        <ng-select [allowClear]="true" [items]="supervisorList" placeholder="Select Supervisor" formControlName="supervisorId">
        </ng-select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-6"></div>
      <div class="col-sm-3">
        <button type="submit" class="btn btn-warning btn-block">Show</button>
      </div>
      <div class="col-sm-3">
        <button type="button" (click)="clearForm()" class="btn btn-default btn-block">Clear</button>
      </div>
    </div>
    <div class="col-sm-12 alert alert-info" *ngIf="noResults && !firstTime && valid">
      No matching items.
    </div>
	 <div class="col-sm-12 alert alert-info " *ngIf="firstTime">
		Please filter above to see the graph results.
	</div>	
	<alert></alert>
  </form>
</div>
<div class="row mx-auto mt-4" >	
  <div class="col-sm-12">
    <highcharts-chart [Highcharts]="chart" [options]="options" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
  </div>
</div>
<div *ngIf=isLoading class="loading"></div>
