<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Manage Assessments</h4>
    </div>    
  </div>
</div>
<hr>
<div *ngIf=isLoading class="loading"></div>
<div class="col-lg-12 mx-auto pb-2">
	<form [formGroup]="assessmentForm" (ngSubmit)="onSubmit()">

		<div class="form-group row mb-0">
			<div class="col-sm-6">
        <label class="col-form-label">Knowledge Check Scheduled Date</label>
				<div class="form-group row">
					<div class="col-sm-6">
						<var class="small">From :</var>
						<my-date-picker (dateChanged)="scheduledSelfAssFromChanged($event)" formControlName="scheduledSelfAssFromDate" [options]="myDatePickerOptions"></my-date-picker>
					</div>
					<div class="col-sm-6">
						<var class="small">To :</var>
						<my-date-picker formControlName="scheduledSelfAssToDate" [options]="scheduledSelfAssToOptions"></my-date-picker>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<label class="col-form-label">Assessment Scheduled Date</label>
				<div class="form-group row">
					<div class="col-sm-6">
						<var class="small">From :</var>
						<my-date-picker (dateChanged)="scheduledAssToChanged($event)" formControlName="scheduledAssFromDate" [options]="myDatePickerOptions"></my-date-picker>
					</div>
					<div class="col-sm-6">
						<var class="small">To :</var>
						<my-date-picker formControlName="scheduledAssToDate" [options]="scheduledAssToOptions"></my-date-picker>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group row mb-0">
			<div class="col-sm-6">
        <label class="col-form-label">Knowledge Check Completed Date</label>
				<div class="form-group row">
					<div class="col-sm-6">
						<var class="small">From :</var>
						<my-date-picker (dateChanged)="selfAssCompletedToChanged($event)" formControlName="selfAssCompletedFromDate" [options]="myDatePickerOptions"></my-date-picker>
					</div>
					<div class="col-sm-6">
						<var class="small">To :</var>
						<my-date-picker formControlName="selfAssCompletedToDate" [options]="selfAssCompletedToOptions"></my-date-picker>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<label class="col-form-label">Assessment Completed Date</label>
				<div class="form-group row">
					<div class="col-sm-6">
						<var class="small">From :</var>
						<my-date-picker (dateChanged)="assCompletedToChanged($event)" formControlName="assCompletedFromDate" [options]="myDatePickerOptions"></my-date-picker>
					</div>
					<div class="col-sm-6">
						<var class="small">To :</var>
						<my-date-picker formControlName="assCompletedToDate" [options]="assCompletedToOptions"></my-date-picker>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="form-group row mb-0">
			<label class="col-sm-6 col-form-label">Self Assessment Scheduled Date</label>
			<label class="col-sm-6 col-form-label">Assessment Scheduled Date</label>
		</div> -->
		<!-- <div class="form-group row ">
			<div class="col-sm-3">
				<var class="small">From :</var>
				<my-date-picker (dateChanged)="scheduledSelfAssFromChanged($event)" formControlName="scheduledSelfAssFromDate" [options]="myDatePickerOptions"></my-date-picker>
			</div>
			<div class="col-sm-3">
				<var class="small">To :</var>
				<my-date-picker formControlName="scheduledSelfAssToDate" [options]="scheduledSelfAssToOptions"></my-date-picker>
			</div>
			<div class="col-sm-3">
				<var class="small">From :</var>
				<my-date-picker (dateChanged)="scheduledAssToChanged($event)" formControlName="scheduledAssFromDate" [options]="myDatePickerOptions"></my-date-picker>
			</div>
			<div class="col-sm-3">
				<var class="small">To :</var>
				<my-date-picker formControlName="scheduledAssToDate" [options]="scheduledAssToOptions"></my-date-picker>
			</div>
		</div> -->
		<!-- <div class="form-group row mb-0">
			<label class="col-sm-6 col-form-label">Self Assessment Completed Date</label>
			<label class="col-sm-6 col-form-label">Assessment Completed Date</label>
		</div> -->
		<!-- <div class="form-group row">
			<div class="col-sm-3">
				<var class="small">From :</var>
				<my-date-picker (dateChanged)="selfAssCompletedToChanged($event)" formControlName="selfAssCompletedFromDate" [options]="myDatePickerOptions"></my-date-picker>
			</div>
			<div class="col-sm-3">
				<var class="small">To :</var>
				<my-date-picker formControlName="selfAssCompletedToDate" [options]="selfAssCompletedToOptions"></my-date-picker>
			</div>
			<div class="col-sm-3">
				<var class="small">From :</var>
				<my-date-picker (dateChanged)="assCompletedToChanged($event)" formControlName="assCompletedFromDate" [options]="myDatePickerOptions"></my-date-picker>
			</div>
			<div class="col-sm-3">
				<var class="small">To :</var>
				<my-date-picker formControlName="assCompletedToDate" [options]="assCompletedToOptions"></my-date-picker>
			</div>
		</div> -->
		<div class="form-group row mt-4">
			<div class="col-sm-4">
				<input (change)="showType($event)" type="checkbox" id="showCompleted" formControlName="showCompleted" name="showCompleted"> Show Completed
			</div>
			<div class="col-sm-2"></div>
			<div class="col-sm-3">
				<button type="submit" [disabled]="!isQueryAssessment" class="btn btn-warning pl-5 pr-5 btn-block mb-1">Show</button>
			</div>
			<div class="col-sm-3">
				<button type="button" (click)="clearForm()" class="btn btn-default pl-5 pr-5 btn-block mb-1">Clear</button>
			</div>
		</div>
	</form>
	<alert></alert>
</div>
<div class="row mx-auto mt-2">
	<div class="col-sm-12 pb-4">
		<button type="button" [disabled]="!isModifyAssessment || selectedRows.length == 0 " (click)="reschedule()" class="btn btn-warning pl-5 pr-5 mb-1 float-left mr-2">Reschedule Assessment</button>
		<button type="button" [disabled]="!isModifyAssessment || selectedRows.length == 0 " (click)="cancelAssessment()" class="btn btn-warning pl-5 pr-5 mb-1 float-left mr-2">Cancel Assessment</button>
		<button type="button" [disabled]="!isModifyAssessment || selectedRows.length == 0 " (click)="changeAssessorModal.open()"
		 class="btn btn-warning pl-5 pr-5 mb-1 float-left mr-2">Change Assessor</button>
    <button type="button" [disabled]="!isDataExist" class="btn btn-warning pl-5 pr-5 mb-1 float-left mr-2" (click)="exportToExcel()">
       Export to Excel
    </button>
	</div>
  <div class="col-sm-12 table-responsive mt-2 mb-1">     
		<table sortable-table (sorted)="onSorted($event)" class="table table-bordered  table-striped data-sortable col-sm-12">
			<thead>
				<tr *ngIf="!isFiltering">
					<th rowspan="2"></th>
          <th sortable-column="userName" sortable-type="string" class="minimum-width text-center " rowspan="2">Full Name</th>
          <th sortable-column="orgCode" sortable-type="string" class="minimum-width text-center " rowspan="2">Org Code</th>
          <th sortable-column="jobProfile" sortable-type="string" class="minimum-width text-center " rowspan="2">Job Profile</th>
          <th sortable-column="assessorName" sortable-type="string" class="minimum-width text-center " rowspan="2">Assessor</th>
          <th class="text-center " colspan="4" align="center">Knowledge Check</th>
          <th class="text-center " colspan="3" align="center">Assessor Lead</th>
          <th sortable-column="assessmentCompletedDate" sortable-type="number" class="text-center " rowspan="2">Assessment Completed Date</th>
          <th class="text-center " rowspan="2"></th>
          <th class="text-center " rowspan="2"></th>
				</tr>
				<tr *ngIf="isFiltering">
					<th rowspan="2"></th>
          <th class="minimum-width text-center " rowspan="2">Full Name</th>
          <th class="minimum-width text-center " rowspan="2">Org Code</th>
          <th class="minimum-width text-center " rowspan="2">Job Profile</th>
          <th class="minimum-width text-center " rowspan="2">Assessor</th>
          <th class="text-center " colspan="4" align="center">Knowledge Check</th>
          <th class="text-center " colspan="3" align="center">Assessor Lead</th>
          <th class="text-center " rowspan="2">Assessment Completed Date</th>
          <th class="text-center " rowspan="2"></th>
          <th class="text-center " rowspan="2"></th>
				</tr>
				<tr *ngIf="!isFiltering">
					<th sortable-column="scheduleSelfAssessmentDate" sortable-type="number" class="text-center ">Scheduled</th>
					<th sortable-column="SelfAssessmentCompletedDate" sortable-type="number" class="text-center ">Actual</th>
					<th class="text-center ">Package</th>
					<th class="text-center ">Answer Key</th>
					<th sortable-column="scheduleAssessmentDate" sortable-type="number" class="text-center ">Scheduled</th>
					<th sortable-column="assessmentDate" sortable-type="number" class="text-center ">Actual</th>
					<th class="text-center ">Package</th>
				</tr>
				<tr *ngIf="isFiltering">
					<th class="text-center ">Scheduled</th>
					<th class="text-center ">Actual</th>
					<th class="text-center ">Package</th>
					<th class="text-center ">Answer Key</th>
					<th class="text-center ">Scheduled</th>
					<th class="text-center ">Actual</th>
					<th class="text-center ">Package</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th></th>
					<th class="minimum-width ">
						<input class="form-control" type="text" name="searchFullName" [(ngModel)]="searchFullName" (keyup)="filter()">
					</th>
					<th class="minimum-width ">
						<input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
					</th>
					<th class="minimum-width ">
						<input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile" (keyup)="filter()">
					</th>
					<th class="minimum-width ">
						<input class="form-control" type="text" name="searchAssessor" [(ngModel)]="searchAssessor" (keyup)="filter()">
					</th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
					<th class=""></th>
				</tr>
				<tr *ngFor="let row of paginatedResults; let i = index">
					<td>
						<input type="checkbox" (change)="setSelected($event, i)" *ngIf="row.selected == 1" checked>
						<input type="checkbox" (change)="setSelected($event, i)" *ngIf="row.selected == 0 || row.selected == null">
					</td>
					<td class="minimum-width ">{{row.userName}}</td>
					<!--<td>
						<input type="checkbox" class="bigcheck" disabled="disabled" *ngIf="row.userContractStatus == 1" checked="checked" />
						<input class="bigcheck" type="checkbox" disabled="disabled" *ngIf="row.userContractStatus==0 || row.userContractStatus==null"
						/>
						<span class="bigcheck-target"></span>
					</td>-->
					<td class="minimum-width ">{{row.orgCode}}</td>
					<td class="minimum-width ">{{row.jobProfile}}</td>
					<td class="minimum-width ">{{row.assessorName}}</td>
					<td class="">{{row.scheduleSelfAssessmentDate == null ? "" : row.scheduleSelfAssessmentDate.toLocaleDateString()}}</td>
					<td class="">{{row.selfAssessmentCompletedDate == null ? "" : row.selfAssessmentCompletedDate.toLocaleDateString()}}</td>
					<td class=" text-center">
            <a *ngIf="row.selfAssessmentStatus == 'Completed'" class="btn btn-sm edit" (click)="fileIconButtonClickEvent(row.id,row.selfAssessmentId, row.userName, row.scheduleSelfAssessmentDate.toLocaleDateString(), 'KnowledgeCheck')">
							<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
						</a>
						<p *ngIf="row.selfAssessmentStatus == 'Error' || row.selfAssessmentStatus == 'Processing' || row.selfAssessmentStatus == ''">
							{{row.selfAssessmentStatus}}
						</p>
					</td>
					<td class=" text-center">
            <a *ngIf="row.selfAssessmentAnswerKeyStatus == 'Completed'" class="btn btn-sm edit" (click)="fileIconButtonClickEvent(row.id,row.selfAssessmentAnswerId, row.userName, row.scheduleSelfAssessmentDate.toLocaleDateString(), 'KnowledgeCheckAnswer')">
							<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
						</a>
						<p *ngIf="row.selfAssessmentAnswerKeyStatus == 'Error' || row.selfAssessmentAnswerKeyStatus == 'Processing' || row.selfAssessmentAnswerKeyStatus == ''">
							{{row.selfAssessmentAnswerKeyStatus}}
						</p>
					</td>

					<!-- <ng-template [ngIf]="row.selfAssessmentStatus == 'Completed' ">
						<td class="">
							<a class="btn btn-sm edit" (click)="fileIconButtonClickEvent(row.id,row.selfAssessmentId)"><i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
							</a>
						</td>
					</ng-template>
					<ng-template [ngIf]="row.selfAssessmentStatus == 'Error' || row.selfAssessmentStatus == 'Processing' || row.selfAssessmentStatus == ''">
						<td class="">{{row.selfAssessmentStatus}}</td>
					</ng-template>
					<ng-template [ngIf]="row.selfAssessmentAnswerKeyStatus == 'Completed' ">
						<td class=""><a class="btn btn-sm edit" (click)="fileIconButtonClickEvent(row.id,row.selfAssessmentAnswerId)"><i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i></a></td>
					</ng-template>
					<ng-template [ngIf]="row.selfAssessmentAnswerKeyStatus == 'Error' || row.selfAssessmentAnswerKeyStatus == 'Processing' || row.selfAssessmentAnswerKeyStatus == '' ">
						<td class="">{{row.selfAssessmentAnswerKeyStatus}}</td>
					</ng-template> -->

          <td *ngIf="row.isKnowledgeTest == 0" class="">{{row.scheduleAssessmentDate == null ? "" : row.scheduleAssessmentDate.toLocaleDateString()}}</td>
          <td *ngIf="row.isKnowledgeTest == 0" class="">{{row.assessmentDate == null ? "" : row.assessmentDate.toLocaleDateString()}}</td>
          <td *ngIf="row.isKnowledgeTest == 0" class=" text-center">
						<a *ngIf="row.assessorStatus == 'Completed'" class="btn btn-sm edit" (click)="fileIconButtonClickEvent(row.id, row.assessorPkgId, row.userName, row.scheduleAssessmentDate.toLocaleDateString(),'Assessment')">
							<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
						</a>
						<a *ngIf="row.assessorStatus == 'Completed' && row.status != 'Completed'">
							<button type="button" class="btn btn-outline-warning btn-sm" (click)="regeneratePkg(row.id)">Regenerate</button>
						</a>
						<p *ngIf="row.assessorStatus == 'Error' || row.assessorStatus == 'Processing'">
							{{row.assessorStatus}}
						</p>
					</td>
          <td *ngIf="row.isKnowledgeTest == 1" class=" text-center" colspan="3">
            <span class="badge badge-custom-kt mt-1 mr-1">Knowledge Check Only</span>
          </td>
          <td class="">{{row.assessmentCompletedDate == null ? "" : row.assessmentCompletedDate.toLocaleDateString()}}</td>
					<!-- <ng-template [ngIf]="row.assessorStatus == 'Completed' ">
						<td class=""><a class="btn btn-sm edit" (click)="fileIconButtonClickEvent(row.id,row.assessorId)"><i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i></a></td>
					</ng-template>
					<ng-template [ngIf]="row.assessorStatus == 'Error' || row.assessorStatus == 'Processing' ">
						<td class="">{{row.assessorStatus}}</td>
					</ng-template> -->
					<!-- unlock Assessment -->
					<td>
						<a *ngIf="row.lockedBy && (userId == row.lockedBy || userRole == 'Admin')" class="btn btn-sm edit" (click)="unlockAssessment(row.id)">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</a>
					</td>
					<!-- perform Assessment -->
					<td [class]="getStyle(row.status)">
            <a *ngIf="(userId != row.assessorId && (userRole == 'Admin' && row.status != 'New') || userId == row.assessorId) && !row.lockedBy && row.isKnowledgeTest == 0 " class="btn btn-sm edit" (click)="performAssessmentBtnClickEvent(row)">
							<i class="fa fa-briefcase" aria-hidden="true"></i>
						</a>
            <a *ngIf="((userId != row.assessorId && userRole != 'Admin')  || (userId != row.assessorId && (userRole == 'Admin' && row.status == 'New'))) || row.lockedBy || row.isKnowledgeTest == 1" style="cursor: pointer" class="btn btn-sm edit disabled fa-disabled">
							<i class="fa fa-briefcase " aria-hidden="true"></i>
						</a>
						<!--<p *ngIf="row.assessorStatus == 'Error' || row.assessorStatus == 'Processing'">
							{{row.assessorStatus}}
						</p>-->
					</td>

					<!-- <ng-template [ngIf]="row.assessorStatus == 'Completed' ">
						<td class=" text-center"><a class="btn btn-sm edit" (click)="performAssessmentBtnClickEvent(row.id,row.assessorId)"><i class="fa fa-briefcase" aria-hidden="true"></i></a></td>
					</ng-template>
					<ng-template [ngIf]="row.assessorStatus == 'Error' || row.assessorStatus == 'Processing' ">
						<td class=" text-center">{{row.assessorStatus}}</td>
					</ng-template> -->
				</tr>
			</tbody>
		</table>
	</div>
  <div class="col-sm-12 mb-4">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
		 [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
	</div>
</div>

<modal #changeAssessorModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Change Assessor</h4>
	</modal-header>
	<modal-body>
		<form [formGroup]="changeAssessorForm">
			<div class="form-group">
				<label for="name">Assessor:</label>
				<select class="form-control" formControlName="assessor" name="assessor" id="assessor" required>
					<option *ngFor="let assessorObj of assessorList" [ngValue]="assessorObj.id"> {{assessorObj.name}}</option>
				</select>
			</div>
		</form>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" [disabled]="!changeAssessorForm.valid" (click)="changeAssessor()">Change</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeAssessorModal.dismiss()">Cancel</button>
	</modal-footer>
</modal>

<modal #cancelAssessmentModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Cancel Assessments</h4>
	</modal-header>
	<modal-body>
		<p *ngIf="showSelfAssessmentMsg">{{constants.SELF_ASSESSMENTS}}</p>
		<p>{{constants.CANCEL_ASSESSMENTS}}</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="cancel()">Yes</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancelAssessmentModal.dismiss()">No</button>
	</modal-footer>
</modal>

<modal #unlockAssessmentModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Unlock Assessment</h4>
	</modal-header>
  <modal-body>
		<p>{{constants.UNLOCK_ASSESSMENT}}</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="unlock()">Yes</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="unlockAssessmentModal.dismiss()">No</button>
	</modal-footer>
</modal>
