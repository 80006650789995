import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { TaskService } from './task.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service';

import { Task } from './task.model';
import { CoreCompetency } from '../corecompetency/corecompetency.model';
import { JobProfile } from '../../jobprofile/jobprofile.model';

import { TagInputModule } from 'ngx-chips';
import { TaskTagService } from '../../tag/tasktag/tasktag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-addtask',
	templateUrl: './addtask.component.html'
})
export class AddTaskComponent implements OnInit {

	addNewTaskForm: FormGroup;

	public autocompleteItemsAsObjects = [];
	public selectedTags = [];

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private taskService: TaskService,
		private taskTagService: TaskTagService,
		private userService: UserService,
		private jobProfileService: JobProfileService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.loadActiveTaskTags();
		this.addNewTaskForm = new FormGroup({
			task: new FormControl(null, Validators.required),
			taskTag: new FormControl(null)
		});
	}

	//navigate to other component
	public cancel() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('AllTask');
		}
	}

	public saveAndNew() {
		const taskText: string = this.addNewTaskForm.value.task;
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.addJPTask(taskText)
		} else {
			this.addTask(taskText)
		}
	}

	public save() {
		const taskText: string = this.addNewTaskForm.value.task;
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.addJPTaskSave(taskText)
		} else {
			let tempTask: Task = new Task('', taskText, true);
			tempTask.tagList = this.selectedTags;
			this.taskService.addNewTask(tempTask).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success("Successfully added the task.");
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
		}
	}

	//add task to DB
	private addTask(taskText: string) {
		let tempTask: Task = new Task('', taskText, true);
		tempTask.tagList = this.selectedTags;
		this.taskService.addNewTask(tempTask).subscribe(
			data => {
				// console.log('[addTask] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success("Successfully added the task.");
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	//add task to job profile
	private addJPTask(taskText: string) {
		let tempTask: Task = new Task('', taskText, true, [], [], []);
		tempTask.regulatoryList = []
		tempTask.tagList = this.selectedTags;
		let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let taskGroup: Task = this.jobProfileService.getSelectedTaskGroupModel()

		this.taskService.addNewTask(tempTask).subscribe(
			data => {
				tempTask.id = data.taskId
				tempTask.criticalityValue = 1
				tempTask.orderNum = ccModel.taskList.length + 1;
				if (taskGroup != null) {
					tempTask.taskGroupId = taskGroup.id
					this.addTaskToJP(jobProfile, ccModel, tempTask, taskGroup)
				} else {
					this.addTaskToJP(jobProfile, ccModel, tempTask, null)
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);

	}

	private addJPTaskSave(taskText: string) {
		let tempTask: Task = new Task('', taskText, true, [], [], []);
		tempTask.regulatoryList = []
		tempTask.tagList = this.selectedTags;
		tempTask.knowledgeQuestionList = [];
		let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let taskGroup: Task = this.jobProfileService.getSelectedTaskGroupModel()

		this.taskService.addNewTask(tempTask).subscribe(
			data => {
				tempTask.id = data.taskId
				tempTask.criticalityValue = 1
				if (taskGroup != null) {
					tempTask.taskGroupId = taskGroup.id
					tempTask.orderNum = taskGroup.taskList.length + 1
					this.addTaskToJPSave(jobProfile, ccModel, tempTask, taskGroup);
				} else {
					tempTask.orderNum = ccModel.taskList.length + 1
					this.addTaskToJPSave(jobProfile, ccModel, tempTask, null);
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);

	}
	// add task to job profile service call
	private addTaskToJP(jobprofile: JobProfile, coreCompetency: CoreCompetency, task: Task, taskGroup: Task) {
		let tempJobProfile: JobProfile = new JobProfile(jobprofile.jobProfileId)
		let tempCCItem: CoreCompetency = new CoreCompetency(coreCompetency.id, coreCompetency.text, true, [])
		tempCCItem.taskList.push(task)
		tempJobProfile.coreCompetencyList = tempCCItem
		if (taskGroup != null) {
			this.jobProfileService.addTasksToTaskGroupInJP(tempJobProfile).subscribe(
				data => {
					taskGroup.taskList.push(task);
					this.alertService.clear();
					this.alertService.success("Successfully added task to the job profile.");
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
		} else {
			let taskTotal = coreCompetency.taskList.length + 1
			this.jobProfileService.addTaskToJobProfile(tempJobProfile).subscribe(
				data => {
					coreCompetency.taskList.push(task);
					coreCompetency.taskTotal = taskTotal
					this.alertService.clear();
					this.alertService.success("Successfully added task to the job profile.");
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
		}
	}

	private addTaskToJPSave(jobprofile: JobProfile, coreCompetency: CoreCompetency, task: Task, taskGroup: Task) {
		let tempJobProfile: JobProfile = new JobProfile(jobprofile.jobProfileId)
		let tempCCItem: CoreCompetency = new CoreCompetency(coreCompetency.id, coreCompetency.text, true, [])
		tempCCItem.taskList.push(task)
		tempJobProfile.coreCompetencyList = tempCCItem
		if (taskGroup != null) {
			this.jobProfileService.addTasksToTaskGroupInJP(tempJobProfile).subscribe(
				data => {
					taskGroup.taskList.push(task);
					this.alertService.clear();
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
		} else {
			let taskTotal = coreCompetency.taskList.length + 1
			this.jobProfileService.addTaskToJobProfile(tempJobProfile).subscribe(
				data => {
					coreCompetency.taskList.push(task);
					coreCompetency.taskTotal = taskTotal
					this.alertService.clear();
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
		}
	}

	public loadActiveTaskTags() {
		this.taskTagService.getActiveTaskTags().subscribe(
			data => {
				//console.log('[loadActiveTaskTags] ' + JSON.stringify(data).toString());
				this.autocompleteItemsAsObjects = []
				data.taskTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.selectedTags.push(event);
	};

	public removeTags(event: any) {
		//console.log('[removeTags]' + JSON.stringify(event).toString());
		// const index: number = this.tags.indexOf(event);
		// if (index !== -1) {
		//     this.tags.splice(index, 1);	
		// this.selectedTags = this.tags;
		// }
		this.selectedTags = this.selectedTags.filter(tag => tag.id !== event.id);
	};

}
