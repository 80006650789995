export class AssessmentDocument {
	constructor(
		public id: string,
		public accId: string,
		/** id of the task in assessment table.
		use to update assessment answer */
		public assId: string,
		public category: string,
		public fileName: string,
		public note?: string,
		public file?: File,
		public ext?: string,
		//id of assessment table
		public assessmentId?: string
	) { }
}
