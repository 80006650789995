<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Performance Tag</h4>
		</div>
		<div class="float-right">
		<div class="row">
		<form [formGroup]="showHideForm" class="mr-2 ml-1" >
			<div class="col-sm-12">
				<input class="mr-1" (change)="showHide($event)" [checked]="checked==true" type="checkbox" id="showHide" name="showHide"> Show Hidden
			</div>
		</form>
		<button type="button" class="btn btn-sm btn-outline-warning ml-1" (click)="selectedComponentItem('AddPerformanceTag')" [disabled]="!isAddCC">
			<i class="fa fa-plus fa-fw"></i>&nbsp;Add New Performance Tag
		</button>
	</div>
</div>
</div>
<hr/>
</div>

<div class="col-lg-12 mx-auto">
	<alert></alert>
	<div class="table-responsive mb-2">
		<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
		 class="table-sm" style="cursor: pointer;">
		</ng-table>
	</div>

	<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
	 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
	</pagination>
	<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>

</div>