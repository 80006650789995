import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class PerformanceTagService {
	public curPerId: string = null;
	public curPerformance: string = null;

	constructor(private http: Http, private router: Router) { }

	addPerformanceTag(performanceTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'performanceTag': performanceTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/performancetag/addPerformanceTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editPerformanceTag(compTagId: string, performanceTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'performanceTag': performanceTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/performancetag/editPerformanceTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changePerformanceTagStatus(compTagId: string, tagStatus: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'tagStatus': tagStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/performancetag/changePerformanceTagStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllPerformanceTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performancetag/getAllPerformanceTag' + token, { headers: headers })
			.map(res => res.json());
	}

	getActivePerformanceTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performancetag/getActivePerformanceTag' + token, { headers: headers })
			.map(res => res.json());
	}

	deletePerformanceTag(compTagId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performancetag/removePerformanceTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setCurretData(compTagId: string, performanceTag: string) {
		this.curPerId = compTagId;
		this.curPerformance = performanceTag;
	}

	getAllShowPerformanceTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performancetag/getAllShowPerformanceTag' + token, { headers: headers })
			.map(res => res.json());
	}
	
	changePerformanceTagShowHide(performanceId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'performanceId': performanceId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performancetag/changePerformanceTagShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}


}