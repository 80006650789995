import { Component, OnInit, ViewChild } from "@angular/core";
import { Ng2TableModule } from "ng2-table/ng2-table";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import {
	NgTableComponent,
	NgTableFilteringDirective,
	NgTablePagingDirective,
	NgTableSortingDirective
} from "ng2-table/ng2-table";

export class TableComponent implements OnInit {
	public rows: Array<any> = [];
	public columns: Array<any> = [];

	public page: number = 1;
	public itemsPerPage: number = 10;
	public maxSize: number = 10;
	public numPages: number = 1;
	public length: number = 0;

	public pageCount: number = 0;

	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	public data: Array<any> = [];

	constructor() { }

	ngOnInit() { }

	public changePage(page: any, data: Array<any> = this.data): Array<any> {		
		let start = (page.page - 1) * page.itemsPerPage;
		let end = page.itemsPerPage > -1 ? start + page.itemsPerPage : data.length;
		return data.slice(start, end);
	}

	//sort data
	public changeSort(data: any, config: any): any {
		if (!config.sorting) {
			return data;
		}

		let columns = this.config.sorting.columns || [];
		let columnName: string = void 0;
		let sort: string = void 0;

		for (let i = 0; i < columns.length; i++) {
			if (columns[i].sort === "asc" || columns[i].sort === "desc") {
				columnName = columns[i].name;
				sort = columns[i].sort;
				break;
			}
		}

		if (!columnName) {
			return data;
		}

		// simple sorting
		return data.sort((previous: any, current: any) => {
			if (
				previous[columnName].toLowerCase() > current[columnName].toLowerCase()
			) {
				return sort === "desc" ? -1 : 1;
			} else if (
				previous[columnName].toLowerCase() < current[columnName].toLowerCase()
			) {
				return sort === "asc" ? -1 : 1;
			}
			return 0;
		});
	}

	//filter data
	public changeFilter(data: any, config: any): any {
		let filteredData: Array<any> = data;
		this.columns.forEach((column: any) => {
			if (column.filtering) {
				filteredData = filteredData.filter((item: any) => {
					return item[column.name].match(
						new RegExp(column.filtering.filterString, "i")
					);
				});
			}
		});

		if (!config.filtering) {
			return filteredData;
		}

		if (config.filtering.columnName) {
			return filteredData.filter((item: any) =>
				item[config.filtering.columnName].match(
					this.config.filtering.filterString
				)
			);
		}

		let tempArray: Array<any> = [];
		filteredData.forEach((item: any) => {
			let flag = false;
			this.columns.forEach((column: any) => {
				if (
					item[column.name].toString().match(this.config.filtering.filterString)
				) {
					flag = true;
				}
			});
			if (flag) {
				tempArray.push(item);
			}
		});
		filteredData = tempArray;
		return filteredData;
	}

	//change table method
	public onChangeTable(
		config: any,
		page: any = { page: this.page, itemsPerPage: this.itemsPerPage }
	): any {		
		if (config.filtering) {
			Object.assign(this.config.filtering, config.filtering);
		}

		if (config.sorting) {
			Object.assign(this.config.sorting, config.sorting);
		}

		let filteredData = this.changeFilter(this.data, this.config);
		let sortedData = this.changeSort(filteredData, this.config);
		this.rows =
			page && config.paging ? this.changePage(page, sortedData) : sortedData;
		this.length = sortedData.length;
	}

	public onCellClick(data: any): any { }

	public extendData() { }
}
