import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
/*
 * Reorder an array for a given type
 * Takes string as an argumnet to sort array
 * Usage:
 *   array | convertDate: <type>
 * Example:
 *
*/

@Pipe({
	name: 'convertDate',
	pure: true
})
export class ConvertDateTime implements PipeTransform {

	public timeZone = null;
	transform(array: any, type: string): any {
		this.timeZone = localStorage.getItem('TimeZone');

		switch (type) {
			case 'scheduledAssessmentsbyParams':
				array = this.convertScheduledAssessmentDatabyParams(array)
				break;
			case 'AllScheduledAssessments':
				array = this.convertAllScheduledAssessmentData(array)
				break;
			case 'allAssessments':
			case 'activeAssessments':
				array = this.convertAllAssessmentData(array)
				break;
		}

		return array;

	}

	private convertScheduledAssessmentDatabyParams(array: any): any {
		if (array.length != 0) {
			for (var i = 0; i < array.length; i++) {
				if (array[i].completedDate != null) {
					// let date = moment(array[i].completedDate);
					// date.tz(this.timeZone);
					let newDate = new Date(new Date(array[i].completedDate).getUTCFullYear(), new Date(array[i].completedDate).getUTCMonth(), new Date(array[i].completedDate).getUTCDate());
					array[i].completedDate = newDate;
				}
			}
			return array
		}
		else {
			return array
		}
	}

	private convertAllScheduledAssessmentData(array: any): any {
		if (array.length != 0) {
			for (var i = 0; i < array.length; i++) {
				if (array[i].scheduleSelfAssessmentDate != null) {
					// let date = moment(array[i].scheduleSelfAssessmentDate);
					// date.tz(this.timeZone);					
					let newDate = new Date(new Date(array[i].scheduleSelfAssessmentDate).getUTCFullYear(), new Date(array[i].scheduleSelfAssessmentDate).getUTCMonth(), new Date(array[i].scheduleSelfAssessmentDate).getUTCDate());
					array[i].scheduleSelfAssessmentDate = newDate;
				}
				if (array[i].scheduleAssessmentDate != null) {
					// let date = moment(array[i].scheduleAssessmentDate);
					// date.tz(this.timeZone);
					let newDate = new Date(new Date(array[i].scheduleAssessmentDate).getUTCFullYear(), new Date(array[i].scheduleAssessmentDate).getUTCMonth(), new Date(array[i].scheduleAssessmentDate).getUTCDate());
					array[i].scheduleAssessmentDate = newDate;
				}
			}
			return array
		}
		else {
			return array
		}
	}



	private convertAllAssessmentData(array: any): any {
		if (array.length != 0) {
			for (var i = 0; i < array.length; i++) {
				if (array[i].assessmentCompletedDate != null) {
					// let date = moment(array[i].assessmentCompletedDate);
					// date.tz(this.timeZone);					
					let newDate = new Date(new Date(array[i].assessmentCompletedDate).getUTCFullYear(), new Date(array[i].assessmentCompletedDate).getUTCMonth(), new Date(array[i].assessmentCompletedDate).getUTCDate());
					array[i].assessmentCompletedDate = newDate;
				}
				if (array[i].assessmentDate != null) {
					// let date = moment(array[i].assessmentCompletedDate);
					// date.tz(this.timeZone);					
					let newDate = new Date(new Date(array[i].assessmentDate).getUTCFullYear(), new Date(array[i].assessmentDate).getUTCMonth(), new Date(array[i].assessmentDate).getUTCDate());
					array[i].assessmentDate = newDate;
				}
				if (array[i].selfAssessmentCompletedDate != null) {
					// let date = moment(array[i].selfAssessmentCompletedDate);
					// date.tz(this.timeZone);
					let newDate = new Date(new Date(array[i].selfAssessmentCompletedDate).getUTCFullYear(), new Date(array[i].selfAssessmentCompletedDate).getUTCMonth(), new Date(array[i].selfAssessmentCompletedDate).getUTCDate());
					array[i].selfAssessmentCompletedDate = newDate;
				}
				if (array[i].scheduleAssessmentDate != null) {
					// let date = moment(array[i].scheduleAssessmentDate);
					// date.tz(this.timeZone);					
					let newDate = new Date(new Date(array[i].scheduleAssessmentDate).getUTCFullYear(), new Date(array[i].scheduleAssessmentDate).getUTCMonth(), new Date(array[i].scheduleAssessmentDate).getUTCDate());
					array[i].scheduleAssessmentDate = newDate;
				}
				if (array[i].scheduleSelfAssessmentDate != null) {
					// let date = moment(array[i].scheduleSelfAssessmentDate);
					// date.tz(this.timeZone);
					let newDate = new Date(new Date(array[i].scheduleSelfAssessmentDate).getUTCFullYear(), new Date(array[i].scheduleSelfAssessmentDate).getUTCMonth(), new Date(array[i].scheduleSelfAssessmentDate).getUTCDate());
					array[i].scheduleSelfAssessmentDate = newDate;
				}
			}
			return array
		} else {
			return array
		}
	}
}





