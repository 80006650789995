import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { PerformanceTagService } from './performancetag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-addperformancetag',
	templateUrl: './addperformancetag.component.html'
})

export class AddPerformanceTagComponent implements OnInit {
	addPerformanceTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private performanceTagService: PerformanceTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.addPerformanceTag = new FormGroup({
			performanceTag: new FormControl(null, Validators.required)
		});
	}

	cancel() {
		this.sharedService.change('AllPerformanceTag');
	}

	saveAndNew() {

		this.performanceTagService.addPerformanceTag(this.addPerformanceTag.value.performanceTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully added the performance tag.");
				// this.addPerformanceTag.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	save() {

		this.performanceTagService.addPerformanceTag(this.addPerformanceTag.value.performanceTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.cancel();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

}
