/**
 * Used this as read only job profile hierarchy in following components
 * CompairJobProfileComponent - (showProgressPlanning==false) - normal jp hierarchy without buttons
 * JobProgressionPlanningComponent - (showProgressPlanning==true) no remediations, has progress bars
 */
import { Component } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { JobProfile } from '../jobprofile.model';
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model';
import { Task } from '../../complib/task/task.model';
import { Knowledge } from '../../complib/knowledge/knowledge.model';
import { Performance } from '../../complib/performance/performance.model';
import { CheckList } from '../../complib/checklist/checklist.model';

@Component({
	selector: 'app-ro-jphirearchy',
	templateUrl: './rojphierarchy.component.html',
	styleUrls: ['../jphierarchy.component.scss'],
	inputs: ['jobProfileModel', 'showProgressPlanning', 'resultHeading']
})
export class ReadOnlyJPHComponent {

	public jobProfileModel: JobProfile
	public showProgressPlanning: boolean = false
	public resultHeading: string = '';
	//this variable use to show/hide data panel
	public showCCDetail: string = '';
	public showTaskDetail: string = '';
	public showTaskGroupDetail: string = ''
	public showPerformanceDetail: string = '';
	public showKnowledgeDetail: string = '';
	public showCheckListDetail: string = '';

	constructor(private sanitizer: DomSanitizer) {

	}


	public showCollapsePanel(itemId: any, itemType: string) {
		switch (itemType) {
			case 'coreCompetency':
				this.showCCDetail = this.changeVarableToShowHideItem(this.showCCDetail, itemId);
				this.showTaskDetail = ''
				this.showPerformanceDetail = ''
				this.showKnowledgeDetail = ''
				this.showCheckListDetail = ''
				break;
			case 'task':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
				this.showPerformanceDetail = ''
				this.showTaskGroupDetail = ''
				this.showKnowledgeDetail = ''
				this.showCheckListDetail = ''
				break;
				case 'taskGroup':
				this.showTaskGroupDetail = this.changeVarableToShowHideItem(this.showTaskGroupDetail, itemId);
				this.showTaskDetail = ''
				this.showPerformanceDetail = '';
				this.showKnowledgeDetail = '';
				this.showCheckListDetail = ''
				break;
			case 'taskGroupTask':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
				this.showPerformanceDetail = '';
				this.showKnowledgeDetail = '';
				this.showCheckListDetail = ''
				break
			case 'performance':
				this.showPerformanceDetail = this.changeVarableToShowHideItem(this.showPerformanceDetail, itemId);
				this.showCheckListDetail = ''
				break;
			case 'knowledgeRemediation':
				this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, itemId)
				break;
			case 'checkListRemediation':
				this.showCheckListDetail = this.changeVarableToShowHideItem(this.showCheckListDetail, itemId)
				break;
			default:
				break;
		}
	}

	private changeVarableToShowHideItem(val: string, text: string): string {
		if (val != text) {
			val = text;
		} else {
			val = '';
		}
		return val;
	}

}
