import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import { TableComponent } from "../util/table/table.component";

import { SharedService } from "../home/shared.service";
import { AlertService } from "../util/alert/alert.service";
import { UserService } from "../user/user.service";
import { DisciplineService } from "./discipline.service";
import { discipline } from "./discipline";
import { CustomModalService } from "../util/custommodal/custommodal.service";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
	selector: "app-discipline",
	templateUrl: "./discipline.component.html"
})
export class DisciplineComponent extends TableComponent {
	public selectedDisciplineId: string;
	public selectedDisciplineStatus: number;
	isAddDisciplineAllowed: boolean = false;
	isDeleteDisciplineAllowed: boolean = false;
	isModifyDisciplineAllowed: boolean = false;
	isQuerDisciplineAllowed: boolean = false;
	public selectedUserRole = [];
	showHideForm: FormGroup;
	checked: boolean = false;

	public stortedSelectedUserRole = localStorage.getItem("selectedUserRole");

	public columns: Array<any> = [
		{
			title: "Discipline",
			name: "discipline",
			filtering: { filterString: "", placeholder: "" }
		},
		{ title: "", name: "statusbutton", className: "accepter-col-action" },
		{ title: "", name: "editbutton", className: "accepter-col-action" },
		{ title: "", name: "deletebutton", className: "accepter-col-action" },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	//this array store all the data from the server
	private serverResultData: Array<any> = [];

	constructor(
		private customModalService: CustomModalService,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private userService: UserService,
		private disciplineService: DisciplineService
	) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}

		//get capabilities
		this.isAddDisciplineAllowed = this.userService.isCapabilityAssigned(
			"AddDiscipline",
			this.selectedUserRole[0][1]
		);
		this.isDeleteDisciplineAllowed = this.userService.isCapabilityAssigned(
			"DeleteDiscipline",
			this.selectedUserRole[0][1]
		);
		this.isModifyDisciplineAllowed = this.userService.isCapabilityAssigned(
			"ModifyDiscipline",
			this.selectedUserRole[0][1]
		);
		this.isQuerDisciplineAllowed = this.userService.isCapabilityAssigned(
			"QueryDiscipline",
			this.selectedUserRole[0][1]
		);

		//check querydiscipline capability before load table
		if (this.isQuerDisciplineAllowed) {
			//get all descipline
			this.loadShowDiscipline();
		} else {
			//noo need to display data
		}
	}

	//on cell click event
	public onCellClick(data: any): any {
		var elementnumber = parseInt(data.row.id) - 1;
		this.selectedDisciplineId = this.serverResultData[elementnumber].Id;
		this.selectedDisciplineStatus = this.serverResultData[elementnumber].Status;
		switch (data.column) {
			case "editbutton":
				if (this.isModifyDisciplineAllowed) {
					this.editButtonClickEvent(this.serverResultData[elementnumber]);
				} else {
					//nothing to do
				}
				break;
			case "deletebutton":
				if (this.isDeleteDisciplineAllowed) {
					this.deleteButtonClickEvent(this.selectedDisciplineId);
				} else {
					//nothing to do
				}

				break;
			case "statusbutton":
				if (this.isModifyDisciplineAllowed) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						//do nothing
					} else {
						this.statusButtonClickEvent(
							this.selectedDisciplineId,
							this.serverResultData[elementnumber].Status
						);
					}
				} else {
					//nothing to do
				}
				break;
			case "showhidebutton":
				//check delete capability
				if (this.isModifyDisciplineAllowed) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonClickEvent(this.selectedDisciplineId, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	//load buttons to the table
	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			//check status
			if (this.serverResultData[i].Status == 1) {
				this.data[i].statusbutton =
					'<a class="btn btn-sm edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				if (this.serverResultData[i].ShowHide == 0) {
					this.data[i].statusbutton =
						'<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
				}
				else {
					this.data[i].statusbutton =
						'<a class="btn btn-sm edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}
			//get edit button
			this.data[i].editbutton =
				'<a class="btn btn-sm edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			//get delete button
			this.data[i].deletebutton =
				'<a class="btn btn-sm delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyDisciplineAllowed) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyDisciplineAllowed) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}

	//navigate to add orgtype component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	private loadDiscipline() {
		this.disciplineService.getDiscipline().subscribe(
			data => {
				this.data = [];
				//console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.orgtypeList.length);
				for (let i = 0; i < data.disciplineList.length; i++) {
					this.data.push({
						id: i + 1,
						discipline: data.disciplineList[i].Discipline,
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.disciplineList;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	//click status button
	private statusButtonClickEvent(id: string, status: string) {
		let itemType = "Discipline";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	//save status
	private changeStatus(id: string, status: number) {
		this.disciplineService.changeStatus(id, status).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	//edit button click event
	private editButtonClickEvent(selectedDiscipline: any) {
		const editDiscipline = new discipline(
			selectedDiscipline.Id,
			selectedDiscipline.Discipline,
			1
		);
		this.disciplineService.setSelectedDisciplineForEdit(editDiscipline);
		this.sharedService.change("EditDiscipline");
	}

	//delete button click event
	private deleteButtonClickEvent(id) {
		let itemType = "Discipline";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	//delete save
	private delete() {
		this.disciplineService.deleteDiscipline(this.selectedDisciplineId).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	public loadShowDiscipline() {
		this.disciplineService.getShowDiscipline().subscribe(
			data => {
				this.data = [];
				//console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.orgtypeList.length);
				for (let i = 0; i < data.disciplineList.length; i++) {
					this.data.push({
						id: i + 1,
						discipline: data.disciplineList[i].Discipline,
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.disciplineList;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	showHide(event) {
		console.log("[event.target.checked]" + event.target.checked)
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQuerDisciplineAllowed) {
				this.loadDiscipline();
			} else {
				//noo need to display data
			}
		} else {
			this.loadShowDiscipline();
		}
	}


	loadData() {
		if (this.checked) {
			this.loadDiscipline();
		}
		else {
			this.loadShowDiscipline();
		}
	}


	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Discipline";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	public showHideEvent(disciplineId: string, showHide: number) {
		this.disciplineService.changeDisciplineShowHide(disciplineId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}
}
