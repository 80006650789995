<div class="col-lg-12">
  <div class="col">
    <div style="overflow:hidden">
      <h4><span *ngIf="showName">{{assessmentModel.userName}}'s </span>{{title}}</h4>
    </div>
    <div *ngIf='showCompletedDate' style="overflow:hidden">
      Job Profile - {{assessmentModel.jobProfileDesc}}
    </div>
    <div *ngIf='showCompletedDate' style="overflow:hidden">
      Assessment Completed Date - {{assessmentModel.assessmentCompletedDate.toLocaleDateString()}}
    </div>
    <hr />
    <alert></alert>
    <form *ngIf="showDropDown" [formGroup]="viewUserRemediationForm" (ngSubmit)="onSubmit()" class="mb-2">

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">User
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
        <div class="col-sm-10">
          <ng-select [allowClear]="true" (removed)="removedUser($event)" [items]="candidateList" id="user" formControlName="user" name="user"
            (selected)="selectedUser($event)" ngDefaultControl></ng-select>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-9"></div>
        <div class="col-sm-3">
          <button type="submit" class="btn btn-warning btn-block" [disabled]="!viewUserRemediationForm.valid">Show</button>
        </div>
      </div>
      <div></div>
    </form>
  </div>
</div>
<br><br>
<div *ngIf="showReport" class="col-lg-12">
  <app-assessmentremediation-report [jobProfileId]="jobProfileId" [assessmentId]="assessmentId" [userName]="userName"></app-assessmentremediation-report>
  <!-- <tabset>
		<tab heading="Assessment" id="tab1" class="pt-2">
			<app-assessmentremediation-report [jobProfileId]="jobProfileId" [assessmentId]="assessmentId"></app-assessmentremediation-report>
		</tab>
		<tab heading="Self Assessment" class="pt-2">
			<app-selfassessmentremediation-report [jobProfileId]="jobProfileId" [assessmentId]="assessmentId"></app-selfassessmentremediation-report>
		</tab>
	</tabset>  -->
</div>
