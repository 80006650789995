export class JobProfile {
	constructor(
		public jobProfileId: string,
		public description?: string,
		public jobProfileStatus?: boolean,
		public orgCodeId?: string,
		public orgCodeText?: string,
		public disciplineId?: string,
		public disciplineText?: string,
		public frequnecy?: number,

		public coreCompetencyList?: any,
		public taskTotal?: any,
		public showHide?: number
	) { }
}
