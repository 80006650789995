<div class="col-sm-12 mb-3">
  <div class="col" style="overflow:hidden">
    <div class="float-right">
      <div class="row">
        <button type="button" [disabled]="serverResultData.length == 0" class="btn btn-outline-warning btn-sm" (click)="exportToExcel()">
       <i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
    </button>
      </div>
    </div>
  </div>
</div>
<div class="col-sm-12">
  <table sortable-table (sorted)="onSorted($event)" class="col-sm-12 table-sm table-bordered  table-striped mb-2">
    <thead>
      <tr>
        <th class="text-center " sortable-column="remediationDescription" sortable-type="string">Description</th>
        <th class="text-center " sortable-column="remediationDeliveryMedium" sortable-type="string">Delivery Medium</th>
        <th class="text-center " sortable-column="remediationType" sortable-type="string">Type</th>
        <th class="text-center " sortable-column="connectedCoreCompetency" sortable-type="string">Core Competency</th>
        <th class="text-center " sortable-column="connectedTaskGroup" sortable-type="string">Task Group</th>
        <th class="text-center " sortable-column="connectedTask" sortable-type="string">Task</th>
        <th class="text-center minwidth" sortable-column="connectedTaskScore" sortable-type="number">Task Score</th>
        <th class="text-center "></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" [(ngModel)]="searchDescription" #description name="searchDescription"
            (keyup)="filter()"></th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchMedium" (click)="clickEvent(medium)"
            #medium [(ngModel)]="searchMedium" (keyup)="filter()"></th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchType" (click)="clickEvent(type)" #type
            [(ngModel)]="searchType" (keyup)="filter()">
        </th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchCCItem" (click)="clickEvent(ccItem)"
            #ccItem [(ngModel)]="searchCCItem" (keyup)="filter()">
        </th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchTaskG" (click)="clickEvent(taskG)" #taskG
            [(ngModel)]="searchTaskG" (keyup)="filter()">
        </th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchTask" (click)="clickEvent(task)" #task
            [(ngModel)]="searchTask" (keyup)="filter()">
        </th>
        <th class="text-center minwidth">
          <div class="sorting-dropdown">
            <ng-multiselect-dropdown [placeholder]="" [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
              (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)">
            </ng-multiselect-dropdown>
          </div>
        </th>
        <th class=""></th>
      </tr>
      <tr *ngFor="let ccItem of serverResultData; let i = index">
        <td class="">{{ccItem.remediationDescription}}</td>
        <td class="">{{ccItem.remediationDeliveryMedium}}</td>
        <td class="">{{ccItem.remediationType}}</td>
        <td class="">{{ccItem.connectedCorecompetency}}</td>
        <td class="">{{ccItem.connectedTaskGroup}}</td>
        <td class="">{{ccItem.connectedTask}}</td>
        <td class="text-center minwidth"><span class="badge" [ngClass]="{'badge-success': ccItem.connectedTaskScore >= 80, 'badge-warning': ccItem.connectedTaskScore >= 50 && ccItem.connectedTaskScore < 80, 'badge-danger' : ccItem.connectedTaskScore < 50}">{{ccItem.connectedTaskScore}}%</span></td>
        <td class="">
          <a class="btn btn-sm edit float-left" *ngIf="ccItem.temItem.length > 0" style="cursor: pointer" (click)="forMoreInfoClickFunction(ccItem.temItem, ccItem.remediationDescription)">
						<i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
					</a>
          <a class="btn btn-sm edit float-left" *ngIf="ccItem.selectedRemediationAttachment.length > 0" style="cursor: pointer" (click)="attachmentInfoClickFunction(ccItem.selectedRemediationAttachment, ccItem.remediationDescription)">
						<i class="fa fa-file" aria-hidden="true"></i>
					</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="col-sm-12">
  <pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
    [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
  <pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
</div>
<div *ngIf=isLoading class="loading"></div>
<modal #forMoreInfomodal>
  <modal-header [show-close]="false">
    <h5 class="modal-title">Remediation Description: {{remediationName}}</h5>
  </modal-header>
  <modal-body>
    <div *ngIf="remediationDescription != ''" class="mt-1 mb-1 pl-0">
      <b>Course Description : </b> {{remediationDescription}}
    </div>
    <div *ngIf="remediationNumber != ''" class="mt-1 mb-1 pl-0">
      <b>Course Number : </b> {{remediationNumber}}
    </div>
    <div *ngIf="remediationLink != ''" class=" mt-1 mb-1 pl-0">
      <b>Link : </b>
      <a class="break-out" target="_blank" href="{{remediationLink}}">{{remediationLink}}</a>
    </div>
    <div *ngIf="remediationNote != ''" class="mt-1 mb-1 pl-0">
      <label class="mb-0">
				<b>Note : </b>
			</label>
      <div class="mt-1 mb-1 pl-0">
        <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediationNote"
          (click)="$event.stopPropagation()" readonly></textarea>
      </div>
    </div>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="forMoreInfomodal.dismiss()">OK</button>
  </modal-footer>
</modal>

<modal #attachmentInfomodal>
  <modal-header [show-close]="false">
    <h5 class="modal-title">Remediation Description: {{remediationName}}</h5>
  </modal-header>
  <modal-body>
    <div *ngFor="let item of selectedRemediationAttachments; let k = index ">
      <ng-template [ngIf]="item.text == 'pdf' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-pdf-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"></i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'txt' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-text-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"></i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'docx' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-word-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"></i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'doc' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-word-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"> </i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'ppt' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-powerpoint-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"> </i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'pptx' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-powerpoint-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"></i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'xls' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-excel-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id);"
							aria-hidden="true"></i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
      <ng-template [ngIf]="item.text == 'xlsx' ">
        <label for="default" class="" style="cursor: pointer" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);">
					<a class="btn btn-sm edit" style="cursor: pointer">
						<i class="fa fa-file-excel-o fa-lg"
							(click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id);"
							aria-hidden="true"></i>
					</a>
					{{ item.fileName }}
				</label>
      </ng-template>
    </div>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="attachmentInfomodal.dismiss()">OK</button>
  </modal-footer>
</modal>




<!-- <div class="col-lg-12">
	<div class="col-lg-12 mx-auto"> -->
<!-- core competency -->
<!-- show data -->
<div class="col-sm-12 mx-auto mb-3">
  <!-- show selected core competencies -->
  <!-- <div class="row" *ngIf="assessmentModel != null && assessmentModel.jobProfile!=null">
			<div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of assessmentModel.jobProfile.coreCompetencyList | orderBy : 'orderNum'; let k = index">
				<div class="card p-2 card-default " (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
					<div class="card-body pointer-cursor">
						<div class="float-left mt-1 mb-1">
							{{ ccItem.text }}
						</div>
						<div class="float-right">
							<div class="float-right btn btn-sm border-0 mr-1 ml-1">
								<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
							</div>
						</div>
					</div>
				</div>

				<div class="col-sm-12 mt-1 mb-1" *ngIf="showCCDetail == ccItem.id"> -->
  <!-- <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-3 pb-3"> -->
  <!-- <div class="mt-2" *ngIf="ccItem.taskList.length > 0"> -->
  <!-- <h6 class="mt-1 mb-1">Task</h6> -->
  <!-- <div *ngFor="let task of ccItem.taskList | orderBy : 'assStatus'; let t = index ">
						<ng-container *ngIf="task.isTaskGroup; else elseTemplate">
							<div *ngIf="task.taskList.length>0" class="col-sm-12 mx-auto mt-1 mb-1">
								<div class="card p-2 card-grey-lighten-3" (click)="showCollapsePanel(task.id, 'taskGroup')">
									<div class="card-body pointer-cursor mb-1">
										<label class="mb-0">
											<span class="badge badge-dark mr-1">Task Group</span>{{ task.taskGroup }}</label>
										<div class="float-right">
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
												<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskGroupDetail == task.id, 'fa-angle-down': showTaskGroupDetail != task.id }"></i>
											</div>
										</div>
									</div>
									<div *ngIf="showTaskGroupDetail == task.id">
										<div class="col-sm-12 mx-auto mt-1 mb-2" *ngFor="let tGtask of task.taskList | orderBy : 'assStatus'; let gt = index">
											<div class="card p-2 " (click)="$event.stopPropagation(); showCollapsePanel(tGtask.id, 'taskGroupTask')">
												<div class="card-body row pointer-cursor">
													<div class="col-sm-9 mt-1 mb-1">{{ tGtask.text }}</div>
													<div class="col-sm-3 float-right">
														<div class="float-right btn btn-sm border-0 mr-1 ml-1">
															<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == tGtask.id, 'fa-angle-down': showTaskDetail != tGtask.id }"></i>
														</div>
														<div class="float-right btn btn-sm border-0 mr-1 ml-1">
															<h6 class="m-0" *ngIf="tGtask.criticalityValue == 1">
																<span class="badge badge-success">Low</span>
															</h6>
															<h6 class="m-0" *ngIf="tGtask.criticalityValue == 2">
																<span class="badge badge-warning">Medium</span>
															</h6>
															<h6 class="m-0" *ngIf="tGtask.criticalityValue == 3">
																<span class="badge badge-danger">High</span>
															</h6>
														</div>
													</div>
												</div>
											</div>
											<div class="col-sm-12 mt-1 mb-1" *ngIf="showTaskDetail == tGtask.id && ( tGtask.performanceList.length>0 || tGtask.knowledgeList.length>0 || tGtask.remediationList.length>0 ) ">
												<div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
													<div class="mt-2" *ngIf="tGtask.performanceList.length>0">
														<h6 class="mt-1 mb-1">Performance
															<span class="badge badge-warning">{{ tGtask.performanceList.length }}</span>
														</h6>
														<div *ngFor="let performance of tGtask.performanceList | orderBy : 'orderNum' ; let p = index ">
															<div class="col-sm-12 mx-auto mt-1">
																<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(performance.id, 'performance')">
																	<div class="card-body pointer-cursor">
																		<div class="float-left mt-1 mb-1">
																			<div *ngIf="(tGtask.performanceList.length > 1) && (p == (tGtask.performanceList.length - 1))"></div>
																			{{ performance.text }}
																		</div>
																		<div class="float-right">
																			<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																				<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
																			</div>
																		</div>
																	</div>
																</div> -->


  <!-- show performance detail -->
  <!-- <div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id  && (performance.checkList.length>0 || performance.remediationList.length>0 ) ">
																	<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
																		<div class="mt-2" *ngIf="performance.checkList.length>0">
																			<h6 class="mt-1 mb-1">Check List
																				<span class="badge badge-warning">{{ performance.checkList.length }}</span>
																			</h6>
																			<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
																				<div class="col-sm-12 mx-auto mt-1">
																					<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(checkList.id, 'checkListRemediation')">
																						<div class="card-body pointer-cursor">
																							<div class="float-left mt-1 mb-1">
																								<div *ngIf="(performance.checkList.length > 1) && (ch == (performance.checkList.length - 1))"></div>
																								{{ checkList.text }}
																							</div>
																							<div class="float-right">
																								<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																									<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
																								</div>
																							</div>
																						</div>
																					</div> -->

  <!-- checkList remediation -->
  <!-- <div *ngIf="showCheckListDetail == checkList.id">
																						<div class="mt-2" *ngIf="checkList.remediationList.length>0">
																							<h6 class="mt-1 mb-1">Remediation
																								<span class="badge badge-warning">{{ checkList.remediationList.length }}</span>
																							</h6>
																							<div *ngFor="let clremediation of checkList.remediationList | convert : 'convertRemediationLink'; let clr = index">
																								<div class="col-sm-12 mx-auto mt-1">
																									<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(clremediation.AssessmentchecklistRemId , 'checklistRemediationDetail', clremediation.Id)">
																										<div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail == clremediation.AssessmentchecklistRemId">
																											<div class="col-sm-12 pl-1 pr-1">
																												<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																													<span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}</span>
																													<span class="badge badge-warning mr-1">{{ clremediation.Type }}</span>
																													{{ clremediation.Description }}
																												</div>
																												<div class="float-right">
																													<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																														<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																													</div>
																												</div>
																											</div>

																											<div *ngIf="clremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																												<b>Course Description : </b>
																												{{ clremediation.CourseDescription }}
																											</div>
																											<div *ngIf="clremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																												<b>Course Number : </b>
																												{{ clremediation.CourseNumber }}
																											</div>
																											<div *ngIf="clremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																												<b>Link : </b>
																												<a class="break-out" target="_blank" href="{{clremediation.link}}">{{clremediation.Link}}</a>
																											</div>
																											<div *ngIf="clremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																												<label class="mb-0">
																													<b>Note : </b>
																												</label>
																												<div class="col-sm-12 mt-2 ml-2">
																													<form>
																														<div class="form-group row">
																															<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="clremediation.Note"
																															 (click)="$event.stopPropagation()" readonly></textarea>
																														</div>
																													</form>
																												</div>
																											</div>
																											<div *ngIf="selectedChecklistRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																												<span class="badge badge-dark ml-1">{{selectedChecklistRemediationAttachment.length}}</span>
																											</div>

																											<br *ngIf="selectedChecklistRemediationAttachment.length > 0">
																											<div *ngFor="let item of selectedChecklistRemediationAttachment; let k = index ">
																												<ng-template [ngIf]="item.text == 'pdf' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																															 aria-hidden="true"></i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'txt' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																															 aria-hidden="true"></i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'docx' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																															 aria-hidden="true"></i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'doc' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																															 aria-hidden="true"> </i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'ppt' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																															 aria-hidden="true"> </i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'pptx' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																															 aria-hidden="true"></i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'xls' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'checklistRemediationDetail');"
																															 aria-hidden="true"></i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																												<ng-template [ngIf]="item.text == 'xlsx' ">
																													<label for="default" class="">
																														<a class="btn btn-sm edit">
																															<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																															 aria-hidden="true"></i>
																														</a>
																														{{ item.fileName }} 
																													</label>
																												</ng-template>
																											</div>
																										</div>
																										<div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail != clremediation.AssessmentchecklistRemId">
																											<div class="float-left">
																												<span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}</span>
																												<span class="badge badge-warning mr-1">{{ clremediation.Type }}</span>
																												{{ clremediation.Description}}
																											</div>
																											<div class="float-right">
																												<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																													<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true}"></i>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>

																				</div>
																			</div>
																		</div>

																		<div class="mt-2 " *ngIf='performance.remediationList.length>0'>
																			<h6 class="mt-1 mb-1 ">Remediation
																				<span class="badge badge-warning">{{ performance.remediationList.length }}</span>
																			</h6>
																			<div *ngFor="let remediation of performance.remediationList | convert : 'convertRemediationLink'; let rem=i ndex ">
																				<div class="col-sm-12 mx-auto mt-1 pointer-cursor">
																					<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(remediation.AssessmentPerfRemId , 'performanceRemediationDetail', remediation.Id) ">
																						<div class="card-body pointer-cursor" *ngIf="showPerformanceRemediationDetail == remediation.AssessmentPerfRemId">
																							<div class="col-sm-12 pl-1 pr-1">
																								<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																									<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																									<span class="badge badge-warning mr-1">{{ remediation.Type }}</span>
																									{{ remediation.Description }}
																								</div>
																								<div class="float-right">
																									<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																										<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																									</div>
																								</div>
																							</div>

																							<div *ngIf="remediation.CourseDescription!=''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																								<b>Course Description : </b> {{ remediation.CourseDescription}}
																							</div>
																							<div *ngIf="remediation.CourseNumber!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																								<b>Course Number : </b> {{ remediation.CourseNumber}}
																							</div>
																							<div *ngIf="remediation.Link!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																								<b>Link : </b>
																								<a class="break-out" target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
																							</div>
																							<div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																								<label class="mb-0">
																									<b>Note : </b>
																								</label>
																								<div class="col-sm-12 mt-2 ml-2">
																									<form>
																										<div class="form-group row">
																											<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
																											 (click)="$event.stopPropagation()" readonly></textarea>
																										</div>
																									</form>
																								</div>
																							</div>
																							<div *ngIf="selectedPerformanceRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																								<span class="badge badge-dark mr-1">{{selectedPerformanceRemediationAttachment.length}}</span>
																							</div>

																							<br *ngIf="selectedPerformanceRemediationAttachment.length > 0">
																							<div *ngFor="let item of selectedPerformanceRemediationAttachment; let k = index ">
																								<ng-template [ngIf]="item.text == 'pdf' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'txt' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'docx' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'doc' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'ppt' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'pptx' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'xls' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																								<ng-template [ngIf]="item.text == 'xlsx' ">
																									<label for="default" class="">
																										<a class="btn btn-sm edit">
																											<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																											 aria-hidden="true"></i>
																										</a>
																										{{ item.fileName }} 
																									</label>
																								</ng-template>
																							</div>
																						</div>
																						<div class="card-body " *ngIf="showPerformanceRemediationDetail != remediation.AssessmentPerfRemId">
																							<div class="float-left ">
																								<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																								<span class="badge badge-warning mr-1">{{ remediation.Type }}</span>
																								{{ remediation.Description }}
																							</div>
																							<div class="float-right">
																								<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																									<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="mt-2 " *ngIf="tGtask.knowledgeList.length>0">
														<h6 class="mt-1 mb-1">Knowledge
															<span class="badge badge-warning">{{ tGtask.knowledgeList.length }}</span>
														</h6>
														<div *ngFor="let knowledge of tGtask.knowledgeList | orderBy : 'orderNum'; let k = index ">
															<div class="col-sm-12 mx-auto mt-1">
																<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																	<div class="card-body pointer-cursor">
																		<div class="float-left mt-1 mb-1">
																			<div *ngIf="(tGtask.knowledgeList.length > 1) && (k == (tGtask.knowledgeList.length - 1))"></div>
																			{{ knowledge.knowledge }}
																		</div>
																		<div class="float-right">
																			<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																				<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
																			</div>
																		</div>
																	</div>
																</div> -->

  <!-- knowledge remediation -->
  <!-- <div *ngIf="showKnowledgeDetail == knowledge.id">
																	<div class="mt-2" *ngIf="knowledge.remediationList.length>0">
																		<h6 class="mt-1 mb-1">Remediation
																			<span class="badge badge-warning">{{ knowledge.remediationList.length }}</span>
																		</h6>
																		<div *ngFor="let kremediation of knowledge.remediationList | convert : 'convertRemediationLink'; let kr = index">
																			<div class="col-sm-12 mx-auto mt-1">
																				<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
																					<div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
																						<div class="col-sm-12 pl-1 pr-1">
																							<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																								<span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
																								<span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>
																								{{ kremediation.Description }}
																							</div>
																							<div class="float-right">
																								<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																									<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																								</div>
																							</div>
																						</div>

																						<div *ngIf="kremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																							<b>Course Description : </b> {{ kremediation.CourseDescription}}
																						</div>
																						<div *ngIf="kremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																							<b>Course Number : </b> {{ kremediation.CourseNumber }}
																						</div>
																						<div *ngIf="kremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																							<b>Link : </b>
																							<a class="break-out" target="_blank" href="{{kremediation.link}}">{{kremediation.Link}}</a>
																						</div>
																						<div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																							<label class="mb-0">
																								<b>Note : </b>
																							</label>
																							<div class="col-sm-12 mt-2 ml-2">
																								<form>
																									<div class="form-group row">
																										<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
																										 (click)="$event.stopPropagation()" readonly></textarea>
																									</div>
																								</form>
																							</div>
																						</div>
																						<div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																							<span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
																						</div>
																						<br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
																						<div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
																							<ng-template [ngIf]="item.text == 'pdf' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"></i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'txt' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"></i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'docx' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"> </i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'doc' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"> </i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'ppt' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"></i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'pptx' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"></i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'xls' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"></i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																							<ng-template [ngIf]="item.text == 'xlsx' ">
																								<label for="default" class="">
																									<a class="btn btn-sm edit">
																										<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																										 aria-hidden="true"></i>
																									</a>
																									{{ item.fileName }} 
																								</label>
																							</ng-template>
																						</div>
																					</div>
																					<div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
																						<div class="float-left">
																							<span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
																							<span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>
																							{{ kremediation.Description }}
																						</div>
																						<div class="float-right">
																							<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																								<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>
													<div class="mt-2" *ngIf="tGtask.remediationList.length>0">
														<h6 class="mt-1 mb-1">Remediation
															<span class="badge badge-warning">{{ tGtask.remediationList.length }}</span>
														</h6>
														<div *ngFor="let remediation of tGtask.remediationList | convert : 'convertRemediationLink'; let tr = index ">
															<div class="col-sm-12 mx-auto mt-1">
																<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(remediation.AssessmentTaskRemId , 'taskRemediationDetail', remediation.Id)">
																	<div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail == remediation.AssessmentTaskRemId">
																		<div class="col-sm-12 pl-1 pr-1">
																			<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																				<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																				<span class="badge badge-warning mr-1">{{ remediation.Type }} </span>
																				{{ remediation.Description }}
																			</div>
																			<div class="float-right">
																				<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																					<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																				</div>
																			</div>
																		</div>

																		<div *ngIf="remediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																			<b>Course Description : </b> {{ remediation.CourseDescription }}
																		</div>
																		<div *ngIf="remediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																			<b>Course Number : </b> {{ remediation.CourseNumber }}
																		</div>
																		<div *ngIf="remediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																			<b>Link : </b>
																			<a class="break-out" target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
																		</div>
																		<div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																			<label class="mb-0">
																				<b>Note : </b>
																			</label>
																			<div class="col-sm-12 mt-2 ml-2">
																				<form>
																					<div class="form-group row">
																						<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
																						 (click)="$event.stopPropagation()" readonly></textarea>
																					</div>
																				</form>
																			</div>
																		</div>
																		<div *ngIf="selectedTaskRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																			<span class="badge badge-dark mr-1">{{selectedTaskRemediationAttachment.length}}</span>
																		</div>

																		<br *ngIf="selectedTaskRemediationAttachment.length > 0">
																		<div *ngFor="let item of selectedTaskRemediationAttachment; let k = index ">
																			<ng-template [ngIf]="item.text == 'pdf' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"> </i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'txt' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'docx' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'doc' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'ppt' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'pptx' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'xls' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																			<ng-template [ngIf]="item.text == 'xlsx' ">
																				<label for="default" class="">
																					<a class="btn btn-sm edit">
																						<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																						 aria-hidden="true"></i>
																					</a>
																					{{ item.fileName }} 
																				</label>
																			</ng-template>
																		</div>
																	</div>
																	<div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail != remediation.AssessmentTaskRemId">
																		<div class="float-left">
																			<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																			<span class="badge badge-warning mr-1">{{ remediation.Type }}</span>
																			{{ remediation.Description }}
																		</div>
																		<div class="float-right">
																			<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																				<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-template #elseTemplate>
							<div class="col-sm-12 mx-auto mt-1">
								<div class="card p-2" (click)="showCollapsePanel(task.id, 'task')">
									<div class="card-body pointer-cursor">
										<div class="float-left mt-1 mb-1">
											<div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
											{{ task.text }}
										</div>
										<div class="float-right">
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
												<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
											</div>
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
												<h6 class="m-0" *ngIf="task.criticalityValue == 1">
													<span class="badge badge-success">Low</span>
												</h6>
												<h6 class="m-0" *ngIf="task.criticalityValue == 2">
													<span class="badge badge-warning">Medium</span>
												</h6>
												<h6 class="m-0" *ngIf="task.criticalityValue == 3">
													<span class="badge badge-danger">High</span>
												</h6>
											</div>
										</div>
									</div>
								</div>

								<div class="col-sm-12 mt-1 mb-1" *ngIf="showTaskDetail == task.id && ( task.performanceList.length>0 || task.knowledgeList.length>0 || task.remediationList.length>0 ) ">
									<div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
										<div class="mt-2" *ngIf="task.performanceList.length>0">
											<h6 class="mt-1 mb-1">Performance
												<span class="badge badge-warning">{{ task.performanceList.length }}</span>
											</h6>
											<div *ngFor="let performance of task.performanceList | orderBy : 'orderNum' ; let p = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2" (click)="showCollapsePanel(performance.id, 'performance')">
														<div class="card-body pointer-cursor">
															<div class="float-left mt-1 mb-1">
																<div *ngIf="(task.performanceList.length > 1) && (p == (task.performanceList.length - 1))"></div>
																{{ performance.text }}
															</div>
															<div class="float-right">
																<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																	<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
																</div>
															</div>
														</div>
													</div> -->


  <!-- show performance detail -->
  <!-- <div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id && (performance.checkList.length>0 || performance.remediationList.length>0 ) ">
														<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
															<div class="mt-2" *ngIf="performance.checkList.length>0">
																<h6 class="mt-1 mb-1">Check List
																	<span class="badge badge-warning">{{ performance.checkList.length }}</span>
																</h6>
																<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
																	<div class="col-sm-12 mx-auto mt-1">
																		<div class="card p-2" (click)="showCollapsePanel(checkList.id, 'checkListRemediation')">
																			<div class="card-body pointer-cursor">
																				<div class="float-left mt-1 mb-1">
																					<div *ngIf="(performance.checkList.length > 1) && (ch == (performance.checkList.length - 1))"></div>
																					{{ checkList.text }}
																				</div>
																				<div class="float-right">
																					<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																						<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
																					</div>
																				</div>
																			</div>
																		</div> -->

  <!-- checkList remediation -->
  <!-- <div *ngIf="showCheckListDetail == checkList.id">
																			<div class="mt-2" *ngIf="checkList.remediationList.length>0">
																				<h6 class="mt-1 mb-1">Remediation
																					<span class="badge badge-warning">{{ checkList.remediationList.length }}</span>
																				</h6>
																				<div *ngFor="let clremediation of checkList.remediationList | convert : 'convertRemediationLink'; let clr = index">
																					<div class="col-sm-12 mx-auto mt-1">
																						<div class="card p-2" (click)="showCollapsePanel(clremediation.AssessmentchecklistRemId , 'checklistRemediationDetail', clremediation.Id)">
																							<div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail == clremediation.AssessmentchecklistRemId">
																								<div class="col-sm-12 pl-1 pr-1">
																									<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																										<span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}</span>
																										<span class="badge badge-warning mr-1">{{ clremediation.Type }}</span>
																										{{ clremediation.Description }}
																									</div>
																									<div class="float-right">
																										<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																											<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																										</div>
																									</div>
																								</div>

																								<div *ngIf="clremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																									<b>Course Description : </b>
																									{{ clremediation.CourseDescription }}
																								</div>
																								<div *ngIf="clremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																									<b>Course Number : </b>
																									{{ clremediation.CourseNumber }}
																								</div>
																								<div *ngIf="clremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																									<b>Link : </b>
																									<a class="break-out" target="_blank" href="{{clremediation.link}}">{{clremediation.Link}}</a>
																								</div>
																								<div *ngIf="clremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																									<label class="mb-0">
																										<b>Note : </b>
																									</label>
																									<div class="col-sm-12 mt-2 ml-2">
																										<form>
																											<div class="form-group row">
																												<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="clremediation.Note"
																												 (click)="$event.stopPropagation()" readonly></textarea>
																											</div>
																										</form>
																									</div>
																								</div>
																								<div *ngIf="selectedChecklistRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																									<span class="badge badge-dark ml-1">{{selectedChecklistRemediationAttachment.length}}</span>
																								</div>

																								<br *ngIf="selectedChecklistRemediationAttachment.length > 0">
																								<div *ngFor="let item of selectedChecklistRemediationAttachment; let k = index ">
																									<ng-template [ngIf]="item.text == 'pdf' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'txt' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'docx' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'doc' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'ppt' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id,'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'pptx' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'xls' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																									<ng-template [ngIf]="item.text == 'xlsx' ">
																										<label for="default" class="">
																											<a class="btn btn-sm edit">
																												<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'checklistRemediationDetail');"
																												 aria-hidden="true"></i>
																											</a>
																											{{ item.fileName }} 
																										</label>
																									</ng-template>
																								</div>
																							</div>
																							<div class="card-body pointer-cursor" *ngIf="showCheckListRemediationDetail != clremediation.AssessmentchecklistRemId">
																								<div class="float-left">
																									<span class="badge badge-info mr-1">{{ clremediation.DeliveryMedium }}</span>
																									<span class="badge badge-warning mr-1">{{ clremediation.Type }}</span>
																									{{ clremediation.Description}}
																								</div>
																								<div class="float-right">
																									<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																										<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true}"></i>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>

																	</div>
																</div>
															</div>

															<div class="mt-2 " *ngIf='performance.remediationList.length>0'>
																<h6 class="mt-1 mb-1 ">Remediation
																	<span class="badge badge-warning">{{ performance.remediationList.length }}</span>
																</h6>
																<div *ngFor="let remediation of performance.remediationList | convert : 'convertRemediationLink'; let rem=i ndex ">
																	<div class="col-sm-12 mx-auto mt-1 pointer-cursor">
																		<div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentPerfRemId , 'performanceRemediationDetail', remediation.Id) ">
																			<div class="card-body pointer-cursor" *ngIf="showPerformanceRemediationDetail == remediation.AssessmentPerfRemId">
																				<div class="col-sm-12 pl-1 pr-1">
																					<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																						<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																						<span class="badge badge-warning mr-1">{{ remediation.Type }}</span>
																						{{ remediation.Description }}
																					</div>
																					<div class="float-right">
																						<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																							<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																						</div>
																					</div>
																				</div>

																				<div *ngIf="remediation.CourseDescription!=''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																					<b>Course Description : </b> {{ remediation.CourseDescription}}
																				</div>
																				<div *ngIf="remediation.CourseNumber!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																					<b>Course Number : </b> {{ remediation.CourseNumber}}
																				</div>
																				<div *ngIf="remediation.Link!=''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																					<b>Link : </b>
																					<a class="break-out" target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
																				</div>
																				<div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																					<label class="mb-0">
																						<b>Note : </b>
																					</label>
																					<div class="col-sm-12 mt-2 ml-2">
																						<form>
																							<div class="form-group row">
																								<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
																								 (click)="$event.stopPropagation()" readonly></textarea>
																							</div>
																						</form>
																					</div>
																				</div>
																				<div *ngIf="selectedPerformanceRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																					<span class="badge badge-dark mr-1">{{selectedPerformanceRemediationAttachment.length}}</span>
																				</div>
																				<br *ngIf="selectedPerformanceRemediationAttachment.length > 0">
																				<div *ngFor="let item of selectedPerformanceRemediationAttachment; let k = index ">
																					<ng-template [ngIf]="item.text == 'pdf' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'txt' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'docx' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'doc' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'ppt' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'pptx' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'xls' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																					<ng-template [ngIf]="item.text == 'xlsx' ">
																						<label for="default" class="">
																							<a class="btn btn-sm edit">
																								<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'performanceRemediationDetail');"
																								 aria-hidden="true"></i>
																							</a>
																							{{ item.fileName }} 
																						</label>
																					</ng-template>
																				</div>
																			</div>
																			<div class="card-body " *ngIf="showPerformanceRemediationDetail != remediation.AssessmentPerfRemId">
																				<div class="float-left ">
																					<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																					<span class="badge badge-warning mr-1">{{ remediation.Type }}</span>
																					{{ remediation.Description }}
																				</div>
																				<div class="float-right">
																					<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																						<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>


										<div class="mt-2 " *ngIf="task.knowledgeList.length>0">
											<h6 class="mt-1 mb-1">Knowledge
												<span class="badge badge-warning">{{ task.knowledgeList.length }}</span>
											</h6>
											<div *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
														<div class="card-body pointer-cursor">
															<div class="float-left mt-1 mb-1">
																<div *ngIf="(task.knowledgeList.length > 1) && (k == (task.knowledgeList.length - 1))"></div>
																{{ knowledge.knowledge }}
															</div>
															<div class="float-right">
																<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																	<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
																</div>
															</div>
														</div>
													</div> -->

  <!-- knowledge remediation -->
  <!-- <div *ngIf="showKnowledgeDetail == knowledge.id">
														<div class="mt-2" *ngIf="knowledge.remediationList.length>0">
															<h6 class="mt-1 mb-1">Remediation
																<span class="badge badge-warning">{{ knowledge.remediationList.length }}</span>
															</h6>
															<div *ngFor="let kremediation of knowledge.remediationList | convert : 'convertRemediationLink'; let kr = index">
																<div class="col-sm-12 mx-auto mt-1">
																	<div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
																		<div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
																			<div class="col-sm-12 pl-1 pr-1">
																				<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																					<span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
																					<span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>
																					{{ kremediation.Description }}
																				</div>
																				<div class="float-right">
																					<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																						<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																					</div>
																				</div>
																			</div>

																			<div *ngIf="kremediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																				<b>Course Description : </b> {{ kremediation.CourseDescription}}
																			</div>
																			<div *ngIf="kremediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																				<b>Course Number : </b> {{ kremediation.CourseNumber }}
																			</div>
																			<div *ngIf="kremediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																				<b>Link : </b>
																				<a class="break-out" target="_blank" href="{{kremediation.link}}">{{kremediation.Link}}</a>
																			</div>
																			<div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																				<label class="mb-0">
																					<b>Note : </b>
																				</label>
																				<div class="col-sm-12 mt-2 ml-2">
																					<form>
																						<div class="form-group row">
																							<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
																							 (click)="$event.stopPropagation()" readonly></textarea>
																						</div>
																					</form>
																				</div>
																			</div>
																			<div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																				<span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
																			</div>
																			<br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
																			<div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
																				<ng-template [ngIf]="item.text == 'pdf' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'txt' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'docx' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'doc' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'ppt' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'pptx' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'xls' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																				<ng-template [ngIf]="item.text == 'xlsx' ">
																					<label for="default" class="">
																						<a class="btn btn-sm edit">
																							<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																							 aria-hidden="true"></i>
																						</a>
																						{{ item.fileName }} 
																					</label>
																				</ng-template>
																			</div>
																		</div>
																		<div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
																			<div class="float-left">
																				<span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
																				<span class="badge badge-warning mr-1">{{ kremediation.Type }}</span>
																				{{ kremediation.Description }}
																			</div>
																			<div class="float-right">
																				<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																					<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
										<div class="mt-2" *ngIf="task.remediationList.length>0">
											<h6 class="mt-1 mb-1">Remediation
												<span class="badge badge-warning">{{ task.remediationList.length }}</span>
											</h6>
											<div *ngFor="let remediation of task.remediationList | convert : 'convertRemediationLink'; let tr = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentTaskRemId , 'taskRemediationDetail', remediation.Id)">
														<div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail == remediation.AssessmentTaskRemId">
															<div class="col-sm-12 pl-1 pr-1">
																<div class="col-sm-11 float-left mr-0 pr-0 pl-0">
																	<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																	<span class="badge badge-warning mr-1">{{ remediation.Type }} </span>
																	{{ remediation.Description }}
																</div>
																<div class="float-right">
																	<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																		<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
																	</div>
																</div>
															</div>

															<div *ngIf="remediation.CourseDescription != ''" class="col-sm-12 float-left mt-3 mb-1 pl-0">
																<b>Course Description : </b> {{ remediation.CourseDescription }}
															</div>
															<div *ngIf="remediation.CourseNumber != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																<b>Course Number : </b> {{ remediation.CourseNumber }}
															</div>
															<div *ngIf="remediation.Link != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																<b>Link : </b>
																<a class="break-out" target="_blank" href="{{remediation.link}}">{{remediation.Link}}</a>
															</div>
															<div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
																<label class="mb-0">
																	<b>Note : </b>
																</label>
																<div class="col-sm-12 mt-2 ml-2">
																	<form>
																		<div class="form-group row">
																			<textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
																			 (click)="$event.stopPropagation()" readonly></textarea>
																		</div>
																	</form>
																</div>
															</div>
															<div *ngIf="selectedTaskRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
																<span class="badge badge-dark mr-1">{{selectedTaskRemediationAttachment.length}}</span>
															</div>

															<br *ngIf="selectedTaskRemediationAttachment.length > 0">
															<div *ngFor="let item of selectedTaskRemediationAttachment; let k = index ">
																<ng-template [ngIf]="item.text == 'pdf' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'txt' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'docx' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'doc' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'ppt' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'pptx' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'xls' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
																<ng-template [ngIf]="item.text == 'xlsx' ">
																	<label for="default" class="">
																		<a class="btn btn-sm edit">
																			<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																			 aria-hidden="true"></i>
																		</a>
																		{{ item.fileName }} 
																	</label>
																</ng-template>
															</div>
														</div>
														<div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail != remediation.AssessmentTaskRemId">
															<div class="float-left">
																<span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
																<span class="badge badge-warning mr-1">{{ remediation.Type }}</span>
																{{ remediation.Description }}
															</div>
															<div class="float-right">
																<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																	<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</ng-template>
					</div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div>

			</div>
		</div> -->
  <!-- </div> -->
  <!-- </div>
</div> -->
