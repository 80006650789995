import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';

import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { CoreCompetencyService } from './corecompetency.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service';

import { CoreCompetency } from './corecompetency.model';
import { JobProfile } from '../../jobprofile/jobprofile.model'

import { TagInputModule } from 'ngx-chips';
import { CoreCompetencyTagService } from '../../tag/corecompetencytag/corecompetencytag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-add-core-competency',
	templateUrl: './addcorecompetency.component.html'
})

export class AddCoreCompetencyComponent implements OnInit {

	addCoreCompetency: FormGroup;
	public autocompleteItemsAsObjects = [];
	public selectedTags = [];

	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private coreCompetencyService: CoreCompetencyService,
		private coreCompetencyTagService: CoreCompetencyTagService,
		private userService: UserService,
		private jobProfileService: JobProfileService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.loadActiveCoreCompetencyTags();
		this.addCoreCompetency = new FormGroup({
			coreCompetency: new FormControl(null, Validators.required),
			coreCompetencyTag: new FormControl(null)
		});
	}

	cancel() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('CoreCompetency');
		}
	}

	saveAndNew() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.addJPCoreCompetency()
		} else {
			this.addNewCoreCompetency()
		}
	}

	save() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			let tempCCModel: CoreCompetency = new CoreCompetency('', this.addCoreCompetency.value.coreCompetency);
			tempCCModel.tagList = this.selectedTags;
			let jobProfileModel: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
			this.coreCompetencyService.addCoreCompetency(tempCCModel)
				.subscribe(
				data => {
					tempCCModel.id = data.coreCompetencyId
					tempCCModel.taskList = [];
					tempCCModel.taskTotal = 0
					jobProfileModel.coreCompetencyList.push(tempCCModel)
					this.addCoreCompetencyToJPSave(jobProfileModel, tempCCModel)
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			let tempCCModel: CoreCompetency = new CoreCompetency('', this.addCoreCompetency.value.coreCompetency);
			tempCCModel.tagList = this.selectedTags;
			this.coreCompetencyService.addCoreCompetency(tempCCModel)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully added the core competency");
					this.addCoreCompetency.reset();
					this.cancel();
				},
				error => {
					let err = error.json();
					this.alertServices.clear();
					this.alertServices.handleError(err);
				}
				);
		}
	}

	//add new core competency
	private addNewCoreCompetency() {
		let tempCCModel: CoreCompetency = new CoreCompetency('', this.addCoreCompetency.value.coreCompetency);
		tempCCModel.tagList = this.selectedTags;
		this.coreCompetencyService.addCoreCompetency(tempCCModel)
			.subscribe(
			data => {
				//console.log('[addNewCoreCompetency] (data) ' + JSON.stringify(data).toString())
				this.alertServices.clear();
				this.alertServices.success("Successfully added the core competency");
				// this.addCoreCompetency.reset();
			},
			error => {
				let err = error.json();
				this.alertServices.clear();
				this.alertServices.handleError(err);
			}
			);
	}

	public loadActiveCoreCompetencyTags() {
		this.coreCompetencyTagService.getActiveCoreCompetencyTags()
			.subscribe(
			data => {
				//console.log('[loadAllCoreCompetencyTag] ' + JSON.stringify(data).toString());
				this.autocompleteItemsAsObjects = []
				data.coreCompetencyTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	//add core competency from job profile screen
	private addJPCoreCompetency() {
		let tempCCModel: CoreCompetency = new CoreCompetency('', this.addCoreCompetency.value.coreCompetency);
		tempCCModel.tagList = this.selectedTags;
		let jobProfileModel: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		this.coreCompetencyService.addCoreCompetency(tempCCModel)
			.subscribe(
			data => {
				// console.log('[addJPCoreCompetency] (data) ' + JSON.stringify(data).toString())
				tempCCModel.id = data.coreCompetencyId
				tempCCModel.taskList = [];
				tempCCModel.taskTotal = 0
				jobProfileModel.coreCompetencyList.push(tempCCModel)

				this.addCoreCompetencyToJP(jobProfileModel, tempCCModel)
				// console.log('[addJPCoreCompetency] (jobProfileModel) ' + JSON.stringify(jobProfileModel).toString())
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	//add core competency to job profile
	private addCoreCompetencyToJP(jobProfile: JobProfile, coreCompetency: CoreCompetency) {
		let tempJobProfile = new JobProfile(jobProfile.jobProfileId)
		tempJobProfile.coreCompetencyList = []
		tempJobProfile.coreCompetencyList.push(coreCompetency)

		this.jobProfileService.addCoreCompetenciesToJobProfile(tempJobProfile)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully added the core competency.");
				// this.addCoreCompetency.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}

	private addCoreCompetencyToJPSave(jobProfile: JobProfile, coreCompetency: CoreCompetency) {
		let tempJobProfile = new JobProfile(jobProfile.jobProfileId)
		tempJobProfile.coreCompetencyList = []
		tempJobProfile.coreCompetencyList.push(coreCompetency)

		this.jobProfileService.addCoreCompetenciesToJobProfile(tempJobProfile)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.cancel();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}

	public addTags(event: any) {
		//console.log('[selectTags]' + JSON.stringify(event).toString());
		this.selectedTags.push(event);
	};

	public removeTags(event: any) {
		this.selectedTags = this.selectedTags.filter(tag => tag.id !== event.id);
	};
}
