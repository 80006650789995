export class Report {
	constructor(
		public reportType?: string,
		public reportName?: string,
		public reportData?: any,
		public reportDataList?: any,
		public columns?: any,
		public printData?: any,
		public reportItem?: any
	) { }
}
