import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-Ranking',
	templateUrl: './ranking.component.html',
	styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
