import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { UserService } from '../../user/user.service';
import { AlertService } from '../../util/alert/alert.service';
import { WidgetService } from '../../dashboard/widget.service';
import { SharedService } from "../../home/shared.service";
import { AssessmentService } from '../assessment.service';
import { AssessmentModel } from '../assessment.model';
import { Constants } from '../../util/const/constants'
import { environment } from '../../../environments/environment';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

declare var require: any;
const { version: appVersion } = require('../../../../package.json');

@Component({
	selector: 'app-assessmentsignature',
	templateUrl: './assessmentsignature.component.html',
	styleUrls: ['./assessmentsignature.component.scss']
})

export class AssessmentSignature implements OnInit {
	@ViewChild('SignaturePad1', { static: false }) signaturePad1: SignaturePad;
	@ViewChild('container1', { static: true }) public sigContainer1: ElementRef;

	public assessmentId: string = null;
	public type: string = null;
	public appVersion: string = ''
	public userName: string = localStorage.getItem('userName')
	public userFirstName: string = localStorage.getItem('userFirstName')
	public assessmentCompleteForm: FormGroup;

	public constants: any

	public appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
	public logoSrc: string = ''
	public headerLogoPath: string = environment.server_URL + '/images/header/'
	public show: boolean = false
	public isLoading: boolean = false;
	public assessment: AssessmentModel = null
	public errorMsg: string = ''
	private signaturePadOptions: Object = {};
	public showAssessment: boolean = false
	public success: boolean = false

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService,
		private assessmentService: AssessmentService, private alertService: AlertService, private sharedService: SharedService, private widgetService: WidgetService) {
		this.assessmentId = this.activatedRoute.snapshot.queryParams["assessmentId"];
		this.type = this.activatedRoute.snapshot.queryParams["type"];

		this.appVersion = appVersion

		if (!userService.alreadyLoggedIn()) {
			this.router.navigateByUrl('/user/login?type=' + this.type + '&assessmentId=' + this.assessmentId);
		} else {
			if (this.assessmentId && (this.type == 'candidate' || this.type == 'assessor')) {
				this.isLoading = true;
				this.assessmentService.getAssessment(this.assessmentId)
					.subscribe(
					data => {
						this.alertService.clear()
						console.log(JSON.stringify(data.assessment))
						this.isLoading = false
						this.assessment = new AssessmentModel(data.assessment[0].Id);
						this.assessment.candidateSignature = data.assessment[0].CandidateSignature
						this.assessment.assessorSignature = data.assessment[0].AssessorSignature
						this.assessment.assessorCompleted = data.assessment[0].AssessorCompleted
						this.assessment.assessorId = data.assessment[0].AssessorId
						this.assessment.userId = data.assessment[0].AsseseeId
						this.assessment.assessmentScore = data.assessment[0].AssessmentScore
						if (data.assessment[0].SupervisorName) {
							this.assessment.supervisorName = data.assessment[0].SupervisorName
						} else {
							this.assessment.supervisorName = ''
						}
						this.assessment.discipline = data.assessment[0].Discipline
						this.assessment.jobProfileDesc = data.assessment[0].JobProfileDesc
						this.assessment.orgCode = data.assessment[0].OrgCode
						this.assessment.userName = data.assessment[0].EmpFirstName + ' ' + data.assessment[0].EmpLastName
						this.assessment.userEmail = data.assessment[0].Email
						this.assessment.candidateStrengths = data.assessment[0].CandidateStrengths

						if (this.assessment.assessorCompleted != 1) {
							this.show = false
							this.errorMsg = 'This assessment is not completed by the assessor.'
						} else if ((this.type == 'candidate' && this.assessment.userId != localStorage.getItem('userId')) ||
							(this.type == 'assessor' && this.assessment.assessorId != localStorage.getItem('userId'))) {
							this.errorMsg = "You don't have permission to access this assessment."
						} else if ((this.type == 'candidate' && this.assessment.candidateSignature == 1) ||
							(this.type == 'assessor' && this.assessment.assessorSignature == 1)) {
							this.errorMsg = 'You have already signed this assessment.'
						} else {
							this.show = true
							console.log(this.userName)
						}
					},
					error => {
						this.isLoading = false
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
					)
			} else {
				this.show = false
				this.errorMsg = 'Invalid URL'
			}
		}
		this.constants = Constants

		if (this.appInfo.headerLogo != "") {
			this.logoSrc = this.headerLogoPath + '' + this.appInfo.headerLogo
		} else {
			this.logoSrc = 'assets/images/logo/axis.jpg'
		}

		this.assessmentCompleteForm = new FormGroup({
			signature: new FormControl('', Validators.required),
			assessorCertification: new FormControl(null)
		})

	}

	ngOnInit() {
		this.beResponsive();

	}

	viewAssessment() {
		this.show = false
		this.showAssessment = true
		this.assessmentService.setSelectedAssessmentModel(this.assessment)
		this.assessmentService.setGenerate(false)
		this.assessmentService.setType(this.type)
	}

	showSignaturePage() {
		this.showAssessment = false
		this.show = true
		console.log(this.assessment.jobProfile)
	}

	public beResponsive() {
		if (this.signaturePad1) {
			this.signaturePad1.clear();
		}
		console.log(this.sigContainer1)
		this.size(this.sigContainer1);
	}

	public size(container: ElementRef) {
		let width = container.nativeElement.clientWidth;
		console.log('width ' + width)
		this.signaturePadOptions = {// passed through to szimek/signature_pad constructo
			'canvasWidth': ((width / 12) * 11) - 60,
		};
	}

	clearSignaturePad1() {
		this.signaturePad1.clear();
		this.assessmentCompleteForm.get("signature").setValue("");
	}

	saveSignature() {

	}

	private drawComplete() {
		this.assessmentCompleteForm.get("signature").setValue(this.signaturePad1.toDataURL());
	}

	onLoggedout() {
		let appConfig: any = JSON.parse(localStorage.getItem('appInfo'))
		localStorage.clear();
		localStorage.setItem('appInfo', JSON.stringify(appConfig));
	}

	valid() {
		if (this.type == 'candidate') {
			if (this.assessmentCompleteForm.get("signature").value) {
				return true
			} else {
				return false
			}
		} else if (this.type == 'assessor') {
			if (this.assessmentCompleteForm.value.signature) {
				return true
			} else {
				return false
			}
		}
	}

	public returnToDashBoard() {
		let selectedRole = localStorage.getItem('selectedUserRole');
		if (selectedRole == null && localStorage.getItem('userRoles') != null) {
			localStorage.setItem('capability', 'reload')
			this.router.navigateByUrl('/userrole');
		}
		else {
			let widgetList = localStorage.getItem('widgetList');
			if (selectedRole != null && widgetList != null) {
				this.router.navigateByUrl('/home');
			}
			else {
				let selectedUserRole = JSON.parse(selectedRole);
				this.sharedService.change('Dashboard');
				this.userService.getAssignedCapabalityList(selectedUserRole[0][1])
					.subscribe(
					data => {
						//get dashboard widgets
						this.widgetService.getDashboardWidgets(selectedUserRole[0][0])
					},
					error => {
					}
					);
			}
		}
	}

	submitSignature() {
		if (this.type == 'candidate') {
			this.assessmentService.submitCandidateSignature(this.assessment.id, this.signaturePad1.toDataURL())
				.subscribe(
				data => {
					this.alertService.clear()
					this.success = true
					this.show = false
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				)

		} else if (this.type == 'assessor') {
			let certificateValue;
			if(this.assessmentCompleteForm.value.assessorCertification != null && this.assessmentCompleteForm.value.assessorCertification != "null"){
				certificateValue = this.assessmentCompleteForm.value.assessorCertification
			}else{
				certificateValue = ""
			}			
			this.assessmentService.submitAssessorSignature(this.assessment.id, certificateValue, this.signaturePad1.toDataURL())
				.subscribe(
				data => {
					this.alertService.clear()
					this.success = true
					this.show = false
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				)
		}
	}

}