<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Candidate Competency</h4>
		</div>
		<div class="float-right">
			<div class="btn-group btn-group-toggle" data-toggle="buttons">
				<button type="button" class="btn btn-outline-warning" (click)="selectedComponentItem('CandidateCompetencyGraphicalView')">Graphical View</button>
				<button type="button" class="btn btn-outline-warning active" (click)="selectedComponentItem('CandidateCompetency')">Tabular View</button>				
			</div>
		</div>
	</div>	
	<hr/>
	<div class="col" style="overflow:hidden">
		<div [formGroup]="recentAssessmentForm" class="float-left">
			<input (change)="showMostRecentAssessment($event)" type="checkbox" id="recentAccessment" formControlName="recentAccessment"
			 name="recentAccessment"> Most recent assessment
		</div>
		<div class="float-right">
			<button type="button" [disabled]="!isDataExist" class="btn btn-outline-warning btn-sm" (click)="exportToExcel()">
				<i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
			</button>
		</div>
	</div>
</div>


<div class="row mx-auto mt-2">
	<div class="col-sm-12 table-responsive mt-2 mb-2">
		<table sortable-table (sorted)="onSorted($event)" class="table table-bordered  data-sortable col-sm-12">
			<thead>
				<tr *ngIf="!isFiltering">
					<th sortable-column="AssessmentScore" sortable-type="number" class="set-width text-center ">Competency Score</th>
					<th sortable-column="FirstName" sortable-type="string" class="text-center ">First Name</th>
					<th sortable-column="LastName" sortable-type="string" class="text-center ">Last Name</th>
					<th sortable-column="JobProfile" sortable-type="string" class="text-center ">Job Profile</th>
					<th sortable-column="OrgCode" sortable-type="string" class="text-center ">Org Code</th>
					<th class="text-center ">Knowledge Check Only</th>
					<th sortable-column="CompletedDate" sortable-type="number" class="text-center ">Completed Date</th>
					<th class="text-center ">Assessment Report</th>
				</tr>
				<tr *ngIf="isFiltering">
					<th class="set-width text-center ">Score</th>
					<th class="text-center ">First Name</th>
					<th class="text-center ">Last Name</th>
					<th class="text-center ">Job Profile</th>
					<th class="text-center ">Org Code</th>
					<th class="text-center ">Knowledge Check Only</th>
					<th class="text-center ">Completed Date</th>
					<th class="text-center ">Assessment Report</th>
				</tr>
			</thead>
			<tbody>
				<tr style="background-color: rgba(0, 0, 0, 0.05)">
					<th class="set-width "></th>
					<th class="">
						<input class="form-control" type="text" name="searchFirstName" [(ngModel)]="searchFirstName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchLastName" [(ngModel)]="searchLastName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
					</th>
					<th class=""></th>
					<th class="">
						<input class="form-control" type="text" name="searchCompletedDate" [(ngModel)]="searchCompletedDate"
							(keyup)="filter()" placeholder="yyyy"	minlength="4"	maxlength="4" numbersOnly >
						<!-- <my-date-picker (dateChanged)="onValueChange($event)" [options]="myDatePickerOptions" [placeholder]="myDatePickerOptions.dateFormat" [(ngModel)]="searchCompletedDate"></my-date-picker> -->
						<!-- <input class="form-control" type="text"  bsDatepicker name="searchCompletedDate"  (bsValueChange)="onValueChange($event)"   [(ngModel)]="searchCompletedDate"> -->
					</th>
					<th class=""></th>
				</tr>
				<tr *ngFor="let row of paginatedResults; let i = index" [style.background-color]="row.color">
					<td class="set-width">
						<div class="progress ">
							<div *ngIf="row.AssessmentScore < 85" class="pointer pointer-cursor" [style.left]="row.AssessmentScore + '%'">
								<div class="progress-badge">
									<p>{{ row.AssessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="row.AssessmentScore == 100" class="pointer pointer-cursor" [style.left]="99.25 + '%'">
								<div class="progress-badge float-right">
									<p>{{ row.AssessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="row.AssessmentScore >= 85 && row.AssessmentScore != 100" class="pointer pointer-cursor" [style.left]="row.AssessmentScore + '%'">
								<div class="progress-badge float-right">
									<p>{{ row.AssessmentScore }}%</p>
								</div>
							</div>
							<div class="progress-bar" [ngClass]="{'progress-bar-success': row.AssessmentScore >= 80, 'progress-bar-warning': row.AssessmentScore >= 50 && row.AssessmentScore < 80, 'progress-bar-danger' : row.AssessmentScore < 50}"
							 role="progressbar" aria-valuenow="row.AssessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="row.AssessmentScore + '%'">
							</div>
						</div>
					</td>
					<td>{{row.FirstName}}</td>
					<td>{{row.LastName}}</td>
					<td>{{row.JobProfile}}</td>
					<td>{{row.OrgCode}}</td>
					<td class="text-center">						
						<input *ngIf="row.IsKnowledgeTest == 1" type="checkbox" checked disabled>
						<input *ngIf="row.IsKnowledgeTest == 0" type="checkbox" disabled>
					</td>
					<td>{{row.CompletedDate == null ? "" : row.CompletedDate.toLocaleDateString()}}</td>
					<td class=" text-center">
						<a style="cursor: pointer" class="btn btn-sm edit " (click)="performAssessmentClickEvent(row.AssessmentModel)">
							<i class="fa fa-folder " aria-hidden="true"></i>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="col-sm-12 mb-4">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
		 [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
	</div>
</div>

<div *ngIf=isLoading class="loading"></div>