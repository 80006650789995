import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { PerformanceService } from './performance.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service'

import { Performance } from './performance.model';
import { Task } from '../task/task.model';
import { CoreCompetency } from '../corecompetency/corecompetency.model'
import { JobProfile } from '../../jobprofile/jobprofile.model'

import { TagInputModule } from 'ngx-chips';
import { PerformanceTagService } from '../../tag/performancetag/performancetag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-addperformance',
	templateUrl: './addperformance.component.html'
})

export class AddPerformanceComponent implements OnInit {

	addNewPerformanceForm: FormGroup;

	public autocompleteItemsAsObjects = [];
	public selectedTags = [];

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private performanceService: PerformanceService,
		private performanceTagService: PerformanceTagService,
		private userService: UserService,
		private jobProfileService: JobProfileService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.loadActivePerformanceTags();
		this.addNewPerformanceForm = new FormGroup({
			performance: new FormControl(null, Validators.required),
			performanceTag: new FormControl(null)
		});
	}

	//navigate to other component
	cancel() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('AllPerformance');
		}
	}

	saveAndNew() {
		let performance: string = this.addNewPerformanceForm.value.performance;
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.addJPPerformance(performance)
		} else {
			this.addPerformance(performance)
		}
	}

	save() {
		let performance: string = this.addNewPerformanceForm.value.performance;
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			let tempPerformance: Performance = new Performance('', performance, true, [], [])
			tempPerformance.tagList = this.selectedTags;
			let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
			let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
			let taskModel: Task = this.jobProfileService.getSelectedTaskModel()
			let i = taskModel.performanceList.length
			this.performanceService.addNewPerformance(tempPerformance)
				.subscribe(
				data => {
					tempPerformance.id = data.performanceId
					tempPerformance.orderNum = i++
					this.addPerformanceToJPSave(jobProfile, ccModel, taskModel, tempPerformance);
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		} else {
			let tempPerformance = new Performance('', performance, true);
			tempPerformance.tagList = this.selectedTags;
			this.performanceService.addNewPerformance(tempPerformance)
				.subscribe(
				data => {
					// console.log('[addJPPerformance] (data) ' + JSON.stringify(data).toString())
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
	}

	//add performance to DB (not job profile)
	private addPerformance(performanceText: string) {
		let tempPerformance = new Performance('', performanceText, true);
		tempPerformance.tagList = this.selectedTags;
		this.performanceService.addNewPerformance(tempPerformance)
			.subscribe(
			data => {
				// console.log('[addJPPerformance] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success(data.message);
				// this.addNewPerformanceForm.reset();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	//add performance to job profile
	private addJPPerformance(performanceText: string) {
		let tempPerformance: Performance = new Performance('', performanceText, true, [], [])
		tempPerformance.tagList = this.selectedTags;
		let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let taskModel: Task = this.jobProfileService.getSelectedTaskModel()
		let i = taskModel.performanceList.length

		this.performanceService.addNewPerformance(tempPerformance)
			.subscribe(
			data => {
				// console.log('[addJPPerformance] (data) ' + JSON.stringify(data).toString())
				tempPerformance.id = data.performanceId
				tempPerformance.orderNum = i++
				this.addPerformanceToJP(jobProfile, ccModel, taskModel, tempPerformance)
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);

	}

	//add performance to job profile server call
	private addPerformanceToJP(jp: JobProfile, cc: CoreCompetency, task: Task, per: Performance) {
		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempTask: Task = new Task(task.id, task.text, true, [], [], [])
		tempTask.performanceList.push(per)
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addPerformanceToJobProfile(tempJP)
			.subscribe(
			data => {
				task.performanceList.push(per)
				this.alertService.clear();
				this.alertService.success('Successfully added performance to the job profile.');
				// this.addNewPerformanceForm.reset();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	private addPerformanceToJPSave(jp: JobProfile, cc: CoreCompetency, task: Task, per: Performance) {
		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempTask: Task = new Task(task.id, task.text, true, [], [], [])
		tempTask.performanceList.push(per)
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addPerformanceToJobProfile(tempJP)
			.subscribe(
			data => {
				task.performanceList.push(per)
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}
	public loadActivePerformanceTags() {
		this.performanceTagService.getActivePerformanceTags()
			.subscribe(
			data => {
				//console.log('[loadActivePerformanceTags] ' + JSON.stringify(data).toString());
				this.autocompleteItemsAsObjects = []
				data.ptagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.selectedTags.push(event);
	};

	public removeTags(event: any) {
		this.selectedTags = this.selectedTags.filter(tag => tag.id !== event.id);
	};


}
