import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../util/table/table.component';

import { SharedService } from '../home/shared.service';
import { UserService } from '../user/user.service';
import { OrgtypeService } from './orgtype.service';
import { orgtype } from './orgtype';
import { AlertService } from '../util/alert/alert.service';
import { CustomModalService } from "../util/custommodal/custommodal.service";
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: "app-orgtype",
	templateUrl: "./orgtype.component.html"
})
export class OrgTypeComponent extends TableComponent {
	public selectedOrgtypeId: string;
	public selectedOrgTypeStatus: number;
	newStatus: string;
	public columns: Array<any> = [
		{ title: 'Org Type', name: 'orgtype', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'statusbutton', className: 'accepter-col-action' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];
	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	isAddOrgCodeAllowed: boolean = false;
	isDeleteOrgCodeAllowed: boolean = false;
	isModifyOrgCodeAllowed: boolean = false;
	isQuerOrgCodeAllowed: boolean = false;
	public selectedUserRole = [];
	checked: boolean = false;

	public stortedSelectedUserRole = localStorage.getItem("selectedUserRole");

	//this array store all the data from the server
	private serverResultData: Array<any> = [];
	showHideForm: FormGroup;
	constructor(
		private customModalService: CustomModalService,
		private sharedService: SharedService,
		private orgtypeService: OrgtypeService,
		private alertServices: AlertService,
		private userService: UserService
	) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}

		//get capabilities
		this.isAddOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"AddOrgCode",
			this.selectedUserRole[0][1]
		);
		this.isDeleteOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"DeleteOrgCode",
			this.selectedUserRole[0][1]
		);
		this.isModifyOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"ModifyOrgCode",
			this.selectedUserRole[0][1]
		);
		this.isQuerOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"QueryOrgCode",
			this.selectedUserRole[0][1]
		);

		//check queryorgcode capability before load table
		if (this.isQuerOrgCodeAllowed) {
			this.loadShowOrgtype();
		} else {
			//noo need to display data
		}
	}

	//on cell click event
	public onCellClick(data: any): any {
		var elementnumber = parseInt(data.row.id) - 1;
		this.selectedOrgtypeId = this.serverResultData[elementnumber].Id;
		this.selectedOrgTypeStatus = this.serverResultData[elementnumber].Status;
		this.newStatus = this.selectedOrgTypeStatus == 1 ? "disable" : "enable";

		switch (data.column) {
			case "statusbutton":
				//check modify capability
				if (this.isModifyOrgCodeAllowed) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						//do nothing
					} else {
						this.statusButtonClickEvent(
							this.selectedOrgtypeId,
							this.serverResultData[elementnumber].Status
						);
					}
				} else {
					//modify capability is not assigned
				}
				break;
			case "editbutton":
				//check modify capability
				if (this.isModifyOrgCodeAllowed) {
					this.editButtonClickEvent(this.serverResultData[elementnumber]);
				} else {
					//modify capability is not assigned
				}
				break;
			case "deletebutton":
				//check delete capability
				if (this.isDeleteOrgCodeAllowed) {
					this.deleteButtonClickEvent(this.selectedOrgtypeId);
				} else {
					//delete capability is not assigned
				}
				break;
			case "showhidebutton":
				//check delete capability
				if (this.isModifyOrgCodeAllowed) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonClickEvent(this.selectedOrgtypeId, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	//load buttons to the table
	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			if (this.data[i].status == 1) {
				if (this.isModifyOrgCodeAllowed) {
					this.data[i].statusbutton =
						'<a class="btn btn-sm edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].statusbutton =
						'<a class="btn btn-sm disabled edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			} else {
				if (this.isModifyOrgCodeAllowed) {
					if (this.data[i].showHide == 0) {
						this.data[i].statusbutton =
							'<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].statusbutton =
							'<a class="btn btn-sm edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				} else {
					this.data[i].statusbutton =
						'<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}

			if (this.serverResultData[i].Status == 1) {
				//check modify capability
				if (this.isModifyOrgCodeAllowed) {
					this.data[i].editbutton =
						'<a class="btn btn-sm edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					//modify capability is not assigned
				}
				//check delete capability
				if (this.isDeleteOrgCodeAllowed) {
					this.data[i].deletebutton =
						'<a class="btn btn-sm delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					//delete capability is not assigned
				}
			} else {
				//check modify capability
				if (this.isModifyOrgCodeAllowed) {
					this.data[i].editbutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					//modify capability is not assigned
				}
				//check delete capability
				if (this.isDeleteOrgCodeAllowed) {
					this.data[i].deletebutton =
						'<a class="btn btn-sm  disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					//delete capability is not assigned
				}
			}

			if (this.data[i].showHide == 1) {
				if (this.data[i].status == 0) {
					if (this.isModifyOrgCodeAllowed) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyOrgCodeAllowed) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}

	//navigate to add orgtype component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	private loadOrgtype() {
		this.orgtypeService.getOrgtype().subscribe(
			data => {
				this.data = [];
				//console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.orgtypeList.length);
				for (let i = 0; i < data.orgtypeList.length; i++) {
					this.data.push({
						id: i + 1,
						orgtype: data.orgtypeList[i].Orgtype,
						status: data.orgtypeList[i].Status,
						showHide: data.orgtypeList[i].ShowHide,
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.orgtypeList;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	//edit button click event
	private editButtonClickEvent(selectedOrgtype: any) {
		const editOrgtype = new orgtype(
			selectedOrgtype.Id,
			selectedOrgtype.Orgtype
		);

		this.orgtypeService.setSelectedOrgtypeForEdit(editOrgtype);
		this.sharedService.change("EditOrgtype");
	}

	//delete button click event
	private deleteButtonClickEvent(id) {
		let itemType = "Org Type";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	// delete button save
	private delete() {
		this.orgtypeService.deleteOrgtype(this.selectedOrgtypeId).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertServices.clear();
				this.alertServices.handleError(err);
			}
		);
	}

	private statusButtonClickEvent(id: string, status: string) {
		let itemType = "Org Type";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private changeStatus(orgTypeId: string, status: number) {
		this.orgtypeService
			.changeOrgTypeStatus(orgTypeId, status == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	private loadShowOrgtype() {
		this.orgtypeService.getShowOrgtype().subscribe(
			data => {
				this.data = [];
				//console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.orgtypeList.length);
				for (let i = 0; i < data.orgtypeList.length; i++) {
					this.data.push({
						id: i + 1,
						orgtype: data.orgtypeList[i].Orgtype,
						status: data.orgtypeList[i].Status,
						showHide: data.orgtypeList[i].ShowHide,
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: ""
					});
				}
				this.serverResultData = data.orgtypeList;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	showHide(event) {		
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQuerOrgCodeAllowed) {
				this.loadOrgtype();
			} else {
				//noo need to display data
			}
		} else {
			this.loadShowOrgtype();
		}
	}

	loadData() {
		if (this.checked) {
			this.loadOrgtype();
		}
		else {
			this.loadShowOrgtype();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Org Type";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	public showHideEvent(selectedOrgtypeId: string, showHide: number) {
		this.orgtypeService.changeOrgTypeShowHide(selectedOrgtypeId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}
}
