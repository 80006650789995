import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { user } from './user';
import { UserService } from './user.service';
import { AlertService } from '../util/alert/alert.service';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { AppService } from '../app.service'
declare var Fingerprint2: any;

import { Widget } from '../dashboard/widget.model'
import { WidgetService } from '../dashboard/widget.service';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
	myForm: FormGroup;
	fp: any;
	isLoading;
	@ViewChild('emailModel', { static: true }) emailModel: ModalComponent;
	@ViewChild('successModel', { static: true }) successModel: ModalComponent;
	@ViewChild('errorModel', { static: true }) errorModel: ModalComponent;
	public assessmentId: string = '';
	public type: string = '';
	public appConfig: any = JSON.parse(localStorage.getItem('appInfo'))
	public logoSrc: string = ''
	public loginLogoPath: string = environment.server_URL + '/images/login/'

	constructor(private userService: UserService, private router: Router, private appService: AppService,
		private alertServices: AlertService, private widgetService: WidgetService, private activatedRoute: ActivatedRoute) {
		this.assessmentId = this.activatedRoute.snapshot.queryParams["assessmentId"];
		this.type = this.activatedRoute.snapshot.queryParams["type"];
		if (userService.isValidLoggedIn()) {
			if (this.assessmentId != "null" && this.assessmentId != null && this.assessmentId !== undefined &&
				this.type != "null" && this.type != null && this.type !== undefined) {
				this.router.navigateByUrl('/assessmentsignature?type=' + this.type + '&assessmentId=' + this.assessmentId);
			} else if (this.assessmentId != "null" && this.assessmentId != null && this.assessmentId !== undefined) {
				this.router.navigateByUrl('/selfassessment?assessmentId=' + this.assessmentId);
			} else {
				this.router.navigateByUrl('/home');
			}
		}

		if (this.appConfig.loginLogo != "") {
			this.logoSrc = this.loginLogoPath + '' + this.appConfig.loginLogo
		} else {
			this.logoSrc = 'assets/images/logo/axis_logo.png'
		}

	}

	onSubmit() {
		window.localStorage.clear()
		this.alertServices.clear()
		this.isLoading = true;
		localStorage.setItem('appInfo', JSON.stringify(this.appConfig));
		const tempUser = new user(this.myForm.value.email, this.myForm.value.password);
		tempUser.deviceId = (<HTMLInputElement>document.getElementById('deviceId')).value;

		this.userService.validateUser(tempUser).subscribe(
			data => {
				// console.log('===data :' + JSON.stringify(data));
				this.isLoading = false;
				if (data.generateKeyCode === undefined) {
					if (data.token === undefined) {
						this.router.navigateByUrl('/user/login');
					} else {
						localStorage.setItem('token', data.token);
						localStorage.setItem('userId', data.userId);
						localStorage.setItem('userName', data.userName);
						localStorage.setItem('userFirstName', data.firstName)
						localStorage.setItem('expiredIn', data.expiredIn);
						localStorage.setItem('TimeZone', data.timeZone);
						// console.log('[onSubmit] (userRoles) ' + JSON.stringify(data.userRoles))
						// local storage can not store arrays need to sringify it
						if (this.assessmentId != "null" && this.assessmentId != null && this.assessmentId !== undefined &&
							this.type != "null" && this.type != null && this.type !== undefined) {
							this.router.navigateByUrl('/assessmentsignature?type=' + this.type + '&assessmentId=' + this.assessmentId);
							if (data.userRoles.length > 1) {
								localStorage.setItem('userRoles', JSON.stringify(data.userRoles));
							} else {
								localStorage.setItem('selectedUserRole', JSON.stringify(data.userRoles));
							}
						} else if (this.assessmentId != "null" && this.assessmentId != null && this.assessmentId !== undefined) {
							this.router.navigateByUrl('/selfassessment?assessmentId=' + this.assessmentId);
							if (data.userRoles.length > 1) {
								localStorage.setItem('userRoles', JSON.stringify(data.userRoles));
							} else {
								localStorage.setItem('selectedUserRole', JSON.stringify(data.userRoles));
							}
						} else if (data.userRoles.length > 1) {
							localStorage.setItem('userRoles', JSON.stringify(data.userRoles));
							this.router.navigateByUrl('/userrole');
						} else {
							localStorage.setItem('selectedUserRole', JSON.stringify(data.userRoles));
							// get assigned capability list
							let roleId = data.userRoles[0][0]
							// console.log('[userRoleId] ' + data.userRoles[0][0])
							if (localStorage.getItem('capability') == null) {
								this.userService.getAssignedCapabalityList(data.userRoles[0][1])
									.subscribe(
									data => {
										localStorage.setItem('widgetList', null);
										// get dashboard widgets
										this.widgetService.getDashboardWidgets(roleId)
									},
									error => {
									}
									);
							}
						}
					}
					// console.log('undefined');

				} else {
					if (data.generateKeyCode) {
						var url = '/user/keycode?userId=' + data.userId;
						if (this.assessmentId) {
							url += '&assessmentId=' + this.assessmentId
						}
						if (this.type) {
							url += '&type=' + this.type
						}
						this.router.navigateByUrl(url);
					}
				}
			},
			error => {
				this.isLoading = false;
				this.alertServices.clear();
				const err = error.json();
				if (err.title === 'WRONGPASSWORD') {
					this.alertServices.handleError(err);
					this.myForm.get('password').setValue('');
				} else if (err.title === 'INVALIDEMAIL') {
					this.alertServices.handleError(err);
					this.myForm.reset();
				} else {
					this.myForm.reset();
				}
			}
		);
	}

	ngOnInit() {
		this.myForm = new FormGroup({
			email: new FormControl(null, [
				Validators.required,
				Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
			]),
			password: new FormControl(null, Validators.required)
		});
	}

	ngAfterViewInit() {
		var options = { excludeCanvas: true }
		this.fp = new Fingerprint2(options);
		this.fp.get(function (result) {
			(<HTMLInputElement>document.getElementById('deviceId')).value = result;
		});
	}

	clickForgotpasswordLink() {
		if (this.myForm.value.email == null) {
			this.emailModel.open();
		} else {
			this.isLoading = true;
			const tempUser = new user(this.myForm.value.email);
			this.userService.validateEmail(tempUser)
				.subscribe(
				data => {
					this.isLoading = false;
					this.successModel.open();
				},
				error => {
					if (error.status === 201) {
						this.isLoading = false;
						this.errorModel.open();
					} else if (error.status === 401) {
						this.isLoading = false;
						this.errorModel.open();
					} else {
						this.isLoading = false;
						this.errorModel.open();
					}
				}
				);
			this.myForm.reset();
		}
	}

}
