<div class="col-lg-12">
    <div class="col" style="overflow:hidden">
        <div class="float-left">
            <h4>Remediation</h4>
        </div>
        <div class="float-right">
				<div class="row">
						<form [formGroup]="showHideForm" class="mr-2 ml-1">
							<div class="col-sm-12">
								<input class="mr-1" (change)="showHide($event)" [checked]="checked==true" type="checkbox" id="showHide" name="showHide"> Show Hidden
							</div>
						</form>
            <button type="button" class="btn btn-outline-warning btn-sm ml-1" (click)="selectedComponentItem('AddRemediation')" [disabled]="!isAddRemediationAllowed">
                <i class="fa fa fa-plus fa-fw"></i>&nbsp;Add New Remediation
				</button>
			</div>
        </div>
    </div>
    <hr />
</div>

<div class="col-lg-12 mx-auto">
    <alert></alert>
    <div class="table-responsive mb-2">
        <ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
            class="table-sm" style="cursor: pointer;">
        </ng-table>
    </div>
    <pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
        [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
    </pagination>
    <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
</div>