import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';

import { Performance } from './performance.model';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { PerformanceService } from './performance.service';
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
	selector: 'app-allperformance',
	templateUrl: './allperformance.component.html'
})
export class AllPerformanceComponent extends TableComponent {
	public selectedPerformance: any;

	public selectedPerformanceStatus: boolean = true;
	public confirmationBtnClass: string = 'btn-default';

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	private isDeletePerformance: boolean;
	private isModifyPerformance: boolean;
	isAddPerformance: boolean;
	private isQueryPerformance: boolean;
	showHideForm: FormGroup;
	public tagList = [];
	public selectedPerformanceShowHide: number;
	checked: boolean = false;

	public columns: Array<any> = [
		{ title: 'Performance', name: 'performance', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'status', className: 'accepter-col-action' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	//this array store all the data from the server
	private serverResultData: Array<any> = [];

	constructor(private customModalService: CustomModalService, private alertService: AlertService, private performanceService: PerformanceService,
		private sharedService: SharedService, private userService: UserService) {
		super();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddPerformance = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.isModifyPerformance = this.userService.isCapabilityAssigned('ModifyCompLib', this.selectedUserRole[0][1]);
		this.isDeletePerformance = this.userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryPerformance = this.userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.isQueryPerformance) {
			this.loadAllShowPerfromances();
		} else {
			//do nothing
		}

		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		var elementnumber = parseInt(data.row.id);
		this.selectedPerformance = this.serverResultData[elementnumber];
		this.selectedPerformanceStatus = this.serverResultData[elementnumber].Status;
		this.selectedPerformanceShowHide = this.serverResultData[elementnumber].ShowHide;
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = 'btn-danger';
		} else {
			this.confirmationBtnClass = 'btn-success'
		}

		switch (data.column) {
			case 'editbutton':
				if (this.isModifyPerformance) {
					this.editButtonClickEvent(this.selectedPerformance);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				if (this.isDeletePerformance) {
					this.deleteButtonClickEvent(this.selectedPerformance.Id);
				} else {
					//do nothing
				}
				break;
			case 'status':
				if (this.isModifyPerformance) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						// do nothing
					} else {
						this.statusButtonClickEvent(this.selectedPerformance.Id, this.serverResultData[elementnumber].Status);
					}
				} else {
					//do nothing
				}
				break;
			case "showhidebutton":
				//check modify capability
				if (this.isModifyPerformance) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {						
						this.showHideButtonClickEvent(this.selectedPerformance.Id, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	private statusButtonClickEvent(id: string, status: number) {
		let itemType = "Performance";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private deleteButtonClickEvent(id: string) {
		let itemType = "Performance";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	////load buttons to the table
	public extendData() {
		for (let i = 0; i < this.data.length; i++) {
			//check user has modify capability
			if (this.isModifyPerformance) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status = '<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					if (this.data[i].showHide == 0) {
						this.data[i].status = '<a class="btn btn-sm disabled" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].status = '<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				}
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status = '<a class="btn btn-sm disabled"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].status = '<a class="btn btn-sm disabled" ><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}

			//check user has delete capability
			if (this.isDeletePerformance) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyPerformance) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyPerformance) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}

		}
	}

	//load all performance from the db
	private loadAllPerfromances() {
		this.performanceService.getAllPerformance()
			.subscribe(
			data => {
				this.data = [];
				//console.log('[loadAllPerformance] data : ' + JSON.stringify(data) + '/(count)' + data.performanceList.length);
				for (let i = 0; i < data.performanceList.length; i++) {
					this.data.push({
						'id': i,
						'performanceId': data.performanceList[i].Id,
						'performance': data.performanceList[i].Performance,
						'status': data.performanceList[i].Status,
						'showHide': data.performanceList[i].ShowHide,
						'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				this.serverResultData = data.performanceList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//edit button click event
	private editButtonClickEvent(selectedPerformance: any) {
		//console.log('[Performance] : ' + selectedPerformance.Id + '/' + selectedPerformance.Performance);
		this.performanceService.getAssignedPerformanceTagList(selectedPerformance.Id)
			.subscribe(
			data => {
				//console.log('[editButtonClickEvent]' + JSON.stringify(data).toString());
				this.tagList = []
				data.assignTagList.forEach(element => {
					let item = { value: element.Description, id: element.PTagId }
					this.tagList.push(item);
				});

				let tempPerformance = new Performance(selectedPerformance.Id, selectedPerformance.Performance);
				tempPerformance.tagList = this.tagList
				this.performanceService.setSelectedPerformanceForEdit(tempPerformance);
				this.sharedService.change('EditPerformance');
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}


	//delete button click event
	delete() {
		//console.log('[deletePerformance] (performanceId/performance) : ' + this.selectedPerformance.Id + '/' + this.selectedPerformance.Performance);
		const tempPerformance = new Performance(this.selectedPerformance.Id, this.selectedPerformance.Performance, this.selectedPerformance.Status);
		this.performanceService.deletePerformance(tempPerformance)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//change status of the performance
	changeStatus() {
		//console.log('[changeStatusofPerformance] (performanceId/performance) : ' + this.selectedPerformance.Id + '/' + this.selectedPerformance.Performance);
		const tempPerformance = new Performance(this.selectedPerformance.Id, this.selectedPerformance.Performance, !this.selectedPerformance.Status);
		this.performanceService.updatePerformanceStatusByPerformanceId(tempPerformance)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	private loadAllShowPerfromances() {
		this.performanceService.getAllShowPerformance()
			.subscribe(
			data => {
				this.data = [];
				//console.log('[loadAllPerformance] data : ' + JSON.stringify(data) + '/(count)' + data.performanceList.length);
				for (let i = 0; i < data.performanceList.length; i++) {
					this.data.push({
						'id': i,
						'performanceId': data.performanceList[i].Id,
						'performance': data.performanceList[i].Performance,
						'status': data.performanceList[i].Status,
						'showHide': data.performanceList[i].ShowHide,
						'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				this.serverResultData = data.performanceList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	showHide(event) {		
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryPerformance) {
				this.loadAllPerfromances();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowPerfromances();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Performance";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}


	loadData() {
		if (this.checked) {
			this.loadAllPerfromances();
		}
		else {
			this.loadAllShowPerfromances();
		}
	}

	public showHideEvent(performanceId: string, showHide: number) {
		this.performanceService.changePerformanceShowHide(performanceId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}
}
