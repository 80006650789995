<!--component html goes here -->
<div class="col-lg-12">
  <div class="col" style="overflow:hidden">
    <div class="float-left">
      <h4>Job Profile Comparison</h4>
    </div>
    <div class="float-right"></div>
  </div>
  <hr />
</div>
<div class="col-lg-12">
  <alert></alert>
  <form [formGroup]="compairForm">
    <div class="form-group row ">
      <div class="col-sm-6">
        <h6>Job Profile 1 :</h6>
        <ng-select #selecta [allowClear]="true" [items]="jobProfileListA" placeholder="Select job profile" formControlName="jobProfileA"
          (selected)="selectOptionA($event, 'selectA')" (removed)="removeJobProfileA($event, 'selectA')" ></ng-select>
      </div>
      <div class="col-sm-6">
        <h6>Job Profile 2 :</h6>
        <ng-select #selectb [allowClear]="true" [items]="jobProfileListB" placeholder="Select job profile" formControlName="jobProfileB"
          (selected)="selectOptionB($event, 'selectB')" (removed)="removeJobProfileB($event, 'selectB')" ></ng-select>
      </div>
    </div>
    <div class="form-group row mt-4 mb-2">
      <div class="col-sm-6"></div>
      <div class="col-sm-3">
        <button (click)="compairJobProfile(true)" [disabled]="!compairForm.valid" class="btn btn-warning btn-block">Difference</button>
      </div>
      <div class="col-sm-3">
        <button (click)="compairJobProfile(false)" [disabled]="!compairForm.valid" class="btn btn-warning btn-block">Similarity</button>
      </div>
    </div>
  </form>
  <hr />
  <h6 *ngIf="showHierarchy" class="">{{ resultHeading }}</h6>
  <app-ro-jphirearchy *ngIf="showHierarchy" [jobProfileModel]="jobProfile" [showProgressPlanning]="false" [resultHeading]="resultHeading"> </app-ro-jphirearchy>

</div>