<div class="row mx-auto mt-2">
    <div class="col-sm-12 table-responsive mt-2 mb-2">
        <table sortable-table (sorted)="onSorted($event)" class="table table-bordered border-dark data-sortable table-striped col-sm-12">
            <thead>
                <tr *ngIf="!isFiltering">
                    <th></th>
                    <th sortable-column="Task" sortable-type="string">Task</th>
                    <th sortable-column="Occurrences" sortable-type="number" style="width:100%;">Occurrences</th>
					 </tr>
					 <tr *ngIf="isFiltering">
							<th></th>
							<th>Task</th>
							<th style="width:100%;">Occurrences</th>
					  </tr>
            </thead>
            <tbody>
                <tr>
                    <th></th>
                    <th>
                        <input class="form-control" type="text" name="searchTask" [(ngModel)]="searchTask" (keyup)="filter()">
                    </th>
                    <th>
                        <input class="form-control" type="text" name="searchOccurrences" [(ngModel)]="searchOccurrences" (keyup)="filter()">
                    </th>
                </tr>
                <tr *ngFor="let row of paginatedResults; let i = index">
                    <td style="width:30%">
                        <div class="progress">
                            <div *ngIf="row.FailedPercentage != 0" class="progress-bar pointer-cursor progress-bar-danger" role="progressbar" [style.width]="row.FailedBadgepPosition + '%'">
                                {{row.FailedPercentage}}%
                            </div>
                            <div *ngIf="row.FailedPercentage == 0" class="progress-bar pointer-cursor progress-bar-danger" role="progressbar" [style.width]="row.FailedBadgepPosition + '%'">
                            </div>
                            <div *ngIf="row.PassPercentage != 0" class="progress-bar progress-bar-warning pointer-cursor" role="progressbar" [style.width]="row.PassBadgepPosition + '%'">
                                {{row.PassPercentage}}%
                            </div>
                            <div *ngIf="row.PassPercentage == 0" class="progress-bar progress-bar-warning pointer-cursor" role="progressbar" [style.width]="row.PassBadgepPosition + '%'">
                            </div>
                            <div *ngIf="row.HighPercentage != 0" class="progress-bar progress-bar-success pointer-cursor" role="progressbar" [style.width]="row.HighBadgepPosition + '%'">
                                {{row.HighPercentage}}%
                            </div>
                            <div *ngIf="row.HighPercentage == 0" class="progress-bar progress-bar-success pointer-cursor" role="progressbar" [style.width]="row.HighBadgepPosition + '%'">
                            </div>
                        </div>
                    </td>
                    <td style="width:60%">{{row.Task}}</td>
                    <td style="width:10%">{{row.Occurrences}}</td>
                </tr>
            </tbody>
        </table>
    <div class="col-sm-12">
        <pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages" [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
        <pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
    </div>
</div>