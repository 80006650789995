import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { orgcode } from './orgcode';

@Injectable()
export class OrgcodeService {

	constructor(private http: Http, private router: Router) { }

	// Get all posts from the API
	getOrgcode() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgcode/getOrgcode' + token, { headers: headers })
			.map(res => res.json());
	}

	addOrgCode(orgcode: orgcode) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify(orgcode);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgcode/addOrgCode' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateOrgcode(orgcode: orgcode) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify(orgcode);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgcode/updateOrgcode' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteOrgcode(orgcode: orgcode) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(orgcode);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgcode/deleteOrgcode' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActiveOrgcodes() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgcode/getActiveOrgCodes' + token, { headers: headers })
			.map(res => res.json());
	}

	getOrgcodeById(selectedNodeId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'Id': selectedNodeId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgcode/getOrgcodeById' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActiveOrgCodesAboveOrgCode(orgCodeId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'orgCodeId': orgCodeId });
		return this.http.post(environment.server_URL + '/orgcode/getActiveOrgCodesAboveOrgCode' + token, body, { headers: headers })
			.map(res => res.json());
	}
}
