import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Note } from './note.model';
import { NoteService } from './note.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-editnote',
	templateUrl: './editnote.component.html'
})
export class EditNoteComponent implements OnInit {

	public editNote: Note;
	editNoteForm: FormGroup;

	public selectedTags = [];
	public activeTagList = [];
	items = [];

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private noteService: NoteService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.editNote = noteService.getSelectedNoteForEdit();
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.editNoteForm = new FormGroup({
			id: new FormControl(this.editNote.id, Validators.required),
			note: new FormControl(this.editNote.text, Validators.required),
			noteTag: new FormControl('')
		});
	}

	//navigate to all note component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}


	onSubmit() {
		if (this.checkNeedtoUpdateNote()) {
			//console.log('[checkNeedtoUpdateNote()] (items)' + JSON.stringify(this.items).toString());
			//console.log('[checkNeedtoUpdateNote()] (tagList)' + JSON.stringify(this.editNote.tagList).toString());

			let tempNote = new Note(this.editNote.id, this.editNoteForm.value.note);
			this.noteService.updateNoteByNoteId(tempNote)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.editNote.text = this.editNoteForm.value.note;
					//console.log('[onSubmit] (this.items) ' + JSON.stringify(this.items).toString());
					//console.log('[onSubmit] (this.editNote.tagList) ' + JSON.stringify(this.editNote.tagList).toString());
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		} else {
			//no need to update note
			this.alertService.clear();
			this.alertService.error('There are no changes to update.');
		}
	}

	checkNeedtoUpdateNote(): boolean {
		if (this.editNote.text != this.editNoteForm.value.note) {
			return true;
		} else {
			return false;
		}
	}

}
