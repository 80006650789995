<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Add New User</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="container">
	<form [formGroup]="addUserForm">
		<!--first name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">First Name
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="text" class="form-control" id="firstName" placeholder="First Name" formControlName="firstName" name="firstName">
			</div>
		</div>
		<!--last name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Last Name
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName" name="lastName">
			</div>
		</div>
		<!--email-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Email
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input maxlength="100" type="email" class="form-control mb-1" id="email" placeholder="Email" formControlName="email" name="email">
				<div class="" *ngIf="addUserForm.controls.email.touched">
					<div class="alert alert-danger mb-1" role="alert" *ngIf="addUserForm.controls.email.hasError('pattern')">
						Invalid email address.
					</div>
				</div>
			</div>
		</div>
		<!--password-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Password
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input maxlength="100" type="password" class="form-control mb-1" placeholder="Password" minlength="6" pattern="^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$"
				 formControlName="password" name="password">
				<div class="" *ngIf="addUserForm.controls.password.touched">
					<div class="alert alert-danger mb-1" role="alert" *ngIf="addUserForm.controls.password.hasError('required')">
						Password is required.</div>
					<div class="alert alert-danger mb-1" role="alert" *ngIf="addUserForm.controls.password.hasError('minlength')">Your password must contain at least six characters.</div>
					<div class="alert alert-danger mb-1" role="alert" *ngIf="addUserForm.controls.password.hasError('pattern')">
						Your password must contain at least one number (0-9) and at least one lower case letter (a-z) and at least one upper case
						letter (A-Z).</div>
				</div>
			</div>
		</div>
		<!--retype password-->
		<div class="form-group row">
			<label for="inputEmail3" class="col-sm-2 col-form-label">Re-type Password
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input maxlength="100" type="password" class="form-control mb-1" placeholder="Re-type Password" formControlName="retypePwd">
				<div class="" *ngIf="addUserForm.controls.retypePwd.touched">
					<div class="alert alert-danger mb-1" role="alert" *ngIf="addUserForm.hasError('mismatchedPasswords')">Passwords do not match.</div>
				</div>
			</div>
		</div>

		<!--org id-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Code
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<ng-select [allowClear]="true" (removed)="removedOrgCode($event)" (selected)="selectedOrgCode($event)" [items]="orgList"
				 placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId">
				</ng-select>
			</div>
		</div>
		<!--Supervisor-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Supervisor</label>
			<div class="col-sm-10">
				<ng-select [multiple]="true" [allowClear]="true" [items]="supervisorList" placeholder="Select supervisor" name="supervisor" id="supervisor"
				 formControlName="supervisor">
				</ng-select>
			</div>
		</div>
		<!--Job profile-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Job Profile</label>
			<div class="col-sm-10">
				<ng-select [allowClear]="true" [items]="jobprofileList" placeholder="Select job profile" name="jobprofile" id="jobprofile"
				 formControlName="jobprofile">
				</ng-select>
			</div>
		</div>
		<!-- Is Assessed-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label"> Assess
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input type="radio" name="assess" formControlName="assess" [(ngModel)]="assess" value='True'> Yes
				<input type="radio" name="assess" formControlName="assess" [(ngModel)]="assess" value='False'> No
			</div>
		</div>
		<!-- Time zone -->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Time Zone
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<ng-select name="timeZone" id="timeZone" formControlName="timeZone" [allowClear]="true" [items]="timeZoneList" (data)="refreshValue($event)" (selected)="selected($event)" (removed)="removed($event)"
				 placeholder="Select Time Zone"></ng-select>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Contractor</label>
			<div class="col-sm-10">
				<input type="checkbox" id="isContractUser" formControlName="isContractUser" name="isContractUser"> </div>
		</div>
		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-3">
			</div>
			<!-- <div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem( 'AllUsers') ">Cancel</button>
			</div> -->
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()" (click)="save()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()" (click)="saveAndNew()">Save & New</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
			</div>
		</div>
	</form>
</div>