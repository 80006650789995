<div class="row mx-auto mb-3" #container1>
  <!-- core competency list -->

  <!-- the buttons show with hierarchy -->
  <div class="col-sm-12 mb-2" *ngIf="showccHierarchyPanel">
    <button type="button" *ngIf="showCandidateStrengthsButton" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1" (click)="showCandidateStrength()">Candidate Strengths</button>
    <button type="button" *ngIf="showOpportunityforDevelopmentButton" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1"
      (click)="showHideOpportunityPanel()">Opportunity For Development</button>
  </div>
  <!-- /the buttons show with hierarchy -->

  <!-- buttons show with cc list -->
  <div class="col-sm-12 mb-2" *ngIf="!showccHierarchyPanel">
    <button type="button" class="btn float-right btn-lg ml-1 mb-1" [disabled]="!isAnsweredCCValid || assessmentModel.selfAssessmentCompletedDate == null || disableforAdmin"
      (click)="completeBtnClickEvent()" [ngClass]="{'btn-warning': !(!isAnsweredCCValid || assessmentModel.selfAssessmentCompletedDate == null || disableforAdmin) , 'btn-secondary': (!isAnsweredCCValid || assessmentModel.selfAssessmentCompletedDate == null || disableforAdmin)}">Complete Assessment</button>
    <div class="alert alert-info float-right ml-2 mr-2" *ngIf="assessmentModel.selfAssessmentCompletedDate == null">
      Knowledge Check is not completed.
    </div>
    <div class="alert alert-info float-right ml-2 mr-2" *ngIf="assessmentModel.candidateSignature == 0 && assessmentModel.assessorSignature == 0 && assessmentModel.assessorCompleted == 1">
      Waiting for assessor and candidate signatures.
    </div>
    <div class="alert alert-info float-right ml-2 mr-2" *ngIf="assessmentModel.candidateSignature == 0 && assessmentModel.assessorSignature == 1 && assessmentModel.assessorCompleted == 1">
      Waiting for candidate signature.
    </div>
    <div class="alert alert-info float-right ml-2 mr-2" *ngIf="assessmentModel.candidateSignature == 1 && assessmentModel.assessorSignature == 0 && assessmentModel.assessorCompleted == 1">
      Waiting for assessor signature.
    </div>
  </div>
  <!-- /buttons show with cc list -->

  <!-- assessment complete panel -->
  <!--<div class="card card-main-100 col-sm-12 mb-4 pt-3 pb-3" *ngIf="showAssmentCompletePanel">
		<form [formGroup]="assessmentCompleteForm" (ngSubmit)="saveSignatures()" (window:resize)="beResponsive()" class="mb-3">
			<div class="row mx-auto mb-2">
				<div class="col-sm-6 pr-4">
					<h5>Candidate</h5>
					<hr/>
				</div>
				<div class="col-sm-6 pl-4">
					<h5>Assessor</h5>
					<hr/>
				</div>
			</div>
			<div class="row mx-auto mb-2">
				<div class="col-sm-6 pr-4">
				</div>
				<div class="col-sm-6 pl-4">
					<div class="form-group row">
						<label for="inputPassword" class="col-sm-4 col-form-label">Assessor Certification</label>
						<div class="col-sm-8">
							<input type="text" class="form-control" id="assessorCertification" formControlName="assessorCertification" placeholder="Assessor Certification">
						</div>
					</div>
				</div>
			</div>
			<div class="row mx-auto mb-2">
				<div class="col-sm-6 pr-4">
					<div class="card">
						<div class="col-sm-12">
							<button type="button" class="float-right col-sm-2 btn btn-sm btn-outline-warning mt-2 mr-2" (click)="clearSignaturePad1()">Clear</button>
						</div>
						<signature-pad [options]="signaturePadOptions" #SignaturePad1 (onEndEvent)="candidateDrawComplete()"></signature-pad>

					</div>
				</div>
				<div class="col-sm-6 pl-4">
					<div class="card">
						<div class="col-sm-12">
							<button type="button" class="float-right col-sm-2 btn btn-sm btn-outline-warning mt-2 mr-2" (click)="clearSignaturePad2()">Clear</button>
						</div>
						<signature-pad [options]="signaturePadOptions" #SignaturePad2 (onEndEvent)="assessorDrawComplete()"></signature-pad>
					</div>
				</div>
			</div>
			<div class="row mx-auto mb-4">
				<div class="col-sm-6 pr-4">
					<label class="mb-0">Signature</label>
					<div>
						<small>
							<var>{{assessmentModel.userName}}</var>
						</small>
					</div>
				</div>
				<div class="col-sm-6 pl-4">
					<label class="mb-0">Signature</label>
					<div>
						<small>
							<var>{{assessmentModel.assessorName}}</var>
						</small>
					</div>
				</div>
			</div>
			<div class="row mx-auto mb-1">
				<div class="col-sm-6 pr-4"></div>
				<div class="col-sm-6 pl-4">
					<div class="col-sm-6 float-left pl-0">
						<button type="submit" class="btn btn-warning btn-block" [disabled]="!assessmentCompleteForm.valid">Submit</button>
					</div>
					<div class="col-sm-6 float-right pr-0">
						<button type="button" class="btn btn-default btn-block" (click)="showCompleteAssessmentPanel()">Cancel</button>
					</div>
				</div>
			</div>
		</form>
	</div>-->
  <!-- /assessment complete panel -->

  <!-- candidate strength panel -->
  <div class="col-sm-12 mt-1 mb-5" *ngIf="showCandidateStrengthPanel">
    <div class="card card-main-100 p-3 ">
      <div class="card-body pointer-cursor">
        <div class="mt-2 mb-2">
          <h5>Candidate Strengths</h5>
          <hr/>
        </div>
        <div class="col-sm-12 float-left mt-1 mb-1">
          <form [formGroup]="candidateStrengthsForm" (ngSubmit)="saveCandidateStrengths()">
            <div class="form-group row">
              <textarea rows="10" type="text" formControlName="candidateStrengths" class="form-control" id="candidateStrengths" name="candidateStrengths"></textarea>
            </div>
            <div *ngIf="!assessmentCompleted" class="form-group row">
              <div class="col-sm-6"> </div>
              <div class="col-sm-3">
                <button class="btn btn-warning btn-block mt-2 " [disabled]="candidateStrengthsForm.value.candidateStrengths == assessmentModel.candidateStrengths"
                  type="submit">Save</button>
              </div>
              <div class="col-sm-3">
                <button class="btn btn-default btn-block mt-2 " type="button" (click)="cancelCandidateStrengths()">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /candidate strength panel -->

  <!-- show opportunity for development panel -->
  <div class="col-sm-12 mt-1 mb-5" *ngIf="showofdPanel">
    <div class="card card-main-100 p-3">
      <div class="card-body pointer-cursor">
        <div class="mt-2 mb-2">
          <p class="h5">Opportunity for Development</p>
          <hr/>
          <div class="mt-2">
            <form [formGroup]="opportunityForm" (ngSubmit)="addOpportunity()">
              <div *ngIf="!assessmentCompleted" class="form-group row">
                <label class="col-sm-2 col-form-label">Category</label>
                <div class="col-lg-10">
                  <ng-select [allowClear]="true" [items]="ofdCategoryList" [disabled]="disableforAdmin" placeholder="Select category" name="category"
                    id="category" formControlName="category">
                  </ng-select>
                  <!-- <select2 [data]="ofdCategoryList" [options]="ofdCategoryOption" [value]="selectedOFDCategory" (valueChanged)="changeOFDCategory($event)" formControlName="category" name="category" id="category" (click)="$event.stopPropagation()" ngDefaultControl></select2> -->
                </div>
              </div>
              <div *ngIf="!assessmentCompleted" class="form-group row p-2">
                <textarea rows="4" type="text" class="form-control" id="opportunityText" formControlName="opportunityText" placeholder="Enter opportunity Text"></textarea>
              </div>
              <div *ngIf="!assessmentCompleted" class="form-group row mb-3">
                <div class="col-sm-6"> </div>
                <div class="col-sm-3">
                  <button class="btn btn-warning btn-block mt-2 " [disabled]="!opportunityForm.valid" type="submit">Save</button>
                </div>
                <div class="col-sm-3">
                  <button class="btn btn-default btn-block mt-2 " type="button" (click)="cancelAddOpportunity()">Cancel</button>
                </div>
              </div>

              <div class="col-sm-12 mt-1" *ngIf="assessmentModel.opportunityList.length">
                <table class="table table-sm table-bordered table-striped">
                  <thead>
                    <th class="">Category</th>
                    <th class="">Task / Opportunity</th>
                    <th *ngIf="!assessmentCompleted" class=""></th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of assessmentModel.opportunityList; let i=index">
                      <td class="">{{item.categoryText}}</td>
                      <td class="">{{item.text}}</td>
                      <td *ngIf="!assessmentCompleted" class="mx-auto">
                        <button type="button" class="btn btn-sm btn-danger mr-1 ml-3" aria-label="Close" [disabled]="disableforAdmin" (click)="deleteOpportunity(item, i)">
													<span aria-hidden="true">&times;</span>
												</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /show opportunity for development panel -->

  <!-- assessment jp hierarchy -->
  <div class="col-sm-12 pl-0 pr-0">
    <!-- cclist -->
    <div *ngIf="!showccHierarchyPanel">
      <div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of jobProfileModel.coreCompetencyList | orderBy : 'assStatus'; let k = index">
        <div class="card p-2 " [ngClass]="{'card-completed': ccItem.assStatus == 3, 'card-inprogress': ccItem.assStatus == 2, 'card-notcomplete': ccItem.assStatus == 1 }"
          (click)="selectCCItem(ccItem, k)">
          <div class="card-body pointer-cursor">
            <div class="float-left mt-1 mb-1">
              {{ ccItem.text }} <span class="badge badge-warning mr-1">{{ ccItem.taskTotal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /cclist -->

    <div class="card card-main pb-2 pt-2 rounded" *ngIf="showccHierarchyPanel">
      <!-- selected ccItem -->
      <div class="row mt-1 mb-2 pl-3 pr-3">
        <div class="col-sm-10">
          <h5>{{ selectedCCItem.text }} <span class="badge badge-warning mr-1">{{ selectedCCItem.taskTotal }}</span></h5>
        </div>
        <div class="col-sm-2">
          <button type="button" class="close" (click)="closeCCItem()">
						<span aria-hidden="true">&times;</span>
					</button>
        </div>
      </div>
      <!-- /selected ccItem -->

      <div *ngFor="let task of selectedCCItem.taskList | orderBy : 'assStatus'; let t = index">
        <ng-container *ngIf="task.isTaskGroup; else elseTemplate">
          <div class="col-sm-12 mx-auto mt-1 mb-1">
            <div class="card p-2 bs-callout " [ngClass]="{'bs-callout-success': task.assStatus == 3, 'bs-callout-warning': task.assStatus == 2, 'bs-callout-default': task.assStatus == 0 }">
              <div class="card-body pointer-cursor mb-1" (click)="showCollapsePanel(task, 'taskGroup')">
                <label class="mb-0">
									<span class="badge badge-dark mr-1">Task Group</span>{{ task.taskGroup }} <span class="badge badge-warning mr-1">{{ task.taskList.length }}</span></label>
                <div class="float-right">
                  <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskGroupDetail == task.id, 'fa-angle-down': showTaskGroupDetail != task.id }"></i>
                  </div>
                </div>
              </div>
              <div *ngIf="showTaskGroupDetail == task.id">
                <div class="col-sm-12 mx-auto mt-1 mb-2" *ngFor="let tGtask of task.taskList | orderBy : 'assStatus'; let gt = index">
                  <div class="card p-2 " [ngClass]="{'card-completed': tGtask.assStatus == 3, 'card-inprogress': tGtask.assStatus == 2, 'card-notcomplete': tGtask.assStatus == 1 }"
                    (click)="$event.stopPropagation(); showCollapsePanel(tGtask, 'taskGroupTask')">
                    <div class="card-body row pointer-cursor">
                      <div class="col-sm-7 mt-1 mb-1">
                        <div class="col-sm-12 float-left">{{ tGtask.text }}</div>
                        <div class="col-sm-12 float-left" *ngIf="tGtask.regulatoryList.length > 0 && showTaskDetail == tGtask.id">
                          <span class="badge badge-custom mt-1 mr-1" *ngFor="let regulatory of tGtask.regulatoryList | orderBy : 'alphabetic'">
                            {{ regulatory.text }}</span>
                        </div>
                        <div class="col-sm-12  mt-2 float-left" *ngIf="tGtask.reasonNA && showTaskDetail == tGtask.id">
                          <div class="alert alert-warning" role="alert">
                            <strong>Reason for N/A: </strong>{{tGtask.reasonNA}}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5 float-right">
                        <div class="float-right btn btn-sm border-0 mr-1 ml-0">
                          <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == tGtask.id, 'fa-angle-down': showTaskDetail != tGtask.id }"></i>
                        </div>
                        <div class="mr-2 ml-2 float-right" *ngIf="showTaskDetail == tGtask.id">
                          <button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" [disabled]="disableforAdmin" (click)="$event.stopPropagation(); addDocumentsToTask(tGtask, gt)">
														<i class="fa fa fa-paperclip "></i>
													</button>
                        </div>

                        <div class="btn-group mr-2 ml-2 float-right" role="group" aria-label="assessment-btn-group">
                          <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                            class="btn btn-sm" (click)="$event.stopPropagation(); taskYesClickEvent(tGtask, task, selectedCCItem)"
                            [ngClass]="{'btn-success': tGtask.isYes, 'btn-outline-warning': !tGtask.isYes}">
											<i class="fa fa-check" aria-hidden="true"></i>
									</button>
                          <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                            class="btn btn-sm" (click)="$event.stopPropagation(); taskNAClickEvent(tGtask, task, selectedCCItem)"
                            [ngClass]="{'btn-warning': tGtask.isNA, 'btn-outline-warning': !tGtask.isNA}">
														N/A
									</button>
                          <button *ngIf="tGtask.isNA && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0" type="button"
                            [disabled]="disableforAdmin" (click)="$event.stopPropagation(); changeReasonForNA(tGtask, selectedCCItem, 'task')"
                            class="btn btn-outline-warning btn-sm">
										<i class="fa fa fa-list"></i>
									</button>
                        </div>

                        <div class="float-right btn btn-sm border-0 mr-1 ml-2 pr-0 pl-0">
                          <h6 class="m-0 float-right" *ngIf="tGtask.criticalityValue == 1">
                            <span class="badge badge-success">Low</span>
                          </h6>
                          <h6 class="m-0 float-right" *ngIf="tGtask.criticalityValue == 2">
                            <span class="badge badge-warning">Medium</span>
                          </h6>
                          <h6 class="m-0 float-right" *ngIf="tGtask.criticalityValue == 3">
                            <span class="badge badge-danger">High</span>
                          </h6>
                        </div>
                        <div class="float-right mr-1 ml-2" *ngIf="tGtask.regulatoryList.length > 0">
                          <label>
                            <span class="badge badge-custom">
                              <i class="fa fa-university" aria-hidden="true"></i>
                              Regulatory Agency
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /task list -->

                  <div *ngIf="showTaskDetail == tGtask.id">
                    <div class="col-sm-12 mx-auto mt-1 pb-3">
                      <div class="mt-2">
                        <h6 class="mt-1 mb-1">Performance
                          <span class="badge badge-warning">{{ tGtask.performanceList.length }}</span>
                        </h6>
                        <div *ngFor="let performance of tGtask.performanceList | orderBy : 'answer' ; let p = index ">
                          <div class="col-sm-12 mx-auto mt-1 mb-1">
                            <!-- performanceList -->
                            <div class="card p-2 " [ngClass]="{'card-completed': performance.answer != 0, ' ': performance.answer == 0 }" (click)="$event.stopPropagation(); showCollapsePanel(performance, 'performance')">
                              <div class="card-body row pointer-cursor">
                                <div class="col-sm-9 mt-1 mb-1">
                                  <div class="col-sm-12 pl-0"> {{ performance.text }} </div>
                                  <div class="col-sm-12 mt-2" *ngIf="showPerformanceDetail == performance.id">
                                    <div *ngIf="performance.reasonNA">
                                      <div class="col-sm-12  mt-2 float-left">
                                        <div class="alert alert-warning" role="alert">
                                          <strong>Reason for N/A: </strong>{{performance.reasonNA}}
                                        </div>
                                      </div>
                                    </div>
                                    <form>
                                      <div class="col-sm-10 float-left">
                                        <!-- <input type="text" class="form-control" id="performanceNote" placeholder="Note"> -->
                                        <textarea *ngIf="!assessmentCompleted || performance.assessmentNote != ''" rows="3" type="text" placeholder="Note" class="form-control"
                                          id="performanceNote" name="performanceNote" [(ngModel)]="currentPerformanceNote"
                                          (click)="$event.stopPropagation()" [disabled]="assessmentCompleted || disableforAdmin"></textarea>
                                      </div>
                                      <div *ngIf="!assessmentCompleted" class="col-sm-2 float-right mt-1">
                                        <button [disabled]="performance.assessmentNote == currentPerformanceNote || disableforAdmin" class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1"
                                          (click)="$event.stopPropagation(); submitNote(performance)">Save</button>
                                        <button class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1" [disabled]="disableforAdmin" (click)="$event.stopPropagation(); showAddNoteModal(performance, true);">Add Note</button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div class="col-sm-3">
                                  <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
                                  </div>
                                  <div class="btn-group float-right" role="group" aria-label="assessment-btn-group" [ngClass]="{'mr-4': performance.answer != 1, 'mr-2': performance.answer == 1 }">
                                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                      class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': performance.answer != 3, 'btn-success': performance.answer == 3 }"
                                      (click)="$event.stopPropagation(); changeAnswer(3, 'performance', performance, tGtask, tGtask, task)">
																			<i class="fa fa-check" aria-hidden="true"></i>
																		</button>
                                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                      class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': performance.answer != 2, 'btn-success': performance.answer == 2 }"
                                      (click)="$event.stopPropagation(); changeAnswer(2, 'performance', performance, tGtask, tGtask, task)">
																			<i class="fa fa-times" aria-hidden="true"></i>
																		</button>
                                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                      class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': performance.answer != 1, 'btn-success': performance.answer == 1}"
                                      (click)="$event.stopPropagation(); changeAnswer(1, 'performance', performance, tGtask, tGtask, task)">N/A</button>
                                    <button *ngIf="performance.answer == 1 && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0"
                                      [disabled]="disableforAdmin" type="button" (click)="$event.stopPropagation(); changeReasonForNA(performance, selectedCCItem, 'performance')"
                                      class="btn btn-secondary btn-sm pointer-cursor">
													<i class="fa fa fa-list"></i>
												</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- /performanceList -->

                            <!-- checkList -->
                            <div *ngIf="showPerformanceDetail == performance.id">
                              <div class="col-sm-12 mx-auto mt-1 pb-3">
                                <div class="mt-2">
                                  <h6 class="mt-1 mb-1">Checklist
                                    <span class="badge badge-warning">{{ performance.checkList.length }}</span>
                                  </h6>
                                  <div *ngFor="let clItem of performance.checkList | orderBy : 'answer' ; let cl = index ">
                                    <div class="col-sm-12 mx-auto mt-1 mb-1">
                                      <div class="card p-2 " [ngClass]="{'card-completed': clItem.answer != 0, ' ': clItem.answer == 0}" (click)="showCollapsePanel(clItem, 'checkList')">
                                        <div class="card-body row pointer-cursor">
                                          <div class="col-sm-8 mt-1 mb-1">
                                            <div class="col-sm-12 pl-0">{{ clItem.text }}</div>
                                            <div class="mt-2" *ngIf="showCheckListDetail == clItem.id && clItem.reasonNA">
                                              <div class="col-sm-12  mt-2 float-left">
                                                <div class="alert alert-warning" role="alert">
                                                  <strong>Reason for N/A: </strong>{{clItem.reasonNA}}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-4">
                                            <div class="float-right btn btn-sm border-0 mr-0 ml-0" *ngIf="clItem.reasonNA">
                                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == clItem.id, 'fa-angle-down': showCheckListDetail != clItem.id }"></i>
                                            </div>
                                            <div class="btn-group float-right pointer-cursor mr-2" role="group" aria-label="assessment-btn-group">
                                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': clItem.answer != 3, 'btn-success': clItem.answer == 3 }"
                                                (click)="$event.stopPropagation(); changeAnswer(3, 'checkList', clItem, performance, tGtask, null)">
																								<i class="fa fa-check" aria-hidden="true"></i>
																							</button>
                                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': clItem.answer != 2, 'btn-success': clItem.answer == 2 }"
                                                (click)="$event.stopPropagation(); changeAnswer(2, 'checkList', clItem, performance, tGtask, null)">
																								<i class="fa fa-times" aria-hidden="true"></i>
																							</button>
                                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': clItem.answer != 1, 'btn-success': clItem.answer == 1 }"
                                                (click)="$event.stopPropagation(); changeAnswer(1, 'checkList', clItem, performance, tGtask, null)">N/A</button>
                                              <button *ngIf="clItem.answer == 1 && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0" type="button"
                                                [disabled]="disableforAdmin" (click)="$event.stopPropagation(); changeReasonForNA(clItem, selectedCCItem, 'checkList')"
                                                class="btn pointer-cursor btn-sm btn-secondary">
																<i class="fa fa fa-list"></i>
															</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- /checkList -->
                          </div>
                        </div>
                      </div>

                      <div class="mt-2">
                        <h6 class="mt-1 mb-1">Knowledge
                          <span class="badge badge-warning">{{ tGtask.knowledgeList.length }}</span>
                        </h6>
                        <div *ngFor="let knowledge of tGtask.knowledgeList | orderBy : 'answer' ; let k = index">
                          <div class="col-sm-12 mx-auto mt-1 mb-1">
                            <div class="card p-2 " [ngClass]="{'card-completed': knowledge.answer != 0, ' ': knowledge.answer == 0 }" (click)="$event.stopPropagation(); showCollapsePanel(knowledge, 'knowledge')">
                              <div class="card-body row pointer-cursor">
                                <div class="col-sm-9 mt-1 mb-1">
                                  <div class="col-sm-12 pl-0">{{ knowledge.knowledge }}</div>
                                  <div class="col-sm-12 mt-2" *ngIf="showKnowledgeDetail == knowledge.id">
                                    <div *ngIf="knowledge.reasonNA">
                                      <div class="col-sm-12  mt-2 float-left">
                                        <div class="alert alert-warning" role="alert">
                                          <strong>Reason for N/A: </strong>{{knowledge.reasonNA}}
                                        </div>
                                      </div>
                                    </div>
                                    <form>
                                      <div class="col-sm-10 float-left">
                                        <textarea *ngIf="!assessmentCompleted || knowledge.assessmentNote != ''" rows="3" type="text" placeholder="Note" class="form-control"
                                          id="knowledgeNote" name="knowledgeNote" [(ngModel)]="currentKnowledgeNote" (click)="$event.stopPropagation()"
                                          [disabled]="assessmentCompleted || disableforAdmin"></textarea>
                                      </div>
                                      <div *ngIf="!assessmentCompleted" class="col-sm-2 float-right mt-1">
                                        <button [disabled]="knowledge.assessmentNote == currentKnowledgeNote || disableforAdmin" class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1"
                                          (click)="$event.stopPropagation(); submitKnowledgeNote(knowledge)">Save</button>
                                        <button class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1" [disabled]="disableforAdmin" (click)="$event.stopPropagation(); showAddNoteModal(knowledge, false);">Add Note</button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div class="col-sm-3">
                                  <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
                                  </div>
                                  <div class="btn-group float-right pointer-cursor mr-2" role="group" aria-label="assessment-btn-group">
                                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                      class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': knowledge.answer != 3, 'btn-success': knowledge.answer == 3 }"
                                      (click)="$event.stopPropagation(); changeAnswer(3, 'knowledge', knowledge, tGtask, tGtask, task)">
																			<i class="fa fa-check" aria-hidden="true"></i>
																		</button>
                                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                      class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': knowledge.answer != 2, 'btn-success': knowledge.answer == 2 }"
                                      (click)="$event.stopPropagation(); changeAnswer(2, 'knowledge', knowledge, tGtask, tGtask, task)">
																			<i class="fa fa-times" aria-hidden="true"></i>
																		</button>
                                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                      class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': knowledge.answer != 1, 'btn-success': knowledge.answer == 1 }"
                                      (click)="$event.stopPropagation(); changeAnswer(1, 'knowledge', knowledge, tGtask, tGtask, task)">N/A</button>
                                    <button *ngIf="knowledge.answer == 1 && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0"
                                      type="button" [disabled]="disableforAdmin" class="btn btn-sm" (click)="$event.stopPropagation(); changeReasonForNA(knowledge, selectedCCItem, 'knowledge')"
                                      class="btn btn-secondary btn-sm pointer-cursor">
													<i class="fa fa fa-list"></i>
												</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mt-2">
                        <h6 class="mt-1 mb-1">Document
                          <span class="badge badge-warning">{{ tGtask.documentList.length }}</span>
                        </h6>
                        <div *ngFor="let document of tGtask.documentList | orderBy : 'fileName' ; let m = index">
                          <div class="col-sm-12 mx-auto mt-1 mb-1">
                            <div class="card p-2 " (click)="showCollapsePanel(document, 'document')">
                              <div class="card-body row pointer-cursor">
                                <div class="col-sm-9 mt-1 mb-1">
                                  <div class="col-sm-12 mt-1 mb-1">
                                    {{ document.fileName }}
                                  </div>
                                  <div class="col-sm-12 mt-1 mb-1" *ngIf="showDocumentDetail == document.id">
                                    <div>Category - {{document.category}}</div>
                                    <div *ngIf="document.note != null">Note - {{document.note}}</div>
                                  </div>
                                </div>
                                <div class="col-sm-3">
                                  <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showDocumentDetail == document.id, 'fa-angle-down': showDocumentDetail != document.id }"></i>
                                  </div>
                                  <button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3" aria-label="Close" (click)="$event.stopPropagation();removeDocument(tGtask, document, gt)">
																		<span aria-hidden="true">&times;</span>
																	</button>
                                  <div class="float-right" *ngIf="showDocumentDetail == document.id">
                                    <a *ngIf="document.ext == 'pdf'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																			<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
																		</a>
                                    <a *ngIf="document.ext == 'txt'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																			<i class="fa fa-file-text-o fa-lg" aria-hidden="true"></i>
																		</a>
                                    <a *ngIf="document.ext == 'doc' || document.ext == 'docx'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																			<i class="fa fa-file-word-o fa-lg" aria-hidden="true"></i>
																		</a>
                                    <a *ngIf="document.ext == 'ppt' || document.ext == 'pptx'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																			<i class="fa fa-file-powerpoint-o fa-lg" aria-hidden="true"></i>
																		</a>
                                    <a *ngIf="document.ext == 'xls' || document.ext == 'xlsx'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																			<i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i>
																		</a>
                                    <a *ngIf="document.ext == 'png' || document.ext == 'jpg' || document.ext == 'jpeg' || document.ext == 'bmp' || document.ext == 'tiff' || document.ext == 'gif'"
                                      class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																			<i class="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
																		</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="col-sm-12 mx-auto mt-1 mb-1">
            <!-- task list -->
            <div class="card p-2 " [ngClass]="{'card-completed': task.assStatus == 3, 'card-inprogress': task.assStatus == 2, 'card-notcomplete': task.assStatus == 1 }"
              (click)="showCollapsePanel(task, 'task')">
              <div class="card-body row pointer-cursor">
                <div class="col-sm-7 mt-1 mb-1">
                  <div class="col-sm-12 float-left"> {{ task.text }}</div>
                  <div class="col-sm-12 float-left" *ngIf="task.regulatoryList.length > 0 && showTaskDetail == task.id">
                    <span class="badge badge-custom mt-1 mr-1" *ngFor="let regulatory of task.regulatoryList | orderBy : 'alphabetic'">
                        {{ regulatory.text }}</span>
                  </div>
                  <div class="col-sm-12  mt-2 float-left" *ngIf="task.reasonNA && showTaskDetail == task.id">
                    <div class="alert alert-warning" role="alert">
                      <strong>Reason for N/A: </strong>{{task.reasonNA}}
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 float-right">
                  <div class="float-right btn btn-sm border-0 mr-1 ml-0">
                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
                  </div>
                  <div class="mr-1 ml-2 float-right" *ngIf="showTaskDetail == task.id">
                    <button type="button" class="btn btn-outline-warning btn-sm " [disabled]="disableforAdmin" (click)="$event.stopPropagation(); addDocumentsToTask(task, t)">
                      <i class="fa fa fa-paperclip "></i>
                    </button>
                  </div>

                  <div class="btn-group mr-1 ml-2 float-right" role="group" aria-label="assessment-btn-group">
                    <button [disabled]="task.isNA && assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin"
                      type="button" class="btn btn-sm" (click)="$event.stopPropagation(); taskYesClickEvent(task, null, selectedCCItem)"
                      [ngClass]="{'btn-success': task.isYes, 'btn-outline-warning': !task.isYes}">
											<i class="fa fa-check" aria-hidden="true"></i>
									</button>
                    <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                      class="btn btn-sm" (click)="$event.stopPropagation(); taskNAClickEvent(task, null, selectedCCItem)" [ngClass]="{'btn-warning': task.isNA, 'btn-outline-warning': !task.isNA}">
                      N/A
                    </button>
                    <button *ngIf="task.isNA && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0" [disabled]="disableforAdmin"
                      type="button" (click)="$event.stopPropagation(); changeReasonForNA(task, selectedCCItem, 'task')" class="btn btn-outline-warning btn-sm">
                      <i class="fa fa fa-list"></i>
                    </button>
                  </div>

                  <div class="float-right btn btn-sm border-0 mr-1 ml-2 pl-0 pr-0">
                    <h6 class="m-0 float-right" *ngIf="task.criticalityValue == 1">
                      <span class="badge badge-success">Low</span>
                    </h6>
                    <h6 class="m-0 float-right" *ngIf="task.criticalityValue == 2">
                      <span class="badge badge-warning">Medium</span>
                    </h6>
                    <h6 class="m-0 float-right" *ngIf="task.criticalityValue == 3">
                      <span class="badge badge-danger">High</span>
                    </h6>
                  </div>
                  <div class="float-right mr-1 ml-2 pl-0 pr-0" *ngIf="task.regulatoryList.length > 0">
                    <label>
                      <span class="badge badge-custom">
                        <i class="fa fa-university" aria-hidden="true"></i>
                        Regulatory Agency
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- /task list -->

            <div *ngIf="showTaskDetail == task.id">
              <div class="col-sm-12 mx-auto mt-1 pb-3">
                <div class="mt-2">
                  <h6 class="mt-1 mb-1">Performance
                    <span class="badge badge-warning">{{ task.performanceList.length }}</span>
                  </h6>
                  <div *ngFor="let performance of task.performanceList | orderBy : 'answer' ; let p = index ">
                    <div class="col-sm-12 mx-auto mt-1 mb-1">
                      <!-- performanceList -->
                      <div class="card p-2 " [ngClass]="{'card-completed': performance.answer != 0, ' ': performance.answer == 0 }" (click)="showCollapsePanel(performance, 'performance')">
                        <div class="card-body row pointer-cursor">
                          <div class="col-sm-9 mt-1 mb-1">
                            <div class="col-sm-12 pl-0"> {{ performance.text }} </div>
                            <div class="mt-2" *ngIf="showPerformanceDetail == performance.id">
                              <div *ngIf="performance.reasonNA">
                                <div class="col-sm-12  mt-2 float-left">
                                  <div class="alert alert-warning" role="alert">
                                    <strong>Reason for N/A: </strong>{{performance.reasonNA}}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <form>
                                  <div class="col-sm-10 float-left">
                                    <!-- <input type="text" class="form-control" id="performanceNote" placeholder="Note"> -->
                                    <textarea *ngIf="!assessmentCompleted || performance.assessmentNote != ''" rows="3" type="text" placeholder="Note" class="form-control"
                                      id="performanceNote" name="performanceNote" [(ngModel)]="currentPerformanceNote" (click)="$event.stopPropagation()"
                                      [disabled]="assessmentCompleted || disableforAdmin"></textarea>
                                  </div>
                                  <div *ngIf="!assessmentCompleted" class="col-sm-2 float-right mt-1">
                                    <button [disabled]="performance.assessmentNote == currentPerformanceNote || disableforAdmin" class="btn btn-sm btn-warning pointer-cursor mb-1 mr-3"
                                      (click)="$event.stopPropagation(); submitNote(performance)">Save</button>
                                    <button class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1" [disabled]="disableforAdmin" (click)="$event.stopPropagation(); showAddNoteModal(performance, true);">Add Note</button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
                            </div>
                            <div class="btn-group float-right mr-2" role="group" aria-label="assessment-btn-group">
                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': performance.answer != 3, 'btn-success': performance.answer == 3 }"
                                (click)="$event.stopPropagation(); changeAnswer(3, 'performance', performance, task, task, null)">
																<i class="fa fa-check" aria-hidden="true"></i>
															</button>
                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': performance.answer != 2, 'btn-success': performance.answer == 2 }"
                                (click)="$event.stopPropagation(); changeAnswer(2, 'performance', performance, task, task, null)">
																<i class="fa fa-times" aria-hidden="true"></i>
															</button>
                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': performance.answer != 1, 'btn-success': performance.answer == 1 }"
                                (click)="$event.stopPropagation(); changeAnswer(1, 'performance', performance, task, task, null)">N/A</button>
                              <button *ngIf="performance.answer == 1 && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0"
                                [disabled]="disableforAdmin" type="button" (click)="$event.stopPropagation(); changeReasonForNA(performance, selectedCCItem, 'performance')"
                                class="btn btn-sm pointer-cursor btn-secondary">
											<i class="fa fa fa-list"></i>
										</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /performanceList -->

                      <!-- checkList -->
                      <div *ngIf="showPerformanceDetail == performance.id">
                        <div class="col-sm-12 mx-auto mt-1 pb-3">
                          <div class="mt-2">
                            <h6 class="mt-1 mb-1">Checklist
                              <span class="badge badge-warning">{{ performance.checkList.length }}</span>
                            </h6>
                            <div *ngFor="let clItem of performance.checkList | orderBy : 'answer' ; let cl = index ">
                              <div class="col-sm-12 mx-auto mt-1 mb-1">
                                <div class="card p-2 " [ngClass]="{'card-completed': clItem.answer != 0, ' ': clItem.answer == 0}" (click)="showCollapsePanel(clItem, 'checkList')">
                                  <div class="card-body row pointer-cursor">
                                    <div class="col-sm-9 mt-1 mb-1">
                                      <div class="col-sm-12 pl-0"> {{ clItem.text }}</div>
                                      <div class="mt-2" *ngIf="showCheckListDetail == clItem.id && clItem.reasonNA">
                                        <div class="col-sm-12  mt-2 float-left">
                                          <div class="alert alert-warning" role="alert">
                                            <strong>Reason for N/A: </strong>{{clItem.reasonNA}}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-3">
                                      <div class="float-right btn btn-sm border-0 mr-0 ml-0" *ngIf="clItem.reasonNA">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == clItem.id, 'fa-angle-down': showCheckListDetail != clItem.id }"></i>
                                      </div>
                                      <div class="btn-group float-right pointer-cursor mr-2" role="group" aria-label="assessment-btn-group">
                                        <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                          class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': clItem.answer != 3, 'btn-success': clItem.answer == 3 }"
                                          (click)="$event.stopPropagation(); changeAnswer(3, 'checkList', clItem, performance, task, null)">
																					<i class="fa fa-check" aria-hidden="true"></i>
																				</button>
                                        <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                          class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': clItem.answer != 2, 'btn-success': clItem.answer == 2 }"
                                          (click)="$event.stopPropagation(); changeAnswer(2, 'checkList', clItem, performance, task, null)">
																					<i class="fa fa-times" aria-hidden="true"></i>
																				</button>
                                        <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                          class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': clItem.answer != 1, 'btn-success': clItem.answer == 1 }"
                                          (click)="$event.stopPropagation(); changeAnswer(1, 'checkList', clItem, performance, task, null)">N/A</button>
                                        <button *ngIf="clItem.answer == 1 && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0" type="button"
                                          [disabled]="disableforAdmin" class="btn btn-sm" (click)="$event.stopPropagation(); changeReasonForNA(clItem, selectedCCItem, 'checkList')"
                                          class="btn btn-sm btn-secondary pointer-cursor">
														<i class="fa fa fa-list"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /checkList -->
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <h6 class="mt-1 mb-1">Knowledge
                    <span class="badge badge-warning">{{ task.knowledgeList.length }}</span>
                  </h6>
                  <div *ngFor="let knowledge of task.knowledgeList | orderBy : 'answer' ; let k = index">
                    <div class="col-sm-12 mx-auto mt-1 mb-1">
                      <div class="card p-2 " [ngClass]="{'card-completed': knowledge.answer != 0, ' ': knowledge.answer == 0 }" (click)="showCollapsePanel(knowledge, 'knowledge')">
                        <div class="card-body row pointer-cursor">
                          <div class="col-sm-9 mt-1 mb-1">
                            <div class="col-sm-12 pl-0">{{ knowledge.knowledge }}</div>
                            <div class="col-sm-12 mt-2" *ngIf="showKnowledgeDetail == knowledge.id">
                              <div *ngIf="knowledge.reasonNA">
                                <div class="col-sm-12  mt-2 float-left">
                                  <div class="alert alert-warning" role="alert">
                                    <strong>Reason for N/A: </strong>{{knowledge.reasonNA}}
                                  </div>
                                </div>
                              </div>
                              <form>
                                <div class="col-sm-10 float-left">
                                  <textarea *ngIf="!assessmentCompleted || knowledge.assessmentNote != ''" rows="3" type="text" placeholder="Note" class="form-control"
                                    id="knowledgeNote" name="knowledgeNote" [(ngModel)]="currentKnowledgeNote" (click)="$event.stopPropagation()"
                                    [disabled]="assessmentCompleted || disableforAdmin"></textarea>
                                </div>
                                <div *ngIf="!assessmentCompleted" class="col-sm-2 float-right mt-1">
                                  <button [disabled]="knowledge.assessmentNote == currentKnowledgeNote || disableforAdmin" class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1"
                                    (click)="$event.stopPropagation(); submitKnowledgeNote(knowledge)">Save</button>
                                  <button class="btn btn-sm btn-warning pointer-cursor mb-1 mr-1" [disabled]="disableforAdmin" (click)="$event.stopPropagation(); showAddNoteModal(knowledge, false);">Add Note</button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
                            </div>
                            <div class="btn-group float-right pointer-cursor mr-2" role="group" aria-label="assessment-btn-group">
                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': knowledge.answer != 3, 'btn-success': knowledge.answer == 3 }"
                                (click)="$event.stopPropagation(); changeAnswer(3, 'knowledge', knowledge, task, task, null)">
																<i class="fa fa-check" aria-hidden="true"></i>
															</button>
                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': knowledge.answer != 2, 'btn-success': knowledge.answer == 2 }"
                                (click)="$event.stopPropagation(); changeAnswer(2, 'knowledge', knowledge, task, task, null)">
																<i class="fa fa-times" aria-hidden="true"></i>
															</button>
                              <button [disabled]="assessmentModel.status == 'Completed' || assessmentModel.assessorCompleted == 1 || disableforAdmin" type="button"
                                class="btn btn-sm pointer-cursor" [ngClass]="{'btn-secondary': knowledge.answer != 1, 'btn-success': knowledge.answer == 1 }"
                                (click)="$event.stopPropagation(); changeAnswer(1, 'knowledge', knowledge, task, task, null)">N/A</button>
                              <button *ngIf="knowledge.answer == 1 && assessmentModel.status != 'Completed' && assessmentModel.assessorCompleted == 0"
                                [disabled]="disableforAdmin" type="button" (click)="$event.stopPropagation(); changeReasonForNA(knowledge, selectedCCItem, 'knowledge')"
                                class="btn pointer-cursor btn-sm btn-secondary">
											<i class="fa fa fa-list"></i>
										</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <h6 class="mt-1 mb-1">Document
                    <span class="badge badge-warning">{{ task.documentList.length }}</span>
                  </h6>
                  <div *ngFor="let document of task.documentList | orderBy : 'fileName' ; let m = index">
                    <div class="col-sm-12 mx-auto mt-1 mb-1">
                      <div class="card p-2 " (click)="showCollapsePanel(document, 'document')">
                        <div class="card-body row pointer-cursor">
                          <div class="col-sm-9 mt-1 mb-1">
                            <div class="col-sm-12 mt-1 mb-1">
                              {{ document.fileName }}
                            </div>
                            <div class="col-sm-12 mt-1 mb-1" *ngIf="showDocumentDetail == document.id">
                              <div>Category - {{document.category}}</div>
                              <div *ngIf="document.note != null">Note - {{document.note}}</div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="float-right btn btn-sm border-0 mr-0 ml-0">
                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showDocumentDetail == document.id, 'fa-angle-down': showDocumentDetail != document.id }"></i>
                            </div>
                            <button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3" aria-label="Close" (click)="$event.stopPropagation();removeDocument(task, document, t)">
															<span aria-hidden="true">&times;</span>
														</button>
                            <div class="float-right" *ngIf="showDocumentDetail == document.id">
                              <a *ngIf="document.ext == 'pdf'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																<i class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i>
															</a>
                              <a *ngIf="document.ext == 'txt'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																<i class="fa fa-file-text-o fa-lg" aria-hidden="true"></i>
															</a>
                              <a *ngIf="document.ext == 'doc' || document.ext == 'docx'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																<i class="fa fa-file-word-o fa-lg" aria-hidden="true"></i>
															</a>
                              <a *ngIf="document.ext == 'ppt' || document.ext == 'pptx'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																<i class="fa fa-file-powerpoint-o fa-lg" aria-hidden="true"></i>
															</a>
                              <a *ngIf="document.ext == 'xls' || document.ext == 'xlsx'" class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																<i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i>
															</a>
                              <a *ngIf="document.ext == 'png' || document.ext == 'jpg' || document.ext == 'jpeg' || document.ext == 'bmp' || document.ext == 'tiff' || document.ext == 'gif'"
                                class="btn btn-sm edit" (click)="$event.stopPropagation();fileIconButtonClickEvent(document.id, document.fileName, document.ext)">
																<i class="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
															</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- /assessment jp hierarchy -->
</div>

<div *ngIf=isLoading class="loading"></div>

<modal #addDocumentModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">Add Document</h4>
  </modal-header>
  <modal-body>
    <form [formGroup]="addDocumentForm">
      <div class="form-group">
        <label for="name">Category:</label>
        <select class="form-control" formControlName="category" name="category" id="category" required>
					<option value="" disabled="true">--please select--</option>
					<option *ngFor="let categoryObj of categoryList" [ngValue]="categoryObj.id"> {{ categoryObj.text }} </option>
				</select>
      </div>
      <div class="form-group">
        <input type="file" id="file" #fileInput (change)="fileChangeEvent($event)" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.jpg,.jpeg,.png,.tiff,.gif,.bmp">
      </div>
      <div class="form-group">
        <textarea type="text" class="form-control" id="note" formControlName="note" name="note"></textarea>
      </div>
    </form>
    <div style="color: red" *ngIf="showError">{{constants.INVALID_FILE_TYPE}}</div>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-warning" data-dismiss="modal" [disabled]="!addDocumentForm.valid" (click)="addDocument()">Add</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="addDocumentModal.dismiss()">Cancel</button>
  </modal-footer>
</modal>

<modal #changeAnswersModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">{{ constants.MODAL_TOPIC_OVERRIDE_ANSWERS }}</h4>
  </modal-header>
  <modal-body>
    {{ changeAnswersModalBody }}
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button *ngIf="changeYESBtnEvent" type="button" class="btn btn-success" data-dismiss="modal" (click)="removeNAFromTask()">Yes</button>
    <button *ngIf="!changeYESBtnEvent" type="button" class="btn btn-success" data-dismiss="modal" (click)="resetAnswersOfTaskItems()">Yes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeAnswersModal.dismiss()">Cancel</button>
  </modal-footer>
</modal>

<modal #changeYesAnswersModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">{{ constants.MODAL_TOPIC_OVERRIDE_ANSWERS }}</h4>
  </modal-header>
  <modal-body>
    {{ changeYesAnswersModalBody }}
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button *ngIf="changeYESAnswerEvent" type="button" class="btn btn-success" data-dismiss="modal" (click)="removeYesFromTask()">Yes</button>
    <button *ngIf="!changeYESAnswerEvent" type="button" class="btn btn-success" data-dismiss="modal" (click)="resetAnswersOfYesTaskItems()">Yes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="changeYesAnswersModal.dismiss()">Cancel</button>
  </modal-footer>
</modal>

<modal #completeAssessmentModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">{{ constants.MODAL_TOPIC_OVERRIDE_ANSWERS }}</h4>
  </modal-header>
  <modal-body>
    {{ completeAssessmentModalBody }}{{defaultReasonNA}}{{ assessmentDefaultReasonNA}}
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-success" data-dismiss="modal" (click)="NATasksAndCompleteAssessment()">Yes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="completeAssessmentModal.dismiss()">Cancel</button>
  </modal-footer>
</modal>

<modal #addReasonForNAModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">{{ constants.MODAL_TOPIC_REASON_FOR_NA }}</h4>
  </modal-header>
  <modal-body>
    <!--<div class="row">
      <label class="col-sm-12 col-form-label">Reason for N/A:</label>
		<div class="col-sm-12">
      	<div class="alert light-bg" role="alert">
          <span >{{ selectedReasonNA }}</span>
        </div>
		</div>
    </div>-->
    <div class="table-responsive mb-2 mt-2">
      <table class="table table-bordered  table-striped col-sm-12">
        <thead>
          <tr>
            <th></th>
            <th class="text-center ">Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th class="">
              <input class="form-control" type="text" name="searchReason" [(ngModel)]="searchReason" (keyup)="filterReasons()">
            </th>
          </tr>
          <tr *ngFor="let reason of reasonNAResults; let i = index">
            <td>
              <input type="checkbox" (change)="setReasonSelected($event, i)" *ngIf="reason.isSelect == true" checked>
              <input type="checkbox" (change)="setReasonSelected($event, i)" *ngIf="reason.isSelect == false">
            </td>
            <td class="">{{reason.reason}}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <pagination class="pagination-sm mb-2" [ngModel]="currentPage1" [totalItems]="total1" [itemsPerPage]="itemsPerPage1" [maxSize]="maxNoOfPages1"
      [boundaryLinks]="true" (pageChanged)="reasonPageChanged($event.page)"></pagination>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="saveReasonForNA()" [disabled]="!selectedReason">Save</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="addReasonForNAModal.dismiss()">Cancel</button>
  </modal-footer>
</modal>

<modal #addANoteModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">{{ constants.MODAL_TOPIC_ADD_NOTE }}</h4>
  </modal-header>
  <modal-body>
    <div class="table-responsive mb-2">
      <table class="table table-bordered  table-striped col-sm-12">
        <thead>
          <tr>
            <th></th>
            <th class="text-center ">Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th class="">
              <input class="form-control" type="text" name="searchNote" [(ngModel)]="searchNote" (keyup)="filter()">
            </th>
          </tr>
          <tr *ngFor="let note of paginatedResults; let i = index">
            <td>
              <input type="checkbox" (change)="setSelected($event, i)" *ngIf="note.isSelect == true" checked>
              <input type="checkbox" (change)="setSelected($event, i)" *ngIf="note.isSelect == false">
            </td>
            <td class="">{{note.note}}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
      [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-warning" data-dismiss="modal" [disabled]="selectedNoteList.length == 0" (click)="addNoteToPerformance()">Add Note</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="addANoteModal.dismiss()">Cancel</button>
  </modal-footer>
</modal>
