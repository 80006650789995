<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Edit Core Competency</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>

<div class="col-lg-12 mx-auto">
	<form [formGroup]="editCoreCompetency" (ngSubmit)="onSubmit()">
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Core Competency
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<textarea noSpaces maxlength="100" type="text" class="form-control" id="coreCompetency" formControlName="coreCompetency" name="coreCompetency"></textarea>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Tags</label>
			<div class="col-sm-10">
				<tag-input [ngModel]="items" [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" formControlName="coreCompetencyTag"
				 [displayBy]="'value'" [identifyBy]="'id'" (onAdd)="addTags($event)" (onRemove)="removeTags($event)" name="coreCompetencyTag">
					<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="activeTagList">
					</tag-input-dropdown>
				</tag-input>
			</div>
		</div>

		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-6">
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!editCoreCompetency.valid">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('CoreCompetency')">Cancel</button>
			</div>
		</div>
	</form>
</div>