import { OnInit, Component, ViewChild, ElementRef } from "@angular/core";
import { TooltipModule } from 'ngx-tooltip'
import { AssessmentService } from "../../../../assessment/assessment.service";
import { SharedService } from "../../../../home/shared.service";
import { OrderArrayByType } from '../../../../util/orderarraybytype.pipe';

@Component({
	selector: 'app-competencysnapshot',
	templateUrl: './competencysnapshot.component.html',
	styleUrls: ['./competencysnapshot.component.scss']
})

export class CompetencySnapshotComponent {
	@ViewChild('competencysnapshot', { static: true }) tblElement: ElementRef;
	public dataSet: any
	public lastScore: number;
	public jobProfile: any;
	public isKnowledgeTest: number;
	public assessmentCompletedDate: any;
	public coreCompetency: Array<any> = [];
	public snapshotArray: Array<any> = [];
	public userId = localStorage.getItem('userId');
	public assessmentId: String;
	constructor(private assessmentService: AssessmentService, private sharedService: SharedService) {
		this.dataSet = assessmentService.getCompetencySnapshotData()
		this.snapshotArray = this.dataSet.snapshotArray
		this.lastScore = this.dataSet.lastScore
		this.assessmentId = this.dataSet.assessmentId
		this.coreCompetency = this.dataSet.coreCompetency
		this.isKnowledgeTest = this.dataSet.isKnowledgeTest
		var temp = this.dataSet.assessmentCompletedDate
		if (temp) {
			this.assessmentCompletedDate = new Date(new Date(temp).getUTCFullYear(), new Date(temp).getUTCMonth(), new Date(temp).getUTCDate()).toLocaleDateString();
		}
		this.jobProfile = this.dataSet.jobProfile
		// console.log('[competencysnapshot] (snapshotArray) ' + JSON.stringify(this.snapshotArray.length));
		// console.log('[competencysnapshot] (snapshotArray) ' + JSON.stringify(this.snapshotArray));
	}

	ngOnInit() {
		// console.log('[competencysnapshot] (snapshotArray) ' + JSON.stringify(this.snapshotArray.length));
	}

	ngAfterViewInit() {
		let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
		this.sharedService.compsanpshotHeightChange(height);
	}
}