<div #employeesCompPercentage class="pointer-default">
	<div class="row">
		<div class="col-sm-6 ">
			<h5>Category
				<small class="text-muted ml-2">{{type}}</small>
			</h5>
		</div>
		<div class="col-sm-6">
			<div ngbDropdown class="dropdown float-right mr-2">
				<button class="btn btn-outline-warning btn-sm pointer-cursor dropdown-toggle" type="button" id="dropdownMenuButton"
				 aria-haspopup="true" aria-expanded="false" ngbDropdownToggle >Change Category</button>
				<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
					<a *ngFor="let option of options" class="dropdown-item" (click)="setType(option)">{{option}}</a>
				</div>
			</div>
		</div>
	</div>
	<hr />
	<div>
		<div *ngFor="let row of data" class="row mt-2" style="overflow-x: auto;overflow-y: auto;">
			<div class="col-sm-6">
				{{row.Description}}
			</div>
			<div class="col-sm-6">
				<div class="progress">
					<div data-toggle="tooltip" *ngIf="row.redPercentage != 0" data-placement="bottom" [title]="getToolTip(row.red)" class="progress-bar progress-bar-danger pointer-cursor"
					 role="progressbar" [style.width]="row.redActual + '%'">
						{{row.redPercentage}}%
					</div>
					<div data-toggle="tooltip" *ngIf="row.redPercentage == 0" data-placement="bottom" [title]="getToolTip(row.red)" class="progress-bar progress-bar-danger pointer-cursor"
					 role="progressbar" [style.width]="row.redActual + '%'">
					</div>
					<div data-toggle="tooltip" *ngIf="row.yellowPercentage != 0" data-placement="bottom" [title]="getToolTip(row.yellow)" class="progress-bar progress-bar-warning pointer-cursor"
					 role="progressbar" [style.width]="row.yellowActual + '%'">
						{{row.yellowPercentage}}%
					</div>
					<div data-toggle="tooltip" *ngIf="row.yellowPercentage == 0" data-placement="bottom" [title]="getToolTip(row.yellow)" class="progress-bar progress-bar-warning pointer-cursor"
					 role="progressbar" [style.width]="row.yellowActual + '%'">						
					</div>
					<div data-toggle="tooltip" *ngIf="row.greenPercentage != 0" data-placement="bottom" [title]="getToolTip(row.green)" class="progress-bar progress-bar-success pointer-cursor"
					 role="progressbar" [style.width]="row.greenActual + '%'">
						{{row.greenPercentage}}%
					</div>
					<div data-toggle="tooltip" *ngIf="row.greenPercentage == 0" data-placement="bottom" [title]="getToolTip(row.green)" class="progress-bar progress-bar-success pointer-cursor"
					 role="progressbar" [style.width]="row.greenActual + '%'">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>