<div class="col-sm-12 mx-auto mb-3">
	<!-- show read only jp hierarchy for comparison -->
	<div class="row" *ngIf="!showProgressPlanning">
		<div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of jobProfileModel.coreCompetencyList | orderBy : 'orderNum'; let k = index">
			<div class="card p-2 card-default " (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
				<div class="card-body pointer-cursor">
					<div class="float-left mt-1, mb-1">
						{{ ccItem.text }}
					</div>
					<div class="float-right">
						<div class="float-right btn btn-sm border-0 mr-1 ml-1">
							<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
						</div>
					</div>

				</div>
			</div>

			<div *ngIf="showCCDetail == ccItem.id">
				<div *ngIf="ccItem.taskList.length == 0 && resultHeading == 'Similarities'">
					<div class="alert alert-info">
						No matching tasks.
					</div>
				</div>
				<div *ngIf="ccItem.taskList.length == 0 && resultHeading == 'Differences'">
					<div class="alert alert-info">
						No difference tasks.
					</div>
				</div>
				<div *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let t = index ">
					<div class="col-sm-12 mx-auto mt-1">
						<div class="card p-2" (click)="showCollapsePanel(task.id, 'task')">
							<div class="card-body pointer-cursor">
								<div class="float-left mt-1, mb-1">
									{{ task.text }}
								</div>
								<div class="float-right">
									<div class="float-right btn btn-sm border-0 mr-1 ml-1">
										<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="showTaskDetail == task.id">
							<div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
								<div class="mt-2">
									<h6 class="mt-1 mb-1">Performance
										<span class="badge badge-warning">{{ task.performanceList.length }}</span>
									</h6>
									<div *ngFor="let performance of task.performanceList | orderBy : 'orderNum' ; let p = index ">
										<div class="col-sm-12 mx-auto mt-1">
											<div class="card p-2 card-default" (click)="showCollapsePanel(performance.id, 'performance')">
												<div class="card-body pointer-cursor">
													<div class="float-left mt-1 mb-1">
														{{ performance.text }}
													</div>
													<div class="float-right">
														<div class="float-right btn btn-sm border-0 mr-1 ml-1">
															<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
														</div>
													</div>
												</div>
											</div>
										</div>

										<!-- show performance detail -->
										<div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id">
											<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
												<div class="mt-2">
													<h6 class="mt-1 mb-1">Check List
														<span class="badge badge-warning">{{ performance.checkList.length }}</span>
													</h6>
													<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
														<div class="col-sm-12 mx-auto mt-1">
															<div class="card p-2" (click)="showCollapsePanel(checkList.id, 'checkListRemediation')">
																<div class="card-body pointer-cursor">
																	<div class="float-left mt-1 mb-1">
																		{{ checkList.text }}
																	</div>
																	<div class="float-right">
																		<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																			<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
																		</div>
																	</div>
																</div>
															</div>

															<!-- checkList remediation -->
															<div *ngIf="showCheckListDetail == checkList.id">
																<div *ngFor="let clremediation of checkList.remediationList; let clr = index">
																	<div class="col-sm-12 mx-auto mt-1">
																		<div class="card p-2 card-default">
																			<div class="card-body pointer-cursor">
																				<div class="float-left">{{ clremediation.text }}</div>
																				<div class="float-right"></div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

														</div>
													</div>
												</div>

												<div class="mt-2">
													<h6 class="mt-1 mb-1">Remediation
														<span class="badge badge-warning">{{ performance.remediationList.length }}</span>
													</h6>
													<div *ngFor="let remediation of performance.remediationList; let rem = index ">
														<div class="col-sm-12 mx-auto mt-1">
															<div class="card p-2">
																<div class="card-body">
																	<div class="float-left">{{ remediation.text }}</div>
																	<div class="float-right"></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

								<div class="mt-2">
									<h6 class="mt-1 mb-1">Knowledge
										<span class="badge badge-warning">{{ task.knowledgeList.length }}</span>
									</h6>
									<div *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
										<div class="col-sm-12 mx-auto mt-1">
											<div class="card p-2 card-default" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
												<div class="card-body pointer-cursor">
													<div class="float-left mt-1 mb-1">
														{{ knowledge.knowledge }}
													</div>
													<div class="float-right">
														<div class="float-right btn btn-sm border-0 mr-1 ml-1">
															<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
														</div>
													</div>
												</div>
											</div>

											<!-- knowledge remediation -->
											<div *ngIf="showKnowledgeDetail == knowledge.id">
												<div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
													<div class="col-sm-12 mx-auto mt-1">
														<div class="card p-2">
															<div class="card-body pointer-cursor">
																<div class="float-left">{{ kremediation.text }}</div>
																<div class="float-right"></div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>

								<div class="mt-2">
									<h6 class="mt-1 mb-1">Knowledge Question
										<span class="badge badge-warning">{{ task.knowledgeQuestionList.length }}</span>
									</h6>
									<div *ngFor="let knowledge of task.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
										<div class="col-sm-12 mx-auto mt-1">
											<div class="card p-2 card-default" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
												<div class="card-body pointer-cursor">
													<div class="float-left mt-1 mb-1">
														{{ knowledge.knowledge }}
													</div>
													<div class="float-right">
														<div class="float-right btn btn-sm border-0 mr-1 ml-1">
															<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
														</div>
													</div>
												</div>
											</div>

											<!-- knowledge remediation -->
											<div *ngIf="showKnowledgeDetail == knowledge.id">
												<div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
													<div class="col-sm-12 mx-auto mt-1">
														<div class="card p-2">
															<div class="card-body pointer-cursor">
																<div class="float-left">{{ kremediation.text }}</div>
																<div class="float-right"></div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>

								<div class="mt-2">
									<h6 class="mt-1 mb-1">Remediation
										<span class="badge badge-warning">{{ task.remediationList.length }}</span>
									</h6>
									<div *ngFor="let remediation of task.remediationList; let tr = index ">
										<div class="col-sm-12 mx-auto mt-1">
											<div class="card p-2 card-default">
												<div class="card-body pointer-cursor">
													<div class="float-left">{{ remediation.text }}</div>
													<div class="float-right"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
	</div>

	<!-- show progress plan jp hierarchy-->
	<div class="row" *ngIf="showProgressPlanning">
		<div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of jobProfileModel.coreCompetencyList | orderBy : 'orderNum'; let k = index">
			<div class="row">
				<div class="col-sm-4 float-left">
					<div class="card p-2 pt-3 pb-0 card-empty">
						<div class="item-progress">
							<div *ngIf="ccItem.assessmentScore < 95" class="item-pointer item-pointer-cursor" [style.left]="ccItem.assessmentScore + '%'">
								<div class="item-progress-badge">
									<p>{{ ccItem.assessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="ccItem.assessmentScore == 100" class="item-pointer item-pointer-cursor" [style.left]="99.25 + '%'">
								<div class="item-progress-badge float-right">
									<p>{{ ccItem.assessmentScore }}%</p>
								</div>
							</div>
							<div *ngIf="ccItem.assessmentScore >= 95 && ccItem.assessmentScore != 100" class="item-pointer item-pointer-cursor" [style.left]="ccItem.assessmentScore + '%'">
								<div class="item-progress-badge float-right">
									<p>{{ ccItem.assessmentScore }}%</p>
								</div>
							</div>
							<div class="item-progress-bar" [ngClass]="{'progress-bar-success': ccItem.assessmentScore >= 80, 'progress-bar-warning': ccItem.assessmentScore >= 50 && ccItem.assessmentScore < 80, 'progress-bar-danger' : ccItem.assessmentScore < 50}"
							 role="progressbar" aria-valuenow="ccItem.assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="ccItem.assessmentScore + '%'">
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-8 float-right">
					<div class="card p-2 card-default " (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
						<div class="card-body pointer-cursor">
							<div class="float-left mt-1, mb-1">
								{{ ccItem.text }}
							</div>
							<div class="float-right">
								<div class="float-right btn btn-sm border-0 mr-1 ml-1">
									<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- core competency detail -->
			<div *ngIf="showCCDetail == ccItem.id">
				<div *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let t = index " [ngClass]="{'pl-0 pr-0': task.isTaskGroup}">
					<ng-container *ngIf="task.isTaskGroup; else elseTemplate">
						<div class="row mx-auto mb-3">
							<div class="col-sm-4 float-left pl-2">
								<div class="card pl-0 pr-3 pt-3 pb-1 card-empty">
								</div>
							</div>
							<div class="col-sm-8 float-right pr-2 pl-3">
								<div class="card p-2 card-grey-lighten-3 pt-1 pb-1 pr-1" (click)="showCollapsePanel(task.id, 'taskGroup')">
									<div class="card-body pointer-cursor mb-1 float-right pr-0 pl-0">
										<label class="mb-0">
											<span class="badge badge-dark mr-1">Task Group</span>{{ task.taskGroup }}</label>
										<div class="float-right">
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
												<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskGroupDetail == task.id, 'fa-angle-down': showTaskGroupDetail != task.id }"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-12 mt-3" *ngIf="showTaskGroupDetail == task.id">
								<div *ngFor="let tGtask of task.taskList | orderBy : 'orderNum'; let gt = index">
									<div class="row">
										<div class="col-sm-4 float-left pl-2">
											<div class="card pl-0 pr-3 pt-2 pb-2 card-empty-trasparent">
												<div class="item-progress">
													<div *ngIf="tGtask.assessmentScore < 95" class="item-pointer item-pointer-cursor" [style.left]="tGtask.assessmentScore + '%'">
														<div class="item-progress-badge">
															<p>{{ tGtask.assessmentScore }}%</p>
														</div>
													</div>
													<div *ngIf="tGtask.assessmentScore == 100" class="item-pointer item-pointer-cursor" [style.left]="99.25 + '%'">
														<div class="item-progress-badge float-right">
															<p>{{ tGtask.assessmentScore }}%</p>
														</div>
													</div>
													<div *ngIf="tGtask.assessmentScore >= 95 && tGtask.assessmentScore != 100" class="item-pointer item-pointer-cursor" [style.left]="tGtask.assessmentScore + '%'">
														<div class="item-progress-badge float-right">
															<p>{{ tGtask.assessmentScore }}%</p>
														</div>
													</div>
													<div class="item-progress-bar" [ngClass]="{'progress-bar-success': tGtask.assessmentScore >= 80, 'progress-bar-warning': tGtask.assessmentScore >= 50 && tGtask.assessmentScore < 80, 'progress-bar-danger' : tGtask.assessmentScore < 50}"
													 role="progressbar" aria-valuenow="tGtask.assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="tGtask.assessmentScore + '%'">
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-8 float-right pr-3 pl-4">
											<div class="col-sm-12 pr-2 pl-2">
												<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(tGtask.id, 'taskGroupTask')">
													<div class="card-body pointer-cursor">
														<div class="float-left mt-1, mb-1">
															{{ tGtask.text }}
														</div>
														<div class="float-right">
															<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == tGtask.id, 'fa-angle-down': showTaskDetail != tGtask.id }"></i>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div *ngIf="showTaskDetail == tGtask.id">
										<div class="col-sm-12 mx-auto mt-1 pb-3">
											<div class="row">
												<div class="col-sm-4 float-left"></div>
												<div class="col-sm-8 float-right pr-3 pl-4">
													<div class="col-sm-12 pr-2 pl-3">
														<div class="card card-grey-lighten-3 pl-3 pr-3 pb-3">
															<div class="mt-2">
																<h6 class="mt-1 mb-1">Performance
																	<span class="badge badge-warning">{{ tGtask.performanceList.length }}</span>
																</h6>
																<div *ngFor="let performance of tGtask.performanceList | orderBy : 'orderNum' ; let p = index ">
																	<div class="col-sm-12 mx-auto mt-1">
																		<div class="card p-2 card-default" (click)="$event.stopPropagation();showCollapsePanel(performance.id, 'performance')">
																			<div class="card-body pointer-cursor">
																				<div class="float-left mt-1 mb-1">
																					{{ performance.text }}
																				</div>
																				<div class="float-right">
																					<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																						<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																	<!-- show performance detail -->
																	<div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id">
																		<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
																			<div class="mt-2">
																				<h6 class="mt-1 mb-1">Check List
																					<span class="badge badge-warning">{{ performance.checkList.length }}</span>
																				</h6>
																				<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
																					<div class="col-sm-12 mx-auto mt-1">
																						<div class="card p-2" (click)="$event.stopPropagation();showCollapsePanel(checkList.id, 'checkListRemediation')">
																							<div class="card-body pointer-cursor">
																								<div class="float-left mt-1 mb-1">
																									{{ checkList.text }}
																								</div>
																								<div class="float-right"></div>
																							</div>
																						</div>

																					</div>
																				</div>
																			</div>

																		</div>
																	</div>

																</div>
															</div>
															<div class="mt-2">
																<h6 class="mt-1 mb-1">Knowledge
																	<span class="badge badge-warning">{{ tGtask.knowledgeList.length }}</span>
																</h6>
																<div *ngFor="let knowledge of tGtask.knowledgeList | orderBy : 'orderNum'; let k = index ">
																	<div class="col-sm-12 mx-auto mt-1">
																		<div class="card p-2 card-default" (click)="$event.stopPropagation();showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																			<div class="card-body pointer-cursor">
																				<div class="float-left mt-1 mb-1">
																					{{ knowledge.knowledge }}
																				</div>
																				<div class="float-right"></div>
																			</div>
																		</div>

																	</div>
																</div>
															</div>
															<div class="mt-2">
																<h6 class="mt-1 mb-1">Knowledge Question
																	<span class="badge badge-warning">{{ tGtask.knowledgeQuestionList.length }}</span>
																</h6>
																<div *ngFor="let knowledge of tGtask.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
																	<div class="col-sm-12 mx-auto mt-1">
																		<div class="card p-2 card-default" (click)="$event.stopPropagation();showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																			<div class="card-body pointer-cursor">
																				<div class="float-left mt-1 mb-1">
																					{{ knowledge.knowledge }}
																				</div>
																				<div class="float-right"></div>
																			</div>
																		</div>

																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-template #elseTemplate>
						<div class="row mx-auto mt-1">
							<div class="col-sm-4 float-left pl-2">
								<div class="card pl-0 pr-3 pt-3 pb-0 card-empty">
									<div class="item-progress">
										<div *ngIf="task.assessmentScore < 95" class="item-pointer item-pointer-cursor" [style.left]="task.assessmentScore + '%'">
											<div class="item-progress-badge">
												<p>{{ task.assessmentScore }}%</p>
											</div>
										</div>
										<div *ngIf="task.assessmentScore == 100" class="item-pointer item-pointer-cursor" [style.left]="99.25 + '%'">
											<div class="item-progress-badge float-right">
												<p>{{ task.assessmentScore }}%</p>
											</div>
										</div>
										<div *ngIf="task.assessmentScore >= 95 && task.assessmentScore != 100" class="item-pointer item-pointer-cursor" [style.left]="task.assessmentScore + '%'">
											<div class="item-progress-badge float-right">
												<p>{{ task.assessmentScore }}%</p>
											</div>
										</div>
										<div class="item-progress-bar" [ngClass]="{'progress-bar-success': task.assessmentScore >= 80, 'progress-bar-warning': task.assessmentScore >= 50 && task.assessmentScore < 80, 'progress-bar-danger' : task.assessmentScore < 50}"
										 role="progressbar" aria-valuenow="task.assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="task.assessmentScore + '%'">
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-8 float-right pr-2 pl-3">
								<div class="card p-2" (click)="showCollapsePanel(task.id, 'task')">
									<div class="card-body pointer-cursor">
										<div class="float-left mt-1, mb-1">
											{{ task.text }}
										</div>
										<div class="float-right">
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
												<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="showTaskDetail == task.id">
							<div class="col-sm-12 mx-auto mt-1 pb-3">
								<div class="row">
									<div class="col-sm-4 float-left"></div>
									<div class="col-sm-8 float-right pr-2 pl-3">
										<div class="col-sm-12 mx-auto pr-3 pl-3 mb-2">
											<div class="card card-grey-lighten-3 pl-3 pr-3 pb-3">
												<div class="mt-2">
													<h6 class="mt-1 mb-1">Performance
														<span class="badge badge-warning">{{ task.performanceList.length }}</span>
													</h6>
													<div *ngFor="let performance of task.performanceList | orderBy : 'orderNum' ; let p = index ">
														<div class="col-sm-12 mx-auto mt-1">
															<div class="card p-2 card-default" (click)="showCollapsePanel(performance.id, 'performance')">
																<div class="card-body pointer-cursor">
																	<div class="float-left mt-1 mb-1">
																		{{ performance.text }}
																	</div>
																	<div class="float-right">
																		<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																			<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<!-- show performance detail -->
														<div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id">
															<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
																<div class="mt-2">
																	<h6 class="mt-1 mb-1">Check List
																		<span class="badge badge-warning">{{ performance.checkList.length }}</span>
																	</h6>
																	<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
																		<div class="col-sm-12 mx-auto mt-1">
																			<div class="card p-2" (click)="showCollapsePanel(checkList.id, 'checkListRemediation')">
																				<div class="card-body pointer-cursor">
																					<div class="float-left mt-1 mb-1">
																						{{ checkList.text }}
																					</div>
																					<div class="float-right"></div>
																				</div>
																			</div>

																		</div>
																	</div>
																</div>

															</div>
														</div>

													</div>
												</div>

												<div class="mt-2">
													<h6 class="mt-1 mb-1">Knowledge
														<span class="badge badge-warning">{{ task.knowledgeList.length }}</span>
													</h6>
													<div *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
														<div class="col-sm-12 mx-auto mt-1">
															<div class="card p-2 card-default" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																<div class="card-body pointer-cursor">
																	<div class="float-left mt-1 mb-1">
																		{{ knowledge.knowledge }}
																	</div>
																	<div class="float-right"></div>
																</div>
															</div>

														</div>
													</div>
												</div>
												<div class="mt-2">
													<h6 class="mt-1 mb-1">Knowledge Question
														<span class="badge badge-warning">{{ task.knowledgeQuestionList.length }}</span>
													</h6>
													<div *ngFor="let knowledge of task.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
														<div class="col-sm-12 mx-auto mt-1">
															<div class="card p-2 card-default" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																<div class="card-body pointer-cursor">
																	<div class="float-left mt-1 mb-1">
																		{{ knowledge.knowledge }}
																	</div>
																	<div class="float-right"></div>
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>


		</div>
	</div>

</div>