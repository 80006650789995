import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-assessmentSummary',
	templateUrl: './assessmentsummary.component.html',
	styleUrls: ['./assessmentsummary.component.scss']
})
export class AssessmentSummaryComponent implements OnInit {
	constructor() { }
	ngOnInit() { }
}
