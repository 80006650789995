import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';

import { Note } from './note.model';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { NoteService } from './note.service';
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
	selector: 'app-allnote',
	templateUrl: './allnote.component.html'
})
export class AllNoteComponent extends TableComponent {
	public selectedNote: any;
	public confirmationBtnClass: string = 'btn-default';
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	private isDeleteNote: boolean;
	private isModifyNote: boolean;
	isAddNote: boolean;
	private isQueryNote: boolean;
	public columns: Array<any> = [
		{ title: 'Note', name: 'note', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	//this array store all the data from the server
	private serverResultData: Array<any> = [];

	constructor(private customModalService: CustomModalService, private alertService: AlertService, private noteService: NoteService,
		private sharedService: SharedService, private userService: UserService) {
		super();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddNote = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.isModifyNote = this.userService.isCapabilityAssigned('ModifyCompLib', this.selectedUserRole[0][1]);
		this.isDeleteNote = this.userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryNote = this.userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.isQueryNote) {
			this.loadAllNotes();
		} else {
			//do nothing
		}
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		var elementnumber = parseInt(data.row.id);
		this.selectedNote = this.serverResultData[elementnumber];
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = 'btn-danger';
		} else {
			this.confirmationBtnClass = 'btn-success'
		}
		switch (data.column) {
			case 'editbutton':
				if (this.isModifyNote) {
					this.editButtonClickEvent(this.selectedNote);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				if (this.isDeleteNote) {
					this.deleteButtonClickEvent(this.selectedNote.Id);
				} else {
					//do nothing
				}
				break;
			default:
				break;
		}
	}

	private deleteButtonClickEvent(id: string) {
		let itemType = "Note";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	////load buttons to the table
	public extendData() {
		for (let i = 0; i < this.data.length; i++) {
			//check user has modify capability
			if (this.isModifyNote) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			}

			//check user has delete capability
			if (this.isDeleteNote) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

		}
	}

	//load all note from the db
	private loadAllNotes() {
		this.data = [];
		this.noteService.getAllNote()
			.subscribe(
			data => {
				//console.log('[loadAllNote] data : ' + JSON.stringify(data) + '/(count)' + data.noteList.length);
				for (let i = 0; i < data.noteList.length; i++) {
					this.data.push({
						'id': i,
						'noteId': data.noteList[i].Id,
						'note': data.noteList[i].Note,
						'editbutton': '', 'deletebutton': ''
					});
				}
				this.serverResultData = data.noteList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//edit button click event
	private editButtonClickEvent(selectedNote: any) {
		//console.log('[Note] : ' + selectedNote.Id + '/' + selectedNote.Note);
		let tempNote = new Note(selectedNote.Id, selectedNote.Note);
		this.noteService.setSelectedNoteForEdit(tempNote);
		this.sharedService.change('EditNote');
	}


	//delete button click event
	delete() {
		//console.log('[deleteNote] (noteId/note) : ' + this.selectedNote.Id + '/' + this.selectedNote.Note);
		const tempNote = new Note(this.selectedNote.Id, this.selectedNote.Note, this.selectedNote.Status);
		this.noteService.deleteNote(tempNote)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadAllNotes();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}
}
