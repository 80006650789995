<div class="col-lg-12">
  <div class="col" style="overflow:hidden">
    <div class="float-left">
      <h4>Regulatory Compliance</h4>
    </div>
    <div class="float-right">

    </div>
  </div>
  <hr />
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="col">
  <div class="col-lg-12 mx-auto">
    <form [formGroup]="regulatoryForm" (ngSubmit)="onSubmit()">
      <div class="form-group row mb-0">
        <div class="col-sm-8">
          <h6>Regulatory Agency:</h6>
          <ng-select [allowClear]="true" [items]="rgList" placeholder="{{ palceholderText }}" (removed)="removeRegulatoryAgency($event)"
            formControlName="regulatoryAgency" (selected)="selectRegulatoryAgency($event)">
          </ng-select>
        </div>
        <div class="col-sm-2">
          <h6>From:</h6>
          <my-date-picker (dateChanged)="assCompletedToChanged($event)" formControlName="fromDate" [options]="fromDatePickerOptions">
          </my-date-picker>
        </div>
        <div class="col-sm-2">
          <h6>To:</h6>
          <my-date-picker (dateChanged)="toDateChanged($event)" [options]="toDatePickerOptions" formControlName="toDate"></my-date-picker>
        </div>
      </div>
      <div class="form-group row mt-4 mb-2">
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
        <div class="col-sm-2">
          <button type="submit" [disabled]="!isQueryRegulatory" class="btn btn-warning pl-5 pr-5 btn-block mb-1">Show</button>
        </div>
        <div class="col-sm-2">
          <button (click)="clear()" class="btn btn-default btn-block">Clear</button>
        </div>
      </div>
      <div class="form-group row mt-4 mb-2">
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4 text-right" *ngIf="isDataExist">
          <button type="button" class="btn btn-outline-warning btn-sm mr-3" (click)="exportToExcel()">
                    <i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
                </button>

          <button type="button" class="btn btn-outline-warning btn-sm" (click)="print()">
                    <i class=" fa fa-print fa-fw "></i>&nbsp;Print
                </button>
        </div>
      </div>
    </form>
    <alert></alert>
    <div class="col-sm-12 alert alert-info ml-1 mr-1" *ngIf="noResults">
      No matching items.
    </div>
  </div>
  <br>
  <div class="col-sm-12 mt-4 " *ngIf="showResults">
    <div *ngFor="let reItem of regulatoryAgenciesData">
      <div class="col-sm-12 mx-auto pt-1 pb-1 mt-1 mt-2 pl-0 pr-0">
        <div class="card p-2" (click)="showCollapsePanel(reItem)">
          <div class="card-body pointer-cursor mb-1">
            <div class="col pr-0">
              {{ reItem.orgCode }}
              <label class="mb-0"><span
                                class="badge badge-warning mr-1">{{ reItem.userList.length }}</span></label>
              <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showItemDetail == reItem.id, 'fa-angle-down': showItemDetail != reItem.id }"></i>
              </div>
            </div>
          </div>
          <div *ngIf="showItemDetail == reItem.id">
            <div class="col-sm-12 mx-auto mt-2 mb-2" (click)="$event.stopPropagation();">
              <div class="row mx-auto mt-2">
                <div class="col-sm-12 table-responsive mt-2 mb-2">
                  <table sortable-table (sorted)="onSorted($event)" class="table table-bordered  data-sortable col-sm-12">
                    <thead>
                      <tr>
                        <th class="text-center " sortable-column="firstName">First Name</th>
                        <th class="text-center " sortable-column="lastName">Last Name</th>
                        <th class="text-center " sortable-column="jobProfile">Job Profile</th>
                        <th class="text-center " sortable-column="task">Task</th>
                        <th class="text-center ">Knowledge Check Only
                        </th>
                        <th class="text-center " sortable-column="completedDateStr">Completed Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of data; let i = index" [style.background-color]="row.Color">
                        <td style="width:10%">{{row.firstName}}</td>
                        <td style="width:10%">{{row.lastName}}</td>
                        <td style="width:10%">{{row.jobProfile}}</td>
                        <td style="width:10%">{{row.task}}</td>
                        <td style="width:5%" class="text-center">
                          <input *ngIf="row.isKnowledgeTest == 1" type="checkbox" checked disabled>
                          <input *ngIf="row.isKnowledgeTest == 0" type="checkbox" disabled>

                        </td>
                        <td style="width:10%">{{row.completedDateStr}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div class="table-responsive mb-2">
                            <ng-table [config]="config" (tableChanged)="onChangeTable(config)"
                                (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns" class="table-sm">
                            </ng-table>
                        </div>
                        <pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length"
                            [itemsPerPage]="itemsPerPage" [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false"
                            (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
                        </pagination> -->
                </div>
                <div class="col-sm-12">
                  <pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
                    [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
                  <pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
