<div class="col-lg-12">
  <div class="col" style="overflow:hidden">
    <div class="float-left">
      <h4>Missed Task Matrix</h4>
    </div>
    <div class="float-right">
      <div class="row">
      </div>
    </div>
  </div>
  <hr />
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="col">
  <div class="col-lg-12 mx-auto">
    <form [formGroup]="missedTaskMatrixForm" (ngSubmit)="onSubmit()">
      <div class="form-group row mb-4">
        <!-- <label class="col-sm-2 col-form-label">Org Code</label> -->
        <div class="col-sm-6">
          <div class="input-group">

            <ng-select [allowClear]="true" [items]="orgList" class="form-control fc-ng-select p-0" placeholder="{{ palceholderText }}"
              (removed)="removeOrgCode($event)" formControlName="missedTaskMatrix" (selected)="selectOrgCode($event)">
            </ng-select>
            <div class="input-group-append">
              <div class="input-group-text">
                <input type="checkbox" id="includeChilderen" formControlName="includeChildren" name="includeChildren">&nbsp;Include
                Children
              </div>
            </div>
          </div>

        </div>
        <div class="col-sm-3 ml-auto">

        </div>

      </div>


      <div class="form-group row mb-1">
        <div class="col-sm-3">
          <div class="btn-group btn-group-toggle float-left w-100">
            <button type="button" [ngClass]="{'active': allClicked, '' : !allClicked}" class="btn btn-sm btn-outline-warning" (click)="showAll()">All</button>
            <button type="button" [ngClass]="{'active': !allClicked, '':allClicked}" class="btn btn-sm btn-outline-warning" (click)="showRegulatoryAgency()">Regulatory Agency</button>

          </div>



        </div>

        <div class="col-sm-7">
          <div class="btn-group ml-1 float-left filter-wrapper-dd " *ngIf="!allClicked">
            <ng-select [multiple]="true" [allowClear]="true" [items]="rgList" placeholder="{{ palceholderTextReg }}" class="filter-ngselect"
              (data)="selectRegulatoryAgency($event)">
            </ng-select>
          </div>
        </div>
        <div class="col-sm-2 ml-auto">
          <div class="btn-group  float-right w-100">
            <button type="submit" [disabled]="!isQueryRemediationmatrix" class="btn btn-sm btn-warning btn-block float-right w-100">Show</button>
          </div>

        </div>
      </div>

      <div class="form-group row mt-4 mb-3">
        <div class="col-sm-4"></div>
        <div class="col-sm-4"></div>
        <div class="col-sm-4 text-right" *ngIf="isDataExist">
          <button type="button" class="btn btn-outline-warning btn-sm mr-3" (click)="exportToExcel()">
            <i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
          </button>

          <button type="button" class="btn btn-outline-warning btn-sm" (click)="print()">
            <i class=" fa fa-print fa-fw "></i>&nbsp;Print
          </button>
        </div>
      </div>

      <!-- <div class="form-group row mt-4 mb-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-6"></div>
            <div class="col-sm-2">
                <button type="submit" [disabled]="!isQueryRemediationmatrix"
                    class="btn btn-warning pl-5 pr-5 btn-block mb-1">Show</button>
            </div>
            <div class="col-sm-2">
                <button (click)="clear()" class="btn btn-default btn-block">Clear</button>
            </div>
        </div> -->
    </form>
    <alert></alert>
    <div class="col-sm-12 alert alert-info ml-1 mr-1" *ngIf="noResults">
      No matching items.
    </div>
  </div>
  <div class="col-lg-12">
    <div *ngIf=showResult class="table-responsive outer" (window:resize)="onResize($event)">
      <div class="inner">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th #firstCell class="fix">&nbsp;</th>
              <th #jobProfile [attr.colspan]="jItem.userList.length" *ngFor="let jItem of missedTaskMatrixData | orderBy : 'alphabeticRemediationMatrixJobprofile'">
                {{ jItem. jobprofile}}
              </th>
              <th rowspan="2">Total</th>
            </tr>
            <tr>
              <th #secondCell class="fix">Missed task</th>
              <ng-container *ngFor="let jItem of missedTaskMatrixData; let k = index;">
                <th #userName *ngFor="let uItem of jItem.userList | orderBy : 'alphabeticRemediationMatrixUser'">
                  {{ uItem.userFullName }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let taskItem of taskListWithoutDuplicate">
              <td #row class="fix">{{ taskItem.description }}</td>
              <ng-container *ngFor="let jItem of missedTaskMatrixData">
                <td #rem [class]="getStyle(uItem.id, taskItem.userList, taskItem.score)" *ngFor="let uItem of jItem.userList | orderBy : 'alphabeticRemediationMatrixUser'"></td>
              </ng-container>
              <td style="text-align: center; vertical-align: middle; font-weight:bold;">{{ taskItem.taskCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-4">
        <pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
          [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
        <pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
      </div>
    </div>
  </div>
</div>
