export class Widget {
   constructor(
      public componentId: number,
		public componentName?: string,
      public componentIcon?: string,
      public position?: number,
      public size?: string,
      public checked?: boolean,
      public minimized?: boolean
   ) {}
}