import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentService } from '../../../../assessment/assessment.service';
import { AssessmentModel } from '../../../../assessment/assessment.model';
import { SharedService } from '../../../../home/shared.service';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { AlertService } from '../../../../util/alert/alert.service';
import { Constants } from '../../../../util/const/constants';
import { IMyDpOptions, IMyCalendarViewChanged, IMyDateModel, IMyDefaultMonth } from 'mydatepicker';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-activeassessment',
	templateUrl: './activeassessment.component.html',
	styleUrls: ['./activeassessment.component.scss']
})
export class ActiveAssessmentComponent implements OnInit, AfterViewInit {
	@ViewChild('activeAssessments', { static: true }) tblElement: ElementRef;
	@ViewChild('unlockAssessmentModal', { static: true }) unlockAssessmentModal: ModalComponent;
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	public data: Array<any> = [];
	public filteredData: Array<any> = [];
	public userId = localStorage.getItem('userId');
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');	
	public selectedUserRole = [];
	public userRole: any = null
	searchFullName: string = "";
	searchOrgCode: string = "";
	searchJobProfile: string = "";
	searchScheduleKnowledgeDate: any = ""
	searchKnowledgeTestCompletedDate: any = ""
	searchSheduleAssessorLeadDate: any = ""
	selectedAssessment: any = null
	constants: any
	scheduleKnowledgeDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
		inline: false
	};
	knowledgeTestCompletedDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
		inline: false
	};
	sheduleAssessorLeadDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
		inline: false
	};
	isDataExist: boolean = false;

	constructor(private assessmentService: AssessmentService, private sharedService: SharedService, private sanitizer: DomSanitizer,
		private changeDetectorRef: ChangeDetectorRef, private alertServices: AlertService, private router: Router, private datePipe: DatePipe) {
		this.constants = Constants;
		this.data = assessmentService.getActiveAssessmentdata();
		this.total = this.data.length;
		this.filteredData = this.data;
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
			this.userRole = this.selectedUserRole[0][1]
		}
	}

	ngOnInit() {
		if (this.filteredData.length > 0) {
			this.isDataExist = true
		} else {
			this.isDataExist = false
		}
	}

	ngAfterViewInit() {
		let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
		this.sharedService.activeHeightChange(height);
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	performAssessmentClickEvent(assessment) {
		this.assessmentService.setSelectedAssessmentModel(assessment)
		this.sharedService.change('PerformAssessment')
	}

	directToSignaturePage(assessment) {
		this.router.navigateByUrl('/assessmentsignature?type=assessor&assessmentId=' + assessment.id);
	}

	clickEvent(element) {
		element.focus();
	}

	filter() {
		this.filteredData = this.data;
		if (this.searchFullName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["userName"].match(new RegExp(this.searchFullName, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["orgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["jobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		let formattedSearchScheduleKnowledgeDate = this.datePipe.transform(this.searchScheduleKnowledgeDate, "MM/dd/yyyy");
		if (formattedSearchScheduleKnowledgeDate != null) {
			this.filteredData = this.filteredData.filter((item: any) => {
				if (item["scheduleSelfAssessmentDate"] != null) {
					let completedDate = this.datePipe.transform(item["scheduleSelfAssessmentDate"], "MM/dd/yyyy");
					return completedDate.match(new RegExp(formattedSearchScheduleKnowledgeDate, "i"));
				}
			});
		}
		let formattedKnowledgeTestCompletedDate = this.datePipe.transform(this.searchKnowledgeTestCompletedDate, "MM/dd/yyyy");
		if (formattedKnowledgeTestCompletedDate != null) {
			this.filteredData = this.filteredData.filter((item: any) => {
				if (item["selfAssessmentCompletedDate"] != null) {
					let selfCompletedDate = this.datePipe.transform(item["selfAssessmentCompletedDate"], "MM/dd/yyyy");
					return selfCompletedDate.match(new RegExp(formattedKnowledgeTestCompletedDate, "i"));
				}
			});
		}
		let formattedSheduleAssessorLeadDate = this.datePipe.transform(this.searchSheduleAssessorLeadDate, "MM/dd/yyyy");
		if (formattedSheduleAssessorLeadDate != null) {
			this.filteredData = this.filteredData.filter((item: any) => {
				if (item["scheduleAssessmentDate"] != null) {
					let selfCompletedDate = this.datePipe.transform(item["scheduleAssessmentDate"], "MM/dd/yyyy");
					return selfCompletedDate.match(new RegExp(formattedSheduleAssessorLeadDate, "i"));
				}
			});
		}
		this.pageChanged(1);
		if (this.filteredData.length > 0) {
			this.isDataExist = true
		} else {
			this.isDataExist = false
		}
	}

	onScheduleKnowledgeDate(event: IMyDateModel) {
		let temp = event.jsdate;
		this.searchScheduleKnowledgeDate = temp
		this.filter()
	}

	onKnowledgeTestCompletedDate(event: IMyDateModel) {
		let temp = event.jsdate;
		this.searchKnowledgeTestCompletedDate = temp
		this.filter()
	}

	onSheduleAssessorLeadDate(event: IMyDateModel) {
		let temp = event.jsdate;
		this.searchSheduleAssessorLeadDate = temp
		this.filter()
	}

	getStyle(date) {
		let style = "";
		let scheduledDate = new Date(date);
		let result = scheduledDate < new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()) ? true : false;
		if (!result || !date) {
			style = " ";			
		} else {
			style = "bs-callout-danger";
		}
		return style;
	}

	unlockAssessment(assessmentId: any) {
		this.selectedAssessment = assessmentId
		this.unlockAssessmentModal.open()
	}

	unlock() {
		this.assessmentService.unlockAssessment(this.selectedAssessment)
			.subscribe(
			data => {
				this.assessmentService.getActiveAssessments().subscribe(
					data => {
						this.assessmentService.loadActiveAssessments(data);
						this.data = this.assessmentService.getActiveAssessmentdata();
						this.total = this.data.length;
						this.filteredData = this.data;
						let end = this.itemsPerPage * this.currentPage;
						let start = end - this.itemsPerPage;
						this.paginatedResults = this.filteredData.slice(start, end);
					});
				this.alertServices.clear();
				this.alertServices.success(Constants.SUCCESS_UNLOCKED_ASSESSMENT);
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);

	}

	directToSelfAssessmentReview(row: any) {
		let assessmentModel: AssessmentModel = new AssessmentModel(row.id);
		assessmentModel.selfAssessmentCompletedDate = row.selfAssessmentCompletedDate;
		assessmentModel.userName = row.userName
		this.assessmentService.setSelectedAssessmentModel(assessmentModel)
		this.sharedService.change('SelfAssessmentReview')
	}

	exportToExcel(){
		var data = []
		if (this.filteredData.length > 0) {
			this.filteredData.forEach(item => {
				var temp = {
					"Full Name": item.userName,
					"Org Code": item.orgCode,
					"Job Profile": item.jobProfile,
					"Knowledge Check Progress": item.selfAssessmentStatus == 'Completed' ? '100%' : item.selfAssessmentProgress + '%',
					"Knowledge Check Completed Date": item.selfAssessmentCompletedDate,				
					"Scheduled Assessor Lead Date": item.isKnowledgeTest == 1 ? 'Knowledge Check Only' : item.scheduleAssessmentDate,
					"Assessor Lead Progress": item.isKnowledgeTest == 1 ? 'Knowledge Check Only' : (item.assessorCompleted == 1 && item.assessorSignature == 0 && 
						this.userId == item.assessorId ? '100%' : item.progress + '%')
				}
				data.push(temp)
			});

			let outputName = "Active Assessments"
		
		this.assessmentService.exportAsExcelFile(data, outputName);
		}
	}

}