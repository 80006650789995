<div style="display: block" *ngIf="showPieChart">
	<div class="row mt-1 mb-1">
		<ul class="legend list-inline mb-0" style="margin: 0px auto;">
			<li class="list-inline-item float-left"><span class="overdue"></span><small>Overdue Assessments</small></li>
			<li class="list-inline-item float-left"><span class="completed"></span><small>Completed Assessments</small></li>
			<li class="list-inline-item float-left"><span class="never"></span><small>Never Assessed</small></li>
		</ul>
	</div>
	<div style="display: block">
		<canvas baseChart *ngIf="!show" [data]="pieChartData" [labels]="pieChartLabels" [colors]="pieChartColors" [chartType]="pieChartType" [options]="pieChartOptions"
		(chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
	</div>
</div>
<div *ngIf="show">
	<div class="row">
		<alert></alert>
		<div class="container mb-2 pl-0">
			<button type="button" class="btn btn-outline-warning btn-sm pointer-cursor mr-1 ml-1 float-left" (click)="showChart()">
				<i class="fa fa-arrow-left fa-fw"></i>
			</button>
			<h5 class="ml-2 float-left mt-1"> {{tableName}} </h5>
		</div>

		<div class="container mb-1 pl-0 pr-0">
			<table class="table-sm table-bordered  table-striped mt-1 col-lg-12">
				<thead>
					<tr>
						<th class="text-center ">First Name</th>
						<th class="text-center ">Last Name</th>
						<th class="text-center ">Org Code</th>
						<th class="text-center ">Job Profile</th>
						<th *ngIf="showDate" class="text-center ">Completed Date</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of paginatedResults; let i = index">
						<td class="">{{row.firstName}}</td>
						<td class="">{{row.lastName}}</td>
						<td class="">{{row.orgCode}}</td>
						<td class="">{{row.jobProfile}}</td>
						<td *ngIf="showDate" class="">{{row.completedDate}}</td>
					</tr>
				</tbody>
			</table>
			<pagination class="pagination-sm mt-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
			 [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
			<br>
		</div>

	</div>

</div>