import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';

import { UserService } from '../user/user.service';
import { WidgetService } from '../dashboard/widget.service';
import { AppService } from '../app.service'

import { Widget } from '../dashboard/widget.model'
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-userrole',
	templateUrl: './userrole.component.html',
	styleUrls: ['./userrole.component.scss']
})
export class UserRoleComponent implements OnInit, AfterViewInit {

	public userRoles = [];
	public stortedUserData = localStorage.getItem('userRoles');
	public selectedUserRole = [];

	public appInfo: any = JSON.parse(localStorage.getItem('appInfo')) 
	public logoSrc: string = ''
	public headerLogoPath: string = environment.server_URL + '/images/header/'

	@ViewChild('userRoleModelStartUP', { static: true }) userRoleModelStartUP: ModalComponent;

	constructor(private router: Router, private userService: UserService, 
		private appService: AppService, private widgetService: WidgetService) {
		if (userService.isValidLoggedIn()) {
			this.router.navigateByUrl('/home');
		}

		if(this.appInfo.headerLogo != "") {
			this.logoSrc = this.headerLogoPath + '' + this.appInfo.headerLogo
		} else {
			this.logoSrc = 'assets/images/logo/axis.jpg'
		}

	}

	ngOnInit() {
		//parse json into array
		if (this.stortedUserData) {
			this.userRoles = JSON.parse(this.stortedUserData);
		}
	}

	ngAfterViewInit() {
		if (this.userRoles.length > 1) {
			if (this.userRoles.length > 3) {
				this.userRoleModelStartUP.open();
			} else {
				this.userRoleModelStartUP.open();
			}
		} else {
			//user has one user role
		}
	}

	onLoggedout() {
		localStorage.clear();
	}

	toggleSidebar() {
		const dom: any = document.querySelector('#sidebar');
		dom.classList.toggle('push-right');
	}

	//userrole button click event
	//set selected user role into local storage then navigate to /dashboard
	urmSPclick(roleID: string, roleDescription: string) {
		var value = [roleID, roleDescription];
		this.selectedUserRole.push(value);
		localStorage.setItem('selectedUserRole', JSON.stringify(this.selectedUserRole));

		//get assigned capability list
		if (localStorage.getItem('capability') == null || localStorage.getItem('capability') == 'reload') {
			this.userService.getAssignedCapabalityList(roleDescription)
				.subscribe(
				data => {
					localStorage.setItem('widgetList', null);
					//get dashboard widgets
					this.widgetService.getDashboardWidgets(roleID)
				},
				error => {
				}
				);
		}
	}


}
