export class Task {
	constructor(
		public id?: string,
		public text?: string,
		public status?: boolean,

		public performanceList?: any,
		public knowledgeList?: any,
		public remediationList?: any,
		public documentList?: any,
		public regulatoryList?: any,

		public jpccId?: any,
		public jptId?: any,
		public orderNum?: number,
		public tagList?: any,

		/** assessment status */
		public assStatus?: number,
		/** id of the task in assessment table.
		use to update assessment answer */
		public assId?: string,

		/** cricality value of the task */
		public criticalityValue?: number,

		/** store assessment score */
		public assessmentScore?: number,

		/**
		 * use these properties for implement 
		 * task group requirement
		 */
		public isTaskGroup?: boolean,
		public tgId?: string,
		public taskGroup?: string,
		public taskGroupId?: string, 
		public taskList?: any,
		//check is N/A task default 0 if yes 1
		public isNA?: any,
		public knowledgeQuestionList?: any,
		//id of assessment table
		public assessmentId?: string,

		public reasonNA?: string,
		public reasonNAId?: string,
		public isYes?: any,
	) { }
}
