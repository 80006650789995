import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RegulatoryAgency } from './regulatoryagency.model';


@Injectable()
export class RegulatoryAgencyService {

	constructor(private http: Http, private router: Router) { }

	private selectedRegulatoryAgencyForEdit: RegulatoryAgency;

	public getSelectedRegulatoryAgencyForEdit(): RegulatoryAgency {
		return this.selectedRegulatoryAgencyForEdit;
	}
	public setSelectedRegulatoryAgencyForEdit(regulatoryAgency: RegulatoryAgency): void {
		this.selectedRegulatoryAgencyForEdit = regulatoryAgency;
	}

	getAllRegulatoryAgency() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/regulatoryagency/getRegulatoryAgency' + token, { headers: headers })
			.map(res => res.json());
	}

	addNewRegulatoryAgency(regulatory: RegulatoryAgency) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(regulatory);
		return this.http.post(environment.server_URL + '/regulatoryagency/addNewRegulatoryAgency' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteRegulatoryAgency(regulatory: RegulatoryAgency) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(regulatory);
		return this.http.post(environment.server_URL + '/regulatoryagency/deleteRegulatoryAgency' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateRegulatoryAgencyById(regulatory: RegulatoryAgency) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(regulatory);
		return this.http.post(environment.server_URL + '/regulatoryagency/updateRegulatoryAgencyById' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getUserLastCompletedAssessmentByRegulatoryId(param: any){
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(param);
		return this.http.post(environment.server_URL + '/regulatoryagency/getUserLastCompletedAssessmentByRegulatoryId' + token, body, { headers: headers })
			.map(res => res.json());
	}
}
