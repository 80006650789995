<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Succession Planning</h4>
		</div>
		<div class="float-right"></div>
	</div>
	<hr />
</div>
<div class="col-lg-12 mx-auto">
	<alert></alert>
	<form [formGroup]="successionPlanningForm">
		<div class="form-group row">
			<div class="col-sm-4 mb-1">
				Select Type :
				<div class="mt-2">
					<label class="mr-4 pr-4">
						<input class="mr-2" type="radio" name="option" formControlName="option" value="user" (click)="setOptionList('user')"> 
						User
					</label>
					<label class="">
						<input class="mr-2" type="radio" name="option" formControlName="option" value="jobprofile" (click)="setOptionList('jobprofile')"> 
						Job Profile
					</label>
				</div>
			</div>
			<div class="col-sm-8 mb-1">
				User/Job Profile :
				<!-- <span class="badge badge-required ml-1 ">REQUIRED</span> -->
				<ng-select [allowClear]="true" [items]="itemList" placeholder="Select user/job profile" formControlName="optionItem" (removed)="removeOptionItem($event)"
				 (selected)="removeOptionItem($event)"></ng-select>
			</div>
		</div>
		<!-- <div class="form-group row ">
			<div class="col-sm-2">
				<input type="radio" name="option" formControlName="option" value="user" (click)="setOptionList('user')">User
			</div>
			<div class="col-sm-2">
				<input type="radio" name="option" formControlName="option" value="jobprofile" (click)="setOptionList('jobprofile')">Job Profile
			</div>
			<div class="col-sm-8"></div>
		</div> -->
		<!-- <div class="form-group row ">
			<div class="col-sm-8">
				User/Job Profile :
				<ng-select [allowClear]="true" [items]="itemList" placeholder="Select user/job profile" formControlName="optionItem" (removed)="removeOptionItem($event)"
				 (selected)="removeOptionItem($event)"></ng-select>
			</div>
			<div class="col-sm-4"></div>
		</div> -->
		<div class="form-group row mt-4 mb-2">
			<div class="col-sm-6"></div>
			<div class="col-sm-3">
				<button (click)="showSuccessionPlanning()" [disabled]="!successionPlanningForm.valid" class="btn btn-warning btn-block">Show</button>
			</div>
			<div class="col-sm-3">
				<button (click)="clear()" class="btn btn-default btn-block">Clear</button>
			</div>
		</div>
	</form>
</div>
<div class="row mx-auto mt-3">
	<div class="col-sm-12 table-responsive mt-2" *ngIf="showResult">
		<table class="table table-bordered  table-striped col-sm-12">
			<thead>
				<tr>
					<th class="text-center " class="set-width"></th>
					<th class="text-center ">First Name</th>
					<th class="text-center ">Last Name</th>
					<th class="text-center ">Org Code</th>
					<th class="text-center ">Job Profile</th>
					<th class="text-center ">Knowledge Check Only</th>
					<th class="text-center ">Completed Date</th>
					<th class="text-center ">View Details</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th class="set-width"></th>
					<th class="">
					<th class="">
						<input class="form-control" type="text" name="searchFirstName" [(ngModel)]="searchFirstName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchLastName" [(ngModel)]="searchLastName" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchJobProfile" [(ngModel)]="searchJobProfile" (keyup)="filter()">
					</th>
					<th></th>
					<th></th>					
				</tr>
				<tr *ngFor="let row of paginatedResults | orderBy : 'score'; let i = index">
					<td class="set-width">
						<div class="progress">
							<div *ngIf="row.score < 85 && row.score > 10" class="pointer-sm" [style.left]="row.score + '%'">
								<div class="progress-badge">
									<p>{{ row.score }}%</p>
								</div>
							</div>
							<div *ngIf="row.score == 100" class="pointer-sm " [style.left]="99.5 + '%'">
								<div class="progress-badge float-right">
									<p>{{ row.score }}%</p>
								</div>
							</div>
							<div *ngIf="row.score >= 85 && row.score != 100" class="pointer-sm " [style.left]="row.score + '%'">
								<div class="progress-badge float-right">
									<p>{{ row.score }}%</p>
								</div>
							</div>
							<div *ngIf="row.score <= 10" class="pointer-sm " [style.left]="row.score + '%'">
								<div class="progress-badge float-left">
									<p>{{ row.score }}%</p>
								</div>
							</div>
							<div class="progress-bar" [ngClass]="{'progress-bar-success': row.score >= 80, 'progress-bar-warning': row.score >= 50 && row.score < 80, 'progress-bar-danger' : row.score < 50}"
							 role="progressbar" aria-valuenow="row.score" aria-valuemin="0" aria-valuemax="100" [style.width]="row.score + '%'">
							</div>
						</div>
					</td>
					<td>{{row.user.FirstName}}</td>
					<td>{{row.user.LastName}}</td>
					<td>{{row.user.OrgCode}}</td>
					<td>{{row.user.JobProfile}}</td>
					<td class="text-center">						
						<input *ngIf="row.user.IsKnowledgeTest == 1" type="checkbox" checked disabled>
						<input *ngIf="row.user.IsKnowledgeTest == 0" type="checkbox" disabled>
					</td>
					<td>{{row.user.AssessmentCompletedDate}}</td>
					<td class="text-center">
						<a style="cursor: pointer" class="btn btn-sm edit " (click)="showAssessmentReport(row.user.UserId, row.user.FirstName, row.user.LastName)">
							<i class="fa fa-line-chart " aria-hidden="true"></i>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="col-sm-12" *ngIf="showResult">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
		 [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
	</div>
	<div class="col-sm-12 alert alert-info ml-1 mr-1" *ngIf="noResults">
		No matching users.
	</div>
</div>