import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';

import { FormGroup, FormControl, Validators } from "@angular/forms";
import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from 'mydatepicker';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import * as FileSaver from 'file-saver';

import { AssessmentService } from '../assessment.service'
import { UserService } from '../../user/user.service'
import { SharedService } from '../../home/shared.service'
import { AlertService } from '../../util/alert/alert.service'

import { AssessmentModel } from '../assessment.model'
import { JobProfile } from '../../jobprofile/jobprofile.model'
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model'
import { Task } from '../../complib/task/task.model'
import { Performance } from '../../complib/performance/performance.model'
import { Knowledge } from '../../complib/knowledge/knowledge.model'
import { CheckList } from '../../complib/checklist/checklist.model'
import { AssessmentDocument } from './assessmentdocument.model'
import { Opportunity } from './opportunity.model'
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-performassessment',
	templateUrl: './performassessment.component.html',
	styleUrls: ['./performassessment.component.scss']
})
export class PerformAssessment implements OnInit {

	//assessment model
	public assessment: AssessmentModel
	//job profile model
	public jobProfile: JobProfile

	public showAssmentDatePanel: boolean = false
	private assessmentCompletedDate: string
	public localAssessmentCompletedDate: any;
	public currentTime: Date = new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1));

	assessmentDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
		disableSince: { year: this.currentTime.getFullYear(), month: this.currentTime.getMonth() + 1, day: this.currentTime.getDate() },
	};
	public assessmentDateForm: FormGroup;

	public showHierarchy: boolean = false

	public assessmentButtonText: string = ''
	isLoading: boolean = false;

	assessorId: string = ''
	stortedSelectedUserRole: string = ''
	loggedUserId: string = ''
	selectedUserRole: string = ''
	disableforAdmin: boolean = false;

	constructor(public assessmentService: AssessmentService, public userService: UserService,
		public sharedService: SharedService, public alertService: AlertService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.assessment = this.assessmentService.getSelectedAssessmentModel()
		this.setLocalTime();
		//get supervisor detail
		//this.getSupervisorUserDetail()
		this.getOppportunityForAssessment();

		//create assessment jobprofile hierarchy
		this.jobProfile = new JobProfile(this.assessment.jobProfileId, this.assessment.jobProfile)
		
		this.assessorId = this.assessment.assessorId
		this.stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
		this.loggedUserId = localStorage.getItem('userId');
		this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		if ((this.assessorId == this.loggedUserId)  || (this.selectedUserRole[0][1] == 'Admin' && (this.assessorId == this.loggedUserId))) {
			this.disableforAdmin = false
		} else {
			this.disableforAdmin = true
		}
	}

	private setLocalTime() {
		// let timeZone = localStorage.getItem('TimeZone');
		// let date = moment(this.assessment.assessmentCompletedDate);
		// date.tz(timeZone);
		// this.localAssessmentCompletedDate = date._d;
		this.localAssessmentCompletedDate = this.assessment.assessmentDate;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.assessmentDateForm = new FormGroup({
			assessmentCompletedDate: new FormControl(null, Validators.required)
		})

		this.setAssessmentButtonText(this.assessment.status)


	}

	//show/hide assessment completed date form
	public startAssessment(status: boolean) {
		this.showAssmentDatePanel = status
	}

	//assessment completed date picker change event
	public assessmentDatePickerChanged(event: IMyDateModel) {
		// console.log('[assessmentDatePickerChanged] (event)' + JSON.stringify(event).toString())

	}

	continue(event) {
		if (event) {
			event.preventDefault();
		}
		this.createJobProfileHierarchy()
	}

	private setAssessmentButtonText(status: string) {
		switch (status) {
			case 'New':
				this.assessmentButtonText = 'Start Assessment'
				break
			case 'Started':
				this.assessmentButtonText = 'Continue Assessment'
				break
			case 'Completed':
				this.assessmentButtonText = 'View Assessment'
				break
			default:
				break
		}
	}

	private assessmentButtonClickEvent(event: any, status: string) {
		if (event) {
			event.preventDefault();
		}

		switch (status) {
			case 'New':
				this.startAssessment(true)
				break
			case 'Started':
				this.startAssessment(true)
				break
			case 'Completed':
				this.createJobProfileHierarchy()
				break
			default:
				break
		}
	}

	//submit assessment completed date
	onSubmitAsscompletedDate() {
		this.alertService.clear();

		let tempDate = this.assessmentDateForm.get('assessmentCompletedDate').value;
		this.assessmentCompletedDate = tempDate.formatted;
		// this.assessmentCompletedDate = new Date(tempDate.year, tempDate.month, tempDate.day, 12)

		let param: any = {
			'assessmentId': this.assessment.id,
			'completedDate': this.assessmentCompletedDate
		};

		this.assessmentService.setAssessmentCompletedDate(param)
			.subscribe(
			data => {
				this.alertService.clear()
				// console.log('[onSubmitAsscompletedDate] (data) ' + JSON.stringify(data).toString())
				this.alertService.success(data.message)
				this.assessment.status = 'Started'
				this.createJobProfileHierarchy()

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	/** get assesssment opportunity detail */
	private getOppportunityForAssessment() {
		this.assessmentService.getOpportunityForAssessment(this.assessment.id)
			.subscribe(
			data => {
				//  console.log('[getSupervisorUserDetail] (data) : ' + JSON.stringify(data.user[0].FirstName));
				if (data.opportunityList.length != 0) {
					this.assessment.opportunityList = this.generateOpportunityListModel(data.opportunityList);
					// console.log('[getOppportunityForAssessment] (opportunityList) ' + JSON.stringify(this.assessment.opportunityList))
				}
				else {
					this.assessment.opportunityList = [];
				}
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			)
	}
	
	/** create assessment job profile hierarchy */
	private createJobProfileHierarchy() {
		if (this.assessment.jobProfileId != null) {
			let param: any = {
				'jobProfileId': this.assessment.jobProfileId,
				'assessmentId': this.assessment.id
			}
			// console.log('[constructor] (param) ' + JSON.stringify(param))
			this.isLoading = true;
			this.assessmentService.getAssessmentJobProfileHierarchy(param)
				.subscribe(
				data => {
					this.alertService.clear()
					// console.log('[getAssessmentJobProfileHierarchy] (data) ' + JSON.stringify(data).toString())
					this.jobProfile.coreCompetencyList = this.generateCCListFromData(data)
					this.validateCCList(this.jobProfile.coreCompetencyList)
					// this.sharedService.showPerformAssessment(true);
					this.isLoading = false
					this.showHierarchy = true
					// console.log('[getAssessmentJobProfileHierarchy] (jobProfile) ' + JSON.stringify(this.jobProfile).toString())
				},
				error => {
					this.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				)
		} else {
			//do nothing
		}
	}

	//generate job profile corecompetency list from data
	private generateCCListFromData(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem
			let tl = data.result[i].taskList;
			let rel = data.result[i].regulatoryAgencyList;
			let pl = data.result[i].performanceList;
			let kl = data.result[i].knowledgeList;
			let dl = data.result[i].documentList;
			let cll = data.result[i].checkList;
			let trl = data.result[i].taskRemediationList;
			let prl = data.result[i].performanceRemediationList;
			let krl = data.result[i].knowledgeRemediationList;
			let clrl = data.result[i].checkListRemediationList;

			let clml = this.generateCheckListModel(cll, clrl);
			let kml = this.generateKnowledgeListModel(kl, krl);
			let pml = this.generatePerformanceListModel(pl, clml, prl)
			let dml = this.generateDocumentListModel(dl);
			let tml = this.generateTaskListModel(tl, pml, kml, trl, dml, rel)
			let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);

			ccItem.orderNum = cci.orderNum
			ccItem.assStatus = cci.status
			ccItem.assId = cci.ACCId
			ccItem.taskTotal = this.generateCCLevelTotaltask(tl)
			corecompetencyList.push(ccItem)
		}
		// console.log(corecompetencyList)
		return corecompetencyList;
	}

	private generateCheckListModel(checkList: any, clrList: any): any {
		let clModelList = []
		for (let i = 0; i < checkList.length; i++) {
			let clModel: CheckList = new CheckList(checkList[i].id, checkList[i].text, '', true, checkList[i].orderNum)
			clModel.jppId = checkList[i].APId
			clModel.remediationList = clrList.filter(item => item.ACLId === checkList[i].ACLId)
			clModel.answer = checkList[i].answer
			clModel.orderNum = checkList[i].orderNum
			clModel.assId = checkList[i].ACLId
			clModel.reasonNA = checkList[i].reasonNA
			clModel.reasonNAId = checkList[i].reasonNAId
			if (!clModel.reasonNA) {
				clModel.reasonNA = ''
			}
			clModelList.push(clModel)
		}
		return clModelList;
	}

	private generateKnowledgeListModel(knowledgeList: any, knoRemList: any): any {
		let knowledgeModelList = []
		for (let i = 0; i < knowledgeList.length; i++) {
			let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 0, knowledgeList[i].id)
			kModel.remediationList = knoRemList.filter(item => item.AKId === knowledgeList[i].AKId)
			kModel.jptId = knowledgeList[i].ATId
			kModel.orderNum = knowledgeList[i].orderNum
			kModel.answer = knowledgeList[i].answer
			kModel.assId = knowledgeList[i].AKId
			kModel.jpccId = knowledgeList[i].ACCId
			kModel.assessmentNote = knowledgeList[i].note
			if (!kModel.assessmentNote) {
				kModel.assessmentNote = ''
			}
			kModel.reasonNA = knowledgeList[i].reasonNA
			kModel.reasonNAId = knowledgeList[i].reasonNAId
			if (!kModel.reasonNA) {
				kModel.reasonNA = ''
			}
			knowledgeModelList.push(kModel)
		}
		return knowledgeModelList;
	}

	private generateDocumentListModel(documentList: any) {
		let documentModelList = [];
		for (let i = 0; i < documentList.length; i++) {
			let temp = documentList[i].FileName;
			let fileName = temp.substring(0, temp.lastIndexOf("."));
			let ext = temp.substring(temp.lastIndexOf(".") + 1);
			let docModel: AssessmentDocument = new AssessmentDocument(documentList[i].Id, documentList[i].assId, documentList[i].AssessmentTaskId,
				documentList[i].Category, fileName, documentList[i].Note);
			docModel.ext = ext;
			documentModelList.push(docModel);
		}
		return documentModelList;
	}

	private generatePerformanceListModel(perList: any, checkList: any, remList: any) {
		let performanceModelList = []
		for (let i = 0; i < perList.length; i++) {
			let pModel: Performance = new Performance(perList[i].id, perList[i].text, true)
			pModel.checkList = checkList.filter(item => item.jppId === perList[i].APId)
			pModel.remediationList = remList.filter(item => item.APId === perList[i].APId)
			pModel.jptId = perList[i].ATId
			pModel.orderNum = perList[i].orderNum
			pModel.answer = perList[i].answer
			pModel.assId = perList[i].APId
			pModel.jpccId = perList[i].ACCId
			pModel.assessmentNote = perList[i].note
			if (!pModel.assessmentNote) {
				pModel.assessmentNote = ''
			}
			pModel.reasonNA = perList[i].reasonNA
			pModel.reasonNAId = perList[i].reasonNAId
			if (!pModel.reasonNA) {
				pModel.reasonNA = ''
			}
			performanceModelList.push(pModel)
		}
		return performanceModelList;
	}

	private generateTaskListModel(taskList: any, perList: any, knoList: any, remList: any, docList: any, regList: any) {
		let taskModelList = []
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task
			if (taskList[i].isTaskGroup) {
				taskModel = new Task(taskList[i].ATId, taskList[i].text);
				taskModel.regulatoryList = []
				taskModel.performanceList = [];
				taskModel.knowledgeList = [];
				taskModel.remediationList = [];
				taskModel.taskList = []
			} else {
				taskModel = new Task(taskList[i].id, taskList[i].text)
				taskModel.regulatoryList = regList.filter(item => item.ATId === taskList[i].ATId)
				taskModel.performanceList = perList.filter(item => item.jptId === taskList[i].ATId)
				taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId)
				taskModel.remediationList = remList.filter(item => item.ATId === taskList[i].ATId)
				taskModel.documentList = docList.filter(item => item.assId === taskList[i].ATId)
				taskModel.criticalityValue = taskList[i].criticality
			}
			taskModel.assId = taskList[i].ATId
			taskModel.assStatus = taskList[i].status
			taskModel.jpccId = taskList[i].ACCId
			taskModel.orderNum = taskList[i].orderNum
			taskModel.tgId = taskList[i].TGId
			taskModel.isTaskGroup = taskList[i].isTaskGroup
			taskModel.taskGroup = taskList[i].tgText
			taskModel.taskGroupId = taskList[i].taskGroupId
			taskModel.isNA = taskList[i].isNA
			taskModel.isYes = taskList[i].isYes
			taskModel.reasonNA = taskList[i].reasonNA
			taskModel.reasonNAId = taskList[i].reasonNAId
			if (!taskModel.reasonNA) {
				taskModel.reasonNA = ''
			}
			taskModelList.push(taskModel)
		}
		// console.log('[generateTaskListModel] (taskModelList) : ' + JSON.stringify(taskModelList));
		taskModelList = this.modifyTaskList(taskModelList)

		return taskModelList;
	}

	public modifyTaskList(taskList: any): any {
		let returnTaskList = []
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				returnTaskList.push(task)
			} else {
				if (task.taskGroupId != null) {
					tempTaskList.push(task)
				} else {
					returnTaskList.push(task)
				}
			}
		});
		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));
		// console.log('[modifyTaskList] (tempTaskList) : ' + JSON.stringify(tempTaskList));
		tempTaskList.forEach(task => {
			if (task.taskGroupId != null && task.taskGroupId != 'null') {
				let tg = returnTaskList.find(item => item.assId === task.taskGroupId);
				tg.taskList.push(task)
			} else {
				returnTaskList.push(task)
			}
		});

		return returnTaskList

	}

	public generateCCLevelTotaltask(taskList: any): any {
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.taskGroupId == null) {
				tempTaskList.push(task.id)
			} else {
			
			}
		});
		return tempTaskList.length
	}

	private generateOpportunityListModel(opportunityList: any) {
		let opportunityModelList = [];

		for (let i = 0; i < opportunityList.length; i++) {
			let opportunityModel: Opportunity = new Opportunity(
				opportunityList[i].category,
				opportunityList[i].CategoryNo,
				opportunityList[i].OpportunityValue,
				opportunityList[i].AssessmentId,
				opportunityList[i].OrderNo
			);
			opportunityModelList.push(opportunityModel)

		}
		return opportunityModelList;
	}

	/**
	 * [validate cc item for 0-knowledge and 0-performance]
	 * this is not practical because user can not create 0-k 0-p cc item
	 * @param  {any}    ccList [description]
	 * @return {[type]}        [description]
	 */
	private validateCCList(ccList: any) {
		ccList.forEach(ccItem => {
			if (ccItem.taskList.length != 0) {
				ccItem.taskList.forEach(task => {
					if (task.isTaskGroup) {
						task.taskList.forEach(tgTask => {
							if (tgTask.performanceList.length != 0) {
								//do nothing
							} else {
								if (tgTask.knowledgeList.length != 0) {
									//do nothing
								} else {
									tgTask.assStatus = 3
								}
							}
						});
					} else {
						if (task.performanceList.length != 0) {
							//do nothing
						} else {
							if (task.knowledgeList.length != 0) {
								//do nothing
							} else {
								task.assStatus = 3
							}
						}
					}
				});
			} else {
				ccItem.assStatus = 3
			}
		});

		ccList.forEach(ccItem => {
			let completedTaskCount: number = 0;
			let ccItemActualTaskList = this.getActualTaskList(ccItem.taskList)
			ccItemActualTaskList.forEach(task => {
				if (task.assStatus == 3) {
					completedTaskCount++
				}
			});
			if (completedTaskCount == ccItemActualTaskList.length) {
				ccItem.assStatus = 3
			}
		});
	}

	//get task list without task groups
	private getActualTaskList(taskList: any): any {
		let returnTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				task.taskList.forEach(tgt => {
					returnTaskList.push(tgt)
				});
			} else {
				returnTaskList.push(task)
			}
		});
		return returnTaskList
	}

}
