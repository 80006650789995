<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Edit User</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="container">
	<form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
		<!--first name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">First Name
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="text" class="form-control" id="firstName" placeholder="First Name" formControlName="firstName" name="firstName"
				 value="shanaka">
			</div>
		</div>
		<!--last name-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Last Name
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName" name="lastName">
			</div>
		</div>
		<!--email-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Email
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input maxlength="100" type="email" class="form-control mb-1" id="email" placeholder="Email" formControlName="email" name="email">
				<div class="" *ngIf="editUserForm.controls.email.touched">
					<div class="alert alert-danger mb-1" role="alert" *ngIf="editUserForm.controls.email.hasError('pattern')">
						Invalid email address.
					</div>
				</div>
			</div>
		</div>
		<!--org id-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Code
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<ng-select [allowClear]="true" (removed)="removedOrgCode($event)" (selected)="selectedOrgCode($event)" [items]="orgList"
				 placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId">
				</ng-select>
			</div>
		</div>
		<!--Supervisor-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Supervisor</label>
			<div class="col-sm-10">
				<ng-select [multiple]="true" [allowClear]="true" [items]="supervisorList" placeholder="Select supervisor" name="supervisor" id="supervisor"
				 formControlName="supervisor">
				</ng-select>
			</div>
		</div>
		<!--Job profile-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Job Profile</label>
			<div class="col-sm-10">
				<ng-select [allowClear]="true" [items]="jobprofileList" placeholder="Select job profile" name="jobprofile" id="jobprofile" (selected)="selectJobProfile($event)"
				 formControlName="jobprofile">
				</ng-select>
			</div>
		</div>
		<!-- Is Assessed-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label"> Assess
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input type="radio" id="assess" name="assess" formControlName="assess" [checked]="isCheckedYes"
				 [value]='true' (click)="changeAssessee($event)"> Yes
				<input type="radio" id="nonassess" name="assess" formControlName="assess" [checked]="isCheckedNo"
				 value='false' (click)="changeAssessee($event)"> No
			</div>
		</div>
		<!-- Time zone -->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Time Zone
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
					<ng-select name="timeZone" id="timeZone" formControlName="timeZone" [allowClear]="true" [items]="timeZoneList" (selected)="selected($event)"
					 (removed)="removed($event)" placeholder="Select Time Zone"></ng-select>
				</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Contractor</label>
			<div class="col-sm-10">
				<input type="checkbox" id="isContractUser" formControlName="isContractUser" name="isContractUser"> </div>
		</div>
		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-6">
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem( 'AllUsers') ">Cancel</button>
			</div>
		</div>
	</form>
</div>