<!--component html goes here -->
<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Add New Regulatory Agency</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>

<div class="col-lg-12 mx-auto">
	<form [formGroup]="addNewRegulatoryForm">
		<!--regulatory-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Description
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<textarea noSpaces maxlength="50" autosize type="text" class="form-control" placeholder="Description"
					id="description" formControlName="description" name="description"></textarea>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Short Name
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="10" type="text" class="form-control" id="shortName" placeholder="Short Name"
					formControlName="shortName" name="shortName">
			</div>
		</div>

		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-3">
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!addNewRegulatoryForm.valid"
					(click)="save()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!addNewRegulatoryForm.valid"
					(click)="saveAndNew()">Save & New</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
			</div>
		</div>
	</form>
</div>