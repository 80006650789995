import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

@Injectable()
export class ChangeThemeService {

   constructor(private http: Http, private router: Router) { }

   faviconFileUpload(file: File) {
      let formData: any = new FormData();
      formData.append('image', file, file.name)
      return this.http.post(environment.server_URL + '/user/uploadfavicon', formData)
         .map(res => res.json());
   }

   loginLogoFileUpload(file: File) {
      let formData: any = new FormData();
      formData.append('image', file, file.name)
      return this.http.post(environment.server_URL + '/user/uploadloginlogo', formData)
         .map(res => res.json());
   }

   HeaderLogoFileUpload(file: File) {
      let formData: any = new FormData();
      formData.append('image', file, file.name)
      return this.http.post(environment.server_URL + '/user/uploadheaderlogo', formData)
         .map(res => res.json());
   }

   updateAppConfig(applicationName: string, themeColor: string) {
      const token = localStorage.getItem('token')
         ? '?token=' + localStorage.getItem('token')
         : '';
      const body = JSON.stringify({ 'applicationName': applicationName, 'themeColor': themeColor });
      const headers = new Headers({ 'Content-Type': 'application/json' });
      return this.http.post(environment.server_URL + '/user/updateAppConfig' + token, body, { headers: headers })
         .map(res => res.json());
   }

}