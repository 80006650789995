<!--component html goes here -->
<div class="col-lg-12">
	<div class="col" style="overflow:hidden" *ngIf="!fromSuccessionPlanning">
		<div class="float-left">
			<h4>Job Progression Planning</h4>
		</div>
		<div class="float-right"></div>
	</div>
	<div class="col" style="overflow:hidden" *ngIf="fromSuccessionPlanning">
		<div class="float-left">
			<h4>{{selectedUser}} vs. {{jobProfileDescription}}</h4>
		</div>
		<div class="float-right"></div>
	</div>
	<hr />
</div>

<div class="col-lg-12" *ngIf="!fromSuccessionPlanning">
	<alert></alert>
	<form *ngIf="!isCandidateUser" [formGroup]="planningNCUForm">
		<div class="form-group row ">
			<div class="col-sm-6">
				<h6>User :</h6>
				<!-- <span class="badge badge-required ml-1 ">REQUIRED</span> -->
				<ng-select [allowClear]="true" [items]="userList" placeholder="Select a user" formControlName="user" (selected)="selectUser($event)"
				 (removed)="removeUser($event)"></ng-select>
			</div>
			<div class="col-sm-6">
				<h6>Job Profile : </h6>
				<!-- <span class="badge badge-required ml-1 ">REQUIRED</span> -->
				<ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select a job profile" formControlName="jobProfileNCU"
				 (selected)="selectJobProfile($event)" (removed)="removeJobProfile($event)"></ng-select>
			</div>
		</div>
		<div class="form-group row mt-4 mb-2">
			<div class="col-sm-6"></div>
			<div class="col-sm-3"></div>
			<div class="col-sm-3">
				<button (click)="showProgressPlanning()" [disabled]="!planningNCUForm.valid" class="btn btn-warning btn-block">Compare</button>
			</div>
		</div>
	</form>
	<form *ngIf="isCandidateUser" [formGroup]="planningCUForm">
		<div class="form-group row ">
			<div class="col-sm-6">
				<h6>User :</h6>
				<input type="text" class="form-control" formControlName="user" [(ngModel)]="candidateUser" name="user" type="text" disabled>
			</div>
			<div class="col-sm-6">
				<h6>Job Profile : </h6>
				<!-- <span class="badge badge-required ml-1 ">REQUIRED</span> -->
				<ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select a job profile" formControlName="jobProfileCU"
				 (selected)="selectJobProfile($event)" (removed)="removeJobProfile($event)"></ng-select>
			</div>
		</div>
		<div class="form-group row mt-4 mb-2">
			<div class="col-sm-6"></div>
			<div class="col-sm-3"></div>
			<div class="col-sm-3">
				<button (click)="showProgressPlanning()" [disabled]="!planningCUForm.valid" class="btn btn-warning btn-block">Compare</button>
			</div>
		</div>
	</form>
	<hr />
</div>
<div class="col-lg-12 mb-2 pl-4 pr-2" *ngIf="showResult">
	<div class="col-sm-4 mb-1">
		<h6>Score as {{jobProfileDescription}}</h6>
	</div>
	<div class="col-sm-12">
		<div class="progress ">
			<div *ngIf="assessmentScore < 95" class="pointer pointer-cursor" [style.left]="assessmentScore + '%'">
				<div class="progress-badge">
					<p>{{ assessmentScore }}%</p>
				</div>
			</div>
			<div *ngIf="assessmentScore == 100" class="pointer pointer-cursor" [style.left]="99.5 + '%'">
				<div class="progress-badge float-right">
					<p>{{ assessmentScore }}%</p>
				</div>
			</div>
			<div *ngIf="assessmentScore >= 95 && assessmentScore != 100" class="pointer pointer-cursor" [style.left]="assessmentScore + '%'">
				<div class="progress-badge float-right">
					<p>{{ assessmentScore }}%</p>
				</div>
			</div>
			<div class="progress-bar" [ngClass]="{'progress-bar-success': assessmentScore >= 80, 'progress-bar-warning': assessmentScore >= 50 && assessmentScore < 80, 'progress-bar-danger' : assessmentScore < 50}"
			 role="progressbar" aria-valuenow="assessmentScore" aria-valuemin="0" aria-valuemax="100" [style.width]="assessmentScore + '%'">
			</div>
		</div>
	</div>
</div>
<div *ngIf="showResult" class="col-lg-12 mt-2">
	<app-ro-jphirearchy [jobProfileModel]="jobProfile" [showProgressPlanning]="true"></app-ro-jphirearchy>
</div>