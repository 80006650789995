export class KnowledgeAnswer { 
   constructor( 
      public answer:string,
      public isCorrect: number,
      public id?: string,
      public newVisualAnswerId?: string,
      public answerFileType?: string,
      public isImageChange?: boolean,
      public answerOldFileType?: string
   ) {  }   
}