import { Component, OnInit } from "@angular/core";
import { ReportTableComponent } from "../reporttable.component";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { Report } from "../report.model";

@Component({
	selector: "app-role-report",
	templateUrl: "./rolereport.component.html"
})
export class RoleReportComponent extends ReportTableComponent implements OnInit {	
	public report: Report;
	public excelData: any[];
	public columns: Array<any> = [
		{
			title: "First Name",
			name: "First Name",
			filtering: { filterString: "" }
		},
		{
			title: "Last Name",
			name: "Last Name",
			filtering: { filterString: "" }
		},	
		{
			title: "Roles",
			name: "Roles",
			filtering: { filterString: "" }
		}
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	private serverResultData: Array<any> = [];

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
	}

	ngOnInit() {		
		this.loadRoleList(this.report.reportData);
	}

	loadRoleList(data: any) {		
		this.excelData = [];
		for (let i = 0; i < data.length; i++) {			
			this.data.push({
				"First Name": data[i].FirstName,
				"Last Name": data[i].LastName,
				"Roles": data[i].role == null ? "" : data[i].role			
			});
		}
		this.serverResultData = data;
		//  this.report.reportDataList = this.excelData;
		this.report.columns = this.columns;
		this.config.report = this.report;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.onChangeTable(this.config);
	}

	public onCellClick(data: any): any { }
}