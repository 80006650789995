import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { orgtype } from './orgtype';

@Injectable()
export class OrgtypeService {

	constructor(private http: Http, private router: Router) { }

	//selected orgtype for edit - need pass this selected orgtype to the edit component
	private selectedOrgtypeForEdit: orgtype;
	public getSelectedOrgtypeForEdit(): orgtype {
		return this.selectedOrgtypeForEdit;
	}
	public setSelectedOrgtypeForEdit(orgtype: orgtype): void {
		this.selectedOrgtypeForEdit = orgtype;
	}

	// Get all posts from the API
	getOrgtype() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/getOrgtype' + token, { headers: headers })
			.map(res => res.json());
	}

	addOrgtype(orgtype: orgtype) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify(orgtype);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/addOrgtype' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateOrgtypeById(orgtype: orgtype) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify(orgtype);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/updateOrgtypeById' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteOrgtype(orgtypeId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'orgtypeId': orgtypeId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/deleteOrgtype' + token, body, { headers: headers })
			.map(res => res.json());
	}

	geActiveOrgtype() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/geActiveOrgtype' + token, { headers: headers })
			.map(res => res.json());
	}

	changeOrgTypeStatus(orgTypeId: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'orgtypeId': orgTypeId, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/changeOrgTypeStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getShowOrgtype() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/getShowOrgtype' + token, { headers: headers })
			.map(res => res.json());
	}

	changeOrgTypeShowHide(orgTypeId: string, showhide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'orgtypeId': orgTypeId, 'showhide': showhide});
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/orgtype/changeOrgTypeShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

}