<div *ngIf='selectedItem!=null' class="row">
	<div class="col-sm-4 col-md-2">
		<nav id="sidebar" class="sidebar slider-right-border" [ngClass]="{sidebarPushRight: isActive}">
			<ul class="list-group">
				<a (click)="selectedComponentItem('Dashboard', 1, true)" class="list-group-item" [ngClass]="{'router-link-active': selectedMenuItem == 1}">
					<i class="fa fa-fw fa-dashboard"></i>&nbsp;Dashboard
				</a>
				<a *ngIf="isMenuItemValid('EnableUser') == true" (click)="selectedComponentItem('AllUsers', 2, true)" [ngClass]="{'router-link-active': selectedMenuItem == 2}"
				 class="list-group-item">
					<i class="fa fa-users fa-fw"></i>&nbsp;Users
				</a>
				<a *ngIf="isMenuItemValid('EnableRole') == true" (click)="selectedComponentItem('AssignUserRole', 3 , true)" [ngClass]="{'router-link-active': selectedMenuItem == 3}"
				 class="list-group-item">
					<i class="fa fa-users fa-fw"></i>&nbsp;Roles
				</a>
				<a *ngIf="isMenuItemValid('EnableJobProfile') == true" (click)="selectedComponentItem('AllJobProfile', 4, true )" [ngClass]="{'router-link-active': selectedMenuItem == 4}"
				 class="list-group-item">
					<i class="fa fa-user fa-fw"></i>&nbsp;Job Profiles
				</a>
				<a *ngIf="isMenuItemValid('CompairJobProfile') == true && selectedUserRole.length >0 && selectedUserRole[0][1] == 'Candidate' "
				 (click)="selectedComponentItem('CompairJobProfile', 5, true )" [ngClass]="{'router-link-active': selectedMenuItem == 5}"
				 class="list-group-item">
					<i class="fa fa-balance-scale fa-fw"></i>&nbsp;Job Profile Comparison
				</a>
				<a *ngIf="isMenuItemValid('JobProgressionPlanning') == true && selectedUserRole.length >0 && selectedUserRole[0][1] == 'Candidate' "
				 (click)="selectedComponentItem('JobProgressionPlanning', 13, true )" [ngClass]="{'router-link-active': selectedMenuItem == 13}"
				 class="list-group-item">
					<i class="fa fa-line-chart fa-fw"></i>&nbsp;Job Progression Planning
				</a>
				<a *ngIf="isMenuItemValid('EnableAssessment') == true" (click)='isScheduleCollapsed = !isScheduleCollapsed' class="list-group-item"
				 aria-controls="collapseScheduleSubMenu" [attr.aria-expanded]="!isScheduleCollapsed">
					<i class="fa fa-book fa-fw"></i>&nbsp;Assessment&nbsp;
					<i [ngClass]="{'fa-chevron-down': !isScheduleCollapsed, 'fa-chevron-up': isScheduleCollapsed}" class="fa" aria-hidden="true"></i>
				</a>
				<div *ngIf="isMenuItemValid('EnableAssessment') == true" [ngbCollapse]="!isScheduleCollapsed" id="collapseScheduleSubMenu">
					<a (click)="selectedComponentItem('ScheduleAssessment', 61, true)" [ngClass]="{'router-link-active': selectedMenuItem == 61}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-building fa-fw"></i>&nbsp;Schedule Assessment
					</a>
					<a (click)="selectedComponentItem('Calendar', 62, true)" [ngClass]="{'router-link-active': selectedMenuItem == 62}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-calendar fa-fw"></i>&nbsp;Assessment Calendar
					</a>
					<a (click)="selectedComponentItem('Assessments', 63, true)" [ngClass]="{'router-link-active': selectedMenuItem == 63}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-puzzle-piece fa-fw"></i>&nbsp;Manage Assessments
					</a>
				</div>
				<a *ngIf="( isMenuItemValid('EnableRemediationReport') == true && selectedUserRole.length >0 && selectedUserRole[0][1] == 'Candidate')"
				 (click)="reloadMyRemediationReport()" [ngClass]="{'router-link-active': selectedMenuItem == 72}" class="list-group-item">
					<i class="fa fa-archive fa-fw"></i>&nbsp;My Remediation Report
				</a>
				<a *ngIf="isMenuItemValid('EnableReport') == true || isMenuItemValid('SuccessionPlanning') == true  || isMenuItemValid('CandidateCompetency') == true  || 
				(isMenuItemValid('RegulatoryComplianceReport') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate') || (isMenuItemValid('CompairJobProfile') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate') "
				 (click)="isReportCollapsed = !isReportCollapsed" class="list-group-item" aria-controls="collapseReportSubMenu" [attr.aria-expanded]="!isReportCollapsed">
					<i class="fa fa-file-text-o fa-fw"></i>&nbsp; Report &nbsp;
					<i [ngClass]="{'fa-chevron-down': !isReportCollapsed, 'fa-chevron-up': isReportCollapsed}" class="fa" aria-hidden="true"></i>
				</a>
				<div [ngbCollapse]="!isReportCollapsed" id="collapseReportSubMenu">
					<a *ngIf="isMenuItemValid('EnableReport') == true" (click)="selectedComponentItem('Report', 71, true)" [ngClass]="{'router-link-active': selectedMenuItem == 71}"
					 class="list-group-item">
						&nbsp;&nbsp;
						<i class="fa fa-file-text-o fa-fw"></i>&nbsp;Simple Reports
					</a>
					<a *ngIf="isMenuItemValid('KnowledgeTestByCandidate') == true " (click)="selectedComponentItem('KnowledgeTestByCandidate', 81, true)"
					 [ngClass]="{'router-link-active': selectedMenuItem == 81}" class="list-group-item pr-0">
						&nbsp;&nbsp;
						<i class="fa fa-newspaper-o fa-fw"></i>&nbsp;Knowledge Check by Candidate
					</a>
					<a *ngIf="isMenuItemValid('EnableRemediationReport') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate'"
					 (click)="selectedComponentItem('RemediationReport', 72, true)" [ngClass]="{'router-link-active': selectedMenuItem == 72}"
					 class="list-group-item">
						&nbsp;&nbsp;
						<i class="fa fa-archive fa-fw"></i>&nbsp;Remediation Report
					</a>
					<a *ngIf="isMenuItemValid('RemediationMatrix') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate'"
						(click)="selectedComponentItem('RemediationMatrix', 79, true)" [ngClass]="{'router-link-active': selectedMenuItem == 79}"
						class="list-group-item">
						   &nbsp;&nbsp;
						   <i class="fa fa-th fa-fw"></i>&nbsp;Remediation Matrix
					   </a>
					   <a *ngIf="isMenuItemValid('MissedTaskMatrix') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate'"
							(click)="selectedComponentItem('MissedTaskMatrix', 80, true)" [ngClass]="{'router-link-active': selectedMenuItem == 80}"
							class="list-group-item">
							   &nbsp;&nbsp;
							   <i class="fa fa-list fa-fw"></i>&nbsp;Missed Task Matrix
						   </a>
					<a *ngIf="isMenuItemValid('CompairJobProfile') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate' "
					 (click)="selectedComponentItem('CompairJobProfile', 73, true)" [ngClass]="{'router-link-active': selectedMenuItem == 73}"
					 class="list-group-item">
						&nbsp;&nbsp;
						<i class="fa fa-balance-scale fa-fw"></i>&nbsp;Job Profile Comparison
					</a>
					<a *ngIf="isMenuItemValid('JobProgressionPlanning') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate' "
					 (click)="selectedComponentItem('JobProgressionPlanning', 74, true)" [ngClass]="{'router-link-active': selectedMenuItem == 74}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;
						<i class="fa fa-line-chart fa-fw"></i>&nbsp;Job Progression Planning
					</a>
					<a *ngIf="isMenuItemValid('SuccessionPlanning') == true " (click)="selectedComponentItem('SuccessionPlanning', 75, true)"
					 [ngClass]="{'router-link-active': selectedMenuItem == 75}" class="list-group-item pr-0">
						&nbsp;&nbsp;
						<i class="fa fa-random fa-fw"></i>&nbsp;Succession Planning
					</a>
					<a *ngIf="isMenuItemValid('CompetencyAnalysis') == true " (click)="selectedComponentItem('CompetencyAnalysis', 76, true)"
					 [ngClass]="{'router-link-active': selectedMenuItem == 76}" class="list-group-item pr-0">
						&nbsp;&nbsp;
						<i class="fa fa-trophy fa-fw"></i>&nbsp;Competency Analysis Tool
					</a>
					<a *ngIf="isMenuItemValid('RegulatoryCompliance') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate' "
					 (click)="selectedComponentItem('RegulatoryCompliance', 78, true)" [ngClass]="{'router-link-active': selectedMenuItem == 78}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;
						<i class="fa fa-university fa-fw"></i>&nbsp;Regulatory Compliance
					</a>
					<a *ngIf="isMenuItemValid('CandidateCompetency') == true && selectedUserRole.length >0 && selectedUserRole[0][1] != 'Candidate' "
					 (click)="selectedComponentItem('CandidateCompetency', 77, true)" [ngClass]="{'router-link-active': selectedMenuItem == 77}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;
						<i class="fa fa-industry fa-fw"></i>&nbsp;Candidate Competency
					</a>
				</div>
				<a *ngIf="isMenuItemValid('EnableCompLib') == true" (click)='isCollapsed = !isCollapsed' class="list-group-item" aria-controls="collapseSubMenu"
				 [attr.aria-expanded]="!isCollapsed">
					<i class="fa fa-thumbs-o-up fa-fw"></i>&nbsp;Competency Library&nbsp;
					<i [ngClass]="{'fa-chevron-down': !isCollapsed, 'fa-chevron-up': isCollapsed}" class="fa" aria-hidden="true"></i>
				</a>
				<div *ngIf="isMenuItemValid('EnableCompLib') == true" [ngbCollapse]="!isCollapsed" id="collapseSubMenu">
					<a (click)="selectedComponentItem('CoreCompetency', 111, true)" [ngClass]="{'router-link-active': selectedMenuItem == 111}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-asterisk fa-fw"></i>&nbsp;Core Competency
					</a>
					<a (click)="selectedComponentItem('AllTask', 112, true)" [ngClass]="{'router-link-active': selectedMenuItem == 112}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-tasks fa-fw"></i>&nbsp;Task
					</a>
					<a (click)="selectedComponentItem('AllTaskGroup', 113, true)" [ngClass]="{'router-link-active': selectedMenuItem == 113}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-object-group fa-fw"></i>&nbsp;Task Group
					</a>
					<a (click)="selectedComponentItem('AllPerformance', 114, true)" [ngClass]="{'router-link-active': selectedMenuItem == 114}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-tachometer fa-fw"></i>&nbsp;Performance
					</a>
					<a (click)="selectedComponentItem('Checklist', 115, true)" [ngClass]="{'router-link-active': selectedMenuItem == 115}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-list fa-fw"></i>&nbsp;Checklist
					</a>
					<a (click)="selectedComponentItem('Remediation', 116, true)" [ngClass]="{'router-link-active': selectedMenuItem == 116}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-cogs fa-fw"></i>&nbsp;Remediation
					</a>
					<a (click)="selectedComponentItem('Knowledge', 117, true)" [ngClass]="{'router-link-active': selectedMenuItem == 117}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-lightbulb-o fa-fw"></i>&nbsp;Knowledge
					</a>
					<a (click)="selectedComponentItem('AllNote', 118, true)" [ngClass]="{'router-link-active': selectedMenuItem == 118}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-paperclip fa-fw"></i>&nbsp;Note
					</a>
					<a (click)="selectedComponentItem('AllNAReason', 119, true)" [ngClass]="{'router-link-active': selectedMenuItem == 119}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-comment fa-fw"></i>&nbsp;Reason for N/A
					</a>
					<a (click)="selectedComponentItem('AllRegulatoryAgency', 127, true)" [ngClass]="{'router-link-active': selectedMenuItem == 127}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-university fa-fw"></i>&nbsp;Regulatory Agency
					</a>
				</div>
				<a *ngIf="isMenuItemValid('EnableDiscipline') == true || isMenuItemValid('EnableOrgCode') == true || isMenuItemValid('EnableOrgCode') == true"
				 (click)='isCompanyConfigCollapsed = !isCompanyConfigCollapsed' class="list-group-item" aria-controls="collapseCompanyConfigSubMenu"
				 [attr.aria-expanded]="!isCompanyConfigCollapsed">
					<i class="fa fa-cog fa-fw"></i>&nbsp;Company Config&nbsp;
					<i [ngClass]="{'fa-chevron-down': !isCompanyConfigCollapsed, 'fa-chevron-up': isCompanyConfigCollapsed}" class="fa" aria-hidden="true"></i>
				</a>
				<div *ngIf="isMenuItemValid('EnableDiscipline') == true || isMenuItemValid('EnableOrgCode') == true || isMenuItemValid('EnableOrgCode') == true"
				 [ngbCollapse]="!isCompanyConfigCollapsed" id="collapseCompanyConfigSubMenu">
					<a *ngIf="isMenuItemValid('EnableDiscipline') == true" (click)="selectedComponentItem('Discipline', 8, true)" [ngClass]="{'router-link-active': selectedMenuItem == 8}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-check-square-o fa-fw"></i>&nbsp;Discipline
					</a>
					<a *ngIf="isMenuItemValid('EnableOrgCode') == true" (click)="selectedComponentItem('OrgCode', 9, true)" [ngClass]="{'router-link-active': selectedMenuItem == 9}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-sitemap fa-fw"></i>&nbsp;Organization
					</a>
					<a *ngIf="isMenuItemValid('EnableOrgCode') == true" (click)="selectedComponentItem('OrgType', 10, true )" [ngClass]="{'router-link-active': selectedMenuItem == 10}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-sitemap fa-fw"></i>&nbsp;Org Type
					</a>
					<a *ngIf="isMenuItemValid('ChangeTheme') == true" (click)="selectedComponentItem('ChangeTheme', 11, true )" [ngClass]="{'router-link-active': selectedMenuItem == 11}"
					 class="list-group-item pr-0">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-paint-brush fa-fw"></i>&nbsp;Setup
					</a>
				</div>
				<a *ngIf="isMenuItemValid('EnableTag') == true" (click)='istagCollapsed = !istagCollapsed' class="list-group-item" aria-controls="collapsetagSubMenu"
				 [attr.aria-expanded]="!istagCollapsed">
					<i class="fa fa-tag"></i>&nbsp;Tags &nbsp;
					<i [ngClass]="{'fa-chevron-down': !istagCollapsed, 'fa-chevron-up': istagCollapsed}" class="fa" aria-hidden="true"></i>
				</a>
				<div *ngIf="isMenuItemValid('EnableTag') == true" [ngbCollapse]="!istagCollapsed" id="collapsetagSubMenu">
					<a (click)="selectedComponentItem('CoreCompetencyTag', 121, true)" [ngClass]="{'router-link-active': selectedMenuItem == 121}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-asterisk fa-fw"></i>&nbsp;Core Competency Tags
					</a>
					<a (click)="selectedComponentItem('AllTaskTag', 122, true)" [ngClass]="{'router-link-active': selectedMenuItem == 122}" class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-tasks fa-fw"></i>&nbsp;Task Tags
					</a>
					<a (click)="selectedComponentItem('AllPerformanceTag', 123, true)" [ngClass]="{'router-link-active': selectedMenuItem == 123}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-tachometer fa-fw"></i>&nbsp;Performance Tags
					</a>
					<a (click)="selectedComponentItem('ChecklistTag', 124, true)" [ngClass]="{'router-link-active': selectedMenuItem == 124}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-list fa-fw"></i>&nbsp;Checklist Tags
					</a>
					<a (click)="selectedComponentItem('RemediationTag', 125, true)" [ngClass]="{'router-link-active': selectedMenuItem == 125}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-cogs fa-fw"></i>&nbsp;Remediation Tags
					</a>
					<a (click)="selectedComponentItem('KnowledgeTag', 126, true)" [ngClass]="{'router-link-active': selectedMenuItem == 126}"
					 class="list-group-item">
						&nbsp;&nbsp;&nbsp;
						<i class="fa fa-lightbulb-o fa-fw"></i>&nbsp;Knowledge Tags
					</a>
				</div>
			</ul>
		</nav>
	</div>
	<div class="col-sm-12 col-md-12 mx-auto lpadding" [ngClass]="{'content-top-70':  showOverlay == false}">
		<component-holder [componentItem]="selectedItem"> </component-holder>
	</div>
</div>