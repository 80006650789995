import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { UserService } from '../user/user.service';
import { OrgcodeService } from '../orgcode/orgcode.service';
import { JobProfileService } from '../jobprofile/jobprofile.service';
import { DisciplineService } from '../discipline/discipline.service';
import { AssessmentService } from './assessment.service';
import { SelectComponent } from 'ng2-select';

@Component({
	selector: 'app-filterForm',
	templateUrl: './scheduleassessment.component.html',
	styleUrls: ['./scheduleassessment.component.scss']
})

export class ScheduleAssessmentComponent implements OnInit {
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filterForm: FormGroup;
	public orgList: Array<any> = [];
	public jobProfileList: Array<any> = [];
	public disciplineList: Array<any> = [];
	color: string;
	selectedUsers: Array<any> = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public selectedUserRole = [];
	isQueryAssessment: boolean = false;
	isAddAssessment: boolean = false;
	public filteredData: Array<any> = [];
	originaldata: Array<any> = [];
	public searchFullName: string = "";
	public searchOrgCode: string = "";
	public searchJobProfile: string = "";
	public searchDiscipline: string = "";
	public isLoading = false;
	isFiltering: boolean = true;
	show: boolean = false;

	constructor(private router: Router, private disciplineService: DisciplineService, private jobProfileService: JobProfileService,
		private userService: UserService, private sharedService: SharedService, private alertServices: AlertService,
		private orgcodeService: OrgcodeService, private assessmentService: AssessmentService, private changeDetectorRef: ChangeDetectorRef, ) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.getActiveDisciplines();
		this.getActiveOrgCodes();
		this.getActiveJobProfiles();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		this.isQueryAssessment = this.userService.isCapabilityAssigned('QueryAssessment', this.selectedUserRole[0][1]);
		this.isAddAssessment = this.userService.isCapabilityAssigned('AddAssessment', this.selectedUserRole[0][1]);
	}

	private getActiveJobProfiles() {
		this.jobProfileService.getAllActiveJobProfiles()
			.subscribe(
			data => {
				this.jobProfileList = [];
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobProfileList.push({ id: data.jobProfileList[i].Id, text: data.jobProfileList[i].Description });
				}
			},
			error => {

			}
			);
	}

	private getActiveOrgCodes() {
		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
				}
			},
			error => {

			}
			);
	}

	private getActiveDisciplines() {
		this.disciplineService.getActiveDisciplines()
			.subscribe(
			data => {
				this.disciplineList = [];
				for (let i = 0; i < data.disciplineList.length; i++) {
					this.disciplineList.push({
						id: data.disciplineList[i].Id,
						text: data.disciplineList[i].Discipline
					});
				}
			},
			error => {
				let err = error.json();
				this.alertServices.clear();
				this.alertServices.handleError(err);
			}
			);
	}

	onSorted($event) {
		this.isFiltering = false;
		this.sortData($event)
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: ScheduledAssessmentSearchCriteria) {
		if (criteria.sortColumn == 'FullName' || criteria.sortColumn == 'OrgCode' || criteria.sortColumn == 'JobProfile' || criteria.sortColumn == 'Discipline') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	sortData(criteria: ScheduledAssessmentSearchCriteria) {
		this.alertServices.clear()
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push(this.originaldata[i]);
			}
			if (this.searchFullName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["FullName"].match(new RegExp(this.searchFullName, "i"));
				});
			}
			if (this.searchOrgCode != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
				});
			}
			if (this.searchJobProfile != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
				});
			}
			if (this.searchDiscipline != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Discipline"].match(new RegExp(this.searchDiscipline, "i"));
				});
			}
		}
	}

	private showTableData(param) {
		this.isLoading = true;
		this.isFiltering = true;
		this.alertServices.clear()
		this.assessmentService.getAllAssessmentBySelectedParameters(param)
			.subscribe(
			data => {
				console.log(data)
				for (let i = 0; i < data.userList.length; i++) {
					var fullName = data.userList[i].FirstName + ' ' + data.userList[i].LastName;
					let completedDate = null;
					if (data.userList[i].LastAssessmentCompletedDate) {
						completedDate = new Date(new Date(data.userList[i].LastAssessmentCompletedDate).getUTCFullYear(), new Date(data.userList[i].LastAssessmentCompletedDate).getUTCMonth(), new Date(data.userList[i].LastAssessmentCompletedDate).getUTCDate());
					}
					var rec = {
						'Id': data.userList[i].Id, 'FullName': fullName, 'IsContract': data.userList[i].IsContract, 'SupervisorId': data.userList[i].SupervisorId, 'OrgCodeId': data.userList[i].OrgId, 'OrgCode': data.userList[i].orgCode != null ? data.userList[i].orgCode : '',
						'JobProfileId': data.userList[i].JobProfieId, 'JobProfile': data.userList[i].jobProfile != null ? data.userList[i].jobProfile : '',
						'DisciplineId': data.userList[i].DisciplineId, 'Discipline': data.userList[i].Discipline != null ? data.userList[i].Discipline : '', 'MonthsFromLastAssessment': this.calculateMonths(data.userList[i].lastAssessmentdays),
						'LastAssessmentDate': this.calculateAssessmentScheduleDate(data.userList[i].AssessmentCompletedDate, data.userList[i].ScheduleAssessmentDate, data.userList[i].SelfAssessmentCompletedDate, data.userList[i].differentDateBtweenCompleteAndCurrent, data.userList[i].Frequency, data.userList[i].Status, data.userList[i].SelfAssessmentStatus, data.userList[i].IsKnowledgeTest),
						'completedDate': completedDate, 'selected': 0
					}
					this.originaldata.push(rec);
				}
				this.isLoading = false;
				this.isFiltering = false;
				this.total = this.originaldata.length;
				this.filteredData = this.originaldata
				this.pageChanged();
				this.show = true;
			},
			error => {
				this.isLoading = false;
				let err = error.json();
				this.alertServices.clear();
				this.alertServices.handleError(err);
			}
			);
	}

	filter() {
		this.isFiltering = true;
		this.filteredData = [];
		this.alertServices.clear()
		for (let i = 0; i < this.originaldata.length; i++) {
			this.filteredData.push(this.originaldata[i]);
		}

		if (this.searchFullName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["FullName"].match(new RegExp(this.searchFullName, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		if (this.searchDiscipline != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Discipline"].match(new RegExp(this.searchDiscipline, "i"));
			});
		}
		this.pageChanged(1);
		this.isFiltering = false;
	}

	ngOnInit() {
		this.filterForm = new FormGroup({
			orgId: new FormControl(null),
			includeChildren: new FormControl(false),
			jobProfileId: new FormControl(null),
			disciplineId: new FormControl(null),
			option: new FormControl(null),
			pastDue: new FormControl(null),
			months: new FormControl(null),
			years: new FormControl(null)
		});
	}


	onSubmit() {
		let orgId = null;
		this.searchFullName = '';
		this.searchOrgCode = '';
		this.searchJobProfile = '';
		this.searchDiscipline = '';
		this.filteredData = [];
		this.originaldata = [];
		if (this.filterForm.get("orgId").value != null) {
			if (this.filterForm.get("orgId").value.length > 0) {
				orgId = this.filterForm.get("orgId").value[0].id;
			}
		}
		let jobProfileId = null;
		if (this.filterForm.get("jobProfileId").value != null) {
			if (this.filterForm.get("jobProfileId").value.length > 0) {
				jobProfileId = this.filterForm.get("jobProfileId").value[0].id;
			}
		}
		let disciplineId = null;
		if (this.filterForm.get("disciplineId").value != null) {
			if (this.filterForm.get("disciplineId").value.length > 0) {
				disciplineId = this.filterForm.get("disciplineId").value[0].id;
			}
		}

		var param = {
			'orgId': orgId, 'includeChildren': this.filterForm.get("includeChildren").value,
			'jobProfileId': jobProfileId, 'disciplineId': disciplineId,
			'option': this.calculateDays(this.filterForm.get("years").value, this.filterForm.get("months").value),
			'userId': '', 'includeCompleted': false, 'pastDue': this.filterForm.get("pastDue").value
		};
		this.showTableData(param)

	}

	setSelected(e, i) {
		this.alertServices.clear();
		let objIndex = this.originaldata.findIndex((obj => obj.Id == this.paginatedResults[i].Id));
		if (e.target.checked) {
			if (this.originaldata[objIndex].LastAssessmentDate == "yellow") {
				this.alertServices.clear();
				this.alertServices.error("This user already has an active assignment.");
				e.target.checked = false;
			} else {
				this.originaldata[objIndex].selected = 1;
			}
		} else {
			this.originaldata[objIndex].selected = 0;
		}
		this.selectedUsers = this.originaldata.filter(function (v) { return v["selected"] == 1; });
	}

	scheduleAssessment() {
		this.selectedUsers = this.originaldata.filter(function (v) { return v["selected"] == 1; });
		this.assessmentService.setSelectedUsers(this.selectedUsers);
		this.selectedUsers = [];
		this.selectedComponentItem('Calendar');
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	private calculateDays(years: any, months: any) {
		var days = 0;
		if (years) {
			days += 365 * years
		}
		if (months) {
			days += 30 * months
		}
		return days;
	}

	private calculateMonths(days) {
		var month;
		month = Math.floor(days / 30) + 'M ' + days % 30 + 'D'
		return month;
	}

	private calculateAssessmentScheduleDate(assessmentCompletedDate, scheduleAssessmentDate, selfAssessmentCompletedDate, sheduledate, frequency, status, selfAssessmentStatus, isKnowledgeTest) {
		if (scheduleAssessmentDate == null && assessmentCompletedDate == null) {
			this.color = 'red';
		} else if (scheduleAssessmentDate != null && (assessmentCompletedDate == null || status == "Started" || selfAssessmentCompletedDate == null)) {
			this.color = 'yellow';
		} else {
			if (sheduledate > 0) {
				if (sheduledate > frequency * 30) {
					this.color = 'red';
				} else {
					this.color = 'green';
				}
			} else {
				if (status == 'Completed' || (selfAssessmentStatus == 'Completed' && isKnowledgeTest == 1)) {
					this.color = 'green';
				} else {
					console.log("yellow 2")
					this.color = 'yellow';
				}
			}
		}
		return this.color;
	}

	public clearForm() {
		this.alertServices.clear()
		this.filterForm.reset();
		this.filteredData = [];
		this.originaldata = [];
		this.show = false
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	showData(e) {
		if (e.target.checked) {
			this.filterForm.get("months").disable()
			this.filterForm.get("years").disable()
			this.filterForm.get("months").setValue(null)
			this.filterForm.get("years").setValue(null)
		} else {
			this.filterForm.get("months").enable()
			this.filterForm.get("years").enable()
		}
	}
}

export class ScheduledAssessmentSearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}
