import { Injectable } from '@angular/core'
import { Http, Response, Headers } from '@angular/http'
import { Observable } from 'rxjs/Rx'
import { environment } from '../environments/environment'
import { Router } from '@angular/router'
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AppService {

   constructor(private http: Http, private router: Router) { }

   getAppInfo() {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      return this.http.post(environment.server_URL + '/user/getAppInfo', { headers: headers })
         .map(res => res.json());
   }

}