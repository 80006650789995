import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { TaskTagService } from './tasktag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-add-tasktag',
	templateUrl: './addtasktag.component.html'
})

export class AddTaskTagComponent implements OnInit {
	addTaskTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private taskTagService: TaskTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.addTaskTag = new FormGroup({
			taskTag: new FormControl(null, Validators.required)
		});
	}

	cancel() {
		this.sharedService.change('AllTaskTag');
	}

	saveAndNew() {

		this.taskTagService.addTaskTag(this.addTaskTag.value.taskTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully added the task tag.");
				// this.addTaskTag.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	save() {

		this.taskTagService.addTaskTag(this.addTaskTag.value.taskTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.cancel();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

}
