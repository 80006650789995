<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Edit Remediation</h4>
		</div>
		<div class="float-right">
		</div>
	</div>
	<hr/>
</div>

<div class="container">
	<form [formGroup]="editRemediationForm" (ngSubmit)="onSubmit()">
		<!--Delivery Medium-->
		<div *ngIf="mediumList">
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Delivery Medium
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-5">
					<select class="form-control" formControlName="medium" name="medium" id="medium" [(ngModel)]="mediumId" required>
						<option *ngFor="let ml of mediumList" [ngValue]="ml.id"> {{ml.text}} </option>
					</select>
				</div>
			</div>
		</div>
		<!--description-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Description
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<!--<input noSpaces maxlength="50" type="text" class="form-control" id="description" formControlName="description" name="description">-->
				<textarea noSpaces maxlength="400" autosize type="text" class="form-control" id="description" formControlName="description" name="description"></textarea>
			</div>
		</div>
		<!--type-->
		<div *ngIf="typeList">
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Type
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-5">
					<select class="form-control" formControlName="type" name="type" id="type" [(ngModel)]="typeId" required>
						<option *ngFor="let tl of typeList" [ngValue]="tl.id"> {{tl.text}} </option>
					</select>
				</div>
			</div>
		</div>
		<!--COURSE NUMBER-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Course Number</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="50" type="text" class="form-control" id="coursenumber" formControlName="coursenumber" name="coursenumber">
			</div>
		</div>
		<!--course description-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Course Description</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="400" type="text" class="form-control" id="coursedescription" formControlName="coursedescription" name="coursedescription">
			</div>
		</div>
		<!--link-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Link</label>
			<div class="col-sm-10">
				<input noSpaces maxlength="100" type="url" class="form-control" id="link" formControlName="link" name="link" (click)="updateLink($event)" pattern="^(?:(ftp|http|https)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$">
			</div>
			<div class="alert alert-danger" role="alert" *ngIf="editRemediationForm.controls.link.hasError('pattern')">
				Your link must contain at least one period(.) and at least one character before the period and at least two charcters after
				the period.</div>
		</div>
		<!--orgcode-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Org Code</label>
			<div class="col-sm-9">
				<ng-select [allowClear]="true" [items]="orgList" placeholder="Select org code" name="orgId" id="orgId" formControlName="orgId" [disabled]="dependencyList.length > 0">
				</ng-select>
			</div>
			<div *ngIf="dependencyList.length > 0" class="col-sm-1">
					<button type="button" class="btn btn-outline-warning" (click)="checkForDependencies()">
						<i class="fa fa-link" aria-hidden="true"></i>
					</button>
				</div>
		</div>
		<!--Note-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Note</label>
			<div class="col-sm-10">
				<textarea noSpaces maxlength="400" autosize type="text" class="form-control" id="note" formControlName="note" name="note"></textarea>
			</div>
		</div>
		<!--attached file-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Attachments</label>
			<div class="col-sm-10">
				<div *ngFor="let item of selectedAttachmentList; let k = index ">
					<ng-template [ngIf]="item.text == 'pdf' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-pdf-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'txt' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-text-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'docx' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-word-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'doc' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-word-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'ppt' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-powerpoint-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'pptx' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-powerpoint-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'xls' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-excel-o fa-lg" (click)="fileIconButtonClickEvent(item.fileNamet, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
					<ng-template [ngIf]="item.text == 'xlsx' ">
						<label for="default" class="">
							<a class="btn btn-sm edit">
								<i class="fa fa-file-excel-o fa-lg" (click)="fileIconButtonClickEvent(item.fileName, item.id);" aria-hidden="true"></i>
							</a>
							{{ item.fileName }}
							<a class="btn btn-sm edit">
								<i class="fa fa-times-circle-o fa-lg" aria-hidden="true" (click)="removeFileClickEvent(item.id, item.fileName, k)"></i>
							</a>
						</label>
					</ng-template>
				</div>
			</div>
		</div>
		<!--upload-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Attachment</label>
			<div class="col-sm-10">
				<input type="file" #fileInput id="file" (change)="fileChangeEvent($event)" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
				 multiple>
				<br>
				<label class="col-form-label">( .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf )</label>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Tags</label>
			<div class="col-sm-10">
				<tag-input [ngModel]="items" [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" formControlName="remediationTag"
				 [displayBy]="'value'" [identifyBy]="'id'" (onAdd)="addTags($event)" (onRemove)="removeTags($event)" name="remediationTag">

					<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="activeTagList">
					</tag-input-dropdown>
				</tag-input>
			</div>
		</div>
		<alert></alert>

		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-6">
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!valid()">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('Remediation')">Cancel</button>
			</div>
		</div>
	</form>
</div>