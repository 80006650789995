import { Component, OnInit, AfterViewInit } from '@angular/core'
import { ViewChild } from '@angular/core'

import { SelectComponent } from 'ng2-select'
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms"

import { JobProfileService } from '../jobprofile.service'
import { UserService } from '../../user/user.service'
import { AlertService } from '../../util/alert/alert.service'

import { JobProfile } from '../jobprofile.model'
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model'

import { Constants } from '../../util/const/constants'

@Component({
	selector: 'app-compairjobprofile',
	templateUrl: './compairjobprofile.component.html'
})
export class CompairJobProfileComponent implements OnInit, AfterViewInit {

	@ViewChild('selecta', { static: true }) public selecta: SelectComponent
	@ViewChild('selectb', { static: true }) public selectb: SelectComponent

	public compairForm: FormGroup;
	public jobProfileListA: Array<any> = []
	public jobProfileListB: Array<any> = []
	public jobProfileList: Array<any> = []
	public userJobProfile: any = null
	public jpACCList: any;
	public jpBCCList: any;
	public resultCCL: any;
	public showHierarchy: boolean = false;
	public jobProfile: JobProfile

	public resultHeading: string = ''
	public jpAText: string
	public jpBText: string
	public selectedOptionA: any = null
	public selectedOptionB: any = null

	public showingItems: any = []
	ccList: any = []

	constructor(private jobProfileService: JobProfileService, private userService: UserService, private alertService: AlertService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.getActiveJobProfiles()
		let ujp = JSON.parse(localStorage.getItem('userJobProfile'))
		if (ujp.id != null) {
			this.userJobProfile = ujp
		}

	}

	ngOnInit() {
		this.compairForm = new FormGroup({
			jobProfileA: new FormControl(null, Validators.required),
			jobProfileB: new FormControl(null, Validators.required)
		})
		let selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'))
		// console.log('[ngOnInit] (userRole) ' + JSON.stringify(selectedUserRole[0][0]))
		if (selectedUserRole[0][0] == '1') {
			if (this.userJobProfile.id != null) {
				this.compairForm.get('jobProfileA').setValue([{ id: this.userJobProfile.id, text: this.userJobProfile.text }])
			} else {
				//do nothing
			}
		} else {
			//do nothing
		}

	}

	ngAfterViewInit() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.

	}

	private mouseDownEvent() {

	}


	selectOptionA(option: any, item: string) {		
		//hide job profile hierarchy
		this.showHierarchy = false;
		this.jobProfileListB = this.jobProfileList.filter(item => (item.id != option.id));
		this.selectb.clickedOutside()
		this.selecta.clickedOutside()
	}

	selectOptionB(option: any, item: string) {		
		//hide job profile hierarchy
		this.showHierarchy = false;
		this.jobProfileListA = this.jobProfileList.filter(item => (item.id != option.id));
		this.selectb.clickedOutside()
		this.selecta.clickedOutside()
	}

	removeJobProfileA(option: any, item: string) {		
		//hide job profile hierarchy
		this.showHierarchy = false
		this.jobProfileListB = this.jobProfileList;
	}

	removeJobProfileB(option: any, item: string) {		
		//hide job profile hierarchy
		this.showHierarchy = false
		this.jobProfileListA = this.jobProfileList;
	}

	private removeItemInJPList(jobProfileList: any, item: any): any {
		let returnVal: Array<any> = []
		jobProfileList.forEach(element => {
			if (element.id != item.id) {
				returnVal.push(element)
			}
		});
		return returnVal
	}

	private getActiveJobProfiles() {
		this.jobProfileService.getAllActiveJobProfiles().subscribe(
			data => {
				this.jobProfileListA = [];
				this.jobProfileListB = [];
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobProfileListA.push({
						'id': data.jobProfileList[i].Id,
						'text': data.jobProfileList[i].Description
					});
					this.jobProfileListB.push({
						'id': data.jobProfileList[i].Id,
						'text': data.jobProfileList[i].Description
					});
					this.jobProfileList.push({
						'id': data.jobProfileList[i].Id,
						'text': data.jobProfileList[i].Description
					});
				}
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		);
	}

	//get differences
	private getDifferences(cclJPA: any, cclJPB: any, rcclJP: any) {
		this.alertService.clear();
		this.resultHeading = 'Differences'
		cclJPA.forEach(ccItem => {
			if (!this.checkValueExists(rcclJP, ccItem.id)) {
				rcclJP.push(ccItem)
			} else {
				rcclJP = rcclJP.filter(item => item.id !== ccItem.id);
				let item: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text, ccItem.status, [])
				item.orderNum = ccItem.orderNum
				this.ccList.push(item);
				this.getTaskDifferences(cclJPA, cclJPB, this.ccList);
				if (this.ccList[0].taskList.length != 0) {
					this.ccList.forEach(ccitem => {
						if (!this.checkValueExists(rcclJP, ccitem.id)) {
							rcclJP.push(ccitem);
						}
					})
				}
				else {
					//do nothing
				}
				this.ccList = [];
			}
		});

		let jp: JobProfile = new JobProfile('0001')
		jp.coreCompetencyList = rcclJP;
		this.jobProfile = jp
		this.ccList = [];
		if (rcclJP.length == 0) {
			this.alertService.clear();
			this.alertService.info(Constants.INFO_NO_DIFFERENCE)
		} else {
			this.showHierarchy = true
		}
	}

	//get task difference
	getTaskDifferences(cclJPA: any, cclJPB: any, rcclJP: any) {
		rcclJP.forEach(ccItem => {
			let ccIJPA = cclJPA.find(item => item.id === ccItem.id);
			let ccIJPB = cclJPB.find(item => item.id === ccItem.id);
			if (ccIJPA != null && ccIJPB != null) {
				if (ccIJPA.taskList.length != 0) {
					ccIJPA.taskList.forEach(task => {
						if (!this.checkValueExists(ccIJPB.taskList, task.id)) {
							ccItem.taskList.push(task)
						} else {
							//do nothing
						}
					});
				}
				if (ccIJPB.taskList.length != 0) {
					ccIJPB.taskList.forEach(task => {
						if (!this.checkValueExists(ccIJPA.taskList, task.id)) {
							ccItem.taskList.push(task)
						} else {
							//do nothing
						}
					});
				}
			}
		});
	}

	//get similarities
	private getSimilarities(cclJPA: any, cclJPB: any) {
		this.resultHeading = 'Similarities'
		let returnVal: any = []
		cclJPA.forEach(ccItem => {
			if (this.checkValueExists(cclJPB, ccItem.id)) {
				let item: CoreCompetency = new CoreCompetency(ccItem.id, ccItem.text, ccItem.status, [])
				item.orderNum = ccItem.orderNum
				returnVal.push(item)
			} else {
				//do nothing
			}
		});
		this.getSimilarTaskList(cclJPA, cclJPB, returnVal)
		let jp: JobProfile = new JobProfile('0001')
		jp.coreCompetencyList = returnVal;
		this.jobProfile = jp
		if (returnVal.length == 0) {
			this.alertService.clear();
			this.alertService.info(Constants.INFO_NO_SIMILARITIES)
		} else {
			this.showHierarchy = true
		}

	}

	// check array for a given value
	private checkValueExists(list: any, value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.id == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	//sort array
	private sortArray(array: any) {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.text > b.text) {
					return 1;
				} else if (a.text < b.text) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	//get similar task
	private getSimilarTaskList(cclJPA: any, cclJPB: any, rcclJP: any) {
		rcclJP.forEach(ccItem => {
			let ccIJPA = cclJPA.find(item => item.id === ccItem.id);
			let ccIJPB = cclJPB.find(item => item.id === ccItem.id);
			ccIJPA.taskList.forEach(task => {
				if (this.checkValueExists(ccIJPB.taskList, task.id)) {
					ccItem.taskList.push(task)
				} else {
					//do nothing
				}
			});
		});
	}


	/**
	 * [compairJobProfile compair 2 job profiles]
	 * @param  {boolean} type [description]
	 * @return {[type]}       [type of the event/ true -> Differences, false -> Similarities]
	 */
	compairJobProfile(type: boolean) {
		const jpAId = this.compairForm.get("jobProfileA").value[0].id;
		const jpBId = this.compairForm.get("jobProfileB").value[0].id;
		// console.log('[showDifferences] (jpAId/jpBId) ' + jpAId + '/' + jpBId)

		let jpA: JobProfile = new JobProfile(jpAId)
		let jpB: JobProfile = new JobProfile(jpBId)

		this.showHierarchy = false

		this.jobProfileService.getJobProfileDetail(jpA).subscribe(
			data => {
				this.jpACCList = this.jobProfileService.generateCCListFromData(data)
				//remove IsTaskGroup = 1 tasks and append task inside the task group to ccItem.taskList
				this.jpACCList.forEach(ccItem => {
					ccItem.taskList.forEach(task => {
						if (task.isTaskGroup) {
							ccItem.taskList = ccItem.taskList.concat(task.taskList);
						}
					})
					ccItem.taskList = ccItem.taskList.filter(item => item.isTaskGroup == 0);
				});
				this.jobProfileService.getJobProfileDetail(jpB).subscribe(
					data => {
						this.jpBCCList = this.jobProfileService.generateCCListFromData(data)
						//remove IsTaskGroup = 1 tasks and append task inside the task group to ccItem.taskList					
						this.jpBCCList.forEach(ccItem => {
							ccItem.taskList.forEach(task => {
								if (task.isTaskGroup) {
									ccItem.taskList = ccItem.taskList.concat(task.taskList);
								}
							})
							ccItem.taskList = ccItem.taskList.filter(item => item.isTaskGroup == 0);
						});
						this.resultCCL = this.jobProfileService.generateCCListFromData(data)
						//remove IsTaskGroup = 1 tasks and append task inside the task group to ccItem.taskList
						this.resultCCL.forEach(ccItem => {
							ccItem.taskList.forEach(task => {
								if (task.isTaskGroup) {
									ccItem.taskList = ccItem.taskList.concat(task.taskList);
								}
							})
							ccItem.taskList = ccItem.taskList.filter(item => item.isTaskGroup == 0);
						});						
						if (type) {
							this.getDifferences(this.jpACCList, this.jpBCCList, this.resultCCL)
						} else {
							this.getSimilarities(this.jpACCList, this.jpBCCList)
						}
					},
					error => {
						let err = error.json()
						this.alertService.clear()
						this.alertService.handleError(err)
					}
				)
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		)
	}
}
