<div #activeAssessments class="row" style="overflow-x: auto;overflow-y: auto;">
  <div class= "col-sm-12 mb-1">
    <button type="button" [disabled]="!isDataExist" class="float-right btn btn-outline-warning btn-sm"
      (click)="exportToExcel()">
      <i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
    </button>
  </div> 
  <table class="table-sm table-bordered col-sm-12 table-striped mb-2">
    <thead>
      <tr>
        <th class="text-center ">Full Name</th>
        <th class="text-center ">Org Code</th>
        <th class="text-center ">Job Profile</th>
        <th class="text-center ">Knowledge Check Progress</th>
        <th class="text-center ">Knowledge Check Completed Date</th>        
        <th class="text-center ">Scheduled Assessor Lead Date</th>
        <th class="text-center ">Assessor Lead Progress</th>
        <th class="text-center ">Perform Assessment</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th><input style="cursor: pointer" class="form-control" (click)="clickEvent(fullName)" type="text" [(ngModel)]="searchFullName"
            #fullName name="searchFullName" (keyup)="filter()"></th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchOrgCode" (click)="clickEvent(orgCode)"
            #orgCode [(ngModel)]="searchOrgCode" (keyup)="filter()"></th>
        <th class=""><input style="cursor: pointer" class="form-control" type="text" name="searchJobProfile" (click)="clickEvent(jobProfile)"
            #jobProfile [(ngModel)]="searchJobProfile" (keyup)="filter()"></th>
        <th class=""></th>
        <th class="">
          <my-date-picker (dateChanged)="onKnowledgeTestCompletedDate($event)" [options]="knowledgeTestCompletedDatePickerOptions"
            [placeholder]="knowledgeTestCompletedDatePickerOptions.dateFormat" [(ngModel)]="searchKnowledgeTestCompletedDate">
          </my-date-picker>
        </th>        
        <th class="">
          <my-date-picker (dateChanged)="onSheduleAssessorLeadDate($event)"
            [options]="sheduleAssessorLeadDatePickerOptions"
            [placeholder]="sheduleAssessorLeadDatePickerOptions.dateFormat" [(ngModel)]="searchSheduleAssessorLeadDate">
          </my-date-picker>
        </th>
        <th class=""></th>
        <th class=""></th>
      </tr>
      <tr *ngFor="let row of paginatedResults; let i = index">
        <td>{{row.userName}}</td>
        <td>{{row.orgCode}}</td>
        <td>{{row.jobProfile}}</td>
        <td class="text-center">
          <div class="progress" *ngIf="row.selfAssessmentStatus != 'Completed'">				  
            <div class="progress-bar progress-bar-warning progress-bar-striped" [style.width]="row.selfAssessmentProgress + '%'" aria-valuemin="0"
              aria-valuemax="100">
              <span>{{row.selfAssessmentProgress}}% </span>
            </div>
          </div>
          <button *ngIf="row.selfAssessmentStatus == 'Completed'" type="button" class="btn btn-warning btn-sm pointer-cursor px-3 py-2"
            (click)="directToSelfAssessmentReview(row)">Review</button>
        </td>
        <td>{{row.selfAssessmentCompletedDate == null ? "" : row.selfAssessmentCompletedDate.toLocaleDateString()}}</td>        
        <td [class]="getStyle(row.scheduleAssessmentDate)" *ngIf="row.isKnowledgeTest == 0">{{row.scheduleAssessmentDate == null ? "" : row.scheduleAssessmentDate.toLocaleDateString()}}</td>
        <!--<td class="">{{row.assessmentCompletedDate == null ? "" : row.assessmentCompletedDate.toLocaleDateString()}}</td>-->
        <td class="" *ngIf="row.isKnowledgeTest == 0">
          <div class="progress" *ngIf="!(row.assessorCompleted == 1 && row.assessorSignature == 0 && userId == row.assessorId)" >				 
            <div class="progress-bar warning-bg progress-bar-striped"
              aria-valuenow="row.progress" aria-valuemin="0" aria-valuemax="100" [style.width]="row.progress + '%'">
              <span>{{row.progress}}% </span>
            </div>
          </div>
          <button *ngIf="row.assessorCompleted == 1 && row.assessorSignature == 0 && userId == row.assessorId" type="button" class="btn btn-warning btn-sm pointer-cursor px-3 py-2"
            (click)="directToSignaturePage(row)">Signature</button>
        </td>
        <td *ngIf="row.isKnowledgeTest == 1" class="text-center" colspan="2">
			  <span class="badge badge-custom-kt mt-1 mr-1">Knowledge Check Only</span>
        </td>
        <td class=" text-center">
          <a *ngIf="row.lockedBy && (userId == row.lockedBy || userRole == 'Admin')" class="btn btn-sm edit" (click)="unlockAssessment(row.id)">
						<i class="fa fa-lock" aria-hidden="true"></i>
					</a>
          <a *ngIf="(userId == row.assessorId || userRole == 'Admin') && !row.lockedBy && row.isKnowledgeTest == 0" style="cursor: pointer" class="btn btn-sm edit "
            (click)="performAssessmentClickEvent(row)">
                        <i class="fa fa-briefcase " aria-hidden="true"></i>
                    </a>
          <a *ngIf="(userId != row.assessorId && userRole != 'Admin') || row.lockedBy || row.isKnowledgeTest == 1" style="cursor: pointer" class="btn btn-sm edit disabled fa-disabled"
            (click)="performAssessmentClickEvent(row)">
                        <i class="fa fa-briefcase " aria-hidden="true"></i>
                    </a>
        </td>
      </tr>
    </tbody>
  </table>
  <pagination class="pagination-sm" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
    [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination><br>

</div>
<modal #unlockAssessmentModal>
  <modal-header [show-close]="false">
    <h4 class="modal-title">Unlock Assessment</h4>
  </modal-header>
  <modal-body>
    <p>{{constants.UNLOCK_ASSESSMENT}}</p>
  </modal-body>
  <modal-footer [show-default-buttons]="false">
    <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="unlock()">Yes</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="unlockAssessmentModal.dismiss()">No</button>
  </modal-footer>
</modal>
