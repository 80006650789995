<!-- <div class="mt-1 col-md-12"> &nbsp;</div>
<div class="chat-panel card card-default">
    <div class="card-header">
        <i class="fa fa-bar-chart-o fa-fw"></i> Assessment Summary
    </div>
    <div class="card-block">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="col-lg-15">
                    <p class="">Active assessments</p>
                    <div class="progress progress-xs">
                        <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                            aria-valuemax="100" style="width: 60%;">
                            <span class="sr-only">60% Complete</span>
                        </div>
                    </div>

                    <p class="">Completed assessments this year</p>
                    <div class="progress progress-xs">
                        <div class="progress-bar progress-bar-warning progress-bar-striped" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                            aria-valuemax="100" style="width: 40%">
                            <span class="sr-only">40% Complete (success)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="mt-1 col-md-12"> &nbsp;</div> -->

<div class="col-lg-15">
    <p class="">Active assessments</p>
    <div class="progress progress-xs">
        <div class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" aria-valuenow="60" aria-valuemin="0"
            aria-valuemax="100" style="width: 60%;">
            <span class="sr-only">60% Complete</span>
        </div>
    </div>

    <p class="">Completed assessments this year</p>
    <div class="progress progress-xs">
        <div class="progress-bar progress-bar-warning progress-bar-striped" role="progressbar" aria-valuenow="40" aria-valuemin="0"
            aria-valuemax="100" style="width: 40%">
            <span class="sr-only">40% Complete (success)</span>
        </div>
    </div>
</div>