<!--component html goes here -->
<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Edit Task</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>

<div class="col-lg-12 mx-auto">
	<form [formGroup]="editTaskForm" (ngSubmit)="onSubmit()">
		<!--task-->
		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Task
				<sup>
					<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
				</sup>
			</label>
			<div class="col-sm-10">
				<textarea noSpaces maxlength="400" autosize type="text" class="form-control" id="task" formControlName="task" name="task"></textarea>
			</div>
		</div>
		<!--task status-->
		<!-- <div class="form-group row">
            <label class="col-sm-2 col-form-label">Task Status</label>
            <div class="col-sm-5">
                <select [(ngModel)]='taskStatus' class="form-control" id="taskStatus" formControlName="taskStatus" name="taskStatus">
                   <option [value]='1' [selected]='taskStatus == 1'>Active</option>
                   <option [value]='0' [selected]='taskStatus == 0'>Inactive</option>
                </select>
            </div>
        </div> -->

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">Tags</label>
			<div class="col-sm-10">
				<tag-input [ngModel]="items" [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" formControlName="taskTag"
				 [displayBy]="'value'" [identifyBy]="'id'" (onAdd)="addTags($event)" (onRemove)="removeTags($event)" name="taskTag">

					<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="activeTagList">
					</tag-input-dropdown>
				</tag-input>
			</div>
		</div>

		<alert></alert>
		<!--buttons-->
		<div class="form-group row">
			<div class="col-sm-6">
			</div>
			<div class="col-sm-3">
				<button type="submit" class="btn btn-warning btn-block" [disabled]="!editTaskForm.valid">Save</button>
			</div>
			<div class="col-sm-3">
				<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('AllTask')">Cancel</button>
			</div>
		</div>
	</form>
</div>