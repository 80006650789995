<div>
  <alert></alert>
  <div *ngIf=isLoading class="loading"></div>

  <div class="ml-2 mr-2">

    <div class="row m-0">
      <div class="col-12">
        <h4> {{title}}</h4>
        <div>
          Knowledge Check Completed Date - {{assessmentModel.selfAssessmentCompletedDate.toLocaleDateString()}}
        </div>
        <div>
          Knowledge Check Score - {{ correct }} out of {{total}} is correct

        </div>
        <hr />
      </div>
    </div>
    <div class="row m-0 ">
      <div class="col-12 p-0">
        <div class="row m-0 ">
          <div class="col-sm-6 mb-4 mt-2">
            <button type="button" [ngClass]="{'btn-warning': allQuestions, 'btn-outline-warning': !allQuestions }" class="btn btn-outline-warning btn-sm mb-1"
              (click)="showAll()">All</button>
            <button type="button" [ngClass]="{'btn-warning': missedQuestions, 'btn-outline-warning': !missedQuestions }" class="btn btn-outline-warning btn-sm ml-1 mb-1"
              (click)="showMissedQuestions()">Missed Questions</button>
            <button type="button" [ngClass]="{'btn-warning': correctQuestions, 'btn-outline-warning': !correctQuestions }" class="btn btn-outline-warning btn-sm ml-1 mb-1 mr-2"
              (click)="showCorrectQuestions()">Correct Questions</button>
            <!--<input [(ngModel)]="accepted" (change)="expandAllKQs($event)" type="checkbox"><span class="kq-font"> Knowledge Questions Only</span>     -->
            <label class="btn  btn-outline-warning btn-sm  mt-1"> Knowledge Questions Only
				<input class="float-left m-1" type="checkbox" [(ngModel)]="accepted" (change)="expandAllKQs($event)">
			</label>
          </div>
          <div class="col-sm-6 mb-4 mt-2 text-right">
            <button type="button" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1 " [disabled]="noResults" (click)="print()">Print</button>
            <button type="button" class="btn float-right btn-outline-warning btn-sm ml-1 mb-1" [disabled]="noResults" (click)="exportToExcel()">Export to Excel</button>
            <div class="btn-group  float-right ml-1">
              <button *ngIf="!expandAllTasks" type="button" class="btn  btn-sm btn-outline-warning float-right" (click)="expandAllTasksInAssessment()"><i class="mr-1 ml-1 fa fa-angle-down"></i>&nbsp;Expand All</button>
              <button *ngIf="expandAllTasks" type="button" class="btn btn-sm btn-outline-warning float-right" (click)="expandAllTasksInAssessment()"><i class="mr-1 ml-1 fa fa-angle-up"></i>&nbsp;Collapse All</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 ">
      <div class="col-sm-12 mb-4">
        <div class="row">
          <div class="col-sm-3">
            <div class="btn-group btn-group-toggle float-left w-100">
              <button type="button" [ngClass]="{'active': allClicked, '' : !allClicked}" class="btn btn-sm btn-outline-warning" (click)="showAllRegulatoryAgency()">All</button>
              <button type="button" [ngClass]="{'active': !allClicked, '':allClicked}" class="btn btn-sm btn-outline-warning" (click)="showRegulatoryAgency()">Regulatory Agency</button>

            </div>

          </div>
          <div class="col-sm-9">


            <div class="btn-group ml-1 float-left filter-wrapper-dd">
              <ng-select *ngIf="!allClicked" [multiple]="true" [allowClear]="true" [items]="regAgencyList" placeholder="{{placeholderText}}"
                name="regAgency" id="regAgency" class="filter-ngselect" (data)="filterTasks($event)">
              </ng-select>
            </div>
            <!--<angular2-multiselect *ngIf="!allClicked" [data]="regAgencyList1" [(ngModel)]="selectedRegItems" [settings]="dropdownSettings"
          (onSelect)="filterTasks($event)" (onDeSelect)="filterTasks($event)" (onSelectAll)="filterTasks($event)" (onDeSelectAll)="removeAllRegulatoryAgency($event)"></angular2-multiselect>-->

          </div>

        </div>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="noResults">
      <div class=" alert alert-info">No matching items.</div>
    </div>
    <div class="col-sm-12" *ngIf="!expandAll">
      <div class="col-sm-12 mt-1 mb-1 pl-0 pr-0" *ngFor="let ccItem of ccList | orderBy : 'orderNum'; let k = index">
        <div class="card p-2 card-default" (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
          <div class="card-body pointer-cursor">
            <div class="float-left mt-1 mb-1">
              {{ ccItem.text }}
            </div>
            <div class="float-right">
              <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id || expandAllTasks, 'fa-angle-down': showCCDetail != ccItem.id && !expandAllTasks }"></i>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showCCDetail == ccItem.id || expandAllTasks">
          <div class="col-sm-12 mt-1 mb-1" *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let t = index ">
            <!-- <div class="col-sm-12 mx-auto mt-1"> -->
            <div class="card p-2" (click)="showCollapsePanel(task.id , 'task')">
              <div class="card-body pointer-cursor">
                <div class="float-left mt-1 mb-1">
                  <div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"></div>
                  {{ task.text }}
                  <div class="col-sm-12" *ngIf="task.regulatoryList.length > 0 && (showTaskDetail == task.id || expandAllTasks)">
                    <span class="badge badge-custom mt-1 mr-1" *ngFor="let regulatory of task.regulatoryList | orderBy : 'alphabetic'">
                      {{ regulatory.text }}</span>
                  </div>
                </div>
                <div class="float-right">
                  <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                    <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskDetail == task.id || expandAllTasks, 'fa-angle-down': showTaskDetail != task.id && !expandAllTasks}"></i>
                  </div>
                  <div class="float-right mr-1 ml-1" *ngIf="task.regulatoryList.length > 0">
                    <label>
                    <span class="badge badge-custom">
                      <i class="fa fa-university" aria-hidden="true"></i>
                      Regulatory Agency
                    </span>
                  </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-1 mb-1" *ngIf="(showTaskDetail==task.id || expandAllTasks) && task.knowledgeList.length > 0">
              <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
                <div class="mt-2" *ngIf="task.knowledgeList.length>0">
                  <h5 class="mt-1 mb-1">Knowledge</h5>
                  <div class="col-sm-12 mx-auto mt-1" *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
                    <div class="card p-2" [ngClass]="{'bs-callout-default': knowledge.answer == 1, 'bs-callout-danger card-incorrect': knowledge.answer == 2, 'bs-callout-success card-correct':  knowledge.answer == 3 }"
                      (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
                      <div class="card-body pointer-cursor">
                        <div class="mt-1 mb-1">
                          <div class="float-right">
                            <div class="float-right btn btn-sm border-0 mr-1 ml-1" *ngIf="knowledge.remediationList.length > 0">
                              <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
                            </div>
                          </div>
                          <div class="col-sm-12 mt-1 mb-1 pr-5">
                            <span class="badge badge-dark-number mr-2 mt-1 float-left">{{k + 1}}</span>
                            <p class="mb-0">{{ knowledge.knowledge}}</p>
                            <img *ngIf="knowledge.isImageAttached === 1" [src]="getImageURL(knowledge.id, knowledge.id, knowledge.fileType)" class="img-fluid mb-2 mt-2 img-ans-custom">
                            <div class="col-sm-12 ml-6" *ngIf="knowledge.type == 'Boolean'">
                              <div class="row pl-2 ml-2 mb-1">
                                <span class="p-2 badge " [ngClass]="{'badge-success-cus': knowledge.booleanAnswerCorrect == 1, 'badge-danger-cus': ( knowledge.booleanAnswerCorrect == 0 && knowledge.answer === 2 ) }">
																								<input disabled class="float-left" type="radio" [name]="knowledge.id" value="1" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)">
																							</span>
                                <p class="m-0 mt-1 ml-2 float-right">True</p>
                              </div>
                              <div class="row pl-2 ml-2 mt-1">
                                <span class="p-2 badge " [ngClass]="{'badge-danger-cus': (knowledge.booleanAnswerCorrect == 1  && knowledge.answer === 2), 'badge-success-cus': knowledge.booleanAnswerCorrect == 0}">
																								<input disabled class="float-left" type="radio" [name]="knowledge.id" value="0" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)">
																							</span>
                                <p class="m-0 mt-1 ml-2 float-right">False</p>
                              </div>
                            </div>
                            <div class="col-sm-12 ml-6" *ngFor="let knowledgeAnswer of knowledge.answerList ; let n = index ">
                              <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Single'">
                                <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 0), 'badge-success-cus': knowledgeAnswer.IsCorrect == 1 , 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																	<input class="float-left" disabled type="radio" [checked]="knowledgeAnswer.GivenAnswer === 1" [name]="knowledge.id" [value]="knowledgeAnswer.id"
																	 (click)="setAnswer(knowledge, knowledgeAnswer)">
																</span>
                                <p *ngIf="knowledgeAnswer.text" class="m-0 mt-1 ml-2 float-right">{{knowledgeAnswer.text}}</p>
                                <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                              </div>
                              <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Multiple'">
                                <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 0), 'badge-success-cus': knowledgeAnswer.IsCorrect == 1, 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																	<input class="float-left" disabled type="checkbox" [name]="knowledge.Id" [value]="knowledgeAnswer.id" [(ngModel)]="knowledgeAnswer.GivenAnswer">
																</span>
                                <p *ngIf="knowledgeAnswer.text" class="m-0 mt-1 ml-2 float-right">{{knowledgeAnswer.text}}</p>
                                <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-custom ml-1">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- knowledge remediation -->
                    <div *ngIf="showKnowledgeDetail == knowledge.id && knowledge.remediationList.length > 0">
                      <div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
                        <div class="mt-2" *ngIf="knowledge.remediationList.length>0">
                          <h5 class="mt-1 mb-1">Remediation</h5>
                          <div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
                            <div class="col-sm-12 mx-auto mt-1">
                              <div class="card p-2" (click)="showCollapsePanel(kremediation.AssessmentKnowledgeRemId , 'knowledgeRemediationDetail', kremediation.Id)">
                                <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail == kremediation.AssessmentKnowledgeRemId">
                                  <div class="col-sm-12 pl-1 pr-1">
                                    <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                      <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
                                      <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{ kremediation.Description
                                      }}
                                    </div>
                                    <div class="float-right">
                                      <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                        <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 float-left mt-3 mb-1 pl-0" *ngIf="kremediation.CourseDescription">
                                    <b>Course Description : </b> {{ kremediation.CourseDescription}}
                                  </div>
                                  <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf="kremediation.CourseNumber">
                                    <b>Course Number : </b> {{ kremediation.CourseNumber }}
                                  </div>
                                  <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf="kremediation.Link">
                                    <b>Link : </b>
                                    <a *ngIf="kremediation.Link.indexOf('http') < 0" [href]="'http://'+kremediation.Link" target="_blank">{{kremediation.Link}}</a>
                                    <a *ngIf="kremediation.Link.indexOf('http') >= 0" href={{kremediation.Link}} target="_blank">{{kremediation.Link}}</a>
                                  </div>
                                  <div *ngIf="kremediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                                    <label class="mb-0">
																			<b>Note : </b>
																		</label>
                                    <div class="col-sm-12 mt-2 ml-2">
                                      <form>
                                        <div class="form-group row">
                                          <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="kremediation.Note"
                                            (click)="$event.stopPropagation()" readonly></textarea>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                  <div *ngIf="selectedKnowledgeRemediationAttachment.length > 0" class="col-sm-12 float-left mt-3 mb-1 pl-0">Documents
                                    <span class="badge badge-dark mr-1">{{selectedKnowledgeRemediationAttachment != null?selectedKnowledgeRemediationAttachment.length:''}}</span>
                                  </div>
                                  <br *ngIf="selectedKnowledgeRemediationAttachment.length > 0">
                                  <div *ngFor="let item of selectedKnowledgeRemediationAttachment; let k = index ">
                                    <ng-template [ngIf]="item.text == 'pdf' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'txt' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'docx' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'doc' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'ppt' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'pptx' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'xls' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                    <ng-template [ngIf]="item.text == 'xlsx' ">
                                      <label for="default" class="">
																				<a class="btn btn-sm edit">
																					<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'knowledgeRemediationDetail');"
																					 aria-hidden="true"></i>
																				</a>
																				{{ item.fileName }}
																			</label>
                                    </ng-template>
                                  </div>
                                </div>
                                <div class="card-body pointer-cursor" *ngIf="showKnowledgeRemediationDetail != kremediation.AssessmentKnowledgeRemId">
                                  <div class="float-left">
                                    <span class="badge badge-info mr-1">{{ kremediation.DeliveryMedium }}</span>
                                    <span class="badge badge-warning mr-1">{{ kremediation.Type }}</span> {{ kremediation.Description
                                    }}
                                  </div>
                                  <div class="float-right">
                                    <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                      <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" *ngIf="task.remediationList.length>0">
                  <div class="mt-2" *ngIf="task.remediationList.length>0">
                    <h5 class="mt-1 mb-1">Remediation</h5>
                    <div *ngFor="let remediation of task.remediationList; let tr = index ">
                      <div class="col-sm-12 mx-auto mt-1">
                        <div class="card p-2" (click)="showCollapsePanel(remediation.AssessmentTaskRemId , 'taskRemediationDetail', remediation.Id)">
                          <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail == remediation.AssessmentTaskRemId">
                            <div class="col-sm-12 pl-1 pr-1">
                              <div class="col-sm-11 float-left mr-0 pr-0 pl-0">
                                <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                                <span class="badge badge-warning mr-1">{{ remediation.Type }} </span> {{ remediation.Description
                                }}
                              </div>
                              <div class="float-right">
                                <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                  <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': true }"></i>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 float-left mt-3 mb-1 pl-0" *ngIf="remediation.CourseDescription">
                              <b>Course Description : </b> {{ remediation.CourseDescription }}
                            </div>
                            <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf=" remediation.CourseNumber">
                              <b>Course Number : </b> {{ remediation.CourseNumber }}
                            </div>
                            <div class="col-sm-12 float-left mt-1 mb-1 pl-0" *ngIf=" remediation.Link">
                              <b>Link : </b>
                              <a *ngIf="remediation.Link.indexOf('http') < 0" [href]="'http://'+remediation.Link" target="_blank">{{remediation.Link}}</a>
                              <a *ngIf="remediation.Link.indexOf('http') >= 0" href={{remediation.Link}} target="_blank">{{remediation.Link}}</a>
                            </div>
                            <div *ngIf="remediation.Note != ''" class="col-sm-12 float-left mt-1 mb-1 pl-0">
                              <label class="mb-0">
																<b>Note : </b>
															</label>
                              <div class="col-sm-12 mt-2 ml-2">
                                <form>
                                  <div class="form-group row">
                                    <textarea rows="3" type="text" class="form-control" id="remediationNote" name="remediationNote" [(ngModel)]="remediation.Note"
                                      (click)="$event.stopPropagation()" readonly></textarea>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="col-sm-12 float-left mt-3 mb-1 pl-0" *ngIf="selectedTaskRemediationAttachment.length > 0">Documents
                              <span class="badge badge-dark mr-1">{{selectedTaskRemediationAttachment != null?selectedTaskRemediationAttachment.length:''}}</span>
                            </div>
                            <br *ngIf="selectedTaskRemediationAttachment.length > 0">
                            <div *ngFor="let item of selectedTaskRemediationAttachment; let k = index ">
                              <ng-template [ngIf]="item.text == 'pdf' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-pdf-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'txt' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-text-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'docx' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'doc' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-word-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'ppt' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'pptx' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-powerpoint-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'xls' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileNamet, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                              <ng-template [ngIf]="item.text == 'xlsx' ">
                                <label for="default" class="">
																	<a class="btn btn-sm edit">
																		<i class="fa fa-file-excel-o fa-lg" (click)="$event.stopPropagation();fileIconButtonClickEvent(item.fileName, item.id, 'taskRemediationDetail');"
																		 aria-hidden="true"></i>
																	</a>
																	{{ item.fileName }}
																</label>
                              </ng-template>
                            </div>
                          </div>
                          <div class="card-body pointer-cursor" *ngIf="showTaskRemediationDetail != remediation.AssessmentTaskRemId">
                            <div class="float-left">
                              <span class="badge badge-info mr-1">{{ remediation.DeliveryMedium }}</span>
                              <span class="badge badge-warning mr-1">{{ remediation.Type }}</span> {{ remediation.Description
                              }}
                            </div>
                            <div class="float-right">
                              <div class="float-right btn btn-sm border-0 mr-1 ml-1">
                                <i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-down': true }"></i>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="allCorrect && missedQuestions" class="ml-2 col-sm-12 alert alert-info">No missed questions</div>
  <div *ngIf="allIncorrect && correctQuestions" class="ml-2 col-sm-12 alert alert-info">No Correct questions</div>
  <div *ngIf="expandAll">
    <div *ngFor="let counter of [ccList | counterPipe]">
      {{ counter.reset() }}
      <div *ngFor="let ccItem of ccList | orderBy : 'orderNum'; let i = index;">
        <div *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let j = index;">
          <div class="col-sm-12" *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
            <div class="card mb-3" [ngClass]="{'bs-callout-default': knowledge.answer == 1, 'bs-callout-danger card-incorrect': knowledge.answer == 2, 'bs-callout-success card-correct':  knowledge.answer == 3 }"
              (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
              <div class="card-body pointer-cursor">
                <div class="mt-1 mb-1">
                  <div class="col-sm-12 mt-1 mb-1 pr-5">
                    <span class="badge badge-dark-number mr-2 mt-1 float-left">{{counter.inc() + 1}}</span>
                    <p class="mb-0">{{ knowledge.knowledge}}</p>
                    <img *ngIf="knowledge.isImageAttached === 1" [src]="getImageURL(knowledge.id, knowledge.id, knowledge.fileType)" class="img-fluid mb-2 mt-2 img-ans-custom">
                    <div class="col-sm-12 ml-6" *ngIf="knowledge.type == 'Boolean'">
                      <div class="row pl-2 ml-2 mb-1">
                        <span class="p-2 badge " [ngClass]="{'badge-success-cus': knowledge.booleanAnswerCorrect == 1, 'badge-danger-cus': ( knowledge.booleanAnswerCorrect == 0 && knowledge.answer === 2 ) }">
																								<input disabled class="float-left" type="radio" [name]="knowledge.id" value="1" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)">
																							</span>
                        <p class="m-0 mt-1 ml-2 float-right">True</p>
                      </div>
                      <div class="row pl-2 ml-2 mt-1">
                        <span class="p-2 badge " [ngClass]="{'badge-danger-cus': (knowledge.booleanAnswerCorrect == 1  && knowledge.answer === 2), 'badge-success-cus': knowledge.booleanAnswerCorrect == 0}">
																								<input disabled class="float-left" type="radio" [name]="knowledge.id" value="0" [checked]="(knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)">
																							</span>
                        <p class="m-0 mt-1 ml-2 float-right">False</p>
                      </div>
                    </div>
                    <div class="col-sm-12 ml-6" *ngFor="let knowledgeAnswer of knowledge.answerList ; let n = index ">
                      <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Single'">
                        <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 0), 'badge-success-cus': knowledgeAnswer.IsCorrect == 1 , 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																	<input class="float-left" disabled type="radio" [checked]="knowledgeAnswer.GivenAnswer === 1" [name]="knowledge.id" [value]="knowledgeAnswer.id">
																</span>
                        <p *ngIf="knowledgeAnswer.text" class="m-0 mt-1 ml-2 float-right">{{knowledgeAnswer.text}}</p>
                        <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                      </div>
                      <div class="row pl-2 ml-2 mt-1" *ngIf="knowledge.type == 'Multiple'">
                        <span class="p-2 badge icon-height" [ngClass]="{'badge-normal-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 0), 'badge-success-cus': knowledgeAnswer.IsCorrect == 1, 'badge-danger-cus': (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1)}">
																	<input class="float-left" disabled type="checkbox" [name]="knowledge.Id" [value]="knowledgeAnswer.id" [(ngModel)]="knowledgeAnswer.GivenAnswer">
																</span>
                        <p *ngIf="knowledgeAnswer.text" class="m-0 mt-1 ml-2 float-right">{{knowledgeAnswer.text}}</p>
                        <img *ngIf="knowledgeAnswer.FileType" [src]="getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)" class="img-fluid img-ans-custom ml-1">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
