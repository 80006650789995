export class Knowledge {
	constructor(
		public knowledge: string,
		public isTestQuestion: number,
		public id?: string,
		public orgCode?: string,
		public type?: string,
		public correctAnswer?: number,
		public assessmentNote?: string,

		public remediationList?: any,
		public jpccId?: any,
		public jptId?: string,
		public jpkId?: string,

		public orderNum?: number,

		public tagList?: any[],

		public answer?: number,
		/** id of the knowledge in assessment table.
		use to update assessment answer */
		public assId?: string,

		public answerList?: any[],
		public booleanAnswerCorrect?: number,
		//id of assessment table
		public assessmentId?: string,
		public reasonNA?: string,
		public reasonNAId?: string,
		public isVisualQuestion?: number,
		public isImageAttached?: number,
		public fileType?: string,
	) { }
}
