import { Component, OnInit, Directive, Input } from '@angular/core';
import { AfterViewInit, AfterContentInit } from '@angular/core';
import { ViewChild, Injector, Inject, ComponentFactoryResolver } from '@angular/core';

import { UserService } from '../../user/user.service';
import { ItemService } from '../item.service';
import { SharedService } from '../shared.service';
import { AssessmentService } from '../../assessment/assessment.service';
import { OverlayService } from '../../util/overlay/overlay.service'

import { StaticDashboardComponent } from "../../dashboard/staticdashboard.component";
import { OverlayToolTipComponent } from '../../util/overlay/overlaytooltip.component'

import { Constants } from '../../util/const/constants'

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, AfterContentInit {

	public stortedUserData: any = localStorage.getItem('selectedUserRole');
	public selectedItem: any = this.stortedUserData != null ? StaticDashboardComponent : null;
	public selectedUserRole = [];
	selectedMenuItem: number = 1;
	isReportCollapsed: boolean = false;
	public constants: any
	public showOverlay: boolean = false

	constructor(private itemService: ItemService, private sharedService: SharedService,
		private userService: UserService, private assessmentService: AssessmentService) {
		this.constants = Constants
	}

	isActive = true;
	showMenu = '';
	eventCalled() {
		this.isActive = !this.isActive;
	}
	addExpandClass(element: any) {
		if (element === this.showMenu) {
			this.showMenu = '0';
		} else {
			this.showMenu = element;
		}
	}

	ngOnInit() {
		this.sharedService.getUserRole().subscribe(item => this.changeUserRole())
		if (this.stortedUserData) {
			this.selectedUserRole = JSON.parse(this.stortedUserData);
		}
		// console.log('[SidebarComponent] (selectedUserRole) ' + this.selectedUserRole[0][0] + ':' + this.selectedUserRole[0][1]);
		// this.sharedService.getEmittedValue().subscribe(item => this.selectedComponentItem(item, 0, false));
		this.sharedService.getToggleOverlayValue().subscribe(item => this.showOverlay = !this.showOverlay);
	}

	ngAfterContentInit() {
		this.sharedService.getEmittedValue().subscribe(item => this.selectedComponentItem(item, 0, false));
	}

	ngAfterViewInit() {
		// this.sharedService.getEmittedValue().subscribe(item => this.selectedComponentItem(item, 0, false));
	}

	selectedComponentItem(itemName: string, itemNumber: number, fromNav: boolean) {
		if (fromNav) {
			this.selectedMenuItem = itemNumber;
		} else {
			//not from navigation no need to change navigation item classes
		}
		if (itemNumber == 0) {
			if (itemName == 'RemediationReport') {
				this.selectedMenuItem = 72;
			}
			else if (itemName == 'Assessments') {
				this.selectedMenuItem = 63;
			}
			else if (itemName == 'Calendar') {
				this.selectedMenuItem = 62;
			}
		}

		if (itemName != 'Calendar') {
			this.assessmentService.setScheduleMode(null);
			this.assessmentService.setSelectedUsers([]);
		}
		localStorage.setItem('selectedMenuItem', itemName);
		this.selectedItem = this.itemService.loadItem(itemName);
		localStorage.setItem('selectedMenuItemNo', itemNumber.toString());
	}

	reloadMyRemediationReport() {
		if (localStorage.getItem('selectedMenuItem') == 'RemediationReport' && localStorage.getItem('selectedMenuItemNo') != '72') { //reload when already loaded Remediation Report in dashboard

		}
		else {
			this.selectedComponentItem('RemediationReport', 72, true);
		}

	}

	isMenuItemValid(type: string): boolean {
		let result: boolean = false;
		let param: string[] = [];
		
		if (localStorage.getItem(type) != null) {
			if (localStorage.getItem(type) == 'true') {
				result = true;
			} else {
				result = false;
			}
		} else {
			result = false;
		}		
		return result;
	}

	private changeUserRole() {
		this.selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'))
		this.selectedMenuItem = 1
	}

}
