<div style="display: block" *ngIf="showPieChart">
  <div class="row mt-1 mb-1">
    <ul class="legend list-inline mb-0" style="margin: 0px auto;">
      <li class="list-inline-item float-left"><span class="overdue"></span><small>Overdue Assessments</small> </li>
      <li class="list-inline-item float-left"><span class="completed"></span><small>Completed Assessments</small></li>
      <li class="list-inline-item float-left"><span class="never"></span><small>Never Assessed</small></li>
      <!-- <li><span class="notawesome"></span> Not Awesome</li> -->
    </ul>
  </div>
  <div style="display: block">
    <canvas *ngIf="!show" baseChart [data]="pieChartData" [labels]="pieChartLabels" [colors]="pieChartColors" [chartType]="pieChartType"
      [options]="pieChartOptions" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
  </div>
</div>
<div *ngIf="show">
  <div class="row" style="overflow-x: auto;">
    <alert></alert>
    <div class="container mb-2 pl-0">
      <button type="button" class="btn btn-outline-warning btn-sm pointer-cursor mr-1 ml-1 float-left" (click)="showChart()">
				<i class="fa fa-arrow-left fa-fw"></i>
			</button>
      <h5 class="ml-2 float-left mt-1"> {{tableName}} </h5>
    </div>

    <div class="container mb-1 pl-0 pr-0">
      <table class="table-sm table-bordered  table-striped mt-1 col-lg-12 mb-2">
        <thead>
          <tr>
            <th class="text-center ">First Name</th>
            <th class="text-center ">Last Name</th>
            <th class="text-center ">Org Code</th>
            <th class="text-center ">Job Profile</th>
            <th *ngIf="showDate" class="text-center ">Completed Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of paginatedResults; let i = index">
            <td>{{row.firstName}}</td>
            <td>{{row.lastName}}</td>
            <td>{{row.orgCode}}</td>
            <td>{{row.jobProfile}}</td>
            <td *ngIf="showDate">{{row.completedDate}}</td>
          </tr>
        </tbody>
      </table>
      <pagination class="pagination-sm mt-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
        [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
      <br>
    </div>



    <!--<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
		 class="table-sm">
		</ng-table>
		<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
		 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
		</pagination>-->
  </div>
  <!-- <div class="row">
		<div class="col" style="overflow:hidden">
			<div class="float-left">
				<button type="button" class="btn btn-outline-warning btn-sm pointer-cursor mr-1 ml-1" (click)="showChart()">
					<i class="fa fa-window-restore fa-fw"></i>&nbsp;Back
				</button>
			</div>
		</div>
	</div> -->
</div>
