import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Http } from '@angular/http';

import { UserService } from './user.service';
import { WidgetService } from '../dashboard/widget.service';

import { Widget } from '../dashboard/widget.model'
import { user } from './user';
import { AlertService } from '../util/alert/alert.service';
import { Constants } from '../util/const/constants'
declare var Fingerprint2: any;

@Component({
	selector: 'app-keycode',
	templateUrl: './keycode.component.html',
	providers: [UserService]
})
export class KeyCodeComponent implements OnInit, AfterViewInit {
	userId: string;
	myForm: FormGroup;
	fp: any;
	public assessmentId: string = '';
	public type: string = '';
	isLoading: boolean = false;

	constructor(private userService: UserService, private router: Router,
		private activatedRoute: ActivatedRoute,
		private widgetService: WidgetService, private alertService: AlertService) {
		if (userService.isValidLoggedIn()) {
			this.router.navigateByUrl('/home');
		}
	}

	ngOnInit() {
		this.myForm = new FormGroup({
			keycode: new FormControl(null, Validators.required)
		});
	}

	ngAfterViewInit() {
		var options = { excludeCanvas: true }
		this.fp = new Fingerprint2(options);
		this.fp.get(function (result) {
			(<HTMLInputElement>document.getElementById('deviceId')).value = result;
		});
	}

	onSubmit() {
		this.isLoading = true;
		var userId = this.activatedRoute.snapshot.queryParams["userId"];
		this.assessmentId = this.activatedRoute.snapshot.queryParams["assessmentId"];
		this.type = this.activatedRoute.snapshot.queryParams["type"];
		const deviceId = (<HTMLInputElement>document.getElementById('deviceId')).value;
		this.userService.validateKeyCode(userId, this.myForm.value.keycode, deviceId)
			.subscribe(
			data => {
				this.isLoading = false;
				localStorage.setItem('token', data.token);
				localStorage.setItem('userId', data.userId);
				localStorage.setItem('userName', data.userName);
				localStorage.setItem('userFirstName', data.firstName)
				localStorage.setItem('expiredIn', data.expiredIn);
				//local storage can not store arrays need to sringify it
				if (this.assessmentId != "null" && this.assessmentId != "undefined" && this.type != "null" && this.type != "undefined" && this.type) {
					if(this.type){
						console.log('type is not empty')
					} else {
						console.log('type is empty')
					}
					if(this.type != "undefined"){
						console.log('type is not defined')
					} else {
						console.log('type is undefined')
					}
					this.router.navigateByUrl('/assessmentsignature?type=' + this.type + '&assessmentId=' + this.assessmentId);
					if (data.userRoles.length > 1) {
						localStorage.setItem('userRoles', JSON.stringify(data.userRoles));
					} else {
						localStorage.setItem('selectedUserRole', JSON.stringify(data.userRoles));
					}
				} else if (this.assessmentId != "null" && this.assessmentId != "undefined" && this.assessmentId) {
					this.router.navigateByUrl('/selfassessment?assessmentId=' + this.assessmentId);
					if (data.userRoles.length > 1) {
						localStorage.setItem('userRoles', JSON.stringify(data.userRoles));
					} else {
						localStorage.setItem('selectedUserRole', JSON.stringify(data.userRoles));
					}
				} else if (data.userRoles.length > 1) {
					localStorage.setItem('userRoles', JSON.stringify(data.userRoles));
					this.router.navigateByUrl('/userrole');
				} else {
					localStorage.setItem('selectedUserRole', JSON.stringify(data.userRoles));
					let roleId = data.userRoles[0][0]
					if (localStorage.getItem('capability') == null) {
						this.userService.getAssignedCapabalityList(data.userRoles[0][1])
							.subscribe(
							data => {
								// get dashboard widgets
								this.widgetService.getDashboardWidgets(roleId)
							},
							error => {
							}
							);
					}
				}
			},
			error => {
				this.isLoading = false;
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_INVALID_KEYCODE)
				// this.router.navigateByUrl('/user/login');
			}
			);
		this.myForm.reset();
	}

}
