import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { user } from './user';
import { ReportService } from '../report/report.service'
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class UserService {

	constructor(private http: Http, private router: Router, private reportService: ReportService) { }

	//selected user for edit - need pass this selected user to the edit component
	private selectedUserForEdit: user;
	public getSelectedUserForEdit(): user {
		return this.selectedUserForEdit;
	}
	public setSelectedUserForEdit(user: user): void {
		this.selectedUserForEdit = user;
	}

	// Get all posts from the API
	getAllPosts() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.get(environment.server_URL + '/user/getUser' + token, { headers: headers })
			.map(res => res.json());
	}

	getUserData() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': localStorage.getItem('userId') });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getUser' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get all users for
	getAllUsers() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getAllUsers' + token, { headers: headers })
			.map(res => res.json());
	}

	//get users where IsAssessed = 1 and Status = 1
	getActiveAndAssessedUsers(supervisorId) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		return this.http.post(environment.server_URL + '/user/getActiveAndAssessedUsers' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get users with a job profile and status = 1
	getActiveUsersWithJobProfile(supervisorId) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		return this.http.post(environment.server_URL + '/user/getActiveUsersWithJobProfile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getJobProfileOfTheUser() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': localStorage.getItem('userId') });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getJobProfileOfTheUser' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete user
	deleteUser(userId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': userId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/deleteUser' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateUserDetails(user: user) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/updateUserDetails' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateUserByUserId(user: user) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/updateUserByUserId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	validateKeyCode(userId: string, keycode: string, deviceId: string) {
		const body = JSON.stringify({ 'userId': userId, 'keycode': keycode, 'deviceId': deviceId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/validateKeyCode', body, { headers: headers })
			.map(res => res.json());
	}

	validateUser(user: user) {
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/login', body, { headers: headers })
			.map(res => res.json());
	}

	//add new user
	addNewUser(user: user) {
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/addNewUser', body, { headers: headers })
			.map(res => res.json());
	}

	validateEmail(user: user) {
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/validateEmail', body, { headers: headers })
			.map(res => res.json());
	}

	resetPassword(user: user) {
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/resetPassword', body, { headers: headers })
			.map(res => res.json());
	}

	isValidLoggedIn() {
		let expiredIn = new Date(localStorage.getItem('expiredIn'));
		return localStorage.getItem('token') !== null && (new Date() < expiredIn) && localStorage.getItem('selectedUserRole') !== null;
	}

	alreadyLoggedIn() {
		let expiredIn = new Date(localStorage.getItem('expiredIn'));
		return localStorage.getItem('token') !== null && (new Date() < expiredIn);
	}

	validateForgotpasswordResetLink(user: user) {
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/validateForgotpasswordResetLink', body, { headers: headers })
			.map(res => res.json());
	}

	resetCurrentPassword(user: user) {
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/resetCurrentPassword', body, { headers: headers })
			.map(res => res.json());
	}

	validateCurrentPassword(user: user) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(user);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/validateCurrentPassword' + token, body, { headers: headers })
			.map(res => res.json());
	}

	redirectToLoginScreen() {
		// localStorage.clear();
		this.router.navigateByUrl('/user/login');
	}

	getAssignedCapabalityList(userRole: string): Observable<string> {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		//const body = JSON.stringify({'role': localStorage.getItem('selectedUserRole')});
		const body = JSON.stringify({ 'role': userRole });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getCapabilityList' + token, body, { headers: headers })
			.map(
			data => {
				let assignedCapabilities = JSON.stringify(data.json().capability);
				let arr: any[] = data.json().capability;
				if (arr.filter(function (v) {
					if (v["Name"] == "AddUser" || v["Name"] == "ModifyUser" || v["Name"] == "DeleteUser" || v["Name"] == "QueryUser") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableUser', 'true');
				} else {
					localStorage.setItem('EnableUser', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "ModifyRole") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableRole', 'true');
				} else {
					localStorage.setItem('EnableRole', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "AddDiscipline" || v["Name"] == "ModifyDiscipline" || v["Name"] == "DeleteDiscipline" || v["Name"] == "QueryDiscipline") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableDiscipline', 'true');
				} else {
					localStorage.setItem('EnableDiscipline', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "AddOrgCode" || v["Name"] == "AddOrgCode" || v["Name"] == "AddOrgCode" || v["Name"] == "AddOrgCode") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableOrgCode', 'true');
				} else {
					localStorage.setItem('EnableOrgCode', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "AddJobProfile" || v["Name"] == "ModifyJobProfile" || v["Name"] == "DeleteJobProfile" || v["Name"] == "QueryJobProfile") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableJobProfile', 'true');
				} else {
					localStorage.setItem('EnableJobProfile', 'false');
				}
				if (arr.filter(function (v) {
					if (v['Name'] == 'CompairJobProfile') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('CompairJobProfile', 'true');
				} else {
					localStorage.setItem('CompairJobProfile', 'false');
				}
				if (arr.filter(function (v) {
					if (v['Name'] == 'JobProgressionPlanning') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('JobProgressionPlanning', 'true');
				} else {
					localStorage.setItem('JobProgressionPlanning', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'SuccessionPlanning') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('SuccessionPlanning', 'true');
				} else {
					localStorage.setItem('SuccessionPlanning', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'CandidateCompetency') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('CandidateCompetency', 'true');
				} else {
					localStorage.setItem('CandidateCompetency', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'ChangeTheme') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('ChangeTheme', 'true');
				} else {
					localStorage.setItem('ChangeTheme', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'CompetencyAnalysis') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('CompetencyAnalysis', 'true');
				} else {
					localStorage.setItem('CompetencyAnalysis', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'RegulatoryCompliance') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('RegulatoryCompliance', 'true');
				} else {
					localStorage.setItem('RegulatoryCompliance', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'RemediationMatrix') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('RemediationMatrix', 'true');
				} else {
					localStorage.setItem('RemediationMatrix', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'MissedTaskMatrix') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('MissedTaskMatrix', 'true');
				} else {
					localStorage.setItem('MissedTaskMatrix', 'false');
				}

				if (arr.filter(function (v) {
					if (v['Name'] == 'KnowledgeTestByCandidate') {
						return true
					} else {
						return false
					}
				}).length > 0) {
					localStorage.setItem('KnowledgeTestByCandidate', 'true');
				} else {
					localStorage.setItem('KnowledgeTestByCandidate', 'false');
				}

				if (arr.filter(function (v) {
					if (v["Name"] == "AddCompLib" || v["Name"] == "ModifyCompLib" || v["Name"] == "DeleteCompLib" || v["Name"] == "QueryCompLib") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableCompLib', 'true');
				} else {
					localStorage.setItem('EnableCompLib', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "AddAssessment" || v["Name"] == "ModifyAssessment" || v["Name"] == "DeleteAssessment" || v["Name"] == "QueryAssessment") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableAssessment', 'true');
				} else {
					localStorage.setItem('EnableAssessment', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "AddTag" || v["Name"] == "ModifyTag" || v["Name"] == "DeleteTag" || v["Name"] == "QueryTag") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableTag', 'true');
				} else {
					localStorage.setItem('EnableTag', 'false');
				}
				if (arr.filter(function (v) {
					if (v["Name"] == "ViewRemediationReport") {
						return true;
					} else {
						return false;
					}

				}).length > 0) {
					localStorage.setItem('EnableRemediationReport', 'true');
				} else {
					localStorage.setItem('EnableRemediationReport', 'false');
				}
				localStorage.setItem('capability', assignedCapabilities);
				this.reportService.getReportListByUserRoleId(userRole).subscribe(data => {
					if (data.reportList.length > 0) {
						localStorage.setItem('EnableReport', 'true');
						let reportList = JSON.stringify(data.reportList)
						localStorage.setItem('reportList', reportList);
					} else {
						localStorage.setItem('EnableReport', 'false');
						localStorage.setItem('reportList', "");
					}
				});
				this.getAllTimeZones()
					.subscribe(
					data => {
						let countries = JSON.stringify(data.timeZoneList)
						localStorage.setItem('timeZones', countries);
					},
					error => {
						localStorage.setItem('timeZones', '');
					});
				return assignedCapabilities;
			},
			error => {
			}
			);
	}

	isCapabilityAssigned(capability: string, userRole: string): boolean {
		if (localStorage.getItem('capability') === null) {
			this.getAssignedCapabalityList(userRole).subscribe(data => {
				let result = false;
				let assignedCapabilities = JSON.parse(localStorage.getItem('capability'));
				for (let temp of assignedCapabilities) {
					if (temp.Name == capability) {
						result = true;
					}
				}
				return result;
			});
		} else {
			let result = false;
			let assignedCapabilities: any[] = JSON.parse(localStorage.getItem('capability'));
			for (let temp of assignedCapabilities) {
				if (temp.Name == capability) {
					result = true;
				}
			}
			return result;
		}
	}

	getRolesAssignedToUser(userId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': userId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getRolesAssignedToUser' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setRolesAssignedToUser(userId: string, roles: any[]) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': userId, 'roles': roles });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/setRolesAssignedToUser' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//change user status
	changeUserstatus(userId: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': userId, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/changeUserstatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get all users for
	getAllSupervisors(userId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': userId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getAllSupervisors' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllTimeZones() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getTimeZones' + token, { headers: headers })
			.map(res => res.json());
	}

	//used to get detail(first name/last name) of the supervisor
	getUserDetailByUserId(userId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'userId': userId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getUserByUserId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllAssessors() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getAllAssessors' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveCandidates(supervisorId) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		return this.http.post(environment.server_URL + '/user/getActiveCandidates' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllShowUsers() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getAllShowUsers' + token, { headers: headers })
			.map(res => res.json());
	}

	changeUserShowHide(userId: string, showhide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'userId': userId, 'showhide': showhide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/changeUserShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllActiveUsersWithRole() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/user/getAllActiveUsersWithRole' + token, { headers: headers })
			.map(res => res.json());
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		// const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		// this.saveAsExcelFile(excelBuffer, excelFileName);
		XLSX.writeFile(workbook, excelFileName+ EXCEL_EXTENSION);
	}
}
