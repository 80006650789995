<div style="position: absolute; z-index: 1090; top: 0; left: 0;" *ngIf="side == 'apSouthWest' && overlayService.isVisible">
	<svg style="position: absolute; z-index: 1090" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter height="200%" width="200%" y="-50%" x="-50%" id="svg_18_blur">
				<feGaussianBlur stdDeviation="0" in="SourceGraphic" />
			</filter>
		</defs>
		<g>
			<rect fill="none" id="canvas_background" height="162" width="162" y="-1" x="-1" />
		</g>
		<g>
			<g stroke="null" transform="rotate(-135 121.5298843383789,41.32876205444335) " id="svg_1">
				<path fill="#FAFAFA" stroke="null" id="svg_2" d="m124.938541,-6.633007c-0.662027,-1.324055 -2.813617,-1.324055 -3.475644,0c-5.627233,10.261425 -10.757946,20.688358 -13.737069,31.942824c-0.331014,1.655069 1.489562,3.310137 2.979123,2.317096c3.310137,-2.317096 6.123754,-5.130713 8.606357,-8.44085c-0.662027,23.170961 -3.806658,47.169455 1.655069,70.009402c0.331014,1.655069 3.14436,1.324055 2.978853,-0.331284c-2.978853,-23.667481 -0.661757,-47.169455 -0.331014,-70.836936c2.813617,2.482603 5.627233,4.965206 8.275343,7.447809c1.158548,1.158548 3.641421,0.496521 3.475915,-1.489562c-1.324325,-10.923182 -4.965476,-21.184608 -10.426932,-30.618499zm-1.820575,19.695316c-0.993041,-1.489562 -3.806658,-1.158548 -3.806658,1.158548c0,0.165507 0,0.331014 0,0.331014c-1.986082,1.986082 -3.806658,3.972165 -5.958247,5.958247c2.64811,-7.613316 5.958247,-14.730111 9.764634,-21.846906c3.310137,6.454768 5.79274,13.075042 7.116795,20.191837c-1.820575,-1.655069 -3.806658,-3.310137 -5.627504,-5.130713c-0.330473,-0.662027 -0.992771,-0.827534 -1.489021,-0.662027z"
				/>
			</g>
		</g>
	</svg>
	<label style="position: absolute;z-index: 1090;color: #FAFAFA;top: 80px;width: 200px">{{ message }}</label>
</div>

<div style="position: absolute; z-index: 1090; top: 0; left: 0;" *ngIf="side == 'apSouthEast' && overlayService.isVisible">
	<svg style="position: absolute; z-index: 1090" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter height="200%" width="200%" y="-50%" x="-50%" id="svg_18_blur">
				<feGaussianBlur stdDeviation="0" in="SourceGraphic" />
			</filter>
		</defs>
		<g>
			<rect fill="none" id="canvas_background" height="162" width="162" y="-1" x="-1" />
		</g>
		<g>
			<g transform="rotate(135 40.529884338378906,41.32875823974609) " stroke="null" id="svg_1">
				<path fill="#FAFAFA" stroke="null" id="svg_2" d="m43.938541,-6.633007c-0.662027,-1.324055 -2.813617,-1.324055 -3.475644,0c-5.627233,10.261425 -10.757946,20.688358 -13.737069,31.942824c-0.331014,1.655069 1.489562,3.310137 2.979123,2.317096c3.310137,-2.317096 6.123754,-5.130713 8.606357,-8.44085c-0.662027,23.170961 -3.806658,47.169455 1.655069,70.009402c0.331014,1.655069 3.14436,1.324055 2.978853,-0.331284c-2.978853,-23.667481 -0.661757,-47.169455 -0.331014,-70.836936c2.813617,2.482603 5.627233,4.965206 8.275343,7.447809c1.158548,1.158548 3.641421,0.496521 3.475915,-1.489562c-1.324325,-10.923182 -4.965476,-21.184608 -10.426932,-30.618499zm-1.820575,19.695316c-0.993041,-1.489562 -3.806658,-1.158548 -3.806658,1.158548c0,0.165507 0,0.331014 0,0.331014c-1.986082,1.986082 -3.806658,3.972165 -5.958247,5.958247c2.64811,-7.613316 5.958247,-14.730111 9.764634,-21.846906c3.310137,6.454768 5.79274,13.075042 7.116795,20.191837c-1.820575,-1.655069 -3.806658,-3.310137 -5.627504,-5.130713c-0.330473,-0.662027 -0.992771,-0.827534 -1.489021,-0.662027z"
				/>
			</g>
		</g>
	</svg>
	<label style="position: absolute;z-index: 1090;color: #FAFAFA;top: 80px; width: 200px">{{ message }}</label>
</div>

<div style="position: absolute; z-index: 1090; top: 0; left: 0;" *ngIf="side == 'apWest' && overlayService.isVisible">
	<svg style="position: absolute; z-index: 1090" width="320" height="80" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter height="200%" width="200%" y="-50%" x="-50%" id="svg_18_blur">
				<feGaussianBlur stdDeviation="0" in="SourceGraphic" />
			</filter>
		</defs>
		<g>
			<rect x="-1" y="-1" width="162" height="82" id="canvas_background" fill="none" />
		</g>
		<g>
			<g id="svg_1" stroke="null" transform="rotate(-90 264.52990722656256,35.32875823974607) ">
				<path fill="#FAFAFA" d="m267.93854,-12.63301c-0.66203,-1.32405 -2.81362,-1.32405 -3.47564,0c-5.62724,10.26143 -10.75795,20.68836 -13.73707,31.94283c-0.33102,1.65507 1.48956,3.31013 2.97912,2.31709c3.31014,-2.31709 6.12375,-5.13071 8.60636,-8.44085c-0.66203,23.17096 -3.80666,47.16946 1.65507,70.0094c0.33101,1.65507 3.14436,1.32406 2.97885,-0.33128c-2.97885,-23.66748 -0.66176,-47.16945 -0.33101,-70.83693c2.81361,2.4826 5.62723,4.9652 8.27534,7.4478c1.15855,1.15855 3.64142,0.49653 3.47591,-1.48956c-1.32432,-10.92318 -4.96547,-21.18461 -10.42693,-30.6185zm-1.82057,19.69532c-0.99304,-1.48956 -3.80666,-1.15855 -3.80666,1.15855c0,0.1655 0,0.33101 0,0.33101c-1.98608,1.98608 -3.80666,3.97217 -5.95825,5.95825c2.64811,-7.61332 5.95825,-14.73011 9.76464,-21.84691c3.31013,6.45477 5.79274,13.07504 7.11679,20.19184c-1.82057,-1.65507 -3.80666,-3.31014 -5.6275,-5.13071c-0.33048,-0.66203 -0.99277,-0.82754 -1.48902,-0.66203z"
				 id="svg_2" stroke="null" />
			</g>
		</g>
	</svg>
	<label style="position: absolute;z-index: 1090;color: #FAFAFA;top: -8px; width: 250px; left: -40px; 
   font-family: sans-serif; font-size: 1rem; font-weight: bold; 
   line-height: 1.5; white-space: pre-line">{{ message }}</label>
</div>

<div style="position: absolute; z-index: 1090; top: 0; left: 0;" *ngIf="side == 'apEast' && overlayService.isVisible">
	<svg style="position: absolute; z-index: 1090" width="320" height="80" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter height="200%" width="200%" y="-50%" x="-50%" id="svg_18_blur">
				<feGaussianBlur stdDeviation="0" in="SourceGraphic" />
			</filter>
		</defs>
		<g>
			<rect x="-1" y="-1" width="162" height="82" id="canvas_background" fill="none" />
		</g>
		<g>
			<g transform="rotate(90 53.529876708984375,35.3287582397461) " id="svg_1" stroke="null">
				<path fill="#FAFAFA" d="m56.93854,-12.63301c-0.66203,-1.32405 -2.81362,-1.32405 -3.47564,0c-5.62724,10.26143 -10.75795,20.68836 -13.73707,31.94283c-0.33102,1.65507 1.48956,3.31013 2.97912,2.31709c3.31014,-2.31709 6.12375,-5.13071 8.60636,-8.44085c-0.66203,23.17096 -3.80666,47.16946 1.65507,70.0094c0.33101,1.65507 3.14436,1.32406 2.97885,-0.33128c-2.97885,-23.66748 -0.66176,-47.16945 -0.33101,-70.83693c2.81361,2.4826 5.62723,4.9652 8.27534,7.4478c1.15855,1.15855 3.64142,0.49653 3.47591,-1.48956c-1.32432,-10.92318 -4.96547,-21.18461 -10.42693,-30.6185zm-1.82057,19.69532c-0.99304,-1.48956 -3.80666,-1.15855 -3.80666,1.15855c0,0.1655 0,0.33101 0,0.33101c-1.98608,1.98608 -3.80666,3.97217 -5.95825,5.95825c2.64811,-7.61332 5.95825,-14.73011 9.76464,-21.84691c3.31013,6.45477 5.79274,13.07504 7.11679,20.19184c-1.82057,-1.65507 -3.80666,-3.31014 -5.6275,-5.13071c-0.33048,-0.66203 -0.99277,-0.82754 -1.48902,-0.66203z"
				 id="svg_2" stroke="null" />
			</g>
		</g>
	</svg>
	<label style="position: absolute;z-index: 1090;color: #FAFAFA;top: 20px; width: 150px; left: 120px">{{ message }}</label>
</div>

<div style="position: absolute; z-index: 1090; top: 0; left: 0;" *ngIf="side == 'apSouth' && overlayService.isVisible">
	<svg style="position: absolute; z-index: 1090" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter height="200%" width="200%" y="-50%" x="-50%" id="svg_18_blur">
				<feGaussianBlur stdDeviation="0" in="SourceGraphic" />
			</filter>
		</defs>
		<g>
			<rect fill="none" id="canvas_background" height="162" width="162" y="-1" x="-1" />
		</g>
		<g id="svg_4" transform="rotate(-45 77.52987670898438,54.328758239746094) ">
			<g stroke="null" transform="rotate(-135 77.5298843383789,54.32876205444336) " id="svg_1">
				<path fill="#FAFAFA" stroke="null" id="svg_2" d="m80.93854,6.36699c-0.66203,-1.32405 -2.81362,-1.32405 -3.47564,0c-5.62724,10.26143 -10.75795,20.68836 -13.73707,31.94283c-0.33102,1.65507 1.48956,3.31013 2.97912,2.31709c3.31014,-2.31709 6.12375,-5.13071 8.60636,-8.44085c-0.66203,23.17096 -3.80666,47.16946 1.65507,70.0094c0.33101,1.65507 3.14436,1.32406 2.97885,-0.33128c-2.97885,-23.66748 -0.66176,-47.16945 -0.33101,-70.83693c2.81361,2.4826 5.62723,4.9652 8.27534,7.4478c1.15855,1.15855 3.64142,0.49653 3.47591,-1.48956c-1.32432,-10.92318 -4.96547,-21.18461 -10.42693,-30.6185zm-1.82057,19.69532c-0.99305,-1.48956 -3.80666,-1.15855 -3.80666,1.15855c0,0.1655 0,0.33101 0,0.33101c-1.98608,1.98608 -3.80666,3.97217 -5.95825,5.95825c2.64811,-7.61332 5.95825,-14.73011 9.76463,-21.84691c3.31014,6.45477 5.79275,13.07504 7.1168,20.19184c-1.82057,-1.65507 -3.80666,-3.31014 -5.6275,-5.13071c-0.33048,-0.66203 -0.99278,-0.82754 -1.48903,-0.66203l0.00001,0z"
				/>
			</g>
		</g>
	</svg>
	<label style="position: absolute;z-index: 1090;color: #FAFAFA;top: 120px; width: 300px; left: -170px; 
   font-family: sans-serif; font-size: 1rem; font-weight: bold; 
   line-height: 1.5; white-space: pre-line">{{ message }}</label>
</div>

<div style="position: absolute; z-index: 1090; top: 0; left: 0;" *ngIf="side == 'apNorthEast' && overlayService.isVisible">
	<svg style="position: absolute; z-index: 1090" width="160" height="160" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter height="200%" width="200%" y="-50%" x="-50%" id="svg_18_blur">
				<feGaussianBlur stdDeviation="0" in="SourceGraphic" />
			</filter>
		</defs>
		<g>
			<rect fill="none" id="canvas_background" height="162" width="162" y="-1" x="-1" />
		</g>
		<g transform="rotate(-180 45.529876708984375,117.32876586914062) " id="svg_4">
			<g id="svg_1" transform="rotate(-135 45.529884338378906,117.32876586914061) " stroke="null">
				<path stroke="null" fill="#FAFAFA" d="m48.93854,69.36699c-0.66203,-1.32405 -2.81362,-1.32405 -3.47564,0c-5.62724,10.26143 -10.75795,20.68836 -13.73707,31.94283c-0.33102,1.65507 1.48956,3.31013 2.97912,2.31709c3.31014,-2.31709 6.12375,-5.13071 8.60636,-8.44085c-0.66203,23.17096 -3.80666,47.16946 1.65507,70.0094c0.33101,1.65507 3.14436,1.32406 2.97885,-0.33128c-2.97885,-23.66748 -0.66176,-47.16945 -0.33101,-70.83693c2.81361,2.4826 5.62723,4.9652 8.27534,7.4478c1.15855,1.15855 3.64142,0.49653 3.47591,-1.48956c-1.32432,-10.92318 -4.96547,-21.18461 -10.42693,-30.6185zm-1.82057,19.69532c-0.99305,-1.48956 -3.80666,-1.15855 -3.80666,1.15855c0,0.1655 0,0.33101 0,0.33101c-1.98608,1.98608 -3.80666,3.97217 -5.95825,5.95825c2.64811,-7.61332 5.95825,-14.73011 9.76463,-21.84691c3.31014,6.45477 5.79275,13.07504 7.1168,20.19184c-1.82057,-1.65507 -3.80666,-3.31014 -5.6275,-5.13071c-0.33048,-0.66203 -0.99278,-0.82754 -1.48903,-0.66203l0.00001,0z" id="svg_2"/>
			</g>
		</g>
	</svg>
	<label style="position: absolute;z-index: 1090;color: #FAFAFA;top: 30px;width: 200px;left: 80px;">{{ message }}</label>
</div>