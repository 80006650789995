import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { AssessmentService } from "../../../../assessment/assessment.service";
import { UserService } from "../../../../user/user.service";
import { SharedService } from "../../../../home/shared.service";
import { AlertService } from '../../../../util/alert/alert.service';

@Component({
	selector: 'app-assessmenthistory',
	templateUrl: './assessmenthistory.component.html'
})

export class AssessmentHistoryComponent implements OnInit, AfterViewInit {
	@ViewChild('assessmentHistory', { static: true }) tblElement: ElementRef;
	public data: Array<any> = [];
	public userId = localStorage.getItem('userId');

	constructor(private assessmentService: AssessmentService, private sharedService: SharedService, private userService: UserService, private alertService: AlertService) {
		this.data = this.assessmentService.getAssessmentHistoryData();
	}

	ngOnInit(): void {
	}

	ngAfterViewInit() {
		let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
		this.sharedService.historyHeightChange(height);
	}

	public performAssessmentClickEvent(assessment, col) {
		if (col == 5) {
			this.assessmentService.setSelectedAssessmentModel(assessment);
			this.sharedService.change('RemediationReport');
		}
		if (col == 6) {
			this.userService.getUserData()
				.subscribe(
				data => {
					if (data.length > 0) {
						assessment.firstName = data[0].FirstName
						assessment.lastName = data[0].LastName
					}
					this.assessmentService.setSelectedAssessmentModel(assessment);
					this.assessmentService.setGenerate(true)
					this.sharedService.change('CandidateAssessment');
				},
				error => {
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				)

		}
	}
}
