<div class="col-lg-12 mx-auto">
	<!-- core competency -->
	<div class="container mx-auto mt-3 mb-2" *ngIf="showHierarchy">
		<div class="row">
			<div class="col-sm-3 p-0 m-0">
				<button type="button" class="float-left btn btn-outline-warning btn-sm mr-2 ml-1" (click)="addCoreCompetencyBtnClick('coreCompetency')">
					<i class="fa fa fa-plus "></i>
				</button>
				<h5 class="float-left ml-2">Core Competency</h5>
			</div>
			<div class="col-sm-9 p-0 m-0">
				<hr/>
			</div>
		</div>
	</div>

	<!-- show data -->
	<div class="col-sm-12 mx-auto mb-3" *ngIf="showHierarchy">
		<!-- show selected core competencies -->
		<div class="row">
			<div class="col-sm-12 mt-1 mb-1" *ngFor="let ccItem of jobProfileModel.coreCompetencyList | orderBy : 'orderNum'; let k = index">
				<div class="card p-2 card-default " (click)="showCollapsePanel(ccItem.id , 'coreCompetency')">
					<div class="card-body pointer-cursor">
						<div class="float-left mt-1, mb-1" [ngClass]="{'': showCCDetail != ccItem.id, 'col-sm-12 pl-0': showCCDetail == ccItem.id }">
							{{ ccItem.text }}
							<label class="mb-0">
									<span class="badge badge-warning mr-1">{{ ccItem.taskTotal }}</span></label>
						</div>
						<div class="float-right">
							<div class="float-left mb-1" *ngIf="showCCDetail == ccItem.id">
								<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); sortCCContentBtnClick(ccItem)">
									<i class="fa fa-sort-alpha-asc"></i>
								</button>
									
								<small class="text-muted">Task Group</small>
								<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="addTaskGroupBtnClick('taskGroup', ccItem)">
									<i class="fa fa fa-plus "></i>
								</button>

								<small class="text-muted">Task</small>
								<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="addTaskBtnClick('task', ccItem)">
									<i class="fa fa fa-plus "></i>
								</button>
							</div>
							<div class="float-right btn btn-sm border-0 mr-1 ml-1">
								<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCCDetail == ccItem.id, 'fa-angle-down': showCCDetail != ccItem.id }"></i>
							</div>
							<button type="button" class="float-right btn btn-sm btn-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeCoreCompetency(ccItem.id, ccItem.text, k)">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				</div>

				<div *ngIf="showCCDetail == ccItem.id">
					<div *ngFor="let task of ccItem.taskList | orderBy : 'orderNum'; let t = index ">
						<ng-container *ngIf="task.isTaskGroup; else elseTemplate">
							<!-- task group -->
							<div class="col-sm-12 mx-auto mt-1 ">
								<div class="card p-2 card-grey-lighten-3" (click)="showCollapsePanel(task.id, 'taskGroup')">
									<div class="card-body pointer-cursor">
										<div class="float-left mt-1, mb-1" [ngClass]="{'': showTaskDetail != task.id, 'col-sm-12 pl-0': showTaskDetail == task.id }">
											<!-- move up/ move down button -->
											<div *ngIf="(ccItem.taskList.length > 1) && (t == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
											<button prevent-parent-click *ngIf="(ccItem.taskList.length > 1) && (t != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
											 (click)="moveUpTask(ccItem, task)">
												<i class="fa fa-arrow-up" aria-hidden="true"></i>
											</button>
											<button prevent-parent-click *ngIf="(ccItem.taskList.length > 1) && (t != (ccItem.taskList.length - 1))" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
											 (click)="moveDownTask(ccItem, task)">
												<i class="fa fa-arrow-down" aria-hidden="true"></i>
											</button>
											<div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
											<label class="mb-0">
												<span class="badge badge-dark mr-1">Task Group</span>{{ task.taskGroup }} <span class="badge badge-warning mr-1">{{ task.taskList.length }}</span></label>
										</div>
										<div class="float-right">
											<div class="float-left mb-1">
												<!-- <small class="text-muted">Edit</small>
                        							<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="editTaskGroup('taskGroupTask', ccItem, task)">
                          							<i class="fa fa-pencil "></i>
												</button> -->
												<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); sortTGContentBtnClick(ccItem, task)">
													<i class="fa fa-sort-alpha-asc"></i>
												</button>

												<small class="text-muted">Task</small>
												<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="addTaskFromTaskGroup('taskGroupTask', ccItem, task)">
													<i class="fa fa fa-plus "></i>
												</button>
											</div>
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
												<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showTaskGroupDetail == task.id, 'fa-angle-down': showTaskGroupDetail != task.id }"></i>
											</div>
											<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeTaskGroup(ccItem, task)">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
									</div>
									<!-- </div> -->
									<div *ngIf="showTaskGroupDetail == task.id">
										<div class="col-sm-12 mx-auto mt-1" *ngFor="let tGtask of task.taskList | orderBy : 'orderNum'; let gt = index">
											<div class="col-sm-12 mx-auto mt-1">
												<div prevent-parent-click class="card p-2" (click)="showCollapsePanel(tGtask.id, 'task')">
													<div class="card-body pointer-cursor">
														<div class="col-sm-12 pl-0 pr-0">
															<div 
																[ngClass]="{'': showTaskDetail != tGtask.id, 'col-sm-12 pl-0': showTaskDetail == tGtask.id }">
															<!-- move up/ move down button -->
															<div *ngIf="(task.taskList.length > 1) && (gt == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																<button prevent-parent-click *ngIf="(task.taskList.length > 1) && (gt != 0)" type="button"
																	class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveUpTask(ccItem, tGtask)">
																<i class="fa fa-arrow-up" aria-hidden="true"></i>
															</button>
																<button prevent-parent-click *ngIf="(task.taskList.length > 1) && (gt != (task.taskList.length - 1))"
																	type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
															 (click)="moveDownTask(ccItem, tGtask)">
																<i class="fa fa-arrow-down" aria-hidden="true"></i>
															</button>
																<div *ngIf="(task.taskList.length > 1) && (gt == (task.taskList.length - 1))"
																	class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
															{{ tGtask.text }}
												
																<div class="float-right btn btn-sm border-0 mr-1 ml-1" *ngIf="showTaskDetail != tGtask.id">
																	<i class="mr-1 ml-1 fa"
																		[ngClass]="{'fa-angle-up': showTaskDetail == tGtask.id, 'fa-angle-down': showTaskDetail != tGtask.id }"></i>
														</div>
																<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1"
																	aria-label="Close" *ngIf="showTaskDetail != tGtask.id"
																	(click)="$event.stopPropagation(); removeTask(ccItem, tGtask)">
																	<span aria-hidden="true">&times;</span>
																</button>
												
																<div class="btn-group float-right mr-3 ml-1" role="group" aria-label="jp-btn-group"
																	*ngIf="showTaskDetail != tGtask.id">
																	<button type="button" class="btn btn-sm pointer-cursor"
																		[ngClass]="{'btn-outline-secondary': tGtask.criticalityValue != 3, 'btn-danger': tGtask.criticalityValue == 3 }"
																	 (click)="$event.stopPropagation(); changeCriticalityValue(3, ccItem, tGtask)">
																		High </button>
																	<button type="button" class="btn btn-sm pointer-cursor"
																		[ngClass]="{'btn-outline-secondary': tGtask.criticalityValue != 2, 'btn-custom-warning': tGtask.criticalityValue == 2 }"
																	 (click)="$event.stopPropagation(); changeCriticalityValue(2, ccItem, tGtask)">
																		Medium </button>
																	<button type="button" class="btn btn-sm pointer-cursor"
																		[ngClass]="{'btn-outline-secondary': tGtask.criticalityValue != 1, 'btn-success': tGtask.criticalityValue == 1 }"
																	 (click)="$event.stopPropagation(); changeCriticalityValue(1, ccItem, tGtask)">
																		Low </button>
																</div>
																<label class="float-right mr-1 ml-5" *ngIf="tGtask.regulatoryList.length > 0 && showTaskDetail != tGtask.id">
																	<span class="badge badge-custom">
																		<i class="fa fa-university" aria-hidden="true"></i>
																		Regulatory Agency
																	</span>
																</label>
															</div>
														</div>												
														<div class="col-sm-12 pl-0 pr-0">
															<div class="col-sm-12 mx-auto"
																*ngIf="showTaskDetail == tGtask.id && (tGtask.regulatoryList.length > 0 || isClickedRegAgency == true) ">
																<div class="row" *ngIf="isClickedRegAgency == false">
																	<div class="col mr-5 mb-1">
																		<span prevent-parent-click class="badge badge-custom float-right ml-1 mt-1"
																			*ngFor="let regulatory of tGtask.regulatoryList | orderBy : 'alphabeticRegulatoryAgency'">
																			{{ regulatory.text }} <i class="fa fa-times"
																				(click)="removeRegulatoryAgency(ccItem, tGtask, regulatory)"></i></span>
																	</div>
																</div>
																<div class="row" *ngIf="isClickedRegAgency == true">
																	<div class="col-sm-8 mt-2">
																		<span prevent-parent-click class="badge badge-custom float-right  ml-1 mt-1"
																			*ngFor="let regulatory of tGtask.regulatoryList | orderBy : 'alphabeticRegulatoryAgency'">
																			{{ regulatory.text }} <i class="fa fa-times"
																				(click)="removeRegulatoryAgency(ccItem, tGtask, regulatory)"></i></span>
																	</div>
																	<div class="col-sm-4 mb-1">
																		<ng-select class="custom" [allowClear]="true" [items]="rgList"
																			placeholder="{{ placeholderText }}" name="rgId" (removed)="removedRegulatoryAgency($event)"
																			[(ngModel)]="selectedRegAgency"
																			(selected)="selectedRegulatoryAgency($event, ccItem, tGtask, regulatory)">
																		</ng-select>
																	</div>
																</div>
															</div>
														</div>
														<div class="col-sm-12 pl-0 pr-0">
															<div>
																<div class="float-right mb-1 mt-1" *ngIf="showTaskDetail == tGtask.id">
																	<div class="btn-group mr-3" role="group" aria-label="jp-btn-group">
																		<button type="button" class="btn btn-sm pointer-cursor"
																			[ngClass]="{'btn-outline-secondary': tGtask.criticalityValue != 3, 'btn-danger': tGtask.criticalityValue == 3 }"
																			(click)="$event.stopPropagation(); changeCriticalityValue(3, ccItem, tGtask)">
																			High </button>
																		<button type="button" class="btn btn-sm pointer-cursor"
																			[ngClass]="{'btn-outline-secondary': tGtask.criticalityValue != 2, 'btn-custom-warning': tGtask.criticalityValue == 2 }"
																			(click)="$event.stopPropagation(); changeCriticalityValue(2, ccItem, tGtask)">
																			Medium </button>
																		<button type="button" class="btn btn-sm pointer-cursor"
																			[ngClass]="{'btn-outline-secondary': tGtask.criticalityValue != 1, 'btn-success': tGtask.criticalityValue == 1 }"
																			(click)="$event.stopPropagation(); changeCriticalityValue(1, ccItem, tGtask)">
																			Low </button>
																	</div>
																	<small class="text-muted">RegulatoryAgency</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
																		(click)="$event.stopPropagation(); addRegulatoryAgency(tGtask.id, tGtask)">
																		<i class="fa fa fa-plus "></i>
																	</button>
																<small class="text-muted">Performance</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
																		(click)="$event.stopPropagation(); addPropertiesToTaskBtnClick('performance', ccItem, tGtask)">
																	<i class="fa fa fa-plus "></i>
																</button>
														
																<small class="text-muted">Knowledge</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
																		(click)="$event.stopPropagation(); addPropertiesToTaskBtnClick('knowledge', ccItem, tGtask)">
																	<i class="fa fa fa-plus "></i>
																</button>
														
																<small class="text-muted">Remediation</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
																		(click)="$event.stopPropagation(); addPropertiesToTaskBtnClick('taskRemediation', ccItem, tGtask)">
																	<i class="fa fa fa-plus "></i>
																</button>
															<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																		<i class="mr-1 ml-1 fa"
																			[ngClass]="{'fa-angle-up': showTaskDetail == tGtask.id, 'fa-angle-down': showTaskDetail != tGtask.id }"></i>
															</div>
																	<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close"
																		(click)="$event.stopPropagation(); removeTask(ccItem, tGtask)">
																<span aria-hidden="true">&times;</span>
															</button>
														</div>
													</div>
												</div>
													</div>
												</div>
												<div *ngIf="showTaskDetail == tGtask.id">
													<div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
														<div class="mt-2">
															<h6 class="mt-1 mb-1">Performance
																<span class="badge badge-warning">{{ tGtask.performanceList.length }}</span>
															</h6>
															<div *ngFor="let performance of tGtask.performanceList | orderBy : 'orderNum' ; let p = index ">
																<div class="col-sm-12 mx-auto mt-1">
																	<div prevent-parent-click class="card p-2" (click)="showCollapsePanel(performance.id, 'performance')">
																		<div class="card-body pointer-cursor">
																			<div class="float-left mt-1 mb-1" [ngClass]="{'': showPerformanceDetail != performance.id, 'col-sm-12 pl-0': showPerformanceDetail == performance.id }">
																				<!-- move up/ move down button -->
																				<div *ngIf="(tGtask.performanceList.length > 1) && (p == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				<button prevent-parent-click *ngIf="(tGtask.performanceList.length > 1) && (p != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																				 (click)="moveUpPerformance(ccItem, tGtask, performance)">
																					<i class="fa fa-arrow-up" aria-hidden="true"></i>
																				</button>
																				<button prevent-parent-click *ngIf="(tGtask.performanceList.length > 1) && (p != (tGtask.performanceList.length - 1))" type="button"
																				 class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDownPerformance(ccItem, tGtask, performance)">
																					<i class="fa fa-arrow-down" aria-hidden="true"></i>
																				</button>
																				<div *ngIf="(tGtask.performanceList.length > 1) && (p == (tGtask.performanceList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				{{ performance.text }}
																			</div>
																			<div class="float-right">
																				<div class="float-left mb-1" *ngIf="showPerformanceDetail == performance.id">
																					<small class="text-muted">Check List</small>
																					<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addPropertiesToPerformanceBtnClick('checkList', ccItem, tGtask, performance)">
																						<i class="fa fa fa-plus "></i>
																					</button>

																					<small class="text-muted">Remediation</small>
																					<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addPropertiesToPerformanceBtnClick('performanceRemediation', ccItem, tGtask, performance)">
																						<i class="fa fa fa-plus "></i>
																					</button>
																				</div>
																				<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																					<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
																				</div>
																				<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removePerformance(ccItem, tGtask, performance)">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																		</div>
																	</div>
																</div>

																<!-- show performance detail -->
																<div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id">
																	<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
																		<div class="mt-2">
																			<h6 class="mt-1 mb-1">Check List
																				<span class="badge badge-warning">{{ performance.checkList.length }}</span>
																			</h6>
																			<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
																				<div class="col-sm-12 mx-auto mt-1">
																					<div prevent-parent-click class="card p-2" (click)="showCollapsePanel(checkList.id, 'checkListRemediation')">
																						<div class="card-body pointer-cursor">
																							<div class="float-left mt-1 mb-1" [ngClass]="{'': showCheckListDetail != checkList.id, 'col-sm-12 pl-0': showCheckListDetail == checkList.id }">
																								<!-- move up/ move down button -->
																								<div *ngIf="(performance.checkList.length > 1) && (ch == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																								<button prevent-parent-click *ngIf="(performance.checkList.length > 1) && (ch != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																								 (click)="moveUPCL(ccItem, task, performance, checkList)">
																									<i class="fa fa-arrow-up" aria-hidden="true"></i>
																								</button>
																								<button prevent-parent-click *ngIf="(performance.checkList.length > 1) && (ch != (performance.checkList.length - 1))" type="button"
																								 class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDOWNCL(ccItem, tGtask ,performance, checkList)">
																									<i class="fa fa-arrow-down" aria-hidden="true"></i>
																								</button>
																								<div *ngIf="(performance.checkList.length > 1) && (ch == (performance.checkList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																								{{ checkList.text }}
																							</div>
																							<div class="float-right">
																								<div class="float-left mb-1" *ngIf="showCheckListDetail == checkList.id">
																									<small class="text-muted">Remediation</small>
																									<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addRemediationToCheckListBtnClick('checkListRemediation', ccItem, tGtask, performance, checkList)">
																										<i class="fa fa fa-plus "></i>
																									</button>
																								</div>
																								<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																									<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
																								</div>
																								<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeCheckList(ccItem, tGtask, performance, checkList)">
																									<span aria-hidden="true">&times;</span>
																								</button>
																							</div>
																						</div>
																					</div>

																					<!-- checkList remediation -->
																					<div *ngIf="showCheckListDetail == checkList.id">
																						<div *ngFor="let clremediation of checkList.remediationList; let clr = index">
																							<div class="col-sm-12 mx-auto mt-1">
																								<div prevent-parent-click class="card p-2 card-default">
																									<div class="card-body pointer-cursor">
																										<div class="float-left">{{ clremediation.text }}</div>
																										<div class="float-right">
																											<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeCheckListRemediation(ccItem, tGtask, performance, checkList, clremediation)">
																												<span aria-hidden="true">&times;</span>
																											</button>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>

																				</div>
																			</div>
																		</div>

																		<div class="mt-2">
																			<h6 class="mt-1 mb-1">Remediation
																				<span class="badge badge-warning">{{ performance.remediationList.length }}</span>
																			</h6>
																			<div *ngFor="let remediation of performance.remediationList; let rem = index ">
																				<div class="col-sm-12 mx-auto mt-1">
																					<div prevent-parent-click class="card p-2">
																						<div class="card-body">
																							<div class="float-left">{{ remediation.text }}</div>
																							<div class="float-right">
																								<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-2 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removePerformanceRemediation(ccItem, tGtask, performance, remediation)">
																									<span aria-hidden="true">&times;</span>
																								</button>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>
														</div>

														<div class="mt-2">
															<h6 class="mt-1 mb-1">Knowledge
																<span class="badge badge-warning">{{ tGtask.knowledgeList.length }}</span>
															</h6>
															<div *ngFor="let knowledge of tGtask.knowledgeList | orderBy : 'orderNum'; let k = index ">
																<div class="col-sm-12 mx-auto mt-1">
																	<div prevent-parent-click class="card p-2 " (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																		<div class="card-body pointer-cursor">
																			<div class="float-left mt-1 mb-1" [ngClass]="{'': showKnowledgeDetail != knowledge.id, 'col-sm-12 pl-0': showKnowledgeDetail == knowledge.id }">
																				<!-- move up/ move down button -->
																				<div *ngIf="(tGtask.knowledgeList.length > 1) && (k == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				<button prevent-parent-click *ngIf="(tGtask.knowledgeList.length > 1) && (k != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																				 (click)="moveUpKnowledge(ccItem, tGtask, knowledge)">
																					<i class="fa fa-arrow-up" aria-hidden="true"></i>
																				</button>
																				<button prevent-parent-click *ngIf="(tGtask.knowledgeList.length > 1) && (k != (tGtask.knowledgeList.length - 1))" type="button"
																				 class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDownKnowledge(ccItem, tGtask, knowledge)">
																					<i class="fa fa-arrow-down" aria-hidden="true"></i>
																				</button>
																				<div *ngIf="(tGtask.knowledgeList.length > 1) && (k == (tGtask.knowledgeList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				{{ knowledge.knowledge }}
																			</div>
																			<div class="float-right">
																				<div class="float-left mb-1" *ngIf="showKnowledgeDetail == knowledge.id">
																					<small class="text-muted">Remediation</small>
																					<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addRemediationToKnowledgeBtnClick('knowledgeRemediation', ccItem, tGtask, knowledge)">
																						<i class="fa fa fa-plus "></i>
																					</button>
																				</div>
																				<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																					<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
																				</div>
																				<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledge(ccItem, tGtask, knowledge)">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																		</div>
																	</div>

																	<!-- knowledge remediation -->
																	<div *ngIf="showKnowledgeDetail == knowledge.id">
																		<div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
																			<div class="col-sm-12 mx-auto mt-1">
																				<div prevent-parent-click class="card p-2">
																					<div class="card-body pointer-cursor">
																						<div class="float-left">{{ kremediation.text }}</div>
																						<div class="float-right">
																							<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledgeRemediation(ccItem, tGtask, knowledge, kremediation)">
																								<span aria-hidden="true">&times;</span>
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</div>

														<div class="mt-2">
															<h6 class="mt-1 mb-1">Knowledge Question
																<span class="badge badge-warning">{{ tGtask.knowledgeQuestionList != null ? tGtask.knowledgeQuestionList.length : 0 }}</span>
															</h6>
															<div *ngFor="let knowledge of tGtask.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
																<div class="col-sm-12 mx-auto mt-1">
																	<div prevent-parent-click class="card p-2 " (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
																		<div class="card-body pointer-cursor">
																			<div class="float-left mt-1 mb-1" [ngClass]="{'': showKnowledgeDetail != knowledge.id, 'col-sm-12 pl-0': showKnowledgeDetail == knowledge.id }">
																				<!-- move up/ move down button -->
																				<div *ngIf="(tGtask.knowledgeQuestionList.length > 1) && (k == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				<button prevent-parent-click *ngIf="(tGtask.knowledgeQuestionList.length > 1) && (k != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																				 (click)="moveUpKnowledge(ccItem, tGtask, knowledge)">
																					<i class="fa fa-arrow-up" aria-hidden="true"></i>
																				</button>
																				<button prevent-parent-click *ngIf="(tGtask.knowledgeQuestionList.length > 1) && (k != (tGtask.knowledgeQuestionList.length - 1))"
																				 type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDownKnowledge(ccItem, tGtask, knowledge)">
																					<i class="fa fa-arrow-down" aria-hidden="true"></i>
																				</button>
																				<div *ngIf="(tGtask.knowledgeQuestionList.length > 1) && (k == (tGtask.knowledgeQuestionList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				{{ knowledge.knowledge }}
																			</div>
																			<div class="float-right">
																				<div class="float-left mb-1" *ngIf="showKnowledgeDetail == knowledge.id">
																					<small class="text-muted">Remediation</small>
																					<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addRemediationToKnowledgeBtnClick('knowledgeRemediation', ccItem, tGtask, knowledge)">
																						<i class="fa fa fa-plus "></i>
																					</button>
																				</div>
																				<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																					<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
																				</div>
																				<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledge(ccItem, tGtask, knowledge)">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																		</div>
																	</div>

																	<!-- knowledge remediation -->
																	<div *ngIf="showKnowledgeDetail == knowledge.id">
																		<div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
																			<div class="col-sm-12 mx-auto mt-1">
																				<div prevent-parent-click class="card p-2">
																					<div class="card-body pointer-cursor">
																						<div class="float-left">{{ kremediation.text }}</div>
																						<div class="float-right">
																							<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledgeRemediation(ccItem, tGtask, knowledge, kremediation)">
																								<span aria-hidden="true">&times;</span>
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</div>

														<div class="mt-2">
															<h6 class="mt-1 mb-1">Remediation
																<span class="badge badge-warning">{{ tGtask.remediationList.length }}</span>
															</h6>
															<div *ngFor="let remediation of tGtask.remediationList; let tr = index ">
																<div class="col-sm-12 mx-auto mt-1">
																	<div prevent-parent-click class="card p-2">
																		<div class="card-body pointer-cursor">
																			<div class="float-left">{{ remediation.text }}</div>
																			<div class="float-right">
																				<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeTaskRemediation(ccItem, tGtask, remediation)">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-template #elseTemplate>
							<!-- task item -->
							<div class="col-sm-12 mx-auto mt-1">
								<div class="card p-2" (click)="showCollapsePanel(task.id, 'task')">
									<div class="card-body pointer-cursor">
										<div class="col-sm-12 pl-0 pr-0">
											<div [ngClass]="{'': showTaskDetail != task.id, 'col-sm-12 pl-0': showTaskDetail == task.id }">
											<!-- move up/ move down button -->
											<div *ngIf="(ccItem.taskList.length > 1) && (t == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
												<button prevent-parent-click *ngIf="(ccItem.taskList.length > 1) && (t != 0)" type="button"
													class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveUpTask(ccItem, task)">
												<i class="fa fa-arrow-up" aria-hidden="true"></i>
											</button>
												<button prevent-parent-click *ngIf="(ccItem.taskList.length > 1) && (t != (ccItem.taskList.length - 1))"
													type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
											 (click)="moveDownTask(ccItem, task)">
												<i class="fa fa-arrow-down" aria-hidden="true"></i>
											</button>
												<div *ngIf="(ccItem.taskList.length > 1) && (t == (ccItem.taskList.length - 1))"
													class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
											{{ task.text }}
								
												<div class="float-right btn btn-sm border-0 mr-1 ml-1" *ngIf="showTaskDetail != task.id">
													<i class="mr-1 ml-1 fa"
														[ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
										</div>
								
												<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1"
													aria-label="Close" (click)="$event.stopPropagation(); removeTask(ccItem, task)"
													*ngIf="showTaskDetail != task.id">
													<span aria-hidden="true">&times;</span>
												</button>
								
												<div class="btn-group float-right mr-3 ml-1" role="group" aria-label="jp-btn-group"
													*ngIf="showTaskDetail != task.id">
													<button type="button" class="btn btn-sm pointer-cursor"
														[ngClass]="{'btn-outline-secondary': task.criticalityValue != 3, 'btn-danger': task.criticalityValue == 3 }"
													 (click)="$event.stopPropagation(); changeCriticalityValue(3, ccItem, task)"> High </button>
													<button type="button" class="btn btn-sm pointer-cursor"
														[ngClass]="{'btn-outline-secondary': task.criticalityValue != 2, 'btn-custom-warning': task.criticalityValue == 2 }"
														(click)="$event.stopPropagation(); changeCriticalityValue(2, ccItem, task)"> Medium
													</button>
													<button type="button" class="btn btn-sm pointer-cursor"
														[ngClass]="{'btn-outline-secondary': task.criticalityValue != 1, 'btn-success': task.criticalityValue == 1 }"
													 (click)="$event.stopPropagation(); changeCriticalityValue(1, ccItem, task)"> Low </button>
												</div>
												<label class="float-right mr-1 ml-5"
													*ngIf="task.regulatoryList.length > 0 && showTaskDetail != task.id">
													<span class="badge badge-custom">
														<i class="fa fa-university" aria-hidden="true"></i>
														Regulatory Agency
													</span>
												</label>
											</div>
										</div>
										<div class="col-sm-12 pl-0 pr-0" >
											<div class="col-sm-12 mx-auto"
												*ngIf="showTaskDetail == task.id && (task.regulatoryList.length > 0 || isClickedRegAgency == true) ">
												<div class="row" *ngIf="isClickedRegAgency == false">
													<div class="mr-5 mb-1">
														<span prevent-parent-click class="badge badge-custom float-right  ml-1 mt-1"
															*ngFor="let regulatory of task.regulatoryList | orderBy : 'alphabeticRegulatoryAgency'">
															{{ regulatory.text }} <i class="fa fa-times"
																(click)="removeRegulatoryAgency(ccItem, task, regulatory)"></i></span>
													</div>
												</div>
												<div class="row" *ngIf="isClickedRegAgency == true">
													<div class="col-sm-8 mt-2">
														<span prevent-parent-click class="badge badge-custom float-right ml-1 mt-1"
															*ngFor="let regulatory of task.regulatoryList | orderBy : 'alphabeticRegulatoryAgency'">
															{{ regulatory.text }} <i class="fa fa-times"
																(click)="removeRegulatoryAgency(ccItem, task, regulatory)"></i></span>
													</div>
													<div class="col-sm-4 mb-1">
														<ng-select [allowClear]="true" [items]="rgList" placeholder="{{ placeholderText }}" name="rgId"
															(removed)="removedRegulatoryAgency($event)" [(ngModel)]="selectedRegAgency"
															(selected)="selectedRegulatoryAgency($event, ccItem, task, regulatory)">
														</ng-select>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 pl-0 pr-0">
											<div class="">
												<div class="float-right mb-1 mt-1" *ngIf="showTaskDetail == task.id && !task.isTaskGroup">
													<div class="btn-group mr-3" role="group" aria-label="jp-btn-group">
														<button type="button" class="btn btn-sm pointer-cursor"
															[ngClass]="{'btn-outline-secondary': task.criticalityValue != 3, 'btn-danger': task.criticalityValue == 3 }"
															(click)="$event.stopPropagation(); changeCriticalityValue(3, ccItem, task)"> High </button>
														<button type="button" class="btn btn-sm pointer-cursor"
															[ngClass]="{'btn-outline-secondary': task.criticalityValue != 2, 'btn-custom-warning': task.criticalityValue == 2 }"
															(click)="$event.stopPropagation(); changeCriticalityValue(2, ccItem, task)"> Medium
														</button>
														<button type="button" class="btn btn-sm pointer-cursor"
															[ngClass]="{'btn-outline-secondary': task.criticalityValue != 1, 'btn-success': task.criticalityValue == 1 }"
															(click)="$event.stopPropagation(); changeCriticalityValue(1, ccItem, task)"> Low </button>
													</div>
													<small class="text-muted">RegulatoryAgency</small>
													<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
														(click)="$event.stopPropagation(); addRegulatoryAgency(task.id, task)">
														<i class="fa fa fa-plus "></i>
													</button>
												<small class="text-muted">Performance</small>
													<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
														(click)="$event.stopPropagation(); addPropertiesToTaskBtnClick('performance', ccItem, task)">
													<i class="fa fa fa-plus "></i>
												</button>
								
												<small class="text-muted">Knowledge</small>
													<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
														(click)="$event.stopPropagation(); addPropertiesToTaskBtnClick('knowledge', ccItem, task)">
													<i class="fa fa fa-plus "></i>
												</button>
								
												<small class="text-muted">Remediation</small>
													<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1"
														(click)="$event.stopPropagation(); addPropertiesToTaskBtnClick('taskRemediation', ccItem, task)">
													<i class="fa fa fa-plus "></i>
												</button>
											<div class="float-right btn btn-sm border-0 mr-1 ml-1">
														<i class="mr-1 ml-1 fa"
															[ngClass]="{'fa-angle-up': showTaskDetail == task.id, 'fa-angle-down': showTaskDetail != task.id }"></i>
											</div>
													<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1"
														aria-label="Close" (click)="$event.stopPropagation(); removeTask(ccItem, task)">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
									</div>
								</div>
									</div>
								</div>

								<div *ngIf="showTaskDetail == task.id">
									<div class="col-sm-12 mx-auto mt-1 card card-grey-lighten-4 pb-3">
										<div class="mt-2">
											<h6 class="mt-1 mb-1">Performance
												<span class="badge badge-warning">{{ task.performanceList.length }}</span>
											</h6>
											<div *ngFor="let performance of task.performanceList | orderBy : 'orderNum' ; let p = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2" (click)="showCollapsePanel(performance.id, 'performance')">
														<div class="card-body pointer-cursor">
															<div class="float-left mt-1 mb-1" [ngClass]="{'': showPerformanceDetail != performance.id, 'col-sm-12 pl-0': showPerformanceDetail == performance.id }">
																<!-- move up/ move down button -->
																<div *ngIf="(task.performanceList.length > 1) && (p == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																<button prevent-parent-click *ngIf="(task.performanceList.length > 1) && (p != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																 (click)="moveUpPerformance(ccItem, task, performance)">
																	<i class="fa fa-arrow-up" aria-hidden="true"></i>
																</button>
																<button prevent-parent-click *ngIf="(task.performanceList.length > 1) && (p != (task.performanceList.length - 1))" type="button"
																 class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDownPerformance(ccItem, task, performance)">
																	<i class="fa fa-arrow-down" aria-hidden="true"></i>
																</button>
																<div *ngIf="(task.performanceList.length > 1) && (p == (task.performanceList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																{{ performance.text }}
															</div>
															<div class="float-right">
																<div class="float-left mb-1" *ngIf="showPerformanceDetail == performance.id">
																	<small class="text-muted">Check List</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addPropertiesToPerformanceBtnClick('checkList', ccItem, task, performance)">
																		<i class="fa fa fa-plus "></i>
																	</button>

																	<small class="text-muted">Remediation</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addPropertiesToPerformanceBtnClick('performanceRemediation', ccItem, task, performance)">
																		<i class="fa fa fa-plus "></i>
																	</button>
																</div>
																<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																	<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showPerformanceDetail == performance.id, 'fa-angle-down': showPerformanceDetail != performance.id }"></i>
																</div>
																<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removePerformance(ccItem, task, performance)">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
														</div>
													</div>
												</div>

												<!-- show performance detail -->
												<div class="col-sm-12 mx-auto mt-2" *ngIf="showPerformanceDetail == performance.id">
													<div class="col-sm-12 mx-auto mb-2 card card-grey-lighten-5 pb-3">
														<div class="mt-2">
															<h6 class="mt-1 mb-1">Check List
																<span class="badge badge-warning">{{ performance.checkList.length }}</span>
															</h6>
															<div *ngFor="let checkList of performance.checkList | orderBy : 'orderNum'; let ch = index ">
																<div class="col-sm-12 mx-auto mt-1">
																	<div class="card p-2" (click)="showCollapsePanel(checkList.id, 'checkListRemediation')">
																		<div class="card-body pointer-cursor">
																			<div class="float-left mt-1 mb-1" [ngClass]="{'': showCheckListDetail != checkList.id, 'col-sm-12 pl-0': showCheckListDetail == checkList.id }">
																				<!-- move up/ move down button -->
																				<div *ngIf="(performance.checkList.length > 1) && (ch == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				<button prevent-parent-click *ngIf="(performance.checkList.length > 1) && (ch != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																				 (click)="moveUPCL(ccItem, task, performance, checkList)">
																					<i class="fa fa-arrow-up" aria-hidden="true"></i>
																				</button>
																				<button prevent-parent-click *ngIf="(performance.checkList.length > 1) && (ch != (performance.checkList.length - 1))" type="button"
																				 class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDOWNCL(ccItem, task ,performance, checkList)">
																					<i class="fa fa-arrow-down" aria-hidden="true"></i>
																				</button>
																				<div *ngIf="(performance.checkList.length > 1) && (ch == (performance.checkList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																				{{ checkList.text }}
																			</div>
																			<div class="float-right">
																				<div class="float-left mb-1" *ngIf="showCheckListDetail == checkList.id">
																					<small class="text-muted">Remediation</small>
																					<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addRemediationToCheckListBtnClick('checkListRemediation', ccItem, task, performance, checkList)">
																						<i class="fa fa fa-plus "></i>
																					</button>
																				</div>
																				<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																					<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showCheckListDetail == checkList.id, 'fa-angle-down': showCheckListDetail != checkList.id }"></i>
																				</div>
																				<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeCheckList(ccItem, task, performance, checkList)">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																		</div>
																	</div>

																	<!-- checkList remediation -->
																	<div *ngIf="showCheckListDetail == checkList.id">
																		<div *ngFor="let clremediation of checkList.remediationList; let clr = index">
																			<div class="col-sm-12 mx-auto mt-1">
																				<div class="card p-2 card-default">
																					<div class="card-body pointer-cursor">
																						<div class="float-left">{{ clremediation.text }}</div>
																						<div class="float-right">
																							<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeCheckListRemediation(ccItem, task, performance, checkList, clremediation)">
																								<span aria-hidden="true">&times;</span>
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																</div>
															</div>
														</div>

														<div class="mt-2">
															<h6 class="mt-1 mb-1">Remediation
																<span class="badge badge-warning">{{ performance.remediationList.length }}</span>
															</h6>
															<div *ngFor="let remediation of performance.remediationList; let rem = index ">
																<div class="col-sm-12 mx-auto mt-1">
																	<div class="card p-2">
																		<div class="card-body">
																			<div class="float-left">{{ remediation.text }}</div>
																			<div class="float-right">
																				<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-2 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removePerformanceRemediation(ccItem, task, performance, remediation)">
																					<span aria-hidden="true">&times;</span>
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>

										<div class="mt-2">
											<h6 class="mt-1 mb-1">Knowledge
												<span class="badge badge-warning">{{ task.knowledgeList.length }}</span>
											</h6>
											<div *ngFor="let knowledge of task.knowledgeList | orderBy : 'orderNum'; let k = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
														<div class="card-body pointer-cursor">
															<div class="float-left mt-1 mb-1" [ngClass]="{'': showKnowledgeDetail != knowledge.id, 'col-sm-12 pl-0': showKnowledgeDetail == knowledge.id }">
																<!-- move up/ move down button -->
																<div *ngIf="(task.knowledgeList.length > 1) && (k == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																<button prevent-parent-click *ngIf="(task.knowledgeList.length > 1) && (k != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																 (click)="moveUpKnowledge(ccItem, task, knowledge)">
																	<i class="fa fa-arrow-up" aria-hidden="true"></i>
																</button>
																<button prevent-parent-click *ngIf="(task.knowledgeList.length > 1) && (k != (task.knowledgeList.length - 1))" type="button"
																 class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDownKnowledge(ccItem, task, knowledge)">
																	<i class="fa fa-arrow-down" aria-hidden="true"></i>
																</button>
																<div *ngIf="(task.knowledgeList.length > 1) && (k == (task.knowledgeList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																{{ knowledge.knowledge }}
															</div>
															<div class="float-right">
																<div class="float-left mb-1" *ngIf="showKnowledgeDetail == knowledge.id">
																	<small class="text-muted">Remediation</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addRemediationToKnowledgeBtnClick('knowledgeRemediation', ccItem, task, knowledge)">
																		<i class="fa fa fa-plus "></i>
																	</button>
																</div>
																<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																	<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
																</div>
																<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledge(ccItem, task, knowledge)">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
														</div>
													</div>

													<!-- knowledge remediation -->
													<div *ngIf="showKnowledgeDetail == knowledge.id">
														<div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
															<div class="col-sm-12 mx-auto mt-1">
																<div class="card p-2">
																	<div class="card-body pointer-cursor">
																		<div class="float-left">{{ kremediation.text }}</div>
																		<div class="float-right">
																			<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledgeRemediation(ccItem, task, knowledge, kremediation)">
																				<span aria-hidden="true">&times;</span>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>

										<div class="mt-2">
											<h6 class="mt-1 mb-1">Knowledge Question
												<span class="badge badge-warning">{{ task.knowledgeQuestionList != null ? task.knowledgeQuestionList.length : 0 }}</span>
											</h6>
											<div *ngFor="let knowledge of task.knowledgeQuestionList | orderBy : 'orderNum'; let k = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2" (click)="showCollapsePanel(knowledge.id, 'knowledgeRemediation')">
														<div class="card-body pointer-cursor">
															<div class="float-left mt-1 mb-1" [ngClass]="{'': showKnowledgeDetail != knowledge.id, 'col-sm-12 pl-0': showKnowledgeDetail == knowledge.id }">
																<!-- move up/ move down button -->
																<div *ngIf="(task.knowledgeQuestionList.length > 1) && (k == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																<button prevent-parent-click *ngIf="(task.knowledgeQuestionList.length > 1) && (k != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
																 (click)="moveUpKnowledge(ccItem, task, knowledge)">
																	<i class="fa fa-arrow-up" aria-hidden="true"></i>
																</button>
																<button prevent-parent-click *ngIf="(task.knowledgeQuestionList.length > 1) && (k != (task.knowledgeQuestionList.length - 1))"
																 type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1" (click)="moveDownKnowledge(ccItem, task, knowledge)">
																	<i class="fa fa-arrow-down" aria-hidden="true"></i>
																</button>
																<div *ngIf="(task.knowledgeQuestionList.length > 1) && (k == (task.knowledgeQuestionList.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
																{{ knowledge.knowledge }}
															</div>
															<div class="float-right">
																<div class="float-left mb-1" *ngIf="showKnowledgeDetail == knowledge.id">
																	<small class="text-muted">Remediation</small>
																	<button type="button" class="btn btn-outline-warning btn-sm mr-2 ml-1" (click)="$event.stopPropagation(); addRemediationToKnowledgeBtnClick('knowledgeRemediation', ccItem, task, knowledge)">
																		<i class="fa fa fa-plus "></i>
																	</button>
																</div>
																<div class="float-right btn btn-sm border-0 mr-1 ml-1">
																	<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showKnowledgeDetail == knowledge.id, 'fa-angle-down': showKnowledgeDetail != knowledge.id }"></i>
																</div>
																<button type="button" class="float-right btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledge(ccItem, task, knowledge)">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
														</div>
													</div>

													<!-- knowledge remediation -->
													<div *ngIf="showKnowledgeDetail == knowledge.id">
														<div *ngFor="let kremediation of knowledge.remediationList; let kr = index">
															<div class="col-sm-12 mx-auto mt-1">
																<div class="card p-2">
																	<div class="card-body pointer-cursor">
																		<div class="float-left">{{ kremediation.text }}</div>
																		<div class="float-right">
																			<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeKnowledgeRemediation(ccItem, task, knowledge, kremediation)">
																				<span aria-hidden="true">&times;</span>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>

										<div class="mt-2">
											<h6 class="mt-1 mb-1">Remediation
												<span class="badge badge-warning">{{ task.remediationList.length }}</span>
											</h6>
											<div *ngFor="let remediation of task.remediationList; let tr = index ">
												<div class="col-sm-12 mx-auto mt-1">
													<div class="card p-2">
														<div class="card-body pointer-cursor">
															<div class="float-left">{{ remediation.text }}</div>
															<div class="float-right">
																<button type="button" class="btn btn-sm btn-outline-danger mr-1 ml-3 mb-1" aria-label="Close" (click)="$event.stopPropagation(); removeTaskRemediation(ccItem, task, remediation)">
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</div>
				</div>

			</div>
		</div>
	</div>

	<!-- data table container -->
	<div class="container mx-auto mt-2 mb-2" *ngIf="showTableData">
		<div class="col-sm-12 mb-2">
			<h5>{{ tableTopic }}</h5>
		</div>
		<div class="col-sm-12 mt-2 mb-4">
			<nav aria-label="breadcrumb" *ngIf="selectedCCModel != null">
				<ol class="breadcrumb">
					<li *ngIf="selectedCCModel != null" class="breadcrumb-item active">{{ selectedCCModel.text }}</li>
					<li *ngIf="selectedTaskModel != null" class="breadcrumb-item active">{{ selectedTaskModel.text }}</li>
					<li *ngIf="selectedTaskGroupModel != null" class="breadcrumb-item active">{{ selectedTaskGroupModel.taskGroup }}</li>
					<li *ngIf="selectedPerformanceModel != null" class="breadcrumb-item active">{{ selectedPerformanceModel.text }}</li>
					<li *ngIf="selectedKnowledgeModel != null" class="breadcrumb-item active">{{ selectedKnowledgeModel.knowledge }}</li>
					<li *ngIf="selectedCheckListModel != null" class="breadcrumb-item active">{{ selectedCheckListModel.text }}</li>
				</ol>
			</nav>
		</div>
		<div class="mt-2 mb-4" [formGroup]="selectTagForm" *ngIf="!addTaskGroup">
			<div class="col-sm-12">
				<div class="row">
					<label class="col-sm-2 ">Tags</label>
					<div class="col-sm-10">
						<tag-input [ngModel]="items" [onlyFromAutocomplete]="true" secondaryPlaceholder="Select a tag" (onAdd)="addTags($event)"
						 (onRemove)="removeTags($event)" formControlName="selectTag" name="selectTag">
							<tag-input-dropdown [showDropdownIfEmpty]="true" [focusFirstElement]="true" [displayBy]="'value'" [identifyBy]="'id'" [autocompleteItems]="autocompleteItemsAsObjects">
							</tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
		</div>

		<!-- Except Add Knowledge display -->
		<div class="col-sm-12 " *ngIf="!showKnowledges">
			<div class="table-responsive md-2">
				<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
				 class="table-sm">
				</ng-table>
			</div>
			<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [ngModelOptions]="{standalone: true}" [totalItems]="length"
			 [itemsPerPage]="itemsPerPage" [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)"
			 (numPages)="numPages = $event">
			</pagination>
			<pre *ngIf="config.paging" class="card card-block card-header">Page: {{ page }} / {{ numPages }}</pre>
		</div>
		<div *ngIf=isLoading class="loading"></div>
		<!-- Add Knowledeg display -->
		<div *ngIf="showKnowledges" class="row mx-auto mt-2">
			<div class="col-sm-12 table-responsive mt-2 mb-1">
				<table class="table table-bordered  table-striped col-sm-12">
					<thead>
						<tr>
							<th></th>
							<th class="text-center ">Knowledge</th>
							<th class="text-center ">Question</th>
							<th class="text-center ">Visual Question</th>
							<th class="text-center ">Type</th>
							<th class="text-center ">Org Code</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th></th>
							<th class="">
								<input class="form-control" type="text" name="searchKnowledge" [(ngModel)]="searchKnowledge" (keyup)="filter()">
							</th>
							<th class="">
								<select name="searchQuestion" [(ngModel)]="searchQuestion" (change)="filter()">
									<option value="2">All</option>
									<option value="1">Yes</option>
									<option value="0">No</option>
								</select>
							</th>
							<th class="">
								<select name="searchQuestion" [(ngModel)]="searchVQuestion" (change)="filter()">									
									<option value="1">Yes</option>
									<option value="0">No</option>
								</select>
							</th>
							<th class="">
								<input class="form-control" type="text" name="searchType" [(ngModel)]="searchType" (keyup)="filter()">
							</th>
							<th class="">
								<input class="form-control" type="text" name="searchOrgCode" [(ngModel)]="searchOrgCode" (keyup)="filter()">
							</th>
						</tr>
						<tr *ngFor="let row of paginatedResults; let i = index">
							<td>
								<input type="checkbox" (change)="setSelected($event, i)" *ngIf="row.selected == 1" checked>
								<input type="checkbox" (change)="setSelected($event, i)" *ngIf="row.selected == 0 || row.selected == null">
							</td>
							<td class="">{{row.description}}</td>
							<td class="">
								<input type="checkbox" class="bigcheck" disabled="disabled" *ngIf="row.isTestQuestion == 1" checked="checked" />
								<input class="bigcheck" type="checkbox" disabled="disabled" *ngIf="row.isTestQuestion==0 || row.isTestQuestion==null" />
								<span class="bigcheck-target"></span>
							</td>
							<td class="">
								<input type="checkbox" class="bigcheck" disabled="disabled" *ngIf="row.isVisualQuestion == 1" checked="checked" />
								<input class="bigcheck" type="checkbox" disabled="disabled" *ngIf="row.isVisualQuestion==0 || row.isVisualQuestion==null" />
								<span class="bigcheck-target"></span>
							</td>
							<td class="">{{row.type}}</td>
							<td class="">{{row.orgCode}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-sm-12">
				<pagination class="pagination-sm mb-2" [ngModel]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage" [maxSize]="maxSize"
				 [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
				<pre class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
			</div>
		</div>
		<div *ngIf=isLoading class="loading"></div>
		<!--buttons-->
		<div class="row mt-2 md-2 mr-2 mb-3 float-right">
			<button type="submit" class="btn btn-warning ml-1 mr-1" (click)="addToJobProfile()">Add To Job Profile</button>
			<button type="button" class="btn btn-warning ml-1 mr-1" (click)="addNewItem()">Add New</button>
			<button type="button" class="btn btn-default ml-1 mr-1" (click)="cancel()">Cancel</button>
		</div>
	</div>

	<!-- add task group -->
	<div class="row mx-auto mt-2 mb-2" *ngIf="showAddTaskGroup">
		<div class="col-sm-12 mb-2">
			<h5>{{ taskGroupTopic }}</h5>
		</div>
		<div class="col-sm-12 mt-2 mb-4">
			<nav aria-label="breadcrumb" *ngIf="selectedCCModel != null">
				<ol class="breadcrumb">
					<li *ngIf="selectedCCModel != null" class="breadcrumb-item active">{{ selectedCCModel.text }}</li>
				</ol>
			</nav>
		</div>
		<div class="col-sm-12 mb-2">
			<form [formGroup]="addTaskGroupForm" (ngSubmit)="taskGroupFormSubmit()">
				<!--task-->
				<div class="form-group row">
					<label class="col-sm-2 col-form-label">Task group name
						<sup>
							<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
						</sup>
					</label>
					<div class="col-sm-10">
						<textarea type="text" class="form-control" id="taskGroup" formControlName="taskGroup" name="taskGroup"></textarea>
					</div>
				</div>
				<!--buttons-->
				<div class="form-group row">
					<div class="col-sm-6">
					</div>
					<div class="col-sm-3">
						<button type="submit" class="btn btn-warning btn-block" [disabled]="!addTaskGroupForm.valid">Save</button>
					</div>
					<div class="col-sm-3">
						<button type="button" class="btn btn-default btn-block" (click)="cancel()">Cancel</button>
					</div>
				</div>
			</form>
		</div>

	</div>
</div>