<div class="col-sm-12">
  <div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label ">Job Profile</label>
      <div class="col-sm-10">
        <ng-select [allowClear]="true" [items]="jobProfileList" placeholder="Select job profile" (selected)="selectedJobProfile($event)"
              (removed)="removedJobProfile($event)">
        </ng-select>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div class="table-responsive mb-2">
    <ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
      class="table-sm">
    </ng-table>
  </div>
  <pagination *ngIf="config.paging" class="pagination-sm mt-2" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
    [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
  </pagination>
  <pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
</div>

<div *ngIf=isLoading class="loading"></div>
