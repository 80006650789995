import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RegulatoryAgencyService } from './regulatoryagency.service'
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { Task } from '../task/task.model';
import { CoreCompetency } from '../corecompetency/corecompetency.model'
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { RegulatoryAgency } from './regulatoryagency.model';

@Component({
	selector: 'app-addregulatoryagency',
	templateUrl: './addregulatoryagency.component.html'
})

export class AddRegulatoryAgencyComponent implements OnInit {

    addNewRegulatoryForm: FormGroup;

    constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private regulatoryAgencyService: RegulatoryAgencyService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

    ngOnInit(){
        this.addNewRegulatoryForm = new FormGroup({
			description: new FormControl(null, Validators.required),
			shortName: new FormControl(null, Validators.required)
		});
    }

    cancel() {		
	    this.sharedService.change('AllRegulatoryAgency');		
    }
    
	save() {
		let description: string = this.addNewRegulatoryForm.value.description;
		let shortName: string = this.addNewRegulatoryForm.value.shortName;
		let tempRegulatoryAgency = new RegulatoryAgency('', description, shortName);
		this.regulatoryAgencyService.addNewRegulatoryAgency(tempRegulatoryAgency)
			.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
	}

	saveAndNew() {
		let description: string = this.addNewRegulatoryForm.value.description;
		let shortName: string = this.addNewRegulatoryForm.value.shortName;
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		this.addRegulatorAgency(description, shortName)
	}

	private addRegulatorAgency(description: string, shortName: string) {
		let tempRegulatoryAgency = new RegulatoryAgency('', description, shortName);
		this.regulatoryAgencyService.addNewRegulatoryAgency(tempRegulatoryAgency)
			.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);					
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
	}
	
}
