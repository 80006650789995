import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Task } from './task.model';

@Injectable()
export class TaskService {

	constructor(private http: Http, private router: Router) { }

	//selected task for edit
	private selectedTaskForEdit: Task;
	public getSelectedTaskForEdit(): Task {
		return this.selectedTaskForEdit;
	}
	public setSelectedTaskForEdit(task: Task): void {
		this.selectedTaskForEdit = task;
	}

	//selected task group for edit
	private selectedTaskGroupForEdit: Task;
	public getSelectedTaskGroupForEdit(): Task {
		return this.selectedTaskGroupForEdit;
	}
	public setSelectedTaskGroupForEdit(taskGroup: Task): void {
		this.selectedTaskGroupForEdit = taskGroup;
	}

	//get all tasks
	getAllTaks() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getAllTasks' + token, { headers: headers })
			.map(res => res.json());
	}

	//update task by task id
	updateTaskByTaskId(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/task/updateTaskByTaskId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update task status by taskId
	updateTaskStatusByTaskId(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/task/updateTaskStatusByTaskId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add new task
	addNewTask(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/task/addNewTask' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete task
	deleteTask(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/task/deleteTask' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get active task
	getActiveTasks() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getActiveTasks' + token, { headers: headers })
			.map(res => res.json());
	}

	//get assigned task taglist
	getAssignedTaskTagList(tId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'tId': tId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/task/getAssignedTaskTagList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllTaskGroups() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getAllTaskGroups' + token, { headers: headers })
			.map(res => res.json());
	}

	//add new task group
	addNewTaskGroup(taskGroup: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(taskGroup);
		return this.http.post(environment.server_URL + '/task/addNewTaskGroup' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete task group
	deleteTaskGroup(taskGroup: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(taskGroup);
		return this.http.post(environment.server_URL + '/task/deleteTaskGroup' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update task group status by taskId
	updateTaskGroupStatusByTaskGroupId(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/task/updateTaskGroupStatusByTaskGroupId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update task group by task group id
	updateTaskGroupByTaskGroupId(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(task);
		return this.http.post(environment.server_URL + '/task/updateTaskGroupByTaskGroupId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllShowTaks() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getAllShowTasks' + token, { headers: headers })
			.map(res => res.json());
	}

	changeTaskShowHide(taskId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'taskId': taskId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/changeTaskShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllShowTaskGroups() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getAllShowTaskGroups' + token, { headers: headers })
			.map(res => res.json());
	}

	changeTaskGroupShowHide(taskGroupId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'taskGroupId': taskGroupId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/changeTaskGroupShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getMissedTaskByLastAssessmentOfUser(orgId: string, includeChildren: boolean, supervisorId: string, regList: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'orgId': orgId, 'includeChildren': includeChildren, 'supervisorId': supervisorId,
		 'regList': regList });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getMissedTaskByLastAssessmentOfUser' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getRegulatoriesByTaskId(taskIdList: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'taskIdList': taskIdList });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/task/getRegulatoriesByTaskId' + token, body, { headers: headers })
			.map(res => res.json());
	}
}
