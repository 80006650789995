export class RegulatoryAgency {
    constructor(
      public id: string,
      public description: string,
      public shortName?: string,
      public text?: string,
      public jpccId?: any,
	  public jptId?: string,
	  public jppId?: string,
    ) { }
  }
  