import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import 'rxjs/Rx';
import { Observable } from 'rxjs';

import { AssessmentModel } from './assessment.model';
import { CheckList } from '../complib/checklist/checklist.model'
import { Knowledge } from '../complib/knowledge/knowledge.model'
import { Performance } from '../complib/performance/performance.model'
import { Task } from '../complib/task/task.model'
import { CoreCompetency } from '../complib/corecompetency/corecompetency.model'
import { AssessmentDocument } from './performassessment/assessmentdocument.model'
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class AssessmentService {
	selectedUsers: Array<any> = [];
	selectedOrgCodeId: string = null;
	scheduleMode: string = null;
	assessmentMode: string = null;
	assessmentDate: Date = null;
	scheduledSelfAssessmentdate: Date = null;
	jobProgressionStartupData: any = null;
	scheduledAssessmentdata = [];
	activeAssessmentdata = [];
	assessmentHistoryData = [];
	competencyPercentageData = [];
	employeeCompPercentageData = [];
	pieChartData: number[] = [0, 0, 0];
	competencySnapshotData: any = null
	employeePieChartData: number[] = [0, 0, 0, 0];
	employeeAssessmentsData = [];

	constructor(private http: Http, private router: Router) { }

	private selectedAssessmentModel: AssessmentModel
	private generate: boolean = true
	private type: string = null
	private selectedItem: string = null
	private selectedUserId: string = null

	public setSelectedAssessmentModel(assessment: AssessmentModel) {
		this.selectedAssessmentModel = assessment
	}
	public getSelectedAssessmentModel(): AssessmentModel {
		return this.selectedAssessmentModel
	}

	public setGenerate(generate: boolean) {
		this.generate = generate
	}

	public getGenerate(): boolean {
		return this.generate
	}

	public setType(type: string) {
		this.type = type
	}

	public getType(): string {
		return this.type
	}

	public setSelectedUserId(id: string) {
		this.selectedUserId = id
	}

	public getSelectedUserId(): string {
		return this.selectedUserId
	}

	public getJobProgressionStartupData() {
		return this.jobProgressionStartupData;
	}

	public setJobProgressionStartupData(data: any) {
		this.jobProgressionStartupData = data;
	}

	public getSelectedItem(): string {
		return this.selectedItem
	}

	public setSelectedItem(selectedItem: string) {
		this.selectedItem = selectedItem
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		// const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		// this.saveAsExcelFile(excelBuffer, excelFileName);
		XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION);
	}

	getCalendarDataByScheduleAssessmentDate(curDate: Date, isAdminRole: boolean) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessorId': localStorage.getItem('userId'), 'curDate': curDate, 'isAdminRole': isAdminRole });
		return this.http.post(environment.server_URL + '/assessment/getCalendarDataByScheduleAssessmentDate' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getCalendarDataByScheduleSelfAssessmentDate(curDate: Date, isAdminRole: boolean) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessorId': localStorage.getItem('userId'), 'curDate': curDate, 'isAdminRole': isAdminRole });
		return this.http.post(environment.server_URL + '/assessment/getCalendarDataByScheduleSelfAssessmentDate' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllAssessmentBySelectedParameters(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(param);
		return this.http.post(environment.server_URL + '/assessment/getAllAssessmentBySelectedParameters' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getOpportunityForAssessment(assId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'assId': assId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getOpportunityForAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getOpportunityOfAssessmentForReport(supervisorId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getOpportunityOfAssessmentForReport' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getOpportunityCategories() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getOpportunityCategories' + token, { headers: headers })
			.map(res => res.json());
	}

	getLastCompletedAssessmentOfUserWithParams(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getLastCompletedAssessmentOfUserWithParams' + token, body, { headers: headers })
			.map(res => res.json());
	}

	addOpportunityForAssessment(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/addOpportunityForAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	deleteOpportunityOfAssessment(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/deleteOpportunityOfAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	rescheduleAssessment(selectedUsers: Array<any>, scheduleAssessmentDate, isAdminRole) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessorId': localStorage.getItem('userId'), 'scheduleAssessmentDate': scheduleAssessmentDate, 'assessmentList': selectedUsers, 'isAdminRole': isAdminRole });
		return this.http.post(environment.server_URL + '/assessment/rescheduleAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setSelectedUsers(users: Array<any>) {
		this.selectedUsers = users;
	}

	setScheduleMode(mode: string) {
		this.scheduleMode = mode;
	}

	getScheduleMode() {
		return this.scheduleMode;
	}

	getSelectedUsers() {
		return this.selectedUsers;
	}

	setSelectedOrgCodeId(orgCodeId: string) {
		this.selectedOrgCodeId = orgCodeId;
	}

	getSelectedOrgCodeId() {
		return this.selectedOrgCodeId;
	}

	setAssessmentMode(mode: string) {
		this.assessmentMode = mode;
	}

	getAssessmentMode() {
		return this.assessmentMode;
	}

	setAssessmentDate(date: Date) {
		this.assessmentDate = date;
	}

	getAssessmentDate() {
		return this.assessmentDate;
	}

	setScheduledSelfAssessmentDate(date: Date) {
		this.scheduledSelfAssessmentdate = date;
	}

	getScheduledSelfAssessmentDate() {
		return this.scheduledSelfAssessmentdate;
	}

	getScheduledAssessmentdata() {
		return this.scheduledAssessmentdata;
	}

	getActiveAssessmentdata() {
		return this.activeAssessmentdata;
	}

	getCompetencyPercentagedata() {
		return this.competencyPercentageData;
	}

	getEmployeeCompPercentagedata() {
		return this.employeeCompPercentageData;
	}

	getPieChartData() {
		return this.pieChartData;
	}

	getCompetencySnapshotData() {
		return this.competencySnapshotData
	}

	getEmployeePieChartData() {
		return this.employeePieChartData;
	}

	getAssessmentCompletedDateForOngoingAssessment(Id: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'Id': Id });
		return this.http.post(environment.server_URL + '/assessment/getAssessmentCompletedDateForOngoingAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllAssessments(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getAssessments' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAssessment(assessmentId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'assessmentId': assessmentId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getPackageContent(assesmentId: string, pkgId: string): Observable<Blob> {
		const body = JSON.stringify({ 'assessmentId': assesmentId, 'pkgId': pkgId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		options.responseType = ResponseContentType.Blob;
		return this.http.post(environment.server_URL + '/assessment/getPackageContent', body, options)
			.map(res => new Blob([res.blob()], { type: 'application/pdf' }));
	}

	getCompetencyAssessmentContent(assesmentId: string) {
		const body = JSON.stringify({ 'assessmentId': assesmentId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		options.responseType = ResponseContentType.Blob;
		return this.http.post(environment.server_URL + '/assessment/getCompetencyAssessmentContent', body, options)
			.map(res => new Blob([res.blob()], { type: 'application/pdf' }));
	}

	generateCompetencyAssessment(assesmentId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assesmentId });
		return this.http.post(environment.server_URL + '/assessment/generateCompetencyAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	regenerateAssessorPackage(assesmentId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assesmentId });
		return this.http.post(environment.server_URL + '/assessment/regenerateAssessorPackage' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setAssessmentCompletedDate(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/setAssessmentCompletedDate' + token, body, { headers: headers })
			.map(res => res.json());
	}

	addAssessment(selectedUsers: Array<any>, scheduleAssessmentDate, isAdminRole, knowledgeTestChecked) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessorId': localStorage.getItem('userId'), 'scheduleAssessmentDate': scheduleAssessmentDate, 'userList': selectedUsers, 'isAdminRole': isAdminRole, 'isKnowledgeTest': knowledgeTestChecked });
		return this.http.post(environment.server_URL + '/assessment/addAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}


	cancelAssessment(selectedUsers: Array<any>) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentList': selectedUsers });
		return this.http.post(environment.server_URL + '/assessment/cancelAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	unlockAssessment(assessmentId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assessmentId });
		return this.http.post(environment.server_URL + '/assessment/unlockAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeAssessor(selectedUsers: Array<any>, newAssessorId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentList': selectedUsers, 'assessorId': newAssessorId });
		return this.http.post(environment.server_URL + '/assessment/changeAssessor' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get assessment job profile hierarchy items
	getAssessmentJobProfileHierarchy(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getAssessmentJobProfileHierarchy' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//<editor-fold> UPDATE ANSWER/STATUS/Note
	/** update answer of assessment check list */
	updateAnswerOfCheckList(clItem: CheckList) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(clItem);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateAnswerOfCheckList' + token, body, { headers: headers })
			.map(res => res.json());
	}
	/** update answer of assessment knowledge */
	updateAnswerOfKnowledge(knowledge: Knowledge, taskStatus: any, taskGrpId: any, taskGrpStatus: any, ccStatus: any, isTaskNA: any, isTaskYes: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledge': knowledge, 'taskStatus': taskStatus, 'taskGrpId': taskGrpId, 'taskGrpStatus': taskGrpStatus, 'ccStatus': ccStatus, 'isTaskNA': isTaskNA, 'isTaskYes': isTaskYes });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateAnswerOfKnowledge' + token, body, { headers: headers })
			.map(res => res.json());
	}
	/** update answer of assessment performance */
	updateAnswerOfPerformance(performance: Performance, taskStatus: any, taskGrpId: any, taskGrpStatus: any, ccStatus: any, isTaskNA: any, isTaskYes: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'performance': performance, 'taskStatus': taskStatus, 'taskGrpId': taskGrpId, 'taskGrpStatus': taskGrpStatus, 'ccStatus': ccStatus, 'isTaskNA': isTaskNA, 'isTaskYes': isTaskYes });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateAnswerOfPerformance' + token, body, { headers: headers })
			.map(res => res.json());
	}
	/** update all task elements answers to N/A */
	updateAllTaskElementAnswers(task: Task, answer: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'task': task, 'answer': answer });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateAllTaskElementAnswers' + token, body, { headers: headers })
			.map(res => res.json());
	}
	/** remove task N/A from task make IsNA == 0 */
	updateTaskIsNA(task: Task, isNA: boolean) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'task': task, 'isNA': isNA });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateTaskIsNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** remove task Yes from task make IsYes == 0 */
	updateTaskIsYes(task: Task, isYes: boolean) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'task': task, 'isYes': isYes });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateTaskIsYes' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update all task elements answers to N/A or not*/
	updateTaskItemsIsNA(task: Task, isNA: boolean, taskGrpId: any, taskGrpStatus: any, ccStatus: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'task': task, 'isNA': isNA, 'taskGrpId': taskGrpId, 'taskGrpStatus': taskGrpStatus, 'ccStatus': ccStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateTaskItemsIsNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update all task elements answers to Yes or not*/
	updateTaskItemsIsYes(task: Task, isYes: boolean, taskGrpId: any, taskGrpStatus: any, ccStatus: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'task': task, 'isYes': isYes, 'taskGrpId': taskGrpId, 'taskGrpStatus': taskGrpStatus, 'ccStatus': ccStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateTaskItemsIsYes' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update answer of assessment task */
	updateStatusOfTask(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(task);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateStatusOfTask' + token, body, { headers: headers })
			.map(res => res.json());
	}
	/** update status of assessment core competency */
	updateStatusOfCoreCompetency(coreCompetency: CoreCompetency) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(coreCompetency);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateStatusOfCoreCompetency' + token, body, { headers: headers })
			.map(res => res.json());
	}
	/** update note of the performance */
	updatePerformanceNote(performance: Performance) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(performance);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updatePerformanceNote' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update note of the knowledge */
	updateKnowledgeNote(knowledge: Knowledge) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(knowledge);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateKnowledgeNote' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update reason for N/A of the performance */
	updatePerformanceReasonForNA(performance: Performance) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(performance);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updatePerformanceReasonForNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update reason for N/A of the knowledge */
	updateKnowledgeReasonForNA(knowledge: Knowledge) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(knowledge);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateKnowledgeReasonForNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update reason for N/A of the checklist */
	updateCheckListReasonForNA(checklist: CheckList) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(checklist);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateCheckListReasonForNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	/** update reason for N/A of the task and items under it */
	updateTaskReasonForNA(task: Task) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(task);
		console.log(body)
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateTaskReasonForNA' + token, body, { headers: headers })
			.map(res => res.json());
	}
	//</editor-fold> UPDATE ANSWER/STATUS/Note

	completeAssessment(assessmentId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assessmentId });
		return this.http.post(environment.server_URL + '/assessment/completeAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateTasksToNA(assessmentId: any, taskList: any, taskGroupList: any, CCList: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assessmentId, 'taskList': taskList, 'taskGroupList': taskGroupList, 'CCList': CCList });
		return this.http.post(environment.server_URL + '/assessment/updateTasksToNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	addDocument(assessmentDocument: AssessmentDocument) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentDocument': assessmentDocument });
		return this.http.post(environment.server_URL + '/assessment/addAssessmentDocument' + token, body, { headers: headers })
			.map(res => res.json());
	}

	uploadAssessmentDocument(file: File) {
		var formData: any = new FormData();
		formData.append("uploads", file, file.name);
		const body = JSON.stringify({ 'assessmentId': this.selectedAssessmentModel.id });
		return this.http.post(environment.server_URL + '/assessment/uploadAssessmentDocument', formData)
			.map(res => res.json());
	}

	getDocumentContent(documentId: string, fileExt: string, mediatype: string): Observable<Blob> {
		const body = JSON.stringify({ 'documentId': documentId, 'fileExt': fileExt });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		options.responseType = ResponseContentType.Blob;
		return this.http.post(environment.server_URL + '/assessment/getDocumentContent', body, options)
			.map(res => new Blob([res.blob()], { type: mediatype }));
	}

	removeDocument(assessmentDocument: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentDocument': assessmentDocument });
		return this.http.post(environment.server_URL + '/assessment/removeAssessmentDocument' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateCandidateStrengths(assessmentId: string, candidateStrengths: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assessmentId, 'candidateStrengths': candidateStrengths });
		return this.http.post(environment.server_URL + '/assessment/updateCandidateStrengths' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getGlobalAssessmentProgress() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getGlobalAssessmentProgress' + token, { headers: headers })
			.map(res => res.json());
	}

	getScheduledAssessments() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getScheduledAssessments' + token, { headers: headers })
			.map(res => res.json());
	}

	loadScheduledAssessments(data) {
		// console.log('[loadScheduledAssessments](data)'+JSON.stringify(data).toString());
		this.scheduledAssessmentdata = [];
		for (var i = 0; i < data.Assessments.length; i++) {
			let tempAssessment = data.Assessments[i]
			let assessment: AssessmentModel = new AssessmentModel(tempAssessment.AssessmentId);
			assessment.orgCode = tempAssessment.OrgCode;
			assessment.userName = tempAssessment.FirstName + " " + tempAssessment.LastName;
			assessment.assessorName = tempAssessment.AssessorFirstName + " " + tempAssessment.AssessorLastName;
			assessment.jobProfile = tempAssessment.JobProfile;
			assessment.jobProfileId = tempAssessment.JobProfileId;
			assessment.lockedBy = tempAssessment.LockedBy;
			assessment.assessorCompleted = tempAssessment.AssessorCompleted;
			assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest;
			if (tempAssessment.ScheduleAssessmentDate == null) {
				assessment.scheduleAssessmentDate = null;
			} else {
				assessment.scheduleAssessmentDate = new Date(new Date(tempAssessment.ScheduleAssessmentDate).getUTCFullYear(), new Date(tempAssessment.ScheduleAssessmentDate).getUTCMonth(), new Date(tempAssessment.ScheduleAssessmentDate).getUTCDate());
			}
			if (tempAssessment.SelfAssessmentCompletedDate == null) {
				assessment.selfAssessmentCompletedDate = null;
			} else {
				assessment.selfAssessmentCompletedDate = new Date(new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCDate());
			}
			if (tempAssessment.ScheduleSelfAssessmentDate == null) {
				assessment.scheduleSelfAssessmentDate = null;
			} else {
				assessment.scheduleSelfAssessmentDate = new Date(new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCMonth(), new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCDate());
			}
			if (tempAssessment.AssessmentDate == null) {
				assessment.assessmentDate = null;
			} else {
				assessment.assessmentDate = new Date(new Date(tempAssessment.AssessmentDate).getUTCFullYear(), new Date(tempAssessment.AssessmentDate).getUTCMonth(), new Date(tempAssessment.AssessmentDate).getUTCDate());
			}
			assessment.assessorId = tempAssessment.AssessorId;
			assessment.status = tempAssessment.Status;
			assessment.discipline = tempAssessment.Discipline;
			assessment.userEmail = tempAssessment.Email;
			assessment.supervisorName = tempAssessment.SupervisorName == null ? '' : tempAssessment.SupervisorName;
			assessment.assessorName = tempAssessment.AssessorFirstName + ' ' + tempAssessment.AssessorLastName
			this.scheduledAssessmentdata.push(assessment);
		}
	}

	getActiveAssessments() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getActiveAssessments' + token, { headers: headers })
			.map(res => res.json());
	}

	loadActiveAssessments(data) {
		this.activeAssessmentdata = [];
		for (var i = 0; i < data.Assessments.length; i++) {
			let tempAssessment = data.Assessments[i]
			let assessment: AssessmentModel = new AssessmentModel(tempAssessment.AssessmentId);
			assessment.orgCode = tempAssessment.OrgCode;
			assessment.userName = tempAssessment.FirstName + " " + tempAssessment.LastName;
			assessment.jobProfile = tempAssessment.JobProfile;
			assessment.jobProfileId = tempAssessment.JobProfileId;
			assessment.candidateStrengths = tempAssessment.CandidateStrengths;
			assessment.lockedBy = tempAssessment.LockedBy;
			assessment.assessorCompleted = tempAssessment.AssessorCompleted
			assessment.assessorSignature = tempAssessment.AssessorSignature
			assessment.candidateSignature = tempAssessment.CandidateSignature
			assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest

			if (tempAssessment.ScheduleAssessmentDate == null) {
				assessment.scheduleAssessmentDate = null;
			} else {
				assessment.scheduleAssessmentDate = new Date(new Date(tempAssessment.ScheduleAssessmentDate).getUTCFullYear(), new Date(tempAssessment.ScheduleAssessmentDate).getUTCMonth(), new Date(tempAssessment.ScheduleAssessmentDate).getUTCDate());
			}
			if (tempAssessment.ScheduleSelfAssessmentDate == null) {
				assessment.scheduleSelfAssessmentDate = null;
			} else {
				assessment.scheduleSelfAssessmentDate = new Date(new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCFullYear(), new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCMonth(), new Date(tempAssessment.ScheduleSelfAssessmentDate).getUTCDate());
			}
			if (tempAssessment.SelfAssessmentCompletedDate == null) {
				assessment.selfAssessmentCompletedDate = null;
			} else {
				assessment.selfAssessmentCompletedDate = new Date(new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCDate());
			}
			if (tempAssessment.AssessmentCompletedDate == null) {
				assessment.assessmentCompletedDate = null;
			} else {
				assessment.assessmentCompletedDate = new Date(new Date(tempAssessment.AssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.AssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.AssessmentCompletedDate).getUTCDate());
			}
			if (tempAssessment.AssessmentDate == null) {
				assessment.assessmentDate = null;
			} else {
				assessment.assessmentDate = new Date(new Date(tempAssessment.AssessmentDate).getUTCFullYear(), new Date(tempAssessment.AssessmentDate).getUTCMonth(), new Date(tempAssessment.AssessmentDate).getUTCDate());
			}
			assessment.assessorId = tempAssessment.AssessorId;
			assessment.status = tempAssessment.Status;
			assessment.discipline = tempAssessment.Discipline;
			assessment.userEmail = tempAssessment.Email;
			assessment.supervisorId = tempAssessment.SupervisorId;
			assessment.assessorName = tempAssessment.AssessorFirstName + ' ' + tempAssessment.AssessorLastName
			assessment.totalItems = tempAssessment.Total;
			assessment.answeredItems = tempAssessment.Answered;
			assessment.selfAssessmentStatus = tempAssessment.SelfAssessmentStatus
			assessment.supervisorName = tempAssessment.SupervisorName == null ? '' : tempAssessment.SupervisorName;

			if (assessment.assessorCompleted != 1) {
				let score = (tempAssessment.Answered / (tempAssessment.Total + 1)) * 100
				if (score > 99 && score < 100) {
					assessment.progress = 99
				} else {
					assessment.progress = Math.round(score);
				}
			}
			else {
				assessment.progress = Math.round((tempAssessment.Answered / tempAssessment.Total) * 100);
			}
			if (tempAssessment.SelfAssessmentStatus != "Completed") {
				let score = (tempAssessment.SelfAssessmentAnswered / (tempAssessment.SelfAssessmentTotal + 1)) * 100
				if (score > 99 && score < 100) {
					assessment.selfAssessmentProgress = 99
				} else {
					assessment.selfAssessmentProgress = Math.round(score);
				}
			}
			else {
				assessment.selfAssessmentProgress = Math.round((tempAssessment.SelfAssessmentAnswered / tempAssessment.SelfAssessmentTotal) * 100);
			}
			this.activeAssessmentdata.push(assessment);
		}
	}

	getNeverAssessedCandadidateList() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getNeverAssessedCandadidateList' + token, { headers: headers })
			.map(res => res.json());
	}

	getCompletedAssessmentCandidateList() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getCompletedAssessmentCandidateList' + token, { headers: headers })
			.map(res => res.json());
	}

	getOverdueAssessmentCandidateList() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getOverdueAssessmentCandidateList' + token, { headers: headers })
			.map(res => res.json());
	}

	getMissedKnowledgeDetails(isTestQuestion: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'isTestQuestion': isTestQuestion });
		return this.http.post(environment.server_URL + '/assessment/getMissedKnowledgeDetails' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getMissedPerformanceDetails() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getMissedPerformanceDetails' + token, { headers: headers })
			.map(res => res.json());
	}

	getMissedTaskDetails() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getMissedTaskDetails' + token, { headers: headers })
			.map(res => res.json());
	}

	getPercentOfAssessmentNA(recentAssessment) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'recentAssessment': recentAssessment });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getPercentOfAssessmentNA' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getKnowledgeTestByCandidate(supervisorId: any, orgCodeId: any, jobProfileId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'supervisorId': supervisorId, 'orgCodeId': orgCodeId, 'jobProfileId': jobProfileId });
		return this.http.post(environment.server_URL + '/assessment/getKnowledgeTestByCandidate' + token, body, { headers: headers })
			.map(res => res.json());
	}

	generateAllCandidatesReport() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/generateAllCandidatesReport' + token, { headers: headers })
			.map(res => res.json());
	}

	generateAllCandidatesReportBySupervisorId(supervisorId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/generateAllCandidatesReportBySupervisorId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getSupervisorCandidateList() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getSupervisorCandidateList' + token, { headers: headers })
			.map(res => res.json());
	}

	getAssessmentHistoryData() {
		return this.assessmentHistoryData;
	}

	getAssessmentHistory(candidateId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'candidateId': candidateId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getAssessmentHistory' + token, body, { headers: headers })
			.map(res => res.json());
	}

	loadAssessmentHistory(data) {
		// console.log('[loadAssessmentHistory]' + JSON.stringify(data).toString());
		this.assessmentHistoryData = [];
		for (var i = 0; i < data.Assessments.length; i++) {
			let tempAssessment = data.Assessments[i]
			let assessment: AssessmentModel = new AssessmentModel(tempAssessment.assessmentId);
			if (tempAssessment.ScheduleAssessmentDate == null) {
				assessment.scheduleAssessmentDate = null;
			} else {
				assessment.scheduleAssessmentDate = new Date(tempAssessment.ScheduleAssessmentDate);
			}
			if (tempAssessment.ScheduleSelfAssessmentDate == null) {
				assessment.scheduleSelfAssessmentDate = null;
			} else {
				assessment.scheduleSelfAssessmentDate = new Date(tempAssessment.ScheduleSelfAssessmentDate);
			}
			if (tempAssessment.SelfAssessmentCompletedDate == null) {
				assessment.selfAssessmentCompletedDate = null;
			} else {
				assessment.selfAssessmentCompletedDate = new Date(tempAssessment.SelfAssessmentCompletedDate);
			}
			if (tempAssessment.AssessmentCompletedDate == null) {
				assessment.assessmentCompletedDate = null;
			} else {
				assessment.assessmentCompletedDate = new Date(tempAssessment.AssessmentCompletedDate);
			}
			assessment.userId = tempAssessment.UserId;
			assessment.jobProfileId = tempAssessment.JobProfileId;
			assessment.jobProfileDesc = tempAssessment.JobProfile;
			assessment.candidateStrengths = tempAssessment.CandidateStrengths;
			assessment.assessmentScore = tempAssessment.AssessmentScore == null ? 0 : tempAssessment.AssessmentScore.toFixed(0);
			assessment.userName = localStorage.getItem("userName")
			assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest
			this.assessmentHistoryData.push(assessment);
		}
	}

	getLastAssessmentScore(candidateId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'candidateId': candidateId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getLastAssessmentScore' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getCompetencyPercentageData(type) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'type': type });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getCompetencyPercentageData' + token, body, { headers: headers })
			.map(res => res.json());
	}

	loadCompetencyPercentageData(data) {
		this.competencyPercentageData = [];
		for (var i = 0; i < data.Assessments.length; i++) {
			let total = data.Assessments[i].Red + data.Assessments[i].Green + data.Assessments[i].Yellow;
			let redActual = Math.round(((data.Assessments[i].Red / total) * 100) * 100) / 100;
			let redPercentage = Math.round((data.Assessments[i].Red / total) * 100);
			let yellowActual = Math.round(((data.Assessments[i].Yellow / total) * 100) * 100) / 100;
			let yellowPercentage = Math.round((data.Assessments[i].Yellow / total) * 100);
			let greenActual = Math.round(((data.Assessments[i].Green / total) * 100) * 100) / 100;
			let greenPercentage = Math.round((data.Assessments[i].Green / total) * 100);
			let result = this.getCalculatedPercentage(redActual, yellowActual, greenActual)
			redActual = result.redPercentage
			yellowActual = result.yellowPercentage
			greenActual = result.greenPercentage

			this.competencyPercentageData.push({
				'Description': data.Assessments[i].Description, 'red': data.Assessments[i].Red, 'yellow': data.Assessments[i].Yellow, 'green': data.Assessments[i].Green,
				'redPercentage': redPercentage, 'yellowPercentage': yellowPercentage, 'greenPercentage': greenPercentage,
				'redActual': redActual, 'yellowActual': yellowActual, 'greenActual': greenActual
			})
		}
	}

	getCompetencyAnalysisData(type: string, userRole: string, userId: string, filteringText: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'type': type, 'userRole': userRole, 'userId': userId, 'filtering': filteringText });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getCompetencyAnalysisData' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getCompetencyAnalysisDataWithSubCategory(type: string, subType: string, userRole: string, userId: string, filteringText: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'type': type, 'subType': subType, 'userRole': userRole, 'userId': userId, 'filtering': filteringText });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getCompetencyAnalysisDataWithSubCategory' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getUpComingAssessmentData(candidateId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'candidateId': candidateId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getUpComingAssessmentData' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get last assessment's core competency score and task score
	getLastAssessmentCCAndTaskScore(candidateId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'candidateId': candidateId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getLastAssessmentCCAndTaskScore' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAssessmentProgress(candidateId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'candidateId': candidateId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getAssessmentProgressData' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getEmployeeCompPercentageData(type: String, supervisorId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'type': type, 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getEmployeeCompPercentageData' + token, body, { headers: headers })
			.map(res => res.json());
	}

	loadEmployeeCompPercentageData(data) {
		this.employeeCompPercentageData = [];
		for (var i = 0; i < data.Assessments.length; i++) {
			let total = data.Assessments[i].Red + data.Assessments[i].Green + data.Assessments[i].Yellow;
			let redActual = Math.round(((data.Assessments[i].Red / total) * 100) * 100) / 100;
			let redPercentage = Math.round((data.Assessments[i].Red / total) * 100);
			let yellowActual = Math.round(((data.Assessments[i].Yellow / total) * 100) * 100) / 100;
			let yellowPercentage = Math.round((data.Assessments[i].Yellow / total) * 100);
			let greenActual = Math.round(((data.Assessments[i].Green / total) * 100) * 100) / 100;
			let greenPercentage = Math.round((data.Assessments[i].Green / total) * 100);

			let result = this.getCalculatedPercentage(redActual, yellowActual, greenActual)
			redActual = result.redPercentage
			yellowActual = result.yellowPercentage
			greenActual = result.greenPercentage

			this.employeeCompPercentageData.push({
				'Description': data.Assessments[i].Description, 'red': data.Assessments[i].Red, 'yellow': data.Assessments[i].Yellow, 'green': data.Assessments[i].Green,
				'redPercentage': redPercentage, 'yellowPercentage': yellowPercentage, 'greenPercentage': greenPercentage,
				'redActual': redActual, 'yellowActual': yellowActual, 'greenActual': greenActual
			})
		}
	}

	public loadPieChartData(data: any) {
		let count = 0;
		count = data.overdueAssessmentCount[0].Count + data.completedAssessmentsCount[0].Count + data.neverAssessedCount[0].Count;
		if (count == 0) {
			this.pieChartData = [];
		} else {
			this.pieChartData = [data.overdueAssessmentCount[0].Count, data.completedAssessmentsCount[0].Count, data.neverAssessedCount[0].Count];
		}
	}

	public loadCompetencysnapshotData(data: any) {
		if (data.lastAssessment.length != 0) {
			this.competencySnapshotData = {
				'snapshotArray': data.lastAssessment,
				'lastScore': data.lastAssessment[0].AssessmentScore,
				'assessmentId': data.lastAssessment[0].AssessmentId,
				'assessmentCompletedDate': data.lastAssessment[0].AssessmentCompletedDate,
				'jobProfile': data.lastAssessment[0].JobProfile,
				'coreCompetency': data.coreCompetencies,
				'isKnowledgeTest': data.lastAssessment[0].IsKnowledgeTest
			}
		} else {
			this.competencySnapshotData = {
				'snapshotArray': [],
				'lastScore': 0,
				'assessmentId': 0,
				'assessmentCompletedDate': '',
				'jobProfile': '',
				'coreCompetency': [],
				'isKnowledgeTest': 0
			}
		}

	}

	//get self assessment job profile hierarchy items
	getSelfAssessmentJobProfileHierarchy(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(param);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getSelfAssessmentJobProfileHierarchy' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update self assessment knowledge answers
	updateSelfAssessmentKnowledgeAnswers(assessmentId: any, ACCId: any, knowledgeList: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({
			'assessmentId': assessmentId, 'ACCId': ACCId, 'knowledgeList': knowledgeList
		});
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/updateSelfAssessmentKnowledgeAnswers' + token, body, { headers: headers })
			.map(res => res.json());
	}

	completeSelfAssessment(assessmentId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'assessmentId': assessmentId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/completeSelfAssessment' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getSelfAssessmentScore(assessmentId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'assessmentId': assessmentId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getSelfAssessmentScore' + token, body, { headers: headers })
			.map(res => res.json());
	}

	submitCandidateSignature(assessmentId: string, signature: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assessmentId, 'signature': signature });
		return this.http.post(environment.server_URL + '/assessment/submitCandidateSignature' + token, body, { headers: headers })
			.map(res => res.json());
	}

	submitAssessorSignature(assessmentId: string, assessorCertification: string, signature: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'assessmentId': assessmentId, 'assessorCertification': assessorCertification, 'signature': signature });
		return this.http.post(environment.server_URL + '/assessment/submitAssessorSignature' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getEmployeeGlobalAssessmentProgress(supervisorId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getEmployeeGlobalAssessmentProgress' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getNeverAssessedCandadidateListBySupervisorId(supervisorId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getNeverAssessedCandadidateListBySupervisorId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getOverdueAssessmentCandidateListBySupervisorId(supervisorId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getOverdueAssessmentCandidateListBySupervisorId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getCompletedAssessmentCandidateListBySupervisorId(supervisorId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getCompletedAssessmentCandidateListBySupervisorId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	public loadEmployeeeAssessmentPieChartData(data: any) {
		let count = 0;
		count = data.overdueAssessmentsCount[0].Count + data.completedAssessmentsCount[0].Count + data.neverAssessedCount[0].Count;
		if (count == 0) {
			this.employeePieChartData = [];
		} else {
			this.employeePieChartData = [data.overdueAssessmentsCount[0].Count, data.completedAssessmentsCount[0].Count, data.neverAssessedCount[0].Count];
		}
	}

	getSuccessionPlanningUserList(jobProfileId: any, supervisorId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'jobProfileId': jobProfileId, 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getSuccessionPlanningUserList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getEmployeeAssessmentsData() {
		return this.employeeAssessmentsData;
	}

	getEmployeeAssessmentsBySupervisorId(supervisorId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/assessment/getEmployeeAssessmentsBySupervisorId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	loadEmployeeAssessmentsData(data) {
		// console.log('[loadEmployeeAssessmentsData]' + JSON.stringify(data).toString());
		this.employeeAssessmentsData = [];
		for (var i = 0; i < data.Assessments.length; i++) {
			let tempAssessment = data.Assessments[i]
			let assessment: AssessmentModel = new AssessmentModel(tempAssessment.assessmentId);
			if (tempAssessment.ScheduleAssessmentDate == null) {
				assessment.scheduleAssessmentDate = null;
			} else {
				assessment.scheduleAssessmentDate = new Date(tempAssessment.ScheduleAssessmentDate);
			}
			if (tempAssessment.ScheduleSelfAssessmentDate == null) {
				assessment.scheduleSelfAssessmentDate = null;
			} else {
				assessment.scheduleSelfAssessmentDate = new Date(tempAssessment.ScheduleSelfAssessmentDate);
			}
			if (tempAssessment.SelfAssessmentCompletedDate == null) {
				assessment.selfAssessmentCompletedDate = null;
			} else {
				assessment.selfAssessmentCompletedDate = new Date(new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCDate());
			}
			if (tempAssessment.AssessmentCompletedDate == null) {
				assessment.assessmentCompletedDate = null;
			} else {
				assessment.assessmentCompletedDate = new Date(new Date(tempAssessment.AssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.AssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.AssessmentCompletedDate).getUTCDate());
			}
			assessment.userId = tempAssessment.UserId;
			assessment.userName = tempAssessment.UserName;
			assessment.jobProfileId = tempAssessment.JobProfileId;
			assessment.candidateStrengths = tempAssessment.CandidateStrengths;
			assessment.jobProfileDesc = tempAssessment.JobProfile;
			assessment.assessmentScore = tempAssessment.AssessmentScore;
			assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest;

			this.employeeAssessmentsData.push(assessment);
		}
	}

	getCalculatedPercentage(redPercentage, yellowPercentage, greenPercentage) {
		let percentage = 100
		let calculatedRed = false
		let calculatedYellow = false
		let calculatedGreen = false
		if (redPercentage == 0) {
			redPercentage = 2
			percentage = percentage - 2
			calculatedRed = true
		}
		if (yellowPercentage == 0) {
			yellowPercentage = 2
			percentage = percentage - 2
			calculatedYellow = true
		}
		if (greenPercentage == 0) {
			greenPercentage = 2
			percentage = percentage - 2
			calculatedGreen = true
		}
		if (!calculatedRed) {
			redPercentage = (redPercentage / 100) * percentage
		}
		if (!calculatedYellow) {
			yellowPercentage = (yellowPercentage / 100) * percentage
		}
		if (!calculatedGreen) {
			greenPercentage = (greenPercentage / 100) * percentage
		}
		let result = { 'redPercentage': redPercentage, 'yellowPercentage': yellowPercentage, 'greenPercentage': greenPercentage }
		return result
	}

}
