
import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import { TableComponent } from "../../util/table/table.component";

import { UserService } from "../../user/user.service";
import { SharedService } from "../../home/shared.service";
import { AlertService } from "../../util/alert/alert.service";
import { remediation } from "./remediation";
import { RemediationService } from "./remediation.service";
import * as FileSaver from "file-saver";
import { CustomModalService } from "../../util/custommodal/custommodal.service";

import "rxjs/Rx";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
	selector: "app-remediation",
	templateUrl: "./remediation.component.html"
})
export class RemediationComponent extends TableComponent {
	public selectedRemediationId: string;
	public selectedRemediationStatus: number;
	public selectedRemediationOrgId: string;
	isAddRemediationAllowed: boolean = false;
	isDeleteRemediationAllowed: boolean = false;
	isModifyRemediationAllowed: boolean = false;
	isQuerRemediationAllowed: boolean = false;
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem("selectedUserRole");
	public tagList = [];
	showHideForm: FormGroup;
	checked: boolean = false;

	//this array store all the data from the server
	private serverResultData: Array<any> = [];
	max: number = 0;
	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	constructor(
		private customModalService: CustomModalService,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private userService: UserService,
		private remediationService: RemediationService
	) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}

		//get capabilities
		this.isAddRemediationAllowed = this.userService.isCapabilityAssigned(
			"AddCompLib",
			this.selectedUserRole[0][1]
		);
		this.isDeleteRemediationAllowed = this.userService.isCapabilityAssigned(
			"DeleteCompLib",
			this.selectedUserRole[0][1]
		);
		this.isModifyRemediationAllowed = this.userService.isCapabilityAssigned(
			"ModifyCompLib",
			this.selectedUserRole[0][1]
		);
		this.isQuerRemediationAllowed = this.userService.isCapabilityAssigned(
			"QueryCompLib",
			this.selectedUserRole[0][1]
		);

		if (this.isQuerRemediationAllowed) {
			this.loadShowRemediation();
		}
	}

	//on cell click event
	public onCellClick(data: any): any {
		var elementnumber = parseInt(data.row.id) - 1;
		this.selectedRemediationId = this.serverResultData[elementnumber].Id;
		this.selectedRemediationStatus = this.serverResultData[
			elementnumber
		].Status;
		this.selectedRemediationOrgId = this.serverResultData[elementnumber].OrgId;
		switch (data.column) {
			case "fileicon":
				//this.fileIconButtonClickEvent(this.serverResultData[elementnumber].uploadFilePath, this.serverResultData[elementnumber].remediAtionattachmentId);
				break;
			case "editbutton":
				//check modify capability
				if (this.isModifyRemediationAllowed) {
					this.editButtonClickEvent(
						data.row,
						this.selectedRemediationId,
						this.selectedRemediationOrgId
					);
				} else {
					//modify capability is not assigned
				}
				break;
			case "deletebutton":
				//check delete capability
				if (this.isDeleteRemediationAllowed) {
					this.deleteButtonClickEvent(this.selectedRemediationId);
				} else {
					//delete capability is not assigned
				}
				break;
			case "statusbutton":
				if (this.isModifyRemediationAllowed) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						//do nothing
					} else {
						this.statusButtonClickEvent(
							this.selectedRemediationId,
							this.serverResultData[elementnumber].Status
						);
					}
				} else {
					//nothing to do
				}
				break;
			case "showhidebutton":
				//check delete capability
				if (this.isModifyRemediationAllowed) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonClickEvent(this.selectedRemediationId, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				var temp = data.column;
				for (var count = 0; count < this.max; count++) {
					var prop = "fileicon" + count;
					if (temp == prop) {
						var values = this.serverResultData[
							elementnumber
						].uploadFilePath.split(",");
						var Id = this.serverResultData[
							elementnumber
						].remediAtionattachmentId.split(",");
						this.fileIconButtonClickEvent(values[count], Id[count]);
						break;
					}
				}
				break;
		}
	}

	//load buttons to the table
	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			var values = [];
			var Id = [];
			if (this.serverResultData[i].uploadFilePath != null) {
				values = this.serverResultData[i].uploadFilePath.split(",");
				Id = this.serverResultData[i].remediAtionattachmentId.split(",");
				var htmlElement = "";
				for (var x = 0; x < values.length; x++) {
					var fileExtension = values[x].substr(values[x].lastIndexOf(".") + 1);
					switch (fileExtension) {
						case "pdf":
							htmlElement =
								'<td><a class="btn btn-sm edit"><i class="fa fa-file-pdf-o fa-lg" (click)="fileIconButtonClickEvent(fileExtension, Id[x]);" aria-hidden="true"></i></a></td';
							break;

						case "txt":
							htmlElement =
								'<td><a class="btn btn-sm edit"><i class="fa fa-file-text-o fa-lg " aria-hidden="true"></i></a></td>';
							break;

						case "docx":
							htmlElement =
								'<a class="btn btn-sm edit"><i class="fa fa-file-word-o fa-lg "  aria-hidden="true"></i></a>';
							break;

						case "doc":
							htmlElement =
								'<a class="btn btn-sm edit"><i class="fa fa-file-word-o fa-lg " aria-hidden="true"></i></a>';
							break;

						case "ppt":
							htmlElement =
								'<a class="btn btn-sm edit"><i class="fa fa-file-powerpoint-o fa-lg " aria-hidden="true"></i></a>';
							break;

						case "pptx":
							htmlElement =
								'<a class="btn btn-sm edit"><i class="fa fa-file-powerpoint-o fa-lg " aria-hidden="true"></i></a>';
							break;

						case "xls":
							htmlElement =
								'<a class="btn btn-sm edit"><i class="fa fa-file-excel-o fa-lg " aria-hidden="true"></i></a>';
							break;

						case "xlsx":
							htmlElement =
								'<a class="btn btn-sm edit"><i class="fa fa-file-excel-o fa-lg " aria-hidden="true"></i></a>';
							break;

						default:
							break;
					}
					var prop = "fileicon" + x;
					var temp = this.data[i];
					temp[prop] = htmlElement;
					//  if(x == 0 ){
					//     this.data[i].fileicon0 = htmlElement;
					//  } else if (x == 1){
					//      this.data[i].fileicon1 = htmlElement;
					//  }
				}
			} else {
				//nothing to do here
			}
			//check status
			if (this.serverResultData[i].Status == 1) {
				this.data[i].statusbutton =
					'<a class="btn btn-sm edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				if (this.serverResultData[i].ShowHide == 0) {
					this.data[i].statusbutton =
						'<a class="btn btn-sm disabled"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
				} else {
					this.data[i].statusbutton =
						'<a class="btn btn-sm edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}
			this.data[i].editbutton =
				'<a class="btn btn-sm edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';

			this.data[i].deletebutton =
				'<a class="btn btn-sm delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyRemediationAllowed) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyRemediationAllowed) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}

		}
	}

	//navigate to add remediation component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	private loadRemediation() {
		this.remediationService.getRemediation().subscribe(
			data => {
				//console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.orgtypeList.length);
				this.data = [];
				for (let i = 0; i < data.remediationList.length; i++) {
					if (data.remediationList[i].uploadFilePath != null) {
						var temp = data.remediationList[i].uploadFilePath.split(",");
						if (temp.length > this.max) {
							this.max = temp.length;
						}
					}
				}
				for (let i = 0; i < data.remediationList.length; i++) {
					var dataTemp = {
						id: i + 1,
						medium: data.remediationList[i].DeliveryMedium,
						description: data.remediationList[i].Description,
						type: data.remediationList[i].Type,
						coursenumber:
						data.remediationList[i].CourseNumber == null
							? ""
							: data.remediationList[i].CourseNumber,
						coursedescription:
						data.remediationList[i].CourseDescription == null
							? ""
							: data.remediationList[i].CourseDescription,
						link:
						data.remediationList[i].Link == null
							? ""
							: data.remediationList[i].Link,
						orgcode:
						data.remediationList[i].OrgCode == null
							? ""
							: data.remediationList[i].OrgCode,
						note:
						data.remediationList[i].Note == null
							? ""
							: data.remediationList[i].Note,
						showHide:
						data.remediationList[i].ShowHide,
						fileicon0: "",
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: "",
					};
					if (this.max > 1) {
						for (var count = 1; count < this.max; count++) {
							var prop = "fileicon" + count;
							dataTemp[prop] = "";
						}
						this.data.push(dataTemp);
					} else {
						var prop = "fileicon";
						dataTemp[prop] = "";
						this.data.push(dataTemp);
					}
				}
				this.serverResultData = data.remediationList;
				let tempAr: Array<any> = [];
				tempAr.push({
					title: "Delivery Medium",
					name: "medium",
					filtering: { filterString: "", placeholder: "" }
				});
				tempAr.push({
					title: "Description",
					name: "description",
					filtering: { filterString: "", placeholder: "" }
				});
				tempAr.push({
					title: "Type",
					name: "type",
					filtering: { filterString: "", placeholder: "" }
				});
				tempAr.push({
					title: "Course Number",
					name: "coursenumber",
					filtering: { filterString: "", placeholder: "" }
				});
				tempAr.push({
					title: "Course Description",
					name: "coursedescription",
					filtering: { filterString: "", placeholder: "" }
				});
				//tempAr.push({ title: 'Link', name: 'link', filtering: { filterString: '', placeholder: 'Filter by Link' } });
				tempAr.push({
					title: "Org Code",
					name: "orgcode",
					filtering: { filterString: "", placeholder: "" }
				});
				//tempAr.push({ title: 'Note', name: 'note', filtering: { filterString: '', placeholder: 'Filter by Note' } });
				//tempAr.push({ title: 'Attachment', name: 'fileicon0', className: 'accepter-col-action' });

				if (this.max > 1) {
					for (var count = 1; count < this.max; count++) {
						//tempAr.push({ title: '' , name: 'fileicon'+ count, className: 'accepter-col-action' });
					}
				}
				tempAr.push({
					title: "",
					name: "statusbutton",
					className: "accepter-col-action"
				});
				tempAr.push({
					title: "",
					name: "editbutton",
					className: "accepter-col-action"
				});
				tempAr.push({
					title: "",
					name: "deletebutton",
					className: "accepter-col-action"
				});
				tempAr.push({
					title: '',
					name: 'showhidebutton',
					className: 'accepter-col-action'
				});
				this.columns = tempAr;
				this.config.sorting.columns = this.columns;

				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	//click status button
	private statusButtonClickEvent(id: string, status: any) {
		let itemType = "Remediation";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	//save status
	public changeStatus(id: string, status: number) {
		this.remediationService.changeStatus(id, status).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	//delete button click event
	private deleteButtonClickEvent(id) {
		let itemType = "Remediation";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	//delete save
	private delete() {
		this.remediationService.deleteRemediation(this.selectedRemediationId).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	fileIconButtonClickEvent(fileName, attachmentId): any {
		//alert()
		var fileExtension = fileName.substr(fileName.lastIndexOf(".") + 1);
		var mediaType = this.getFileType(fileExtension);
		this.remediationService
			.getFileContent(fileName, attachmentId, mediaType)
			.subscribe(fileData => {
				FileSaver.saveAs(fileData, fileName);
			});
	}

	//edit button click event
	private editButtonClickEvent(
		selectedRemediation: any,
		selectedRemediationId,
		selectedRemediationOrgId
	) {
		this.remediationService
			.getAssignedRemediationTagList(selectedRemediationId)
			.subscribe(
			data => {
				//console.log('[editButtonClickEvent]' + JSON.stringify(data).toString());
				this.tagList = [];
				data.assignTagList.forEach(element => {
					let item = { value: element.Description, id: element.RTagId };
					this.tagList.push(item);
				});

				let tempRemediation = new remediation(
					selectedRemediationId,
					selectedRemediation.medium,
					selectedRemediation.description,
					selectedRemediation.type,
					selectedRemediation.coursenumber,
					selectedRemediation.coursedescription,
					selectedRemediation.link,
					selectedRemediationOrgId,
					selectedRemediation.note,
					selectedRemediation.attachment
				);
				tempRemediation.tagList = this.tagList;
				this.remediationService.setSelectedRemediationForEdit(
					tempRemediation
				);
				this.sharedService.change("EditRemediation");
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	getComponet() {
		return this;
	}

	private getFileType(fileExtension: string) {
		var mediatype;
		if (fileExtension == "pdf") {
			mediatype = "application/pdf";
		} else if (fileExtension == "docx") {
			mediatype =
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
		} else if (fileExtension == "doc") {
			mediatype = "application/msword";
		} else if (fileExtension == "ppt") {
			mediatype = "application/vnd.ms-powerpoint";
		} else if (fileExtension == "pptx") {
			mediatype =
				"application/vnd.openxmlformats-officedocument.presentationml.presentation";
		} else if (fileExtension == "xls") {
			mediatype = "application/vnd.ms-excel";
		} else if (fileExtension == "xlsx") {
			mediatype =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		} else {
		}
		return mediatype;
	}

	private loadShowRemediation() {
		this.remediationService.getShowRemediation().subscribe(
			data => {
				this.data = [];
				//console.log('[getAllUsers] data : ' + JSON.stringify(data) + '/(count)' + data.orgtypeList.length);

				for (let i = 0; i < data.remediationList.length; i++) {
					if (data.remediationList[i].uploadFilePath != null) {
						var temp = data.remediationList[i].uploadFilePath.split(",");
						if (temp.length > this.max) {
							this.max = temp.length;
						}
					}
				}
				for (let i = 0; i < data.remediationList.length; i++) {
					var dataTemp = {
						id: i + 1,
						medium: data.remediationList[i].DeliveryMedium,
						description: data.remediationList[i].Description,
						type: data.remediationList[i].Type,
						coursenumber: data.remediationList[i].CourseNumber == null ? "" : data.remediationList[i].CourseNumber,
						coursedescription: data.remediationList[i].CourseDescription == null ? "" : data.remediationList[i].CourseDescription,
						link: data.remediationList[i].Link == null ? "" : data.remediationList[i].Link,
						orgcode: data.remediationList[i].OrgCode == null ? "" : data.remediationList[i].OrgCode,
						note: data.remediationList[i].Note == null ? "" : data.remediationList[i].Note,
						showHide: data.remediationList[i].ShowHide,
						fileicon0: "",
						statusbutton: "",
						editbutton: "",
						deletebutton: "",
						showhidebutton: "",
					};
					if (this.max > 1) {
						for (var count = 1; count < this.max; count++) {
							var prop = "fileicon" + count;
							dataTemp[prop] = "";
						}
						this.data.push(dataTemp);
					} else {
						var prop = "fileicon";
						dataTemp[prop] = "";
						this.data.push(dataTemp);
					}
				}
				this.serverResultData = data.remediationList;
				let tempAr: Array<any> = [];

				tempAr.push({ title: "Delivery Medium", name: "medium", filtering: { filterString: "", placeholder: "" } });
				tempAr.push({ title: "Description", name: "description", filtering: { filterString: "", placeholder: "" } });
				tempAr.push({ title: "Type", name: "type", filtering: { filterString: "", placeholder: "" } });
				tempAr.push({ title: "Course Number", name: "coursenumber", filtering: { filterString: "", placeholder: "" } });
				tempAr.push({ title: "Course Description", name: "coursedescription", filtering: { filterString: "", placeholder: "" } });
				tempAr.push({ title: "Org Code", name: "orgcode", filtering: { filterString: "", placeholder: "" } });
				if (this.max > 1) {
					for (var count = 1; count < this.max; count++) {
					}
				}
				tempAr.push({ title: "", name: "statusbutton", className: "accepter-col-action" });
				tempAr.push({ title: "", name: "editbutton", className: "accepter-col-action" });
				tempAr.push({ title: "", name: "deletebutton", className: "accepter-col-action" });
				tempAr.push({ title: '', name: 'showhidebutton', className: 'accepter-col-action' });
				this.columns = tempAr;
				this.config.sorting.columns = this.columns;

				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	showHide(event) {
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQuerRemediationAllowed) {
				this.loadRemediation();
			} else {
				//noo need to display data
			}
		} else {
			this.loadShowRemediation();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Remediation";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}


	loadData() {
		if (this.checked) {
			this.loadRemediation();
		}
		else {
			this.loadShowRemediation();
		}
	}

	public showHideEvent(remediationId: string, showHide: number) {
		this.remediationService.changeRemediationShowHide(remediationId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}
}
