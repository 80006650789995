<div class="col-lg-12">
    <div class="col" style="overflow:hidden">
        <div class="float-left">
            <h4>Reset Password</h4>
        </div>
        <div class="float-right">

        </div>
    </div>
    <hr />
</div>

<div class="container">
    <div>
        <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
            <!--current password-->
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Current Password <sup><i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i></sup></label>
                <div class="col-sm-9">
                    <input type="password" class="form-control" placeholder="Current Password" formControlName="curPwd">
                </div>
            </div>

            <!--new password-->
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">New Password <sup><i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i></sup></label>
                <div class="col-sm-9">
                    <input type="password" class="form-control" placeholder="New Password" minlength="6" pattern="^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$"
                        formControlName="newPwd">
                    <div class="mt-sm-3" *ngIf="passwordResetForm.controls.newPwd.touched">
                        <div class="alert alert-danger" role="alert" *ngIf="passwordResetForm.controls.newPwd.hasError('required')">
                            Password is required.</div>
                        <div class="alert alert-danger" role="alert" *ngIf="passwordResetForm.controls.newPwd.hasError('minlength')">Your password must contain at least six characters.</div>
                        <div class="alert alert-danger" role="alert" *ngIf="passwordResetForm.controls.newPwd.hasError('pattern')">
                            Your password must contain at least one number (0-9) and at least one lower case letter (a-z) and at least one upper case
                            letter (A-Z).</div>
                    </div>
                </div>
            </div>

            <!--retype new password-->
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Re-type New Password <sup><i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i></sup></label>
                <div class="col-sm-9">
                    <input type="password" class="form-control" placeholder="Re-type New Password" formControlName="retypeNewPwd">
                    <div class="mt-sm-3" *ngIf="passwordResetForm.controls.retypeNewPwd.touched">
                        <div class="alert alert-danger" *ngIf="passwordResetForm.hasError('mismatchedPasswords')">Passwords do not match.</div>
                    </div>
                </div>
            </div>
            <alert></alert>
            <!--buttons-->
            <div class="form-group row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-3">
                    <button type="submit" class="btn btn-warning btn-block" [disabled]="!passwordResetForm.valid"> Reset Password </button>
                </div>
                <div class="col-sm-3">
                    <button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('Profile')">Cancel</button>
                </div>
            </div>

        </form>
    </div>
</div>
