export class Performance {
  constructor(
    public id: string,
    public text: string,
    public status?: boolean,

    public checkList?: any,
    public remediationList?: any,

	 public jpccId?: any,
	 public jptId?: string,
	 public jppId?: string,
    public orderNum?: number,
    public tagList?: any,

    public answer?: any,
    /** id of the performance in assessment table.
    use to update assessment answer */
    public assId?: string,
    //assessment note in assessmentperformance table
    public assessmentNote?: string,
	//id of assessment table
    public assessmentId?: string,
	 public reasonNA?: string,
	 public reasonNAId?: string
  ) { }
}
