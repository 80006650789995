<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Discipline</h4>
		</div>
		<div class="float-right">
			<div class="row">
				<form [formGroup]="showHideForm" class="mr-2 ml-1">
					<div class="col-sm-12">
						<input class="mr-1" (change)="showHide($event)" [checked]="checked==true"  type="checkbox" id="showHide" name="showHide"> Show Hidden
					</div>
				</form>
				<button type="button" class="btn btn-outline-warning btn-sm ml-1" (click)="selectedComponentItem('AddDiscipline')" [disabled]="!isAddDisciplineAllowed">
					<i class="fa fa-plus"></i>&nbsp;Add New Discipline
				</button>
			</div>
		</div>
	</div>
	<hr/>
</div>

<div class="col-lg-12 mx-auto">
	<alert></alert>
	<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
	 style="cursor: pointer;" class="table-sm">
	</ng-table>
	<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
	 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
	</pagination>
	<pre *ngIf="config.paging" class="card card-block card-header">Page: {{page}} / {{numPages}}</pre>
</div>