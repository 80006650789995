/**This class used to display task report. Most missed tasks are
 * displayed in top.
*/

import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { Report } from "../report.model";
import { ReportTableComponent } from "../reporttable.component";
import { AssessmentService } from '../../assessment/assessment.service';

@Component({
	selector: "app-supervisor-candidate-report",
	templateUrl: "./supervisorcandidatereport.component.html"
})
export class SupervisorCandiadteReportComponent implements OnInit {
	public report: Report;
	public data: any[];
	public excelData: any[];
	private serverResultData: Array<any> = [];
	public displayData: Array<any> = [];
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	originaldata: Array<any> = [];

	searchSupervisorName: string = "";
	searchFirstName: string = "";
	searchLastName: string = "";
	searchContractor: string = "";
	searchEmail: string = "";
	searchOrgCode: string = "";
	searchJobProfile: string = "";
	searchTimeZone: string = "";
	searchIsAssessed: string = "";
	isFiltering: boolean = false;

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private changeDetectorRef: ChangeDetectorRef,
		private assessmentService: AssessmentService
	) {
		this.report = this.reportService.getSelectedReport();
		this.report.columns = [
			{
				title: "Supervisor Name",
				name: "Supervisor Name",
				filtering: {
					filterString: ""
				}
			},
			{
				title: "First Name",
				name: "First Name",
				filtering: { filterString: "" }
			},
			{
				title: "Last Name",
				name: "Last Name",
				filtering: { filterString: "" }
			},
			{
				title: "Contractor",
				name: "Contractor",
				filtering: { filterString: "" }
			},
			{
				title: "Email",
				name: "Email",
				filtering: { filterString: "" }
			},
			{
				title: "Org Code",
				name: "Org Code",
				filtering: { filterString: "" }
			},
			{
				title: "Job Profile",
				name: "Job Profile",
				filtering: { filterString: "" }
			},
			{
				title: "Time Zone",
				name: "Time Zone",
				filtering: { filterString: "" }
			},
			{
				title: "Is Assessed",
				name: "Is Assessed",
				filtering: {
					filterString: ""
				}
			},
			{ title: "Last Assessment Completed Date", name: "Last Assessment Completed Date" },
			{ title: "Scheduled Assessment Date", name: "Scheduled Assessment Date" }
		];
	}

	ngOnInit() {
		// this.processData(this.report.reportData);
		//this.sortData(this.report.reportData);
		this.loadCandidatesWithSupervisors(this.report.reportData);
		// this.createExcelData();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	getSupervisorCandidates(criteria: SupervisorCandidateSearchCriteria) {
		if (criteria.sortDirection === 'desc') {
			this.fillData();
			this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
		}
		else if (criteria.sortDirection === 'asc') {
			this.fillData();
			this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				var lastAssessmentCompletedDate = null;
				if (this.originaldata[i].LastAssessmentCompletedDate != null) {
					lastAssessmentCompletedDate = new Date(new Date(this.originaldata[i].LastAssessmentCompletedDate).getUTCFullYear(), new Date(this.originaldata[i].LastAssessmentCompletedDate).getUTCMonth(), new Date(this.originaldata[i].LastAssessmentCompletedDate).getUTCDate());
				}
				var scheduledAssessmentDate = null;
				if (this.originaldata[i].ScheduledAssessmentDate != null) {
					scheduledAssessmentDate = new Date(new Date(this.originaldata[i].ScheduledAssessmentDate).getUTCFullYear(), new Date(this.originaldata[i].ScheduledAssessmentDate).getUTCMonth(), new Date(this.originaldata[i].ScheduledAssessmentDate).getUTCDate());
				}
				this.filteredData.push({
					"SupervisorName": this.originaldata[i].SupervisorName,
					"FirstName": this.originaldata[i].FirstName,
					"LastName": this.originaldata[i].LastName,
					"Contractor": this.originaldata[i].Contractor,
					"Email": this.originaldata[i].Email,
					"OrgCode": this.originaldata[i].OrgCode,
					"JobProfile": this.originaldata[i].JobProfile,
					"TimeZone": this.originaldata[i].TimeZone,
					"IsAssessed": this.originaldata[i].IsAssessed,
					"LastAssessmentCompletedDate": lastAssessmentCompletedDate == null ? '' : lastAssessmentCompletedDate.toLocaleDateString(),
					"ScheduledAssessmentDate": scheduledAssessmentDate == null ? '' : scheduledAssessmentDate.toLocaleDateString()
				});
			}
			if (this.searchSupervisorName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["SupervisorName"].match(new RegExp(this.searchSupervisorName, "i"));
				});
			}
			if (this.searchFirstName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["FirstName"].match(new RegExp(this.searchFirstName, "i"));
				});
			}
			if (this.searchLastName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["LastName"].match(new RegExp(this.searchLastName, "i"));
				});
			}
			if (this.searchContractor != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Contractor"].match(new RegExp(this.searchContractor, "i"));
				});
			}
			if (this.searchEmail != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Email"].match(new RegExp(this.searchEmail, "i"));
				});
			}
			if (this.searchOrgCode != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
				});
			}
			if (this.searchJobProfile != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
				});
			}
			if (this.searchTimeZone != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["TimeZone"].match(new RegExp(this.searchTimeZone, "i"));
				});
			}
			if (this.searchIsAssessed != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["IsAssessed"].match(new RegExp(this.searchIsAssessed, "i"));
				});
			}
			//this.report.printData = this.filteredData;
		}

	}

	private loadCandidatesWithSupervisors(userData: any) {
		let unsortedData = [];
		this.excelData = [];
		for (var i = 0; i < userData.length; i++) {
			// remove users without supervisor
			let item = userData[i];
			if (item.Supervisor != null && item.Supervisor != "") {
				var lacd = null
				var sad = null
				if (item.LastAssessmentCompletedDate != null) {
					lacd = new Date(new Date(item.LastAssessmentCompletedDate).getUTCFullYear(), new Date(item.LastAssessmentCompletedDate).getUTCMonth(), new Date(item.LastAssessmentCompletedDate).getUTCDate()).toLocaleDateString();
				} else {
					lacd = "";
				}
				if (item.ScheduleAssessmentDate != null) {
					sad = new Date(new Date(item.ScheduleAssessmentDate).getUTCFullYear(), new Date(item.ScheduleAssessmentDate).getUTCMonth(), new Date(item.ScheduleAssessmentDate).getUTCDate()).toLocaleDateString();
				} else {
					sad = "";
				}
				unsortedData.push({
					Id: item.Id,
					Supervisor: item.Supervisor,
					FirstName: item.FirstName,
					LastName: item.LastName,
					Contractor: item.IsContract == 0 ? "No" : "Yes",
					Email: item.Email,
					OrgCode: item.orgCode,
					JobProfile: item.jobProfile,
					TimeZone: item.TimeZone,
					IsAssessed: item.IsAssessed === 1 ? "Yes" : "No",
					AssessmentCompletedDate: lacd,
					ScheduleAssessmentDate: sad
				});
			}
		}

		let data: any[] = unsortedData.sort((n1, n2) => {
			//sort by supervisor name
			if (n1.Supervisor.toLowerCase() > n2.Supervisor.toLowerCase()) {
				return 1;
			}

			if (n1.Supervisor.toLowerCase() < n2.Supervisor.toLowerCase()) {
				return -1;
			}

			return 0;
		});

		const today = new Date();
		for (let i = 0; i < data.length; i++) {
			this.displayData.push({
				"SupervisorName": data[i].Supervisor,
				"FirstName": data[i].FirstName,
				"LastName": data[i].LastName,
				"Contractor": data[i].Contractor,
				"Email": data[i].Email,
				"OrgCode": data[i].OrgCode == null ? "" : data[i].OrgCode,
				"JobProfile": data[i].JobProfile == null ? "" : data[i].JobProfile,
				"TimeZone": data[i].TimeZone == null ? "" : data[i].TimeZone,
				"IsAssessed": data[i].IsAssessed,
				"LastAssessmentCompletedDate":
				data[i].AssessmentCompletedDate != null && (new Date(data[i].AssessmentCompletedDate) <= today)
					? new Date(data[i].AssessmentCompletedDate).toLocaleDateString()
					: "",
				"ScheduledAssessmentDate":
				data[i].ScheduleAssessmentDate != null &&
					(data[i].AssessmentCompletedDate == null ||
						data[i].ScheduleAssessmentDate > data[i].AssessmentCompletedDate)
					? new Date(data[i].ScheduleAssessmentDate).toLocaleDateString()
					: ""
			});
			this.originaldata.push({
				"SupervisorName": data[i].Supervisor,
				"FirstName": data[i].FirstName,
				"LastName": data[i].LastName,
				"Contractor": data[i].Contractor,
				"Email": data[i].Email,
				"OrgCode": data[i].OrgCode == null ? "" : data[i].OrgCode,
				"JobProfile": data[i].JobProfile == null ? "" : data[i].JobProfile,
				"TimeZone": data[i].TimeZone == null ? "" : data[i].TimeZone,
				"IsAssessed": data[i].IsAssessed,
				"LastAssessmentCompletedDate":
				data[i].AssessmentCompletedDate != null && (new Date(data[i].AssessmentCompletedDate) <= today)
					? new Date(data[i].AssessmentCompletedDate).toLocaleDateString()
					: "",
				"ScheduledAssessmentDate":
				data[i].ScheduleAssessmentDate != null &&
					(data[i].AssessmentCompletedDate == null ||
						data[i].ScheduleAssessmentDate > data[i].AssessmentCompletedDate)
					? new Date(data[i].ScheduleAssessmentDate).toLocaleDateString()
					: ""
			});
		}

		this.total = this.displayData.length;
		this.filteredData = this.displayData;
		this.extendData();
		this.pageChanged();
	}

	public extendData() {
		if (this.filteredData.length > 1) {
			let currentSupervisorName = this.filteredData[0]["SupervisorName"];
			this.filteredData[0]["Color"] = "";
			this.report.reportDataList = [];
			this.report.reportDataList.push({
				"SupervisorName": this.filteredData[0].SupervisorName,
				"FirstName": this.filteredData[0].FirstName,
				"LastName": this.filteredData[0].LastName,
				"Contractor": this.filteredData[0].Contractor,
				"Email": this.filteredData[0].Email,
				"OrgCode": this.filteredData[0].OrgCode,
				"JobProfile": this.originaldata[0].JobProfile,
				"TimeZone": this.filteredData[0].TimeZone,
				"IsAssessed": this.filteredData[0].IsAssessed,
				"LastAssessmentCompletedDate": this.filteredData[0].LastAssessmentCompletedDate,
				"ScheduledAssessmentDate": this.filteredData[0].ScheduledAssessmentDate
			});
			for (let i = 1; i < this.filteredData.length; i++) {
				if (this.filteredData[i]["SupervisorName"] != '') {
					if (this.filteredData[i]["SupervisorName"] === currentSupervisorName) {
						this.filteredData[i]["SupervisorName"] = '';
						this.filteredData[i]["Color"] = this.filteredData[i - 1]["Color"]
					} else {
						currentSupervisorName = this.filteredData[i]["SupervisorName"];
						this.filteredData[i]["Color"] = this.filteredData[i - 1]["Color"] == "" ? "rgba(0, 0, 0, 0.05)" : ""
					}
				}
				this.report.reportDataList.push({
					"SupervisorName": this.filteredData[i].SupervisorName,
					"FirstName": this.filteredData[i].FirstName,
					"LastName": this.filteredData[i].LastName,
					"Contractor": this.filteredData[i].Contractor,
					"Email": this.filteredData[i].Email,
					"OrgCode": this.filteredData[i].OrgCode,
					"JobProfile": this.originaldata[i].JobProfile,
					"TimeZone": this.filteredData[i].TimeZone,
					"IsAssessed": this.filteredData[i].IsAssessed,
					"LastAssessmentCompletedDate": this.filteredData[i].LastAssessmentCompletedDate,
					"ScheduledAssessmentDate": this.filteredData[i].ScheduledAssessmentDate
				});
			}
		}
		else {
			this.report.reportDataList = this.filteredData;
		}
	}

	public fillData() {
		if (this.filteredData.length > 1) {
			let currentSupervisorName = this.filteredData[0]["SupervisorName"];
			for (let i = 1; i < this.filteredData.length; i++) {
				if (this.filteredData[i]["SupervisorName"] == '') {
					this.filteredData[i]["SupervisorName"] = currentSupervisorName;
				} else {
					currentSupervisorName = this.filteredData[i]["SupervisorName"];
				}
			}
		}
	}

	onSorted($event) {
		this.isFiltering = false;
		this.getSupervisorCandidates($event);
		this.extendData();
		//  this.createExcelData();
		this.pageChanged(this.currentPage);
	}

	filter() {
		this.isFiltering = true;
		this.filteredData = [];
		for (let i = 0; i < this.originaldata.length; i++) {
			this.filteredData.push({
				"SupervisorName": this.originaldata[i].SupervisorName,
				"FirstName": this.originaldata[i].FirstName,
				"LastName": this.originaldata[i].LastName,
				"Contractor": this.originaldata[i].Contractor,
				"Email": this.originaldata[i].Email,
				"OrgCode": this.originaldata[i].OrgCode,
				"JobProfile": this.originaldata[i].JobProfile,
				"TimeZone": this.originaldata[i].TimeZone,
				"IsAssessed": this.originaldata[i].IsAssessed,
				"LastAssessmentCompletedDate": this.originaldata[i].LastAssessmentCompletedDate,
				"ScheduledAssessmentDate": this.originaldata[i].ScheduledAssessmentDate
			});
		}
		if (this.searchSupervisorName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["SupervisorName"].match(new RegExp(this.searchSupervisorName, "i"));
			});
		}
		if (this.searchFirstName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["FirstName"].match(new RegExp(this.searchFirstName, "i"));
			});
		}
		if (this.searchLastName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["LastName"].match(new RegExp(this.searchLastName, "i"));
			});
		}
		if (this.searchContractor != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Contractor"].match(new RegExp(this.searchContractor, "i"));
			});
		}
		if (this.searchEmail != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Email"].match(new RegExp(this.searchEmail, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		if (this.searchTimeZone != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["TimeZone"].match(new RegExp(this.searchTimeZone, "i"));
			});
		}
		if (this.searchIsAssessed != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["IsAssessed"].match(new RegExp(this.searchIsAssessed, "i"));
			});
		}
		this.report.printData = this.filteredData;
		//this.createExcelData();
		this.extendData();
		this.pageChanged(1);
		this.isFiltering = false;
	}
}

export class SupervisorCandidate {
	supervisorName: string;
	firstName: string;
	lastName: string;
	contractor: string;
	email: string;
	orgCode: string;
	jobProfile: string;
	timeZone: string;
	isAssessed: string;
	lastAssessmentCompletedDate: string;
	scheduledAssessmentDate: string
}

export class SupervisorCandidateSearchCriteria {
	sortColumn: string;
	sortDirection: string;
}



