<!--<div class="pos-f-t header" style="position: relative; border-bottom: 1px solid #ddd;">
  <nav class="navbar navbar-inverse bg-inverse navbar-toggleable-md">
	   <a class="navbar-brand" href="javascript:void(0)">
      <img [src]="logoSrc" />
    </a>
    <div class="navbar-toggler pt-0 mr-0">
      <button class="btn btn-warning-name p-2 pr-3 pl-3 rounded-0" (click)="toggleSidebar()">
        <i class="fa fa-bars">
          <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 10px; left: -90px;" [message]="navToggleBtnMessage"
            [side]="'apSouth'"></app-overlay-tooltip>
        </i>
      </button>
    </div>
    <div class="navbar-toggler" style="margin-right:70px; padding-top:0px; padding-bottom:3px; padding-left:0px; padding-right:0px;">
      <ul class="navbar-nav-toggler ml-auto mt-md-0">
        <li class="nav-item dropdown" ngbDropdown>
          <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <i class="fa fa-user">
              <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: -16px; left: -330px;" [message]="profileBtnMessage"
                [side]="'apWest'"></app-overlay-tooltip>
            </i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item capitalize-text" (click)="selectedComponentItem('Profile')">
              <i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</a>
            <button class="dropdown-item" (click)="showUserRoles()" id="showUserrole" [disabled]="disableSwitchUserRole">
              <i class="fa fa-fw fa-group"></i> Switch User Roles</button>
            <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
              <i class="fa fa-fw fa-power-off"></i> Log Out</a>
            <hr>
            <a class="dropdown-item">
              <i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
          </div>
        </li>
      </ul>
    </div>

   

    <div class="collapse navbar-collapse">

      <ul class="navbar-nav ml-auto mt-md-0">
        <li class="nav-item dropdown" ngbDropdown>
          <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <i class="fa fa-user">
              <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: -16px; left: -330px;" [message]="profileBtnMessage"
                [side]="'apWest'"></app-overlay-tooltip>
            </i>
            //<label class="mb-0"> {{ userName }} </label>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item capitalize-text" (click)="selectedComponentItem('Profile')">
              <i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</a>
            <button class="dropdown-item" (click)="showUserRoles()" id="showUserrole" [disabled]="disableSwitchUserRole">
              <i class="fa fa-fw fa-group"></i> Switch User Roles</button>
            <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
              <i class="fa fa-fw fa-power-off"></i> Log Out</a>
            <hr>
            <a class="dropdown-item">
              <i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>-->
<div class="pos-f-t header" style="position: relative; border-bottom: 1px solid #ddd;">
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="navbar-brand">
      <img [src]="logoSrc" />
    </div>
    <!--<button class="navbar-toggler" type="button" (click)="toggleSidebar()">
		<span class="navbar-toggler-icon"></span>
	</button>-->

	<button class="navbar-toggler btn btn-warning-name p-2 pr-3 pl-3 rounded-0" type="button" (click)="toggleSidebar()">
        <i class="fa fa-bars">
          <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: 10px; left: -90px;" [message]="navToggleBtnMessage"
            [side]="'apSouth'"></app-overlay-tooltip>
        </i>
      </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <form class="form-inline my-2 my-lg-0">

        <!--<a class="nav-link " href="#" id="navbarDropdown" role="button">-->
        <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0 my-2 my-sm-0 pl-3 pr-3" id="navbarDropdown" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user">
              <app-overlay-tooltip *ngIf="showOverlay" style="position: relative; top: -16px; left: -330px;" [message]="profileBtnMessage"
                [side]="'apWest'"></app-overlay-tooltip> 
            </i>            
          </button>
        <!--</a>-->
        <div class="dropdown-menu dropdown-menu-right mr-3" style="top:78%" aria-labelledby="navbarDropdown">
          <a class="dropdown-item capitalize-text" (click)="selectedComponentItem('Profile')">
              <i class="fa fa-fw fa-user"></i> {{userFirstName}}'s Profile</a>
          <button class="dropdown-item" (click)="showUserRoles()" id="showUserrole" [disabled]="disableSwitchUserRole">
              <i class="fa fa-fw fa-group"></i> Switch User Roles</button>
          <a class="dropdown-item" [routerLink]="['/user/login']" (click)="onLoggedout()">
              <i class="fa fa-fw fa-power-off"></i> Log Out</a>
          <hr>
          <a class="dropdown-item">
              <i class="fa fa-fw fa-info-circle"></i> Version - {{appVersion}}</a>
        </div>
      </form>
    </div>
  </nav>
</div>
<modal #userRoleModel [keyboard]="false" [backdrop]="'static'" style="z-index: 1170">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Select User Role</h4>
  </modal-header>
  <modal-body>
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-4" *ngFor="let userrole of userRoles">
          <div [ngClass]="selectedUserRole != null && selectedUserRole.length > 0 && selectedUserRole[0][0]==userrole[0]? 'card card-success card-block text-center' : 'card card-block text-center'"
            (click)="urmSPclick(userrole[0],userrole[1])">
            <h4>{{ userrole[1] }}</h4>
            <img class="img-thumbnail rounded mx-auto d-block" src="../assets/images/user_roles.jpg">
          </div>
          <br>
        </div>
      </div>
    </div>
  </modal-body>
  <modal-footer [show-default-buttons]="false" id="modelFooter">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="userRoleModel.dismiss()">Cancel</button>
  </modal-footer>
</modal>
