import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { ChecklistService } from './checklist.service';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';
import { CheckList } from './checklist.model'
import { CustomModalService } from '../../util/custommodal/custommodal.service';

@Component({
	selector: 'app-checklist',
	templateUrl: './allchecklist.component.html'
})

export class AllChecklistComponent extends TableComponent {
	selectedChecklistId: string;
	selectedChecklistStatus: number;
	newStatus: string;
	selectedUserRole: string;
	private isDeleteCompAllowed: boolean;
	private isModifyCompAllowed: boolean;
	isAddCompAllowed: boolean;
	private isQueryCompAllowed: boolean;
	checked: boolean = false;

	public tagList = [];
	showHideForm: FormGroup;
	public columns: Array<any> = [
		{ title: 'Checklist', name: 'checklist', filtering: { filterString: '', placeholder: '' } },
		{ title: 'Org Code', name: 'orgCode', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'statusbutton', className: 'accepter-col-action' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	constructor(private customModalService: CustomModalService, private router: Router, private userService: UserService, private sharedService: SharedService,
		private alertServices: AlertService, private checklistService: ChecklistService) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'));
		//get user capabilities
		this.isAddCompAllowed = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.isModifyCompAllowed = this.userService.isCapabilityAssigned('ModifyCompLib', this.selectedUserRole[0][1]);
		this.isDeleteCompAllowed = this.userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryCompAllowed = this.userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);

		if (this.isQueryCompAllowed) {
			this.loadAllShowChecklist();
		} else {
			//do nothing
		}

	}

	ngOnInit() {
		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + JSON.stringify(data.row.orgId));
		this.selectedChecklistId = data.row.id;
		this.selectedChecklistStatus = data.row.status;
		this.newStatus = this.selectedChecklistStatus == 1 ? "disable" : "enable";
		switch (data.column) {
			case 'editbutton':
				if (this.isModifyCompAllowed) {
					this.editButtonClickEvent(data.row.id, data.row.checklist, data.row.orgId);
				}
				break;
			case 'deletebutton':
				if (this.isDeleteCompAllowed) {
					this.deleteButtonClickEvent(this.selectedChecklistId);
				}
				break;
			case 'statusbutton':
				if (this.isModifyCompAllowed) {
					if (data.row.showHide == 0) {
						//do nothing
					} else {
						this.statusButtonClickEvent(this.selectedChecklistId, this.selectedChecklistStatus);
					}
				}
				break;
			case "showhidebutton":
				//check delete capability
				if (this.isModifyCompAllowed) {
					if (data.row.status == 0 || data.row.showHide == 0) {
						this.showHideButtonClickEvent(this.selectedChecklistId, data.row.showHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}
	private deleteButtonClickEvent(id: string) {
		let itemType = "Checklist";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	private delete() {
		this.checklistService.deleteChecklist(this.selectedChecklistId)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully removed the checklist.");
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	private editButtonClickEvent(checklistId: string, checklist: string, orgId: string) {
		this.checklistService.getAssignedChecklistTagList(checklistId)
			.subscribe(
			data => {
				//console.log('[editButtonClickEvent]' + JSON.stringify(data).toString());
				this.tagList = []
				data.assignTagList.forEach(element => {
					let item = { value: element.Description, id: element.CLTagId }
					this.tagList.push(item);
				});
				//console.log('[editButtonClickEvent]' + JSON.stringify(orgId).toString());
				let tempChecklist = new CheckList(checklistId, checklist, orgId);
				tempChecklist.tagList = this.tagList
				this.checklistService.setSelectedChecklistForEdit(tempChecklist);
				this.sharedService.change('EditChecklist');
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	private statusButtonClickEvent(id: string, status: number) {
		let itemType = "Checklist";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private changeStatus(checklistId: string, status: number) {
		this.checklistService.changeChecklistStatus(checklistId, status == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			//console.log(this.data[i].Status);
			if (this.data[i].status == 1) {
				if (this.isModifyCompAllowed) {
					this.data[i].statusbutton = '<a class="btn btn-sm edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].statusbutton = '<a class="btn btn-sm disabled edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			} else {
				if (this.isModifyCompAllowed) {
					if (this.data[i].showHide == 0) {
						this.data[i].statusbutton = '<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].statusbutton = '<a class="btn btn-sm edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				} else {
					this.data[i].statusbutton = '<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}

			if (this.isModifyCompAllowed) {
				this.data[i].editbutton = '<a class="action-btn edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].editbutton = '<a class="action-btn disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			}
			if (this.isDeleteCompAllowed) {
				this.data[i].deletebutton = '<a class="action-btn delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="action-btn disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.data[i].showHide == 1) {
				if (this.data[i].status == 0) {
					if (this.isModifyCompAllowed) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyCompAllowed) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}


	private loadAllChecklist() {
		this.checklistService.getAllChecklist()
			.subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.length; i++) {
					this.data.push({
						'id': data[i].Id,
						'checklist': data[i].Description,
						'orgCode': data[i].OrgCode == null ? "" : data[i].OrgCode,
						'orgId': data[i].OrgId == null ? "" : data[i].OrgId,
						'status': data[i].Status,
						'showHide': data[i].ShowHide,
						'statusbutton': '', 'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				// console.log(data);
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	private loadAllShowChecklist() {
		this.checklistService.getAllShowChecklist()
			.subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.length; i++) {
					this.data.push({
						'id': data[i].Id,
						'checklist': data[i].Description,
						'orgCode': data[i].OrgCode == null ? "" : data[i].OrgCode,
						'orgId': data[i].OrgId == null ? "" : data[i].OrgId,
						'status': data[i].Status,
						'showHide': data[i].ShowHide,
						'statusbutton': '', 'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				
				this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	showHide(event) {		
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryCompAllowed) {
				this.loadAllChecklist();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowChecklist();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Checklist";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	loadData() {
		if (this.checked) {
			this.loadAllChecklist();
		}
		else {
			this.loadAllShowChecklist();
		}
	}

	public showHideEvent(checklistId: string, showHide: number) {
		this.checklistService.changeChecklistShowHide(checklistId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.loadData();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			)
	}

}
