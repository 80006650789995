export class user {
	constructor(
		public email: string,
		public password?: string,
		public id?: string,
		public firstName?: string,
		public lastName?: string,
		public isAssesed?: boolean,
		public deviceId?: string,
		public reqId?: string,
		public userId?: string,
		public newPwd?: string,
		public keyCode?: string,
		public orgId?: string,
		public supervisorId?: string,		
		public jobProfieId?: string,
		public timeZone?: string,
		public isContract?: boolean,
		public supIds?: any[]
	) { }

}
