import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ReportTableComponent } from "../reporttable.component";

import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";
import { JobProfileService } from '../../jobprofile/jobprofile.service';
import { Report } from "../report.model";

@Component({
	selector: "app-opportunity-for-development-report",
	templateUrl: "./opportunityfordevelopmentreport.component.html"
})
export class OpportunityForDevelopmentReportComponent extends ReportTableComponent
	implements OnInit {

	public isLoading: boolean = false;
	public report: Report;
	private serverResultData: Array<any> = [];
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	originaldata: Array<any> = [];
	searchName: string = "";
	searchJobProfile: string = "";
	searchOrgCode: string = "";
	searchCategory: string = "";
	searchOpportunity: string = "";
	isFiltering: boolean = true;
	public columns: Array<any> = [
		{
			title: "Name",
			name: "Name"
		},
		{
			title: "Job Profile",
			name: "Job Profile"
		},
		{
			title: "Org Code",
			name: "Org Code"
		},
		{
			title: "Completed Date",
			name: "Completed Date"
		},
		{
			title: "Knowledge Check Score",
			name: "Knowledge Check Score"
		},
		{
			title: "Competency Score",
			name: "Competency Score"
		},
		{
			title: "Category",
			name: "Category"
		},
		{
			title: "Task / Opportunity",
			name: "Opportunity"
		}
	];

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
		this.report = this.reportService.getSelectedReport();
	}

	ngOnInit() {
		this.loadOpportunityList(this.report.reportData);
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.config.report.reportDataList = this.filteredData
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	loadOpportunityList(data: any) {
		for (let i = 0; i < data.length; i++) {
			let knowPre
			if ((data[i].SelfAssessmentCorrect == 0 && data[i].SelfAssessmentTotal == 0)) {
				knowPre = 0
			} else {
				knowPre = Math.round((data[i].SelfAssessmentCorrect / data[i].SelfAssessmentTotal) * 100)
			}
			var tempItem = {
				"Name": data[i].FirstName + " " + data[i].LastName,
				"JobProfile": data[i].JobProfile == null ? "" : data[i].JobProfile,
				"OrgCode": data[i].OrgCode == null ? "" : data[i].OrgCode,
				"CompletedDate": new Date(new Date(data[i].LastAssessmentCompletedDate).getUTCFullYear(), new Date(data[i].LastAssessmentCompletedDate).getUTCMonth(), new Date(data[i].LastAssessmentCompletedDate).getUTCDate()).toLocaleDateString(),
				"KnowledgeCheckScore": knowPre + '%',
				"CompetencyScore": data[i].AssessmentScore == null ? "" : data[i].AssessmentScore + '%',
				"Category": data[i].category == null ? "" : data[i].category,
				"Opportunity": data[i].OpportunityValue == null ? "" : data[i].OpportunityValue
			}
			this.filteredData.push(tempItem);
			this.originaldata.push(tempItem);
		}
		this.report.columns = this.columns;
		this.config.report = this.report;
		this.serverResultData = data;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.total = this.filteredData.length;
		this.isFiltering = false;
		this.pageChanged();

	}

	filter() {
		this.isFiltering = true;
		for (var i = 0; i < this.originaldata.length; i++) {
			this.filteredData = this.originaldata.map(x => Object.assign({}, x));
		}
		if (this.searchName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Name"].match(new RegExp(this.searchName, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchCategory != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Category"].match(new RegExp(this.searchCategory, "i"));
			});
		}
		if (this.searchOpportunity != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["Opportunity"].match(new RegExp(this.searchOpportunity, "i"));
			});
		}
		this.pageChanged(1);
		this.isFiltering = false;
	}

	onSorted($event) {
		this.isFiltering = false;
		console.log($event)
		this.sortData($event)
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: OpportunityForDevelopmentSearchCriteria) {
		if (criteria.sortColumn == 'Name' || criteria.sortColumn == 'JobProfile' || criteria.sortColumn == 'OrgCode' || criteria.sortColumn == 'Category' || criteria.sortColumn == 'Opportunity') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	sortData(criteria: OpportunityForDevelopmentSearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push({
					"Name": this.originaldata[i].Name,
					"JobProfile": this.originaldata[i].JobProfile,
					"OrgCode": this.originaldata[i].OrgCode,
					"CompletedDate": this.originaldata[i].CompletedDate,
					"KnowledgeCheckScore": this.originaldata[i].KnowledgeCheckScore,
					"CompetencyScore": this.originaldata[i].CompetencyScore,
					"Category": this.originaldata[i].Category,
					"Opportunity": this.originaldata[i].Opportunity
				});
			}
			if (this.searchName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Name"].match(new RegExp(this.searchName, "i"));
				});
			}
			if (this.searchJobProfile != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
				});
			}
			if (this.searchOrgCode != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
				});
			}
			if (this.searchCategory != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Category"].match(new RegExp(this.searchCategory, "i"));
				});
			}
			if (this.searchOpportunity != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["Opportunity"].match(new RegExp(this.searchOpportunity, "i"));
				});
			}
		}
	}
}

export class OpportunityForDevelopmentSearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}