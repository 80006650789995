<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Reorder Core Competency</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>
<alert></alert>
<div *ngFor="let cc of data | orderBy : 'orderNum'; let t = index ">
	<div class="col-sm-12 mx-auto mt-1 ">
		<div class="card p-2 card-grey-lighten-3">
			<div class="float-left mt-1, mb-1">
				<div *ngIf="(data.length > 1) && (t == 0)" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
				<button prevent-parent-click *ngIf="(data.length > 1) && (t != 0)" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
				 (click)="moveUpCoreCompetency(data, cc)">
					<i class="fa fa-arrow-up" aria-hidden="true"></i>
				</button>
				<button prevent-parent-click *ngIf="(data.length > 1) && (t != (data.length - 1))" type="button" class="btn btn-sm btn-outline-warning mr-1 ml-1 p-1"
				 (click)="moveDownCoreCompetency(data, cc)">
					<i class="fa fa-arrow-down" aria-hidden="true"></i>
				</button>
				<div *ngIf="(data.length > 1) && (t == (data.length - 1))" class="btn btn-sm border-0 mr-2 ml-2 p-2"></div>
				<label class="mb-0">{{ cc.coreCompetency }}</label>
			</div>
		</div>
	</div>
</div>