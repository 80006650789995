import { Component } from '@angular/core';
import { AssessmentService } from '../../../../assessment/assessment.service';
import { AlertService } from '../../../../util/alert/alert.service';
import 'chart.js'
import 'chart.piecelabel.js';

@Component({
	selector: 'global-assessment-progress',
	templateUrl: './globalassessmentprogress.component.html',
	styleUrls: ['./globalassessmentprogress.component.scss']
})
export class GlobalAssessmentProgressComponent {
	public pieChartLabels: string[] = ['Overdue Assessments', 'Completed Assessments', 'Never Assessed'];
	public pieChartColors: Array<any> = [{ backgroundColor: ['#ffc107', '#28a745', '#dc3545'] }];
	public pieChartData: number[] = [0, 0, 0];
	public pieChartType: string = 'pie';
	show: boolean = false;
	showPieChart: boolean = false;
	itemsPerPage: number = 5;
	currentPage: number = 1;
	maxNoOfPages: number = 5;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	public data: Array<any> = [];
	public filteredData: Array<any> = [];
	public tableName: string = 'Overdue Assessments';
	public showDate: boolean = false
	public pieChartOptions: any = {
		pieceLabel: {
			render: 'percentage',
			fontSize: 12,
			fontColor: ['#000000', '#000000', '#000000'],
			position: 'outside',
			showActualPercentages: true
		},
		legend: { 
			display: false,
			position: 'right' 
		}
	};

	constructor(private assessmentService: AssessmentService, private alertService: AlertService) {
		this.pieChartData = assessmentService.getPieChartData();

		if (this.pieChartData.length == 0) {
			this.showPieChart = false
		} else {
			this.showPieChart = true;
		}
	}

	ngOnInit() {
		// this.assessmentService.getGlobalAssessmentProgress()
		// 	.subscribe(
		// 	data => {
		// 		console.log(data);
		// 		this.pieChartData = [data.missingAssessmentsCount[0].Count, data.neverAssessedCount[0].Count, data.scheduledAssessmentsCount[0].Count, data.uptodateAssessmentsCount[0].Count];
		// 	},
		// 	error => {
		// 		console.log(error);
		// 	}
		// 	);
	}
	// events
	public chartClicked(e: any): void {
		if (e.active.length == 0) {
			return;
		}
		
		let index = e.active[0]._index;
		this.showDate = false
		switch (index) {
			case 0:
				this.tableName = 'Overdue assessments candidate list'
				this.assessmentService.getOverdueAssessmentCandidateList().subscribe(
					data => {
						this.showDate = true
						this.loadTableData(data.candidateList)
						this.show = true;
					},
					error => {
						this.show = true;
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
				break
			case 1:
				this.tableName = 'Completed assessments candidate list'
				this.assessmentService.getCompletedAssessmentCandidateList().subscribe(
					data => {
						this.showDate = true
						this.loadTableData(data.candidateList)
						this.show = true;
					},
					error => {
						this.show = true;
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
				break
			case 2:
				this.tableName = 'Never Assessed candidate list'
				this.assessmentService.getNeverAssessedCandadidateList().subscribe(
					data => {
						this.loadTableData(data.candidateList)
						this.show = true;
					},
					error => {
						this.show = true;
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
				break
			default:
				break
		}

	}

	loadTableData(data) {
		this.data = [];
		this.currentPage = 1;
		for (let i = 0; i < data.length; i++) {
			var completedDate = data[i].AssessmentCompletedDate
			if (completedDate == null) {
				completedDate = null;
			} else {
				completedDate = new Date(new Date(completedDate).getUTCFullYear(), new Date(completedDate).getUTCMonth(), new Date(completedDate).getUTCDate()).toLocaleDateString();
			}
			this.data.push({
				firstName: data[i].FirstName,
				lastName: data[i].LastName,
				email: data[i].Email,
				orgCode: data[i].OrgCode == null ? "" : data[i].OrgCode,
				supervisorName:
					data[i].SupFirstName == null
						? ""
						: data[i].SupFirstName + " " + data[i].SupLastName,
				jobProfile: data[i].JobProfile == null ? "" : data[i].JobProfile,
				completedDate: completedDate
			});
		}
		this.total = this.data.length;
		this.filteredData = this.data;
		this.pageChanged();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		// this.changeDetectorRef.detectChanges();
	}

	public chartHovered(e: any): void {
		// console.log(e);
	}

	showChart() {
		this.show = false;
	}
}