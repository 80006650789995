import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { CoreCompetencyTagService } from './corecompetencytag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-add-corecompetencytag',
	templateUrl: './addcorecompetencytag.component.html'
})

export class AddCoreCompetencyTagComponent implements OnInit {
	addCoreCompetencyTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private coreCompetencyTagService: CoreCompetencyTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

	}

	ngOnInit() {
		this.addCoreCompetencyTag = new FormGroup({
			coreCompetencyTag: new FormControl(null, Validators.required)
		});
	}

	cancel() {
		this.sharedService.change('CoreCompetencyTag');
	}

	saveAndNew() {

		this.coreCompetencyTagService.addCoreCompetencyTag(this.addCoreCompetencyTag.value.coreCompetencyTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully added the core competency tag.");
				// this.addCoreCompetencyTag.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	save() {

		this.coreCompetencyTagService.addCoreCompetencyTag(this.addCoreCompetencyTag.value.coreCompetencyTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.cancel();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

}
