import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Performance } from './performance.model';

@Injectable()
export class PerformanceService {

	constructor(private http: Http, private router: Router) { }

	//selected preformance for edit
	private selectedPerformanceForEdit: Performance;
	public getSelectedPerformanceForEdit(): Performance {
		return this.selectedPerformanceForEdit;
	}
	public setSelectedPerformanceForEdit(performance: Performance): void {
		this.selectedPerformanceForEdit = performance;
	}

	//get all performance
	getAllPerformance() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performance/getAllPerformance' + token, { headers: headers })
			.map(res => res.json());
	}

	//get active performances
	getActivePerformances() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performance/getActivePerformances' + token, { headers: headers })
			.map(res => res.json());
	}

	//update performance by performance id
	updatePerformanceByPerformanceId(performance: Performance) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(performance);
		return this.http.post(environment.server_URL + '/performance/updatePerformanceByPerformanceId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update performance by performance id
	updatePerformanceStatusByPerformanceId(performance: Performance) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(performance);
		return this.http.post(environment.server_URL + '/performance/updatePerformanceStatusByPerformanceId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add new performance
	addNewPerformance(performance: Performance) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(performance);
		return this.http.post(environment.server_URL + '/performance/addNewPerformance' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete performance
	deletePerformance(performance: Performance) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(performance);
		return this.http.post(environment.server_URL + '/performance/deletePerformance' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get assigned performance taglist
	getAssignedPerformanceTagList(pId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'pId': pId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/performance/getAssignedPerformanceTagList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActivePerformanceWithTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performance/getActivePerformanceWithTags' + token, { headers: headers })
			.map(res => res.json());
	}

	getAllShowPerformance() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performance/getAllShowPerformance' + token, { headers: headers })
			.map(res => res.json());
	}

	changePerformanceShowHide(performanceId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'performanceId': performanceId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/performance/changePerformanceShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}


}
