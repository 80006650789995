<div class="col-lg-12">
    <div class="col" style="overflow:hidden">
        <div class="float-left">
            <h4>Edit Performance Tag</h4>
        </div>
        <div class="float-right">

        </div>
    </div>
    <hr />
</div>


<div class="col-lg-12 mx-auto">
    <form [formGroup]="editPerformanceTag" (ngSubmit)="onSubmit()">
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Performance Tag <sup><i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i></sup></label>
            <div class="col-sm-10">
                <textarea noSpaces maxlength="100" autosize type="text" class="form-control" id="performanceTag" formControlName="performanceTag" name="performanceTag"></textarea>
            </div>
        </div>
        <alert></alert>
        <!--buttons-->
        <div class="form-group row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-3">
                <button type="submit" class="btn btn-warning btn-block" [disabled]="!editPerformanceTag.valid">Save</button>
            </div>
            <div class="col-sm-3">
                <button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('AllPerformanceTag')">Cancel</button>
            </div>
        </div>
    </form>
</div>