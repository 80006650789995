import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { Report } from './report.model'

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ReportService {

	constructor(private http: Http, private router: Router) { }

	//selected report
	private selectedReport: Report
	public getSelectedReport(): Report {
		return this.selectedReport
	}
	public setSelectedReport(report: Report): void {
		this.selectedReport = report
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		// const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		// this.saveAsExcelFile(excelBuffer, excelFileName);
		XLSX.writeFile(workbook, excelFileName+ EXCEL_EXTENSION);
	}

	private saveAsExcelFile(buffer: any, fileName: String) {
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
	}

	getReportListByUserRoleId(roleId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'roleId': roleId });
		return this.http.post(environment.server_URL + '/report/getReportListByUserRoleId' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
