import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { Knowledge } from './knowledge.model';
import { KnowledgeAnswer } from './knowledgeanswer';

@Injectable()
export class KnowledgeService {
	// public curKnowledgeId: string = null;
	// public tagList: any[];

	constructor(private http: Http, private router: Router) { }

	private selectedKnowledgeForEdit: Knowledge;

	public getSelectedKnowledgeForEdit(): Knowledge {
		return this.selectedKnowledgeForEdit;
	}
	public setSelectedKnowledgeForEdit(knowlegde: Knowledge): void {
		this.selectedKnowledgeForEdit = knowlegde;
	}

	addKnowledge(knowledge: Knowledge, answers: KnowledgeAnswer[], uploadFile: Array<any>, uploadAnswerFile: Array<any>, knowledgeId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledge': knowledge, 'answers': answers, 'uploadFile': uploadFile, 'uploadAnswerFile': uploadAnswerFile, 'knowledgeId': knowledgeId});		
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledge/addKnowledge' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editKnowledge(knowledge: Knowledge, answers: KnowledgeAnswer[], uploadFile: Array<any>, uploadAnswerFile: Array<any>) {
		//console.log('[editKnowledge.service]'+JSON.stringify(knowledge).toString())
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledge': knowledge, 'answers': answers, 'uploadFile': uploadFile, 'uploadAnswerFile': uploadAnswerFile });		
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledge/editKnowledge' + token, body, { headers: headers })
			.map(res => res.json());
	}

	removeKnowledge(knowledgeId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledgeId': knowledgeId });		
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledge/removeKnowledgeById' + token, body, { headers: headers })
			.map(res => res.json());
	}

	// setCurKnowledgeId(id: string, tagList: any[]) {
	// 	this.curKnowledgeId = id;
	// 	this.tagList = tagList;
	// }

	getAllKnowledgeElements() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledge/getAllKnowledge' + token, { headers: headers })
			.map(res => res.json());


	}

	getKnowledgeById(knowledgeId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledgeId': knowledgeId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledge/getKnowledgeById' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActliveKnowledgeByOrgCode(orgCodeId) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'orgCodeId': orgCodeId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledge/getAllActiveKnowledgeByOrgCode' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeKnowledgeStatus(knowledgeId: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledgeId': knowledgeId, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledge/changeKnowledgeStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get assigned knowledge taglist
	getAssignedKnowledgeTagList(kId: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'kId': kId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledge/getAssignedKnowledgeTagList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActiveKnowledgeWithTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledge/getActiveKnowledgeWithTags' + token, { headers: headers })
			.map(res => res.json());
	}

	getAllShowKnowledgeElements() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledge/getAllShowKnowledge' + token, { headers: headers })
			.map(res => res.json());
	}

	changeKnowledgeShowHide(knowledgeId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'knowledgeId': knowledgeId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledge/changeKnowledgeShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

	makeFileRequests(params: Array<string>, files: Array<File>, knowledgeId: any, ids: Array<any>) {
		var formData: any = new FormData();
		formData.append('knowledgeId', knowledgeId)		//
		for (var i = 0; i < files.length; i++) {
			formData.append('answerId', ids[i].answerId)
			formData.append("uploads[]", files[i], files[i].name);
		}
		return this.http.post(environment.server_URL + '/knowledge/uploadMultifiles', formData)
			.map(res => res.json());
	}

	makeFileRequest(params: Array<string>, files: Array<File>, knowledgeId: any, oldFileType: any) {
		var formData: any = new FormData();
		formData.append('knowledgeId', knowledgeId)	
		formData.append('oldFileType', oldFileType)
		for (var i = 0; i < files.length; i++) {
			formData.append("uploads[]", files[i], files[i].name);
		}
		return this.http.post(environment.server_URL + '/knowledge/uploadfile', formData)
			.map(res => res.json());
	}

}
