<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<button type="button" class="btn btn-outline-warning btn-sm pointer-cursor mr-1 ml-1 float-left" (click)="showReportList()">
				<i class="fa fa-arrow-left fa-fw"></i>
			</button>
			<h4 class="float-right ml-2">
				<h6 class="float-left mr-2 mb-0 mt-1">
					<span class="badge badge-warning">{{ report.reportType }}</span>
				</h6>
				<label class="mb-0">{{ report.reportName }}</label>
			</h4>
		</div>
		<div class="float-right">
			<div *ngIf="!isiPad">
				<button type="button" class="btn btn-outline-warning btn-sm mr-1" (click)="exportToExcel(report.reportName, report.reportDataList, report.reportItem)">
					<i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
				</button>
				<button type="button" class="btn btn-outline-warning btn-sm" (click)="print()">
					<i class=" fa fa-print fa-fw "></i>&nbsp;Print
				</button>
			</div>
			<div *ngIf="isiPad">
				<button type="button" class="btn btn-outline-warning btn-sm" (click)="print()">
					<i class=" fa fa-print fa-fw "></i>&nbsp;Print
				</button>
			</div>
		</div>
	</div>
	<div class="col" style="overflow:hidden" *ngIf="report.reportName === constants.PERCENT_ASSESSMENT_NA_REPORT">
		<br>
		<div class="float-left">
			<input (change)="showMostRecentAssessment($event)" type="checkbox" id="recentAccessment" 
			 name="recentAccessment" [(ngModel)]="recentAssessment" [ngModelOptions]="{standalone: true}"> Most recent assessment
		</div>
		<div class="float-right"></div>
	</div>
	<hr /> 
</div>


<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.MOST_MISSED_KNOWLEDGE_QUESTION || report.reportName === constants.MOST_MISSED_PERFORMANCE || report.reportName === constants.MOST_MISSED_KNOWLEDGE">
	<app-missed-assessment-data-report></app-missed-assessment-data-report>
</div>

<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.MOST_MISSED_TASKS">
	<app-missed-tasks-report></app-missed-tasks-report>
</div>

<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.NEVER_ASSESSED_REPORT || report.reportName === constants.OVERDUE_ASSESSMENTS_REPORT">
	<app-never-assessed-report></app-never-assessed-report>
</div>

<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.LIST_ALL_CANDIDATES && report.reportType === 'Supervisor'">
	<app-supervisor-candidate-report></app-supervisor-candidate-report>
</div>

<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.LIST_ALL_CANDIDATES && report.reportType === 'Candidate'">
	<app-candidates-report></app-candidates-report>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.LIST_ALL_SUPERVISORS">
	<app-supervisor-report></app-supervisor-report>
</div>

<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.LIST_ALL_TASKS ">
	<app-task-report></app-task-report>
</div>

<div class="col-lg-12 mx-auto " *ngIf="report.reportName === constants.LIST_ALL_CHECKLIST ">
	<app-checklistreport></app-checklistreport>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.LIST_ALL_PERFORMANCE">
	<app-performancereport></app-performancereport>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.LIST_ALL_KNOWLEDGE">
	<app-knowledgereport></app-knowledgereport>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.PERCENT_ASSESSMENT_NA_REPORT">
	<app-percent-na-report></app-percent-na-report>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.ROLE">
	<app-role-report></app-role-report>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.JOBPROFILE_TASKS">
	<app-jobprofile-tasks-report></app-jobprofile-tasks-report>
</div>

<div class="col-lg-12 mx-auto" *ngIf="report.reportName === constants.OPPORTUNITY_FOR_DEVELOPMENT">
	<app-opportunity-for-development-report></app-opportunity-for-development-report>
</div>



