import { Component, OnInit } from '@angular/core'

import { SelectComponent } from 'ng2-select'
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms"

import { JobProfileService } from '../jobprofile.service'
import { UserService } from '../../user/user.service'
import { AlertService } from '../../util/alert/alert.service'
import { AssessmentService } from '../../assessment/assessment.service'
import { error } from 'util';

import { JobProfile } from '../jobprofile.model'
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model'
import { Task } from '../../complib/task/task.model'

@Component({
	selector: 'app-jobprogressionplanning',
	templateUrl: './jobprogressionplanning.component.html',
	styleUrls: ['./jobprogressionplanning.component.scss']
})
export class JobProgressionPlanningComponent implements OnInit {

	public jobProfileList: Array<any> = []
	public userList: Array<any> = []
	public planningNCUForm: FormGroup
	public planningCUForm: FormGroup
	public isCandidateUser: boolean = false
	private selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'))
	public candidateUser: any

	public showResult: boolean = false;

	public jobProfile: JobProfile;
	public assessmentScore: number = 0;
	public jobProfileDescription: string;
	fromSuccessionPlanning: boolean = false;
	selectedJobProfileId: any = "";
	selectedUserId: any = null;
	selectedUser: any = "";

	constructor(private jobProfileService: JobProfileService, private userService: UserService,
		private assessmentService: AssessmentService, private alertService: AlertService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.getActiveAndAssessedUsers()
		this.getActiveJobProfiles()
		if (this.selectedUserRole[0][0] == '1') {
			this.isCandidateUser = true
			this.getUserDetail()
		} else {

		}
		let startupData = this.assessmentService.getJobProgressionStartupData()
		if (startupData != null) {
			this.fromSuccessionPlanning = true;
			this.selectedJobProfileId = startupData.jobProfileId;
			this.selectedUserId = startupData.userId;
			this.selectedUser = startupData.userName;
			this.assessmentService.setJobProgressionStartupData(null)
			this.getJobProfileDetail(this.selectedJobProfileId, this.selectedUserId)
		}
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.selectedUserRole[0][0] == '1') {
			this.planningCUForm = new FormGroup({
				'user': new FormControl(null, Validators.required),
				'jobProfileCU': new FormControl(null, Validators.required)
			})
		} else {
			this.planningNCUForm = new FormGroup({
				'user': new FormControl(null, Validators.required),
				'jobProfileNCU': new FormControl(null, Validators.required)
			})
		}
	}

	/**
	 * START - services call methods
	 */
	private getActiveJobProfiles() {
		this.jobProfileService.getAllActiveJobProfiles().subscribe(
			data => {
				this.jobProfileList = []
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobProfileList.push({
						'id': data.jobProfileList[i].Id,
						'text': data.jobProfileList[i].Description
					});
					if (data.jobProfileList[i].Id == this.selectedJobProfileId) {
						this.jobProfileDescription = data.jobProfileList[i].Description;
						this.planningNCUForm.get("jobProfileNCU").setValue([{ id: data.jobProfileList[i].Id, text: data.jobProfileList[i].Description }])
					}
				}
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		);
	}

	private getActiveAndAssessedUsers() {
		var supervisorId = '';
		if (this.selectedUserRole[0][1] == 'Supervisor') {
			supervisorId = localStorage.getItem('userId');
		}

		this.userService.getActiveAndAssessedUsers(supervisorId).subscribe(
			data => {
				this.userList = []
				for (var i = 0; i < data.userlist.length; i++) {
					this.userList.push({
						'id': data.userlist[i].Id,
						'text': data.userlist[i].UserName
					})
					if (data.userlist[i].Id == this.selectedUserId) {
						this.planningNCUForm.get("user").setValue([{ id: data.userlist[i].Id, text: data.userlist[i].UserName }])
					}
				}
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		)
	}

	private getUserDetail() {
		this.userService.getUserData().subscribe(
			data => {
				this.candidateUser = data[0].FirstName + ' ' + data[0].LastName
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		);
	}

	private getJobProfileDetail(jpId: string, candidateId: string) {
		let jobProfile: JobProfile = new JobProfile(jpId)
		let ccList
		this.jobProfileService.getJobProfileDetail(jobProfile).subscribe(
			data => {
				ccList = this.jobProfileService.generateCCListFromData(data)
				jobProfile.coreCompetencyList = ccList
				this.getLastAssessmentHierarchy(candidateId, jobProfile)
				// 
				// this.jobProfile = jobProfile
				// this.showResult = true
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		)
	}

	private getLastAssessmentHierarchy(candidateId: string, jobProfile: JobProfile) {
		let assessmentJP: JobProfile = new JobProfile('001')
		let assccList
		this.assessmentService.getLastAssessmentCCAndTaskScore(candidateId).subscribe(
			data => {
				// console.log('[getLastAssessmentHierarchy] (data) ' + JSON.stringify(data));
				assccList = this.jobProfileService.generateAssessmentScoreJP(data)
				assessmentJP.coreCompetencyList = assccList
				this.compareAndAddScore(jobProfile, assessmentJP)
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		)
	}
	/**
	 * END - services call methods
	 */

	private compareAndAddScore(jp: JobProfile, assJp: JobProfile) {
		// console.log('[compareAndAddScore] (jp) ' + JSON.stringify(jp.coreCompetencyList));
		// console.log('[compareAndAddScore] (assJp) ' + JSON.stringify(assJp.coreCompetencyList));
		let total = 0;
		this.assessmentScore = 0;
		jp.coreCompetencyList.forEach(ccItem => {
			ccItem.assessmentScore = 0;
			if (this.checkValueExists(assJp.coreCompetencyList, ccItem.id)) {
				let assCCItem = this.getItemByIdFromArray(assJp.coreCompetencyList, ccItem.id)
				ccItem.assessmentScore = assCCItem.assessmentScore

				let ccTotal = 0;
				let criticalityTotal = 0;
				ccItem.taskList.forEach(task => {
					task.assessmentScore = 0;
					if (task.isTaskGroup == 0) {
						if (this.checkValueExists(assCCItem.taskList, task.id)) {
							let assTaskItem = this.getItemByIdFromArray(assCCItem.taskList, task.id)
							let tScore = assTaskItem.assessmentScore
							if (!tScore) {
								tScore = 0
							}
							task.assessmentScore = tScore
							ccTotal = ccTotal + (tScore * this.getGetCriticalityValue(task.criticalityValue))
							criticalityTotal = criticalityTotal + this.getGetCriticalityValue(task.criticalityValue)
						} else {
							criticalityTotal = criticalityTotal + this.getGetCriticalityValue(task.criticalityValue)
						}
					}
					else {
						task.taskList.forEach(task => {
							task.assessmentScore = 0;

							if (this.checkValueExists(assCCItem.taskList, task.id)) {
								let assTaskItem = this.getItemByIdFromArray(assCCItem.taskList, task.id)
								let tScore = assTaskItem.assessmentScore
								if (!tScore) {
									tScore = 0
								}
								task.assessmentScore = tScore
								ccTotal = ccTotal + (tScore * this.getGetCriticalityValue(task.criticalityValue))
								criticalityTotal = criticalityTotal + this.getGetCriticalityValue(task.criticalityValue)
							} else {
								criticalityTotal = criticalityTotal + this.getGetCriticalityValue(task.criticalityValue)
							}

						});
					}
				});
				if (criticalityTotal != 0) {
					ccItem.assessmentScore = Math.round((ccTotal / criticalityTotal));
					total = total + (ccTotal / criticalityTotal)
				}
			} else {
				ccItem.taskList.forEach(task => {
					if (task.isTaskGroup == 0) {
						task.assessmentScore = 0;
					}
					else {
						task.taskList.forEach(task => {
							task.assessmentScore = 0;
						});

					}
				});
			}
		});
		if (jp.coreCompetencyList.length > 0) {
			this.assessmentScore = Math.round((total / jp.coreCompetencyList.length));
		}
		this.jobProfile = jp
		// console.log('[compareAndAddScore] (jobProfile) ' + JSON.stringify(jp.coreCompetencyList));
		this.showResult = true
	}

	private getGetCriticalityValue(value: any) {
		if (value == 1) {
			return 1;
		} else if (value == 2) {
			return 5;
		} else if (value == 3) {
			return 10;
		}
		else {
			return 0;
		}
	}

	//find item by id in arrayList
	private getItemByIdFromArray(arrayList: any, itemId: string): any {
		return arrayList.find(item => item.id === itemId);
	}

	//check given value exists in a given array
	private checkValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.id == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	/**
	 * START - select remove options
	 */
	private selectUser(option: any) {
		this.showResult = false
	}

	private removeUser(option: any) {
		this.showResult = false
	}

	private selectJobProfile(option: any) {
		this.showResult = false
	}

	private removeJobProfile(option: any) {
		this.showResult = false
	}
	/**
	 * END - select remove options
	 */

	private showProgressPlanning() {
		let jpId
		let userId
		if (this.selectedUserRole[0][0] == '1') {
			jpId = this.planningCUForm.get("jobProfileCU").value[0].id;
			this.jobProfileDescription = this.planningCUForm.get("jobProfileCU").value[0].text;
			userId = localStorage.getItem('userId')
		} else {
			jpId = this.planningNCUForm.get("jobProfileNCU").value[0].id;
			this.jobProfileDescription = this.planningNCUForm.get("jobProfileNCU").value[0].text;
			userId = this.planningNCUForm.get("user").value[0].id;
		}
		this.getJobProfileDetail(jpId, userId)
	}


}