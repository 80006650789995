import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class KnowledgeTagService {
	public curKnowledgeId: string = null;
	public curKnowledge: string = null;

	constructor(private http: Http, private router: Router) { }

	addKnowledgeTag(knowledgeTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'knowledgeTag': knowledgeTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledgetag/addKnowledgeTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editKnowledgeTag(compTagId: string, knowledgeTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'knowledgeTag': knowledgeTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledgetag/editKnowledgeTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeKnowledgeTagStatus(compTagId: string, tagStatus: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'tagStatus': tagStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/knowledgetag/changeKnowledgeTagStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllKnowledgeTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledgetag/getAllKnowledgeTag' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveKnowledgeTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledgetag/getActiveKnowledgeTag' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteKnowledgeTag(compTagId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledgetag/removeKnowledgeTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setCurretData(compTagId: string, knowledgeTag: string) {
		this.curKnowledgeId = compTagId;
		this.curKnowledge = knowledgeTag;
	}

	getAllShowKnowledgeTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledgetag/getAllShowKnowledgeTag' + token, { headers: headers })
			.map(res => res.json());
	}

	changeKnowledgeTagShowHide(knowledgeId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'knowledgeId': knowledgeId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/knowledgetag/changeKnowledgeTagShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
