import { ReportTableComponent } from "../reporttable.component";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { Report } from "../report.model";
import { ReportService } from "../report.service";

@Component({
	selector: "app-performancereport",
	templateUrl: "./performancereport.component.html"
})
export class PerformanceReportComponent extends ReportTableComponent
	implements OnInit {
	public report: Report;
	public excelData: any[];
	public columns: Array<any> = [
		{
			title: "Performance",
			name: "Performance",
			filtering: { filterString: "" }
		},
		{
			title: "Tags",
			name: "Tags",
			filtering: { filterString: "" }
		}
	];

	private serverResultData: Array<any> = [];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
	}

	ngOnInit() {
		this.loadActivePerformanceWithTags(this.report.reportData);
	}

	public loadActivePerformanceWithTags(data: any) {
		this.excelData = [];
		for (let i = 0; i < data.length; i++) {
			this.data.push({
				Performance: data[i].Performance,
				Tags: data[i].Tags == null ? "" : data[i].Tags
			});

			// this.excelData.push({
			//   Performance: data[i].Performance,
			//   Tags: data[i].Tags == null ? "" : data[i].Tags
			// });
		}
		this.serverResultData = data;
		this.report.columns = this.columns;
		//  this.report.reportDataList = this.excelData;
		this.config.report = this.report;
		this.pageCount = Math.ceil(
			this.serverResultData.length / this.itemsPerPage
		);
		this.onChangeTable(this.config);
	}
}
