import { OnInit, AfterViewInit, Component, ViewChild, ElementRef } from "@angular/core";
import { AssessmentService } from "../../../assessment/assessment.service";
import { SharedService } from "../../../home/shared.service";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from "rxjs";

@Component({
	selector: 'app-employeescomppercentage',
	templateUrl: './employeescomppercentage.component.html',
	styleUrls: ['./employeescomppercentage.component.scss']
})

export class EmployeesCompPercentageComponent implements OnInit, AfterViewInit {
	@ViewChild('employeesCompPercentage', { static: true })
	tblElement: ElementRef;
	public type = 'Core Competency';
	public userId = localStorage.getItem('userId');
	public data = [];
	public options = [];
	private timer: any;

	constructor(private assessmentService: AssessmentService, private sharedService: SharedService, private sanitizer: DomSanitizer) {
		this.options = ["Org Code", "Job Profile", "Discipline", "Core Competency"];
		this.data = this.assessmentService.getEmployeeCompPercentagedata();
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
		this.sharedService.employeeCompPercentageHeightChange(height);
	}

	setType(type) {
		this.type = type;
		this.loadEmployeesCompetencyPercentageData(this.type, this.userId);
	}

	public loadEmployeesCompetencyPercentageData(type, supervisorId) {
		this.assessmentService.getEmployeeCompPercentageData(type, supervisorId)
			.subscribe(
			data => {				
				this.data = [];
				for (var i = 0; i < data.Assessments.length; i++) {
					let total = data.Assessments[i].Red + data.Assessments[i].Green + data.Assessments[i].Yellow;
					let redActual = Math.round(((data.Assessments[i].Red / total) * 100) * 100) / 100;
					let redPercentage = Math.round((data.Assessments[i].Red / total) * 100);
					let yellowActual = Math.round(((data.Assessments[i].Yellow / total) * 100) * 100) / 100;
					let yellowPercentage = Math.round((data.Assessments[i].Yellow / total) * 100);
					let greenActual = Math.round(((data.Assessments[i].Green / total) * 100) * 100) / 100;
					let greenPercentage = Math.round((data.Assessments[i].Green / total) * 100);

					let result = this.assessmentService.getCalculatedPercentage(redActual, yellowActual, greenActual)
					redActual = result.redPercentage
					yellowActual = result.yellowPercentage
					greenActual = result.greenPercentage

					this.data.push({
						'Description': data.Assessments[i].Description, 'red': data.Assessments[i].Red, 'yellow': data.Assessments[i].Yellow, 'green': data.Assessments[i].Green,
						'redPercentage': redPercentage, 'yellowPercentage': yellowPercentage, 'greenPercentage': greenPercentage,
						'redActual': redActual, 'yellowActual': yellowActual, 'greenActual': greenActual
					})
				}
				this.timer = Observable.timer(500)
				this.timer.subscribe(t => {
					let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
					this.sharedService.employeeCompPercentageHeightChange(height);
				})

			});
	}

	getToolTip(count) {
		return "Assessments: " + count;
	}
}