import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class RemediationTagService {
	public curRemediationId: string = null;
	public curRemediation: string = null;

	constructor(private http: Http, private router: Router) { }

	addRemediationTag(remediationTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'remediationTag': remediationTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/remediationtag/addRemediationTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editRemediationTag(remId: string, remediationTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'remId': remId, 'remediationTag': remediationTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/remediationtag/editRemediationTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeRemediationTagStatus(remId: string, tagStatus: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'remId': remId, 'tagStatus': tagStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/remediationtag/changeRemediationTagStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllRemediationTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediationtag/getAllRemediationTag' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveRemediationTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediationtag/getActiveRemediationTag' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteRemediationTag(remId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'remId': remId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediationtag/removeRemediationTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setCurretData(remId: string, remediationTag: string) {
		this.curRemediationId = remId;
		this.curRemediation = remediationTag;
	}

	getAllShowRemediationTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediationtag/getAllShowRemediationTag' + token, { headers: headers })
			.map(res => res.json());
	}

	changeRemediationTagShowHide(remediationId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'remediationId': remediationId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediationtag/changeRemediationTagShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
