import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { remediation } from './remediation';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

@Injectable()
export class RemediationService {

	constructor(private http: Http, private router: Router) { }

	private SelectedRemediationForEdit: remediation;

	public setSelectedRemediationForEdit(remediation: remediation): void {
		this.SelectedRemediationForEdit = remediation;
	}

	public getSelectedRemediationForEdit(): remediation {
		return this.SelectedRemediationForEdit;
	}

	// Get all posts from the API
	getRemediation() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/getRemediation' + token, { headers: headers })
			.map(res => res.json());
	}

	// change status
	changeStatus(id: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'id': id, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/changeStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete remediation
	deleteRemediation(Id: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'Id': Id });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/deleteRemediation' + token, body, { headers: headers })
			.map(res => res.json());
	}

	makeFileRequest(params: Array<string>, files: Array<File>) {
		var formData: any = new FormData();

		for (var i = 0; i < files.length; i++) {
			formData.append("uploads[]", files[i], files[i].name);
		}
		return this.http.post(environment.server_URL + '/remediation/uploadfile', formData)
			.map(res => res.json());
	}

	addRemediation(remediation: remediation) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(remediation);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/addRemediation' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getActliveRemediationByOrgCode(orgCodeId) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'orgCodeId': orgCodeId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/getAllActiveRemediationByOrgCode' + token, body, { headers: headers })
			.map(res => res.json());
	}
	addRemediationWithUploadFile(remediation: remediation, uploadFile: Array<any>) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ remediation, 'uploadFile': uploadFile });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/addRemediationWithUploadFile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editRemediation(remediation: remediation) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(remediation);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/editRemediation' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editRemediationWithUploadFile(remediation: remediation, uploadFile: Array<any>) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ remediation, 'uploadFile': uploadFile });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/editRemediationWithUploadFile' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getRemediationAttachmentsByRemediationId(id: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'id': id });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/getRemediationAttachmentsByRemediationId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getRemediationAttachments() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';		
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/getRemediationAttachments' + token, { headers: headers })
			.map(res => res.json());
	}

	getFileContent(fileName: string, Id: string, mediatype: string): Observable<Blob> {
		const body = JSON.stringify({ 'fileName': fileName, 'Id': Id });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		let options = new RequestOptions({ headers: headers });
		options.responseType = ResponseContentType.Blob;
		return this.http.post(environment.server_URL + '/remediation/getFileContent', body, options)
			// .map(res => <Blob>res.blob());
			.map(res => new Blob([res.blob()], { type: mediatype }));
	}

	getAssignedRemediationTagList(rId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'rId': rId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/remediation/getAssignedRemediationTagList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	public getFileType(fileExtension: string) {
		var mediatype;
		if (fileExtension == 'pdf') {
			mediatype = 'application/pdf';
		} else if (fileExtension == 'docx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		} else if (fileExtension == 'doc') {
			mediatype = 'application/msword';
		} else if (fileExtension == 'ppt') {
			mediatype = 'application/vnd.ms-powerpoint';
		} else if (fileExtension == 'pptx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		} else if (fileExtension == 'xls') {
			mediatype = 'application/vnd.ms-excel';
		} else if (fileExtension == 'xlsx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		} else {
		}
		return mediatype;
	}

	getShowRemediation() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/getShowRemediation' + token, { headers: headers })
			.map(res => res.json());
	}

	changeRemediationShowHide(remediationId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'remediationId': remediationId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/changeRemediationShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getRemediationByLastAssessmentOfUser(orgId: string, includeChildren: boolean, supervisorId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'orgId': orgId, 'includeChildren': includeChildren, 'supervisorId': supervisorId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/remediation/getRemediationByLastAssessmentOfUser' + token, body, { headers: headers })
			.map(res => res.json());
	}
}
