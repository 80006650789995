import { Component, OnInit, ChangeDetectorRef } from '@angular/core'

import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms"

import { JobProfileService } from '../../jobprofile/jobprofile.service'
import { UserService } from '../../user/user.service'
import { AlertService } from '../../util/alert/alert.service'
import { AssessmentService } from '../assessment.service'
import { SharedService } from '../../home/shared.service'
import { OrderArrayByType } from "../../util/orderarraybytype.pipe"

@Component({
	selector: 'app-successionplanning',
	templateUrl: './successionplanning.component.html',
	styleUrls: ["./successionplanning.component.scss"]
})
export class SuccessionPlanningComponent implements OnInit {
	public userList: Array<any> = []
	public jobProfileList: Array<any> = []
	public successionPlanningForm: FormGroup
	public itemList: Array<any> = []
	public showResult: boolean = false;
	public candidateList = [];
	public noResults: boolean = false;

	itemsPerPage: number = 5;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	optionId: any = "";

	searchFirstName: string = "";
	searchLastName: string = "";
	searchOrgCode: string = "";
	searchJobProfile: string = "";

	private selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'))

	constructor(private jobProfileService: JobProfileService,
		private userService: UserService,
		private alertService: AlertService,
		private assessmentService: AssessmentService,
		private sharedService: SharedService,
		private changeDetectorRef: ChangeDetectorRef) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.getActiveUsersWithJobProfile()
		this.getActiveJobProfiles()
	}

	ngOnInit() {
		this.successionPlanningForm = new FormGroup({
			'option': new FormControl("", Validators.required),
			'optionItem': new FormControl(null, Validators.required)
		})
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.changeDetectorRef.detectChanges();
	}

	filter() {
		this.filteredData = this.candidateList;
		if (this.searchFirstName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item.user["FirstName"].match(new RegExp(this.searchFirstName, "i"));
			});
		}
		if (this.searchLastName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item.user["LastName"].match(new RegExp(this.searchLastName, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item.user["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item.user["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		this.pageChanged(1);
	}

	private getActiveJobProfiles() {
		this.jobProfileService.getAllActiveJobProfiles().subscribe(
			data => {
				this.jobProfileList = []
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobProfileList.push({
						'id': data.jobProfileList[i].Id,
						'text': data.jobProfileList[i].Description,
						'jobprofileid': data.jobProfileList[i].Id
					});
				}
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		);
	}

	private getActiveUsersWithJobProfile() {
		var supervisorId = '';
		if (this.selectedUserRole[0][1] == 'Supervisor') {
			supervisorId = localStorage.getItem('userId');
		}
		this.userService.getActiveUsersWithJobProfile(supervisorId).subscribe(
			data => {
				this.userList = []
				for (var i = 0; i < data.userlist.length; i++) {
					this.userList.push({
						'id': data.userlist[i].Id,
						'text': data.userlist[i].UserName,
						'jobprofileid': data.userlist[i].JobProfileId
					})
				}
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		)
	}

	setOptionList(option) {
		this.successionPlanningForm.get("optionItem").setValue(null);
		this.showResult = false;
		this.noResults = false;
		if (option == "user") {
			this.itemList = this.userList;
		} else if (option == "jobprofile") {
			this.itemList = this.jobProfileList;
		}
	}

	removeOptionItem(option: any) {
		this.candidateList = [];
		this.showResult = false;
		this.noResults = false;
	}

	showSuccessionPlanning() {
		this.optionId = this.successionPlanningForm.get("optionItem").value[0].id;
		let option = this.successionPlanningForm.value.option
		if (option == "user") {
			this.optionId = this.userList.filter(item => item.id == this.optionId)[0].jobprofileid;
		}
		var supervisorId = '';
		if (this.selectedUserRole[0][1] == 'Supervisor') {
			supervisorId = localStorage.getItem('userId');
		}
		this.assessmentService.getSuccessionPlanningUserList(this.optionId, supervisorId).subscribe(
			data => {
				// console.log(JSON.stringify(data).toString())
				this.candidateList = data.candidateList;
				for (var i = 0; i < this.candidateList.length; i++) {
					var temp = this.candidateList[i].user.AssessmentCompletedDate
					if (temp) {
						this.candidateList[i].user.AssessmentCompletedDate = new Date(new Date(temp).getUTCFullYear(), new Date(temp).getUTCMonth(), new Date(temp).getUTCDate()).toLocaleDateString();
					}
				}

				if (data.candidateList.length == 0) {
					this.noResults = true;
					this.showResult = false;
				} else {
					this.showResult = true;
					this.noResults = false;
				}
				this.total = this.candidateList.length;
				this.filteredData = this.candidateList;
				this.pageChanged();
			},
			error => {
				let err = error.json()
				this.alertService.clear()
				this.alertService.handleError(err)
			}
		)
	}

	clear() {
		this.showResult = false;
		this.noResults = false;
		this.candidateList = [];
		this.successionPlanningForm.get("optionItem").setValue(null);
		this.searchFirstName = "";
		this.searchLastName = "";
		this.searchOrgCode = "";
		this.searchJobProfile = "";
	}

	showAssessmentReport(userId: any, firstName: any, lastName: any) {
		var temp = {
			'userId': userId,
			'userName': firstName + " " + lastName,
			'jobProfileId': this.optionId
		}
		this.assessmentService.setJobProgressionStartupData(temp);
		this.sharedService.change("JobProgressionPlanning")
	}
}