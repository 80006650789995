import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';

import { NAReason } from './nareason.model';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { NAReasonService } from './nareason.service';
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
	selector: 'app-allnareason',
	templateUrl: './allnareason.component.html'
})
export class AllNAReasonComponent extends TableComponent {
	public selectedReason: any;
	public confirmationBtnClass: string = 'btn-default';
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	private isDeleteReason: boolean;
	private isModifyReason: boolean;
	isAddReason: boolean;
	private isQueryReason: boolean;
	public isLoading: boolean = false;
	public columns: Array<any> = [
		{ title: 'Reason', name: 'reason', filtering: { filterString: '', placeholder: '' } },
		{ title: 'Default', name: 'naDefault' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	//this array store all the data from the server
	private serverResultData: Array<any> = [];

	constructor(private customModalService: CustomModalService, private alertService: AlertService, private naReasonService: NAReasonService,
		private sharedService: SharedService, private userService: UserService) {
		super();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddReason = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.isModifyReason = this.userService.isCapabilityAssigned('ModifyCompLib', this.selectedUserRole[0][1]);
		this.isDeleteReason = this.userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryReason = this.userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.isQueryReason) {
			this.loadAllReasons();
		} else {
			//do nothing
		}
	}

	ngOnChanges() {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.

	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	//on cell click event
	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		this.alertService.clear();
		var elementnumber = parseInt(data.row.id);
		this.selectedReason = this.serverResultData[elementnumber];
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = 'btn-danger';
		} else {
			this.confirmationBtnClass = 'btn-success'
		}		
		switch (data.column) {
			case 'editbutton':
				if (this.isModifyReason) {
					this.editButtonClickEvent(this.selectedReason);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				if (this.isDeleteReason) {
					this.deleteButtonClickEvent(this.selectedReason.Id, this.selectedReason.NADefault);
				} else {
					//do nothing
				}
				break;
			case 'naDefault':
				this.defaultIconClickEvent(this.selectedReason.Id);
				break;
			default:
				break;
		}
	}

	private deleteButtonClickEvent(id: string, NADefault: any) {
		if (NADefault == 1) {
			this.alertService.clear();
			this.alertService.error("You cannot remove the default reason for N/A.");
		} else {
			let itemType = "Reason for N/A";
			this.customModalService.invoke(
				itemType,
				id,
				this,
				"delete", null
			);
		}
	}

	////load buttons to the table
	public extendData() {
		for (let i = 0; i < this.data.length; i++) {
			//check user has modify capability
			if (this.isModifyReason) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			}

			//check user has delete capability
			if (this.isDeleteReason) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}
			if (this.data[i].naDefault == 1) {
				this.data[i].naDefault = '<a class="btn p-0"><i class="fa fa-check-square-o" aria-hidden="true"></i></a>'
			} else {
				this.data[i].naDefault = '<a class="btn p-0"><i class="fa fa-square-o" aria-hidden="true"></i>'
			}
		}
	}

	//load all Reason from the db
	private loadAllReasons() {
		this.data = [];
		this.naReasonService.getAllNAReasons()
			.subscribe(
			data => {
				//console.log('[loadAllReason] data : ' + JSON.stringify(data) + '/(count)' + data.ReasonList.length);
				for (let i = 0; i < data.reasonList.length; i++) {
					this.data.push({
						'id': i,
						'reasonId': data.reasonList[i].Id,
						'reason': data.reasonList[i].Reason,
						'naDefault': data.reasonList[i].NADefault,
						'editbutton': '', 'deletebutton': ''
					});
				}
				this.serverResultData = data.reasonList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	private defaultIconClickEvent(selectedReasonId: any) {		
		let itemType = "Reason for N/A";
		this.customModalService.invoke(
			itemType,
			selectedReasonId,
			this,
			"defaultNA", null
		);
	}

	setDefaultReason(reasonId: any) {		
		let tempReason = new NAReason(reasonId, '');
		this.naReasonService.setDefaultReason(tempReason)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadAllReasons();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//edit button click event
	private editButtonClickEvent(selectedReason: any) {
		//console.log('[Reason] : ' + selectedReason.Id + '/' + selectedReason.Reason);
		let tempReason = new NAReason(selectedReason.Id, selectedReason.Reason);
		this.naReasonService.setSelectedReasonForEdit(tempReason);
		this.sharedService.change('EditNAReason');
	}


	//delete button click event
	delete() {
		//console.log('[deleteReason] (reasonId/reason) : ' + this.selectedReason.Id + '/' + this.selectedReason.Reason);
		const tempNAReason = new NAReason(this.selectedReason.Id, this.selectedReason.Reason);
		this.naReasonService.deleteNAReason(tempNAReason)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadAllReasons();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}
}
