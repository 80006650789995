import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NAReason } from './nareason.model';

@Injectable()
export class NAReasonService {

	constructor(private http: Http, private router: Router) { }

	private selectedReasonForEdit: NAReason;
	public getSelectedReasonForEdit(): NAReason {
		return this.selectedReasonForEdit;
	}
	public setSelectedReasonForEdit(reason: NAReason): void {
		this.selectedReasonForEdit = reason;
	}

	//add new NA Reason
	addNAReason(reason: NAReason) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(reason);
		return this.http.post(environment.server_URL + '/nareason/addNAReason' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//get all reasons for N/A
	getAllNAReasons() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/nareason/getAllNAReasons' + token, { headers: headers })
			.map(res => res.json());
	}

	//get the default reasons for N/A
	getDefaultReasonForNA() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/nareason/getDefaultReasonForNA' + token, { headers: headers })
			.map(res => res.json());
	}

	//update reason for N/A by reason id
	updateNAReasonByReasonId(reason: NAReason) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(reason);
		return this.http.post(environment.server_URL + '/nareason/updateNAReasonByReasonId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete reason for N/A
	deleteNAReason(naReason: NAReason) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(naReason);
		return this.http.post(environment.server_URL + '/nareason/deleteNAReason' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//set default reason for N/A
	setDefaultReason(reason: NAReason) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(reason);
		return this.http.post(environment.server_URL + '/nareason/setDefaultReason' + token, body, { headers: headers })
			.map(res => res.json());
	}
}