import { Component, OnInit } from '@angular/core';

import { SharedService } from './shared.service'
import { OverlayService } from '../util/overlay/overlay.service'

import { OverlayToolTipComponent } from '../util/overlay/overlaytooltip.component'

import { Constants } from '../util/const/constants'

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {
	public showOverlay: boolean = false
	public constants: any

	public slidebarMessage: string = Constants.OERLAY_INS_SLIDEBAR

	constructor(public sharedService: SharedService, public overlayService: OverlayService) {
		this.constants = Constants
	}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.sharedService.getToggleOverlayValue().subscribe(item => this.showOverlay = !this.showOverlay);
	}

	private removeOverlay() {
		this.sharedService.toggleOverlayIns(!this.showOverlay)
		this.overlayService.toggle()
	}
}