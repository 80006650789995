import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

@Injectable()
export class TaskTagService {
	public curTaskId: string = null;
	public curTask: string = null;

	constructor(private http: Http, private router: Router) { }

	addTaskTag(taskTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'taskTag': taskTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/tasktag/addTaskTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editTaskTag(compTagId: string, taskTag: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'taskTag': taskTag });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/tasktag/editTaskTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeTaskTagStatus(compTagId: string, tagStatus: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId, 'tagStatus': tagStatus });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/tasktag/changeTaskTagStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllTaskTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/tasktag/getAllTaskTag' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveTaskTags() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/tasktag/getActiveTaskTag' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteTaskTag(compTagId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compTagId': compTagId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/tasktag/removeTaskTag' + token, body, { headers: headers })
			.map(res => res.json());
	}

	setCurretData(compTagId: string, taskTag: string) {
		this.curTaskId = compTagId;
		this.curTask = taskTag;
	}

	getAllShowTaskTag() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/tasktag/getAllShowTaskTag' + token, { headers: headers })
			.map(res => res.json());
	}

	changeTaskTagShowHide(taskId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'taskId': taskId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/tasktag/changeTaskTagShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}	
	
}
