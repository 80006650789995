import { Pipe, PipeTransform } from '@angular/core';
import { Counter } from './counter';

@Pipe({
	name: 'counterPipe'
})
export class CounterPipe implements PipeTransform {
	count: Counter;

	transform(value: any): Counter {

		if (!this.count) {
			this.count = new Counter();
		}
		return this.count;
	}
}