import { Component, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { OnInit, AfterViewInit, ViewContainerRef, ComponentRef, ComponentFactoryResolver, QueryList } from '@angular/core'
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { OverlayToolTipComponent } from '../util/overlay/overlaytooltip.component'

import { WidgetService } from './widget.service'
import { UserService } from '../user/user.service'
import { SharedService } from '../home/shared.service'
import { AlertService } from '../util/alert/alert.service'
import { AssessmentService } from '../assessment/assessment.service'
import { OverlayService } from '../util/overlay/overlay.service'

import { Widget } from './widget.model';
import { Constants } from '../util/const/constants'

@Component({
	selector: 'app-staticdashboard',
	templateUrl: './staticdashboard.component.html',
	styleUrls: ['./staticdashboard.component.scss']
})
export class StaticDashboardComponent implements OnInit, AfterViewInit {

	@ViewChildren('widgetBody', { read: ViewContainerRef }) widgetHosts: QueryList<ViewContainerRef>;
	@ViewChild('selectWidgetsModal', { static: true }) selectWidgetsModal: ModalComponent;
	public showWidgetPanel: boolean = true
	public widgetList = []
	public displayWidgetsList = []
	public userId = localStorage.getItem('userId')
	public userRoleId = JSON.parse(localStorage.getItem('selectedUserRole'))
	public errorNoUserRole: string;
	public constants: any;
	public showOverlay: boolean = false

	public widgetCloseBtnToolTip: string = Constants.OERLAY_INS_DSHBOR_WIDGET_CLOSE_BTN

	public resetMessage: string = Constants.OERLAY_INS_DSHBOR_REST_BTN
	public saveMessage: string = Constants.OERLAY_INS_DSHBOR_SAVE_BTN
	public addNewMessage: string = Constants.OERLAY_INS_DSHBOR_ADDNEW_BTN
	public widgetCloseMessage: string = Constants.OERLAY_INS_DSHBOR_WIDGET_CLOSE_BTN
	public widgetMinimizeMessage: string = Constants.OERLAY_INS_DSHBOR_WIDGET_MINIMIZE_BTN
	public manageBtnMessage: string = Constants.OERLAY_INS_DSHBOR_MANAGE_BTN

	@ViewChild('resetBtn', { static: true }) resetBtn: ElementRef

	constructor(private userService: UserService, private alertService: AlertService, private assessmentService: AssessmentService,
		private widgetService: WidgetService, private sharedService: SharedService, private resolver: ComponentFactoryResolver, private overlayService: OverlayService) {

		if (!(this.userService.isValidLoggedIn())) {
			this.userService.redirectToLoginScreen()
		}
		this.constants = Constants
		this.widgetList = JSON.parse(localStorage.getItem('widgetList'))
		// this.widgetList = this.widgetService.widgetList;
		this.displayWidgetsList = this.removeUnCheckedWidgets()
		this.errorNoUserRole = Constants.ERROR_NO_USERROLE;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.		
		this.sharedService.getUserRole().subscribe(item => this.changeUserRole())
		this.sharedService.getWidgetList().subscribe(item => this.reloadDashBoard())
		localStorage.setItem('selectedMenuItem', 'DashBoard');
		this.sharedService.getToggleOverlayValue().subscribe(item => this.showOverlay = !this.showOverlay);
	}

	ngAfterViewInit() {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.loadComponents()
	}

	private loadComponents() {
		for (let i = 0; i < this.displayWidgetsList.length; i++) {
			let widgetName = this.displayWidgetsList[i].componentName;
			if (this.displayWidgetsList[i].checked) {
				switch (widgetName) {
					case 'Scheduled Assessments':
						this.assessmentService.getScheduledAssessments().subscribe(
							data => {
								// console.log('[loadComponents] (Scheduled Assessments-data) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.Assessments, this.displayWidgetsList[i])
								this.assessmentService.loadScheduledAssessments(data);
								this.loadComponent(this.displayWidgetsList[i], i)
							});
						break;
					case 'Active Assessments':
						this.assessmentService.getActiveAssessments().subscribe(
							data => {
								// console.log('[loadComponents] (Active Assessments-data) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.Assessments, this.displayWidgetsList[i])
								this.assessmentService.loadActiveAssessments(data);
								this.loadComponent(this.displayWidgetsList[i], i)
							});
						break;
					case 'Assessment History':
						this.assessmentService.getAssessmentHistory(localStorage.getItem('userId')).subscribe(
							data => {
								// console.log('[loadComponents] (Assessment History-data) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.Assessments, this.displayWidgetsList[i])
								this.assessmentService.loadAssessmentHistory(data);
								this.loadComponent(this.displayWidgetsList[i], i)
							});
						break;
					case 'Competency Percentage':
						this.assessmentService.getCompetencyPercentageData("Core Competency").subscribe(
							data => {
								// console.log('[loadComponents] (Competency Percentage-data) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.Assessments, this.displayWidgetsList[i])
								this.assessmentService.loadCompetencyPercentageData(data);
								this.loadComponent(this.displayWidgetsList[i], i)
							});
						break;
					case 'My Employee Competency Percentage':
						this.assessmentService.getEmployeeCompPercentageData("Core Competency", localStorage.getItem('userId')).subscribe(
							data => {
								// console.log('[loadComponents] (My Employee Competency Percentage-data) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.Assessments, this.displayWidgetsList[i])
								this.assessmentService.loadEmployeeCompPercentageData(data);
								this.loadComponent(this.displayWidgetsList[i], i)
							});
						break;
					case 'Global Assessment Progress':
						this.assessmentService.getGlobalAssessmentProgress().subscribe(
							data => {
								// console.log('[loadComponents] (Global Assessment Progress-data) ' + JSON.stringify(data));
								let overdueAssessmentCount = data.overdueAssessmentCount[0].Count;
								let completedAssessmentsCount = data.completedAssessmentsCount[0].Count;
								let neverAssessedCount = data.neverAssessedCount[0].Count;
								if ((neverAssessedCount | completedAssessmentsCount | neverAssessedCount) == 0) {
									let data = [];
									this.changeMinimizedValue(data, this.displayWidgetsList[i])
								}
								this.assessmentService.loadPieChartData(data)
								this.loadComponent(this.displayWidgetsList[i], i)
							}
						)
						break;
					case 'Competency Snapshot':
						this.assessmentService.getLastAssessmentScore(localStorage.getItem('userId')).subscribe(
							data => {
								// console.log('[loadComponents] (Competency Snapshot-data) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.lastAssessment, this.displayWidgetsList[i])
								this.assessmentService.loadCompetencysnapshotData(data)
								this.loadComponent(this.displayWidgetsList[i], i)
							}
						)
						break;
					case 'My Employee Global Assessment Progress':
						this.assessmentService.getEmployeeGlobalAssessmentProgress(localStorage.getItem('userId')).subscribe(
							data => {
								// console.log('[loadComponents] (My Employee Global Assessment Progress-data) ' + JSON.stringify(data));
								let overdueAssessmentsCount = data.overdueAssessmentsCount[0].Count;
								let completedAssessmentsCount = data.completedAssessmentsCount[0].Count;
								let neverAssessedCount = data.neverAssessedCount[0].Count;
								if ((overdueAssessmentsCount | completedAssessmentsCount | neverAssessedCount) == 0) {
									let data = [];
									this.changeMinimizedValue(data, this.displayWidgetsList[i])
								}
								this.assessmentService.loadEmployeeeAssessmentPieChartData(data)
								this.loadComponent(this.displayWidgetsList[i], i)
							}
						)
						break;
					case 'My Employee Assessments':
						this.assessmentService.getEmployeeAssessmentsBySupervisorId(localStorage.getItem('userId')).subscribe(
							data => {
								// console.log('[getEmployeeAssessmentsBySupervisorId] (My Employee Assessments) ' + JSON.stringify(data));
								this.changeMinimizedValue(data.Assessments, this.displayWidgetsList[i])
								this.assessmentService.loadEmployeeAssessmentsData(data);
								this.loadComponent(this.displayWidgetsList[i], i)
							}
						)
						break;
					default:
						this.loadComponent(this.displayWidgetsList[i], i)
						break;
				}
			}
		}
	}

	private loadComponent(curWidget, index) {
		const component = this.widgetService.loadComponentByComponentName(curWidget.componentName);
		if (component) {
			const target = this.widgetHosts.toArray()[index];
			const widgetComponent = this.resolver.resolveComponentFactory(component);
			const cmpRef: any = target.createComponent(widgetComponent);
		}
	}

	private changeMinimizedValue(data: any, widget: Widget) {
		if (data.length != 0) {
			widget.minimized = false
		} else {
			widget.minimized = true
		}
	}

	showWidgetsList() {
		this.showWidgetPanel = false
		this.selectWidgetsModal.open()
		this.displayWidgetsList = []
	}

	getSelectedWidgets() {
		this.showWidgetPanel = true;
		this.widgetList.forEach(widget => {
			if (widget.checked) {
				this.displayWidgetsList.push(widget)
			}
		});
		this.loadComponents()
	}

	/**
	 * widget remove button click event 
	 */
	removeWidget(widget: Widget): void {
		this.displayWidgetsList = []
		let tempWidget: Widget = this.widgetList.find(item => item.componentId === widget.componentId)
		tempWidget.checked = false
		this.displayWidgetsList = this.removeUnCheckedWidgets()
	}

	/**
	 * save dashboard
	 */
	saveDashboard() {
		// console.log('[saveDashboard] (displayWidgetsList) ' + JSON.stringify(this.displayWidgetsList))
		// console.log('[saveDashboard] (widgetList) ' + JSON.stringify(this.widgetList))
		const widgetString = JSON.stringify(this.widgetList)
		const params: any = {
			'userId': this.userId,
			'userRoleId': this.userRoleId[0][0],
			'widgetString': widgetString
		}
		this.widgetService.saveUserPreference(params).subscribe(
			data => {
				this.alertService.clear()
				this.alertService.success(data.message)
				localStorage.setItem('widgetList', JSON.stringify(this.widgetList));
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	/**
	 * remove checked == false items
	 * from displaywidgetlist
	 */
	private removeUnCheckedWidgets(): any {
		let temp = []
		if (this.widgetList != null) {
			this.widgetList.forEach(widget => {
				if (widget.checked) {
					temp.push(widget)
				}
			});
		}
		return temp;
	}

	/**
	 * reset dashboard event
	 */
	resetDashBoard() {		
		this.widgetList = []
		this.displayWidgetsList = []
		this.widgetService.getWidgetListByUserRole(this.userRoleId[0][0]).subscribe(
			data => {
				const wl = this.widgetService.generateWidgetListByUserRole(data.userPreference)
				localStorage.setItem('widgetList', JSON.stringify(wl));
				// location.reload()
				this.widgetList = this.widgetService.generateWidgetListByUserRole(data.userPreference)
				this.displayWidgetsList = this.removeUnCheckedWidgets()
				this.loadComponents()
			},
			error => {

			}
		)
	}

	private reloadDashBoard() {		
		this.displayWidgetsList = []
		this.widgetList = JSON.parse(localStorage.getItem('widgetList'))
		this.displayWidgetsList = this.removeUnCheckedWidgets()
		this.loadComponents()
	}

	private changeUserRole() {		
		this.userRoleId = JSON.parse(localStorage.getItem('selectedUserRole'))
	}

	private minimizeWidget(widget: Widget) {
		widget.minimized = !widget.minimized
	}

	public showHelp() {
		this.sharedService.toggleOverlayIns(true);
		this.overlayService.toggle()
	}

}