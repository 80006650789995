<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>My Profile</h4>
		</div>
		<div class="float-right">

		</div>
	</div>
	<hr />
</div>


<div class="container">
	<div>
		<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
			<!--first name-->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">First Name
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" formControlName="firstName" value="" name="firstName" type="text">
				</div>
			</div>
			<!--last name-->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Last Name
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" formControlName="lastName" value="" name="lastName" type="text">
				</div>
			</div>
			<!--email-->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Email
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<input type="text" class="form-control" formControlName="email" value="" name="email" type="email">
				</div>
			</div>

			<!-- Time zone -->
			<div class="form-group row">
				<label class="col-sm-2 col-form-label">Time Zone
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<div class="col-sm-10">
					<ng-select name="timeZone" id="timeZone" formControlName="timeZone" [allowClear]="true" [items]="timeZoneList" (selected)="selected($event)"
					 (removed)="removed($event)" placeholder="Select Time Zone"></ng-select>
				</div>
			</div>
			<alert></alert>
			<!--buttons-->
			<div class="form-group row">
				<div class="col-sm-6">
				</div>
				<div class="col-sm-3">
					<!-- <label><span><a [routerLink]="['/user/resetpwd']"> Reset Password</a></span></label> -->
					<button type="button" class="btn btn-default btn-block" (click)="selectedComponentItem('ResetPassword')">Reset Password</button>
				</div>
				<div class="col-sm-3">
					<button type="submit" class="btn btn-warning btn-block" [disabled]="!myForm.valid">Save</button>
				</div>
			</div>
		</form>
	</div>
</div>