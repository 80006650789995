import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { ChecklistTagService } from './checklisttag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-addchecklisttag',
	templateUrl: './addchecklisttag.component.html'
})

export class AddChecklistTagComponent implements OnInit {
	addChecklistTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private checklistTagService: ChecklistTagService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.addChecklistTag = new FormGroup({
			checklistTag: new FormControl(null, Validators.required)
		});
	}

	cancel() {
		this.sharedService.change('ChecklistTag');
	}

	saveAndNew() {
		this.checklistTagService.addChecklistTag(this.addChecklistTag.value.checklistTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success("Successfully added the checklist tag.");
				// this.addChecklistTag.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	save() {
		this.checklistTagService.addChecklistTag(this.addChecklistTag.value.checklistTag)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.cancel();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

}
