<div class="pos-f-t header" style="position: relative; border-bottom: 1px solid #ddd;">
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="navbar-brand">
      <img [src]="logoSrc" />
    </div>
    <!--<button class="navbar-toggler" type="button" (click)="toggleSidebar()">
		<span class="navbar-toggler-icon"></span>
	</button>-->

	<button class="navbar-toggler btn btn-warning-name p-2 pr-3 pl-3 rounded-0" type="button" (click)="toggleSidebar()">
        <i class="fa fa-bars">          
        </i>
      </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <form class="form-inline my-2 my-lg-0">

        <!--<a class="nav-link " href="#" id="navbarDropdown" role="button">-->
        <button type="button" class="btn btn-warning-name dropdown-toggle rounded-0 my-2 my-sm-0 pl-3 pr-3" id="navbarDropdown" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user">              
            </i>            
          </button>
        <!--</a>-->        
      </form>
    </div>
  </nav>
</div>


<modal #userRoleModelStartUP [keyboard]="false" [backdrop]="'static'">
	<modal-header [show-close]="false">
		<h4 class="modal-title">Select User Role</h4>
	</modal-header>
	<modal-body>
		<div class="col-sm-12">
			<div class="row">
				<div class="col-sm-4" *ngFor="let userrole of userRoles" (click)="urmSPclick(userrole[0],userrole[1])">
					<div class="card card-block text-center">
						<h4>{{ userrole[1] }}</h4>
						<img class="img-thumbnail rounded mx-auto d-block" src="../assets/images/user_roles.jpg">
					</div>
					<br>
				</div>
			</div>
		</div>
	</modal-body>
</modal>