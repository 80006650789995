import { Component } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentService } from "../../assessment/assessment.service";

@Component({
	selector: 'app-assessmentprogress',
	templateUrl: "./assessmentprogress.component.html",
	styleUrls: ['./assessmentprogress.component.scss']
})

export class AssessmentProgressComponent {
	public userId = '';
	public data: any;
	public show: boolean = false;
	public progress: number = 0;
	public selfAssessmentProgress: number = 0;
	public isKnowledgeTest: number;

	constructor(private assessmentService: AssessmentService, private sanitizer: DomSanitizer) {
		this.userId = localStorage.getItem('userId');
		this.assessmentService.getAssessmentProgress(this.userId)
			.subscribe(
			data => {
				this.data = data.assessmentProgressData[0];

				if ((this.data.Status == 'Started' || this.data.Status == 'New' || this.data.SelfAssessmentStatus == 'Started' || this.data.SelfAssessmentStatus == 'New')) {
					this.show = true;
					this.isKnowledgeTest = this.data.IsKnowledgeTest
					if (this.data.Answered != 0) {
						if (this.data.AssessorCompleted != 1) {
							let percentage = (this.data.Answered / (this.data.Total + 1)) * 100
							if (percentage > 99 && percentage < 100) {
								this.progress = 99
							} else {
								this.progress = Math.round(percentage);
							}
						}
						else {
							this.progress = Math.round((this.data.Answered / this.data.Total) * 100);
						}
					}
					if (this.data.SelfAssessmentAnswered != 0) {
						if (this.data.SelfAssessmentStatus != 'Completed') {
							let percentage = (this.data.SelfAssessmentAnswered / (this.data.SelfAssessmentTotal + 1)) * 100
							if (percentage > 99 && percentage < 100) {
								percentage = 99
							} else {
								this.selfAssessmentProgress = Math.round(percentage);
							}
						}
						else {
							this.selfAssessmentProgress = Math.round((this.data.SelfAssessmentAnswered / this.data.SelfAssessmentTotal) * 100);
						}
					}
				} else {
					this.show = false;
				}
			});
	}

} 