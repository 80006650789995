<div class="col-lg-12">
  <div class="col" style="overflow:hidden">
    <div class="float-left">
      <h4>Simple Reports</h4>
    </div>
    <div class="float-right">
    </div>
  </div>
  <hr />
</div>

<div class="col-lg-12">
  <alert></alert>
  <div class="list-group p-1 pb-2" *ngFor="let item of reports">
    <div class="list-group-item-secondary rounded-top p-2">
      <h5 class="mb-1 ml-1">{{ item.type }}</h5>
    </div>
    <div class="p-2 list-group-item" *ngFor="let reportItem of item.reportList">
      <div class="col-sm-12">
        <div class="col-sm-6 float-left p-0">
          <p class="tab mb-0 ml-2">{{ reportItem }}</p>
        </div>
        <div class="col-sm-6 float-right">
          <button type="button" class="btn btn-warning btn-sm float-right" (click)="generateReport(item.type, reportItem)">Generate Report</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf=isLoading class="loading"></div>
</div>

