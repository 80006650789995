<!--component html goes here -->
<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Competency Analysis Tool</h4>
		</div>
		<div class="float-right">
			<div class="row">
			</div>
		</div>
	</div>
	<hr />
</div>
<div class="col-lg-12 mx-auto">
	<form>
		<div class="form-group row ">
			<div class="col-sm-6">
				<h6>Category
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup> :
				</h6>
				<ng-select #sc [allowClear]="true" [items]="categoryList" placeholder="Select a category" (removed)="removeCategory($event)"
				 (selected)="selectCategory($event)"></ng-select>
			</div>
			<div class="col-sm-6">
				<h6>Sub Category :</h6>
				<ng-select #ssc [allowClear]="true" [items]="subCategoryList" placeholder="Select a sub category" (removed)="removeSubCategory($event)"
				 [disabled]="isDisableBtn" (selected)="selectSubCategory($event)"></ng-select>
			</div>
		</div>
		<div class="form-group row mt-4 mb-2">
			<div class="col-sm-3">
				<button class="btn btn-warning btn-block" (click)="showFilterModal()" [disabled]="isDisableBtn">Filter</button>
			</div>
			<div class="col-sm-3">
				<button class="btn btn-warning btn-block" (click)="showCATResult()" [disabled]="isDisableBtn">Show</button>
			</div>
			<div class="col-sm-3">
				<button (click)="print()" class="btn btn-warning btn-block" [disabled]="!isPrintable">Print</button>
			</div>
			<div class="col-sm-3">
				<button (click)="clear()" class="btn btn-default btn-block">Clear</button>
			</div>
		</div>
		<div class="form-group row mt-4 mb-2" *ngIf="isFilteringAvailable()">
			<div class="col-sm-12 float-left pr-0">
				<label class="float-left">Filtered By: </label>
			</div>
			<div class="col-sm-12 float-left pr-0">
				<span class="badge badge-light float-left ml-0 mt-1 mb-1 mr-1" *ngIf='filteringText.orgCode'>
					<h6 class="float-left mr-2 ml-1 mb-1 mt-1"><b>Org Code:</b> {{filteringText.orgCode}}</h6>
					<button type="button" class="close mr-1" aria-label="Close" (click)="removeFilteringText('orgCode')">
						<span class="close-red" aria-hidden="true">&times;</span>
					</button>
				</span>
				<span class="badge badge-light float-left m-1" *ngIf='filteringText.jobProfile'>
					<h6 class="float-left mr-2 ml-1 mb-1 mt-1" ><b>Job Profile:</b> {{filteringText.jobProfile}}</h6>
					<button type="button" class="close mr-1" aria-label="Close" (click)="removeFilteringText('jobProfile')">
						<span class="close-red" aria-hidden="true">&times;</span>
					</button>
				</span>
				<span class="badge badge-light float-left m-1" *ngIf='filteringText.discipline '>
					<h6 class="float-left mr-2 ml-1 mb-1 mt-1"><b>Discipline:</b> {{filteringText.discipline}}</h6>
					<button type="button" class="close mr-1" aria-label="Close" (click)="removeFilteringText('discipline')">
						<span class="close-red" aria-hidden="true">&times;</span>
					</button>
				</span>
				<span class="badge badge-light float-left m-1" *ngIf='filteringText.supervisor'>
					<h6 class="float-left mr-2 ml-1 mb-1 mt-1"><b>Supervisor:</b> {{filteringText.supervisor}}</h6>
					<button type="button" class="close mr-1" aria-label="Close" (click)="removeFilteringText('supervisor')">
						<span class="close-red" aria-hidden="true">&times;</span>
					</button>
				</span>
				<span class="badge badge-light float-left m-1" *ngIf='filteringText.coreCompetency'>
					<h6 class="float-left mr-2 ml-1 mb-1 mt-1"><b>Core Competency:</b> {{filteringText.coreCompetency}}</h6>
					<button type="button" class="close mr-1" aria-label="Close" (click)="removeFilteringText('coreCompetency')">
						<span class="close-red" aria-hidden="true">&times;</span>
					</button>
				</span>
			</div>
		</div>
	</form>
	<hr/>
	<alert></alert>
	<div class="col-sm-12 alert alert-info ml-1 mr-1" *ngIf="noResults">
		No matching users.
	</div>
</div>

<div class="col-sm-12 mt-2 " *ngIf="showResults == 'Category'">
	<div *ngFor="let caItem of competencyAnalysisData">
		<div class="col-sm-12 mx-auto pt-1 pb-1 mt-1 mt-2 pl-0 pr-0">
			<div class="card p-2" (click)="showCollapsePanel(caItem, null, 'Category')">
				<div class="card-body pointer-cursor mb-1">
					<div class="col-sm-4 float-left pt-1 pb-2">
						<div class="progress">
							<div data-toggle="tooltip" *ngIf="caItem.redPercentage != 0" data-placement="bottom" class="progress-bar pointer-cursor progress-bar-danger"
							 role="progressbar" [style.width]="caItem.redActual + '%'">
								{{caItem.redPercentage}}%
							</div>
							<div data-toggle="tooltip" *ngIf="caItem.redPercentage == 0" data-placement="bottom" class="progress-bar pointer-cursor progress-bar-danger"
							 role="progressbar" [style.width]="caItem.redActual + '%'">								
							</div>
							<div data-toggle="tooltip" *ngIf="caItem.yellowPercentage != 0" data-placement="bottom" class="progress-bar progress-bar-warning pointer-cursor"
							 role="progressbar" [style.width]="caItem.yellowActual + '%'">
								{{caItem.yellowPercentage}}%
							</div>
							<div data-toggle="tooltip" *ngIf="caItem.yellowPercentage == 0" data-placement="bottom" class="progress-bar progress-bar-warning pointer-cursor"
							 role="progressbar" [style.width]="caItem.yellowActual + '%'">								
							</div>
							<div data-toggle="tooltip" *ngIf="caItem.greenPercentage != 0" data-placement="bottom" class="progress-bar progress-bar-success pointer-cursor"
							 role="progressbar" [style.width]="caItem.greenActual + '%'">
								{{caItem.greenPercentage}}%
							</div>
							<div data-toggle="tooltip" *ngIf="caItem.greenPercentage == 0" data-placement="bottom" class="progress-bar progress-bar-success pointer-cursor"
							 role="progressbar" [style.width]="caItem.greenActual + '%'">
							</div>
						</div>
					</div>
					<div class="col-sm-8 float-right pr-0">
						<label class="col-sm-11 mb-0 float-left pointer-cursor">{{ caItem.description }}</label>
						<div class="float-right btn btn-sm border-0 mr-1 ml-1">
							<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': showItemDetail == caItem.id, 'fa-angle-down': showItemDetail != caItem.id }"></i>
						</div>
					</div>
				</div>
				<div *ngIf="showItemDetail == caItem.id">
					<div class="col-sm-12 mx-auto mt-2 mb-2" (click)="$event.stopPropagation();">
						<div class="table-responsive mb-2">
							<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
							 class="table-sm">
							</ng-table>
						</div>
						<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
						 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
						</pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="col-sm-12 mt-2" *ngIf="showResults == 'SubCategory'">
	<div *ngFor="let parentItem of subCategoryCAData">
		<div class="col-sm-12 mx-auto pt-1 pb-1 mt-1 mt-2 pl-0 pr-0">
			<div class="card p-2 card-grey-lighten-3" (click)="showCollapsePanel(parentItem, null, 'SubCategoryParent')">
				<div class="card-body pointer-cursor mb-1 pl-2">
					<label class="mb-0">{{ parentItem.parentDescription }}</label>
					<div class="float-right">
						<div class="float-right btn btn-sm border-0 mr-1 ml-1">
							<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': parentItem.expand == true, 'fa-angle-down': parentItem.expand == false }"></i>
						</div>
					</div>
				</div>
				<div *ngIf="parentItem.expand == true">
					<div class="col-sm-12 mx-auto mt-1 mb-2" *ngFor="let child of parentItem.childList">
						<div class="card p-2 " (click)="$event.stopPropagation(); showCollapsePanel(child, parentItem, 'SubCategoryChild')">
							<div class="card-body row pointer-cursor">
								<div class="col-sm-4 float-left pt-1 pb-2">
									<div class="progress">
										<div data-toggle="tooltip" *ngIf="child.redPercentage != 0" data-placement="bottom" class="progress-bar pointer-cursor progress-bar-danger"
										 role="progressbar" [style.width]="child.redActual + '%'">
											{{child.redPercentage}}%
										</div>
										<div data-toggle="tooltip" *ngIf="child.redPercentage == 0" data-placement="bottom" class="progress-bar pointer-cursor progress-bar-danger"
										 role="progressbar" [style.width]="child.redActual + '%'">
										</div>
										<div data-toggle="tooltip" *ngIf="child.yellowPercentage != 0" data-placement="bottom" class="progress-bar progress-bar-warning pointer-cursor"
										 role="progressbar" [style.width]="child.yellowActual + '%'">
											{{child.yellowPercentage}}%
										</div>
										<div data-toggle="tooltip" *ngIf="child.yellowPercentage == 0" data-placement="bottom" class="progress-bar progress-bar-warning pointer-cursor"
										 role="progressbar" [style.width]="child.yellowActual + '%'">
										</div>
										<div data-toggle="tooltip" *ngIf="child.greenPercentage != 0" data-placement="bottom" class="progress-bar progress-bar-success pointer-cursor"
										 role="progressbar" [style.width]="child.greenActual + '%'">
											{{child.greenPercentage}}%
										</div>
										<div data-toggle="tooltip" *ngIf="child.greenPercentage == 0" data-placement="bottom" class="progress-bar progress-bar-success pointer-cursor"
										 role="progressbar" [style.width]="child.greenActual + '%'">
										</div>
									</div>
								</div>
								<div class="col-sm-8 float-right pr-0">
									<label class="col-sm-11 mb-0 float-left pointer-cursor">{{ child.description }}</label>
									<div class="float-right btn btn-sm border-0 mr-2 ml-1">
										<i class="mr-1 ml-1 fa" [ngClass]="{'fa-angle-up': child.expand == true, 'fa-angle-down': child.expand == false }"></i>
									</div>
								</div>
							</div>
							<div *ngIf="child.expand == true && parentItem.expand == true">
								<div class="col-sm-12 mx-auto mt-2 mb-2" (click)="$event.stopPropagation();">
									<div class="table-responsive mb-2">
										<ng-table [config]="config" (tableChanged)="onChangeTable(config)" (cellClicked)="onCellClick($event)" [rows]="rows" [columns]="columns"
										 class="table-sm">
										</ng-table>
									</div>
									<pagination *ngIf="config.paging" class="pagination-sm" [(ngModel)]="page" [totalItems]="length" [itemsPerPage]="itemsPerPage"
									 [maxSize]="maxSize" [boundaryLinks]="true" [rotate]="false" (pageChanged)="onChangeTable(config, $event)" (numPages)="numPages = $event">
									</pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<modal #filterDataModal>
	<modal-header [show-close]="false">
		<h4 class="modal-title">Filter Data</h4>
	</modal-header>
	<modal-body>
		<form [formGroup]="filterForm">
			<div class="from-group row p-2">
				<div class="col-sm-12 mt-1 mb-1">
					<h6>Org Code : </h6>
					<input #filterOrgCode type="text" class="form-control" id="filterOrgCode" placeholder="Org code" name="filterOrgCode" formControlName="filterOrgCode">
				</div>
				<div class="col-sm-12 mt-1 mb-1">
					<h6>Job Profile : </h6>
					<input #filterJobProfile type="text" class="form-control" id="filterJobProfile" placeholder="Job profile" name="filterJobProfile"
					 formControlName="filterJobProfile">
				</div>
				<div class="col-sm-12 mt-1 mb-1">
					<h6>Discipline : </h6>
					<input #filterDiscipline type="text" class="form-control" id="filterDiscipline" placeholder="Discipline" name="filterDiscipline"
					 formControlName="filterDiscipline">
				</div>
				<div class="col-sm-12 mt-1 mb-1">
					<h6>Supverisor : </h6>
					<input #filterFirstName type="text" class="form-control" id="filterSupervisor" placeholder="Supervisor Name" name="filterSupervisor"
					 formControlName="filterSupervisor">
				</div>
				<div class="col-sm-12 mt-1 mb-1">
					<h6>Core Competency : </h6>
					<input #filterFirstName type="text" class="form-control" id="filterCoreComp" placeholder="Core Competency" name="filterCoreComp"
					 formControlName="filterCoreComp">
				</div>
			</div>
		</form>
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dismissModal()">Cancel</button>
		<button type="button" class="btn btn-warning" data-dismiss="modal" (click)="filterCATResult()">Filter</button>
	</modal-footer>
</modal>