import { Component, Injectable, Output, EventEmitter } from '@angular/core';
import { NgGridItemConfig, NgGridItemEvent } from 'angular2-grid';
import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import 'rxjs/Rx';
import { Observable } from 'rxjs';

//widgets
import { ProgressAssessmentComponent } from './widgets/assessment/progress/progressassessment.component';
import { AssessmentSummaryComponent } from './widgets/assessment/summary/assessmentsummary.component';
import { RankingComponent } from './widgets/ranking/ranking.component';
import { GlobalAssessmentProgressComponent } from './widgets/assessment/globalprogress/globalassessmentprogress.component';
import { ScheduledAssessmentComponent } from './widgets/assessment/scheduled/scheduledassessment.component';
import { ActiveAssessmentComponent } from './widgets/assessment/active/activeassessment.component';
import { AssessmentHistoryComponent } from './widgets/assessment/history/assessmenthistory.component';
import { CompetencySnapshotComponent } from './widgets/assessment/competencysnapshot/competencysnapshot.component';
import { CompetencyPercentageComponent } from './widgets/competencypercentage/competencypercentage.component';
import { EmployeesCompPercentageComponent } from './widgets/employeescompetencypercentage/employeescomppercentage.component';
import { EmployeeAssessmentProgressComponent } from './widgets/employeeassessmentprogress/employeeassessmentprogress.component';
import { EmployeeAssessmentsComponent } from './widgets/employeeassessments/employeeassessments.component'

import { Widget } from './widget.model'

@Injectable()
export class WidgetService {

	constructor(private http: Http, private router: Router) { }

	public loadComponentByComponentName(componentName: string): any {
		switch (componentName) {
			case 'Progress Assessment Component':
				return ProgressAssessmentComponent
			case 'Assessment Summary Component':
				return AssessmentSummaryComponent
			case 'Ranking Component':
				return RankingComponent
			case 'Global Assessment Progress':
				return GlobalAssessmentProgressComponent
			case 'Scheduled Assessments':
				return ScheduledAssessmentComponent
			case 'Active Assessments':
				return ActiveAssessmentComponent
			case 'Assessment History':
				return AssessmentHistoryComponent
			case 'Competency Snapshot':
				return CompetencySnapshotComponent
			case 'Competency Percentage':
				return CompetencyPercentageComponent
			case 'My Employee Competency Percentage':
				return EmployeesCompPercentageComponent
			case 'My Employee Global Assessment Progress':
				return EmployeeAssessmentProgressComponent
			case 'My Employee Assessments':
				return EmployeeAssessmentsComponent
		}
	}

	/**
	 * [getDashboardWidgets get dashboard widgets from server]
	 * @return {[type]} [description]
	 */
	public getDashboardWidgets(userRoleId: string) {
		this.getUserPreference(userRoleId).subscribe(
			data => {
				if (data.userPreference != null) {
					const widgetList = this.generateWidgetList(data.userPreference)
					localStorage.setItem('widgetList', JSON.stringify(widgetList));
					this.router.navigateByUrl('/home');
				} else {
					this.getUserRoleWidgetList(userRoleId)
				}
			},
			error => {
				// no need to handle error
			}
		)
	}

	public getUserRoleWidgetList(userRoleId: string) {
		this.getWidgetListByUserRole(userRoleId).subscribe(
			data => {
				const widgetList = this.generateWidgetListByUserRole(data.userPreference)
				localStorage.setItem('widgetList', JSON.stringify(widgetList));
				this.router.navigateByUrl('/home');
			},
			error => {
				// no need to handle error
			}
		)
	}

	/**
	 * [generateWidgetList description]
	 * @param  {any} dbWidgetList [description]
	 * @return {any}              [description]
	 */
	public generateWidgetList(dbWidgetList: any): any {
		let widgetList = []
		dbWidgetList.forEach(item => {
			let widget: Widget = new Widget(item.componentId)
			widget.componentName = item.componentName
			widget.componentIcon = item.componentIcon
			widget.position = item.position
			widget.size = item.size
			widget.checked = item.checked;
			widget.minimized = false;
			widgetList.push(widget)
		});
		return this.sortArrayByPosition(widgetList)
	}

	/**
	 * [generateWidgetListByUserRole description]
	 * @method generateWidgetListByUserRole
	 * @param  {any}                        dbWidgetList [description]
	 * @return {any}                                     [description]
	 */
	public generateWidgetListByUserRole(dbWidgetList: any): any {
		let widgetList = []
		dbWidgetList.forEach(item => {
			let widget: Widget = new Widget(item.Id)
			widget.componentName = item.WidgetName
			widget.componentIcon = item.WidgetIcon
			widget.position = item.Position
			widget.size = item.Size
			widget.checked = true;
			widget.minimized = false;
			widgetList.push(widget)
		});
		return this.sortArrayByPosition(widgetList)
	}

	/**
	 * sort array by position
	 * @param array 
	 */
	private sortArrayByPosition(array: any): any {
		if (array.length != 0) {
			array.sort((a, b) => {
				if (a.position < b.position) {
					return -1;
				} else if (a.position > b.position) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	saveUserPreference(param: JSON) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(param);
		return this.http.post(environment.server_URL + '/user/saveUserPreference' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getUserPreference(userRoleId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'userId': localStorage.getItem('userId'), 'userRoleId': userRoleId });
		return this.http.post(environment.server_URL + '/user/getUserPreference' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getWidgetListByUserRole(userRoleId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'userId': localStorage.getItem('userId'), 'userRoleId': userRoleId });
		return this.http.post(environment.server_URL + '/user/getWidgetListByUserRole' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
