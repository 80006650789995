import {
	Component,
	ElementRef,
	OnInit,
	Renderer2,
	ValueProvider,
	ViewChild
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserService } from '../../user/user.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';

import { remediation } from './remediation';
import { RemediationService } from './remediation.service';
import { CustomModalService } from "../../util/custommodal/custommodal.service";
import { RemediationTagService } from '../../tag/remediationtag/remediationtag.service';
import { TagInputModule } from 'ngx-chips';
import { Constants } from '../../util/const/constants'
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-remediation',
	templateUrl: './editremediation.component.html'
})
export class EditRemediationComponent implements OnInit {
	@ViewChild("fileInput", { static: true }) el: ElementRef;

	editRemediationForm: FormGroup;
	public typeId: string
	public typeList: any[];
	public selectedOrgId: string;
	public orgList: Array<any> = [];
	public mediumId: string
	public mediumList: any[];
	public editRemediation: remediation;
	private filesToUpload: Array<File> = [];
	private removedFiles: Array<string> = [];
	private uploadFileData: Array<any> = [];
	public selectedAttachmentList = [];
	public activeTagList = [];
	public items = [];
	public duplicateFiles: any = '';
	public dependencyList: Array<any> = [];
	itemType = "Remediation";

	public validFilesToUpload: Array<File> = [];
	public invalidfiles: string = '';

	constructor(
		private sharedService: SharedService,
		private remediationService: RemediationService,
		private alertServices: AlertService,
		private userService: UserService,
		private remediationTagService: RemediationTagService,
		private orgcodeService: OrgcodeService,
		private customModalService: CustomModalService,
		private rd: Renderer2
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.editRemediation = remediationService.getSelectedRemediationForEdit();
		this.checkForDependencies()
		this.editRemediation.tagList.forEach(tag => {
			let item = { value: tag.value, id: tag.id };
			this.items.push(item);
		});

		this.filesToUpload = [];
	}

	checkForDependencies() {
		this.customModalService.invoke(
			this.itemType,
			this.editRemediation.id,
			this,
			"edit", null
		);
	}
	valid() {
		let result = false;

		if (this.editRemediationForm.get("description").status == "VALID" && this.editRemediationForm.get("medium").status == "VALID" && this.editRemediationForm.get("type").status == "VALID") {
			result = true;
		}
		if (result) {
			if (this.editRemediationForm.value.note) {
				if (this.editRemediationForm.value.note.length > 0) {
					if (this.editRemediationForm.value.note.trim().length == 0) {
						result = false
					}
				}
			}
		}
		if (result) {
			if (this.editRemediationForm.value.coursedescription) {
				if (this.editRemediationForm.value.coursedescription.length > 0) {
					if (this.editRemediationForm.value.coursedescription.trim().length == 0) {
						result = false
					}
				}
			}
		}
		if (result) {
			if (this.editRemediationForm.value.coursenumber) {
				if (this.editRemediationForm.value.coursenumber.length > 0) {
					if (this.editRemediationForm.value.coursenumber.trim().length == 0) {
						result = false
					}
				}
			}
		}
		return result;
	}

	ngOnInit() {
		this.typeList = [{ id: 'Procedure', text: 'Procedure' }, { id: 'Overview', text: 'Overview' }, { id: 'Policy', text: 'Policy' }];
		this.typeId = this.editRemediation.type;

		this.mediumList = [{ id: 'CBT', text: 'CBT' }, { id: 'Document', text: 'Document' }, { id: 'Classroom', text: 'Classroom' }, { id: 'Note', text: 'Note' }];
		this.mediumId = this.editRemediation.medium;
		this.loadActiveRemediationTags();

		this.editRemediationForm = new FormGroup({
			id: new FormControl(this.editRemediation.id, Validators.required),
			medium: new FormControl(this.editRemediation.medium, Validators.required),
			description: new FormControl(
				this.editRemediation.description,
				Validators.required
			),
			type: new FormControl(this.editRemediation.type, Validators.required),
			coursenumber: new FormControl(this.editRemediation.courseNumber),
			coursedescription: new FormControl(
				this.editRemediation.courseDescription
			),
			link: new FormControl(this.editRemediation.link),
			orgId: new FormControl(null),
			note: new FormControl(this.editRemediation.note),
			remediationTag: new FormControl("")
		});

		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
					if (this.editRemediation.orgId != null) {
						if (data.orgcode[i].Id == this.editRemediation.orgId) {
							this.editRemediationForm.get("orgId").setValue([{ id: data.orgcode[i].Id, text: data.orgcode[i].Description }])
						}
					}
				}
				this.selectedOrgId = this.editRemediation.orgId;
			},
			error => {				
			}
			);

		this.remediationService.getRemediationAttachmentsByRemediationId(this.editRemediation.id)
			.subscribe(
			data => {
				this.selectedAttachmentList = [];
				for (var i = 0; i < data.remediationAttachmentList.length; i++) {
					var fileExtension = data.remediationAttachmentList[i].FileName.substr((data.remediationAttachmentList[i].FileName.lastIndexOf('.') + 1));
					this.selectedAttachmentList.push({ id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
				}
				this.editRemediation.attachment = this.selectedAttachmentList.length;
			},
			error => {				
			}
			);
		//
	}

	//remove files with unsupported extentions from upload list
	private checkForValidFiles() {
		let fileTypes = ['xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf'];
		this.invalidfiles = '';
		this.validFilesToUpload = [];
		let fileNameparts = [];
		for (var i = 0; i < this.filesToUpload.length; i++) // invalid file check
		{
			fileNameparts = this.filesToUpload[i].name.split('.');
			if (fileTypes.indexOf(fileNameparts[fileNameparts.length - 1]) >= 0) {
				this.validFilesToUpload.push(this.filesToUpload[i])
			}
			else {
				if (this.invalidfiles != '') {
					this.invalidfiles = this.invalidfiles + ', ';
				}
				this.invalidfiles = this.invalidfiles + this.filesToUpload[i].name;
			}
		}

		this.filesToUpload = this.validFilesToUpload;
		return this.filesToUpload.length > 0 ? true : false;
	}

	onSubmit() {
		this.invalidfiles = "";
		this.duplicateFiles = "";
		this.checkForValidFiles();
		if (this.selectedAttachmentList.length > 0) {
			this.checkForDuplicateFiles();
		}
		if (this.checkNeedToUpdateRemediation()) {
			let orgId = null;
			if (this.editRemediationForm.get("orgId").value != null) {
				if (this.editRemediationForm.get("orgId").value.length > 0) {
					orgId = this.editRemediationForm.get("orgId").value[0].id;
				}
			}
			if (this.filesToUpload.length == 0) {
				if (
					(this.invalidfiles != '') && (this.editRemediationForm.value.link == null || this.editRemediationForm.value.link == '') && (this.editRemediationForm.value.note == null || this.editRemediationForm.value.note == '')
					&& (this.editRemediationForm.value.coursenumber == null || this.editRemediationForm.value.coursenumber == '' || this.editRemediationForm.value.coursedescription == null || this.editRemediationForm.value.coursedescription == '') &&
					this.selectedAttachmentList.length == 0
				) {
					this.alertServices.clear();
					this.alertServices.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
				}
				else if (
					(this.invalidfiles == '') && (this.editRemediationForm.value.link == null || this.editRemediationForm.value.link == '') && (this.editRemediationForm.value.note == null || this.editRemediationForm.value.note == '')
					&& (this.editRemediationForm.value.coursenumber == null || this.editRemediationForm.value.coursenumber == '' || this.editRemediationForm.value.coursedescription == null || this.editRemediationForm.value.coursedescription == '') &&
					this.selectedAttachmentList.length == 0
				) {
					this.alertServices.clear();
					this.alertServices.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS);
				} else {
					//need to update the remediation
					const tempremediation = new remediation(
						this.editRemediation.id,
						this.editRemediationForm.value.medium,
						this.editRemediationForm.value.description,
						this.editRemediationForm.value.type,
						this.editRemediationForm.value.coursenumber,
						this.editRemediationForm.value.coursedescription,
						this.editRemediationForm.value.link,
						orgId,
						this.editRemediationForm.value.note,
						""
					);
					tempremediation.tagList = this.items;
					tempremediation.removedAttachments = this.removedFiles;
					this.remediationService.editRemediation(tempremediation).subscribe(
						data => {
							this.alertServices.clear();
							this.alertServices.success(data.message);
							this.editRemediation.medium = this.editRemediationForm.value.medium;
							this.editRemediation.type = this.editRemediationForm.value.type;
							this.editRemediation.description = this.editRemediationForm.value.description;
							this.editRemediation.courseDescription = this.editRemediationForm.value.coursedescription;
							this.editRemediation.orgId = orgId;
							this.editRemediation.note = this.editRemediationForm.value.note;
							this.editRemediation.attachment = this.selectedAttachmentList.length;
							this.editRemediation.tagList = [];
							this.items.forEach(tag => {
								let item = { value: tag.value, id: tag.id };
								this.editRemediation.tagList.push(item);
							});
						},
						error => {
							this.alertServices.clear();
							let err = error.json();
							// console.log(err);
							this.alertServices.handleError(err);
						}
					);
				}
			} else {
				this.uploadFileData = [];
				this.remediationService
					.makeFileRequest([], this.filesToUpload)
					.subscribe(
					data => {
						const tempremediation = new remediation(
							this.editRemediation.id,
							this.editRemediationForm.value.medium,
							this.editRemediationForm.value.description,
							this.editRemediationForm.value.type,
							this.editRemediationForm.value.coursenumber,
							this.editRemediationForm.value.coursedescription,
							this.editRemediationForm.value.link,
							orgId,
							this.editRemediationForm.value.note,
							''
						);
						tempremediation.removedAttachments = this.removedFiles;
						//this.editRemediation.attachment = this.selectedAttachmentList.length;
						tempremediation.tagList = this.items;
						this.filesToUpload = []
						this.remediationService
							.editRemediationWithUploadFile(tempremediation, data.file)
							.subscribe(
							data => {
								this.alertServices.clear();
								let message = data.message;
								if (this.invalidfiles != '') {
									message = message + ' except invalid file(s) ' + this.invalidfiles;
								}
								if (this.duplicateFiles != '') {
									if (this.invalidfiles != '') {
										message = message + ' and duplicated file(s) ' + this.duplicateFiles;
									}
									else {
										message = message + ' except duplicated file(s) ' + this.duplicateFiles;
									}
								}

								for (var i = 0; i < data.uploadedFileData.length; i++) {
									var fileExtension = data.uploadedFileData[
										i
									].fileName.substr(
										data.uploadedFileData[i].fileName.lastIndexOf(".") + 1
										);
									this.selectedAttachmentList.push({
										id: data.uploadedFileData[i].Id,
										text: fileExtension,
										fileName: data.uploadedFileData[i].fileName
									});
									this.filesToUpload = []
								}

								this.editRemediation.medium = this.editRemediationForm.value.medium;
								this.editRemediation.type = this.editRemediationForm.value.type;
								this.editRemediation.description = this.editRemediationForm.value.description;
								this.editRemediation.courseDescription = this.editRemediationForm.value.coursedescription;
								this.editRemediation.orgId = orgId
								this.editRemediation.note = this.editRemediationForm.value.note;
								this.el.nativeElement.value = "";
								this.editRemediation.attachment = this.selectedAttachmentList.length;
								this.alertServices.success(message);
							},
							error => {
								this.alertServices.clear();
								let err = error.json();
								// console.log(err);
								this.alertServices.handleError(err);
							}
							);
					},
					error => {						
					}
					);
			}
		} else {
			this.alertServices.clear();
			this.displayAlert();
		}
	}

	public updateLink(input: any) {
		this.alertServices.clear();
	}

	public displayAlert() {
		this.el.nativeElement.value = "";
		if (this.invalidfiles != '' && this.duplicateFiles != '') {
			this.alertServices.error(Constants.INFO_NO_CHANGES + " " + Constants.INFO_INVALID_AND_DUPLICATED_FILES);
		}
		else if (this.invalidfiles != '') {
			this.alertServices.error(Constants.INFO_NO_CHANGES + " " + Constants.INFO_INVALID_FILES);
		}
		else if (this.duplicateFiles != '') {
			this.alertServices.error(Constants.INFO_NO_CHANGES + " " + Constants.INFO_DUPLICATE_FILES);
		}
		else {
			this.alertServices.error(Constants.INFO_NO_CHANGES);
		}
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	fileChangeEvent(fileInput: any) {
		this.alertServices.clear();
		this.filesToUpload = <Array<File>>fileInput.target.files;
	}

	private checkNeedToUpdateRemediation(): boolean {
		let orgId = null;
		if (this.editRemediationForm.get("orgId").value != null) {
			if (this.editRemediationForm.get("orgId").value.length > 0) {
				orgId = this.editRemediationForm.get("orgId").value[0].id;
			}
		}
		
		if (this.editRemediation.medium != this.editRemediationForm.value.medium) {
			return true;
		} else if (this.editRemediation.type != this.editRemediationForm.value.type) {
			return true;
		} else if (this.editRemediation.description != this.editRemediationForm.value.description) {
			return true;
		} else if (this.editRemediation.courseNumber != this.editRemediationForm.value.coursenumber) {
			return true;
		} else if (this.editRemediation.courseDescription != this.editRemediationForm.value.coursedescription) {
			return true;
		} else if (this.editRemediation.link != this.editRemediationForm.value.link) {
			return true;
		} else if ((this.editRemediation.orgId != null && orgId == null) && (this.editRemediation.orgId != "" && orgId == null) ||
			(this.editRemediation.orgId == "" && orgId != null) || (this.editRemediation.orgId != "" && orgId != null && this.editRemediation.orgId != orgId)) {
			return true;
		} else if (this.editRemediation.note != this.editRemediationForm.value.note) {
			return true;
		} else if (this.filesToUpload.length != 0) {
			return true;
		} else if (this.compairArrayForChanges(this.editRemediation.tagList, this.items)) {
			return true;
		} else if (this.removedFiles.length > 0) {
			return true;
		}
		else {
			return false;
		}
	}

	//remove duplicated files
	private checkForDuplicateFiles() {
		let duplicateFileFound: boolean = false;
		this.duplicateFiles = '';
		let nonDuplicatefilesToUpload = [];
		for (var i = 0; i < this.filesToUpload.length; i++) {
			duplicateFileFound = false;
			for (var j = 0; j < this.selectedAttachmentList.length; j++) {
				if (this.filesToUpload[i].name == this.selectedAttachmentList[j].fileName) {
					duplicateFileFound = true;
					if (this.duplicateFiles != '') {
						this.duplicateFiles = this.duplicateFiles + ', ';
					}
					this.duplicateFiles = this.duplicateFiles + this.filesToUpload[i].name;
					break;
				}
			}
			if (!duplicateFileFound) {
				nonDuplicatefilesToUpload.push(this.filesToUpload[i]);
			}
		}
		this.filesToUpload = nonDuplicatefilesToUpload;
		return this.filesToUpload.length > 0 ? true : false;
	}

	public compairArrayForChanges(arrayOld: any, arrayNew: any): boolean {
		// console.log('[compairArrayForChanges] (tagList)' + JSON.stringify(this.items).toString());
		// console.log('[compairArrayForChanges] (tagList)' + JSON.stringify(this.ccModel.tagList).toString());
		let haveChanges: boolean = false;		
		if (arrayOld.length != arrayNew.length) {
			haveChanges = true
		} else {
			let missing = arrayNew.filter(
				function (v) {
					var result = true;
					arrayOld.forEach(function (item) {
						if (v.value == item.value) {
							result = false;
						}
					});
					return result;
				});

			let adding = arrayOld.filter(
				function (v) {
					var result = true;
					arrayNew.forEach(function (item) {
						if (v.value == item.value) {
							result = false;
						}
					});
					return result;
				});
			
			if (missing.length != 0) {
				haveChanges = true
			}
			else if (adding.length != 0) {
				haveChanges = true;
			} else {
				haveChanges = false
			}
		}

		return haveChanges;
	}

	private removeFileClickEvent(id: string, fileName: string, k: any) {
		let fileData = id + '_' + fileName;
		this.removedFiles.push(fileData);
		this.selectedAttachmentList.splice(k, 1);
	}

	fileIconButtonClickEvent(fileName, attachmentId): any {
		//alert()
		var fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
		var mediaType = this.getFileType(fileExtension);
		this.remediationService.getFileContent(fileName, attachmentId, mediaType)
			.subscribe(fileData => {
				//saveAs(fileData, fileName);
				// FileSaver.saveAs(fileData, fileName);
				var anchor = document.createElement("a");
				anchor.href = window.URL.createObjectURL(fileData);
				anchor.download = fileName;
				anchor.target = '_blank';
				anchor.click();
			});

	}

	private getFileType(fileExtension: string) {
		var mediatype;
		if (fileExtension == 'pdf') {
			mediatype = 'application/pdf';
		} else if (fileExtension == 'docx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		} else if (fileExtension == 'doc') {
			mediatype = 'application/msword';
		} else if (fileExtension == 'ppt') {
			mediatype = 'application/vnd.ms-powerpoint';
		} else if (fileExtension == 'pptx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		} else if (fileExtension == 'xls') {
			mediatype = 'application/vnd.ms-excel';
		} else if (fileExtension == 'xlsx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		} else {
		}
		return mediatype;
	}

	//load active remediation tags
	public loadActiveRemediationTags() {
		this.remediationTagService.getActiveRemediationTags().subscribe(
			data => {
				//console.log('[loadActiveTaskTags] ' + JSON.stringify(data).toString());
				this.activeTagList = [];
				data.remediationTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id };
					this.activeTagList.push(item);
				});
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}


	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.items.push(event);
	}

	public removeTags(event: any) {
		// console.log('[removeTags](items be)' + JSON.stringify(this.items).toString());
		// console.log('[removeTags]' + JSON.stringify(event).toString());
		// console.log('[removeTags](items after)' + JSON.stringify(this.items).toString());
		this.items = this.items.filter(tag => tag.id !== event.id);
	}

}
