import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { OverlayToolTipComponent } from '../../util/overlay/overlaytooltip.component'

import { UserService } from '../../user/user.service';
import { SharedService } from '../shared.service';
import { WidgetService } from '../../dashboard/widget.service';
import { AlertService } from '../../util/alert/alert.service';
import { OverlayService } from '../../util/overlay/overlay.service'
import { AppService } from '../../app.service'

import { Constants } from '../../util/const/constants'

declare var require: any;
const { version: appVersion } = require('../../../../package.json');

import { Widget } from '../../dashboard/widget.model'
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	public userRoles = [];
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public stortedUserData = localStorage.getItem('userRoles');
	public disableSwitchUserRole = false;
	appVersion = "";
	public userName: string = localStorage.getItem('userName')
	public userFirstName: string = localStorage.getItem('userFirstName')
	public constants: any
	public showOverlay: boolean = false

	public profileBtnMessage: string = Constants.OERLAY_INS_PROFILE_BTN
	public navToggleBtnMessage: string = Constants.OERLAY_INS_SLIDEBAR_TOGGLE_BTN

	public appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
	public logoSrc: string = ''
	public headerLogoPath: string = environment.server_URL + '/images/header/'

	@ViewChild('userRoleModel', { static: true }) userRoleModel: ModalComponent;

	constructor(private router: Router, private sharedService: SharedService, private userService: UserService, public appService: AppService,
		private widgetService: WidgetService, private alertService: AlertService, private overlay: OverlayService) {
		this.appVersion = appVersion;
		//get dashboard widget list if user already logged
		if (localStorage.getItem('widgetList') != null) {
			// this.getDashboardWidgets()
		}
		this.constants = Constants

		if (this.appInfo.headerLogo != "") {
			this.logoSrc = this.headerLogoPath + '' + this.appInfo.headerLogo
		} else {
			this.logoSrc = 'assets/images/logo/axis.jpg'
		}

	}

	ngOnInit() {
		//parse json into array
		if (this.stortedUserData) {

			this.userRoles = JSON.parse(this.stortedUserData);
			this.selectedUserRole = this.stortedSelectedUserRole != null ? JSON.parse(this.stortedSelectedUserRole) : [];
			if (this.selectedUserRole.length > 0) {
				// console.log('[HeaderComponent/ngOnInit]selectedUserRole : ' + this.selectedUserRole[0][0] + ':' + this.selectedUserRole[0][1]);
			}
		}
		this.getJobProfileOfTheUser();
		//show user role model if user has more than one user role
		if (this.userRoles.length > 1) {
			if (this.selectedUserRole.length == 0) {
				this.router.navigateByUrl('/userrole');
			}
		} else if (this.stortedSelectedUserRole != null) {
			//user has one user role
			// disable switch userroel button
			this.disableSwitchUserRole = true;
			let widgetList = localStorage.getItem('widgetList');
			if (widgetList != null) {
				this.router.navigateByUrl('/home');
			}
			else {
				let selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
				this.sharedService.change('Dashboard');
				this.userService.getAssignedCapabalityList(selectedUserRole[0][1])
					.subscribe(
					data => {
						//get dashboard widgets
						this.widgetService.getDashboardWidgets(selectedUserRole[0][0])
					},
					error => {
					}
					);
			}
		}
		else {
			this.router.navigateByUrl('/user/login');
		}
		this.sharedService.getToggleOverlayValue().subscribe(item => this.showOverlay = !this.showOverlay);
	}

	toggleSidebar() {
		const dom: any = document.querySelector('#sidebar');
		console.log(dom)
		dom.classList.toggle('push-right');
	}

	rltAndLtr() {
		const dom: any = document.querySelector('body');
		dom.classList.toggle('rtl');
	}

	onLoggedout() {
		let appConfig: any = JSON.parse(localStorage.getItem('appInfo'))
		localStorage.clear();
		localStorage.setItem('appInfo', JSON.stringify(appConfig));
	}

	changeLang(language: string) {

	}

	//userrolemodelStartUp button click event
	urmSPclick(roleID: string, roleDescription: string) {
		var value = [roleID, roleDescription];		
		this.selectedUserRole = []
		this.selectedUserRole.push(value);		
		localStorage.setItem('selectedUserRole', JSON.stringify(this.selectedUserRole));
		this.sharedService.userRoleChange(this.selectedUserRole)
		localStorage.removeItem('capability');
		this.userService.getAssignedCapabalityList(this.selectedUserRole[0][1])
			.subscribe(
			data => {
				localStorage.removeItem('widgetList');				
				this.getDashboardWidgets(this.selectedUserRole[0][0])
				// location.reload();
			},
			error => {
			}
			);
	}

	showUserRoles() {
		if (this.userRoles.length > 3) {
			this.userRoleModel.open();
		} else {
			this.userRoleModel.open();
		}
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	/**
	* [getDashboardWidgets get dashboard widgets from server]
	* @return {[type]} [description]
	*/
	private getDashboardWidgets(userRoleId: string) {
		this.widgetService.getUserPreference(userRoleId).subscribe(
			data => {
				if (data.userPreference != null) {
					const widgetList = this.widgetService.generateWidgetList(data.userPreference)
					localStorage.setItem('widgetList', JSON.stringify(widgetList));
					this.sharedService.widgetListChange(widgetList)
					this.userRoleModel.close();
					this.sharedService.change('Dashboard')
					// location.reload();
				} else {
					this.getUserRoleWidgetList(userRoleId)
				}
			},
			error => {

			}
		)
	}

	private getUserRoleWidgetList(userRoleId: string) {
		this.widgetService.getWidgetListByUserRole(userRoleId).subscribe(
			data => {
				const widgetList = this.widgetService.generateWidgetListByUserRole(data.userPreference)
				localStorage.setItem('widgetList', JSON.stringify(widgetList));
				this.sharedService.widgetListChange(widgetList)
				this.userRoleModel.close();
				this.sharedService.change('Dashboard')
				// location.reload();
			},
			error => {
				// no need to handle error
			}
		)
	}

	private getJobProfileOfTheUser() {
		this.userService.getJobProfileOfTheUser().subscribe(
			data => {
				// console.log('[getJobProfileOfTheUser] (userJobProfile) ' + JSON.stringify(data))
				if (data.jobProfile.length != 0) {
					const jp = {
						'id': data.jobProfile[0].Id,
						'text': data.jobProfile[0].Description
					}
					localStorage.setItem('userJobProfile', JSON.stringify(jp));
				} else {
					//do nothing
				}

			},
			error => {

			}
		)
	}

}
