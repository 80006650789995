import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { AssessmentService } from '../assessment.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service';
import { UserService } from '../../user/user.service';
import { AssessmentModel } from '../assessment.model';
import { FormGroup, FormControl } from "@angular/forms";
import * as Highcharts from 'highcharts/highstock';


@Component({
	selector: "app-candidate-competency-graphical-view",
	templateUrl: "./candidatecompetencygraphicalview.component.html"
})
export class CandidateCompetencyGraphicalViewComponent implements OnInit {

	public orgList: Array<any> = [];
	public jobProfileList: Array<any> = [];
	public supervisorList: Array<any> = [];
	public stortedUserData: any = localStorage.getItem('selectedUserRole');
	public userId: any = localStorage.getItem('userId');
	public selectedUserRole = [];
	isLoading: boolean = false;
	dataAvailable: boolean = false;
	firstTime: boolean = false;
	noResults: boolean = false;
	valid: boolean = true;
	isSupervisor: boolean = false;
	filterForm: FormGroup;
	supervisorName: string;
	userList: string[] = [];
	knowledgeTestScoreList: any = [];
	competancyScoreList: any = [];

	chart = Highcharts;
	options;

	allAssessmentParams: any = {
		'orgId': "",
		'jobProfileId': "",
		'supervisorId': ""
	};

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private assessmentService: AssessmentService,
		private jobProfileService: JobProfileService,
		private userService: UserService,
		private orgcodeService: OrgcodeService
	) {
		this.getActiveOrgCodes();
		this.getActiveJobProfiles();
		this.getAllSupervisors();
	}

	private getActiveJobProfiles() {
		this.jobProfileService.getAllActiveJobProfiles()
			.subscribe(
			data => {
				this.jobProfileList = [];
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobProfileList.push({ id: data.jobProfileList[i].Id, text: data.jobProfileList[i].Description });
				}
			},
			error => {

			}
			);
	}

	private getActiveOrgCodes() {
		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
				}
			},
			error => {

			}
			);
	}

	private getAllSupervisors() {
		this.userService.getAllSupervisors("")
			.subscribe(
			data => {
				this.supervisorList = [];
				for (var i = 0; i < data.supervisorsdetails.length; i++) {
					this.supervisorName = data.supervisorsdetails[i].FirstName + ' ' + data.supervisorsdetails[i].LastName;
					this.supervisorList.push({ id: data.supervisorsdetails[i].Id, text: this.supervisorName });
				}
			},
			error => {
			}
			);
	}

	ngOnInit() {
		this.firstTime = true
		this.filterForm = new FormGroup({
			orgId: new FormControl(null),
			jobProfileId: new FormControl(null),
			supervisorId: new FormControl(null)
		});

		if (this.stortedUserData) {
			this.selectedUserRole = JSON.parse(this.stortedUserData);
		}

		if (this.selectedUserRole[0][1] == 'Supervisor') {
			this.isSupervisor = true
		} else {
			this.isSupervisor = false
		}

		this.init()
	}

	onSubmit() {
		this.valid = true
		this.firstTime = false
		let orgId = "";
		if (this.filterForm.get("orgId").value != null) {
			if (this.filterForm.get("orgId").value.length > 0) {
				orgId = this.filterForm.get("orgId").value[0].id;
			}
		}
		let jobProfileId = "";
		if (this.filterForm.get("jobProfileId").value != null) {
			if (this.filterForm.get("jobProfileId").value.length > 0) {
				jobProfileId = this.filterForm.get("jobProfileId").value[0].id;
			}
		}
		let supervisorId = "";
		if (this.filterForm.get("supervisorId").value != null) {
			if (this.filterForm.get("supervisorId").value.length > 0) {
				supervisorId = this.filterForm.get("supervisorId").value[0].id;
			}
		}

		this.allAssessmentParams['orgId'] = orgId
		this.allAssessmentParams['jobProfileId'] = jobProfileId
		this.allAssessmentParams['supervisorId'] = supervisorId
		this.showFilterData(this.allAssessmentParams)

	}

	public clearForm() {
		this.filterForm.reset();
		this.competancyScoreList = []
		this.userList = []
		this.knowledgeTestScoreList = []
		this.allAssessmentParams['orgId'] = ""
		this.allAssessmentParams['jobProfileId'] = ""
		this.allAssessmentParams['supervisorId'] = ""
		this.init()
		this.noResults = false
		this.valid = true
		this.alertService.clear()
		this.isLoading = false
	}

	private showFilterData(param) {
		if (this.selectedUserRole[0][1] == 'Supervisor') {
			if (!this.allAssessmentParams['jobProfileId'] && !this.allAssessmentParams['orgId']) {
				this.valid = false
				this.alertService.clear()
				this.alertService.error("Please select at least one filtering item to see the graph results.")
			}
		} else {
			if (!this.allAssessmentParams['jobProfileId'] && !this.allAssessmentParams['orgId'] && !this.allAssessmentParams['supervisorId']) {
				this.valid = false
				this.alertService.clear()
				this.alertService.error("Please select at least one filtering item to see the graph results.")
			}
		}
		if (this.valid) {
			this.isLoading = true;
			this.alertService.clear()
			this.userList = [];
			this.knowledgeTestScoreList = [];
			this.competancyScoreList = [];
			if (this.selectedUserRole[0][1] == 'Supervisor') {
				this.allAssessmentParams['supervisorId'] = localStorage.getItem('userId');
			}
			this.assessmentService.getLastCompletedAssessmentOfUserWithParams(param)
				.subscribe(
				data => {
					this.isLoading = false;
					for (let i = 0; i < data.assesmentList.length; i++) {
						var fullName = data.assesmentList[i].FirstName + ' ' + data.assesmentList[i].LastName;
						this.userList.push(fullName)
						if (data.assesmentList[i].AssessmentScore != null) {
							this.competancyScoreList.push(data.assesmentList[i].AssessmentScore)
						} else {
							this.competancyScoreList.push(0)
						}
						let knowPre
						if ((data.assesmentList[i].SelfAssessmentCorrect == 0 && data.assesmentList[i].SelfAssessmentTotal == 0)) {
							knowPre = 0
						} else {
							knowPre = Math.round((data.assesmentList[i].SelfAssessmentCorrect / data.assesmentList[i].SelfAssessmentTotal) * 100)
						}
						this.knowledgeTestScoreList.push(knowPre)
					}
					this.init();
					if (data.assesmentList.length > 0) {						
						this.dataAvailable = true
						this.noResults = false
					} else {
						this.dataAvailable = false
						this.noResults = true
					}
				},
				error => {
					this.isLoading = false;
					let err = error.json();
					this.alertService.clear();
					this.alertService.handleError(err);
				}
				);
		}
	}

	init() {
		// let maxNumber = (this.userList.length - 1);

		this.options = {
			chart: {
				type: 'column'
			},
			xAxis: {
				categories: this.userList,
				tickLength: 0,
				uniqueNames: false
			},
			title: {
				text: ''
			},
			yAxis: {
				floor: 0,
				ceiling: 100,
				title: {
					text: 'Percentage'
				}
			},
			plotOptions: {
				column: {
					cropThreshold: 25000,
					minPointLength: 3
				}
			},

			credits: false,
			series: [{
				name: 'Knowledge Check Score',
				cropThreshold: 100000,
				data: this.knowledgeTestScoreList
			}, {
				name: 'Competency Score',
				cropThreshold: 100000,
				data: this.competancyScoreList
			}]
		};

	}

	//navigate to candidate competency tabular view report component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

}