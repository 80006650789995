<div class="pos-f-t fixed-top header" style="border-bottom: 1px solid #ddd;">
	<nav class="navbar navbar-light navbar-toggleable-md">
		<a class="navbar-brand" href="javascript:void(0)">
			<img [src]="logoSrc" />
		</a>
	</nav>
</div>
<div *ngIf=isLoading id="page-wrapper" style="min-height: 524px; margin-top:70px;" >
	<div class="row">
		<div class="col-lg-12">
			<br/>
			<h2 class="page-header">Reset Password</h2>
		</div>
		<div class="mt-2 col-md-12"> &nbsp;</div>

		<div class="col-lg-6">
			<div>
				<form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
					<fieldset class="form-group">

						<div class="form-group">
							<input type="password" class="form-control" placeholder="New Password" minlength="6" pattern="^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$"
							 formControlName="newPwd">
						</div>
						<div style="color: red" *ngIf="passwordResetForm.controls.newPwd.touched">
							<div *ngIf="passwordResetForm.controls.newPwd.hasError('required')">Password is required.</div>
							<div *ngIf="passwordResetForm.controls.newPwd.hasError('minlength')">Your password must contain at least six characters.</div>
							<div *ngIf="passwordResetForm.controls.newPwd.hasError('pattern')">Your password must contain at least one number (0-9) and at least one lower case letter (a-z) and at least one upper
								case letter (A-Z).</div>
						</div>

						<div class="form-group">
							<input type="password" class="form-control" placeholder="Re-type New Password" formControlName="retypeNewPwd">
						</div>
						<div style="color: red" *ngIf="passwordResetForm.controls.retypeNewPwd.touched">
							<div *ngIf="passwordResetForm.hasError('mismatchedPasswords')">Passwords do not match.</div>
						</div>
						<div class="form-group">
							<button class="btn btn-default" [disabled]="!passwordResetForm.valid"> Change Password </button>
						</div>
					</fieldset>
				</form>
			</div>
		</div>
	</div>
</div>

<modal #successModel>
	<modal-header [show-close]="false">
		<h4>Alert</h4>
	</modal-header>
	<modal-body>
		Your password has been changed.
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-default" (click)="clickSuccessBtn()">OK</button>
	</modal-footer>
</modal>

<modal #errorModel>
	<modal-header [show-close]="false">
		<h4>Alert</h4>
	</modal-header>
	<modal-body>
		You have entered an invalid password. please try again.
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-default" data-dismiss="modal" (click)="errorModel.dismiss()">OK</button>
	</modal-footer>
</modal>

<modal #expierModel>
	<modal-header [show-close]="false">
		<h4>Alert</h4>
	</modal-header>
	<modal-body>
		Your password reset link has been expired.
	</modal-body>
	<modal-footer [show-default-buttons]="false">
		<button type="button" class="btn btn-default" data-dismiss="modal" (click)="expierModel.dismiss()">OK</button>
	</modal-footer>
</modal>