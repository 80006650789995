import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ReportTableComponent } from "../reporttable.component";

import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../report.service";

import { Report } from "../report.model";

@Component({
	selector: "app-candidates-report",
	templateUrl: "./candidatesreport.component.html"
})
export class CandidatesReportComponent extends ReportTableComponent
	implements OnInit {
	public report: Report;
	public columns: Array<any>;
	private serverResultData: Array<any> = [];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"],
		report: null
	};

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService
	) {
		super();
		this.report = this.reportService.getSelectedReport();
		this.columns = [
			{
				title: "First Name",
				name: "First Name",
				filtering: { filterString: "" }
			},
			{
				title: "Last Name",
				name: "Last Name",
				filtering: { filterString: "" }
			},
			{
				title: "Contractor",
				name: "Contractor",
				filtering: { filterString: "" }
			},
			{
				title: "Email",
				name: "Email",
				filtering: { filterString: "" }
			},
			{
				title: "Org Code",
				name: "Org Code",
				filtering: { filterString: "" }
			},
			{
				title: "Supervisor Name",
				name: "Supervisor Name",
				filtering: {
					filterString: ""
				}
			},
			{
				title: "Job Profile",
				name: "Job Profile",
				filtering: { filterString: "" }
			},
			{ title: "Last Assessment Completed Date", name: "Last Assessment Completed Date" },
			{ title: "Scheduled Assessment Date", name: "Scheduled Assessment Date" }
		];
		this.config.sorting = { columns: this.columns };
	}

	ngOnInit() {
		this.loadCandidateDataToTable(this.report.reportData);
	}

	/**
	 * [onCellClick cell click event in table]
	 * @param  {any} data [description]
	 * @return {any}      [description]
	 */
	public onCellClick(data: any): any { }
	//no need to impletement event
	private loadCandidateDataToTable(data: any) {
		data.forEach(item => {
			let fname = "";
			if (item.FirstName != null) {
				fname = item.FirstName;
			}
			let lname = "";
			if (item.LastName != null) {
				lname = item.LastName;
			}
			let email = item.Email;
			if (item.Email != null) {
				email = item.Email;
			}
			let oc = "";
			if (item.OrgCode != null) {
				oc = item.OrgCode;
			}
			let supName = "";
			if (item.SupervisorName != null) {
				supName = item.SupervisorName;
			}
			let jp = "";
			if (item.JobProfile != null) {
				jp = item.JobProfile;
			}
			let lacd = null;
			let sasd = null;
			let sad = null;
			if (item.Status == "Completed") {
				if (item.LastAssessmentCompletedDate != null) {
					lacd = new Date(new Date(item.LastAssessmentCompletedDate).getUTCFullYear(), new Date(item.LastAssessmentCompletedDate).getUTCMonth(), new Date(item.LastAssessmentCompletedDate).getUTCDate()).toLocaleDateString();
				} else {
					lacd = "";
				}
				sad = "";
			} else {
				if (item.LastAssessmentCompletedDate != null) {
					lacd = new Date(new Date(item.LastAssessmentCompletedDate).getUTCFullYear(), new Date(item.LastAssessmentCompletedDate).getUTCMonth(), new Date(item.LastAssessmentCompletedDate).getUTCDate()).toLocaleDateString();
				} else {
					lacd = "";
				}
				if (item.ScheduleAssessmentDate != null) {
					sad = new Date(new Date(item.ScheduleAssessmentDate).getUTCFullYear(), new Date(item.ScheduleAssessmentDate).getUTCMonth(), new Date(item.ScheduleAssessmentDate).getUTCDate()).toLocaleDateString();
				} else {
					sad = "";
				}
			}

			this.data.push({
				"First Name": fname,
				"Last Name": lname,
				"Contractor": item.IsContract == 0 ? "No" : "Yes",
				"Email": email,
				"Org Code": oc,
				"Supervisor Name": supName,
				"Job Profile": jp,
				"Last Assessment Completed Date": lacd,
				"Scheduled Assessment Date": sad
			});

			this.config.report = this.report;
			this.report.columns = this.columns;
			this.onChangeTable(this.config);
		});
	}
}
