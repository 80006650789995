<div class="col-lg-12">
  <div class="col" style="overflow:hidden">
    <div class="float-left">
      <h4>Remediation Matrix</h4>
    </div>
    <div class="float-right">
      <div class="row">
      </div>
    </div>
  </div>
  <hr />
</div>
<div *ngIf=isLoading class="loading"></div>
<div class="col-lg-12 mx-auto" >
  <form [formGroup]="remediationMatrixForm" (ngSubmit)="onSubmit()">
    <div class="form-group row mb-0">
      <label class="col-sm-2 col-form-label">Org Code</label>
      <div class="col-sm-8">
        <ng-select [allowClear]="true" [items]="orgList" placeholder="{{ palceholderText }}" (removed)="removeOrgCode($event)" formControlName="remediationmatrix"
          (selected)="selectOrgCode($event)">
        </ng-select>
      </div>
      <div class="col-sm-2">
        <label class="col-form-label">
                    <input type="checkbox" id="includeChilderen" formControlName="includeChildren"
                        name="includeChildren">&nbsp;Include Children
                </label>
      </div>
    </div>
    <div class="form-group row mt-4 mb-2">
      <div class="col-sm-4"></div>
      <div class="col-sm-6"></div>
      <div class="col-sm-2">
        <button type="submit" [disabled]="!isQueryRemediationmatrix" class="btn btn-warning pl-5 pr-5 btn-block mb-1">Show</button>
      </div>
      <!-- <div class="col-sm-2">
                <button (click)="clear()" class="btn btn-default btn-block">Clear</button>
            </div> -->
    </div>
  </form>
  <alert></alert>
  <div class="col-sm-12 alert alert-info ml-1 mr-1" *ngIf="noResults">
    No matching items.
  </div>
</div>
<div *ngIf=showResult class="table-responsive outer" (window:resize)="onResize($event)">
  <div class="inner">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th #firstCell class="fix">&nbsp;</th>
          <th #jobProfile [attr.colspan]="jItem.userList.length" *ngFor="let jItem of remediationMatrixData | orderBy : 'alphabeticRemediationMatrixJobprofile'">
            {{ jItem. jobprofile}}
          </th>
          <th rowspan="2">Total</th>
        </tr>
        <tr>
          <th #secondCell class="fix">Remediation</th>
          <ng-container *ngFor="let jItem of remediationMatrixData; let k = index;">
            <th #userName *ngFor="let uItem of jItem.userList | orderBy : 'alphabeticRemediationMatrixUser'">
              {{ uItem.userFullName }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let remItem of remListWithoutDuplicate">
          <td #row class="fix">{{ remItem.description }}</td>
          <ng-container *ngFor="let jItem of remediationMatrixData">
            <td #rem [class]="getStyle(uItem.id, remItem.userList, remItem.score)" *ngFor="let uItem of jItem.userList | orderBy : 'alphabeticRemediationMatrixUser'"></td>
          </ng-container>
          <td style="text-align: center; vertical-align: middle; font-weight:bold;">{{ remItem.remCount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-sm-12">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
		 [boundaryLinks]="true" (pageChanged)="pageChanged($event.page)"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
	</div>
</div>
<br>
