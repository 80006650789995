import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NoteService } from './note.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { Note } from './note.model';
import { Task } from '../task/task.model';
import { CoreCompetency } from '../corecompetency/corecompetency.model'
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-addnote',
	templateUrl: './addnote.component.html'
})

export class AddNoteComponent implements OnInit {

	addNewNoteForm: FormGroup;

	public autocompleteItemsAsObjects = [];
	public selectedTags = [];

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private noteService: NoteService,
		private userService: UserService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.addNewNoteForm = new FormGroup({
			note: new FormControl(null, Validators.required),
			noteTag: new FormControl(null)
		});
	}

	//navigate to other component
	cancel() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('AllNote');
		}
	}

	saveAndNew() {
		let note: string = this.addNewNoteForm.value.note;
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		this.addNote(note)
	}

	save() {
		let note: string = this.addNewNoteForm.value.note;
		let tempNote = new Note('', note, true);
		this.noteService.addNewNote(tempNote)
			.subscribe(
			data => {
				// console.log('[addJPNote] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success(data.message);
				this.cancel();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	//add note to DB (not job profile)
	private addNote(noteText: string) {
		let tempNote = new Note('', noteText, true);
		this.noteService.addNewNote(tempNote)
			.subscribe(
			data => {
				// console.log('[addJPNote] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success(data.message);
				// this.addNewNoteForm.reset();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}
}
