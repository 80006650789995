/**
 * This component work as parent component to view
 * self assessment and assessment remediation reports
 */
import { Component, OnInit } from "@angular/core";
import { Constants } from '../../util/const/constants';
import { UserService } from '../../user/user.service';
import { AlertService } from '../../util/alert/alert.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AssessmentService } from '../../assessment/assessment.service';
import { SharedService } from '../../home/shared.service'
import { AssessmentModel } from '../../assessment/assessment.model';

@Component({
	selector: "app-remediation-report",
	templateUrl: "./remediationreport.component.html"
})
export class RemediationReportComponent implements OnInit {
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public title: string = '';
	public candidateList: Array<any> = [];
	public showDropDown: boolean = false;
	public user: any;
	public userName: any;
	public viewUserRemediationForm: FormGroup;
	public showReport: boolean = false;
	public assessmentId: any;
	public jobProfileId: any;
	public jobProfileDesc: any;
	public showCompletedDate: boolean = false;
	public showJobProfile: boolean = false;
	public assessmentCompletedDate: Date = null;
	public assessmentModel: AssessmentModel = null;
	public selectedUserId: any = null
	public showName: boolean = false

	constructor(private userService: UserService, private alertService: AlertService, private sharedService: SharedService,
		private assessmentService: AssessmentService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
			if (this.selectedUserRole[0][1] != 'Candidate') {
				this.title = Constants.REMEDIATION_REPORT;
				this.selectedUserId = assessmentService.getSelectedUserId();
				this.getActiveCandidates();
				this.showDropDown = true;

				if (this.selectedUserId) {
					this.showDropDown = false;
					this.showCompletedDate = true;
					this.showJobProfile = true;
					this.assessmentModel = assessmentService.getSelectedAssessmentModel();
					this.assessmentId = this.assessmentModel.id;
					this.jobProfileId = this.assessmentModel.jobProfileId;
					this.jobProfileDesc = this.assessmentModel.jobProfileDesc;
					this.assessmentCompletedDate = this.assessmentModel.assessmentCompletedDate;
					this.showReport = true;
					this.showName = true;
					this.userName = this.assessmentModel.userName
					assessmentService.setSelectedUserId(null)
					assessmentService.setSelectedAssessmentModel(null)
				}
			}
			else {
				this.title = Constants.MY_REMEDIATION_REPORT;
				this.user = localStorage.getItem('userId');
				this.userName = localStorage.getItem('userName')
				if (localStorage.getItem('selectedMenuItemNo') == '72') { //load latest completed assessment
					this.showReport = false;
					this.showCompletedDate = false;
					this.showJobProfile = false;
					this.loadLastAssessmentDetailsbyUser();
				}
				else { //load data from histry
					this.showCompletedDate = true;
					this.showJobProfile = true;
					this.assessmentModel = assessmentService.getSelectedAssessmentModel();
					this.assessmentId = this.assessmentModel.id;
					this.jobProfileId = this.assessmentModel.jobProfileId;
					this.jobProfileDesc = this.assessmentModel.jobProfileDesc;
					this.assessmentCompletedDate = this.assessmentModel.assessmentCompletedDate;
					this.showReport = true;
				}
			}
		}
	}

	onSubmit() {
		if (this.viewUserRemediationForm.get("user").value != null) {
			if (this.viewUserRemediationForm.get("user").value.length > 0) {
				this.user = this.viewUserRemediationForm.get("user").value[0].id;
				this.userName = this.viewUserRemediationForm.get("user").value[0].text;
				this.loadLastAssessmentDetailsbyUser();
			}
		}
	}

	ngOnInit() {
		this.sharedService.getItem().subscribe(item => this.reload())
		this.viewUserRemediationForm = new FormGroup({
			user: new FormControl(null, Validators.required)
		});


	}

	private getActiveCandidates() {
		var supervisorId = '';
		if (this.selectedUserRole[0][1] == 'Supervisor') {
			supervisorId = localStorage.getItem('userId');
		}
		this.userService.getActiveCandidates(supervisorId)
			.subscribe(
			data => {
				this.candidateList = [];
				for (var i = 0; i < data.candidateList.length; i++) {
					this.candidateList.push({ id: data.candidateList[i].Id, text: data.candidateList[i].FirstName + " " + data.candidateList[i].LastName });
				}
				this.user = null;
			},
			error => {
				this.alertService.clear();
				this.alertService.error(error);
			}
			);
	}

	public selectedUser(event: any) {
		this.alertService.clear();
		this.showReport = false;
	}

	public removedUser(event: any): void {
		this.viewUserRemediationForm.get("user").setValue(null);
		this.showReport = false;
		this.alertService.clear();
	}

	private reload() {
		this.showReport = false;
		this.showCompletedDate = false;
		this.showJobProfile = false;
		this.loadLastAssessmentDetailsbyUser();
	}

	private loadLastAssessmentDetailsbyUser() {
		let param: any = {
			'orgId': '', 'includeChildren': false,
			'jobProfileId': '', 'disciplineId': '',
			'option': '', 'includeNonAssessed': true,
			'userId': this.user, 'includeCompleted': false
		};
		this.assessmentService.getAllAssessmentBySelectedParameters(param).subscribe(
			data => {
				if (data.userList.length > 0 && (data.userList[0].lastCompletedassessmentId != null && data.userList[0].lastCompletedassessmentJPId != null)) {
					this.assessmentId = data.userList[0].lastCompletedassessmentId;
					this.jobProfileId = data.userList[0].lastCompletedassessmentJPId;
					this.assessmentCompletedDate = data.userList[0].LastAssessmentCompletedDate;
					this.showReport = true;
				}
				else {
					if (this.showDropDown) {
						this.alertService.clear();
						this.alertService.error(Constants.ERROR_HAS_NO_COMPLETED_ASSESSMENT);
					}
					else {
						this.alertService.clear();
						this.alertService.error(Constants.ERROR_HAS_NO_COMPLETED_ASSESSMENT_FOR_CANDIDATE);
					}
				}
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		);
	}
}
