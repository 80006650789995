import { Component, OnInit, ViewChildren, QueryList, ElementRef, Renderer2, ContentChildren, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { RemediationService } from '../../complib/remediation/remediation.service';
import { TableComponent } from '../../util/table/table.component'
import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from 'mydatepicker';
import { Constants } from '../../util/const/constants';
import { OrgcodeService } from '../../orgcode/orgcode.service'
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';

@Component({
	selector: "app-remediation-matrix-report",
	templateUrl: "./remediationmatrixreport.component.html",
	styleUrls: ['./remediationmatrixreport.component.scss']
})

export class RemediationMatrixReportComponent implements OnInit, AfterViewInit {
	public orgList: Array<any> = [];
	public remediationMatrixData = [];
	public allRemediationList = [];
	public remListWithoutDuplicate = [];
	public allUsersWithRemediation = [];
	public filteredData: Array<any> = [];
	public palceholderText: any;
	public selectedOrgId: string = null;
	public isQueryRemediationmatrix: boolean = false;
	public isLoading: boolean = false;
	public noResults: boolean = false;
	public showResult: boolean = false;
	public maxCounter;
	public userCount: number = 0;
	public supervisorId = '';

	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');

	public remediationMatrixForm: FormGroup
	@ViewChildren('firstCell') firstCell: QueryList<ElementRef>;
	@ViewChildren('jobProfile') jobProfile: QueryList<ElementRef>;
	@ViewChildren('secondCell') secondCell: QueryList<ElementRef>;
	@ViewChildren('userName') userName: QueryList<ElementRef>;
	@ViewChildren('row') row: QueryList<ElementRef>;
	@ViewChildren('rem') rem: QueryList<ElementRef>;

	constructor(private remediationService: RemediationService,
		private alertService: AlertService, private orgcodeService: OrgcodeService, private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef, private pipe: OrderArrayByType) {

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
			if (this.selectedUserRole[0][1] == 'Supervisor') {
				this.supervisorId = localStorage.getItem('userId');
			}
		}
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.remListWithoutDuplicate = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	ngOnInit() {
		//load active org code
		this.loadActiveOrgCode()
		//define form
		this.remediationMatrixForm = new FormGroup({
			remediationmatrix: new FormControl(null),
			includeChildren: new FormControl(false),
		});
	}

	ngAfterViewInit() {

	}

	ngAfterViewChecked() {
		this.firstCell.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.firstCell = comps
		});

		this.secondCell.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.secondCell = comps
		});
		this.row.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.row = comps
		});

		this.userName.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.userName = comps;
			this.renderer.setStyle(
				this.secondCell.first.nativeElement,
				'height',
				comps.first.nativeElement.offsetHeight + 'px'
			);
		});

		this.jobProfile.changes.subscribe((comps: QueryList<ElementRef>) => {
			console.log(comps.first.nativeElement.offsetHeight)
			this.jobProfile = comps;
			this.renderer.setStyle(
				this.firstCell.first.nativeElement,
				'height',
				comps.first.nativeElement.offsetHeight + 'px'
			);
		});

		this.rem.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.rem = comps;
			let rowIndex = 0;
			let remIndex = 0;
			comps.forEach((element, index) => {
				if (index == remIndex) {
					// console.log(index + " == " + remIndex)
					let rowHeight = this.row.filter((ele, i) => i === rowIndex)[0].nativeElement.offsetHeight
					// console.log(rowHeight)
					this.renderer.setStyle(
						element.nativeElement,
						'height',
						rowHeight + 'px')
					remIndex = remIndex + this.userCount
					rowIndex++
				}
			}
			);
		});
	}

	//call form submission
	onSubmit() {
		this.noResults = false
		this.remediationMatrixData = []
		this.remListWithoutDuplicate = []
		this.showResult = false
		this.isLoading = true
		this.remediationService.getRemediationByLastAssessmentOfUser(this.selectedOrgId, this.remediationMatrixForm.get("includeChildren").value, this.supervisorId)
			.subscribe(
			data => {
				this.isLoading = false
				this.remediationMatrixData = []
				this.allRemediationList = []
				this.remListWithoutDuplicate = []
				this.allUsersWithRemediation = []
				if (data.userList.length > 0) {
					this.noResults = false
					this.showResult = true
					this.userCount = data.userList.length
					for (var i = 0; i < data.userList.length; i++) {
						let userList = []
						let id = data.userList[i].userId
						let userFirstName = data.userList[i].userFirstName
						let userLastName = data.userList[i].userLastName
						let jobprofile = data.userList[i].jobProfileDescription
						let jobProfileId = data.userList[i].jobProfieId
						let remediationList = data.userList[i].remediationList
						let userFullName = userFirstName + " " + userLastName
						let userItem = { id, userFirstName, userLastName, userFullName, remediationList }
						userList.push(userItem)
						this.allUsersWithRemediation.push(userItem)
						remediationList.forEach(item => {
							this.allRemediationList.push({ 'id': item.Id, 'description': item.Description })
						});
						//getting same job profile data
						if (!this.addToExisting(jobProfileId, userItem)) {
							this.remediationMatrixData.push({ jobProfileId, jobprofile, userList })
						}
					}
					this.remListWithoutDuplicate = this.getFrequencyOfRemediation(this.allRemediationList, this.allUsersWithRemediation)
					this.filteredData = this.remListWithoutDuplicate
					this.filteredData = this.pipe.transform(this.filteredData, 'alphabeticRemediationMatrixRemediation')
					this.pageChanged();

				} else {
					this.noResults = true
					this.showResult = false
				}
			},
			error => {
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	//getting same job profile data
	addToExisting(jobProfileId: any, userItem: any) {
		var found: boolean = false;
		for (var i = 0; i < this.remediationMatrixData.length; i++) {
			var temp = this.remediationMatrixData[i].jobProfileId
			if (temp == jobProfileId) {
				found = true
				this.remediationMatrixData[i].userList.push(userItem)
				break
			}
		}
		return found;
	}

	//get remediation frequency count and higest count
	private getFrequencyOfRemediation(remediationlist: any, userList: any) {
		let visited = -1
		let fr = []
		let maxCounter = 0;
		for (var i = 0; i < remediationlist.length; i++) {
			let count = 1
			let counter = 1;
			for (let j = i + 1; j < remediationlist.length; j++) {
				if (remediationlist[i].id == remediationlist[j].id) {
					count++
					counter++;
					//To avoid counting same element again  
					fr[j] = visited
				}
			}
			if (fr[i] != visited) {
				fr[i] = count
			}
			if (maxCounter < counter) {
				maxCounter = counter;
			}
		}
		for (var i = 0; i < fr.length; i++) {
			if (fr[i] != visited) {
				let userRemList = this.getRemediationUserList(remediationlist[i].id, userList)
				let score = Math.round((fr[i] / maxCounter) * 100)
				this.remListWithoutDuplicate.push({ 'id': remediationlist[i].id, 'description': remediationlist[i].description, 'remCount': fr[i], 'score': score, 'userList': userRemList })
			}
		}
		return this.remListWithoutDuplicate
	}

	private getRemediationUserList(remId: any, userList: any) {
		var remuserlist = []
		for (var i = 0; i < userList.length; i++) {
			userList[i].remediationList.forEach(item => {
				if (item.Id == remId) {
					remuserlist.push({ 'userId': userList[i].id })
				}
			});
		}
		return remuserlist
	}

	//start cell coloring logic
	getStyle(userId: any, remList: any, score: any) {
		let style = ''
		let found: boolean = false;
		for (let i = 0; i < remList.length; i++) {
			if (remList[i].userId == userId) {
				found = true
				break
			}
		}
		style = this.selectStyle(found, score)
		return style
	}

	private selectStyle(found: boolean, score: any) {
		let style = ''
		if (found == true) {
			if (1 <= score && score <= 33) {
				style = " text-center cell-green";
			} else if (34 <= score && score <= 66) {
				style = " text-center cell-yellow";
			} else if (67 <= score && score <= 100) {
				style = " text-center cell-red";
			} else {
				style = ""
			}
		} else {
			style = ''
		}
		return style
	}

	private loadActiveOrgCode() {
		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
				}
				if (this.orgList.length > 0) {
					this.palceholderText = 'Select org code'
				} else {
					this.palceholderText = 'No items available'
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}

	public removeOrgCode(event: any) {
		this.isQueryRemediationmatrix = false
		this.noResults = false
		this.remediationMatrixData = []
		this.showResult = false
	}

	public selectOrgCode(event: any) {
		this.isQueryRemediationmatrix = true
		this.selectedOrgId = event.id
		this.noResults = false
	}

	public onResize(event: any) {
		this.noResults = false
		let temp = this.remediationMatrixData
		let tempRemList = this.remListWithoutDuplicate
		this.remediationMatrixData = []
		this.remListWithoutDuplicate = []
		this.showResult = false
		this.isLoading = true
		this.remediationMatrixData = temp
		this.remListWithoutDuplicate = tempRemList
		this.isLoading = false
		this.showResult = true

		this.renderer.setStyle(
			this.secondCell.first.nativeElement,
			'height',
			this.userName.first.nativeElement.offsetHeight + 'px'
		);

		this.renderer.setStyle(
			this.firstCell.first.nativeElement,
			'height',
			this.jobProfile.first.nativeElement.offsetHeight + 'px'
		);

		let rowIndex = 0;
		let remIndex = 0;
		this.rem.forEach((element, index) => {
			if (index == remIndex) {
				// console.log(index + " == " + remIndex)
				let rowHeight = this.row.filter((ele, i) => i === rowIndex)[0].nativeElement.offsetHeight
				// console.log(rowHeight)
				this.renderer.setStyle(
					element.nativeElement,
					'height',
					rowHeight + 'px')
				remIndex = remIndex + this.userCount
				rowIndex++
			}
		}
		);

	}

}