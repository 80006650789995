import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Task } from '../task.model';
import { TaskService } from '../task.service';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../home/shared.service';
import { UserService } from '../../../user/user.service';
import { NoWhitespaceDirective } from "../../../util/validator/nowhitespace.directive"

@Component({
	selector: 'app-edittaskgroup',
	templateUrl: './edittaskgroup.component.html'
})
export class EditTaskGroupComponent implements OnInit {

	public editTaskGroup: Task;
	public taskGroupStatus: boolean;
	editTaskGroupForm: FormGroup;

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private taskService: TaskService,
		private userService: UserService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.editTaskGroup = taskService.getSelectedTaskGroupForEdit();
		this.taskGroupStatus = this.editTaskGroup.status;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.

		this.editTaskGroupForm = new FormGroup({
			id: new FormControl(this.editTaskGroup.id, Validators.required),
			taskGroup: new FormControl(this.editTaskGroup.text, Validators.required)
		});
	}

	//navigate to all task component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedtoUpdateTaskGroup()) {
			let tempTaskGroup = new Task(this.editTaskGroup.id, this.editTaskGroupForm.value.taskGroup);

			this.taskService.updateTaskGroupByTaskGroupId(tempTaskGroup)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);

		} else {
			//no need to update the task no changes
			this.alertService.clear();
			this.alertService.error('There are no changes to update.')
		}
	}

	checkNeedtoUpdateTaskGroup(): boolean {
		if (this.editTaskGroup.text != this.editTaskGroupForm.value.taskGroup) {
			return true;
		} else {
			return false;
		}
	}
}