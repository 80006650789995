import { Component } from '@angular/core'
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal'
import { TableComponent } from '../../../util/table/table.component'

import { Task } from '../../task/task.model';
import { UserService } from '../../../user/user.service';
import { SharedService } from '../../../home/shared.service';
import { AlertService } from '../../../util/alert/alert.service';
import { TaskService } from '../../task/task.service';
import { CustomModalService } from '../../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
	selector: 'app-alltaskgroup',
	templateUrl: './alltaskgroup.component.html'
})
export class AllTaskGroupComponent extends TableComponent {

	isDeleteTaskGroup: boolean;
	isModifyTaskGroup: boolean;
	isAddTaskGroup: boolean;
	isQueryTaskGroup: boolean;

	public selectedTaskGroup: any;
	public selectedTaskStatus: boolean = true;
	public confirmationBtnClass: string = 'btn-default';

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	//this array store all the data from the server
	private serverResultData: Array<any> = [];
	showHideForm: FormGroup;
	checked: boolean = false;

	public columns: Array<any> = [
		{ title: 'Task Group', name: 'taskGroup', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'status', className: 'accepter-col-action' },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];
	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};
	constructor(private userService: UserService, private sharedService: SharedService, private alertService: AlertService,
		private taskService: TaskService, private customModalService: CustomModalService) {
		super()
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddTaskGroup = userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);
		this.isModifyTaskGroup = userService.isCapabilityAssigned('ModifyCompLib', this.selectedUserRole[0][1]);
		this.isDeleteTaskGroup = userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryTaskGroup = userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);
	}

	//navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		if (this.isQueryTaskGroup) {
			//load data
			this.loadAllShowTaskGroups();
		} else {
			//do nothing
		}

		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
	}

	private loadAllTaskGroups() {
		this.taskService.getAllTaskGroups()
			.subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.taskGroupList.length; i++) {
					this.data.push({
						'id': i,
						'taskGroupId': data.taskGroupList[i].Id,
						'taskGroup': data.taskGroupList[i].TaskGroup,
						'status': data.taskGroupList[i].Status,
						'showHide': data.taskGroupList[i].showHide,
						'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				
				this.serverResultData = data.taskGroupList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {

			//check user has modify capability
			if (this.isModifyTaskGroup) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task
				if (this.data[i].status == 1) {
					this.data[i].status = '<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					if (this.serverResultData[i].ShowHide == 0) {
						this.data[i].status = '<a class="btn btn-sm disabled" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].status = '<a class="btn btn-sm" style="cursor: pointer"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				}
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				//show status of the task group
				if (this.data[i].status == 1) {
					this.data[i].status = '<a class="btn btn-sm disabled"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].status = '<a class="btn btn-sm disabled" ><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}
			//check user has delete capability
			if (this.isDeleteTaskGroup) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.serverResultData[i].ShowHide == 1) {
				if (this.serverResultData[i].Status == 0) {
					if (this.isModifyTaskGroup) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyTaskGroup) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}

	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		var elementnumber = parseInt(data.row.id);
		this.selectedTaskGroup = this.serverResultData[elementnumber];
		this.selectedTaskStatus = this.serverResultData[elementnumber].Status;
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = 'btn-danger'
		} else {
			this.confirmationBtnClass = 'btn-success'
		}
		// console.log('[onCellClick] (selectedUserId) : ' + this.selectedUserId);
		switch (data.column) {
			case 'editbutton':
				//check user has modify/edit capability
				if (this.isModifyTaskGroup) {
					this.editButtonClickEvent(this.selectedTaskGroup);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				//check user has delete capability
				if (this.isDeleteTaskGroup) {
					this.deleteButtonClickEvent(this.selectedTaskGroup.Id);
				} else {
					//do nothing
				}
				break;
			case 'status':
				if (this.isModifyTaskGroup) {
					if (this.serverResultData[elementnumber].ShowHide == 0) {
						// do nothing
					} else {
						this.statusButtonClickEvent(this.selectedTaskGroup.Id, this.serverResultData[elementnumber].Status);
					}
				} else {
					//do nothing
				}
				break;
			case "showhidebutton":
				//check delete capability
				if (this.isModifyTaskGroup) {
					if (this.serverResultData[elementnumber].Status == 0 || this.serverResultData[elementnumber].ShowHide == 0) {
						this.showHideButtonClickEvent(this.selectedTaskGroup.Id, this.serverResultData[elementnumber].ShowHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	private statusButtonClickEvent(id: string, status: number) {
		let itemType = "Task Group";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private deleteButtonClickEvent(id: string) {
		let itemType = "Task Group";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	private editButtonClickEvent(selectedTaskGroup: any) {
		let taskGroup: Task = new Task(selectedTaskGroup.Id, selectedTaskGroup.TaskGroup);
		this.taskService.setSelectedTaskGroupForEdit(taskGroup);
		this.sharedService.change('EditTaskGroup');
	}

	//delete button click event
	delete() {
		const tempTaskGroup = new Task(this.selectedTaskGroup.Id, this.selectedTaskGroup.Task, this.selectedTaskGroup.Status);
		this.taskService.deleteTaskGroup(tempTaskGroup)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	changeStatus() {
		const tempTaskGroup = new Task(this.selectedTaskGroup.Id, this.selectedTaskGroup.Task, !this.selectedTaskGroup.Status);
		this.taskService.updateTaskGroupStatusByTaskGroupId(tempTaskGroup)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	private loadAllShowTaskGroups() {
		this.taskService.getAllShowTaskGroups()
			.subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.taskGroupList.length; i++) {
					this.data.push({
						'id': i,
						'taskGroupId': data.taskGroupList[i].Id,
						'taskGroup': data.taskGroupList[i].TaskGroup,
						'status': data.taskGroupList[i].Status,
						'showHide': data.taskGroupList[i].showHide,
						'editbutton': '', 'deletebutton': '', 'showhidebutton': ''
					});
				}
				
				this.serverResultData = data.taskGroupList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
				this.showHideForm.reset();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	showHide(event) {		
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryTaskGroup) {
				this.loadAllTaskGroups();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowTaskGroups();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Task Group";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	loadData() {
		if (this.checked) {
			this.loadAllTaskGroups();
		}
		else {
			this.loadAllShowTaskGroups();
		}
	}

	public showHideEvent(taskGroupId: string, showHide: number) {
		this.taskService.changeTaskGroupShowHide(taskGroupId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}
}