import { Component, Input } from '@angular/core'

import { OverlayService } from './overlay.service'

@Component({
   selector: 'app-overlay-tooltip',
   templateUrl: './overlaytooltip.component.html',
   styles: ['./overlaytooltip.component.scss']
})
export class OverlayToolTipComponent {
   @Input() public side: string = ''
   @Input() public message: string = ''

   constructor(public overlayService: OverlayService) {

   }
}