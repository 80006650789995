import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { ChangeThemeService } from './changetheme.service'
import { AlertService } from '../util/alert/alert.service'
import { Constants } from '../util/const/constants'

import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-change-template',
	templateUrl: './changetheme.component.html',
	styleUrls: ['./changetheme.component.scss']
})
export class ChangeTheme implements OnInit {

	@ViewChild('faviconInput', { static: true }) faviconEL: ElementRef
	@ViewChild('loginLogoInput', { static: true }) loginLogoEL: ElementRef
	@ViewChild('headerLogoInput', { static: true }) headerLogoEL: ElementRef

	public themeArray: Array<any> = [
		{
			'name': 'Red Theme',
			'color': 'red',
			'position': 1
		},
		{
			'name': 'Green Theme',
			'color': 'green',
			'position': 2
		},
		{
			'name': 'Blue Theme',
			'color': 'blue',
			'position': 3
		},
		{
			'name': 'Yellow Theme',
			'color': 'yellow',
			'position': 4
		}
	];
	appConfigForm: FormGroup
	filesToUpload: Array<any> = [];
	public themeColor: string = ''
	public applicationName: string = ''
	public appConfig: any = JSON.parse(localStorage.getItem('appInfo'))

	public faviconItem: any = null
	public loginItem: any = null
	public headerItem: any = null

	public faviconIconPath: string = environment.server_URL + '/images/favicon/'
	public loginLogoPath: string = environment.server_URL + '/images/login/'
	public headerLogoPath: string = environment.server_URL + '/images/header/'

	constructor(private changeThemeService: ChangeThemeService, private alertService: AlertService, @Inject(DOCUMENT) private _document: HTMLDocument) {		
		this.themeColor = this.appConfig.themeColor
		this.applicationName = this.appConfig.applicationName
		this.filesToUpload = [];
	}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.appConfigForm = new FormGroup({
			applicationName: new FormControl(this.applicationName, Validators.required)
		});
	}

	public changeTheme(theme: any) {		
		this.themeColor = theme.color
		// this.setStyle(theme.color)
	}

	public setStyle(faviconName: string) {
		switch (faviconName) {
			case 'yellow':
				window['switchStyle']('Yellow');
				break;
			case 'red':
				window['switchStyle']('Red');
				break;
			case 'blue':
				window['switchStyle']('Blue');
				break;
			case 'green':
				window['switchStyle']('Green');
				break;
			default:
				break;
		}
	}

	public saveTheme() {
		let newAppName: string = this.appConfigForm.value.applicationName
		let newThemeColor: string = this.themeColor
		// console.log('[saveTheme] (newAppName/newThemeColor) ' + JSON.stringify(newAppName + '/' + newThemeColor));
		this.changeThemeService.updateAppConfig(newAppName, newThemeColor).subscribe(
			data => {
				this.setStyle(newThemeColor)
				this.changeLocalStorage(newAppName, newThemeColor)
				this.alertService.clear();
				this.alertService.success(Constants.SUCCESS_CHANGE_THEME);
			},
			error => {
				this.alertService.clear();
				this.alertService.handleError(error);
			}
		)

	}

	fileChangeEvent(fileInput: any, type: string) {
		let image: File = fileInput.target.files[0]
		let item: any = {
			type: type,
			file: image
		}
		this.filesToUpload.push(item)

		switch (type) {
			case 'favicon':
				this.faviconItem = item
				break;
			case 'loginLogo':
				this.loginItem = item
				break;
			case 'headerLogo':
				this.headerItem = item
				break;
		}

	}

	public checkValid(): boolean {
		let result: boolean = false

		let newAppName: string = this.appConfigForm.value.applicationName
		let newThemeColor: string = this.themeColor

		if (newAppName != this.appConfig.applicationName) {
			result = true
		} else {
		}

		if (newThemeColor != this.appConfig.themeColor) {
			result = true
		} else {
		}
		return result
	}

	changeLocalStorage(newAppName: string, newThemeColor: string) {
		let appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
		let newAppConfig: any = {
			'applicationName': newAppName,
			'themeColor': newThemeColor,
			'favicon': appInfo.favicon,
			'loginLogo': appInfo.loginLogo,
			'headerLogo': appInfo.headerLogo
		}
		this.appConfig = newAppConfig
		localStorage.setItem('appInfo', JSON.stringify(newAppConfig));
	}

	changeLocalStorageImages(type: string, fileName: string) {
		let appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
		let newAppConfig: any;
		switch (type) {
			case 'favicon':
				newAppConfig = {
					'applicationName': appInfo.applicationName,
					'themeColor': appInfo.themeColor,
					'favicon': fileName,
					'loginLogo': appInfo.loginLogo,
					'headerLogo': appInfo.headerLogo
				}
				break;
			case 'loginLogo':
				newAppConfig = {
					'applicationName': appInfo.applicationName,
					'themeColor': appInfo.themeColor,
					'favicon': appInfo.favicon,
					'loginLogo': fileName,
					'headerLogo': appInfo.headerLogo
				}
				break;
			case 'headerLogo':
				newAppConfig = {
					'applicationName': appInfo.applicationName,
					'themeColor': appInfo.themeColor,
					'favicon': appInfo.favicon,
					'loginLogo': appInfo.loginLogo,
					'headerLogo': fileName
				}
				break;
			default:
				break;
		}
		this.appConfig = newAppConfig
		localStorage.setItem('appInfo', JSON.stringify(newAppConfig));
	}

	public checkFavicon(): boolean {
		let result: boolean = false
		if (this.faviconEL.nativeElement.value.length != 0) {
			result = true
		} else {
		}
		return result
	}
	public uploadFavicon() {
		this.changeThemeService.faviconFileUpload(this.faviconItem.file).subscribe(
			data => {
				this.changeLocalStorageImages('favicon', data.filename)
				this.setFavicon(data.filename)
				this.alertService.clear();
				this.alertService.success(Constants.SUCCESS_UPLOAD_FAVICON);
			},
			error => {
				this.alertService.clear();
				this.alertService.handleError(error);
			}
		)
	}

	public checkLoginLogo(): boolean {
		let result: boolean = false
		if (this.loginLogoEL.nativeElement.value.length != 0) {
			result = true
		} else {
		}
		return result
	}
	public uploadLoginLogo() {
		this.changeThemeService.loginLogoFileUpload(this.loginItem.file).subscribe(
			data => {
				this.changeLocalStorageImages('loginLogo', data.filename)
				this.alertService.clear();
				this.alertService.success(Constants.SUCCESS_UPLOAD_LOGINLOGO);
			},
			error => {
				this.alertService.clear();
				this.alertService.handleError(error);
			}
		)
	}

	public checkHeaderLogo(): boolean {
		let result: boolean = false
		if (this.headerLogoEL.nativeElement.value.length != 0) {
			result = true
		} else {
		}
		return result
	}
	public uploadHeaderLogo() {
		this.changeThemeService.HeaderLogoFileUpload(this.headerItem.file).subscribe(
			data => {
				this.changeLocalStorageImages('headerLogo', data.filename)
				this.alertService.clear();
				this.alertService.success(Constants.SUCCESS_UPLOAD_HEADERLOGO);
			},
			error => {
				this.alertService.clear();
				this.alertService.handleError(error);
			}
		)
	}

	public setFavicon(faviconPath: string) {
		// console.log(faviconPath);
		if (faviconPath != '') {
			let fp: string = this.faviconIconPath + '' + faviconPath
			this._document.getElementById('appFavicon').setAttribute('href', fp);
		} else {
			this._document.getElementById('appFavicon').setAttribute('href', '/assets/images/favicon/axis.ico');
		}
	}
}