import { Component, Inject } from '@angular/core';
import { UserComponent } from './user/user.component';
import { AppService } from './app.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router'
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	title = 'app';
	appInfo: any;
	public faviconIconPath: string = environment.server_URL + '/images/favicon/'
	show: boolean = false
	private themeWrapper = document.querySelector('body');

	constructor(private appService: AppService, @Inject(DOCUMENT) private _document: HTMLDocument, private router: Router) {
		console.log('inside app component')
		if (localStorage.getItem('appInfo') == null) {
			this.appInfo = appService.getAppInfo().subscribe(
				data => {
					let appConfig: any = {
						'applicationName': data.applicationName,
						'themeColor': data.themeColor,
						'favicon': data.favicon,
						'loginLogo': data.loginLogo,
						'headerLogo': data.headerLogo
					}					
					this.setFavicon(data.favicon)					
					this.setStyle(appConfig)					
					localStorage.setItem('appInfo', JSON.stringify(appConfig));					
					this.show = true
				},
				error => {
					const err = error.json();
				}
			)
		} else {
			let appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
			this.setFavicon(appInfo.favicon)
			this.setStyle(appInfo)
			this.show = true
		}
	}

	public setFavicon(faviconPath: string) {
		if (faviconPath != '') {
			let fp: string = this.faviconIconPath + '' + faviconPath
			this._document.getElementById('appFavicon').setAttribute('href', fp);
		} else {
			this._document.getElementById('appFavicon').setAttribute('href', '/assets/images/favicon/axis.ico');
		}
	}

	setStyle(appConfig: any) {
		console.log(appConfig.themeColor)
		switch (appConfig.themeColor) {			
			case 'red':
				window['switchStyle']('Red');
				break;
			case 'yellow':
			  console.log('switchcing to yellow')
				window['switchStyle']('Yellow');
				break;
			case 'blue':
				window['switchStyle']('Blue');
				break;
			case 'green':
				window['switchStyle']('Green');
				break;
			default:
				break;
		}
		// this.themeWrapper.style.setProperty('--temp', '#dc3545');
	}

}
