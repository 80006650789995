import { Pipe, PipeTransform } from '@angular/core';
/*
 * Reorder an array for a given type
 * Takes string as an argumnet to sort array
 * Usage:
 *   array | orderBy: <type>
 * Example:
 *   *ngFor = "let ccItem of jobProfileModel.coreCompetencyList | orderBy : 'alphabetic'; let k = index"
 *   *ngFor = "let task of ccItem.taskList | orderBy : 'orderNum'; let t = index "
*/

@Pipe({
	name: 'orderBy',
	pure: false
})
export class OrderArrayByType implements PipeTransform {
	transform(array: any, type: string): any {
		switch (type) {
			case 'orderNum':
				array = this.orderByOrderNumber(array)
				break;
			case 'alphabetic':
				array = this.orderByAlphabetically(array)
				break;
			case 'answer':
				array = this.orderByAnswer(array)
				break;
			case 'assStatus':
				array = this.orderByAssessmentStatus(array)
				break;
			case 'fileName':
				array = this.orderByFileName(array)
				break;
			case 'score':
				array = this.orderByScore(array)
				break;
			case 'scoredesc':
				array = this.orderByScoreDesc(array)
				break;
			case 'alphabeticTaskname':
				array = this.orderByTaskNameAlphabetically(array)
				break;
			case 'alphabeticRemediations':
				array = this.orderRemediations(array)
				break;
			case 'alphabeticRegulatoryAgency':
				array = this.orderRegulatoryAgency(array)
				break;
			case 'alphabeticRemediationMatrixUser':
				array = this.orderRemediationMatrixUser(array)
				break;
			case 'alphabeticRemediationMatrixJobprofile':
				array = this.orderRemediationMatrixJobprofile(array)
				break;
			case 'alphabeticRemediationMatrixRemediation':
				array = this.orderRemediationMatrixRemediation(array)
				break;
			case 'alphabeticMissedTaskMatrix':
				array = this.orderTaskMatrixTotal(array)
				break;
			case 'alphabeticMissedTaskMatrixPrint':
				array = this.orderTaskMatrixPrint(array)
				break;
		}
		return array
	}

	private orderByOrderNumber(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.orderNum < b.orderNum) {
					return -1;
				} else if (a.orderNum > b.orderNum) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByScoreDesc(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.score < b.score) {
					return -1;
				} else if (a.score > b.score) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByScore(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.score > b.score) {
					return -1;
				} else if (a.score < b.score) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByFileName(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.fileName < b.fileName) {
					return -1;
				} else if (a.fileName > b.fileName) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByAlphabetically(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.text.toLowerCase() > b.text.toLowerCase()) {
					return 1;
				} else if (a.text.toLowerCase() < b.text.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderByAnswer(array: any): any {
		if (array != null && array.length != 0) {
			array = this.orderByOrderNumber(array)
			// array.sort((a, b) => {
			// 	if (a.answer < b.answer) {
			// 		return -1
			// 	} else if (a.answer > b.answer) {
			// 		return 1
			// 	} else {
			// 		return 0
			// 	}
			// })
			return array
		} else {
			return array
		}
	}

	private orderByAssessmentStatus(array: any): any {
		if (array != null && array.length != 0) {
			array = this.orderByOrderNumber(array)
			// array.sort((a, b) => {
			// 	var a_assStatus = a.assStatus;
			// 	var b_assStatus = b.assStatus;
			// 	if (a_assStatus == 2) {
			// 		a_assStatus = 0
			// 	}
			// 	if (b_assStatus == 2) {
			// 		b_assStatus = 0
			// 	}
			// 	if (a_assStatus < b_assStatus) {
			// 		return -1
			// 	} else if (a_assStatus > b_assStatus) {
			// 		return 1
			// 	} else {
			// 		return 0
			// 	}
			// })
			return array
		} else {
			return array
		}
	}

	private orderByTaskNameAlphabetically(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.text.toLowerCase() > b.text.toLowerCase()) {
					return 1;
				} else if (a.text.toLowerCase() < b.text.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderRemediations(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.remediationDescription.toLowerCase() === b.remediationDescription.toLowerCase()) {
					if (a.connectedCorecompetency.toLowerCase() === b.connectedCorecompetency.toLowerCase()) {
						if (a.connectedTaskGroup.toLowerCase() === b.connectedTaskGroup.toLowerCase()) {
							return b.connectedTask.toLowerCase() - a.connectedTask.toLowerCase()
						}
						return a.connectedTaskGroup.toLowerCase() > b.connectedTaskGroup.toLowerCase() ? 1 : -1;
					}
					return a.connectedCorecompetency.toLowerCase() > b.connectedCorecompetency.toLowerCase() ? 1 : -1;
				}
				return a.remediationDescription.toLowerCase() > b.remediationDescription.toLowerCase() ? 1 : -1;
			});
			return array
		} else {
			return array
		}
	}

	private orderRegulatoryAgency(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.text.toLowerCase() < b.text.toLowerCase()) {
					return 1;
				} else if (a.text.toLowerCase() > b.text.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderRemediationMatrixUser(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.userFullName.toLowerCase() > b.userFullName.toLowerCase()) {
					return 1;
				} else if (a.userFullName.toLowerCase() < b.userFullName.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderRemediationMatrixJobprofile(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.jobprofile.toLowerCase() > b.jobprofile.toLowerCase()) {
					return 1;
				} else if (a.jobprofile.toLowerCase() < b.jobprofile.toLowerCase()) {
					return -1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

	private orderRemediationMatrixRemediation(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.remCount === b.remCount) {
					if (a.description.toLowerCase() > b.description.toLowerCase()) {
						return 1;
					} else if (a.description.toLowerCase() < b.description.toLowerCase()) {
						return -1;
					} else {
						return 0
					}
				}
				return a.remCount < b.remCount ? 1 : -1;
			});
			return array
		} else {
			return array
		}
	}

	private orderTaskMatrixTotal(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.taskCount === b.taskCount) {
					if (a.description.toLowerCase() > b.description.toLowerCase()) {
						return 1;
					} else if (a.description.toLowerCase() < b.description.toLowerCase()) {
						return -1;
					} else {
						return 0
					}
				}
				return a.taskCount < b.taskCount ? 1 : -1;
			});
			return array
		} else {
			return array
		}
		// if (array != null && array.length != 0) {
		// 	array.sort((a, b) => {
		// 		if (a.taskCount < b.taskCount) {
		// 			return -1;
		// 		} else if (a.taskCount > b.taskCount) {
		// 			return 1;
		// 		} else {
		// 			return 0
		// 		}
		// 	});
		// 	return array
		// } else {
		// 	return array
		// }
	}

	private orderTaskMatrixPrint(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.taskCount === b.taskCount) {
					if (a.description.toLowerCase() > b.description.toLowerCase()) {
						return 1;
					} else if (a.description.toLowerCase() < b.description.toLowerCase()) {
						return -1;
					} else {
						if (a.jobprofile.toLowerCase() > b.jobprofile.toLowerCase()) {
							return 1;
						} else if (a.jobprofile.toLowerCase() < b.jobprofile.toLowerCase()) {
							return -1;
						} else {
							if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
								return 1;
							} else if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
								return -1;
							} else {
								return 0
							}
						}
					}
				}
				return a.taskCount < b.taskCount ? 1 : -1;
			});
			return array
		} else {
			return array
		}

	}
}
