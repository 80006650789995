import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-progressAssessment',
	templateUrl: './progressassessment.component.html',
	styleUrls: ['./progressassessment.component.scss']
})
export class ProgressAssessmentComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
