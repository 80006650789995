import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../user/user.service";
import { SharedService } from "../../home/shared.service";
import { AlertService } from "../../util/alert/alert.service";
import { KnowledgeService } from "./knowledge.service";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import { TableComponent } from "../../util/table/table.component";
import { Knowledge } from "./knowledge.model";
import { CustomModalService } from "../../util/custommodal/custommodal.service";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
	selector: "app-allknowledges",
	templateUrl: "./allknowledges.component.html"
})
export class KnowledgesComponent extends TableComponent {
	selectedKnowledgeId: string;
	selectedKnowledgeStatus: number;
	newStatus: string;
	knowledgeId: string;
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem("selectedUserRole");
	private isDeleteKnowledge: boolean;
	private isModifyKnowledge: boolean;
	isAddKnowledge: boolean;
	private isQueryKnowledge: boolean;
	checked: boolean = false;

	public tagList = [];
	public answerList = [];

	public columns: Array<any> = [
		{
			title: "Knowledge",
			name: "knowledge",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Test Question",
			name: "isTestQuestion",
			sort: false,
			selectable: true
		},
		{
			title: "Visual Question",
			name: "isVisualQuestion",
			sort: false,
			selectable: true
		},
		{
			title: "Type",
			name: "type",
			filtering: { filterString: "", placeholder: "" }
		},
		{
			title: "Org Code",
			name: "orgCode",
			filtering: { filterString: "", placeholder: "" }
		},
		{ title: "", name: "statusbutton", className: "accepter-col-action" },
		{ title: "", name: "editbutton", className: "accepter-col-action" },
		{ title: "", name: "deletebutton", className: "accepter-col-action" },
		{ title: '', name: 'showhidebutton', className: 'accepter-col-action' }
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
	};

	//this array store all the data from the server
	private serverResultData: Array<any> = [];
	showHideForm: FormGroup;
	constructor(
		private customModalService: CustomModalService,
		private userService: UserService,
		private router: Router,
		private sharedService: SharedService,
		private alertService: AlertService,
		private knowledgeService: KnowledgeService
	) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddKnowledge = this.userService.isCapabilityAssigned(
			"AddCompLib",
			this.selectedUserRole[0][1]
		);
		this.isModifyKnowledge = this.userService.isCapabilityAssigned(
			"ModifyCompLib",
			this.selectedUserRole[0][1]
		);
		this.isDeleteKnowledge = this.userService.isCapabilityAssigned(
			"DeleteCompLib",
			this.selectedUserRole[0][1]
		);
		this.isQueryKnowledge = this.userService.isCapabilityAssigned(
			"QueryCompLib",
			this.selectedUserRole[0][1]
		);
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		//load data after check the permissions
		if (this.isQueryKnowledge) {
			this.loadAllShowKnowledges();
		} else {
			//do nothing
		}

		this.showHideForm = new FormGroup({
			showHide: new FormControl(null)
		});
	}

	//on cell click event
	public onCellClick(data: any): any {
		this.selectedKnowledgeId = data.row.id;
		this.selectedKnowledgeStatus = data.row.status;
		this.newStatus = this.selectedKnowledgeStatus == 1 ? "disable" : "enable";
		switch (data.column) {
			case "editbutton":
				if (this.isModifyKnowledge) {
					this.editButtonClickEvent(
						data.row.id,
						data.row.knowledge,
						data.row.isTestQuestion,
						data.row.isVisualQuestion,
						data.row.orgCodeId,
						data.row.type,
						data.row.isCorrect
					);
				} else {
					//do nothing
				}
				break;
			case "deletebutton":
				if (this.isDeleteKnowledge) {
					this.deleteButtonClickEvent(data.row.id);
				} else {
					//do nothing
				}
				break;
			case "statusbutton":
				if (this.isModifyKnowledge) {
					if (data.row.showHide == 0) {
						// do nothing
					} else {
						this.statusButtonClickEvent(
							this.selectedKnowledgeId,
							this.selectedKnowledgeStatus
						);
					}
				}
				break;
			case "showhidebutton":
				//check modify capability
				if (this.isModifyKnowledge) {
					if (data.row.status == 0 || data.row.showHide == 0) {
						this.showHideButtonClickEvent(data.row.id, data.row.showHide);
					} else {
						//do nothing
					}
				} else {
					//modify capability is not assigned
				}
				break;
			default:
				break;
		}
	}

	private statusButtonClickEvent(id: string, status: number) {
		let itemType = "Knowledge";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"statusChange",
			status
		);
	}

	private changeStatus(knowledgeId: string, status: number) {
		this.knowledgeService
			.changeKnowledgeStatus(knowledgeId, status == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	private deleteButtonClickEvent(id: string) {
		this.knowledgeId = id;
		let itemType = "Knowledge";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	private editButtonClickEvent(
		curKnowledgeId: any,
		curKnowledge: any,
		curIsTestQuestion: any,
		curIsVisualQuestion: any,
		curOrgCode: any,
		curType: any,
		curIsCorrect: any
	) {
		this.knowledgeService
			.getAssignedKnowledgeTagList(curKnowledgeId)
			.subscribe(data => {
				//console.log('[editButtonClickEvent]' + JSON.stringify(data).toString());
				this.tagList = [];
				data.assignTagList.forEach(element => {
					let item = { value: element.Description, id: element.KTagId };
					this.tagList.push(item);
				});

				this.knowledgeService.getKnowledgeById(curKnowledgeId)
					.subscribe(data => {
						this.answerList = [];
						data.answers.forEach(element => {
							let item = { answer: element.Answer, isCorrect: element.IsCorrect };
							this.answerList.push(item);
						});

						let tempKnowledge = new Knowledge(
							curKnowledge,
							curIsTestQuestion,
							curKnowledgeId,
							curOrgCode,
							curType,
							curIsCorrect
						);
						tempKnowledge.fileType = data.knowledge[0].FileType;
						tempKnowledge.tagList = this.tagList;
						tempKnowledge.answerList = this.answerList;
						// console.log('[tempKnowledge](answers)' + JSON.stringify(tempKnowledge).toString());
						this.knowledgeService.setSelectedKnowledgeForEdit(tempKnowledge);
						this.sharedService.change("EditKnowledge");
					})
			});

	}

	private delete() {
		this.knowledgeService.removeKnowledge(this.selectedKnowledgeId).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success("Successfully removed the knowledge.");
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	//load buttons to the table
	public extendData() {
		//for every row
		for (let i = 0; i < this.data.length; i++) {
			if (this.data[i].status == 1) {
				if (this.isModifyKnowledge) {
					this.data[i].statusbutton =
						'<a class="btn btn-sm edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				} else {
					this.data[i].statusbutton =
						'<a class="btn btn-sm disabled edit"><i class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			} else {
				if (this.isModifyKnowledge) {
					if (this.data[i].showHide == 0) {
						this.data[i].statusbutton =
							'<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true" style="color:grey"></i></a>';
					} else {
						this.data[i].statusbutton =
							'<a class="btn btn-sm edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
					}
				} else {
					this.data[i].statusbutton =
						'<a class="btn btn-sm disabled edit"><i class="fa fa-times-circle-o fa-lg" aria-hidden="true"></i></a>';
				}
			}
			//check modify capability
			if (this.isModifyKnowledge) {
				this.data[i].editbutton =
					'<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				if (this.data[i].isTestQuestion == "1") {
					this.data[i].isTestQuestion = '<input type="checkbox" checked>';
				} else {
					this.data[i].isTestQuestion = '<input type="checkbox" >';
				}
				if (this.data[i].isVisualQuestion == "1") {
					this.data[i].isVisualQuestion = '<input type="checkbox" checked>';
				} else {
					this.data[i].isVisualQuestion = '<input type="checkbox" >';
				}
			} else {
				this.data[i].editbutton =
					'<a class="btn btn-sm disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
				if (this.data[i].isTestQuestion == "1") {
					this.data[i].isTestQuestion =
						'<input type="checkbox" checked disabled>';
				} else {
					this.data[i].isTestQuestion = '<input type="checkbox" disabled>';
				}
				if (this.data[i].isVisualQuestion == "1") {
					this.data[i].isVisualQuestion = '<input type="checkbox" checked>';
				} else {
					this.data[i].isVisualQuestion = '<input type="checkbox" >';
				}
			}

			if (this.isDeleteKnowledge) {
				this.data[i].deletebutton =
					'<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton =
					'<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

			if (this.data[i].showHide == 1) {
				if (this.data[i].status == 0) {
					if (this.isModifyKnowledge) {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm edit"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></a>';
					} else {
						this.data[i].showhidebutton =
							'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
					}
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye fa-lg" aria-hidden="true" ></i></a>';
				}

			} else {
				if (this.isModifyKnowledge) {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				} else {
					this.data[i].showhidebutton =
						'<a class="btn btn-sm  disabled edit"><i class="fa fa-eye-slash fa-lg" aria-hidden="true" style="color:red"></i></a>';
				}
			}
		}
	}

	private loadAllKnowledges() {
		//console.log('inside load knowledges');
		this.knowledgeService.getAllKnowledgeElements().subscribe(
			data => {
				this.data = [];
				// console.log('[loadAllKnowledges]' + JSON.stringify(data).toString());
				for (let i = 0; i < data.knowledge.length; i++) {
					this.data.push({
						id: data.knowledge[i].Id,
						knowledge: data.knowledge[i].Knowledge,
						isTestQuestion: data.knowledge[i].IsTestQuestion,
						isVisualQuestion: data.knowledge[i].IsVisualQuestion,
						type: data.knowledge[i].Type == null
							? ""
							: data.knowledge[i].Type,
						isCorrect:
						data.knowledge[i].IsCorrect == null
							? ""
							: data.knowledge[i].IsCorrect,
						orgCode:
						data.knowledge[i].OrgCode == null
							? ""
							: data.knowledge[i].OrgCode,
						orgCodeId:
						data.knowledge[i].OrgCodeId == null
							? ""
							: data.knowledge[i].OrgCodeId,
						status:
						data.knowledge[i].Status,
						showHide:
						data.knowledge[i].ShowHide,
						editbutton: "",
						deletebutton: "",
						statusbutton: "",
						showhidebutton: ""
					});
				}
				// console.log('data pushed');
				this.serverResultData = data;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	private loadAllShowKnowledges() {
		//console.log('inside load knowledges');
		this.knowledgeService.getAllShowKnowledgeElements().subscribe(
			data => {
				this.data = [];
				// console.log('[loadAllKnowledges]' + JSON.stringify(data).toString());
				for (let i = 0; i < data.knowledge.length; i++) {
					this.data.push({
						id: data.knowledge[i].Id,
						knowledge: data.knowledge[i].Knowledge,
						isTestQuestion: data.knowledge[i].IsTestQuestion,
						isVisualQuestion: data.knowledge[i].IsVisualQuestion,
						type: data.knowledge[i].Type == null
							? ""
							: data.knowledge[i].Type,
						isCorrect:
						data.knowledge[i].IsCorrect == null
							? ""
							: data.knowledge[i].IsCorrect,
						orgCode:
						data.knowledge[i].OrgCode == null
							? ""
							: data.knowledge[i].OrgCode,
						orgCodeId:
						data.knowledge[i].OrgCodeId == null
							? ""
							: data.knowledge[i].OrgCodeId,
						status:
						data.knowledge[i].Status,
						showHide:
						data.knowledge[i].ShowHide,
						editbutton: "",
						deletebutton: "",
						statusbutton: "",
						showhidebutton: ""
					});
				}
				// console.log('data pushed');
				this.serverResultData = data;
				this.pageCount = Math.ceil(
					this.serverResultData.length / this.itemsPerPage
				);
				//console.log(JSON.stringify(this.serverResultData));
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	showHide(event) {
		this.checked = !this.checked;
		if (event.target.checked) {
			if (this.isQueryKnowledge) {
				this.loadAllKnowledges();
			} else {
				//noo need to display data
			}
		} else {
			this.loadAllShowKnowledges();
		}
	}


	loadData() {
		if (this.checked) {
			this.loadAllKnowledges();
		}
		else {
			this.loadAllShowKnowledges();
		}
	}

	public showHideButtonClickEvent(id: string, showHide: number) {
		let itemType = "Knowledge";
		let status = showHide;
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"showHide",
			status
		);
	}

	public showHideEvent(knowledgeId: string, showHide: number) {
		this.knowledgeService.changeKnowledgeShowHide(knowledgeId, showHide == 1 ? 0 : 1)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadData();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}
}
