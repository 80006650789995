import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Note } from './note.model';

@Injectable()
export class NoteService {

	constructor(private http: Http, private router: Router) { }

	//selected preformance for edit
	private selectedNoteForEdit: Note;
	public getSelectedNoteForEdit(): Note {
		return this.selectedNoteForEdit;
	}
	public setSelectedNoteForEdit(note: Note): void {
		this.selectedNoteForEdit = note;
	}

	//get all note
	getAllNote() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/note/getAllNote' + token, { headers: headers })
			.map(res => res.json());
	}

	//get active notes
	getActiveNotes() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/note/getActiveNotes' + token, { headers: headers })
			.map(res => res.json());
	}

	//update note by note id
	updateNoteByNoteId(note: Note) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(note);
		return this.http.post(environment.server_URL + '/note/updateNoteByNoteId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//update note by note id
	updateNoteStatusByNoteId(note: Note) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(note);
		return this.http.post(environment.server_URL + '/note/updateNoteStatusByNoteId' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//add new note
	addNewNote(note: Note) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(note);
		return this.http.post(environment.server_URL + '/note/addNewNote' + token, body, { headers: headers })
			.map(res => res.json());
	}

	//delete note
	deleteNote(note: Note) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify(note);
		return this.http.post(environment.server_URL + '/note/deleteNote' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllShowNote() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/note/getAllShowNote' + token, { headers: headers })
			.map(res => res.json());
	}

	changeNoteShowHide(noteId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'noteId': noteId, 'showHide': showHide });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/note/changeNoteShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}


}
