import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { RegulatoryAgencyService } from '../../complib/regulatoryagency/regulatoryagency.service';
import { TableComponent } from '../../util/table/table.component'
import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from 'mydatepicker';
import { Constants } from '../../util/const/constants';
import { ReportService } from "../report.service";

@Component({
	selector: "app-regulatory-compliance-report",
	templateUrl: "./regulatorycompliancereport.component.html",
	styleUrls: ['./regulatorycompliancereport.component.scss']
})

export class RegulatoryComplianceReportComponent extends TableComponent implements OnInit {

	public rgList: Array<any> = [];
	public selectRgList: Array<any> = [];
	public palceholderText: any;
	public regulatoryAgenciesData = [];	
	public noResults: boolean = false;
	public showResults: boolean = false;
	public showItemDetail: string = ''
	public rowData = []
	public isQueryRegulatory: boolean = false
	public selectedRegulatoryId: string = null
	public selectedRegulatory: string = null
	public isDataExist: boolean = false;
	public isLoading: boolean = false
	public excelData = []
	public isDateExist: boolean = false
	paginatedResults = [];
	filteredData: Array<any> = [];
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;

	public columns: Array<any> = [
		{ 'title': 'First Name', 'name': 'firstName'},
		{ 'title': 'Last Name', 'name': 'lastName' },
		{ 'title': 'Job Profile', 'name': 'jobProfile' },
		{ 'title': 'Task', 'name': 'task'},
		{ 'title': 'Knowledge Check Only', 'name': 'isKnowledgeTest' },
		{ 'title': 'Completed Date', 'name': 'completedDateStr'},
	]
	// public config: any = {
	// 	paging: true,
	// 	sorting: { columns: this.columns },
	// 	className: ["table-striped", "table-bordered", "col-sm-12"]
	// };

	fromDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};

	toDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
	};

	public regulatoryForm: FormGroup


	constructor(private regulatoryAgencyService: RegulatoryAgencyService,
		private alertService: AlertService, private reportService: ReportService, private changeDetectorRef: ChangeDetectorRef,) {
		super();
	}

	ngOnInit() {
		this.loadAllRegulatoryAgencies()

		this.regulatoryForm = new FormGroup({
			regulatoryAgency: new FormControl(null),
			fromDate: new FormControl(null),
			toDate: new FormControl(null)
		});
	}

	private loadAllRegulatoryAgencies() {
		this.regulatoryAgencyService.getAllRegulatoryAgency()
			.subscribe(
			data => {
				this.rgList = [];
				this.selectRgList = []
				for (var i = 0; i < data.regulatoryAgencyList.length; i++) {
					this.rgList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].Description + " - " + data.regulatoryAgencyList[i].ShortName });
					this.selectRgList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].ShortName})
				}
				if (this.rgList.length > 0) {
					this.palceholderText = 'Select regulatory agency'
				} else {
					this.palceholderText = 'No items available'
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}
	public removeRegulatoryAgency(event: any) {
		this.isQueryRegulatory = false
		this.noResults = false
		this.showResults = false
		this.regulatoryAgenciesData = []
		this.selectedRegulatoryId = null
		this.selectedRegulatory = null
		this.isDataExist = false
	}

	public selectRegulatoryAgency(event: any) {
		this.isQueryRegulatory = true
		this.selectedRegulatoryId = event.id
		this.selectedRegulatory = event.text
		this.noResults = false
		this.showResults = false
		this.isDataExist = false
		this.currentPage = 1
	}

	onSubmit() {
		var fromDate = this.regulatoryForm.get("fromDate").value
		var toDate = this.regulatoryForm.get("toDate").value
		var result = true
		this.showItemDetail = ''

		if ((!fromDate && toDate) || (!toDate && fromDate)) {
			result = false
			this.alertService.error(Constants.RANGE_ERROR_IN_REGULATORY_COMPLIANCE)
		} else {
			if (fromDate > toDate) {
				this.alertService.error(Constants.INVALID_RANGE_IN_REGULATORY_COMPLIANCE)
			}
		}

		if (!fromDate) {
			fromDate = "";
		} else {
			fromDate = fromDate.date;
			fromDate = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
		}
		if (!toDate) {
			toDate = "";
		} else {
			toDate = toDate.date;
			toDate = new Date(toDate.year, toDate.month - 1, toDate.day);
		}
		if (this.selectedRegulatoryId != null && result == true) {
			let param = {
				'id': this.selectedRegulatoryId,
				'fromDate': fromDate == "" ? "" : fromDate.toLocaleDateString().replace(/[^ -~]/g, ''),
				'toDate': toDate == "" ? "" : toDate.toLocaleDateString().replace(/[^ -~]/g, '')
			}
			this.isLoading = true
			this.regulatoryAgencyService.getUserLastCompletedAssessmentByRegulatoryId(param)
				.subscribe(
				data => {
					this.regulatoryAgenciesData = []
					this.excelData = []
					//this.filteredData = [];
					if (data.regulatoryAgencyList.length > 0) {
						this.isLoading = false
						this.noResults = false
						this.showResults = true
						for (var i = 0; i < data.regulatoryAgencyList.length; i++) {
							let userList = []
							let id = data.regulatoryAgencyList[i].Id
							let orgCode = data.regulatoryAgencyList[i].orgCode
							let userFirstName = data.regulatoryAgencyList[i].FirstName
							let userLastName = data.regulatoryAgencyList[i].LastName
							let jobProfileId = data.regulatoryAgencyList[i].JobProfieId
							let jobprofile = data.regulatoryAgencyList[i].jobProfile
							let task = data.regulatoryAgencyList[i].Task
							let isKnowledgeTest = data.regulatoryAgencyList[i].IsKnowledgeTest
							let assessmentCompletedDate = null
							let assDate = data.regulatoryAgencyList[i].LastAssessmentCompletedDate
							if (assDate == null) {
								assessmentCompletedDate = null
							} else {
								assessmentCompletedDate = new Date(new Date(assDate).getUTCFullYear(), new Date(assDate).getUTCMonth(), new Date(assDate).getUTCDate()).toLocaleDateString();
							}
							var userItem = {id, userFirstName, userLastName, jobProfileId, jobprofile, task, isKnowledgeTest, assessmentCompletedDate }
							var excelItem = {id, orgCode, userFirstName, userLastName, jobProfileId, jobprofile, task, isKnowledgeTest, assessmentCompletedDate }
							userList.push(userItem)
							//this.filteredData.push(userItem)
							this.excelData.push(excelItem)
							if (!this.addToExisting(id, orgCode, userItem)) {
								this.regulatoryAgenciesData.push({ id, orgCode, userList })
							}
						}
						this.isDataExist = true

					} else {
						this.isLoading = false
						this.noResults = true
						this.showResults = false
						this.isDataExist = false
					}
				},
				error => {
					this.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				});
		}
	}

	addToExisting(id: any, orgCode: any, userItem: any) {
		var found: boolean = false;
		for (var i = 0; i < this.regulatoryAgenciesData.length; i++) {
			var temp = this.regulatoryAgenciesData[i].orgCode
			if (temp == orgCode) {
				found = true
				this.regulatoryAgenciesData[i].userList.push(userItem)
				break
			}
		}
		return found;
	}

	public showCollapsePanel(reItem: any) {
		if (this.showItemDetail != reItem.id) {
			this.loadTableData(reItem)
			this.showItemDetail = reItem.id
		} else {
			this.showItemDetail = ''
		}
	}

	getRegulatoryCompliance(criteria: RegulatoryComplianceSearchCriteria) {
		if (criteria.sortDirection === 'desc') {
			this.fillData();
			this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
		}
		else if (criteria.sortDirection === 'asc') {
			this.fillData();
			this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
		}
		else {
		}

	}

	fillData() {
		if (this.filteredData.length > 1) {
			let currentFirstName = this.filteredData[0]["firstName"];
			let currentLastName = this.filteredData[0]["lastName"];
			let currentJobProfile = this.filteredData[0]["jobProfile"];
			let currentTask = this.filteredData[0]["task"]
			let currentCompletedDate = this.filteredData[0]["completedDateStr"]
			for (let i = 1; i < this.filteredData.length; i++) {
				if (this.filteredData[i]["firstName"] == '') {
					this.filteredData[i]["firstName"] = currentFirstName;
					this.filteredData[i]["lastName"] = currentLastName;

					if (this.filteredData[i]["jobProfile"] == '') {
						this.filteredData[i]["jobProfile"] = currentJobProfile;
					}
					this.filteredData[i]["task"] = this.filteredData[i]["task"]
					this.filteredData[i]["completedDateStr"] = this.filteredData[i]["completedDateStr"]

				} else {
					currentFirstName = this.filteredData[i]["firstName"];
					currentLastName = this.filteredData[i]["lastName"];
					currentJobProfile = this.filteredData[i]["jobProfile"];
					currentTask = this.filteredData[i]["task"]
					currentCompletedDate = this.filteredData[i]["completedDateStr"]
				}
			}
		}
	}

	onSorted($event) {
		this.getRegulatoryCompliance($event);
		this.extendData();
		//  this.createExcelData();
		this.pageChanged(this.currentPage);
	}

	public extendData() {
		let currentId = this.filteredData[0]["id"];
		let currentJobProfile = this.filteredData[0]["jobProfileId"];
		this.filteredData[0]["Color"] = "";
		for (let i = 1; i < this.filteredData.length; i++) {
			if (this.filteredData[i]["id"] != '') {
				if (this.filteredData[i]["id"] === currentId) {
					this.filteredData[i]["firstName"] = '';
					this.filteredData[i]["lastName"] = '';
					if (this.filteredData[i]["jobProfileId"] === currentJobProfile) {
						this.filteredData[i]["jobProfile"] = ''
					} else {
						currentJobProfile = this.filteredData[0]["jobProfileId"]
					}
					this.filteredData[i]["Color"] = this.filteredData[i - 1]["Color"]
				} else {
					currentId = this.filteredData[i]["id"];
					currentJobProfile = this.filteredData[i]["jobProfileId"];
					this.filteredData[i]["Color"] = this.filteredData[i - 1]["Color"] == "" ? "rgba(0, 0, 0, 0.05)" : ""
				}
			}
		}


		// for (let i = 0; i < this.data.length; i++) {
		// 	if (this.data[i].isKnowledgeTest == 1) {
		// 		this.data[i].isKnowledgeTest = '<input type="checkbox" checked class="text-center">'
		// 	} else {
		// 		this.data[i].isKnowledgeTest = '<input type="checkbox">'
		// 	}
		// }

	}

	public loadTableData(reItem: any) {
		this.data = []
		this.filteredData = []
		reItem.userList.forEach(element => {
			this.data.push({
				'id': element.id,
				'firstName': element.userFirstName,
				'lastName': element.userLastName,
				'jobProfileId': element.jobProfileId,
				'jobProfile': element.jobprofile,
				'task': element.task,
				'isKnowledgeTest': element.isKnowledgeTest,
				'completedDateStr': element.assessmentCompletedDate,
			})

			this.filteredData.push({
				'id': element.id,
				'firstName': element.userFirstName,
				'lastName': element.userLastName,
				'jobProfileId': element.jobProfileId,
				'jobProfile': element.jobprofile,
				'task': element.task,
				'isKnowledgeTest': element.isKnowledgeTest,
				'completedDateStr': element.assessmentCompletedDate,
			})
		});
		this.pageChanged();
		this.extendData();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.data = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	public assCompletedToChanged(event: IMyDateModel) {
		var temp = event.jsdate;
		var newDate;
		if (event.jsdate != null) {
			temp = new Date(temp.getTime() - (24 * 60 * 60 * 1000));
			newDate = { year: temp.getFullYear(), month: temp.getMonth() + 1, day: temp.getDate() };
		} else {
			newDate = { year: 0, month: 0, day: 0 };
		}
		this.toDatePickerOptions = {
			dateFormat: 'mm/dd/yyyy',
			disableUntil: newDate
		};
		this.noResults = false
		this.showResults = false
		this.regulatoryAgenciesData = []
		this.isDataExist = false
	}

	public toDateChanged(event: IMyDateModel) {
		this.noResults = false
		this.showResults = false
		this.regulatoryAgenciesData = []
		this.isDataExist = false
	}

	public clear() {
		this.noResults = false
		this.showResults = false
		this.regulatoryAgenciesData = []
		this.regulatoryForm.reset()
		this.isQueryRegulatory = false
		this.selectedRegulatoryId = null
		this.isDataExist = false
	}

	public formatDate(date) {
		date = date.split("/");
		var year = date[2]
		year = year.substring(2);
		var month = date[0];
		var day = date[1];

		if (month.length == 1) {
			month = "0" + month;
		}

		if (day.length == 1) {
			day = "0" + day;
		}

		var Date = month + day + year
		return Date;
		// var time = date.getHours() + '' + date.getMinutes()
		// return (Date + ':' + time)
	}

	public exportToExcel() {
		var data = []
		var fromDate = this.regulatoryForm.get("fromDate").value
		var toDate = this.regulatoryForm.get("toDate").value
		let outputName = ''
		let shortName = []
		shortName = this.selectRgList.filter(temp => temp.id == this.selectedRegulatoryId);		
		if (fromDate != null && toDate != null) {
			fromDate = fromDate.date;
			fromDate = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
			fromDate = fromDate.toLocaleDateString()
			toDate = toDate.date;
			toDate = new Date(toDate.year, toDate.month - 1, toDate.day);
			toDate = toDate.toLocaleDateString()
			
			outputName = "RegulatoryCompliance_" + shortName[0].text +"_" +  this.formatDate(fromDate) +"_" +  this.formatDate(toDate)
		} else {
			outputName = "RegulatoryCompliance_" + shortName[0].text
		}
		
		this.excelData.forEach(item => {
			var temp = {
				"First Name": item["userFirstName"],
				"Last Name": item["userLastName"],
				"Org Code": item["orgCode"],
				"Job Profile": item["jobprofile"],
				"Task": item["task"],
				"Knowledge Check Only": item["isKnowledgeTest"] == 1 ? 'Yes' : 'No',
				"Completed Date": item["assessmentCompletedDate"]
			}
			data.push(temp)
		})
		this.reportService.exportAsExcelFile(data, outputName);

	}

	print() {
		let popupWinindow;
		let content = this.loadHtmlData()
		popupWinindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" />' +
			'<style>.print-center{text-align: center !important; }</style>' +
			'</head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWinindow.document.close();
	}

	loadHtmlData() {
		var fromDate = this.regulatoryForm.get("fromDate").value
		var toDate = this.regulatoryForm.get("toDate").value
		var reportData: Array<any> = []
		var reportColumns: Array<any> = []
		if (fromDate != null && toDate != null) {
			this.isDateExist = true
		} else {
			this.isDateExist = false
		}
		let reportHTML = '<h1>Regulatory Compliance Report</h1>';
		reportHTML += '<div style="font-size: 1.1rem">Regulatory Agency: ' + this.selectedRegulatory + '</div>'
		if (this.isDateExist) {
			fromDate = fromDate.date;
			fromDate = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
			toDate = toDate.date;
			toDate = new Date(toDate.year, toDate.month - 1, toDate.day);
			reportHTML += '<div>From: ' + fromDate.toLocaleDateString() + ' &nbsp;To: ' + toDate.toLocaleDateString() + '</div>'
		}

		this.columns.forEach(item => {
			reportColumns.push(item)

		})

		for (var i = 0; i < this.regulatoryAgenciesData.length; i++) {
			reportHTML += '<h4>Org Code: ' + this.regulatoryAgenciesData[i].orgCode + '</h4>'
			reportHTML += "<table width=1000px' border=1 style='border-collapse:collapse' > " +
				"<thead>" +
				"<tr style='width=100%'>";
			for (let k = 0; k < reportColumns.length; k++) {
				//this.report.reportHTML = "<th> " + this.columns[k].title + "</th>";
				reportHTML += "<th style='width:16%'> " + reportColumns[k].title + "</th>";
			}

			let currentId = this.regulatoryAgenciesData[i].userList[0]["id"];

			reportHTML += "</tr> " + "</thead> "
			reportHTML += "<tbody> ";

			reportHTML += "<tr>";
			reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[0]["userFirstName"];
			reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[0]["userLastName"];
			reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[0]["jobprofile"];
			reportHTML += "<td style='width:20%'>" + this.regulatoryAgenciesData[i].userList[0]["task"];
			if (this.regulatoryAgenciesData[i].userList[0]["isKnowledgeTest"] == '1') {
				reportHTML += "<td style='width:12%' class='print-center' > <input type='checkbox' checked disabled >";
			} else {
				reportHTML += "<td style='width:12%' class='print-center'> <input type='checkbox' disabled>";
			}
			reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[0]["assessmentCompletedDate"];
			reportHTML += "</td>";
			reportHTML += "</tr>";

			for (let m = 1; m < this.regulatoryAgenciesData[i].userList.length; m++) {
				if (this.regulatoryAgenciesData[i].userList[m]["id"] != '') {
					if (this.regulatoryAgenciesData[i].userList[m]["id"] === currentId) {
						reportHTML += "<td style='width:16%'>";
						reportHTML += "<td style='width:16%'>";
						reportHTML += "<td style='width:16%'>";
						reportHTML += "<td style='width:20%'>" + this.regulatoryAgenciesData[i].userList[m]["task"];
						if (this.regulatoryAgenciesData[i].userList[m]["isKnowledgeTest"] == '1') {
							reportHTML += "<td style='width:12%' class='print-center'> <input type='checkbox' checked disabled >";
						} else {
							reportHTML += "<td style='width:12%' class='print-center'> <input type='checkbox' disabled>";
						}
						reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[m]["assessmentCompletedDate"];
						reportHTML += "</td>";
					} else {
						reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[m]["userFirstName"];
						reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[m]["userLastName"];
						reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[m]["jobprofile"];
						reportHTML += "<td style='width:20%'>" + this.regulatoryAgenciesData[i].userList[m]["task"];
						if (this.regulatoryAgenciesData[i].userList[m]["isKnowledgeTest"] == '1') {
							reportHTML += "<td style='width:12%' class='print-center'> <input type='checkbox' checked disabled>";
						} else {
							reportHTML += "<td style='width:12%' class='print-center'> <input type='checkbox' disabled>";
						}
						reportHTML += "<td style='width:16%'>" + this.regulatoryAgenciesData[i].userList[m]["assessmentCompletedDate"];
						reportHTML += "</td>";

						currentId = this.regulatoryAgenciesData[i].userList[m]["id"]
					}
				}
				reportHTML += "</tr>";
			}


			// this.regulatoryAgenciesData[i].userList.forEach(item => {
			// 	console.log(item["userFirstName"])
			// 	var temp = {
			// 		"First Name": item["userFirstName"],
			// 		"Last Name": item["userLastName"],
			// 		"Job Profile": item["jobprofile"],
			// 		"Task": item["task"],
			// 		"Knowledge Check": item["isKnowledgeTest"] == 1 ? 'Yes' : 'No',
			// 		"Completed Date": item["assessmentCompletedDate"]
			// 	}
			// 	reportData = []
			// 	reportData.push(temp)

			// 	if (reportData.length > 0) {
			// 		reportHTML += "</tr> " + "</thead> "
			// 		reportHTML += "<tbody> ";

			// 		for (let j = 0; j < reportData.length; j++) {
			// 			reportHTML += "<tr>";						
			// 			Object.getOwnPropertyNames(reportData[j]).forEach(key => {							
			// 				//let value = this.data[j][key];
			// 				// if (reportData[j][key] == 'isKnowledgeTest') {

			// 				// } else {
			// 				// 	reportHTML += "<td style='width:16%'>" + reportData[j][key];
			// 				// 	+"</td>";
			// 				// }													
			// 			});

			// 			reportHTML += "</tr>";
			// 		}
			// 	}
			// })
			reportHTML += "</tbody> " + "</table> ";
		}
		return reportHTML
	}

}

export class RegulatoryComplianceSearchCriteria {
	sortColumn: string;
	sortDirection: string;
}
