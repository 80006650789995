import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AssessmentService } from '../../../assessment/assessment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from '../../../home/shared.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-competencypercentage',
	templateUrl: './competencypercentage.component.html',
	styleUrls: ['./competencypercentage.component.scss']
})
export class CompetencyPercentageComponent implements OnInit, AfterViewInit {
	@ViewChild('competencyPercentage', { static: true }) tblElement: ElementRef;
	data = [];
	type = 'Core Competency';
	options = [];
	private timer: any;

	constructor(private assessmentService: AssessmentService, private sharedService: SharedService, private sanitizer: DomSanitizer) {
		this.options = ["Org Code", "Job Profile", "Discipline", "Supervisor", "Core Competency"];
		this.data = assessmentService.getCompetencyPercentagedata();
	}

	loadCompetencyPercentageData(type) {
		this.assessmentService.getCompetencyPercentageData(type)
			.subscribe(
			data => {
				this.data = [];
				for (var i = 0; i < data.Assessments.length; i++) {
					let total = data.Assessments[i].Red + data.Assessments[i].Green + data.Assessments[i].Yellow;
					let redActual = Math.round(((data.Assessments[i].Red / total) * 100) * 100) / 100;
					let redPercentage = Math.round((data.Assessments[i].Red / total) * 100);
					let yellowActual = Math.round(((data.Assessments[i].Yellow / total) * 100) * 100) / 100;
					let yellowPercentage = Math.round((data.Assessments[i].Yellow / total) * 100);
					let greenActual = Math.round(((data.Assessments[i].Green / total) * 100) * 100) / 100;
					let greenPercentage = Math.round((data.Assessments[i].Green / total) * 100);
					let result = this.assessmentService.getCalculatedPercentage(redActual, yellowActual, greenActual)
					redActual = result.redPercentage
					yellowActual = result.yellowPercentage
					greenActual = result.greenPercentage
					// console.log(redActual + " " + yellowActual + " " + greenActual)
					let description = data.Assessments[i].Description
					if (type == 'Supervisor') {
						if (!description) {
							description = 'No Supervisor'
						}
					}
					this.data.push({
						'Description': description, 'red': data.Assessments[i].Red, 'yellow': data.Assessments[i].Yellow, 'green': data.Assessments[i].Green,
						'redPercentage': redPercentage, 'yellowPercentage': yellowPercentage, 'greenPercentage': greenPercentage,
						'redActual': redActual, 'yellowActual': yellowActual, 'greenActual': greenActual
					})
				}
				this.timer = Observable.timer(500)
				this.timer.subscribe(t => {
					let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
					this.sharedService.competencyPercentageHeightChange(height);
				})

			});
	}

	ngOnInit() {

	}

	ngAfterViewInit() {
		let height = this.tblElement.nativeElement.getBoundingClientRect().bottom - this.tblElement.nativeElement.getBoundingClientRect().top;
		this.sharedService.competencyPercentageHeightChange(height);
	}

	setType(type) {
		this.type = type;
		this.loadCompetencyPercentageData(this.type);
	}

	getToolTip(count) {
		return "Assessments: " + count;
	}
}