<div class="login-page">
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="panel">
                <img class="img-fluid pt-3 pl-3 pr-3 pb-2" [src]="logoSrc"/>
                <div class="panel-body">
                    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                        <fieldset>
                            <input style="display: none;" id="deviceId" name="deviceId" type="text" >                            
                            <div class="form-group">
                                <input class="form-control" formControlName="email" placeholder="Email" name="email" type="email" >
                            </div>
                            <div class="form-group">
                                <input class="form-control" formControlName="password" placeholder="Password" name="password" type="password" value="">
                            </div>
                            <alert></alert>
                            <div>
                                <label>
                                <span> <button type="button" class="btn btn-login-link" (click) = "clickForgotpasswordLink()">Forgot Password?</button></span>
                                </label>
                            </div>
                            <!-- Change this to a button or input when using this as a form -->
                            <button class="btn rounded-btn" [disabled]="!myForm.valid" > Login </button>
                        </fieldset>
                    </form> 
                </div>  
            </div>         
        </div>
    </div>
</div>

<div  *ngIf=isLoading class="loading"></div>

<modal #emailModel>
    <modal-header [show-close]="false">
       <h4>Alert</h4>
    </modal-header>
    <modal-body>
        Please enter email.
    </modal-body>
    <modal-footer [show-default-buttons]="false">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="emailModel.dismiss()">OK</button>
    </modal-footer>
</modal>

<modal #successModel>
    <modal-header [show-close]="false">
       <h4>Alert</h4>
    </modal-header>
    <modal-body>
        Verification link has been sent.
    </modal-body>
    <modal-footer [show-default-buttons]="false">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="emailModel.dismiss()">OK</button>
    </modal-footer>
</modal>

<modal #errorModel>
    <modal-header [show-close]="false">
       <h4>Alert</h4>
    </modal-header>
    <modal-body>
        You have entered an invalid email. please try again.
    </modal-body>
    <modal-footer [show-default-buttons]="false">
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="emailModel.dismiss()">OK</button>
    </modal-footer>
</modal>
