import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { UserService } from '../../user/user.service';
import { AlertService } from '../../util/alert/alert.service';
import { AssessmentService } from '../assessment.service';
import { RemediationService } from '../../complib/remediation/remediation.service';
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model';
import { Task } from '../../complib/task/task.model';
import { Knowledge } from '../../complib/knowledge/knowledge.model';
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';
import * as FileSaver from "file-saver";

import { WidgetService } from '../../dashboard/widget.service';
import { SharedService } from "../../home/shared.service";
import { OverlayService } from '../../util/overlay/overlay.service'

import { Constants } from '../../util/const/constants'
import { OverlayToolTipComponent } from '../../util/overlay/overlaytooltip.component'
import { environment } from '../../../environments/environment';

declare var require: any;
const { version: appVersion } = require('../../../../package.json');

@Component({
	selector: 'app-selfassessment',
	templateUrl: './selfassessment.component.html',
	styleUrls: ['./selfassessment.component.scss', '../../home/header/header.component.scss',
		'../../report/remediationreport/assessmentremediationreport.component.scss']
})

export class SelfAssessment implements OnInit {
	public assessmentId: string = null;
	public ccList: any = [];
	public reviewCCList: any = [];
	public showCCDetail: string = '';
	public showTaskDetail: string = '';
	public showKnowledgeDetail: string = '';
	public showRemediationDetail: string = '';
	public showTaskRemediationDetail: string = '';
	public showKnowledgeRemediationDetail: string = '';
	public remediationAttachmentList: any = [];
	public selectedTaskRemediationAttachment: any = [];
	public selectedKnowledgeRemediationAttachment: any = [];
	public showKnowledge: boolean = false;
	public selectedTask: Task;
	public selectedCC: CoreCompetency;
	public isCCCompleted: boolean = false;
	public assessmentCompleted: boolean = false;
	public score: any = "";
	public reviewMissedAnswers: boolean = false;
	public isMissedKnowledgeAvailable: boolean = false;
	public firstTime: boolean = false;
	public appVersion: string = ''
	public userName: string = localStorage.getItem('userName')
	public userFirstName: string = localStorage.getItem('userFirstName')

	public constants: any
	public showOverlay: boolean = false

	public sACompletedBtn: string = Constants.OERLAY_INS_SELFASEST_COMSELFASSE_BTN
	public ccOverlayInc: string = Constants.OERLAY_INS_SELFASEST_CCITEM
	public taskOverlayInc: string = Constants.OERLAY_INS_SELFASEST_TASKITEM

	public previousScrollTop: number = 0

	public appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
	public logoSrc: string = ''
	public headerLogoPath: string = environment.server_URL + '/images/header/'
	public visualImagesPath: string = environment.server_URL + '/images/knowledge/'
	public isLoading: boolean = false

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private remediationService: RemediationService,
		private assessmentService: AssessmentService, private alertService: AlertService, private widgetService: WidgetService, private pipe: OrderArrayByType,
		private sharedService: SharedService, private overlayService: OverlayService, @Inject(DOCUMENT) private document: HTMLDocument) {
		this.assessmentId = this.activatedRoute.snapshot.queryParams["assessmentId"];
		this.appVersion = appVersion

		if (!userService.alreadyLoggedIn()) {
			this.router.navigateByUrl('/user/login?assessmentId=' + this.assessmentId);
		} else {
			if (this.assessmentId != null) {
				this.createJobProfileHierarchy();
			}
		}
		this.firstTime = true;
		this.constants = Constants
		// this.overlayService.toggle()

		if (this.appInfo.headerLogo != "") {
			this.logoSrc = this.headerLogoPath + '' + this.appInfo.headerLogo
		} else {
			this.logoSrc = 'assets/images/logo/axis.jpg'
		}

	}

	ngOnInit() {
	}

	onLoggedout() {
		let appConfig: any = JSON.parse(localStorage.getItem('appInfo'))
		localStorage.clear();
		localStorage.setItem('appInfo', JSON.stringify(appConfig));
	}

	private removeOverlay() {
		this.showOverlay = false
		this.overlayService.toggle()
	}

	private createJobProfileHierarchy() {
		let param: any = {
			'assessmentId': this.assessmentId,
		}
		this.isLoading = true
		this.assessmentService.getSelfAssessmentJobProfileHierarchy(param)
			.subscribe(
				data => {
				this.alertService.clear()
					this.ccList = this.generateCCListFromData(data);
					this.ccList = this.pipe.transform(this.ccList, 'orderNum')
					this.updateCCStatus();
					this.showKnowledge = false;
					this.selectedCC = null;
					this.selectedTask = null;
					this.isLoading = false

					if (this.firstTime) {
						this.showOverlay = true
						this.sharedService.toggleOverlayIns(true);
						this.overlayService.toggle()
					} else {

					}
				if (this.previousScrollTop != 0) {

					}
				},
				error => {
					this.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
					this.showOverlay = false
				}
			)
	}

	private updateCCStatus() {
		this.ccList = this.ccList.filter(item => item.taskList.length > 0)
		for (let i = 0; i < this.ccList.length; i++) {
			let ccItem = this.ccList[i];

			if (this.firstTime && i == 0) {
				this.showCCDetail = ccItem.id;
				this.showTaskDetail = ccItem.taskList[0].id;
			}
			if (ccItem.taskList.filter(item => item.assStatus == 0).length == 0) {
				ccItem.assStatus = 1;
			} else {
				ccItem.assStatus = 0;
			}
		}
		if (this.ccList.filter(item => item.assStatus == 0).length == 0) {
			this.isCCCompleted = true;
		}
	}

	public getImageURL(knowledgeId: any, answerId: any, fileType: any) {
		return this.visualImagesPath + "/" + knowledgeId + "/" + answerId + "." + fileType
	}

	private generateCCListFromData(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem
			let tl = data.result[i].taskList.filter(item => item.isTaskGroup == 0);
			let kl = data.result[i].knowledgeList;
			let knl = data.result[i].knowledgeAnswerList;
			let trml = data.result[i].taskRemediationList;
			let krml = data.result[i].knowledgeRemediationList;
			let kml = this.generateKnowledgeListModel(kl, knl, krml);
			let tml = this.generateTaskListModel(tl, kml, trml);
			let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
			ccItem.orderNum = cci.orderNum
			ccItem.assId = cci.ACCId
			ccItem.taskTotal = tl.length
			corecompetencyList.push(ccItem)
		}
		return corecompetencyList;
	}

	private generateKnowledgeListModel(knowledgeList: any, knowledgeAnswerList: any, knowledgeRemediationList): any {
		let knowledgeModelList = []
		for (let i = 0; i < knowledgeList.length; i++) {
			let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 1, knowledgeList[i].id)
			kModel.answerList = knowledgeAnswerList.filter(item => item.AKId === knowledgeList[i].AKId)
			kModel.remediationList = knowledgeRemediationList.filter(item => item.AKId === knowledgeList[i].AKId);
			kModel.jptId = knowledgeList[i].ATId
			kModel.orderNum = knowledgeList[i].orderNum
			kModel.answer = knowledgeList[i].answer
			kModel.assId = knowledgeList[i].AKId
			kModel.type = knowledgeList[i].Type
			kModel.booleanAnswerCorrect = knowledgeList[i].IsCorrect
			kModel.isVisualQuestion = knowledgeList[i].IsVisualQuestion
			kModel.isImageAttached = knowledgeList[i].ImageAttached
			kModel.fileType = knowledgeList[i].FileType
			knowledgeModelList.push(kModel)
		}
		return knowledgeModelList;
	}

	private generateTaskListModel(taskList: any, knoList: any, taskRemediationList: any) {
		let taskModelList = []
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task = new Task(taskList[i].id, taskList[i].text)
			taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId)
			taskModel.remediationList = taskRemediationList.filter(item => item.ATId === taskList[i].ATId)
			taskModel.jpccId = taskList[i].ACCId
			taskModel.orderNum = taskList[i].orderNum
			taskModel.assId = taskList[i].ATId
			taskModel.criticalityValue = taskList[i].criticality
			taskModelList.push(taskModel)
			if (taskModel.knowledgeList.filter(item => item.answer == 0).length == 0) {
				taskModel.assStatus = 1
			} else {
				taskModel.assStatus = 0
			}
		}
		taskModelList = this.pipe.transform(taskModelList, 'orderNum')
		return taskModelList;
	}

	public showCollapsePanel(itemId: any, itemType: string, remediationId: any) {
		switch (itemType) {
			case 'coreCompetency':
				this.showCCDetail = this.changeVarableToShowHideItem(this.showCCDetail, itemId);
				this.showTaskDetail = '';
				this.showKnowledge = false;
				this.firstTime = false;
				break;
			case 'task':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
				this.firstTime = false;
			case 'taskRemediation':
				this.showRemediationDetail = this.changeVarableToShowHideItem(this.showRemediationDetail, itemId);
				this.firstTime = false;
			case 'knowledgeRemediation':
				this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, itemId)
				this.showKnowledgeRemediationDetail = '';
				break;
			case 'taskRemediationDetail':
				this.selectedTaskRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedTaskRemediationAttachment == null || this.selectedTaskRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedTaskRemediationAttachment);
				}
				else if (this.selectedTaskRemediationAttachment.length == 1 && this.selectedTaskRemediationAttachment[0].id == '') {
					this.selectedTaskRemediationAttachment = [];
				}
				this.showTaskRemediationDetail = this.changeVarableToShowHideItem(this.showTaskRemediationDetail, itemId)
				break;
			case 'knowledgeRemediationDetail':
				this.selectedKnowledgeRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedKnowledgeRemediationAttachment == null || this.selectedKnowledgeRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedKnowledgeRemediationAttachment);
				}
				else if (this.selectedKnowledgeRemediationAttachment.length == 1 && this.selectedKnowledgeRemediationAttachment[0].id == '') {
					this.selectedKnowledgeRemediationAttachment = [];
				}
				this.showKnowledgeRemediationDetail = this.changeVarableToShowHideItem(this.showKnowledgeRemediationDetail, itemId)
				break;
			default:
				break;
		}
	}

	private selectTask(ccItem: CoreCompetency, task: Task) {
		this.selectedTask = task;
		this.showKnowledge = true;
		this.selectedCC = ccItem;
		this.previousScrollTop = document.documentElement.scrollTop
		document.documentElement.scrollTop = 0
		return false;
	}

	private changeVarableToShowHideItem(val: string, text: string): string {
		if (val != text) {
			val = text;
		} else {
			val = '';
		}

		return val;
	}

	private cancel(task: Task) {
		this.firstTime = false;
		this.createJobProfileHierarchy();
		document.documentElement.scrollTop = this.previousScrollTop
	}

	private setAnswer(knowledge: any, knowledgeAnswer: any) {
		for (let i = 0; i < knowledge.answerList.length; i++) {
			let answer = knowledge.answerList[i]
			if (answer.id == knowledgeAnswer.id) {
				answer.GivenAnswer = 1
			} else {
				answer.GivenAnswer = 0
			}
		}
	}

	private setKnowlegeAnswer(knowledge: any, answer: boolean) {
		if (answer) {
			knowledge.GivenAnswer = 3;
		} else {
			knowledge.GivenAnswer = 2;
		}
	}

	private save() {
		this.firstTime = false;
		for (let i = 0; i < this.selectedTask.knowledgeList.length; i++) {
			let knowledge = this.selectedTask.knowledgeList[i];
			if (knowledge.answerList.filter(item => item.GivenAnswer == 1).length > 0 || (knowledge.type == 'Boolean' && (knowledge.GivenAnswer == 3 || knowledge.GivenAnswer == 2))) {
				for (let j = 0; j < knowledge.answerList.length; j++) {
					let answer = knowledge.answerList[j];
					if (answer.GivenAnswer == null) {
						answer.GivenAnswer = 0;
					}
					answer.IsGivenAnswerCorrect = (answer.GivenAnswer == answer.IsCorrect ? 1 : 0);
				}
				if (knowledge.type == 'Boolean') {
					if (knowledge.GivenAnswer == 3 || knowledge.GivenAnswer == 2) {
						if ((knowledge.GivenAnswer == 3 && knowledge.booleanAnswerCorrect == 1) ||
							(knowledge.GivenAnswer == 2 && knowledge.booleanAnswerCorrect == 0)) {
							knowledge.answer = 3;
						} else {
							knowledge.answer = 2;
						}
					}
				} else {
					if (knowledge.answerList.filter(item => item.IsGivenAnswerCorrect == 0).length > 0) {
						knowledge.answer = 2;
					} else {
						knowledge.answer = 3;
					}
				}
			}
		}
		if (this.selectedTask.knowledgeList.filter(item => item.answer == 0).length == 0) {
			this.selectedTask.assStatus = 1
		} else {
			this.selectedTask.assStatus = 0
		}

		this.assessmentService.updateSelfAssessmentKnowledgeAnswers(this.assessmentId, this.selectedTask.jpccId, this.selectedTask.knowledgeList)
			.subscribe(
				data => {
					this.alertService.clear()
					this.updateCCStatus();
					this.showKnowledge = false;
					this.selectedCC = null;
					this.selectedTask = null;
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)

	}

	private completeSelfAssessment() {
		this.assessmentService.completeSelfAssessment(this.assessmentId)
			.subscribe(
				data => {
					this.alertService.clear()
					this.isCCCompleted = false;
					this.assessmentCompleted = true;
					let correct: number = data.correctKnowledgeCount;
					let total: number = data.totalKnowledgeCount;
					this.score = ((Math.round((correct / total) * 100)) * 100) / 100;
					this.showKnowledge = false;
					this.selectedCC = null;
					this.selectedTask = null;
					this.showCCDetail = '';
					this.showTaskDetail = '';
					this.reviewAnswers();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
	}

	public viewMissedKnowledge() {
		this.reviewMissedAnswers = !this.reviewMissedAnswers;
	}

	// load dashboard after completing self assessment
	public returnToDashBoard() {
		let selectedRole = localStorage.getItem('selectedUserRole');
		if (selectedRole == null && localStorage.getItem('userRoles') != null) {
			localStorage.setItem('capability', 'reload')
			this.router.navigateByUrl('/userrole');
		}
		else {
			let widgetList = localStorage.getItem('widgetList');
			if (selectedRole != null && widgetList != null) {
				this.router.navigateByUrl('/home');
			}
			else {
				let selectedUserRole = JSON.parse(selectedRole);
				this.sharedService.change('Dashboard');
				this.userService.getAssignedCapabalityList(selectedUserRole[0][1])
					.subscribe(
						data => {
							//get dashboard widgets
							this.widgetService.getDashboardWidgets(selectedUserRole[0][0])
						},
						error => {
						}
					);
			}
		}
	}

	private reviewAnswers() {
		this.reviewCCList = [];
		for (let i = 0; i < this.ccList.length; i++) {
			let ccItem = this.ccList[i];
			for (let j = 0; j < ccItem.taskList.length; j++) {
				let taskItem = ccItem.taskList[j];
				if (taskItem.knowledgeList.filter(item => item.answer == 2).length == 0) {
					taskItem.assStatus = 1;
				} else {
					taskItem.assStatus = 0;
				}
			}
			if (ccItem.taskList.filter(item => item.assStatus == 0).length == 0) {
				ccItem.assStatus = 1;
			} else {
				ccItem.assStatus = 0;
			}
			if (ccItem.assStatus == 0) {
				this.reviewCCList.push(ccItem);
			}
		}
		if (this.ccList.filter(item => item.assStatus == 0).length == 0) {
			this.isMissedKnowledgeAvailable = false;
		} else {
			this.isMissedKnowledgeAvailable = true;
		}		
		}

	private getRemediationAttachmentsByRemediationId(remediationId: any, attachments: any) {
		this.remediationService.getRemediationAttachmentsByRemediationId(remediationId).subscribe(
			data => {
				if (data.remediationAttachmentList.length > 0) {
					for (var i = 0; i < data.remediationAttachmentList.length; i++) {
						var fileExtension = data.remediationAttachmentList[i].FileName.substr((data.remediationAttachmentList[i].FileName.lastIndexOf('.') + 1));
						attachments.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
						this.remediationAttachmentList.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
					}
				}
				else {
					this.remediationAttachmentList.push({ remediationId: remediationId, id: '', text: '', fileName: '' });
				}

			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	public fileIconButtonClickEvent(fileName, attachmentId, itemType): any {
		var fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
		var mediaType = this.remediationService.getFileType(fileExtension);
		this.remediationService.getFileContent(fileName, attachmentId, mediaType)
			.subscribe(fileData => {
				FileSaver.saveAs(fileData, fileName);
			});
	}

}