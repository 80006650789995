<div class="col-lg-12">
    <div class="col" style="overflow:hidden">
        <div class="float-left">
            <h4>Assign User Roles</h4>
        </div>
        <div class="float-right">

        </div>
    </div>
    <hr />
</div>
<div class="container">
    <form [formGroup]="assignUserRoleForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Select User</label>
            <div class="col-sm-10">
					 <ng-select [allowClear]="true" (removed)="removedUser($event)" (selected)="selectedUser($event)" [items]="userList" placeholder="Select user" name="userId" id="userId" formControlName="userId">
					</ng-select>
					</div>
        </div>
        <div formArrayName="roles">
            <div *ngFor="let role of getFormData().controls;let i=index">
                <div class="form-group row" [formGroupName]="i">
                    <div class="col-sm-1">
                        <input type="checkbox" id="access" formControlName="access" name="access" data-md-icheck (change)="assigneRole($event, i)" />
                    </div>
                    <div class=" col-sm-3 ">
                        {{role.get('role').value}}
                    </div>
                </div>
            </div>
        </div>
        <!--buttons-->
        <div class="form-group row " *ngIf="isDataAvailable==true">
            <div class="col-sm-9 ">
            </div>
            <div class="col-sm-3 ">
                <button type="submit" class="btn btn-warning btn-block" [disabled]="!assignUserRoleForm.valid">Save</button>
            </div>            
        </div>
    </form>
</div>
<alert></alert>