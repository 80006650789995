import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { user } from './user';
import { UserService } from './user.service';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-user',
	templateUrl: './forgotpassword.component.html',
	styleUrls: []
})

export class ForgotpasswordComponent implements OnInit {

	isLoading;
	passwordResetForm: FormGroup;
	@ViewChild('successModel', { static: true }) successModel: ModalComponent;
	@ViewChild('errorModel', { static: true }) errorModel: ModalComponent;
	@ViewChild('expierModel', { static: true }) expierModel: ModalComponent;
	public appInfo: any = JSON.parse(localStorage.getItem('appInfo'))
	public logoSrc: string = ''
	public headerLogoPath: string = environment.server_URL + '/images/header/'

	constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder) {
		this.passwordResetForm = fb.group({
			newPwd: ['', Validators.required],
			retypeNewPwd: ['', Validators.required]
		}, { validator: matchingPasswords('newPwd', 'retypeNewPwd') })
		if (this.appInfo.headerLogo != "") {
			this.logoSrc = this.headerLogoPath + '' + this.appInfo.headerLogo
		} else {
			this.logoSrc = 'assets/images/logo/axis.jpg'
		}
	}

	onSubmit() {
		var userId = this.activatedRoute.snapshot.queryParams["userId"];
		var reqId = this.activatedRoute.snapshot.queryParams["reId"];
		const tempUser = new user('', '', '', '', '', false, '', reqId, userId, this.passwordResetForm.value.newPwd);
		this.userService.resetCurrentPassword(tempUser)
			.subscribe(
			data => {
				//this.router.navigateByUrl('/user/login');
				this.successModel.open();
			},
			error => {
				this.errorModel.open();
			}
			);
		this.passwordResetForm.reset();
	}

	ngOnInit() {
		var userId = this.activatedRoute.snapshot.queryParams["userId"];
		var reqId = this.activatedRoute.snapshot.queryParams["reId"];
		const tempUser = new user('', '', '', '', '', false, '', reqId, userId);
		this.userService.validateForgotpasswordResetLink(tempUser)
			.subscribe(
			data => {
				if (data.exTime == true) {
					this.isLoading = true;
				} else {
					this.isLoading = false;
					this.router.navigateByUrl('/user/login');
				}
			},
			error => {
				this.isLoading = false;
				this.router.navigateByUrl('/user/login');
			}
			);
	}

	clickSuccessBtn() {
		this.router.navigateByUrl('/user/login');
		//this.successModel.close();
	}
}

// FORM GROUP VALIDATORS
export function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
	return (group: FormGroup): { [key: string]: any } => {
		let password = group.controls[passwordKey];
		let confirmPassword = group.controls[confirmPasswordKey];

		if (password.value !== confirmPassword.value) {
			return {
				mismatchedPasswords: true
			};
		}
	}
}