<div class="row mx-auto mt-2">
	<div class="col-sm-12 table-responsive mt-2 mb-2">
		<table sortable-table (sorted)="onSorted($event)" class="table table-bordered  data-sortable col-sm-12">
			<thead>
				<tr *ngIf="!isFiltering">
					<th class="set-width text-center ">Score</th>
					<th sortable-column="Candidate" sortable-type="string" class="text-center ">Candidate</th>
					<th sortable-column="Task NA" sortable-type="string"class="text-center ">Task NA</th>
					<th sortable-column="Performance NA" sortable-type="string" class="text-center ">Performance NA</th>
					<th sortable-column="Knowledge NA"sortable-type="string" class="text-center ">Knowledge NA</th>
					<th sortable-column="Assessor" sortable-type="string" class="text-center ">Assessor</th>
					<th class="text-center ">Completed Date</th>
					<th class="text-center ">Assessment Report</th>
				</tr>
				<tr *ngIf="isFiltering">
					<th class="set-width text-center ">Score</th>
					<th class="text-center ">Candidate</th>				
					<th class="text-center ">Task NA</th>
					<th class="text-center ">Performance NA</th>
					<th class="text-center ">Knowledge NA</th>
					<th class="text-center ">Assessor</th>
					<th class="text-center ">Completed Date</th>
					<th class="text-center ">Assessment Report</th>
				</tr>
			</thead>
			<tbody>
				<tr style="background-color: rgba(0, 0, 0, 0.05)">
					<th class="set-width "></th>
					<th class="">
						<input class="form-control" type="text" name="searchCandidate" [(ngModel)]="searchCandidate" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchTaskNA" [(ngModel)]="searchTaskNA" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchPerformanceNA" [(ngModel)]="searchPerformanceNA" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchKnowledgeNA" [(ngModel)]="searchKnowledgeNA" (keyup)="filter()">
					</th>
					<th class="">
						<input class="form-control" type="text" name="searchAssessor" [(ngModel)]="searchAssessor" (keyup)="filter()">
					</th>
					<th class=""></th>
					<th class=""></th>
				</tr>
				<tr *ngFor="let row of paginatedResults; let i = index" [style.background-color]="row.color">
					<td class="set-width">
						<div class="progress ">
							<div *ngIf="row['Assessment Score'] < 85" class="pointer pointer-cursor" [style.left]="row['Assessment Score'] + '%'">
								<div class="progress-badge">
									<p>{{ row['Assessment Score'] }}%</p>
								</div>
							</div>
							<div *ngIf="row['Assessment Score'] == 100" class="pointer pointer-cursor" [style.left]="99.25 + '%'">
								<div class="progress-badge float-right">
									<p>{{ row['Assessment Score'] }}%</p>
								</div>
							</div>
							<div *ngIf="row['Assessment Score'] >= 85 && row['Assessment Score'] != 100" class="pointer pointer-cursor" [style.left]="row['Assessment Score'] + '%'">
								<div class="progress-badge float-right">
									<p>{{ row['Assessment Score'] }}%</p>
								</div>
							</div>
							<div class="progress-bar" [ngClass]="{'progress-bar-success': row['Assessment Score'] >= 80, 'progress-bar-warning': row['Assessment Score'] >= 50 && row['Assessment Score'] < 80, 'progress-bar-danger' : row['Assessment Score'] < 50}"
							 role="progressbar" aria-valuenow="row['Assessment Score']" aria-valuemin="0" aria-valuemax="100" [style.width]="row['Assessment Score'] + '%'">
							</div>
						</div>
					</td>
					<td>{{row.Candidate}}</td>
					<td>{{row["Task NA"]}} 
						<span class="badge badge-dark">{{ row.PrecentTaskNA }}%</span> 
					</td>
					<td>{{row["Performance NA"]}}
						<span class="badge badge-dark">{{ row.PrecentPerformanceNA }}%</span>
					</td>
					<td>{{row["Knowledge NA"]}}
						<span class="badge badge-dark">{{ row.PrecentKnowledgeNA }}%</span>
					</td>
					<td>{{row["Assessor"]}}</td>
					<td>{{row["Completed Date"] == null ? "" : row["Completed Date"]}}</td>
					<td class=" text-center">
						<a style="cursor: pointer" class="btn btn-sm edit " (click)="performAssessmentClickEvent(row['Assessment'])">
							<i class="fa fa-folder " aria-hidden="true"></i>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="col-sm-12">
		<pagination class="pagination-sm mb-2" [ngModel]="currentPage" [totalItems]="total" [itemsPerPage]="itemsPerPage" [maxSize]="maxNoOfPages"
		 [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event.page)" (numPages)="numPages = $event"></pagination>
		<pre class="card card-block card-header">Page: {{currentPage}} / {{numPages}}</pre>
	</div>
</div>
<br>

<div *ngIf=isLoading class="loading"></div>