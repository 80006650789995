export class CheckList {
	constructor(
		public id: string,
		public text?: string,
		public orgId?: string,
		public status?: boolean,

		public orderNum?: number,
		public remediationList?: any,

		public jppId?: string,
		public JPCLId?: any,

		public tagList?: any[],

		public answer?: number,
		/** id of the check list in assessment table.
		use to update assessment answer */
		public assId?: string,
		//id of assessment table
		public assessmentId?: string,
		public reasonNA?: string,
		public reasonNAId?: string
	) { }
}
