import { Injectable } from '@angular/core';
import { CustomModalComponent } from "./custommodal.component";
import { DependencyService } from '../dependency/dependency.service';
import { AlertService } from '../alert/alert.service';
import * as $ from 'jquery';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable()
export class CustomModalService {
	private customModal: CustomModalComponent;
	public foundDependency: boolean = false;
	public allItems: any = []

	constructor(private alertService: AlertService, private dependencyService: DependencyService) { }

	private setModalData(dependency: any, title: string, itemId: string, component: any, status: any, allItems: any) {		
		this.customModal.modal.dependency = dependency;
		this.customModal.modal.title = title.split('_')[0];
		console.log(this.customModal.modal.title)
		this.customModal.modal.itemId = itemId;
		this.customModal.modal.component = component;
		if (this.customModal.modal.title == "Reason for N/A") {
			this.customModal.modal.bodyText = "reason for N/A"
		} else {
			this.customModal.modal.bodyText = this.customModal.modal.title.toLowerCase();
		}
		if (this.customModal.modal.title == "Job Profile"){
			this.customModal.modal.allItems = allItems
		}
		this.customModal.modal.status = status;
	}

	add(customModal: any) {
		this.customModal = customModal;
	}

	private open(modalId: string) {
		this.customModal.open(modalId);
	}

	public isFoundDependency() {
		return this.foundDependency;
	}

	public invokeCopy(nonCopy: any, type: string, id: string, component: any, modalId: string, status: any, allItems: any) {		
		this.allItems = this.generateItemList(allItems)
		this.setModalData(nonCopy, type, id, component, status, this.allItems);
		this.open(modalId);
	}

	public invoke(type: string, id: string, component: any, modalId: string, status: any) {	
		if (modalId == 'showHide' || type == 'Note') {
			this.setModalData(null, type, id, component, status, null);
			this.open(modalId);
		}
		else if (status == 1 || modalId == 'delete') {
			component.isLoading = true
			this.dependencyService.getDependency(id, type).subscribe(
				data => {
					component.isLoading = false
					this.setModalData(data.dependencyList, type, id, component, status, null);
					this.open(modalId);
					if (data.dependencyList.length > 0) {
						this.foundDependency = true;
					}
				},
				error => {
					component.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
		}
		else if (modalId == 'edit') {
			if (component.dependencyList.length > 0) {
				this.setModalData(component.dependencyList, type, id, component, status, null);
				this.open(modalId);
			}
			else {
				this.dependencyService.getDependency(id, type).subscribe(
					data => {
						if (data.dependencyList.length > 0) {
							component.dependencyList = data.dependencyList
							this.foundDependency = true;
						}
					},
					error => {
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
			}

		}
		else {
			this.setModalData(null, type, id, component, status, null);
			this.open(modalId);
		}
	}

	public exportAsExcelFile(json: any[], excelFileName: string): void {
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		// const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
		// this.saveAsExcelFile(excelBuffer, excelFileName);
		XLSX.writeFile(workbook, excelFileName+ EXCEL_EXTENSION);
	}

	public generateItemList(data: any): any {
		let dependencyType = "";
		let dependencyItems = []
		data.forEach(element => {
			if (element.dependencyType === dependencyType) { //existing type
				let item = { value: "- " + element.dependencyValue, type: "" };
				dependencyItems.push(item);
			} else { //new type				
				let item = { value: "", type: element.dependencyType };
				dependencyItems.push(item);
				dependencyType = element.dependencyType;
				item = { value: "- " + element.dependencyValue, type: "" };
				dependencyItems.push(item);
			}
		});		
		return dependencyItems
	}
}
