<div class="login-page">
	<div class="row">
		<div class="col-md-4 offset-md-4">
			<div class="panel">
				<h1>Key Code Verification</h1>
				<h3>Your account is currently inactive. A key code has been sent to your email. Please enter the key code below to reactivate
					your account.</h3>
				<div class="panel-body">
					<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
						<fieldset>
							<input style="display: none;" id="deviceId" name="deviceId" type="text">
							<div class="form-group">
								<input class="form-control" formControlName="keycode" placeholder="Key Code" name="keycode" type="text">
							</div>
							<div>
								<alert></alert>
							</div>
							<button class="btn rounded-btn" [disabled]="!myForm.valid"> Submit </button>
						</fieldset>
					</form>
					<br>
					<button class="btn rounded-btn" [routerLink]="['/user/login']"> Cancel </button>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf=isLoading class="loading"></div>