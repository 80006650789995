import { Component } from "@angular/core";
import { AssessmentService } from "../../../assessment/assessment.service";
import { AlertService } from "../../../util/alert/alert.service";

@Component({
	selector: 'app-employeeassessmentprogress',
	templateUrl: './employeeassessmentprogress.component.html',
	styleUrls: ['../assessment/globalprogress/globalassessmentprogress.component.scss']
})

export class EmployeeAssessmentProgressComponent {

	public pieChartLabels: Array<string> = ['Overdue Assessments', 'Completed Assessments', 'Never Assessed'];
	public pieChartColors: Array<any> = [{ backgroundColor: ['#ffc107', '#28a745', '#dc3545'] }];
	public pieChartData: number[] = [0, 0, 0];
	public pieChartType: string = 'pie';
	show: boolean = false;
	showPieChart: boolean = false;
	itemsPerPage: number = 5;
	currentPage: number = 1;
	maxNoOfPages: number = 1;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	public showDate: boolean = false
	public data: Array<any> = [];
	public filteredData: Array<any> = [];
	public tableName: string = 'Overdue Assessments'
	public supervisorId = localStorage.getItem('userId');
	public pieChartOptions: any = {
		pieceLabel: {
			render: 'percentage',
			fontSize: 12,
			fontColor: ['#000000', '#000000', '#000000'],
			position: 'outside',
			showActualPercentages: true
		},
		legend: { 
			display: false,
			position: 'right' 
		}
	};

	constructor(private assessmentService: AssessmentService, private alertService: AlertService) {
		this.pieChartData = assessmentService.getEmployeePieChartData()

		if (this.pieChartData.length == 0) {
			this.showPieChart = false;
		} else {
			this.showPieChart = true;
		}
	}

	ngOnInit() {
		// this.assessmentService.getEmployeeGlobalAssessmentProgress(this.supervisorId)
		// 	.subscribe(
		// 	data => {
		// 		this.pieChartData = [data.missingAssessmentsCount[0].Count, data.neverAssessedCount[0].Count, data.scheduledAssessmentsCount[0].Count, data.uptodateAssessmentsCount[0].Count];
		// 	},
		// 	error => {
		// 		console.log(error);
		// 	}
		// 	)
	}

	public chartClicked(e: any): void {
		if (e.active.length == 0) {
			return;
		}
		
		let index = e.active[0]._index;

		switch (index) {
			case 0:
				this.tableName = 'Overdue assessments candidate list'
				this.assessmentService.getOverdueAssessmentCandidateListBySupervisorId(this.supervisorId).subscribe(
					data => {
						this.showDate = true
						this.loadProgressDataToTable(data.candidateList)
						this.show = true;
					},
					error => {
						this.show = true;
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
				break
			case 1:
				this.tableName = 'Completed assessments candidate list'
				this.assessmentService.getCompletedAssessmentCandidateListBySupervisorId(this.supervisorId).subscribe(
					data => {
						this.showDate = true
						this.loadProgressDataToTable(data.candidateList)
						this.show = true;
					},
					error => {
						this.show = true;
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
				break
			case 2:
				this.tableName = 'Never Assessed candidate list'
				this.assessmentService.getNeverAssessedCandadidateListBySupervisorId(this.supervisorId).subscribe(
					data => {
						this.loadProgressDataToTable(data.candidateList)
						this.show = true;
					},
					error => {
						this.show = true;
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
				break
			default:
				break
		}

	}

	showChart() {
		this.show = false;
	}

	public loadProgressDataToTable(data) {
		this.data = [];
		this.currentPage = 1;
		for (let i = 0; i < data.length; i++) {
			var completedDate = data[i].AssessmentCompletedDate
			if (completedDate == null) {
				completedDate = null;
			} else {
				completedDate = new Date(new Date(completedDate).getUTCFullYear(), new Date(completedDate).getUTCMonth(), new Date(completedDate).getUTCDate()).toLocaleDateString();
			}
			this.data.push({
				firstName: data[i].FirstName,
				lastName: data[i].LastName,
				email: data[i].Email,
				orgCode: data[i].OrgCode == null ? "" : data[i].OrgCode,
				jobProfile: data[i].JobProfile == null ? "" : data[i].JobProfile,
				completedDate: completedDate
			});
		}
		this.total = this.data.length;
		this.filteredData = this.data;
		this.pageChanged();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		// this.changeDetectorRef.detectChanges();
	}
}