import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';
import { CoreCompetency } from './corecompetency.model';

@Injectable()
export class CoreCompetencyService {

	// public curCoreCompId: string = null;
	// public curCoreCompetency: string = null;
	// public curAssignedTagList: any[] = null;

	constructor(private http: Http, private router: Router) { }

	private selectedCCModel: CoreCompetency;

	public setSelectedCCModel(ccModel: CoreCompetency): void {
		this.selectedCCModel = ccModel
	}

	public getSelectedCCModel(): CoreCompetency {
		return this.selectedCCModel;
	}

	addCoreCompetency(coreCompetency: CoreCompetency) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(coreCompetency);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/addCoreCompetency' + token, body, { headers: headers })
			.map(res => res.json());
	}

	editCoreCompetency(coreCompetency: CoreCompetency) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify(coreCompetency);
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/editCoreCompetency' + token, body, { headers: headers })
			.map(res => res.json());
	}

	changeCoreCompetencyStatus(compId: string, status: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compId': compId, 'status': status });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/changeCoreCompetencyStatus' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllCoreCompetencies() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getAllCoreCompetency' + token, { headers: headers })
			.map(res => res.json());
	}

	getActiveCoreCompetencies() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getActiveCoreCompetencies' + token, { headers: headers })
			.map(res => res.json());
	}

	deleteCoreCompetency(compId: string) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compId': compId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/removeCoreCompetency' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAssignedCCTagList(compId: String) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const body = JSON.stringify({ 'compId': compId });
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this
			.http
			.post(environment.server_URL + '/complib/getAssignedCCTagList' + token, body, { headers: headers })
			.map(res => res.json());
	}

	getAllShowCoreCompetencies() {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/getAllShowCoreCompetencies' + token, { headers: headers })
			.map(res => res.json());
	}

	changeCCShowHide(ccId: string, showHide: number) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';

		const body = JSON.stringify({ 'ccId': ccId, 'showHide': showHide});
		const headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(environment.server_URL + '/complib/changeCCShowHide' + token, body, { headers: headers })
			.map(res => res.json());
	}

	updateCoreCompetencyOrder(ccList: any) {
		const token = localStorage.getItem('token')
			? '?token=' + localStorage.getItem('token')
			: '';
		const headers = new Headers({ 'Content-Type': 'application/json' });
		const body = JSON.stringify({ 'ccList': ccList});
		return this.http.post(environment.server_URL + '/complib/updateCoreCompetencyOrder' + token, body, { headers: headers })
			.map(res => res.json());
	}

}
