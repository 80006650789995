import { Component, OnInit, Output, EventEmitter } from "@angular/core";

import { AlertService } from "../util/alert/alert.service";
import { SharedService } from "../home/shared.service";
import { ReportService } from "./report.service";
import { Constants } from "../util/const/constants";

import { Report } from "./report.model";
import { ChecklistService } from "../complib/checklist/checklist.service";
import { TaskService } from "../complib/task/task.service";
import { PerformanceService } from "../complib/performance/performance.service";
import { KnowledgeService } from "../complib/knowledge/knowledge.service";
import { AssessmentService } from "../assessment/assessment.service";
import { UserService } from "../user/user.service";

@Component({
	selector: "app-generate-report",
	templateUrl: "./generatereport.component.html"
})
export class GenerateReportComponent implements OnInit {
	public report: Report;
	public constants: any;
	public data: any[];
	public isiPad: boolean = false;
	public recentAssessment: boolean = true;

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private taskService: TaskService,
		private checklistService: ChecklistService,
		private performanceService: PerformanceService,
		private knowledgeService: KnowledgeService,
		private userService: UserService,
		private assessmentService: AssessmentService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.report = this.reportService.getSelectedReport();
		this.constants = Constants;
		this.isiPad = window.navigator.platform === 'iPad' ? true : window.navigator.platform === 'iPhone' ? true : false;
	}

	ngOnInit() { }

	public loadReportHTML() {

		if (this.report.reportName == Constants.MOST_MISSED_TASKS) {

			if (this.report.printData.length == 0) {
				let reportHTML = '<h1>Assessment - Most missed tasks</h1><br>';
				return reportHTML;
			}

			let reportHTML = '<h1>Assessment - Most missed tasks</h1><br>' +
				'<table width="1000px" border=1 style="border-collapse:collapse;" ><thead><tr><th></th><th style="width:99.5%;">Task</th><th style="width:99.5%;">Occurrences</th></tr>' +
				'</thead><tbody><tr>';

			for (let j = 0; j < this.report.printData.length; j++) {

				reportHTML = reportHTML + '<td style="width:30%;"><div style=\"line-height: 1rem; font-size: .75rem;text-align: center; width=1000px; margin-bottom: 10px; margin-top: 10px; overflow: hidden;background-color: #bfbfbf;border-radius:.25rem;' +
					'display: -webkit-box;display: -ms-flexbox;display: flex;\">';

				if (this.report.printData[j]["FailedPercentage"] != 0) {
					reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
						'background-color:#dc3545;width:' + this.report.printData[j]["FailedBadgepPosition"] + '%;\" role="progressbar"' +
						'>' + this.report.printData[j]["FailedPercentage"] + '%</div>';
				}

				else if (this.report.printData[j]["FailedPercentage"] == 0) {
					reportHTML = reportHTML + '<div style=\"' +
						'background-color:#dc3545;width:' + this.report.printData[j]["FailedBadgepPosition"] + '%;\"' +
						'> </div>';
				}
				if (this.report.printData[j]["PassPercentage"] != 0) {
					reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
						'background-color:#ffc107;width:' + this.report.printData[j]["PassBadgepPosition"] + '%;\" role="progressbar" ' +
						'>' + this.report.printData[j]["PassPercentage"] + '%</div>';
				}
				else if (this.report.printData[j]["PassPercentage"] == 0) {
					reportHTML = reportHTML + '<div style=\"' +
						'background-color:#ffc107;width:' + this.report.printData[j]["PassBadgepPosition"] + '%;\"' +
						'> </div>';
				}
				if (this.report.printData[j]["HighPercentage"] != 0) {
					reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
						'background-color:#28a745;width:' + this.report.printData[j]["HighBadgepPosition"] + '%;\" role="progressbar" ' +
						'>' + this.report.printData[j]["HighPercentage"] + '%</div>';
				}
				else if (this.report.printData[j]["HighPercentage"] == 0) {
					reportHTML = reportHTML + '<div style=\"' +
						'background-color:#28a745;width:' + this.report.printData[j]["HighBadgepPosition"] + '%;\"' +
						'> </div>';
				}

				reportHTML = reportHTML + '</div></td><td style="width:65%;">' + this.report.printData[j]["Task"] + '</td>';
				reportHTML = reportHTML + '<td style="width:5%;">' + this.report.printData[j]["Occurrences"] + '</td></tr>';

			}
			reportHTML = reportHTML + "</tbody></table>";

			return reportHTML;

		}
		else {
			let reportHTML =
				"<h1>" +
				this.report.reportType +
				" - " +
				this.report.reportName +
				"</h1>";
			var reportData: Array<any> = []
			var reportColumns: Array<any> = []
			if (this.report.reportName == Constants.PERCENT_ASSESSMENT_NA_REPORT) {
				this.report.reportDataList.forEach(item => {
					// delete item["Assessment Report"]
					// delete item["Assessment"]
					var temp = {
						"Assessment Score": item["Assessment Score"],
						"Candidate": item["Candidate"],
						"Task NA": item["Task NA"] + " (" + item.PrecentTaskNA + "%)",
						"Performance NA": item["Performance NA"] + " (" + item.PrecentPerformanceNA + "%)",
						"Knowledge NA": item["Knowledge NA"] + " (" + item.PrecentKnowledgeNA + "%)",
						"Assessor": item["Assessor"],
						"Completed Date": item["Completed Date"]
					}
					reportData.push(temp)
				})
				this.report.columns.forEach(item => {
					if (item.title != 'Assessment Report' && item.title != 'Assessment') {
						reportColumns.push(item)
					}
				})
			} else {
				reportColumns = this.report.columns
				reportData = this.report.reportDataList
			}
			if (this.report.reportName == Constants.JOBPROFILE_TASKS) {
				reportHTML = reportHTML + "<br>Job Profile : " + this.report.reportItem
			}
			if (reportData.length > 0) {
				reportHTML = reportHTML + "<br><br><table border=1 style=border-collapse:collapse > " +
					"<thead>" +
					"<tr>";
				for (let k = 0; k < reportColumns.length; k++) {
					//this.report.reportHTML = "<th> " + this.columns[k].title + "</th>";
					reportHTML =
						reportHTML + "<th> " + reportColumns[k].title + "</th>";
				}

				reportHTML = reportHTML + "</tr> " + "</thead> " + "<tbody> ";

				for (let j = 0; j < reportData.length; j++) {
					reportHTML = reportHTML + "<tr>";
					//console.log( "load data in arrray") ;
					Object.getOwnPropertyNames(reportData[j]).forEach(key => {
						//let value = this.data[j][key];
						reportHTML = reportHTML + "<td>" + reportData[j][key];
						+"</td>";
					});

					reportHTML = reportHTML + "</tr>";
				}

				reportHTML = reportHTML + "</tbody> " + "</table> ";
			}
			return reportHTML;
		}
	}

	print() {
		let popupWinindow;
		let content = this.loadReportHTML();
		popupWinindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWinindow.document.close();
	}

	exportToExcel(reportName: string, excelData: any[], reportItem: any) {
		var data = []
		if (reportName == Constants.PERCENT_ASSESSMENT_NA_REPORT) {
			excelData.forEach(item => {
				var temp = {
					"Assessment Score": item["Assessment Score"],
					"Candidate": item["Candidate"],
					"Task NA": item["Task NA"] + " (" + item.PrecentTaskNA + "%)",
					"Performance NA": item["Performance NA"] + " (" + item.PrecentPerformanceNA + "%)",
					"Knowledge NA": item["Knowledge NA"] + " (" + item.PrecentKnowledgeNA + "%)",
					"Assessor": item["Assessor"],
					"Completed Date": item["Completed Date"]
				}
				data.push(temp)
			})
		} else {
			data = excelData
		}
		let outputName = reportName
		if (reportItem) {
			outputName += " - " + reportItem
		}
		this.reportService.exportAsExcelFile(data, outputName);
	}

	showReportList() {
		this.sharedService.change("Report");
	}

	showMostRecentAssessment(event) {
		if (event.target.checked) {
			this.recentAssessment = true
		} else {
			this.recentAssessment = false
		}
		this.assessmentService.getPercentOfAssessmentNA(this.recentAssessment).subscribe(
			data => {
				this.report.reportData = data.candidateList;
				this.sharedService.setRefreshReport(this.report.reportData);
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		);
	}
}
