import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { TableComponent } from '../../util/table/table.component';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { CustomModalService } from '../../util/custommodal/custommodal.service';
import { FormGroup, FormControl } from '@angular/forms';
import { RegulatoryAgencyService } from './regulatoryagency.service'
import { RegulatoryAgency } from './regulatoryagency.model'

@Component({
	selector: 'app-allregulatoryagency',
	templateUrl: './allregulatoryagency.component.html'
})
export class AllRegulatoryAgency extends TableComponent {
	public selectedRegulatoryAgency: any;
	public confirmationBtnClass: string = 'btn-default';

    public selectedUserRole = [];
    public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');

	private isDeleteRegulatoryAgency: boolean;	
	public isAddRegulatoryAgency: boolean;
    private isQueryRegulatoryAgeny: boolean;
    
    private serverResultData: Array<any> = [];

    public columns: Array<any> = [
		{ title: 'Description', name: 'description', filtering: { filterString: '', placeholder: '' } },
		{ title: 'Short Name', name: 'shortName', filtering: { filterString: '', placeholder: '' } },
		{ title: '', name: 'editbutton', className: 'accepter-col-action' },
		{ title: '', name: 'deletebutton', className: 'accepter-col-action' }
		
	];

	config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"]
    };
    
    constructor(private customModalService: CustomModalService, private alertService: AlertService,	private sharedService: SharedService, private userService: UserService,
        private regulatoryAgencyService: RegulatoryAgencyService) {
		super();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		//get user capabilities
		this.isAddRegulatoryAgency = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);		
		this.isDeleteRegulatoryAgency = this.userService.isCapabilityAssigned('DeleteCompLib', this.selectedUserRole[0][1]);
		this.isQueryRegulatoryAgeny = this.userService.isCapabilityAssigned('QueryCompLib', this.selectedUserRole[0][1]);
    }
    
    ngOnInit(){
        if (this.isQueryRegulatoryAgeny) {
			this.loadAllRegulatoryAgency();
		} else {
			//do nothing
		}
    }

    //navigate to add user component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

    //load all note from the db
	private loadAllRegulatoryAgency() {
		this.data = [];
		this.regulatoryAgencyService.getAllRegulatoryAgency()
			.subscribe(
			data => {
				//console.log('[loadAllNote] data : ' + JSON.stringify(data) + '/(count)' + data.noteList.length);
				for (let i = 0; i < data.regulatoryAgencyList.length; i++) {
					this.data.push({
						'id': i,
						'regulatoryAgencyId': data.regulatoryAgencyList[i].Id,
						'description': data.regulatoryAgencyList[i].Description,
						'shortName': data.regulatoryAgencyList[i].ShortName,
						'editbutton': '', 'deletebutton': ''
					});
				}
				this.serverResultData = data.regulatoryAgencyList;
				this.pageCount = Math.ceil(this.serverResultData.length / this.itemsPerPage);
				this.onChangeTable(this.config);
				this.extendData();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	public extendData() {
		for (let i = 0; i < this.data.length; i++) {
			//check user has modify capability
			if (this.isAddRegulatoryAgency) {
				this.data[i].editbutton = '<a class="action-btn edit" style="cursor: pointer"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].editbutton = '<a class="btn btn-sm  disabled edit"><i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></a>';
			}

			//check user has delete capability
			if (this.isDeleteRegulatoryAgency) {
				this.data[i].deletebutton = '<a class="action-btn delete" style="cursor: pointer"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			} else {
				this.data[i].deletebutton = '<a class="btn btn-sm disabled delete"><i class="fa fa-trash-o fa-lg" aria-hidden="true"></i></a>';
			}

		}
	}

	//on cell click event
	public onCellClick(data: any): any {
		//console.log('[onCellClick] (selectedRow) : ' + data.row.id);
		var elementnumber = parseInt(data.row.id);
		this.selectedRegulatoryAgency = this.serverResultData[elementnumber];
		if (this.serverResultData[elementnumber].Status) {
			this.confirmationBtnClass = 'btn-danger';
		} else {
			this.confirmationBtnClass = 'btn-success'
		}
		switch (data.column) {
			case 'editbutton':
				if (this.isAddRegulatoryAgency) {
					this.editButtonClickEvent(this.selectedRegulatoryAgency);
				} else {
					//do nothing
				}
				break;
			case 'deletebutton':
				if (this.isDeleteRegulatoryAgency) {
					this.deleteButtonClickEvent(this.selectedRegulatoryAgency.Id);
				} else {
					//do nothing
				}
				break;
			default:
				break;
		}
	}

	private editButtonClickEvent(selectedNote: any) {
		const tempRegulatoryAgency = new RegulatoryAgency(this.selectedRegulatoryAgency.Id, this.selectedRegulatoryAgency.Description, this.selectedRegulatoryAgency.ShortName);
		this.regulatoryAgencyService.setSelectedRegulatoryAgencyForEdit(tempRegulatoryAgency);
		this.sharedService.change('EditRegulatoryAgency');
	}

	private deleteButtonClickEvent(id: string) {
		let itemType = "Regulatory Agency";
		this.customModalService.invoke(
			itemType,
			id,
			this,
			"delete", null
		);
	}

	delete() {	
		const tempRegulatoryAgency = new RegulatoryAgency(this.selectedRegulatoryAgency.Id, this.selectedRegulatoryAgency.Description, this.selectedRegulatoryAgency.ShortName);
		this.regulatoryAgencyService.deleteRegulatoryAgency(tempRegulatoryAgency)
			.subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.loadAllRegulatoryAgency();
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}
}
